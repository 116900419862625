import React, {  useEffect, useState, useContext } from "react";
import {  getDataTypePost } from "../UseContext/ApiHelpers";
import { BASE_URL_STATIC } from "../../../config";

import PostTime from "../Home/CenterBlock/CenterBlockPosts/PostTime";
import FollowUnfollowButton from "../Button/FollowUnfollowButton";
import FavouriteMemeButton from "../Button/FavouriteButton/FavouriteMemeButton";
import { ApiContext } from "../UseContext/ApiCall";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup";
import ImageTag from "../../Common/assets/imageTag";

const Memes = () => {

  const [allMemes, setAllMemes] = useState([])
  const [page, setPage] = useState(1)

  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setPostData] = useState(null);

  const { loggedInUserId } = useContext(ApiContext);

  const { config } = useContext(ApiContext)
 
  document.title = "Emilo-memes"

  useEffect(() => {
    getDataTypePost('memes', 'posttype',"", page, config , 20).then(res => {
      setAllMemes(res)
      setPage(page + 1)
    });
  }, []);

  console.log('allMemes : ', allMemes);

  return (
    <>
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />

      <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
        <div className="explore-sec my-3 my-sm-5 my-lg-5">
          {
            allMemes
            &&
            allMemes.map((post, index) => (
              <div className="rounded-4 position-relative cursor-pointer" style={{ height: '200px' }} onClick={() => { setShowPostsPopup(true); setPostId(post._id); setPostData(post); }} key={index}>
                {
                  post.mediaUrls[0].filetype === 'image'
                  ?
                  <ImageTag url={post.mediaUrls[0].url} classHere="h-100 w-100 rounded-4 object-fit-cover shadow-sm" altHere="one" />
                  :
                  <ImageTag url={post.postTumbnailUrl} classHere="h-100 w-100 rounded-4 object-fit-cover shadow-sm" altHere="two" />
                  // <ReactPlayer url={AwsFileUrl+post.postTumbnailUrl} className='h-100 w-100 rounded-4 object-fit-cover shadow-sm' playing={true} />
                }

                <div className="px-2 pb-1 rounded-3 position-absolute top-0 end-0 mt-3 me-3" style={{ backgroundColor: '#e2e3e591' }}>
                  <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="post" />
                </div>

                <div className="hover-info position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex align-items-center justify-content-center">
                  <span className="me-2">
                    <img src={`${BASE_URL_STATIC}/explore-icon4.png`} className="me-1" alt="Views" />
                    <span className="text-white">{post._id && post.viewcount}</span>
                  </span>

                  <span className="me-2">
                    <img src={`${BASE_URL_STATIC}/explore-icon5.png`} className="me-1" alt="Likes" />
                    <span className="text-white">{post._id && post.likecount}</span>
                  </span>

                  <span className="me-2">
                    <img src={`${BASE_URL_STATIC}/explore-icon6.png`} className="me-1" alt="Comments" />
                    <span className="text-white">{post._id && post.commentcount}</span>
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </section>


      {/* Old Code */}
      {/* <section className="memes">
        <section className={`section-main ${className || ""}`}>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-12">
                <div className="center-block">
                  <div className="explore-block">
                    <div className="row no-gutters">
                      <div className="masonary">
                        {
                          allMemes
                          &&
                          allMemes.map((files, index) => (
                           
                            <div key={index} className="imgBox img_box">
                              {files.mediaUrls && files.mediaUrls[0] && (
                                <div>
                                  {files.mediaUrls[0] && files.mediaUrls[0].filetype === 'image' && (
                                    <img className="grid-wrap" src={AwsFileUrl + files.mediaUrls[0].url} alt="meme" onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-4.png`} />
                                  )}
                                  {files.mediaUrls[0] && files.mediaUrls[0].filetype === 'video' && (
                                    <video className="grid-wrap">
                                      <source src={AwsFileUrl + files.mediaUrls[0].url} />
                                    </video>
                                  )}
                                </div>
                              )}

                              {
                                files.mediaUrls.length === 1
                                ?
                                <div className="iconBox">
                                  <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="memes" />
                                </div>
                                :
                                <div className="iconBox">
                                  <img src={`${BASE_URL_STATIC}/icon/white-posts.svg`} alt="memes" />
                                </div>
                              }

                              <div className="likeBox"  onClick={() =>{setShowPostsPopup(!showPostsPopup);  setPostId(files._id); setPostData(files)}}>
                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon4.png`} alt="" />
                                {files._id && files.viewcount}

                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon5.png`} alt="" />
                                {files._id && files.likecount}
                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon6.png`} alt="" />
                                {files._id && files.commentcount}

                              </span>
                              </div>
                            </div>
                          ))
                        }
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <BackToTop />
      </section> */}


      {/* Mobile Memes */}
      <section className="mobile-memes">
        <div className="mm-memes">
          {
            allMemes
            &&
            allMemes.map((memes, index) => (
             
             
              <div className="mm-meme" key={index}>
                {/* User */}
                <div className="mmm-user">
                  <div className="mmm-user-profile">
                    <img src={`${BASE_URL_STATIC}/user-icon${index + 1}.jpg`} alt="profile" />
                  </div>

                  <div className="mmm-user-header">
                    <div className="mmm-user-header-1">
                      <span className="mmm-user-name">{memes.fname}</span>
                      <span className="mmm-user-activity">Activity</span>
                    </div>

                    <div className="mmm-user-header-2">
                      <span className="mmm-user-location">Location</span>
                      <span className="mmm-user-time">
                        <PostTime date={memes.createdTime} />
                      </span>
                    </div>
                  </div>

                  <div className="mmm-user-fubtn">
                    {
                      memes.userId !== loggedInUserId
                      &&
                      <FollowUnfollowButton otherUserId={memes.userId} />
                    }
                  </div>

                  <div className="mmm-user-threeDot">
                    <i className="far fa-ellipsis-v" />
                  </div>
                </div>

                {/* Show */}
                <div className="mmm-show">
                  <img src={`${BASE_URL_STATIC}/cp-${index + 1}.png`} alt="show" />
                </div>

                {/* Area */}
                <div className="mmm-area">
                  <div className="mmm-area-view">
                    {
                      memes.viewcount === 0
                      ?
                      <img src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
                      :
                      <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
                    }
                    <span>{memes.viewcount}</span>
                  </div>
                  

                  <div className="mmm-area-like" >
                    <img src={`${BASE_URL_STATIC}/explore-icon5.png`} alt="" />
                    <span>{memes.likecount}</span>

                  </div>

                  <div className="mmm-area-comment">
                    <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="view" />
                    <span>{memes.commentcount}</span>
                  </div>

                  <div className="mmm-area-share">
                    <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="view" />
                    <span>{memes.sharecount}</span>
                  </div>

                  <div className="mmm-area-favourite">
                    <FavouriteMemeButton memeId={memes.memeId} />
                  </div>

                  <div className="mmm-area-download">
                    <img src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="view" />
                  </div>
                </div>

                {/* Description */}
                {
                  memes.description
                  &&
                  <div className="mmm-description">
                    <span>{memes.description}</span>
                  </div>
                }

                <hr className="mm-meme-hr" />
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
}

export default Memes;
