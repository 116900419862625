import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import PostTime from '../../../Home/CenterBlock/CenterBlockPosts/PostTime';
import Slider from 'react-slick';
import ProfileBestClipThreeDotPopup from './ThreeDot/ProfileBestClipThreeDotPopup';
import ReactPlayer from 'react-player';

import ProfileBestClipCommentThreeDotPopup from './CommentThreeDot/ProfileBestClipCommentThreeDotPopup';
import { ApiContext } from '../../../UseContext/ApiCall';
import axios from 'axios';
import LikeBestClipButton from '../../../Button/LikeButton/LikeBestClipButton';
import { BASE_URL_STATIC } from '../../../../../config';
import FavouriteBestClipButton from '../../../Button/FavouriteButton/FavouriteBestClipButton';
import AddComments from '../../../Button/AddComments';

const ProfileBestClipPopup = ({ showProfileBestClipPopup, bestclipId , setShowProfileBestClipPopup, profileBestClipPopupData, setprofileBestClipPopupData }) => {

  const [showProfileBestClipThreeDotPopup, setShowProfileBestClipThreeDotPopup] = useState(false);
  const [showProfileBestClipCommentThreeDotPopup, setShowProfileBestClipCommentThreeDotPopup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);


   //------------------- comment states-----------------
  //const [postId, setPostId] = useState('') // memeID , bestClipId , reelId
  const [clickedreply, setClickedReply] = useState(false)
  const [viewReply, setViewReply] = useState(false)
  const [comment, setComment] = useState();
  const [comments, setComments] = useState([]);
  const [parentSpinner, setParentSpinner] = useState(false)

  const { config } = useContext(ApiContext);

  // To get/update comment of post
  const getBestClipComment = () => {
    axios.post('https://emilonode.sjain.io/post/getpostcomment', { type:"bestclip" ,  bestclipId }, config )
    .then(res => {
      console.log("comment list: " , res.data.commentList);
      setComments(res.data.commentList);
    })
    .catch(err => {
      console.log(err);
    });
  };
  
  useEffect(() => {
    if(profileBestClipPopupData) {
      setClickedReply(false)
      setViewReply(false)
      getBestClipComment()
    };
  }, [profileBestClipPopupData , comment]);

  const settings = {
    dots: true,
    className: "profileBestClipPopup-bestClip-slider",
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
    afterChange: (index) => setActiveIndex(index),
  };

  return (
    <>
      {/* Profile Best Clip Three Dot Popup */}
      <ProfileBestClipThreeDotPopup showProfileBestClipThreeDotPopup={showProfileBestClipThreeDotPopup} setShowProfileBestClipThreeDotPopup={setShowProfileBestClipThreeDotPopup} setShowProfileBestClipPopup={setShowProfileBestClipPopup} profileBestClipPopupData={profileBestClipPopupData} setprofileBestClipPopupData={setprofileBestClipPopupData} />

      {/* Profile Best Clip Comment Three Dot Popup */}
      <ProfileBestClipCommentThreeDotPopup showProfileBestClipCommentThreeDotPopup={showProfileBestClipCommentThreeDotPopup} setShowProfileBestClipCommentThreeDotPopup={setShowProfileBestClipCommentThreeDotPopup} comment={comment} getBestClipComment={getBestClipComment} />

      <Modal show={showProfileBestClipPopup} onHide={() => setShowProfileBestClipPopup(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            profileBestClipPopupData
            &&
            <section className='profileBestClipPopup'>
              {/* Best Clip */}
              <section className='profileBestClipPopup-bestClips'>
                <Slider {...settings} >
                  {
                    profileBestClipPopupData
                    &&
                    profileBestClipPopupData.details.map((details, index) => (
                      <div key={index} className="profileBestClipPopup-bestClip">
                        <ReactPlayer
                            url={`https://emilonode.sjain.io/post/getvideobyid?bestclipVideoId=${details.videoId}`} className="profileBestClipPopup-bestClip-video"
                            playing={index === activeIndex}
                            controls
                          />
                      </div>
                    ))
                  }
                </Slider>
              </section>

              {/* Details */}
              <section className='profileBestClipPopup-details'>
                {/* user */}
                <div className='profileBestClipPopup-details-user'>
                  {
                    profileBestClipPopupData.profileImg
                    ?
                    <img className='profileBestClipPopup-details-profile' src={profileBestClipPopupData.profileImg} alt={profileBestClipPopupData.bestclipId} height={50} width={50} />
                    :
                    <img className='profileBestClipPopup-details-profile' src={`${BASE_URL_STATIC}/dummy.png`} alt='profile' height={50} width={50} />
                  }

                  <div className='profileBestClipPopup-details-header'>
                    <div className='profileBestClipPopup-details-head'>
                      <span className='profileBestClipPopup-details-name'>{profileBestClipPopupData.fname}</span>
                      
                      {
                        (profileBestClipPopupData.activityEmoji !== 'undefined' && profileBestClipPopupData.activityName !== 'undefined')
                        &&
                        <span className='profileBestClipPopup-details-activity'>{profileBestClipPopupData.activityEmoji} {profileBestClipPopupData.activityName}</span>
                      }

                      {
                        (profileBestClipPopupData.feelingEmoji !== 'undefined' && profileBestClipPopupData.feelingName !== 'undefined')
                        &&
                        <span className='profileBestClipPopup-details-activity'>{profileBestClipPopupData.feelingEmoji} {profileBestClipPopupData.feelingName}</span>
                      }
                    </div>

                    {
                      profileBestClipPopupData.fname
                      &&
                      <span className='profileBestClipPopup-details-location'>{profileBestClipPopupData.title}</span>
                    }

                    <span className='profileBestClipPopup-details-time'>
                      <PostTime date={profileBestClipPopupData.createdTime} />
                    </span>
                  </div>

                  <img className='profileBestClipPopup-details-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" onClick={() => setShowProfileBestClipThreeDotPopup(true)} />
                </div>

                <hr className='profileBestClipPopup-details-hr' />

                <AddComments postId={bestclipId} setComment={setComment} commentType="bestclip"  setShowProfilePostCommentThreeDotPopup={setShowProfileBestClipCommentThreeDotPopup} clickedreply = {clickedreply} setClickedReply = {setClickedReply} viewReply={viewReply}  postsPopupData={profileBestClipPopupData} comments={comments} setComments={setComments} parentSpinner={parentSpinner} setParentSpinner={setParentSpinner}/>
               

                <hr className='profileBestClipPopup-details-hr' />

                {/* area */}
                <div className='profileBestClipPopup-details-area'>
                  <div className='profileBestClipPopup-details-area-div'>
                    {
                      (profileBestClipPopupData.viewCount === 0)
                      ?
                      <img className='profileBestClipPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="like" height={20} width={30} />
                      :
                      <img className='profileBestClipPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="like" height={20} width={30} />
                    }
                    <span className='profileBestClipPopup-details-area-count'>{profileBestClipPopupData.viewCount}</span>
                  </div>

                  <div className='profileBestClipPopup-details-area-div'>
                    <LikeBestClipButton bestClipId={profileBestClipPopupData.bestclipId} />
                  </div>

                  <div className='profileBestClipPopup-details-area-div'>
                    <img className='profileBestClipPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" />
                    <span className='profileBestClipPopup-details-area-count'>{profileBestClipPopupData.commentCount}</span>
                  </div>

                  <div className='profileBestClipPopup-details-area-div'>
                    <img className='profileBestClipPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" />
                    <span className='profileBestClipPopup-details-area-count'>{profileBestClipPopupData.shareCount}</span>
                  </div>

                  <div className='profileBestClipPopup-details-area-favourite'>
                    <FavouriteBestClipButton bestClipId={profileBestClipPopupData.bestclipId} />
                  </div>


                  <img className='profileBestClipPopup-details-area-download' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="star" />
                </div>

                {/* description */}
                <div className='profileBestClipPopup-details-description'>{profileBestClipPopupData.description}</div>
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileBestClipPopup;
