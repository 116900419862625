import React, { useContext } from 'react';
import { BASE_URL_STATIC } from '../../../../../../config';
import Modal from 'react-bootstrap/Modal';
import { settingContext } from '../../../../UseContext/SettingContext';
import { ApiContext } from '../../../../UseContext/ApiCall';


const StoryAdvanceSettingPopup = () => {

  const { 
    showStoriesAdvanceSettingPopup, setShowStoriesAdvanceSettingPopup, 
    storiesAdvanceSetting, setStoriesAdvanceSetting,
  } = useContext(ApiContext);

  const { value } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };

  return (
    <>
      <Modal show={showStoriesAdvanceSettingPopup} onHide={() => setShowStoriesAdvanceSettingPopup(false)} centered backdrop="static" keyboard={false} className="create-call create-stories-popup" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <div className='row mb-4 mx-auto'>
              <span className='col-1 px-0 cursor-pointer' onClick={() => setShowStoriesAdvanceSettingPopup(false)}>⮜</span>
              <span className='col-10 px-0 text-center fs-5 fw-semibold'>Advance Setting</span>
            </div>

            <div>
              {/* Audience Control */}
              <div className='mb-3'>
                <div className='accordion-level-3 shadow-sm accordion-button p-2 rounded-3' style={{ backgroundColor: '#f0f0f0' }} data-bs-toggle="collapse" data-bs-target="#story-audienceControl">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} style={{ height: '23px' }} alt="audience-control" />
                  <h6 className='fw-semibold ms-3 mb-0'>Audience Control</h6>
                </div>

                <div id='story-audienceControl' className="accordion-collapse collapse show accordion accordion-flush mt-3 ms-3">
                  {/* Value */}
                  <div className='row mx-auto'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-storyView-public" name="visibility" value='public' defaultChecked
                        checked={storiesAdvanceSetting.visibility === 'public'} 
                        onChange={(e) => setStoriesAdvanceSetting(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                      />
                      <label className="form-check-label" htmlFor="ps-storyView-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-storyView-followers" name="visibility" value='followers' 
                        checked={storiesAdvanceSetting.visibility === 'followers'} 
                        onChange={(e) => setStoriesAdvanceSetting(prev => ({ ...prev, [e.target.name]: e.target.value }))} 
                      />
                      <label className="form-check-label" htmlFor="ps-storyView-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-storyView-follower" name="visibility" value='cf' 
                        checked={storiesAdvanceSetting.visibility === 'cf'} 
                        onChange={(e) => setStoriesAdvanceSetting(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                      />
                      <label className="form-check-label" htmlFor="ps-storyView-follower">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-storyView-fof" name="visibility" value='fof' 
                        checked={storiesAdvanceSetting.visibility === 'fof'} 
                        onChange={(e) => setStoriesAdvanceSetting(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                      />
                      <label className="form-check-label" htmlFor="ps-storyView-fof">Followers of Followers</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2'>({message[value.storyView ? value.storyView : 'public']} view your stories)</small>
                  </div>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                </div>
              </div>

              {/* Story Expiry Time */}
              <div className='mb-3'>
                <div className='accordion-level-3 shadow-sm accordion-button p-2 rounded-3' style={{ backgroundColor: '#f0f0f0' }} data-bs-toggle="collapse" data-bs-target="#story-expiry-time">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} style={{ height: '23px' }} alt="audience-control" />
                  <h6 className='fw-semibold ms-3 mb-0'>Story Expiry Time</h6>
                </div>

                <div id='story-expiry-time' className="accordion-collapse collapse show accordion accordion-flush mt-3 ms-3 mb-0">
                  {/* Value */}
                  <div className='row mx-auto'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input type="radio" className="form-check-input" name="storyExpireTime" id="ps-storyExpiryTime-24"
                        checked={storiesAdvanceSetting.expire === '24'} 
                        onChange={() => setStoriesAdvanceSetting(prev => ({ ...prev, expire: '24' }))}
                      />
                      <label className="form-check-label" htmlFor="ps-storyExpiryTime-24">24 Hours</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input type="radio" className="form-check-input" name="storyExpireTime" id="ps-storyExpiryTime-48"
                        checked={storiesAdvanceSetting.expire === '48'} 
                        onChange={() => setStoriesAdvanceSetting(prev => ({ ...prev, expire: '48' }))}
                      />
                      <label className="form-check-label" htmlFor="ps-storyExpiryTime-48">48 Hours</label>
                    </div>
                  </div>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                </div>
              </div>

              {/* Likes */}
              <div className='mb-2'>
                <span>Likes</span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='like' 
                    checked={storiesAdvanceSetting.like === 'on'} 
                    onChange={(e) => setStoriesAdvanceSetting((prev) => ({ ...prev, [e.target.name]: e.target.checked ? 'on' : 'off' }))}
                  />
                </div>
              </div>

              {/* Comments */}
              <div className='mb-3'>
                <span>Comments</span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='comment' 
                    checked={storiesAdvanceSetting.comment === 'on'} 
                    onChange={(e) => setStoriesAdvanceSetting((prev) => ({ ...prev, [e.target.name]: e.target.checked ? 'on' : 'off' }))}
                  />
                </div>
              </div>

              {/* Save */}
              <div className='text-center'>
                <button type="button" className='btn btn-primary rounded-pill w-50' onClick={() => setShowStoriesAdvanceSettingPopup(false)}>Save</button>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal> 
    </>
  )
}

export default StoryAdvanceSettingPopup
