import React from "react";
import ProfileUser from '../ProfileUser'
import ProfileUserEditMain from "./ProfileUserEditMain";
import BackToTop from "../../../Helper/BackToTop";

const ProfileUserEdit = () => {
  
  return (
    <>
      <ProfileUser />
      <ProfileUserEditMain />
      <BackToTop />
    </>
  );
};

export default ProfileUserEdit;
