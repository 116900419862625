import React from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../../config';

const CallRigthBlock = ({ callRbUser }) => {

  const { chatUserId } = useParams();

  console.log(callRbUser);

  const userData = [
    {
      id: 101,
      name: 'Siddharth',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 14 Feb 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 102,
      name: 'Avinash Chandrakar',
      img1: `/images/icon/come-connect.svg`,
      time: '08:10, 18 Mar 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 103,
      name: 'Mithlesh',
      img1: `/images/icon/go-connect.svg`,
      time: '07:40, 18 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 104,
      name: 'Ranjeet Das',
      img1: `/images/icon/come-connect.svg`,
      time: '08:50, 19 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 105,
      name: 'Soumith Shetty',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 21 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 106,
      name: 'Abhishek Bhoir',
      img1: `/images/icon/come-connect.svg`,
      time: '10:20, 22 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 107,
      name: 'Amit Gajbhiye',
      img1: `/images/icon/go-connect.svg`,
      time: '08:20, 23 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 108,
      name: 'Umesh Mahobia',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 24 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 101,
      name: 'Siddharth',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 14 Feb 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 102,
      name: 'Avinash Chandrakar',
      img1: `/images/icon/come-connect.svg`,
      time: '08:10, 18 Mar 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 103,
      name: 'Mithlesh',
      img1: `/images/icon/go-connect.svg`,
      time: '07:40, 18 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 104,
      name: 'Ranjeet Das',
      img1: `/images/icon/come-connect.svg`,
      time: '08:50, 19 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 105,
      name: 'Soumith Shetty',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 21 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 106,
      name: 'Abhishek Bhoir',
      img1: `/images/icon/come-connect.svg`,
      time: '10:20, 22 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
    {
      id: 107,
      name: 'Amit Gajbhiye',
      img1: `/images/icon/go-connect.svg`,
      time: '08:20, 23 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/voice-call.svg`,
    },
    {
      id: 108,
      name: 'Umesh Mahobia',
      img1: `/images/icon/go-connect.svg`,
      time: '06:20, 24 Apr 2024',
      img2: `${BASE_URL_STATIC}/icon/video-call.svg`,
    },
  ];

  return (
    <>
      <section className='chats-rb-call'>
      {
          chatUserId
          ?
          <div className='border border-primary position-relative' style={{ height: '90vh' }}>
            {/* Header */}
            <div className='bg-white p-2 p-lg-3 d-flex align-items-center'>
              <img className='ch-profile rounded-circle' src={callRbUser.profileImg} alt="profile" />

              <div className='col-6 col-sm-7 col-lg-6 col-xl-7 px-0 ms-2 ms-lg-3 me-auto'>
                <h6 className='text-dark text-nowrap overflow-hidden text-truncate fw-bold'>{callRbUser.name}</h6>

                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate, odio.</small>
              </div>

              <img className='ch-vc-icon p-2 rounded-3 bg-body-secondary mx-1 mx-sm-3 mx-lg-2 mx-xl-3' src={`${BASE_URL_STATIC}/icon/voice-call.svg`} alt="voice" />

              <img className='ch-vc-icon p-2 rounded-3 bg-body-secondary mx-1 mx-sm-3 mx-lg-2 mx-xl-3' src={`${BASE_URL_STATIC}/icon/video-call.svg`} alt="video" />
            </div>

            {/* Box */}
            <div className='p-2 p-lg-3 overflow-scroll scrollbar-hidden' style={{ height: '0%' }}>
              {
                userData.map((data, index) => (
                  <div className='d-flex align-items-center mb-3'>
                    <img className='ch-profile rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />

                    <div className='ms-2 ms-lg-3 me-auto'>
                      <h6 className='d-inline me-3 fw-medium'>{data.name}</h6>

                      <img className='call-rb-box-arrow-img' src={data.img1} alt="call" />

                      <small className='d-block text-secondary'>{data.time}</small>
                    </div>

                    <img className='call-rb-box-call-img rounded-3 bg-body-secondary mx-1 mx-sm-2 mx-xl-3' src={data.img2} alt="call" />

                    <i className="far fa-ellipsis-v mx-1 mx-sm-2 mx-xl-3" />
                  </div>
                ))
              }
            </div>
          </div>
          :
          <div className='chats-rb-chat-f'>
            <img className='chats-rb-chat-f-img' src={`${BASE_URL_STATIC}/chat-bg.jpg`} alt="chat background" />
          </div>
        }
      </section> 
    </>
  )
}

export default CallRigthBlock
