import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserFavPosts } from "../../Emilo/UseContext/ScApiHelpers";
import { ScApiContext } from "../useContext/softCornerContext";
import { Hearts } from "react-loader-spinner";
import { BASE_URL_STATIC } from "../../../config";
import GallaryPopup from "./GallaryPopup";


const GallaryFavrouites = () => {
  const [favPhotos, setFavPhotos] = useState([]);
  const { scConfig } = useContext(ScApiContext)
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)



  useEffect(() => {
    setSpinner(true)
    getUserFavPosts(scConfig).then((res) => {
      setFavPhotos(res);
      setSpinner(false)
    });


  }, []);

  return (
    <>
      {data && <GallaryPopup open={openPopup} data={data} close={() => setOpenPopup(false)} />}
      <section className="sc-gallary" style={{ flexDirection: "column" }}>
        <section>
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`}
                alt="sc-gallary-photos"
                height={30}
                width={30}
              />
              <span>Photos</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Album`} className="sc-gallary-nav-div">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`}
                alt="sc-gallary-album"
                height={30}
                width={30}
              />
              <span>Album</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Explore`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`}
                alt="sc-gallary-explore"
                height={30}
                width={30}
              />
              <span>Explore</span>
            </Link>
          </div>
        </section>

        <h2 style={{ margin: "20px 50px" }}>Favourites</h2>

        <section>
          {spinner ? <Hearts
            height="150"
            width="150"
            color="#ED1E24"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass="gallary-loader"
            visible={true}

          /> : (
            favPhotos && favPhotos.length > 0 && (
              <div className="sc-gallary-older">
                <div className="older-posts">
                  {favPhotos.reverse().map((post, index) => (
                    <span onClick={() => { setOpenPopup(true); setData(post) }} key={index} className="older-posts-span">
                      {post.details && post.details[0].videoId && (
                        <video >
                          <source
                            src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${post.details[0].videoId}`}
                          />
                        </video>
                      )}
                      {post.details && post.details[0].imageId && (
                        <img src={post.details[0].imageUrl} alt="" />
                      )}
                    </span>
                  ))}
                </div>
              </div>
            ))}

        </section>
      </section>
    </>
  );
};

export default GallaryFavrouites;
