import React, { useContext, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import { ApiContext } from '../../UseContext/ApiCall';

const TextPostPopup = () => {

  const editor = useRef(null);

  const { PageContent, setPageContent } = useContext(ApiContext);

  const config = useMemo(() => ({
    toolbarButtonSize: "small",
    readonly: false,
    height: 200,
    toolbarSticky: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbarInlineForSelection: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttons: [
      'undo', 'redo', 'bold', 'italic', 'underline',
      'font', 'fontsize', 'brush', 'paragraph',
      'ul', 'ol', 'center', 'right', 'justify',
    ],
  }), []);  // useMemo ensures config doesn't change on every render

  return (
    <>
      <div className='story-text-editor'>
        <JoditEditor
          ref={editor}
          value={PageContent}
          config={config}
          tabIndex={1}
          onChange={value => setPageContent(value)}
        />
      </div>
    </>
  );
};

export default TextPostPopup;
