import React from 'react'
import Notifications from './Notifications'
import TrendingRightBlock from '../Home/RightBlock/TrendingRightBlock'

const NotificationBody = () => {
    return (
        <div className='row'>
            <div className='col-12 col-lg-7'>
                <Notifications />
            </div>
            <div className='col-lg-5 p-0'>
               <TrendingRightBlock/>
            </div>
        </div>
    )
}

export default NotificationBody