import React from "react";
import BackToTop from "../Helper/BackToTop";
// import SectionMain from "./SectionMain";
import CenterBlock from "./CenterBlock/CenterBlock";
import RightBlock from "./RightBlock/RightBlock";

const Home2 = ({ className }) => {

  return (
    <>
      <section>
        <section className={`section-main ${className || ""}`}>
          <div className="container-fluid p-0">
            <div className="row no-gutters ">
              <div className="col-lg-7">
                <CenterBlock />
              </div>
              <div className="col-lg-5">
                <RightBlock />
              </div>
            </div>
          </div>
        </section>

        <BackToTop />
      </section>
    </>
  );
};

export default Home2;
