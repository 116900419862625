import React, { useContext, useEffect, useState } from "react";
import { clearChat, getPartnerImage } from "../../Emilo/UseContext/ScApiHelpers";
import { BASE_URL_STATIC, BASEURL } from "../../../config";
import toast from "react-hot-toast";
import PostTime from "../../Emilo/Home/CenterBlock/CenterBlockPosts/PostTime";
import { ScApiContext } from "../useContext/softCornerContext";
import { Link } from "react-router-dom";



const ScChatHeader = ({ setSpinner, setArrivalMsg, onlineStatus }) => {

  const [showThreeDotPopup, setShowThreeDotPopup] = useState(false);

  const [image, setImage] = useState(null);

  // console.log("asda", onlineStatus);

  const scConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("SCtoken")}`,
    },
  };

  const { chatId } = useContext(ScApiContext);

  useEffect(() => {
    getPartnerImage(scConfig).then((res) => {
      setImage(res);
    });
    
  }, [chatId]);

  const handleDelete = () => {
    setSpinner(true);
    clearChat(chatId, scConfig)
      .then((res) => {
        toast.success(res);
        setSpinner(false);
        setArrivalMsg([]);
      })
      .catch(() => setSpinner(false));
  };

  

  return (
    <>
  
      <section className="sc-chat-header position-fixed bg-white z-1 d-flex align-items-center shadow-sm px-3 px-sm-4 py-2">
        {/* Back */}
        <Link to={`${BASEURL}/SoftCorner`}>
          <img className="chat-rb-header-previous" src="https://demo3.sjainventures.com/e-milo/images/icon/previous.svg" alt="back" />
        </Link>

        {/* Profile and Online Dot */}
        <div className="position-relative rounded-circle mx-2 mx-sm-3">
          {
            image && image.partnerImageUrl 
            ? 
            <img src={image.partnerImageUrl} className="ch-profile rounded-circle" alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/dummy.png`} />
            : 
            <img src={`${BASE_URL_STATIC}/dummy.png`} className="ch-profile rounded-circle" alt="dummy" />
          }

          {/* Online Dot */}
       {onlineStatus && onlineStatus.scOnlineStatus && onlineStatus.scOnlineStatus === "online" &&  <span className="position-absolute" style={{ fontSize: '9px', right: '0' }}>🟢</span>}
        </div>

        {/* Name and online/offline */}
        <div className="col-5 col-sm-6 col-lg-5 col-xl-6 px-0 me-auto">
          <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{image && image.fname}</h6>

          {
            onlineStatus && onlineStatus.scOnlineStatus && onlineStatus.scOnlineStatus === "online" 
            ?
            <small className="text-success p-0">Online</small>
            :
            <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">last seen at{" "} <PostTime date={onlineStatus && onlineStatus.lastOnlineTime} />{" "} ago</small>
          }
        </div>
<div className="d-flex justify-content-end gap-4"> 

        {/* Audio */}
        <img src="https://demo3.sjainventures.com/e-milo/images/icon/voice-call.svg" alt="audio" />

        {/* Video */}
        <img src="https://demo3.sjainventures.com/e-milo/images/icon/video-call.svg" className="mx-auto" alt="video" />

        {/* Three Dot */}
        <img src="https://demo3.sjainventures.com/e-milo/images/icon/threeDot.svg" onClick={() => setShowThreeDotPopup(!showThreeDotPopup)} alt="threedot" />

</div>
        {/* Three Dot Popup */}
        <>
          {
            showThreeDotPopup
            &&
            <div className="border boder-primary position-absolute d-flex flex-column p-3 rounded-3 shadow-sm bg-white" style={{ top: '65px', right: '5px', gap: '10px' }}>
              {/* <span >Clear Chat</span> */}
              <span onClick={handleDelete}>Clear Chat</span>
              <span>More...</span>
              <span>More...</span>
              <span>More...</span>
              <span>More...</span>
            </div>
          }
        </>

        {/* <span>{checkOn.onoroff}</span> */}
        {/* <span>{checkOn && checkOn.onoroff === "offline" && checkOn.lastOnlineTime}</span> */}




     
      </section>

    

   

 
    </>
  );
};

export default ScChatHeader;
