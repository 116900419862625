

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const OnboardingMobile = () => {

  const [mobile, setMobile] = useState('');

  return (
    <>
      <section className='onboardingMobile'>
        <div className='onboardingMobile-area'>
          {/* Emilo Logo */}
          <div className='onboardingMobile-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingMobile-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingMobile-area-data'>
            <h3 className='onboardingMobile-area-data-head fw-bold'>Enter your Mobile?</h3>

            <div className='onboardingMobile-area-data-input'>
              <form>
                <p className='onboardingMobile-area-data-input-in'>IN +91</p>

                <label htmlFor="mobile">
                  <input type="tel" name="mobile" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='12345 12345' />
                </label>

                <p className='onboardingMobile-area-data-input-sendOTP' onClick={() => localStorage.setItem('mobile', mobile)}>Send OTP</p>
              </form>
            </div>

            <p className='onboardingMobile-area-data-message'>You will receive an OTP on your mobile number For verification.</p>
          </div>

          {/* Button */}
          <div className='onboardingMobile-area-button'>
            <Link to={`/SoftCorner/OnboardingOTP`}>
              <button type="button" className='onboardingMobile-area-btn' onClick={() => localStorage.setItem('mobile', mobile)}>Start</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingMobile;
