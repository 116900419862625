import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const NotificationpostPopup = ({show, setShow, errorMsg}) => {
 

  const handleClose = () => setShow(false);
 
  return (
    <>
     
      <Modal show={show} onHide={handleClose} className="main-modal">
      
        <Modal.Body>
          <img src="\images\DemoImage\warnningx.png" alt="warning" className='pe-5 ps-5' />
          <div className='text-center'>
            <h5>Your account has been restricted for posting</h5>
       
          </div>
          <div className="p-3 text-dark bg-light border border-light rounded-3">
          {errorMsg}
          </div>
          <div className="text-center mt-3">
            <Button variant="primary" size="lg"  onClick={handleClose}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationpostPopup;
