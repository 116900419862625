import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ScApiContext } from '../useContext/softCornerContext';
import { delete_me_Message } from '../../Emilo/UseContext/ScApiHelpers';


const ScDeleteHeader = ({ selectedMessages, setSelectedMessages,  setArrivalMsg }) => {

   const {scConfig } = useContext(ScApiContext);

   const handleDelete = () => {
      selectedMessages.forEach((messageId, i) => {
            delete_me_Message(messageId, scConfig).then(() => {
               setSelectedMessages([]);
               setArrivalMsg((prev => prev.filter(msg => (msg.messageId || msg.replyMessageId) !== messageId)))
           })
      });
   };
   


   return (
      <div className='scChatHeader flex' style={{ padding: "20px 30px" }}>
         <Button onClick={() => handleDelete()} style={{ backgroundColor: "#ff6969" }} variant="danger">Delete ({selectedMessages.length}) </Button>

         <span onClick={() => setSelectedMessages([])}><FontAwesomeIcon icon={faClose} style={{ fontSize: "20px" }} color='gray' /></span>
      </div>
   )
}

export default ScDeleteHeader