import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const OnboardingOTP = () => {

  const [OTP, setOTP] = useState('');

  return (
    <>
      <section className='onboardingOTP'>
        <div className='onboardingOTP-area'>
          {/* Emilo Logo */}
          <div className='onboardingOTP-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingOTP-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingOTP-area-data'>
            <h2 className='onboardingOTP-area-data-head'>Code Verification</h2>

            <p className='onboardingOTP-area-data-message'>Enter One Time Password Sent to Your phone number +91 12345 12345</p>

            <OtpInput
              value={OTP}
              onChange={setOTP}
              numInputs={4}
              renderSeparator={<pre> </pre>}
              renderInput={(props) => <input {...props} className='onboardingOTP-area-data-inputOTP' style={{ width: '70px' }} />}
            />
          </div>

          {/* Button */}
          <div className='onboardingOTP-area-button'>
            <Link to={`/SoftCorner/OnboardingImage`}>
              <button type="button" className='onboardingOTP-area-btn'>Verify OTP</button>
            </Link>
          </div>

          <p className='onboardingOTP-area-resendOTP'>Didn’t get the OTP? <span>Resend OTP in 01:00</span></p>
        </div>
      </section>
    </>
  );
};

export default OnboardingOTP;
