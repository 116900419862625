import React from 'react';
import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { Chart } from "react-google-charts";


const Check = ({ className }) => {

  const data = [
    ["Year", "Sales", "Expenses"],
    ["2013", 1000, 400],
    ["2014", 1170, 460],
    ["2015", 660, 1120],
    ["2016", 1030, 540],
  ];

  const options = {
    title: "Company Performance",
    hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "50%", height: "70%" },
  };

  return (
    <>
      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-4 text-center fw-bold'>Check</h3>
              
              <Chart
                chartType="LineChart"
                width="500px"
                height="500px"
                data={data}
                options={options}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Check;