import React, { useRef, useEffect, useContext, useState, useMemo } from "react";
import { ApiContext } from "../UseContext/ApiCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { AwsFileUrl, BASEURL, BASE_URL_STATIC } from "../../../config";
import FollowUnfollowButton from "./FollowUnfollowButton";
import { postEvent } from "../UseContext/ApiHelpers";
// import ReactPlayer from "react-player";


function ViewPosts({ post,showPostsPopup, mediaTags, imageSrc, fileType, vidSrc, trendingfeed }) {

  const refObs = useRef();
  const videoRef = useRef();
  const { config } = useContext(ApiContext);
  const [tagOpen, setTagOpen] = useState(false);
  const [displayTag, setDisplayTag] = useState(false);

  const handleVisibilityChange = async () => {
    postEvent(post._id, "view", post.postedBy, "post", config)
  };

  useMemo(() => {
    if(showPostsPopup){
      videoRef && videoRef.current && videoRef.current.pause();
    }
  }, [showPostsPopup]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handleVisibilityChange(post._id);
            // Play the video when it becomes visible
            if (videoRef.current) {
              videoRef.current.play();
            }
          } else {
            // Pause the video when it goes out of view
            if (videoRef.current) {
              videoRef.current.pause();
            }
          }
        });
      },
      {
        root: null,
        threshold: 0.5, // You can adjust the threshold as needed
      }
    );

    if (refObs.current) {
      observer.observe(refObs.current);
    }

    return () => {
      if (refObs.current) {
        observer.unobserve(refObs.current);
      }
    };
  }, [post._id]);

  function handleShowTag() {
    setDisplayTag(!displayTag);
  };

  return (
    <div className="feedBox viewPost"  id={post._id} ref={refObs}>
      {fileType === "image" && (
        <div style={{ position: "relative", overflowX: "hidden" }}>

          {post.type !== 'text' && post.type === "location" ?
            <Link className="bg-white rounded-2 d-flex flex-column flex-lg-row gap-2 align-items-center p-2" target='_blank' to={`https://maps.google.com/maps?q=${post.lat}%2C${post.lng}&z=17&hl=en`}>
              <img className="m-0" src={`${AwsFileUrl + imageSrc}`} alt="post" onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-1.png`} style={{ maxWidth: "220px" }} />

              <div className="d-flex flex-column  align-items-start text-start">
                <p className="fw-bold">City</p>
                <p className="fs-14">{post.city}</p>

                <br />

                <p className="fw-bold">Place</p>
                <p className="fs-14">{post.place}</p>
              </div>
            </Link>
            :
            <img src={`${AwsFileUrl + imageSrc}`} alt="post" onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-1.png`} width="100%" />
          }

          {/* temporary commented  */}
          {mediaTags && mediaTags.length > 0 && (
            <small className="tagsPost" onClick={handleShowTag}>
              <FontAwesomeIcon className="icon" icon={faUserTag} style={{ color: "#d8d4d6" }} />
            </small>
          )}

          {mediaTags &&
            displayTag && mediaTags.length > 0 &&
            mediaTags.map((tag, index) => (
              <div
                className="displayTags"
                key={index}
                style={{ position: "absolute", top: `${tag.y}%`, left: `${tag.x}%` }}
              >
                <Link to={`${BASEURL}/profile/${tag.tagUserId}`}>
                  <small>{tag.fname}</small>
                </Link>
              </div>
            ))}
        
        </div>
      )}

      {fileType === "video" && (
        <div style={{ position: "relative" }}>
          <video className="bg-dark" ref={videoRef} controls style={{ maxWidth: "100%", maxHeight: "74vh" }}>
            <source
              src={AwsFileUrl + vidSrc}
            />
          </video>

          {mediaTags && mediaTags.length > 0 && (
            <small className="tagsPost" onClick={() => setTagOpen(true)}>
              <FontAwesomeIcon className="icon" icon={faUserTag} style={{ color: "#d8d4d6" }} />
            </small>
          )} 

          <Modal show={tagOpen} onHide={() => setTagOpen(false)} centered backdrop="static" keyboard={false} className="create-call">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {mediaTags &&
                mediaTags.map((tag, index) => (
                  <div
                    className="tagProfilePopUp"
                    key={index}
                  >
                    <Link to={`${BASEURL}/profile/${tag.tagUserId}`}>
                      <img src={tag.profileImg? (AwsFileUrl + tag.profileImg.imageId ) : `${BASE_URL_STATIC}/dummy.png`} alt="profileImage" />
                      <small>{tag.fname}</small>
                    </Link>
                    <div>
                      <FollowUnfollowButton otherUserId={tag.tagUserId} />
                    </div>
                  </div>
                ))}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default ViewPosts;
