import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../../config';

const CallCenterBlock = ({ setCallRbUser }) => {

  let pinnedUsers = [
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '101',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon2.jpg',
      name: 'Aish Roy',
      time: 'now',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
  ];

  let groupUsers = [
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
  ];

  let generalUsers = [
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '101',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon2.jpg',
      name: 'Aish Roy',
      time: 'now',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '101',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon2.jpg',
      name: 'Aish Roy',
      time: 'now',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '102',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon3.jpg',
      name: 'Siddharth Dongre',
      time: '11m',
      msg: 'Lorem ipsum dolor sit amet.'
    },
    {
      userId: '103',
      profileImg: 'https://emilorj.sjain.io/Images/user-icon6.jpg',
      name: 'Avinash Kaif',
      time: 'yesterday',
      msg: 'Lorem ipsum dolor sit amet.'
    },
  ];

  return (
    <>
      <section className='px-2 px-md-3 px-lg-4'>
        {/* Search, filter and icon */}
        <div className='d-flex align-items-center pb-3'>
          <form className='d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2'>
            <img className='mx-2' src={`/images/icon/search.svg`} alt="search" />

            <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id='search' name='search' placeholder='Search' />
          </form>

          <span className="mx-3 mx-lg-4 text-primary">Filter</span>

          <span className=''>
           <i className="far fa-ellipsis-v" />
          </span>
        </div>

        <div className="accordion accordion-flush m-0 overflow-scroll scrollbar-hidden" style={{ height: '75vh' }}>
          {/* Pinned */}
          <div className=''>
            <div className='mb-3'>
              <img src={`${BASE_URL_STATIC}/icon/pinned.svg`} alt="pinned" />
              <span className="ms-2 fw-bold">Pinned</span>
            </div>

            {
              pinnedUsers.map((user, index) => (
                <Link to={`/chats/call/${user.userId}`} className='d-flex align-items-center mb-3' onClick={() => setCallRbUser(user)} key={index}>
                  <img className='chat-cb-profile rounded-circle' src={user.profileImg} alt="profile" />

                  <div className='ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0'>
                    <h6 className='text-dark text-nowrap overflow-hidden text-truncate'>{user.name}</h6>
                    <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">{user.msg}</small>
                  </div>

                  <span className="badge bg-primary rounded-circle ms-2">2</span>

                  <i className="far fa-ellipsis-v mx-2 text-dark"  />
                </Link>
              ))
            }

            <hr className='hr' />
          </div>

          {/* Group */}
          <div className='chats-cb-chat-all'>
            <span className='fw-bold mb-3'>Group</span>

            {
              groupUsers.map((user, index) => (
                <Link to={`/chats/call/${user.userId}`} className='d-flex align-items-center mb-3' onClick={() => setCallRbUser(user)} key={index}>
                  <img className='chat-cb-profile rounded-circle' src={user.profileImg} alt="profile" />

                  <div className='ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0'>
                    <h6 className='text-dark text-nowrap overflow-hidden text-truncate'>{user.name}</h6>
                    <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">{user.msg}</small>
                  </div>

                  <span className="badge bg-primary rounded-circle ms-2">6</span>

                  <i className="far fa-ellipsis-v mx-2 text-dark"  />
                </Link>
              ))
            }

            <hr className='hr' />
          </div>

          {/* General */}
          <div className='chats-cb-chat-all'>
            <span className='fw-bold mb-3'>General</span>

            {
              generalUsers.map((user, index) => (
                <Link to={`/chats/call/${user.userId}`} className='d-flex align-items-center mb-3' onClick={() => setCallRbUser(user)} key={index}>
                  <img className='chat-cb-profile rounded-circle' src={user.profileImg} alt="profile" />

                  <div className='ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0'>
                    <h6 className='text-dark text-nowrap overflow-hidden text-truncate'>{user.name}</h6>
                    <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">{user.msg}</small>
                  </div>

                  <span className="badge bg-primary rounded-circle ms-2">4</span>

                  <i className="far fa-ellipsis-v mx-2 text-dark"  />
                </Link>
              ))
            }

            <hr className='hr' />
          </div>
        </div>

      </section> 
    </>
  )
}

export default CallCenterBlock
