import React, { useContext, useEffect, useRef, useState } from 'react';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../config';
import EmojiPicker from "emoji-picker-react";
import { ScApiContext } from '../useContext/softCornerContext';
import { ReplyMessage, createSendMessage, editMessage_sc } from "../../Emilo/UseContext/ScApiHelpers";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";



const ScChatFooter = ({ isEdit ,setSelectedMessages, setIsEdit,setArrivalMsg, setOnlineStatus,sock, setSock, setTyping,replyMsg, setReplyMsg, sendMessage, setSendMessage}) => {

  const [openEmoji, setOpenEmoji] = useState(false);
  const [typingTimeout, settypingTimeout] = useState(null);


  const { scConfig,chatId } = useContext(ScApiContext);

  const SCUserId = localStorage.getItem("SCuserId");

  console.log("replyMsg" , replyMsg)

  // Emoji
  const handleEmojiPick = (e) => {
    setSendMessage((prev) => prev + e.emoji);
  };

  const handleChange = (e) => {
    sock.emit("typing_started", chatId);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    settypingTimeout(
      setTimeout(() => {
        sock.emit("typing_stoped", chatId);
      }, 1000)
    );

    setSendMessage(e.target.value);
  };
  const handleSubmit = () => {
    if (!isEdit) {
      // Create a new message
      if (replyMsg !== null) {
        ReplyMessage(sendMessage, replyMsg.messageId, chatId, scConfig)
          .then(async (res) => {
            const newMessage = {
              message: res.message,
              senderSCId: res.senderSCId,
              receiverSCId: res.receiverSCId,
              side: "left",
              createdTime: res.createdTime,
              chatId: res.chatId,
              replyMessage: replyMsg.message,
              replyMessageId: replyMsg.messageId,
            };
  
            sock.emit("send_message", newMessage);
            setArrivalMsg((list) => [...list, newMessage]);
            setSendMessage("");
            setReplyMsg(null);
            setOpenEmoji(false);
          });
      } else {
        if (sendMessage !== "") {
          createSendMessage(sendMessage, chatId, scConfig).then(async (res) => {

            console.log(" SCUserId", res)
            console.log(" SCUserId",  SCUserId )
            const newMessage = {
              message: res.message,
              senderSCId: res.senderSCId,
              receiverSCId: res.receiverSCId,
              side  :  "left", 
              createdTime: res.createdTime,
              chatId: res.chatId,
              messageId: res.messageId,
            };
  
            sock.emit("send_message", newMessage);
            setArrivalMsg((list) => [...list, newMessage]);
            setSendMessage("");
            setOpenEmoji(false);
          });
        } else {
          toast.error("Please write a message");
        }
      }
    } else {
      // Edit an existing message
      editMessage_sc(replyMsg.messageId, sendMessage, scConfig)
        .then((res) => {

          console.log("res", res)
          const data = {
            messageId: replyMsg.messageId,
            newMessage: sendMessage,
            chatId: chatId,
            receiverSCId : replyMsg.receiverSCId
          };
  
          // Emit edit message event to server
          sock.emit("sc_message_edit_everyone_req", data);
  
          setSelectedMessages([]);
          setArrivalMsg((msgs) =>
            msgs.map((msg) => {
              if (msg.messageId === replyMsg.messageId || msg.replyMessageId === replyMsg.messageId) {
                return { ...msg, message: sendMessage, isEdited: "1" };
              }
              return msg;
            })
          );
          setSendMessage("");
          setReplyMsg(null);
          setIsEdit(false);
        })
        .catch((error) => {
          setIsEdit(false);
          console.error(`Error editing message:`, error);
        });
    }
  };
  













  
  useEffect(() => {
    const socket = io.connect(BASEAPIURL);
    setSock(socket);

    socket.emit("online", SCUserId);
    socket.emit("join_room", chatId);
    socket.emit("check_user_online", { chatId, scUserId: SCUserId });
    setInterval(() => {
      socket.emit("check_user_online", { chatId, scUserId: SCUserId });
    }, 1000 * 15);
    socket.on("get_user_online", (e) => setOnlineStatus(e));

    return () => {
      socket.on("disconnect", () => {});

      socket.disconnect();
    };
  }, [chatId, SCUserId]);

  useEffect(() => {
    if (sock) {
      sock.on("receive_message", (data) => {
        setArrivalMsg((list) => [...list, data]);
      });
      sock.on("typing_started_from_server", () => setTyping(true));
      sock.on("typing_stoped_from_server", () => setTyping(false));
    }
  }, [sock]);



  
  // ----- Selected Files ----- 

  const [showFilesOption, setShowFilesOption] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileClick = () => {
    setShowFilesOption(!showFilesOption);
    setSelectedFiles([]);
  };


  // --- Gallary ---

  const gallaryInputRef = useRef();

  // It will open select photo and video field
  const handleGalleryClick = () => {
    if (gallaryInputRef.current) {
      gallaryInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleGallaryChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      console.log("Selected files: ", files);

      setSelectedFiles(Array.from(files));
    };
  };


  // --- Audio ---

  const audioInputRef = useRef();

  // It will open select photo and video field
  const handleAudioClick = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleAudioChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      console.log("Selected files: ", files);

      setSelectedFiles(Array.from(files));
    };
  };


  // --- Documents ---

  const documentInputRef = useRef();

  // It will open select photo and video field
  const handleDocumentClick = () => {
    if (documentInputRef.current) {
      documentInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleDocumentChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      setSelectedFiles(Array.from(files));
    };
  };


  // It will remove selected files (photo, video, audio and document)
  const handleRemoveSelectedFile = (index) => {    
    setSelectedFiles((prev) => prev.filter((data, i) => i !== index));
  };


  // ----- Camera -----

  const cameraInputRef = useRef(null);

  // To click on camera icon it will open device camera 
  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    };
  };

  // To handle clicked picture by device camera
  const handleCameraChange = (event) => {
    const file = event.target.files;
    if (file) {
      console.log("Captured image file: ", file);
      setSelectedFiles(Array.from(file));
    };
  };



  // ----- Record -----

  const [showRecord, setShowRecord] = useState(false);

  // To handle record click
  const handleRecordClick = () => {
    setShowRecord(!showRecord);
  };



  // ----- Show/Hide Popup ----- 

  const popupShowHideRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!popupShowHideRef.current || !popupShowHideRef.current.contains(event.target)) {
      setShowFilesOption(false);
      setSelectedFiles([]);
    };
  };
  

  return (
    <>
      <section className="sc-chat-footer border shadow-sm d-flex align-items-center px-2 px-sm-3 py-1 rounded-pill bg-white position-fixed">
        {/* Emoji */}
        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} className='ms-1' onClick={() => setOpenEmoji(!openEmoji)} alt="emoji" />

        <span className="position-absolute" style={{ bottom: '47px' }}>
          {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} style={{ height: '75vh', width: '100%' }} />}
        </span>
        
        {/* Message */}
        <textarea className="flex-grow-1 mx-2 mx-sm-3 border-0 resize-none" dir="auto" rows='1' value={sendMessage} onChange={handleChange} name="message" id="message" placeholder="Message..."></textarea>

        {/* Select Files or clip */}
        <img src={`/images/icons/clip.svg`} className="cursor-pointer me-3 me-sm-4" onClick={handleFileClick} style={{ width: '20px' }} alt="clip" />

        {/* Select Files Options */}
        <>
          {
            (showFilesOption && selectedFiles.length === 0)
            &&
            <div className="position-absolute bg-white rounded-3 shadow-sm d-flex justify-content-evenly py-2" style={{ bottom: '47px', width: '96%' }} ref={popupShowHideRef}>
              {/* Camera */}
              <div className="d-flex flex-column align-items-center" onClick={handleCameraClick}>
                <img src="/images/icons/chat-camera-w.svg" className="bg-info p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="gallary" />
                <small>Camera</small>

                <input type="file" accept="image/*, video/*" ref={cameraInputRef} onChange={handleCameraChange} style={{ display: 'none' }} />
              </div>

              {/* Gallary */}
              <div className="d-flex flex-column align-items-center" onClick={handleGalleryClick}>
                <img src="/images/icons/chat-gallary.svg" className="bg-success p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="gallary" />
                <small>Gallary</small>

                <input type="file" accept="image/*, video/*" ref={gallaryInputRef} onChange={handleGallaryChange} style={{ display: 'none' }} multiple />
              </div>

              {/* Audio */}
              <div className="d-flex flex-column align-items-center" onClick={handleAudioClick}>
                <img src="/images/icons/chat-audio.svg" className="bg-danger p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="audio" />
                <small>Audio</small>

                <input type="file" accept="audio/*" ref={audioInputRef} onChange={handleAudioChange} style={{ display: 'none' }} multiple />
              </div>

              {/* Document */}
              <div className="d-flex flex-column align-items-center" onClick={handleDocumentClick}>
                <img src="/images/icons/chat-document.svg" className="bg-warning p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="document" />
                <small>Document</small>

                <input type="file" accept=".pdf, .zip" ref={documentInputRef} onChange={handleDocumentChange} style={{ display: 'none' }} multiple />
                {/* <input type="file" accept=".pdf, .zip, .xlsx, .xls, .ppt, .pptx, .doc, .docx" ref={documentInputRef} onChange={handleDocumentChange} style={{ display: 'none' }} multiple /> */}
              </div>
            </div>
          }
        </>

        {/* Show Selected Files */}
        <>
          {
            selectedFiles && selectedFiles.length > 0
            &&
            <div className='position-absolute bg-white rounded-3 px-1 py-2 shadow-sm d-flex align-items-start justify-content-start flex-wrap' style={{ bottom: '47px', width: '95%' }} ref={popupShowHideRef}>
              {
                selectedFiles.map((selectedFile, index) => (
                  <>
                    {console.log('selected : ', selectedFile.type.includes('pdf'))}

                    {/* Image */}
                    <>
                      {
                        (selectedFile.type.slice(0, 5) === 'image')
                        &&
                        <div className="position-relative d-inline rounded-1 me-2 mb-2" key={index}>
                          <img src={URL.createObjectURL(selectedFile)} className="rounded-2 shadow-sm object-fit-contain bg-secondary-subtle" style={{ height: '60px', width: '60px' }} alt="selectedImage" />

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer" 
                            style={{ width: '13px', fontSize: '10px' }} 
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Video */}
                    <>
                      {
                        (selectedFile.type.slice(0, 5) === 'video')
                        &&
                        <div className="position-relative d-inline rounded-1 me-2 mb-2" key={index}>
                          <video className="rounded-2 shadow-sm object-fit-contain bg-secondary-subtle" style={{ height: '60px', width: '60px' }}>
                            <source src={URL.createObjectURL(selectedFile)} type="video/mp4" />
                          </video>

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer" 
                            style={{ width: '13px', fontSize: '10px' }} 
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Audio */}
                    <>
                      {
                        (selectedFile.type.slice(0, 5) === 'audio')
                        &&
                        <div className="position-relative me-sm-3" key={index}>
                          <audio className="" style={{ height: '60px' }} controlsList="nodownload" controls>
                            <source src={URL.createObjectURL(selectedFile)} type="audio/mpeg" />
                          </audio>

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer" 
                            style={{ width: '13px', fontSize: '10px' }} 
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Document */}
                    <>
                      {
                        selectedFile.type.includes('pdf')
                        &&
                        <div className="position-relative me-3 mb-2 d-flex flex-column" key={index}>
                          <img src={`/images/icons/chat-pdf.svg`} className="object-fit-contain" style={{ height: '60px', width: '60px' }} alt="selectedImage" />
                          <small className="text-truncate" style={{ width: '60px' }}>{selectedFile.name}</small>

                          <span className="position-absolute rounded-circle text-center start-0 ms-2 mt-1 bg-white fw-bold cursor-pointer" 
                            style={{ width: '13px', fontSize: '9px' }} 
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Zip */}
                    <>
                    {
                      selectedFile.type.includes('zip')
                      &&
                      <div className="position-relative me-3 mb-2 d-flex flex-column" key={index}>
                        <img src={`/images/icons/chat-zip.svg`} className="object-fit-contain" style={{ height: '60px', width: '60px' }} alt="selectedImage" />
                        <small className="text-truncate" style={{ width: '60px' }}>{selectedFile.name}</small>

                        <span className="position-absolute rounded-circle text-center start-0 ms-2 mt-1 bg-white fw-bold cursor-pointer" 
                          style={{ width: '13px', fontSize: '9px' }} 
                          onClick={() => handleRemoveSelectedFile(index)}
                        >x</span>
                      </div>
                    }
                  </>
                  </>
                ))
              }
            </div>
          }
        </>

        {/* Camera */}
        <>
          <img src={`${BASE_URL_STATIC}/icon/camera.svg`} className="cursor-pointer me-3 me-sm-4" style={{ width: '25px' }} onClick={handleCameraClick} alt="camera" />

          <input type="file" accept="image/*, video/*" ref={cameraInputRef} onChange={handleCameraChange} style={{ display: 'none' }} />
        </>

        {/* Record or Send */}
        <>
          {
            sendMessage === ''
            ?
            <div className="border bg-primary rounded-circle d-flex justify-content-center align-items-center cursor-pointer" style={{ height: '37px', width: '37px' }} onClick={handleRecordClick}>
              <img src={`${BASE_URL_STATIC}/icon/mic.svg`} style={{ width: '13px' }} alt="mic"  />
            </div>
            :
            <div className="border bg-primary rounded-circle d-flex justify-content-center align-items-center cursor-pointer" style={{ height: '37px', width: '37px' }} onClick={() => handleSubmit()}>
              <img src={`${BASE_URL_STATIC}/icon/send.svg`} style={{ width: '17px' }} alt="send"  />
            </div>
          }
        </>

        {/* Reply */}
        <>
          {
            replyMsg 
            && 
            // <div className="scReplyArea">
            <div className="SCRply">
              <p className="scReplyClose"><FontAwesomeIcon icon={faX} onClick={() => {setReplyMsg(null); setIsEdit(false); setSendMessage("")}} /></p>

              <p>{replyMsg && replyMsg.message && replyMsg.message.length > 50 ? replyMsg.message.substr(0, 100) + "..." : replyMsg.message}</p>
            </div>
          }
        </>
      </section>
    </>
  )
}

export default ScChatFooter
