import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const CheckInCenterBlock = ({ post }) => {
    const selectedLocation = {
        lat: post.lat,
        lng: post.lng
    };


    return (
        <div className="d-flex justify-content-between flex-column flex-md-row gap-3 align-items-center border border-1 rounded-4 p-2 my-2">
            <div className='col-12 col-md-4'>

                <LoadScript googleMapsApiKey="AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60">
                    <GoogleMap
                        mapContainerStyle={{ height: '150px', width: '100%' , borderRadius : "15px" }}
                        center={selectedLocation}
                        zoom={15}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}>
                    
                        {selectedLocation && <Marker position={selectedLocation} />}
                    </GoogleMap>
                </LoadScript>
            </div>
            <div className="col-12 col-md-8 d-flex flex-column w-100 align-items-start">
                <div className='text-start'>

                    <small className="text-secondary">City </small>
                    <p className="text-break text-black" style={{ fontSize: '12px', fontWeight: '500' }}>
                        {post.city}
                    </p>
                </div>
                <br />
                <div className='text-start'>
                    <small className="text-secondary mt-2">Place </small>
                    <p className="w-100 text-break text-black" style={{ fontSize: '12px', fontWeight: '500' }}>
                        {post.place}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CheckInCenterBlock;
