import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';
import SettingInputUser from '../../../Profile/ProfileUser/ProfileUserSetting/SettingInputUser';



const BestclipAdvanceSettingPopup = ({
  showBestclipAdvanceSettingPopup, setShowBestclipAdvanceSettingPopup,
  setShowCreateBestClipsPopup1
}) => {

  const { config } = useContext(ApiContext);
  const { value, setValue, handleOptionChange, setAdded, isAccountPrivate } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };

  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('privacy', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        // ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get (Followers Except, Hide From, Block Comments Users and Others)
    getAdded('privacy', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        // ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <Modal show={showBestclipAdvanceSettingPopup} onHide={() => {setShowBestclipAdvanceSettingPopup(false); setShowCreateBestClipsPopup1(true);}} className='create-post m-0' backdrop="static" centered >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section>
            <div className='d-flex'>
              <span className='cursor-pointer' onClick={() => {
                setShowBestclipAdvanceSettingPopup(false);
                setShowCreateBestClipsPopup1(true);
              }}>⮜</span>
              <h4 className='mx-auto text-primary'>Advance Setting</h4>
            </div>

            <div className="accordion accordion-flush overflow-y-scroll pe-1 my-4" style={{ maxHeight: '50vh' }}>
              {/* Audience Control */}
              <div className=''>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-audienceControl">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Audience Control</h5>
                </div>

                <div id='bestclip-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* View */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#bc-audienceControl-view">Who can view your bestclip?</h5>

                    <div id='bc-audienceControl-view' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipView-public" name="bestclipView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.bestclipView || value.bestclipView === 'public'}  
                            disabled={isAccountPrivate === 'private'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipView-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipView-followers" name="bestclipView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.bestclipView === 'followers'} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipView-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipView-follower" name="bestclipView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.bestclipView === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipView-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipView-fof" name="bestclipView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.bestclipView === 'fof'} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipView-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.bestclipView ? value.bestclipView : 'public']} view your bestclip)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.bestclipView) || (value.bestclipView === 'public') || (value.bestclipView === 'followers') || (value.bestclipView === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.bestclipView || value.bestclipView === 'public') ? 'bestclipViewPublic' : 
                                value.bestclipView === 'followers' ? 'bestclipViewFollowers' : 
                                value.bestclipView === 'fof' && 'bestclipViewFOF'
                              }
                              settingType={value.bestclipView ? value.bestclipView : 'public'} 
                              header={
                                (!value.bestclipView || value.bestclipView === 'public') ? 'Hide bestclip from specific people' :
                                value.bestclipView === 'followers' ? 'Followers Except' :
                                value.bestclipView === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your bestclip' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Likes & Comments */}
              <div className='my-3'>
                <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-likeAndComments">
                  <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                  <h5 className='fw-semibold ms-3 mb-0'>Likes & Comments</h5>
                </div>

                <div id='bestclip-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Like Count */}
                  <div className='mt-3 ms-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0'>Hide like count</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='bestclipLike' value={value.bestclipLike === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.bestclipLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <small className='text-secondary ms-3 mt-3 d-block'>(Confirm if you want to hide the like counts of your bestclip)</small>
                  </div>

                  <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Comments */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#bestclip-likeAndComments-comment">Who can comment on your bestclip?</h5>

                    <div id='bestclip-likeAndComments-comment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipComment-public" name="bestclipComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.bestclipComment || (!value.bestclipComment && value.bestclipView === 'public') || value.bestclipComment === "public"} 
                            disabled={value.bestclipView === 'fof' || value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipComment-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipComment-followers" name="bestclipComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipComment && value.bestclipView === 'followers') || value.bestclipComment === "followers"} 
                            disabled={value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipComment-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipComment-follower" name="bestclipComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipComment && value.bestclipView === 'closeFriends') || value.bestclipComment === "closeFriends"} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipComment-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipComment-fof" name="bestclipComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipComment && value.bestclipView === 'fof') || value.bestclipComment === 'fof'} 
                            disabled={value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipComment-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.bestclipComment ? value.bestclipComment : 'public']} comment on your bestclip)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.bestclipComment) || (value.bestclipComment === 'public') || (value.bestclipComment === 'followers') || (value.bestclipComment === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.bestclipComment || value.bestclipComment === 'public') ? 'bestclipCommentPublic' : 
                                value.bestclipComment === 'followers' ? 'bestclipCommentFollowers' : 
                                value.bestclipComment === 'fof' && 'bestclipCommentFOF'
                              }
                              settingType={value.bestclipComment ? value.bestclipComment : 'public'} 
                              header={
                                (!value.bestclipComment || value.bestclipComment === 'public') ? 'Hide bestclip comment from specific people' :
                                value.bestclipComment === 'followers' ? 'Followers Except' :
                                value.bestclipComment === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your bestclip comment' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Share & Download */}
              <div className=''>
                <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-shareAndDownload">
                  <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                  <h5 className='fw-semibold ms-3 mb-0'>Share & Download</h5>
                </div>

                <div id='bestclip-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Share */}
                  <div className='ms-3 mt-3'>
                    {/* bestclip share */}
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow other EMILO users to share your bestclip</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='bestclipShare' value={value.bestclipShare === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.bestclipShare === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='ms-3'>
                      <span>By default, your selected audience for the Best Clips will be able to use your Best Clips. However, if you want to disallow specific people from using your Best Clips, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='bestclipShare' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                    {/* bestclip share to story */}
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow sharing of your bestclip on Stories</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='bestclipShareToStory' value={value.bestclipShareToStory === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.bestclipShareToStory === 'true' ? true : false} 
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Download */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#bestclip-shareAndDownload-download">Who can download your bestclip?</h5>

                    <div id='bestclip-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipDownload-public" name="bestclipDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.bestclipDownload || (!value.bestclipDownload && value.bestclipView === 'public') || value.bestclipDownload === 'public'}  
                            disabled={value.bestclipView === 'followers' || value.bestclipView === 'fof' || value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipDownload-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipDownload-followers" name="bestclipDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipDownload && value.bestclipView === 'followers') || value.bestclipDownload === 'followers'} 
                            disabled={value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipDownload-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipDownload-follower" name="bestclipDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipDownload && value.bestclipView === 'closeFriends') || value.bestclipDownload === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipDownload-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipDownload-fof" name="bestclipDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.bestclipDownload && value.bestclipView === 'fof') || value.bestclipDownload === 'fof'} 
                            disabled={value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipDownload-fof">Followers of Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-bestclipDownload-following" name="bestclipDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.bestclipDownload === 'none'} 
                          />
                          <label className="form-check-label" htmlFor="ps-bestclipDownload-following">No one</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.bestclipDownload ? value.bestclipDownload : 'public']} download your bestclip)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.bestclipDownload) || (value.bestclipDownload === 'public') || (value.bestclipDownload === 'followers') || (value.bestclipDownload === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.bestclipDownload || value.bestclipDownload === 'public') ? 'bestclipDownloadPublic' : 
                                value.bestclipDownload === 'followers' ? 'bestclipDownloadFollowers' : 
                                value.bestclipDownload === 'fof' && 'bestclipDownloadFOF'
                              }
                              settingType={value.bestclipDownload ? value.bestclipDownload : 'public'} 
                              header={
                                (!value.bestclipDownload || value.bestclipDownload === 'public') ? 'Hide bestclip download from specific people' :
                                value.bestclipDownload === 'followers' ? 'Followers Except' :
                                value.bestclipDownload === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your bestclip download' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className='btn btn-primary rounded-pill w-100'>Done</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BestclipAdvanceSettingPopup
