import React, { useContext, useState } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { ApiContext } from '../../UseContext/ApiCall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faClose, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const ForwardPop = ({ open, close, socket, setArrivalMsg, setmessageObject, setSelectedMsg, messageObject }) => {
  const { allChatUsers, createEmiloMessage } = useContext(ApiContext);
  const [selectedChats, setSelectedChats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State to handle the search input

  const nav = useNavigate();
  const params = useParams();

    console.log("para,s" , params)

  const handleForward = async () => {
    if (selectedChats.length === 0) {
      toast.error("Please select at least one chat to forward the message.");
      return;
    }

    messageObject.forEach(async (msg) => {
      setLoader(true);
      try {
        for (const chatId of selectedChats) {
          createEmiloMessage(msg.message, chatId, msg.fileUrl, msg.type, "true", msg.fileName).then((res) => {
            if (res) {
              const newMessage = {
                ...res.data,
                chatId,
                loading: true,
                isForwarded: "true",
              };
              socket && socket.emit("send_message_emilo", newMessage);
              setArrivalMsg((list) => [...list, newMessage]);
            }
          });
        }
        setSelectedMsg([]);
        setmessageObject([]);
        setLoader(false);
        close();
        nav(`/chats/${selectedChats[0]}`);
      } catch (error) {
        toast.error("Failed to send message");
        setLoader(false);
        setSelectedMsg([]);
        setmessageObject([]);
      }
    });
  };

  const handleCheckboxChange = (chatId) => {
    setSelectedChats((prevSelectedChats) => {
      if (prevSelectedChats.includes(chatId)) {
        setAlertVisible(false);
        return prevSelectedChats.filter((id) => id !== chatId);
      } else if (prevSelectedChats.length >= 5) {
        setAlertVisible(true);
        return prevSelectedChats;
      } else {
        setAlertVisible(false);
        return [...prevSelectedChats, chatId];
      }
    });
  };

  console.log("filteredUsers", allChatUsers);

  // Filter users based on search query
  const filteredUsers = allChatUsers.length > 0 && allChatUsers.filter((user) => {
    const name = user.isGroupChat === "true" ? user.groupName : (user.senderFname || user.fname);
    if (name === undefined || user.chatId === params.chatId) {
      return false;
    }
    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });


  return (
    <section className="fwd-msg">
      <Modal size="sm" show={open} onHide={close} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <p className="chat-filter-head">
            <input
              type="text"
              className="form-control"
              placeholder="Forward to..."
              value={searchQuery} // Bind input value to state
              onChange={(e) => setSearchQuery(e.target.value)} // Update state on input change
            />

            <span className='cp' onClick={() => close()}><FontAwesomeIcon icon={faClose} /> </span>
          </p>
        </Modal.Header>
        <Modal.Body className="fwd_msg_modal_body">
          <section>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <div key={index}>
                  <div className="chats-rb-div">
                    <div className="chats-cb-chat-all-user">
                      <img
                        className="rounded-pill"
                        src="https://s3aws.emilo.in/default/user.webp"
                        width={40}
                        alt="profile"
                      />
                      <div className="chats-cb-chat-all-user-nameArea">
                        <label htmlFor={`user-${index}`} className="d-flex justify-content-between align-items-center">
                          <span className="chats-cb-chat-all-user-name">
                            {user.isGroupChat === "true" ? user.groupName : user.senderFname}
                          </span>
                          <input
                            type="checkbox"
                            id={`user-${index}`}
                            onChange={() => handleCheckboxChange(user.chatId)}
                            disabled={selectedChats.length >= 5 && !selectedChats.includes(user.chatId)}
                            checked={selectedChats.includes(user.chatId)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className='d-flex flex-col align-items-center justify-content-center gap-3'>
                <img src="https://img.freepik.com/premium-vector/vector-illustration-about-concept-no-result-data-document-file-found_675567-5734.jpg?w=740" alt="" width={50} />

                <span className='fw-bold'>No users found</span>

              </p>
            )}

            {alertVisible && (
              <Alert variant="danger" className="mt-3">
                You can only select up to 5 chats. Please unselect a chat to select another.
              </Alert>
            )}

            <div className="sticky-btn-container">
              <button onClick={handleForward} className="fwd-msg-btn">
                {loader ? (
                  <span>
                    <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />
                  </span>
                ) : (
                  <span style={{ rotate: "53deg" }}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: "snow" }} />
                  </span>
                )}
              </button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ForwardPop;
