import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from '../../UseContext/ApiCall';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup'
import { AwsFileUrl,} from '../../../../config';
import { GetuserFavorite } from '../../UseContext/ApiHelpers';




const FavouritePosts = () => {

  const [favouritePosts, setFavouritePosts] = useState([]);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  
 
  const [postId, setPostId] = useState('');
  const [mainpoastData, setmainpoastData] = useState('')

  const { config } = useContext(ApiContext);

  // To get favourite posts
  const getFavouritePosts = async (config) => {
 
 
    GetuserFavorite("post", config)
    .then(res => {
   
      setFavouritePosts(res);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouritePosts(config);
  }, []);

 

  return (
    <>
      {/* Post image popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />
      <section className='favourite-posts'>
        <h1 className='favourite-posts-head'>Posts</h1>

        <div className='favourite-posts-imgs'>
          {
            favouritePosts && favouritePosts.map((favouritePost, index) => (
            
            <img className='favourite-posts-img' src={ AwsFileUrl +favouritePost.mediaUrls[0].url} alt={favouritePost.mediaUrls[0].url} onClick={() => { setShowPostsPopup(!showPostsPopup); setmainpoastData(favouritePost); setPostId(favouritePost._id) }}  />
            ))
          }
        </div>
      </section> 
    </>
  );
};

export default FavouritePosts;
