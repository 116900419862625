import React,{ useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC } from '../../../../config';
import Skeleton from 'react-loading-skeleton';
import { Link, NavLink } from 'react-router-dom';

import Parser from "html-react-parser";
import FollowUnfollowButton from '../../Button/FollowUnfollowButton';
import OthersSekeleton from '../../Skeleton/RightSection/OthersSekeleton';
import axios from 'axios';
import FollowedBy from '../../Others/FollowedBy/FollowedBy';
import { getTrendingPeoples } from '../../UseContext/ApiHelpers';

const Suggestions = () => {

  // const [otherUsers, setOtherUsers] = useState([]);
  const [suggestedPeoples, setSuggestedPeoples] = useState([]);
  const [trendingPeoples , setTrendingPeoples] = useState([])

  const { config, loggedInUserId, RightSectionLoader, setRightSectionLoader } = useContext(ApiContext);

  // To get all user to show in suggestion section of right block
  const fetchUser = async () => {
    setRightSectionLoader(true);

    try {
      const res = await axios.post(`${BASEURL}/user/getalluser`)

      if (res.status === 200) {
        // setOtherUsers(res.data.users);
      } 
      setRightSectionLoader(false);
    } catch (err) {
      return null;
    } finally {
      setRightSectionLoader(false);
    };
  };

  useEffect(() => {
    fetchUser();
  }, []);

  // To get people to show in suggestions in right block of home page
  const getSuggestedPeople = (config) => {
    axios.post(`${BASEAPIURL}/getmutualfollowers`, {}, config)
    .then(res => {
      // console.log('mutual list : ', res.data.mutualList);
      setSuggestedPeoples(res.data.mutualList);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getSuggestedPeople(config);
    getTrendingPeoples(config)
    .then(res => setTrendingPeoples(res.data.topFollowers))
    // .then(res => console.log("res" , res))
  }, []);

  return (
    <>
      <section style={{ height: '73%' }}>
        <h6 className='mb-3 text-center bg-white p-2 fw-bold'>{(!RightSectionLoader) ? "Suggestions" : <Skeleton width={100} height={10} />}</h6>

        <div className='overflow-scroll h-100 scrollbar-hidden'>
          {
            (!RightSectionLoader) 
            ? 
              (suggestedPeoples && suggestedPeoples.length > 0 
              ?
              suggestedPeoples.map((suggestedPeople , i) => (
                <div className="peopleBox" key={i}>
                  <div className="suggestionImage">
                    {
                      (suggestedPeople.profileImg) 
                      ? 
                      <img className="suggestionImage-img" src={ AwsFileUrl + suggestedPeople.profileImg.imageId} alt='profile' onError={(e) => e.target.src=`${BASE_URL_STATIC}/user-icon${i+1}.jpg`}  /> 
                      : 
                      <img className="suggestionImage-img" src={`${BASE_URL_STATIC}/dummy.png`} alt='profile'  />
                    }
                  </div>
                  <div className="contentBox">
                    <div className="blockOne">
                      <div className="nameBox">
                        <NavLink to={`${BASEURL}/profile/${suggestedPeople.userId}`}
                          style={{
                            cursor: "pointer",
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          {Parser(suggestedPeople.fname)}
                        </NavLink>
                        <FollowedBy otherUserId={suggestedPeople.userId} />
                      </div>

                      {
                        (suggestedPeople.userId !== loggedInUserId)
                        &&
                        <div className="followBox">
                          <FollowUnfollowButton otherUserId={suggestedPeople.userId} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )) 
              : 
              trendingPeoples 
              && 
              trendingPeoples.map((suggestedPeople , i) => (
                <div className="d-flex align-items-center mb-3" key={i}>
                  {
                    (suggestedPeople.profileImg && suggestedPeople.profileImg) 
                    ? 
                    <img className="rounded-circle rb-profile" src={AwsFileUrl +  suggestedPeople.profileImg.imageId} alt='profile' onError={(e) => e.target.src=`${BASE_URL_STATIC}/user-icon1.jpg`} height={60} width={60} /> 
                    : 
                    <img className="rounded-circle rb-profile" src={`${BASE_URL_STATIC}/dummy.png`} alt='profile' />
                  }

                  <div className="ms-2 ms-xl-3 me-auto">
                    <Link to={`${BASEURL}/profile/${suggestedPeople.userId}`} className='rb-name'>{Parser(suggestedPeople.fname)}</Link>

                    <FollowedBy otherUserId={suggestedPeople.userId} />
                  </div>

                  {
                    (suggestedPeople.userId !== loggedInUserId)
                    &&
                    <FollowUnfollowButton otherUserId={suggestedPeople.userId} />
                  }
                </div>
              )) )
            :
            <OthersSekeleton />
          }
        </div>

      </section>
    </>
  );
};

export default Suggestions;
