import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CButton, CCol, CContainer, CForm, CFormInput, CInputGroup, CRow } from '@coreui/react'
import { BASEAPIURL, headerUserAPI, LogoutUser } from '../../../config'

const TwoFactorPage = ({ setTwoFactor }) => {
    const [Message, setMessage] = useState({ status: 0, message: "" })
    const [PostData, setPostData] = useState({
        token: '',
        type: '',
        code: '',
        // wwwToken: getWwwToken(0),
        deviceType: 'web',
        apiCall: false
    })

    function isApiCall() {
        setPostData({
            ...PostData,
            apiCall: !PostData.apiCall,
            deviceType: 'web',
        })
    }

    const userLogin = async (event) => {
        event.preventDefault()
        try {
            const headers = headerUserAPI();
            const response = await axios.post(`${BASEAPIURL}/user/approve-factor`, PostData, { headers: headers })
            isApiCall()
            setMessage({ status: response.data.verified ? 1 : 0, message: response.data.message });
            if (response && response.data && response.data.verified) {
                setTwoFactor(1);
            } else {
                setTwoFactor(0);
            }
            // handle success or perform any necessary action
        } catch (error) {
            isApiCall()
            // console.error('Error making post request:', error)
            // setMessage(error.message)
            setMessage({ status: 0, message: error.message });
            // handle error or display error message
        }
    }

    const [verificationMethods, setVerificationMethods] = useState('')
    async function getVerificationMethods() {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const response = await axios.post(`${BASEAPIURL}/user/verification-method`, { get: new Date() }, { headers: headers });
            if (response.data && response.data.data && response.data.data.length > 0) {
                setVerificationMethods(response.data.data); // Assuming verificationMethods is the correct data
            } else {
                setVerificationMethods([]);
            }
        } catch (error) {
            // console.error('Error making post request:', error.message);
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }

    function maskedValue(phoneNumber, slice = 4) {
        // Remove spaces and special characters for simplicity
        // const cleaned = phoneNumber.replace(/[^\d]/g, '');
        const cleaned = phoneNumber;
    
        // Check if phone number has at least 4 digits
        if (cleaned.length <= 4) {
            return cleaned; // No need to mask if the number is too short
        }
    
        // Mask all digits except the last 2 or last 4 based on length
        const maskLength = cleaned.length > 7 ? cleaned.length - 4 : cleaned.length - 2;
        const maskedPart = '*'.repeat(maskLength);
        const visiblePart = cleaned.slice(-slice); // Get last 4 digits for long numbers, else last 2 digits
    
        return `${maskedPart}${visiblePart}`;
    }

    async function generateMobileOtp(v) {
        if (!v.mobile && v.mobile !== true && v.mobile !== '') {
            setMessage({ status: 0, message: 'Please Enter your Mobile to Continue.' });
            return false;
        }
        try {
            const headers = headerUserAPI();
            await axios.post(`${BASEAPIURL}/user/mobile/generateOtp`, v, { headers: headers });
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }
    async function generateEmailOtp(v) {
        if (!v.email && v.email !== true && v.email !== '') {
            setMessage({ status: 0, message: 'Please Enter your Email to Continue.' });
            return false;
        }
        try {
            const headers = headerUserAPI();
            await axios.post(`${BASEAPIURL}/user/email/generateOtp`, v, { headers: headers });
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }

    useEffect(() => {
        getVerificationMethods();
        // Create a new link element for the CSS file
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/css/TwoFactorVerifyPage.css'; // Replace with the path to your CSS file
        // Append the link element to the document head
        document.head.appendChild(link);
        // Cleanup function to remove the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
      }, []);

    function showSubTitle(type, token) {
        let returnValue = "Enter the Code from here";
        switch (type) {
            case "mobile":
                returnValue = `We'll send a code to ${maskedValue(token)}`;
                break;
            case "email":
                returnValue = `We'll send a code to ${maskedValue(token, 10)}`;
                break;
            case "authApp":
            default:
                returnValue = "Enter the Code from here";
                break;
        }
        return returnValue;
    }

    return (
        <div className="login-area">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={6} xs={12}>
                        <div className="TwoFactorVerifyDiv p-4 mt-5">
                            <div style={{ display: PostData.type && PostData.type !== '' ? "none" : "" }}>
                                <h3 className="text-info ml-0 mr-0">Choose a way to confirm that its you</h3>
                                <p>These are your available confirmation methods.</p>
                                {verificationMethods && verificationMethods.map((s) => (
                                    <div
                                        className="OptFromSection"
                                        key={s._id}
                                        onClick={(e) => {
                                            setPostData({
                                                ...PostData,
                                                title: s.title,
                                                type: s.type,
                                                token: s.token,
                                                _id: s._id
                                            })
                                            if (s.type === "mobile") {
                                                generateMobileOtp({ mobile: s.token });
                                            }
                                            if (s.type === "email") {
                                                generateEmailOtp({ email: s.token });
                                            }
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            name="otpFrom"
                                            checked={s._id === PostData._id}
                                            onChange={(e) => {
                                                console.log(e.target.checked);
                                            }}
                                            style={{ float: 'right', marginTop: '5px', width: '20px', height: '20px' }}
                                        />
                                        <div style={{ paddingRight: '30px' }}>
                                            <h6>{s.title}</h6>
                                            <p className="mb-0">{showSubTitle(s.type, s.token)}</p>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                ))}
                            </div>
                            <CForm
                                onSubmit={(e) => {
                                    userLogin(e)
                                }}
                                method="POST"
                                action="/"
                                style={{
                                    display: PostData.type && PostData.type !== '' ? "" : "none"
                                }}
                            >
                                <CInputGroup>
                                    {PostData.type === 'authApp' && (
                                        <div>
                                            <h3 className="text-info  ml-0  mr-0">Go to your authentication app</h3>
                                            <p>Enter the 6-digit code for this account from the two-factor authentication app that you set up (i.e. {PostData.title})</p>
                                        </div>
                                    )}
                                    {PostData.type === 'email' && (
                                        <div>
                                            <h3 className="text-info  ml-0  mr-0">Go to your Email</h3>
                                            <p>Enter the Code for this account from the two-factor Email that you set up (i.e. {PostData.title})</p>
                                        </div>
                                    )}
                                    {PostData.type === 'mobile' && (
                                        <div>
                                            <h3 className="text-info  ml-0  mr-0">Go to your Mobile SMS</h3>
                                            <p>Enter the OTP for this account from the SMS from Mobile that you set up (i.e. {PostData.title})</p>
                                        </div>
                                    )}
                                    <CFormInput
                                        className="bg-white p-3"
                                        name="code"
                                        type="number"
                                        min={0}
                                        step={1}
                                        required
                                        placeholder="Enter Code"
                                        value={PostData.code}
                                        onChange={(e) => {
                                            setMessage({ status: 0, message: "" })
                                            setPostData({
                                                ...PostData,
                                                code: e.target.value,
                                            })
                                        }}
                                    />
                                </CInputGroup>
                                {Message.message && Message.message !== "" && (
                                    <div className={`alert alert-${Message.status === 1 ? 'success' : 'danger'}`}>
                                        {Message.message}
                                    </div>
                                )}
                                <CButton
                                    type="submit"
                                    className="loginBtn mb-1"
                                    onClick={() => {
                                        isApiCall()
                                    }}
                                >
                                    Submit
                                </CButton>
                                <a
                                    href="/"
                                    className="text-right text-black d-block mb-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setMessage({ status: 0, message: "" })
                                        setPostData({ ...PostData, title: '', type: '', token: '', code: '', _id: '' })
                                    }}
                                >
                                    Try Another way
                                </a>
                            </CForm>
                            <p className="mt-3 text-center">
                                Not able to authnticate?&nbsp;
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        LogoutUser();
                                    }}
                                >
                                    Try Login again.
                                </a>
                            </p>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default TwoFactorPage
