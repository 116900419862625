import React, { useContext, useEffect, useState } from 'react';
import ProfileUser from "../ProfileUser";
import { ApiContext } from '../../../UseContext/ApiCall';
import { IoMdCheckmark } from "react-icons/io";
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';
import * as htmlToImage from 'html-to-image'; // Import the html-to-image package
import download from 'downloadjs'; // Import the downloadjs package
import { Link } from 'react-router-dom';
import { WhatsappShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';

const ProfileUserShare = () => {

  const [linkCopied, setIsLinkCopied] = useState(false);

  const { loggedInUserId, loggedInUserName } = useContext(ApiContext);

  const getQRCode = () => {
    axios.get(`https://api.qrserver.com/v1/create-qr-code/?size=[250]x[250]&data=${loggedInUserId}`)
      .then(res => {
        // console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQRCode();
    setIsLinkCopied(false);
  }, []);

  const handleCopy = () => {
    setIsLinkCopied(true);
  };

  const handleDownloadQR = () => {
    const qrContainer = document.getElementById('qr-container');
    
    if (qrContainer) {
      htmlToImage.toPng(qrContainer)
        .then((dataUrl) => {
          download(dataUrl, `${loggedInUserName}.png`);
        })
        .catch((error) => {
          console.error('Error generating QR code image:', error);
        });
    } else {
      console.error('QR container not found');
    }
  };
  
  return (
    <>
      <ProfileUser />

      <section className='d-flex flex-column align-items-center mb-5 mb-lg-0'>
        <img id='qr-container' className='ps-qrCode-img' src={`https://api.qrserver.com/v1/create-qr-code/?data=https://emilorj.sjain.io/profile/${loggedInUserId}`} alt="QRCode" />

        <button className='btn btn-primary rounded-pill px-4 my-3' onClick={handleDownloadQR}>
          <img className='me-2' src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="feed icon 6" />
          Download QR
        </button>
        
        <CopyToClipboard text={`${BASEURL}/profile/${loggedInUserId}`} onCopy={() => handleCopy()}>
          <div className='ps-form-div position-relative me-4 me-lg-3 mb-3'>
            <input type="text" className='form-control rounded-pill px-3 shadow-sm' value={`${BASEURL}/profile/${loggedInUserId}`} id='copy' name='copy' style={{ border: 'none' }} />

            <label htmlFor="copy" className='form-label position-absolute top-50 start-100 translate-middle text-white bg-primary py-2 px-3 rounded-pill'>{linkCopied ? <span><IoMdCheckmark color='#f3f3f3' /> copied</span> : "copy"}</label>
          </div>
        </CopyToClipboard>

        {/* Profile share > social media > Icons */}
        <div className='mb-5 mb-lg-0'>
          <Link to={`${BASEURL}/profile/${loggedInUserId}/profile-share`} className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle'>
            <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/share-2.svg`} alt="icon" />
          </Link>

          <Link to={`https://www.instagram.com/direct/inbox/`} className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle'>
            <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/instagram-1.png`} alt="icon" />
          </Link>

          <LinkedinShareButton url={`${BASEURL}/profile/${loggedInUserId}`}>
            <Link className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle' to={`https://in.linkedin.com/`}>
              <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/linkedIn-1.svg`} alt="icon" />
            </Link>
          </LinkedinShareButton>

          <TwitterShareButton url={`${BASEURL}/profile/${loggedInUserId}`}>
            <Link className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle' to={`https://twitter.com/`}>
              <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/twitter-1.svg`} alt="icon" />
            </Link>
          </TwitterShareButton>

          <WhatsappShareButton url={`https://emilorj.sjain.io/profile/${loggedInUserId}`} title={'Check my profile on Emilo'} image={`https://cdn-icons-png.flaticon.com/512/3135/3135715.png`}>
            <Link to={`https://web.whatsapp.com/`} className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle'>
              <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/whatsapp-1.svg`} alt="icon" />
            </Link>
          </WhatsappShareButton>

          <FacebookShareButton url={`${BASEURL}/profile/${loggedInUserId}`}>
            <Link to={`https://www.facebook.com/`} className='border p-2 mx-1 mx-md-2 mx-lg-3 bg-light rounded-circle'>
              <img className='ps-sm-icon' src={`${BASE_URL_STATIC}/icon/facebook-1.svg`} alt="icon" />
            </Link>
          </FacebookShareButton>
        </div>
      </section>
    </>
  )
};

export default ProfileUserShare;
