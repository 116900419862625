import React from 'react';
import { BASE_URL_STATIC } from '../../../../../config';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Rating from 'react-rating';


const ProposeOptionGifts2 = () => {

  // --- Product ---

  const productImages = [
    'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
    'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
    'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
    'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
  ];

  const productSettings = {
    customPaging: function(index) {
      return (
        <div>
          <img src={productImages[index]} className='product-image-option border rounded-3 shadow-sm object-fit-contain p-1 w-100' alt='option' />
        </div>
      );
    },
    dotsClass: "slick-dots slick-thumb d-flex justify-content-between",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  // --- Related ---

  const relatedItems = [
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
  ];

  const relatedSettings = {
    className: 'relatedSettings',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };


  // --- Review --- 

  const productReviews = [
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg',
      name: 'John Doe',
      rating: 4,
      date: '09/08/2024',
      review: 'Great product! Really loved the quality and the fast shipping. Will definitely buy again.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-pink-hair_23-2149436186.jpg',
      name: 'Jane Smith',
      rating: 3,
      date: '08/08/2024',
      review: 'The product is okay, but I expected better. The material feels a bit cheap.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses-green-hair_23-2149436201.jpg',
      name: 'Alex Johnson',
      rating: 5,
      date: '07/08/2024',
      review: 'Excellent! Exceeded my expectations. The customer service was also very helpful.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-rainbow-sunglasses_23-2149436181.jpg',
      name: 'Emily Davis',
      rating: 4,
      date: '06/08/2024',
      review: 'Good product, but the color was slightly different from what I saw online.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-pink-hat_23-2149436195.jpg',
      name: 'Michael Brown',
      rating: 2,
      date: '05/08/2024',
      review: 'Not very satisfied with the product. It arrived late and the packaging was damaged.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg',
      name: 'John Doe',
      rating: 4,
      date: '09/08/2024',
      review: 'Great product! Really loved the quality and the fast shipping. Will definitely buy again.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-pink-hair_23-2149436186.jpg',
      name: 'Jane Smith',
      rating: 3,
      date: '08/08/2024',
      review: 'The product is okay, but I expected better. The material feels a bit cheap.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses-green-hair_23-2149436201.jpg',
      name: 'Alex Johnson',
      rating: 5,
      date: '07/08/2024',
      review: 'Excellent! Exceeded my expectations. The customer service was also very helpful.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-rainbow-sunglasses_23-2149436181.jpg',
      name: 'Emily Davis',
      rating: 4,
      date: '06/08/2024',
      review: 'Good product, but the color was slightly different from what I saw online.',
    },
    {
      profile: 'https://img.freepik.com/free-psd/3d-illustration-person-with-pink-hat_23-2149436195.jpg',
      name: 'Michael Brown',
      rating: 2,
      date: '05/08/2024',
      review: 'Not very satisfied with the product. It arrived late and the packaging was damaged.',
    },
  ];

  const reviewSettings = {
    className: 'reviewSettings',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };


  return (
    <>
      <section className='vh-100 overflow-y-scroll scrollbar-hidden pt-5 px-3 px-sm-4 px-md-5 pb-5 row mx-auto p-lg-0'>
        {/* Mobile > Heading */}
        <h3 className='d-lg-none m-0 pt-5'>Roses And Orchids Basket Gift Basket Gift</h3>

        {/* Mobile > Rating */}
        <div className='d-flex align-items-center d-lg-none my-3'>
          <div className='bg-primary px-3 rounded-pill me-3 d-flex align-items-center'>
            <span className='me-2 text-white'>5</span>
            <img src={`https://demo3.sjainventures.com/e-milo/images/star.png`} style={{ width: '15px' }} alt="star" />
          </div>

          <span>1,354 Rating</span>
        </div>

        {/* Product Image */}
        <div className='col-lg-6 px-0 px-lg-3 pt-lg-5'>
          <Slider {...productSettings} className='productSlider pt-lg-5'>
            {
              productImages.map((image, index) => (
                <div key={index}>
                  <img src={image} className='product-image-selected border rounded-4 shadow-sm w-100 object-fit-contain p-1 mb-2' alt='selected' />
                </div>
              ))
            }
          </Slider>
        </div>

        {/* Product Details */}
        <div className='product-detail col-lg-6 px-0 px-lg-3 pt-lg-5 overflow-y-scroll scrollbar-hidden'>
          {/* Heading */}
          <h2 className='d-none d-lg-block pt-lg-5'>Roses And Orchids Basket Gift</h2>

          {/* Rating */}
          <div className='d-none d-lg-flex my-3'>
            <div className='bg-primary px-3 rounded-pill d-flex align-items-center me-3'>
              <span className='text-white me-2'>5</span>
              <img src={`${BASE_URL_STATIC}/star.png`} alt="star" height={15} width={15} />
            </div>

            <span>1,423 Rating</span>
          </div>

          {/* Price And Quantity */}
          <div className='d-flex align-items-end my-3'>
            <h3 className='m-0'>&#8377;1,799</h3>

            <h4 className='ms-2 me-4 me-sm-5 text-decoration-line-through text-secondary'>1,999</h4>

            <div className='border px-3 py-1 shadow-sm rounded-3'>
              <span className=''>-</span>
              <span className='mx-3'>1</span>
              <span className=''>+</span>
            </div>
          </div>

          {/* Mobile > Add to Cart */}
          <div className='d-lg-none mb-3 text-center'>
            <button type='button' className='btn btn-primary text-white w-75 text-center p-2 rounded-pill'>Add to Cart</button>
          </div>

          {/* Description & Product Detail */}
          <div className='accordion accordion-flush'>
            {/* Description */}
            <div className='mb-3'>
              <h5 className='accordion-button bg-transparent shadow-none text-dark fw-semibold' data-bs-toggle="collapse" data-bs-target="#description">Description</h5>

              <div id='description' className="accordion-collapse collapse show mt-2 ms-2">
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat nobis iusto assumenda iste dolorem eligendi dolor distinctio consectetur, eum ut. Nisi deserunt at praesentium nemo assumenda quo blanditiis quam, rem totam nobis hic natus non amet soluta, ea qui ex? Perspiciatis architecto eos id commodi doloremque dolorum tempore quas quod nostrum! Cum illo maxime numquam quas nemo quae perferendis nihil repellendus saepe necessitatibus aperiam optio enim non id sed dolores distinctio nisi, et earum vel autem consequatur possimus? Ratione reprehenderit natus mollitia nobis ullam et? Minima libero id impedit aut tempora, reiciendis dolorem nam dolore ipsam dolor repellendus ullam! Nostrum!</span>

                <hr className='hr bg-secondary' />
              </div>
            </div>

             {/* Product Detail */}
             <div className='mb-3'>
              <h5 className='accordion-button bg-transparent shadow-none text-dark fw-semibold' data-bs-toggle="collapse" data-bs-target="#productDetail">Product Detail</h5>

              <div id='productDetail' className="accordion-collapse collapse show mt-2 ms-2">
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat nobis iusto assumenda iste dolorem eligendi dolor distinctio consectetur, eum ut. Nisi deserunt at praesentium nemo assumenda quo blanditiis quam, rem totam nobis hic natus non amet soluta, ea qui ex? Perspiciatis architecto eos id commodi doloremque dolorum tempore quas quod nostrum! Cum illo maxime numquam quas nemo quae perferendis nihil repellendus saepe necessitatibus aperiam optio enim non id sed dolores distinctio nisi, et earum vel autem consequatur possimus? Ratione reprehenderit natus mollitia nobis ullam et? Minima libero id impedit aut tempora, reiciendis dolorem nam dolore ipsam dolor repellendus ullam! Nostrum!</span>

                <hr className='hr bg-secondary' />
              </div>
            </div>
          </div>

          {/* Laptop > Add to Cart */}
          <div className='d-none d-lg-block mb-3 text-center'>
            <button type='button' className='btn btn-primary text-white w-75 text-center p-2 rounded-pill'>Add to Cart</button>
          </div>

          {/* Related Items */}
          <div className='mb-5'>
            <h4 className='mb-3'>Related Items</h4>

            <Slider {...relatedSettings}>
              {
                relatedItems.map((items, index) => (
                  <Link className='' to={`/SoftCorner/ProposeOption-Gifts/item`} key={index}>
                    <div className='bg-secondary-subtle rounded-4 px-1 py-2 me-2 me-sm-3'>
                      <img src={items.img} className='w-100 object-fit-contain' style={{ height: '115px' }} alt="sc-pog-img-1" />

                      <p className='mx-auto text-nowrap overflow-hidden text-truncate my-2 fw-bold' style={{ width: '90%' }}>{items.name}</p>

                      <div className="d-flex mx-auto" style={{ width: '90%' }}>
                        <p className="me-auto">₹ {items.price}</p>
                        
                        <div className="d-flex align-items-center bg-primary px-2 rounded-4">
                          <p className="me-2 text-white">5</p>
                          <img src="https://demo3.sjainventures.com/e-milo/images/star.png" style={{ width: '13px' }} alt="star" />
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              }
            </Slider>
          </div>

          {/* Reviews */}
          <div className='mb-5'>
            <h4 className='mb-3'>Reviews</h4>

            <Slider {...reviewSettings}>
              {
                productReviews.map((reviews, index) => (
                  <div className='pe-2 pe-sm-3 pe-lg-0 pe-xl-3'>
                    <div className='bg-danger-subtle p-2 rounded-4' style={{ width: '97% !importent' }} key={index}>
                      <div className='d-flex align-items-center'>
                        <img src={reviews.profile} className='rounded-circle shadow-sm me-3' style={{ height: '50px', width: '50px' }} alt="profile" />

                        <h4>{reviews.name}</h4>
                      </div>                  

                      {/* Rating */}
                      <Rating
                        className='text-center my-3 me-3'
                        initialRating={reviews.rating}
                        readonly
                        emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '15px' }} alt="empty" />}
                        fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '15px' }} alt="fill" />}
                      />

                      <span>{reviews.date}</span>

                      <span>{reviews.review}</span>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProposeOptionGifts2
