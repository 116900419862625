import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../UseContext/ApiCall";
import { checkEmail, checkNumber, email_otp_send } from "../../../UseContext/ApiHelpers";
import { BASE_URL_STATIC } from "../../../../../config";
import axios from "axios";
import toast from "react-hot-toast";

const ForgetPassword = () => {

   const [emailMessage, setEmailMessage] = useState("");
   const [numberMessage, setNumberMessage] = useState(false);
   const [btnDisable, setbtnDisable] = useState(true)

   const { phoneNumber, setPhoneNumber , otpEmail,setOtpEmail } = useContext(ApiContext)

   function handleChange(e) {
      const { value } = e.target;

      if (value.length === 0 || value === "" || value === null) {
         setEmailMessage(null)
         setNumberMessage(null)
      }
      else if (value.includes("@") && (value.includes(".com") || value.includes(".in") || value.includes(".org"))) {
         checkEmail(value).then((res) => {
            console.log("res", res);
            if (res !== "email already taken") {
               setEmailMessage("sorry! email not registered");
               setbtnDisable(true)
            } else {
               setEmailMessage(null);
               setbtnDisable(false)
               setOtpEmail(value)
            };
         });
      }

      else if (/^\d{10}$/.test(value)) {
         
         checkNumber("91"+value).then((res) => {
            if (res !== "mobile number already register") {
               setNumberMessage("sorry! mobile number not registered");
               setbtnDisable(true)

            } else {
               setNumberMessage(null);
               setbtnDisable(false)
               setPhoneNumber(value)
            };
         });
      }
      else {
         setbtnDisable(true)
      }
   };

   useEffect(() => {
      setbtnDisable(true)
   }, []);

   const nav = useNavigate();

   const sendotp = async (e) => {
      e.preventDefault();
      if (phoneNumber !== null) {
         try {
            await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: "91"+ phoneNumber }).then((res) => {
               toast.success(res.data.message)
               nav("/signup/fp-verify-otp")
               setbtnDisable(false)
            })
         } catch (error) {
            toast.error("failed to send otp")
            setbtnDisable(false)
         }
      }

      else {
         email_otp_send(otpEmail).then((res) => {
            toast.success(res)
            nav("/signup/fp-verify-otp")
            setbtnDisable(false)
         }).catch(() => {
            toast.error("failed to send otp")
            setbtnDisable(false)
         })

      }
   };

   return (
    <div className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100 ">
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/forget-pass.png`} alt="logo" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0">
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="" srcSet="" />
        </div>

        <form className="w-75 mx-auto" onSubmit={sendotp}>
          <h4 className="eam-rb-header mx-0 my-2 font-weight-bold">Forgot Password</h4>

          <small className="my-2">Please provide the Email /Mobile number associated with your account for password reset.</small>

          <input type="text" className="form-control form-control-lg rounded-pill mt-3" onChange={handleChange} placeholder="Email/mobile" />
          <small className="text-danger ms-2">{emailMessage}</small>
          <small className="text-danger ms-2">{numberMessage}</small> <br />

          <button type="button" onClick={sendotp} className="btn btn-primary rounded-pill w-100 fs-5" disabled={btnDisable}>Next</button>
        </form>
      </section>
      </div>
   );
};

export default ForgetPassword;
