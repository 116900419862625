import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ScApiContext } from './useContext/softCornerContext';

const ScProtected = ({ component: Component }) => {

    const nav = useNavigate()
    const { isSoftCornerPartnerExist } = useContext(ScApiContext)
    console.log("issoft", isSoftCornerPartnerExist)
    useEffect(() => {
        if (!isSoftCornerPartnerExist) {
            nav('/SoftCorner')
        }
    }, [isSoftCornerPartnerExist , nav])
    return (
        <div>
            {isSoftCornerPartnerExist && <Component />}
        </div>
    )
}

export default ScProtected