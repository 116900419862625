import React from 'react';
import { BASE_URL_STATIC } from '../../../config';

const NoupcommingEvents = () => {
   return (
      <div className='noupcommingevents'>
         <img src={`${BASE_URL_STATIC}/noupcomming-event.png`} alt="no event" />

         <span>
            <span>
               You haven&apos;t added any event : &#41;
            </span>
            <span style={{color:"#d3d3d3"}}>
            Create an event and share a quality time with your loved ones.
            </span>
         </span>
      </div>
   )
}

export default NoupcommingEvents