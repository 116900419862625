import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BASEAPIURL } from '../../../config';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import toast from 'react-hot-toast';


export const businessContext = createContext();


const BusinessContext = ({ children }) => {

  const [paramsPageId, setParamsPageId] = useState('');

  const { config } = useContext(ApiContext);

  const pageConfig = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("pageToken")}`,
      // 'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json',
    },
  };


  // ---------- Basic Info ----------

  const [pageBasicInfo, setPageBasicInfo] = useState({
    pageName: '',
    pageEmail: '',
    pageType: '',
    pageCategory: '',
    pageSubCategory: '',
  });

  const categories = [
    {
      category: "Technology",
      subcategories: [
        "Web Development",
        "Mobile Apps",
        "AI & Machine Learning",
        "Blockchain",
        "Software"
      ]
    },
    {
      category: "Fashion",
      subcategories: [
        "Clothing",
        "Accessories",
        "Footwear",
        "Jewelry",
        "Beauty Products"
      ]
    },
    {
      category: "Food & Beverage",
      subcategories: [
        "Restaurants",
        "Cafes",
        "Bakeries",
        "Organic Foods",
        "Beverages"
      ]
    },
    {
      category: "Health & Fitness",
      subcategories: [
        "Gyms",
        "Yoga Centers",
        "Nutrition",
        "Wellness Products",
        "Physical Therapy"
      ]
    },
    {
      category: "Education",
      subcategories: [
        "Schools",
        "Colleges",
        "Online Courses",
        "Tutoring",
        "Skill Development"
      ]
    },
    {
      category: "Finance",
      subcategories: [
        "Banking",
        "Investment",
        "Insurance",
        "Accounting",
        "Cryptocurrency"
      ]
    },
    {
      category: "Entertainment",
      subcategories: [
        "Movies",
        "Music",
        "TV Shows",
        "Events",
        "Theater"
      ]
    },
    {
      category: "Real Estate",
      subcategories: [
        "Residential",
        "Commercial",
        "Rental Services",
        "Property Management",
        "Architecture"
      ]
    },
    {
      category: "Travel & Tourism",
      subcategories: [
        "Hotels",
        "Flights",
        "Tour Operators",
        "Car Rentals",
        "Travel Guides"
      ]
    },
    {
      category: "Sports",
      subcategories: [
        "Cricket",
        "Football",
        "Hockey",
      ]
    },
  ];

  const [myPages, setMyPages] = useState({
    businessPages: [],
    publicFigurePages: [],
  });  

  const [pages, setPages] = useState({
    businessPages: [],
    publicFigurePages: [],
  });  


  // To handle create business or public figure page
  const handleCreatePage = () => {
    axios.post(`${BASEAPIURL}/page/createPage`, pageBasicInfo, config)
    .then(res => {
      if(res.data.message === 'Page created successfully') {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      };

      localStorage.setItem('pageId', res.data.pageData._id);
      localStorage.setItem('pageToken', res.data.pageToken);

      // getPageDetails();

      window.location.href = `${pageBasicInfo.pageType === 'businessPage' ? '/business' : '/public-figure'}/${res.data.pageData._id}`;
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // Get logged in user (business or pubilc figure) pages to show in header dropdown
  const getMyPages = () => {
    axios.post(`${BASEAPIURL}/page/getMyPages`, {}, config)
    .then(res => {
      setMyPages({
        businessPages: res.data.businessPages || [],
        publicFigurePages: res.data.publicFigurePages || [],
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // Get logged in user (business or pubilc figure) pages to show in header dropdown
  const getPages = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPages`, { paramsPageId })
      .then(res => {
        setPages({
          businessPages: res.data.businessPages || [],
          publicFigurePages: res.data.publicFigurePages || [],
        });
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };


  // ---------- Home ----------

  const [showCreatePostPopup, setShowCreatePostPopup] = useState(false);
  const [showEditPagePopup, setShowEditPagePopup] = useState(false);  
  const [pageDetails, setPageDetails] = useState();
  const [pageBanners, setPageBanners] = useState([]);
  const [isItMyPage, setIsItMyPage] = useState(false);
  
  const pageId = localStorage.getItem('pageId'); 

  // To get page token
  const getPageToken = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageToken`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);

        toast.success(res.data.message);

        if(res.data.pageToken) {
          localStorage.setItem('pageToken', res.data.pageToken);
        } else {
          localStorage.removeItem('pageToken');
        };
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };
  
  // Get page details
  const getPageDetails = (paramsPageId) => {    
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageDetails`, { paramsPageId })
      .then(res => {
        setPageDetails(res.data.pageDetails);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // Upload page profile
  const uploadPageProfile = (profile) => {
    console.log('profile : ', profile); 

    const formData = new FormData();

    formData.append('profile', profile); 

    axios.post(`${BASEAPIURL}/page/uploadPageProfile`, formData, pageConfig)
    .then((res) => {
      console.log('res : ', res);

      getPageDetails(paramsPageId);
    })
    .catch((err) => {
      console.error('err : err', err);
    });
  };

  // create page banner 
  const createPageBanner = (banners) => {
    const formData = new FormData();

    for (let i = 0; i < banners.length; i++) {
      formData.append('banners', banners[i]);
    };

    axios.post(`${BASEAPIURL}/page/createPageBanner`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      getPageBanner(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page banner
  const getPageBanner = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageBanner`, { paramsPageId })
      .then(res => {
        // console.log('res : ', res.data);
  
        setPageBanners(res.data.banners);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // delete page banner
  const deletePageBanner = (banner) => {
    console.log('delete : ', banner);

    axios.post(`${BASEAPIURL}/page/deletePageBanner`, { banner }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      getPageBanner(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // To check is it my page
  const checkIsItMyPage = (paramsPageId) => {    
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/checkIsItMyPage`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);
  
        setIsItMyPage(res.data.checkIsItMyPage);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };


  // ---------- Home > About ----------

  const [showBioPopup, setShowBioPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showMissionVisionPopup, setShowMissionVisionPopup] = useState(false);
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showContactInfoMobilePopup, setShowContactInfoMobilePopup] = useState(false);
  const [showContactInfoEmailPopup, setShowContactInfoEmailPopup] = useState(false);
  const [showDealerPopup, setShowDealerPopup] = useState(false);
  const [showWebsiteEmailPopup, setShowWebsiteEmailPopup] = useState(false)
  const [showCreateMilestonePopup, setShowCreateMilestonePopup] = useState(false);
  const [showMilestonePopup, setShowMilestonePopup] = useState(false);
  const [showActiveHoursPopup, setShowActiveHoursPopup] = useState(false);

  const [pageAbout, setPageAbout] = useState({
    bio: '',

    yearOfEstablishment: '',
    aboutPage: '',

    mission: '',
    vission: '',

    category: '',
    subCategory: '',

    address: [],

    contactInfo: {
      mobile:  [],
      email:  [],
    },

    dealer: [],

    websiteLink: '',
    email: '',

    milestone: [],

    activeHours: {
      startDay: '',
      endDay: '',
      startTime: '',
      endTime: '',
    },
  });

  const [newAddress, setNewAddress] = useState({
    name: '',
    line1: '',
    line2: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [cities, setCities] = useState([]);

  const stateCityData = [
    { state: 'Andhra Pradesh', cities: ['Visakhapatnam', 'Vijayawada', 'Guntur', 'Nellore'] },
    { state: 'Arunachal Pradesh', cities: ['Itanagar', 'Naharlagun', 'Tawang', 'Pasighat'] },
    { state: 'Assam', cities: ['Guwahati', 'Dibrugarh', 'Silchar', 'Tezpur'] },
    { state: 'Bihar', cities: ['Patna', 'Gaya', 'Bhagalpur', 'Muzaffarpur'] },
    { state: 'Chhattisgarh', cities: ['Raipur', 'Bhilai', 'Korba', 'Bilaspur'] },
    { state: 'Goa', cities: ['Panaji', 'Margao', 'Vasco da Gama', 'Mapusa'] },
    { state: 'Gujarat', cities: ['Ahmedabad', 'Surat', 'Vadodara', 'Rajkot'] },
    { state: 'Haryana', cities: ['Faridabad', 'Gurgaon', 'Panipat', 'Ambala'] },
    { state: 'Himachal Pradesh', cities: ['Shimla', 'Manali', 'Dharamshala', 'Solan'] },
    { state: 'Jharkhand', cities: ['Ranchi', 'Jamshedpur', 'Dhanbad', 'Bokaro'] },
    { state: 'Karnataka', cities: ['Bengaluru', 'Mysuru', 'Mangaluru', 'Hubballi'] },
    { state: 'Kerala', cities: ['Thiruvananthapuram', 'Kochi', 'Kozhikode', 'Thrissur'] },
    { state: 'Madhya Pradesh', cities: ['Bhopal', 'Indore', 'Gwalior', 'Jabalpur'] },
    { state: 'Maharashtra', cities: ['Mumbai', 'Pune', 'Nagpur', 'Nashik'] },
    { state: 'Manipur', cities: ['Imphal', 'Thoubal', 'Bishnupur', 'Churachandpur'] },
    { state: 'Meghalaya', cities: ['Shillong', 'Tura', 'Jowai', 'Nongstoin'] },
    { state: 'Mizoram', cities: ['Aizawl', 'Lunglei', 'Serchhip', 'Champhai'] },
    { state: 'Nagaland', cities: ['Kohima', 'Dimapur', 'Mokokchung', 'Tuensang'] },
    { state: 'Odisha', cities: ['Bhubaneswar', 'Cuttack', 'Rourkela', 'Sambalpur'] },
    { state: 'Punjab', cities: ['Ludhiana', 'Amritsar', 'Jalandhar', 'Patiala'] },
    { state: 'Rajasthan', cities: ['Jaipur', 'Jodhpur', 'Udaipur', 'Kota'] },
    { state: 'Sikkim', cities: ['Gangtok', 'Namchi', 'Gyalshing', 'Mangan'] },
    { state: 'Tamil Nadu', cities: ['Chennai', 'Coimbatore', 'Madurai', 'Tiruchirappalli'] },
    { state: 'Telangana', cities: ['Hyderabad', 'Warangal', 'Nizamabad', 'Karimnagar'] },
    { state: 'Tripura', cities: ['Agartala', 'Dharmanagar', 'Udaipur', 'Kailashahar'] },
    { state: 'Uttar Pradesh', cities: ['Lucknow', 'Kanpur', 'Agra', 'Varanasi'] },
    { state: 'Uttarakhand', cities: ['Dehradun', 'Haridwar', 'Rishikesh', 'Nainital'] },
    { state: 'West Bengal', cities: ['Kolkata', 'Asansol', 'Siliguri', 'Durgapur'] }
  ];

  const [contactInfo, setContactInfo] = useState({
    name: '',
    mobile: '',
    email: '',
  });

  const [dealer, setDealer] = useState({
    name: '',
    website: '',
  });

  const [milestone, setMilestone] = useState({
    image: null,
    heading: '',
    date: '',
    description: '',
  });
  
  const [activeHours, setActiveHours] = useState({
    startDay: '',
    endDay: '',
    startTime: '',
    endTime: '',
  });

  
  // create active hours
  const createActiveHours = () => {
    if(activeHours.startDay !== '' && activeHours.endDay !== '' && activeHours.startTime !== '' && activeHours.endTime !== '') {
      axios.post(`${BASEAPIURL}/page/createActiveHours`, { activeHours }, pageConfig)
      .then(res => {
        // console.log('res : ', res);

        toast.success(res.data.message);

        setShowActiveHoursPopup(false);

        setActiveHours({
          startDay: '',
          endDay: '',
          startTime: '',
          endTime: '',
        });

        getActiveHours();
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Fill Properly');
    };
  };

  // get active hours
  const getActiveHours = () => {
    axios.post(`${BASEAPIURL}/page/getActiveHours`, {}, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setPageAbout(prevState => ({
        ...prevState,
        activeHours: {
          ...prevState.activeHours,
          startDay: res.data.activeHours.startDay,
          endDay: res.data.activeHours.endDay,
          startTime: res.data.activeHours.startTime,
          endTime: res.data.activeHours.endTime,
        }
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create milestone
  const createMilestone = () => {
    const formData = new FormData();

    formData.append('image', milestone.image);
    formData.append('heading', milestone.heading);
    formData.append('date', milestone.date);
    formData.append('description', milestone.description);

    axios.post(`${BASEAPIURL}/page/createMilestone`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setMilestone({
        image: null,
        heading: '',
        date: '',
        description: '',
      });

      setPageAbout((prevState) => ({
        ...prevState,
        milestone: [...prevState.milestone, res.data.milestone],
      }));

      setShowCreateMilestonePopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };
  
  // create page website link & email
  const createWebsiteEmail = () => {
    if(pageAbout.email !== '') {
      axios.post(`${BASEAPIURL}/page/createWebsiteEmail`, { websiteLink: pageAbout.websiteLink, email: pageAbout.email }, pageConfig)
      .then(res => {
        console.log('res : ', res);

        if(res.data.message === 'Email already exist') {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        };

        setShowWebsiteEmailPopup(false);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Enter Email');
    }
  };

  // create page dealer
  const createDealer = () => {
    if(dealer.name !== '' && dealer.website !== '') {
      axios.post(`${BASEAPIURL}/page/createDealer`, { dealer }, pageConfig)
      .then(res => {
        console.log('res : ', res);

        setShowDealerPopup(false);

        getDealer();

        setDealer({
          name: '',
          website: '',
        });
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Fill Form Properly');
    };
  };

  // get page dealer
  const getDealer = () => {
    axios.post(`${BASEAPIURL}/page/getDealer`, {}, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      setPageAbout(preState => ({
        ...preState,
        dealer: res.data.dealer
      }))
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete page dealer
  const deleteDealer = (dealerId) => {
    axios.post(`${BASEAPIURL}/page/deleteDealer`, { dealerId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      // Remove the dealer from the front end
      setPageAbout(prevState => ({
        ...prevState,
        dealer: prevState.dealer.filter(dealer => dealer._id !== dealerId)
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page contact info
  const createContactInfo = (type) => {
    const info = (type === 'mobile') ? contactInfo.mobile :  contactInfo.email;

    axios.post(`${BASEAPIURL}/page/createContactInfo`, { _id: contactInfo._id, name: contactInfo.name, [type]: info }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      // it will update in front end
      setContactInfo({
        name: '',
        mobile: '',
        email: '',
      });

      // it will close popup accordingly type
      if(type === 'mobile') {
        setShowContactInfoMobilePopup(false);
      } else {
        setShowContactInfoEmailPopup(false);
      };

      getContactInfo();
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page contact info
  const getContactInfo = () => {
    axios.post(`${BASEAPIURL}/page/getContactInfo`, {}, pageConfig)
    .then(res => {
      console.log('res : ', res.data.contactInfo); 

      setPageAbout(prevState => ({
        ...prevState,
        contactInfo: {
          mobile: res.data.contactInfo.mobile,
          email: res.data.contactInfo.email,
        },
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  }; 

  // delete contact info
  const deleteContactInfo = (infoId) => {        
    axios.post(`${BASEAPIURL}/page/deleteContactInfo`, { infoId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      // Remove the data from front end
      setPageAbout(prevState => ({
        ...prevState,
        contactInfo: {
          mobile: prevState.contactInfo.mobile.filter(info => info._id !== infoId),
          email: prevState.contactInfo.email.filter(info => info._id !== infoId),
        },
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page address
  const createPageAddress = () => {
    if(newAddress.name !== '' && newAddress.line1 !== '' && newAddress.line2 !== '' && newAddress.state !== '' && newAddress.city !== '' && newAddress.pincode !== '') {
      axios.post(`${BASEAPIURL}/page/createPageAddress`, { newAddress }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data); 
  
        toast.success(res.data.message);
  
        setShowAddressPopup(false);

        getPageAddress();
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Fill Form Properly');
    };
  };

  // get page address
  const getPageAddress = () => {
    axios.post(`${BASEAPIURL}/page/getPageAddress`, {}, pageConfig)
    .then(res => {
      console.log('res : ', res.data.address); 

      setPageAbout((prevState) => ({
        ...prevState,
        address: res.data.address
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  }; 

  // delete page address
  const deletePageAddress = (addressId) => {
    axios.post(`${BASEAPIURL}/page/deletePageAddress`, { addressId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      // Remove address from front end
      setPageAbout((prevState) => ({
        ...prevState,
        address: prevState.address.filter(add => add._id !== addressId)
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page category & sub category
  const createCategorySubCategory = () => {
    if(pageAbout.category !== '' && pageAbout.subCategory !== '') {
      axios.post(`${BASEAPIURL}/page/createCategorySubCategory`, { category: pageAbout.category, subCategory: pageAbout.subCategory }, pageConfig)
      .then(res => {
        console.log('res : ', res.data); 
  
        toast.success(res.data.message);
  
        setShowCategoryPopup(false);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Select Sub Category');
    };
  };

  // create page mission & vission
  const createMissionVission = () => {
    axios.post(`${BASEAPIURL}/page/createMissionVission`, { mission: pageAbout.mission, vission: pageAbout.vission }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      toast.success(res.data.message);

      setShowMissionVisionPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page info
  const createPageInfo = () => {
    axios.post(`${BASEAPIURL}/page/createPageInfo`, { yearOfEstablishment: pageAbout.yearOfEstablishment, aboutPage: pageAbout.aboutPage }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      toast.success(res.data.message);

      setShowInfoPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page bio
  const createPageBio = async () => {
    await axios.post(`${BASEAPIURL}/page/createPageBio`, { pageBio: pageAbout.bio }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowBioPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // To get business page > about page details
  const getPageAboutDetails = async (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageAboutDetails`, { paramsPageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        setPageAbout((prevState) => ({
          ...prevState,
          bio: res.data.bio,
  
          yearOfEstablishment: res.data.yearOfEstablishment,
          aboutPage: res.data.aboutPage,
  
          mission: res.data.mission,
          vission: res.data.vission,
          
          category: res.data.category,
          subCategory: res.data.subCategory,
  
          address: res.data.address,
  
          contactInfo: {
            mobile: res.data.contactInfo.mobile,
            email: res.data.contactInfo.email,
          },
  
          dealer: res.data.dealer,
  
          websiteLink: res.data.websiteLink,
          email: res.data.email,
  
          milestone: res.data.milestone,
  
          activeHours: res.data.activeHours,
        }));
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };



  // ---------- Home > Product & Services ----------

  const [showAddProductPopup, setShowAddProductPopup] = useState(false);
  const [showAddServicePopup, setShowAddServicePopup] = useState(false);

  const [productsServices, setProductsServices] = useState({
    products: [],
    services: [],
  });

  // console.log('productsServices : ', productsServices);

  const [addNewProductService, setAddNewProductService] = useState({
    image: null,
    heading: '',
    description: '',
    price: '',
    link: '',
  });

  // console.log('addNewProductService : ', addNewProductService);

  // create new product
  const createNewProductService = (type) => {   
    if(
      addNewProductService.image !== null && 
      addNewProductService.heading !== '' && 
      addNewProductService.description !== '' && 
      addNewProductService.price !== '' && 
      (type !== 'product' || addNewProductService.link !== '')
    ) {
      const formData = new FormData();
  
      formData.append('image', addNewProductService.image);
      formData.append('heading', addNewProductService.heading);
      formData.append('description', addNewProductService.description);
      formData.append('price', addNewProductService.price);
      formData.append('link', addNewProductService.link);
      formData.append('type', type);
  
      axios.post(`${BASEAPIURL}/page/createNewProductService`, formData, pageConfig)
      .then(res => {       
        // console.log('res : ', res.data);

        if (type === 'product') {
          setProductsServices(prevState => ({ ...prevState, products: [...prevState.products, res.data.newProduct],  }));

          setShowAddProductPopup(false);

          toast.success('Product added succesfully');
        } else if (type === 'service') {
          setProductsServices(prevState => ({ ...prevState, services: [...prevState.services, res.data.newProduct], }));

          setShowAddServicePopup(false);

          toast.success('Service added succesfully');
        };

        setAddNewProductService({
          image: null,
          heading: '',
          description: '',
          price: '',
          link: '',
        });
      })
      .catch(err => {
        console.error('err : ', err);
      });
    } else {
      toast.error('Fill Form Properly');
    };
  };

  // get page products & services
  const getProductsServices = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getProductsServices`, { paramsPageId })
      .then(res => {
        // console.log('res : ', res.data);
  
        setProductsServices(res.data.productsServices);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };



  // ---------- Home > Review ----------

  const [showWriteReveiwPopup, setShowWriteReveiwPopup] = useState(false);
  const [showReplyReviewPopup, setShowReplyReviewPopup] = useState(false);
  const [addNewReview, setAddNewReview] = useState({
    rating: '',
    review: '',
  });
  const [pageReviews, setPageReviews] = useState([]);
  const [replyReviewData, setReplyReviewData] = useState();

  // create page review 
  const createPageReview = (paramsPageId) => {
    axios.post(`${BASEAPIURL}/page/createPageReview`, { addNewReview, pageId: paramsPageId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowWriteReveiwPopup(false);

      setAddNewReview({
        rating: '',
        review: '',
      });

      getPageReviews(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page reviews
  const getPageReviews = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageReviews`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);
  
        setPageReviews(res.data.reviews);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // reply to a review 
  const replyReview = () => {
    axios.post(`${BASEAPIURL}/page/replyReview`, { replyReviewData }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowReplyReviewPopup(false);

      // Update reply to front end
      setPageReviews(prevReviews => {
        const updatedReviews = prevReviews.map(review => {
          if (review._id === replyReviewData._id) {
            return {
              ...review,
              reply: replyReviewData.reply, 
              updatedAt: new Date().toISOString() 
            };
          }
          return review; 
        });

        return updatedReviews;
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // like dislike review
  const likeDislikeReview = (review) => {    
    axios.post(`${BASEAPIURL}/page/likeDislikeReview`, { reviewId: review._id, postedBy: review.userId._id }, config)
    .then(res => {
      console.log('res : ', res);
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Home > Listing ----------

  const [showApplyJobPopup, setShowApplyJobPopup] = useState(false);
  const [showAddJobsPopup, setShowAddJobsPopup] = useState(false);  
  const [showAddFrenchisePopup, setShowAddFrenchisePopup] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [frenchises, setFrenchises] = useState([]);

  const [addNewJob, setAddNewJob] = useState({
    image: null,
    heading: '',
    description: '',
    salary: '',
    experience: 'fresher',
  });

  const [addNewFrenchise, setAddNewFrenchise] = useState({
    image: null,
    heading: '',
    description: '',
    number: '',
    email: '',
  });


  // create a new job
  const createJob = () => {
    const formData = new FormData();
  
    formData.append('image', addNewJob.image);
    formData.append('heading', addNewJob.heading);
    formData.append('description', addNewJob.description);
    formData.append('salary', addNewJob.salary);
    formData.append('experience', addNewJob.experience);

    axios.post(`${BASEAPIURL}/page/createJob`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setShowAddJobsPopup(false);

      setAddNewJob({
        image: null,
        heading: '',
        description: '',
        salary: '',
        experience: 'fresher',
      });

      getJobs(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get jobs
  const getJobs = (paramsPageId) => {
    if(paramsPageId) {
      axios.post(`${BASEAPIURL}/page/getJobs`, { paramsPageId })
      .then(res => {
        // console.log('res : ', res.data.jobs);

        setJobs(res.data.jobs);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // delete job 
  const deleteJob = (jobId) => {
    axios.post(`${BASEAPIURL}/page/deleteJob`, { jobId }, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      getJobs(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create a new frenchise
  const createFrenchise = () => {
    const formData = new FormData();
  
    formData.append('image', addNewFrenchise.image);
    formData.append('heading', addNewFrenchise.heading);
    formData.append('description', addNewFrenchise.description);
    formData.append('number', addNewFrenchise.number);
    formData.append('email', addNewFrenchise.email);

    axios.post(`${BASEAPIURL}/page/createFrenchise`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setShowAddFrenchisePopup(false);

      setAddNewFrenchise({
        image: null,
        heading: '',
        description: '',
        number: '',
        email: '',
      });

      getFrenchises(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get frenchises
  const getFrenchises = (paramsPageId) => {
    if(paramsPageId) {
      axios.post(`${BASEAPIURL}/page/getFrenchises`, { paramsPageId })
      .then(res => {
        // console.log('res : ', res.data.frenchises);

        setFrenchises(res.data.frenchises);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // delete frenchise
  const deleteFrenchise = (frenchiseId) => {
    axios.post(`${BASEAPIURL}/page/deleteFrenchise`, { frenchiseId }, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      getFrenchises(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };




  // ---------- Event ----------

  const [showCreateEventPopup, setShowCreateEventPopup] = useState(false);
  const [showUpdateEventPopup, setShowUpdateEventPopup] = useState(false);
  const [showAddEventHostsPopup, setShowAddEventHostsPopup] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [eventOrganiserPage, setEventOrganiserPage] = useState({});
  const [eventHostsList, setEventHostsList] = useState([]);

  const [newEvent, setNewEvent] = useState({
    image: null,
    title: '',
    subTitle: '',
    about: '',
    mode: 'virtual',
    address: '',
    mobile: '',
    email: '',
    host: '',
    websiteLink: '',
    ticketLink: '',
    repeatEvent: 'off',
    repeatEventOption: 'daily',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    rsvp: 'off',
    boostEvent: 'off',
  });

  const [searchedHostsList, setSearchedHostsList] = useState([]);
  const [selectedHostsList, setSelectedHostsList] = useState([]);
  

  // create event
  const createEvent = () => {
    const formData = new FormData();
    
    for (const key in newEvent) {
      formData.append(key, newEvent[key]);
    };

    formData.append("selectedHostsList", JSON.stringify(selectedHostsList));

    axios.post(`${BASEAPIURL}/page/createEvent`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setShowCreateEventPopup(false);

      setNewEvent({
        image: null,
        title: '',
        subTitle: '',
        about: '',
        mode: 'virtual',
        address: '',
        mobile: '',
        email: '',
        host: '',
        websiteLink: '',
        ticketLink: '',
        repeatEvent: 'off',
        repeatEventOption: 'daily',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        rsvp: 'off',
        boostEvent: 'off',
      });

      setSelectedHostsList([]);

      getMyEvents();
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get my events
  const getMyEvents = () => {
    axios.post(`${BASEAPIURL}/page/getMyEvents`, {}, pageConfig)
    .then(res => {
      // console.log('res : ', res.data.myEvents);

      setMyEvents(res.data.myEvents);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // update my event
  const updateEvent = () => {
    axios.post(`${BASEAPIURL}/page/updateEvent`, { event }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowUpdateEventPopup(false);

      // getEvent(res.data.eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete my event 
  const deleteEvent = (eventId) => {
    axios.post(`${BASEAPIURL}/page/deleteEvent`, { eventId }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      window.location.href = `/business/my-events/${paramsPageId}`;
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get events
  const getEvents = () => {
    axios.post(`${BASEAPIURL}/page/getEvents`, {}, pageConfig)
    .then(res => {
      // console.log('res : ', res.data.events);

      setEvents(res.data.events);
    }).catch(err => {
      console.log('err : ', err); 
    });
  };

  // get event 
  const getEvent = (eventId) => {
    if(eventId !== '') {
      axios.post(`${BASEAPIURL}/page/getEvent`, { eventId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data.event);

        setEvent(res.data.event);

        setEvent(preState => ({ ...preState, myEvent: res.data.myEvent }));
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // get event organiser page
  const getEventOrganiserPage = (eventId) => {
    axios.post(`${BASEAPIURL}/page/getEventOrganiserPage`, { eventId })
    .then(res => {
      // console.log('res : ', res.data);

      setEventOrganiserPage(res.data.page);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get searched hosts (while creating event)
  const getSearchedHosts = (value) => {    
    if(value !== '') {
      axios.post(`${BASEAPIURL}/page/getSearchedHosts`, { value })
      .then(res => {
        // console.log('res : ', res.data.users);
  
        setSearchedHostsList(res.data.users);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      setSearchedHostsList([]);
    };
  };

  // get searched hosts (after creating event, it will show filtered hosts)
  const getHostsToAdd = (value, eventId) => {
    if(value !== '') {
      axios.post(`${BASEAPIURL}/page/getHostsToAdd`, { value, eventId })
      .then(res => {
        // console.log('res : ', res.data.users);

        setSearchedHostsList(res.data.users);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      setSearchedHostsList([]);
    };
  };

  // add event hosts
  const addEventHosts = (eventId) => {
    axios.post(`${BASEAPIURL}/page/addEventHosts`, { eventId, selectedHostsList }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddEventHostsPopup(false);

      setNewEvent(preState => ({ ...preState, host: '' }));
      setSearchedHostsList([]);
      setSelectedHostsList([]);

      getEventHosts(eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get event hosts
  const getEventHosts = (eventId) => {    
    axios.post(`${BASEAPIURL}/page/getEventHosts`, { eventId })
    .then(res => {
      // console.log('res : ', res.data);

      setEventHostsList(res.data.hosts);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete event host
  const deleteEventHost = (hostId) => {
    axios.post(`${BASEAPIURL}/page/deleteEventHost`, { hostId }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      getEventHosts(res.data.eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // update event banner 
  const updateEventBanner = (file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('eventId', event._id);

    axios.post(`${BASEAPIURL}/page/updateEventBanner`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setEvent(preState => ({ ...preState, image: res.data.image }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  



  useEffect(() => {
    getPageToken(paramsPageId);
  }, [paramsPageId]);


  const Value = {

    pageConfig,

    paramsPageId, setParamsPageId,


    // ----- Home > Event -----
    showCreateEventPopup, setShowCreateEventPopup,
    showUpdateEventPopup, setShowUpdateEventPopup,
    showAddEventHostsPopup, setShowAddEventHostsPopup,
    newEvent, setNewEvent,
    myEvents, setMyEvents,
    events, setEvents,
    event, setEvent,
    eventOrganiserPage, setEventOrganiserPage,
    searchedHostsList, setSearchedHostsList,
    selectedHostsList, setSelectedHostsList,
    eventHostsList, setEventHostsList,
    createEvent,
    updateEvent,
    deleteEvent,
    getMyEvents, getEvents, getEvent, 
    getEventOrganiserPage, getSearchedHosts,
    getEventHosts, getHostsToAdd,
    addEventHosts, deleteEventHost,
    updateEventBanner,


    // ---------- Basic Info ----------
    pageBasicInfo, setPageBasicInfo,
    myPages, setMyPages,
    pages, setPages,
    categories,
    handleCreatePage,
    getMyPages,
    getPages,


    // ----- Home -----
    showCreatePostPopup, setShowCreatePostPopup,
    showEditPagePopup, setShowEditPagePopup,
    pageDetails, setPageDetails,
    pageBanners, setPageBanners,
    isItMyPage, setIsItMyPage,
    pageId,
    uploadPageProfile,
    // handleGoIntoPage,
    createPageBanner,
    deletePageBanner,
    checkIsItMyPage,
    getPageDetails,
    getPageBanner,


    // ----- Home > About -----
    showBioPopup, setShowBioPopup,
    showInfoPopup, setShowInfoPopup,
    showMissionVisionPopup, setShowMissionVisionPopup,
    showCategoryPopup, setShowCategoryPopup,
    showAddressPopup, setShowAddressPopup,
    showContactInfoMobilePopup, setShowContactInfoMobilePopup,
    showContactInfoEmailPopup, setShowContactInfoEmailPopup,
    showDealerPopup, setShowDealerPopup,
    showWebsiteEmailPopup, setShowWebsiteEmailPopup,
    showCreateMilestonePopup, setShowCreateMilestonePopup,
    showMilestonePopup, setShowMilestonePopup,
    showActiveHoursPopup, setShowActiveHoursPopup,

    pageAbout, setPageAbout,
    newAddress, setNewAddress,
    cities, setCities,
    contactInfo, setContactInfo,
    dealer, setDealer,
    milestone, setMilestone,
    activeHours, setActiveHours,
    stateCityData,
    createPageBio,
    createPageInfo,
    createMissionVission,
    createCategorySubCategory,
    createPageAddress, deletePageAddress,
    createContactInfo, deleteContactInfo,
    createDealer, deleteDealer,
    createWebsiteEmail,
    createMilestone,
    createActiveHours,
    getPageAboutDetails,


    // ----- Home > Product & Services -----

    showAddProductPopup, setShowAddProductPopup,
    showAddServicePopup, setShowAddServicePopup,
    productsServices, setProductsServices,
    addNewProductService, setAddNewProductService,
    createNewProductService,
    getProductsServices,


    // ----- Home > Review -----
    showWriteReveiwPopup, setShowWriteReveiwPopup,
    showReplyReviewPopup, setShowReplyReviewPopup,
    addNewReview, setAddNewReview,
    pageReviews, setPageReviews,
    replyReviewData, setReplyReviewData,
    createPageReview,
    getPageReviews,
    replyReview,
    likeDislikeReview,


    // ----- Home > Listing -----
    showApplyJobPopup, setShowApplyJobPopup,
    showAddJobsPopup, setShowAddJobsPopup,
    showAddFrenchisePopup, setShowAddFrenchisePopup,
    addNewJob, setAddNewJob,
    addNewFrenchise, setAddNewFrenchise,
    jobs, setJobs,
    frenchises, setFrenchises,
    createJob, getJobs, deleteJob,
    createFrenchise, getFrenchises, deleteFrenchise,


  };

  return (<businessContext.Provider value={Value}>{children}</businessContext.Provider>);
}

export default BusinessContext;