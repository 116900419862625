import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import { profileContext } from '../../UseContext/ProfileContext';

const ReportBestClip = () => {

  const { reportBestClip, setreportBestClip, handleReportPost,  showReportBestClipPopup, setShowReportBestClipPopup } = useContext(ApiContext);

  const {profileBestclipData} = useContext(profileContext)

  // console.log('a : ', profileBestClipPopupData);

  return (
    <>
      <section className='reportPostPopups'>
        <Modal show={showReportBestClipPopup} onHide={() => setShowReportBestClipPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report Bestclip</h4>

              <textarea className='reportPostPopup-textarea' value={reportBestClip} onChange={(e) => setreportBestClip(e.target.value)} name="report" placeholder='Report here...'></textarea>

              <span className='reportPostPopup-report' onClick={() => handleReportPost(profileBestclipData.bestclipId,'Bestclip', profileBestclipData.userId, reportBestClip)}>Report</span>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportBestClip
