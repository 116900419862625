import React from 'react'
import { Link } from 'react-router-dom'
import { BASEURL, BASE_URL_STATIC } from '../../../config'

const AddToLifePartner = () => {
  return (
    <>
      <section className='addToLifePartner'>
        <img className='addToLifePartner-logo' src={`${BASE_URL_STATIC}/SoftCorner/atlp-logo.png`} alt="atlp-logo" height={60} width={60} />

        <div className='addToLifePartner-head'>Add to Life Partner</div>

        <img className='addToLifePartner-couple' src={`${BASE_URL_STATIC}/SoftCorner/atlp-couple.png`} alt="atlp-couple" height={300} width={350} />

        <div className='addToLifePartner-message'>When two gentle-hearted individuals unknowingly choose each other as life partners, they are destined to be a perfect match and will eventually tie the knot.</div>

        <div className='addToLifePartner-tac'>
          <form>
            <input type="checkbox" id='termsAndConditions' name='termsAndConditions' />
            <label htmlFor="termsAndConditions">I agree to E-Milo’s soft corner <span>Terms And conditions</span></label>
          </form>
        </div>

        <Link to={`${BASEURL}/SoftCorner/Propose&GetMarried`} className='addToLifePartner-button'>Add to Life Partner</Link>
      </section>
    </>
  )
}

export default AddToLifePartner
