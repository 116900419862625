import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';

const LikedMemes = () => {

  const [likedMemes, setLikedMemes] = useState([]);

  const { config } = useContext(ApiContext);

  // To get liked memes
  const GetLikedMemes = async (config) => {
    await axios.post('https://emilonode.sjain.io/post/getmemelikebyuser', {}, config)
    .then(res => {
      // console.log(res.data.listMeme);
      setLikedMemes(res.data.listMeme.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    GetLikedMemes(config);
  }, []);

  return (
    <>
      <section className='liked-memes'>
        <h1 className='liked-memes-head'>Memes</h1>

        <div className='liked-memes-imgs'>
          {
            likedMemes.map((likedMeme, index) => (
              // console.log(likedMeme.details)
              likedMeme.details.map(detail => (
                // console.log(detail)
                <img className='liked-bestClips-img' src={detail.imageUrl} alt={detail.imageId} height={215} width={200} />
              ))
            ))
          }
        </div>
      </section>
    </>
  )
}

export default LikedMemes
