import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AwsFileUrl } from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';

const MessagePopup = ({ open, close, mediaFiles, activeMsgIndex, setActiveMsgIndex }) => {
    const sliderRef = useRef(null);
    const videoRefs = useRef([]);
    const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

    const activeMediaIndex = mediaFiles.findIndex(e => e.msgId === activeMsgIndex);

    useEffect(() => {
        // Set the current slide when the modal opens or the activeMsgIndex changes
        if (activeMediaIndex !== -1 && sliderRef.current) {
            sliderRef.current.slickGoTo(activeMediaIndex);
            setCurrentSlide(activeMediaIndex);
        }
    }, [activeMediaIndex, open]);

    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        beforeChange: (oldIndex, newIndex) => {
            // Pause all videos before changing slide
            videoRefs.current.forEach(video => {
                if (video) {
                    video.pause();
                }
            });
        },
        afterChange: (currentIndex) => {
            // Update the current slide state
            setCurrentSlide(currentIndex);

            // Set the new active message ID based on the next slide index
            const currentMedia = mediaFiles[currentIndex];
            if (currentMedia) {
                setActiveMsgIndex(currentMedia.msgId);
            }

            // Auto-play video if it's a video file
            if (currentMedia && currentMedia.type === 'video_file') {
                const currentVideo = videoRefs.current[currentIndex];
                if (currentVideo) {
                    currentVideo.play();
                }
            }
        }
    };

    // Custom navigation handlers
    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handlePrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className='msg_modal'>
            <Modal className='msg_modal' show={open} fullscreen={true} onHide={close}>
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <button className='btn' onClick={close}>
                            <FontAwesomeIcon icon={faXmark} color='#fff' />
                        </button>
                    </div>

                    {mediaFiles && mediaFiles.length > 0 && (
                        <div className='position-relative'>
                            <Slider ref={sliderRef} {...settings}>
                                {mediaFiles.map((media, i) => (
                                    <div key={i} className='d-flex justify-content-center align-items-center h-80vh w-80vw msg_pop'>
                                        {/* Render image file */}
                                        {media.type === 'img_file' && (
                                            <div>
                                                <img src={AwsFileUrl + media.url} alt="media" className='img_box' />
                                            </div>
                                        )}

                                        {/* Render video file */}
                                        {media.type === 'video_file' && (
                                            <div>
                                                <video
                                                    ref={(el) => videoRefs.current[i] = el}
                                                    controls
                                                >
                                                    <source src={AwsFileUrl + media.url} />
                                                </video>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </Slider>

                            {/* Custom prev/next buttons */}
                            <div className="btns">
                                <button 
                                    className='d-none d-md-block btn-prev' 
                                    onClick={handlePrev}
                                    disabled={currentSlide === 0} // Disable prev button if on the first slide
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} color='#fff' />
                                </button>
                                <button 
                                    className='d-none d-md-block btn-next' 
                                    onClick={handleNext}
                                    disabled={currentSlide === mediaFiles.length - 1} // Disable next button if on the last slide
                                >
                                    <FontAwesomeIcon icon={faAngleRight} color='#fff' />
                                </button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MessagePopup;
