import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from '../../UseContext/ApiCall';
import {  AwsFileUrl } from '../../../../config';
import {  GetuserFavorite } from '../../UseContext/ApiHelpers';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';


const FavouriteMemes = () => {

  const [favouriteMemes, setFavouriteMemes] = useState([]);
 
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData , setmainpoastData] = useState('')
  

  const { config } = useContext(ApiContext);

  // To get favourite memes
  const getFavouriteMemes = async(config) => {
  await  GetuserFavorite("memes", config)
    .then(res => {
      console.log("resdata", res);
      setFavouriteMemes(res.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteMemes(config);
  }, []);


  return (
    <>
      {/* Profile Memes Popup */}
    <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />

      <section className='favourite-memes'>
        <h1 className='favourite-memes-head'>Memes</h1>

        <div className='favourite-memes-imgs'>
          {
            favouriteMemes && favouriteMemes.map((favouriteMeme, index) => (
          
              <img className='favourite-memes-img' src={AwsFileUrl+favouriteMeme.mediaUrls[0].url} alt={favouriteMeme.mediaUrls[0].url}  onClick={() =>{setShowPostsPopup(!showPostsPopup); setmainpoastData(favouriteMeme);  setPostId(favouriteMeme._id)}} />
             
            ))
          }
        </div>
      </section>
    </>
  )
}

export default FavouriteMemes
