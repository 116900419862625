import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../config';


const UpdateEventPopup = () => {

  const { 
    event, setEvent,
    showUpdateEventPopup, setShowUpdateEventPopup, 
    updateEventBanner,
    updateEvent,
  } = useContext(businessContext);
  

  // ----- Update Banner -----

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      console.log('Selected file:', file);

      updateEventBanner(file);
    };
  };

  return (
    <>
      <Modal show={showUpdateEventPopup} onHide={() => setShowUpdateEventPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
        <section>
          <h5 className='text-center mb-4 fw-semibold'>Update Event</h5>

          {
            event
            &&
            <div className='overflow-y-scroll ps-1 pe-2' style={{ height: '75vh' }}>
              {/* Add banner */}
              <div className='w-100 mb-4 position-relative' style={{ height: '200px' }}>
                <img src={event && AwsFileUrl+event.image} className='h-100 w-100 rounded-4' alt="new event" />

                <div className="position-absolute top-0 mt-3 cursor-pointer bg-secondary-subtle pt-1 pe-1 pb-2 ps-2 rounded-3" style={{ right: '20px' }}>
                  <img src="/images/business/icons/edit-blue.svg" className="" style={{ height: '20px', width: '20px' }} onClick={handleIconClick} alt="edit" />
                </div>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
              </div>

              <h6 className='fw-semibold mb-2'>Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Title...' 
                value={event.title} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, title: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Sub Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Sub Title...' 
                value={event.subTitle} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, subTitle: e.target.value }))}
              />  

              <h6 className='fw-semibold mb-2'>About</h6>
              <textarea class="form-control mb-4" placeholder="Write Something Here About Event..."
                value={event.about} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, about: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mode</h6>
              <select class="form-select mb-4" value={event.mode} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, mode: e.target.value }))} aria-label="Default select example" >
                <option value="virtual">Virtual</option>
                <option value="inPerson">In Person</option>
              </select>

              <h6 className='fw-semibold mb-2'>Address</h6>
              <textarea class="form-control mb-4" placeholder="Enter Address..."
                value={event.address} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, address: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mobile</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Mobile...' 
                value={event.mobile} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, mobile: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Email</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Email...' 
                value={event.email} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, email: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Website Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Website Link...' 
                value={event.websiteLink} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, websiteLink: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Ticket Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Ticket Link...' 
                value={event.ticketLink} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, ticketLink: e.target.value }))}
              />

              <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                <h6 className='fw-semibold mb-0'>Repeat Event</h6>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    checked={event.repeatEvent === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, repeatEvent: e.target.checked ? 'on' : 'off' }))}
                  />
                </div>
              </div>

              <select class="form-select mb-4" value={event.repeatEventOption} disabled={event.repeatEvent === 'off'} aria-label="Default select example"
                onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, repeatEventOption: e.target.value }))} 
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>

              {/* Start & End Date */}
              <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>Start Date</h6>
                  <input type="date" className='form-control' min={new Date().toISOString().split("T")[0]}
                    value={event.startDate} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, startDate: e.target.value, endDate: '' }))} 
                  />
                </div>

                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>End Date</h6>
                  <input type="date" className='form-control' min={event.startDate || new Date().toISOString().split("T")[0]} disabled={event.startDate === ''} 
                    value={event.endDate} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, endDate: e.target.value }))} 
                  />
                </div>
              </div>

              {/* Start & End Time */}
              <>
                {
                  event.startDate && event.endDate
                  &&
                  <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>Start Time</h6>
                      <input type="time" className='form-control' min={new Date().toTimeString().split(" ")[0].slice(0, 5)}
                        value={event.startTime} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, startTime: e.target.value, endTime: '' }))} 
                      />
                    </div>

                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>End Time</h6>
                      <input type="time" className='form-control' min={event.startTime || new Date().toTimeString().split(" ")[0].slice(0, 5)} disabled={event.startTime === ''}
                        value={event.endTime} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, endTime: e.target.value }))} 
                      />
                    </div>
                  </div>
                }
              </>

              <div className='border rounded-3 p-3 mb-4'>
                <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                  <h6 className='fw-semibold mb-0'>RSVP's</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={event.rsvp === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, rsvp: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>

                <div className='d-flex gap-3 align-items-center justify-content-between'>
                  <h6 className='fw-semibold mb-0'>Boost Event</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={event.boostEvent === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, boostEvent: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>
              </div>

              <button type="button" className='btn btn-primary rounded-pill w-100' onClick={updateEvent}>Update</button>
            </div>
          }
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateEventPopup
