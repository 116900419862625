import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../../UseContext/ApiCall";
import Modal from 'react-bootstrap/Modal';
import toast from "react-hot-toast";
import { AwsFileUrl } from "../../../../../../config";
import { getStoryDataType } from "../../../../UseContext/ApiHelpers";
import ImageShow from "../../../../../Common/assets/imageTag";
import StoryEmiloGallaryPost from "./StoryEmiloGallaryPost";


const StoryEmiloGallaryPopup = () => {
  const [post, setPost] = useState([]);
  const [flix, setFlix] = useState([]);
  const [meme, setMeme] = useState([]);
  const [bestclip, setBestclip] = useState([]);
  const [selectedData, setSelectedData] = useState([]);  // to store selected image
  const [open , setOpen] = useState(false)

  const [page] = useState(1);

  
  const {config,
    showStoriesEmiloGalleryPopup,
    setShowStoriesEmiloGalleryPopup,
    setshowCreateStoriesPopup,
  } = useContext(ApiContext);

  const [menu, setMenu] = useState({
    posts: true,
    flixs: false,
    memes: false,
    bestclips: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (menu.posts) {
        await getStoryDataType('post', 'posttype', "", page, config, 20).then((res) => setPost(res))
      }
      else if (menu.flixs) {
        await getStoryDataType('flix', 'posttype', "", page, config, 20).then((res) => setFlix(res))
      }
      else if (menu.memes) {
        await getStoryDataType('memes', 'posttype', "", page, config, 20).then((res) => setMeme(res))
      }
      else if (menu.bestclips) {
        await getStoryDataType('bestclips', 'posttype', "", page, config, 20).then((res) => setBestclip(res))
      }
    };
    fetchData();
  }, [menu, page, config]);


  const handleImageSelect = (imageData) => {
    setSelectedData((prevSelectedData) => {
      // Check if the combination of _id and mediaUrl exists in the selectedData
      const isAlreadySelected = prevSelectedData.some(
        (item) => item._id === imageData._id && item.mediaUrl === imageData.mediaUrl
      );

      if (isAlreadySelected) {
        // If the combination is already selected, remove it (deselect)
        return prevSelectedData.filter(
          (item) => !(item._id === imageData._id && item.mediaUrl === imageData.mediaUrl)
        );
      } else {
        // If the combination is not selected, add it to the selectedData array
        return [...prevSelectedData, imageData];
      }
    });
  };

  return (
    <>
      <StoryEmiloGallaryPost selectedData={selectedData} setSelectedData={setSelectedData} open={open} close={() => {setOpen(false); setShowStoriesEmiloGalleryPopup(true)}} />

      <Modal
        show={showStoriesEmiloGalleryPopup}
        onHide={() => setShowStoriesEmiloGalleryPopup(false)}
        centered
        backdrop="static"
        keyboard={false}
        className="create-call create-stories-popup"
      >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className="p-3">
          {/* Header */}
          <div className="d-flex mt-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                setShowStoriesEmiloGalleryPopup(false);
                setshowCreateStoriesPopup(true);
              }}
            >
              ⮜
            </span>
            <h5 className="mb-0 mx-auto fw-semibold">Add to Stories</h5>
          </div>

          {/* Menu */}
          <div className="d-flex flex-wrap gap-2 align-items-center my-3 justify-content-between">
            {/* Posts */}
            <div
              className={`${menu.posts && "bg-primary"} border rounded-pill px-2 py-1 cursor-pointer d-flex align-items-center`}
              onClick={() => setMenu({ posts: true, flixs: false, memes: false, bestclips: false })}
            >
              <img
                src={`${AwsFileUrl}images/icon/gallery01.svg`}
                className="me-2"
                style={{ height: "20px" }}
                alt="post"
              />
              <span className={`${menu.posts && "text-white"}`}>Posts</span>
            </div>

            {/* Flixs */}
            <div
              className={`${menu.flixs && "bg-primary"} border rounded-pill px-2 py-1 cursor-pointer d-flex align-items-center`}
              onClick={() => setMenu({ posts: false, flixs: true, memes: false, bestclips: false })}
            >
              <img
                src={`${AwsFileUrl}images/menu-icon3.png`}
                className="me-2"
                style={{ height: "20px" }}
                alt="flixs"
              />
              <span className={`${menu.flixs && "text-white"}`}>Flixs</span>
            </div>

            {/* Memes */}
            <div
              className={`${menu.memes && "bg-primary"} border rounded-pill px-2 py-1 cursor-pointer d-flex align-items-center`}
              onClick={() => setMenu({ posts: false, flixs: false, memes: true, bestclips: false })}
            >
              <img
                src={`${AwsFileUrl}images/menu-icon5.png`}
                className="me-2"
                style={{ height: "20px" }}
                alt="memes"
              />
              <span className={`${menu.memes && "text-white"}`}>Memes</span>
            </div>

            {/* Bestclips */}
            <div
              className={`${menu.bestclips && "bg-primary"} border rounded-pill px-2 py-1 cursor-pointer d-flex align-items-center`}
              onClick={() => setMenu({ posts: false, flixs: false, memes: false, bestclips: true })}
            >
              <img
                src={`${AwsFileUrl}images/menu-icon6.png`}
                className="me-2"
                style={{ height: "20px" }}
                alt="bestclips"
              />
              <span className={`${menu.bestclips && "text-white"}`}>Bestclips</span>
            </div>
          </div>

          {/* Show > Posts */}
          {menu.posts && (
            <div className="gallery_view row">
              {post && post.length > 0 ? (
                post.map((e) => (
                  <div
                    className="col-3 d-flex align-items-center mb-3"
                    key={e._id}
                    style={{
                      filter: selectedData.some(item => item._id === e._id && item.mediaUrl === e.mediaUrl)
                        ? 'brightness(0.6)'  // Dimmed (selected image)
                        : 'brightness(1)',   // Normal brightness (unselected image),  // Highlight selected image
                    }}
                  >
                    <ImageShow
                      url={e.postTumbnailUrl || e.mediaUrl}
                      classHere=""
                      altHere="story"
                      onClickhere={handleImageSelect}  // Select image on click
                      onClickPara={e}  // Pass image data
                      style={{
                        cursor: 'pointer',  // Make image clickable
                      }}
                    />
                  </div>
                ))
              ) : (
                <p className="fw-bold text-center w-100">No posts available</p>
              )}
            </div>
          )}

          {/* Show > Flixs */}
          {menu.flixs && (
            <div className="gallery_view row">
              {flix && flix.length > 0 ? (
                flix.map((e) => (
                  <div
                    className="col-3 d-flex align-items-center mb-3"
                    key={e._id}
                    style={{
                      filter: selectedData.some(item => item._id === e._id && item.mediaUrl === e.mediaUrl)
                        ? 'brightness(0.6)'  // Dimmed (selected image)
                        : 'brightness(1)',   // Normal brightness (unselected image),  // Highlight selected image

                    }}
                  >
                    <ImageShow
                      url={e.postTumbnailUrl || e.mediaUrl}
                      classHere=""
                      altHere="story"
                      onClickhere={handleImageSelect}  // Select image on click
                      onClickPara={e}  // Pass image data
                      style={{
                        cursor: 'pointer',  // Make image clickable
                      }}
                    />
                  </div>
                ))
              ) : (
                <p className="fw-bold text-center w-100">No flix available</p>
              )}
            </div>
          )}

          {/* Show > Memes */}
          {menu.memes && (
            <div className="gallery_view row">
              {meme && meme.length > 0 ? (
                meme.map((e) => (
                  <div
                    className="col-3 d-flex align-items-center mb-3"
                    key={e._id}
                    style={{
                      filter: selectedData.some(item => item._id === e._id && item.mediaUrl === e.mediaUrl)
                        ? 'brightness(0.6)'  // Dimmed (selected image)
                        : 'brightness(1)',   // Normal brightness (unselected image),  // Highlight selected image

                    }}
                  >
                    <ImageShow
                      url={e.postTumbnailUrl || e.mediaUrl}
                      classHere=""
                      altHere="story"
                      onClickhere={handleImageSelect}  // Select image on click
                      onClickPara={e}  // Pass image data
                      style={{
                        cursor: 'pointer',  // Make image clickable
                      }}
                    />
                  </div>
                ))
              ) : (
                <p className="fw-bold text-center w-100">No memes available</p>
              )}
            </div>
          )}

          {/* Show > Bestclips */}
          {menu.bestclips && (
            <div className="gallery_view row">
              {bestclip && bestclip.length > 0 ? (
                bestclip.map((e) => (
                  <div
                    className="col-3 d-flex align-items-center mb-3"
                    key={e._id}
                    style={{
                      filter: selectedData.some(item => item._id === e._id && item.mediaUrl === e.mediaUrl)
                        ? 'brightness(0.6)'  // Dimmed (selected image)
                        : 'brightness(1)',   // Normal brightness (unselected image),  // Highlight selected image

                    }}
                  >
                    <ImageShow
                      url={e.postTumbnailUrl || e.mediaUrl}
                      classHere=""
                      altHere="story"
                      onClickhere={handleImageSelect}  // Select image on click
                      onClickPara={e}  // Pass image data
                      styleHere={{
                        cursor: 'pointer',  // Make image clickable
                      }}
                    />
                  </div>
                ))
              ) : (
                <p className="fw-bold text-center w-100">No best clips available</p>
              )}
            </div>
          )}

          {/* Button */}
          <div className="text-center mt-4">
            <button type="button" className="btn btn-primary w-50 rounded-5" onClick={() =>{
              selectedData.length > 0 ?  setOpen(true) : toast.error("Please select file");
              setShowStoriesEmiloGalleryPopup(true);
              }}>
            Next
            </button>
          </div>
         
        </Modal.Body>
      </Modal>

    </>
  );
};

export default StoryEmiloGallaryPopup;
