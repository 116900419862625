import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../../config';

const FavouriteMemeButton = ({ memeId }) => {

  const [isMemeFavourite, setIsMemeFavourite] = useState(false);

  const { config } = useContext(ApiContext);

  // To check memes is added to favourite or not
  const checkFavouriteMeme = (memeId, config) => {
    axios.post('https://emilonode.sjain.io/post/checkfavmeme', { memeId: memeId }, config)
    .then(res => {
      setIsMemeFavourite(res.data.added);
    })
    .catch(err => {
      return null
    });
  };

  useEffect(() => {
    checkFavouriteMeme(memeId, config);
  }, [memeId]);

  // To add post to favourite
  const createFavouriteMeme = (memeId, config) => {
    let action = isMemeFavourite ? 'add' : 'remove';

    axios.post('https://emilonode.sjain.io/post/createfavmeme', { memeId: memeId, action: action }, config)
    .then(res => {
      console.log(res.data.message);
      setIsMemeFavourite(!isMemeFavourite);
    })
    .catch(err => {
      return null;
    });
  };

  return (
    <>
      {
        isMemeFavourite
        ?
        <img className='favouriteMemeBtn' src={`${BASE_URL_STATIC}/icon/favourite-1.svg`} alt="views" onClick={() => createFavouriteMeme(memeId, config)} style={{ cursor: 'pointer' }} />
        :
        <img className='favouriteMemeBtn' src={`${BASE_URL_STATIC}/icon/favourite-2.svg`} alt="views" onClick={() => createFavouriteMeme(memeId, config)} style={{ cursor: 'pointer' }} />
      }
    </>
  )
}

export default FavouriteMemeButton
