import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../../UseContext/ApiCall';
import toast from 'react-hot-toast';

const EditBestClipPopup = ({ showEditBestClipPopup, setShowEditBestClipPopup, setShowProfileBestClipThreeDotPopup, profileBestClipPopupData, setprofileBestClipPopupData }) => {

  const [editBestClipPopupData, setEditBestClipPopupData] = useState({
    location: '',
    description: ''
  });

  // console.log('a : ', profileBestClipPopupData);

  const { config } = useContext(ApiContext);

  // it will set previous data to fields
  useEffect(() => {
    profileBestClipPopupData
    &&
    setEditBestClipPopupData({
      location: profileBestClipPopupData.title,
      description: profileBestClipPopupData.description
    });
  }, [profileBestClipPopupData]);

  // it will change data
  const handleEditBestClipPopupDataChange = (e) => {
    const { name, value } = e.target;

    setEditBestClipPopupData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // To handle edit post
  const handleEditBestClip = async (editBestClipPopupData, config) => {

    await axios.post('https://emilonode.sjain.io/post/editBestClip', { 
      bestClipId: profileBestClipPopupData.bestclipId, 
      location: editBestClipPopupData.location, 
      description: editBestClipPopupData.description 
    }, config)
    .then(res => {
      console.log('res : ', res);

      setShowEditBestClipPopup(false);
      setShowProfileBestClipThreeDotPopup(false);

      toast.success(res.data.message);

      setprofileBestClipPopupData(preVal => ({
        ...preVal,
        title: editBestClipPopupData.location,
        description: editBestClipPopupData.description
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  return (
    <>
      <section className='editBestClipPopups'>
        <Modal show={showEditBestClipPopup} onHide={() => setShowEditBestClipPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='editBestClipPopup'>
              <h5 className='editBestClipPopup-head'>Edit Bestclip</h5>

              <form className='editBestClipPopup-form'>
                <label htmlFor="location">
                  <input type="text" value={editBestClipPopupData.location} onChange={handleEditBestClipPopupDataChange} id='location' name='location' placeholder='Location...' />
                </label> <br />

                <textarea value={editBestClipPopupData.description} onChange={handleEditBestClipPopupDataChange} id="description" name="description" cols="51" rows="10" placeholder='Description...'></textarea>

                <span className='editBestClipPopup-btn' onClick={() => handleEditBestClip(editBestClipPopupData, config)}>Edit Bestclip</span>
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section>  
    </>
  )
}

export default EditBestClipPopup
