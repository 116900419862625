import React, { useState } from 'react'
import { BASE_URL_STATIC } from '../../../config'


const AccountSetting = () => {

  const [days, setDays] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  // To handle day click
  const handleDayClick = (e) => {
    const value = e.target.innerText.toLowerCase();

    setDays(preState => ({
      ...preState,
      [value]: days[value] === false ? true : false
    }))
  };

  const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  // const messages = {
  //   on: 'You will see notifications from everyone',
  //   off: 'You will not get any notifications',
  //   followers: 'You will see notifications only from your page followers',
  // };

  const hide = {
    words: ['word1', 'word2', 'word3', 'word4'],
    phrases: ['phrases1', 'phrases2', 'phrases3', 'phrases4'],
    emojis: ['😀', '🗿', '👯', '🐆'],
    users: ['Siddhart', 'Abhishek', 'Sourya', 'Soumith', 'Ranjeet'],
  };

  return (
    <>
      <section className='business-rb h-100 overflow-y-scroll scrollbar-hidden pt-3 px-2 px-sm-3 px-md-4 px-xl-5' style={{ backgroundColor: '#edf1f4' }} id='accountSettingToggle'>
        {/* Search Bar, Notification, Chat and Profile */}
        <div className='bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3'>
          {/* Search Bar */}
          <div className='d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className='me-2 me-sm-3' alt="search" />

            <input type="search" className='w-100 bg-transparent border-0' placeholder='Search...' />
          </div>

          {/* Notification */}
          <div className='mx-3 mx-sm-4 mx-md-5'>
            <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
          </div>

          {/* Chat */}
          <div>
            <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
          </div>

          {/* Profile */}
          <div className='ms-3 ms-sm-4 ms-md-5'>
            <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '30px', width: '30px' }} alt="profile" />
          </div>
        </div>

        {/* Accordion */}
        <div className='accordion accordion-flush rounded-3 bg-white p-3'>
          {/* --- General Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#generalSetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>General Setting</h6>
            </div>

            <div id='generalSetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              {/* Page Info */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#page-info">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Page Info</h6>
                </div>

                <div id='page-info' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  <div className='ms-3'>
                    {/* Page Name */}
                    <div className='d-flex justify-content-between align-items-end'>
                      <div>
                        <h6 className='fw-semibold'>Page Name</h6>
                        <span>Meraki</span>
                      </div>

                      <div>
                        <img src={`/images/business/icons/edit-blue.svg`} className='me-2' style={{ height: '17px' }} alt="edit-blue" />
                        <small className='text-primary'>Edit</small>
                      </div>
                    </div>

                    <hr className='hr bg-secondary' />

                    {/* Username */}
                    <div className='d-flex justify-content-between align-items-end'>
                      <div>
                        <h6 className='fw-semibold'>Username</h6>
                        <span>Meraki</span>
                      </div>

                      <div>
                        <img src={`/images/business/icons/edit-blue.svg`} className='me-2' style={{ height: '17px' }} alt="edit-blue" />
                        <small className='text-primary'>Edit</small>
                      </div>
                    </div>

                    <hr className='hr bg-secondary' />

                    {/* Category */}
                    <div className='d-flex justify-content-between align-items-end'>
                      <div>
                        <h6 className='fw-semibold'>Category</h6>
                        <span>Health & Beauty</span>
                      </div>

                      <div>
                        <img src={`/images/business/icons/edit-blue.svg`} className='me-2' style={{ height: '17px' }} alt="edit-blue" />
                        <small className='text-primary'>Edit</small>
                      </div>
                    </div>

                    <hr className='hr bg-secondary' />

                    {/* Sub Category */}
                    <div className='d-flex flex-wrap align-items-end'>
                      <div>
                        <h6 className='fw-semibold'>Sub Category</h6>
                        <span>Hair Product & Services</span>
                      </div>

                      <div className='ms-auto me-4'>
                        <img src={`/images/business/icons/add-circle.svg`} className='me-2' style={{ height: '17px' }} alt="edit-blue" />
                        <small className='text-primary'>Add Sub Categories</small>
                      </div>

                      <div>
                        <img src={`/images/business/icons/edit-blue.svg`} className='me-2' style={{ height: '17px' }} alt="edit-blue" />
                        <small className='text-primary'>Edit</small>
                      </div>
                    </div>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Account Info */}
                  <span className='px-4 py-1 rounded-3 fw-semibold mt-3' style={{ backgroundColor: '#f0f1fe' }}>Account Info</span>

                  <div className='ms-3'>
                    {/* Page Id */}
                    <div className='d-flex align-items-center my-4'>
                      <img src={`/images/business/icons/hashtag.svg`} className='me-3' style={{ height: '25px' }} alt="hashtag" />

                      <div className='border-bottom flex-grow-1'>
                        <small className='d-block text-secondary'>Page Id</small>
                        <span className='fw-semibold'>0123456789</span>
                      </div>
                    </div>

                    {/* Page Creation Date */}
                    <div className='d-flex align-items-center my-4'>
                      <img src={`/images/business/icons/active-hours.svg`} className='me-3' style={{ height: '25px' }} alt="hashtag" />

                      <div className='border-bottom flex-grow-1'>
                        <small className='d-block text-secondary'>Page Creation Date</small>
                        <span className='fw-semibold'>May 01, 2024</span>
                      </div>
                    </div>

                    {/* Account Info */}
                    <div className='d-flex align-items-center my-4'>
                      <img src={`/images/business/icons/dealer-man.svg`} className='me-3' style={{ height: '25px' }} alt="hashtag" />

                      <div className='border-bottom flex-grow-1'>
                        <small className='d-block text-secondary'>Account Info</small>
                        <span className='fw-semibold'>Asuka Meraki</span>
                      </div>
                    </div>

                    {/* Page Verification */}
                    <div className='d-flex align-items-center mb-2'>
                      <img src={`/images/business/icons/verified.svg`} className='me-3' style={{ height: '25px' }} alt="hashtag" />
                      
                      <div className='border-bottom flex-grow-1'>
                        <small className='d-block text-secondary'>Page Verification</small>
                        <span className='fw-semibold'>Verified</span>
                      </div>
                    </div>

                    <div className='ms-5'> 
                      <span className='mb-2 text-secondary'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id minus asperiores modi debitis. Cum aut odit, amet soluta quos ex, quibusdam, illo nam nobis eos similique impedit porro cupiditate minima optio! Molestiae qui quod id? Dolorem culpa explicabo voluptate consequatur quasi, ex repellat rem odit doloribus, provident ratione unde fugit <span className='text-primary'>Learn More.</span>.</span>

                      <h6 className='fw-semibold'>Meraki International Operations Japan M.I.O.S.</h6>
                    </div>

                    {/* Ads Campaign Details */}
                    <div className='mt-4'>
                      <div className='d-flex'>
                        <img src={`/images/business/icons/ads-campaign.svg`} style={{ height: '20px' }} alt="ads-campaign" />
                        <h6 className='mb-0 ms-3 me-auto text-secondary'>Ads Campaign Details</h6>
                        <span className='text-primary'>See All</span>
                      </div>

                      {/* <div className='shadow d-flex flex-column flex-sm-row align-items-center p-3 rounded-3' style={{ width: '300px' }}>
                        <img src={`http://www.celebrityscentsation.com/celebrity-perfumes/actors/elle-fanning/paco-rabanne-fame/images/elle-fanning-paco-rabanne-fame-ad-lg.jpg`} className='object-fit-cover rounded-3' style={{ height: '75px', width: '75px' }} alt="ads" />

                        <div className='my-3 my-sm-0 mx-3'>
                          <h5 className='fw-semibold mb-0'>Meraki</h5>
                          <span className='text-secondary'>Lorem ipsum dolor sit amet?</span>
                        </div>

                        <button type="button" className='btn btn-primary text-nowrap'>View Ad</button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Page Roles */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#page-roles">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Page Roles</h6>
                </div>

                <div id='page-roles' className="accordion-collapse collapse ms-3 accordion accordion-flush">
                  <div className='d-flex align-items-center px-4 py-3 rounded-3 shadow-sm mb-4' style={{ backgroundColor: '#f5f5f5'}}>
                    <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '50px', width: '50px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h5 className='mb-0 fw-semibold'>Siddharth Dongre</h5>
                      <span className='text-secondary'>Page Deletion, Content, Message, Commnity, Activity, Ads and Insights etc.</span>
                    </div>

                    <span className='fw-semibold'>Owner</span>
                  </div>

                  <div className='ms-3'>
                    <div className='d-flex justify-content-evenly mb-4'>
                      <span className='fw-semibold flex-grow-1 border-bottom border-3 pb-1 text-center border-primary'>Administrator</span>
                      <span className='fw-semibold flex-grow-1 border-bottom border-3 pb-1 text-center'>Task Manager (Editor)</span>
                      <span className='fw-semibold flex-grow-1 border-bottom border-3 pb-1 text-center'>Moderator</span>
                      <span className='fw-semibold flex-grow-1 border-bottom border-3 pb-1 text-center'>Advertiser</span>
                      <span className='fw-semibold flex-grow-1 border-bottom border-3 pb-1 text-center'>Analyst</span>
                    </div>

                    <h6 className='fw-semibold mb-3'>People with task access</h6>

                    <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis sed voluptatibus recusandae quidem, iure sapiente nostrum est obcaecati itaque aliquam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius velit, ipsam molestiae ex quaerat excepturi illo sed commodi odit iusto.</span>

                    <div className='as-gs-pr-users my-3'>
                      {
                        Array(5).fill(0).map((value, index) => (
                          <div className='border px-3 py-2 rounded-3 shadow-sm d-flex align-items-center' key={index}>
                            <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle me-3' style={{ height: '40px', width: '40px' }} alt="profile" />

                            <div>
                              <h6 className='fw-semibold mb-0'>Siddharth Dongre</h6>
                              <small className='text-primary'>@SiddharthDongre</small>
                            </div>
                          </div>
                        ))
                      }
                    </div>

                    <button type="button" className='btn border-primary'>
                      <img src={`/images/business/icons/add-circle.svg`} className='me-2' style={{ height: '20px' }} alt="add-circle" />
                      <span className='text-primary'>Add New</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>  

          {/* --- Notification Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 collapsed shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#notificationSetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>Notification Setting</h6>
            </div>

            <div id='notificationSetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              {/* Silent Mode */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#silent-mode">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Silent Mode</h6>
                </div>

                <div id='silent-mode' className="accordion-collapse collapse ms-3 me-3 accordion accordion-flush">
                  {/* Take a Break */}
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h6 className='fw-semibold'>Take a Break</h6>
                      <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt ratione eius doloremque, laboriosam tempore quas.</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Do not Disturb */}
                  <div className='d-flex justify-content-between mb-4'>
                    <div>
                      <h6 className='fw-semibold'>Do not Disturb</h6>
                      <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt ratione eius doloremque, laboriosam tempore quas.</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  {/* Start Time, End Time and Day */}
                  <div className='d-flex flex-wrap gap-4'>
                    {/* Start Time */}
                    <div>
                      <span className='bg-secondary-subtle px-3 rounded-3 shadow-sm mb-3'>Start Time</span>
                      <input type="time" className='form-control' />
                    </div>

                    {/* End Time */}
                    <div>
                      <span className='bg-secondary-subtle px-3 rounded-3 shadow-sm mb-3'>End Time</span>
                      <input type="time" className='form-control' />
                    </div>

                    {/* Day */}
                    <div>
                      <span className='bg-secondary-subtle px-3 rounded-3 shadow-sm mb-3'>Day</span>

                      <div className=''>
                        {
                          week.map((day, index) => (
                            <span className={`${days[day] === true ? 'bg-primary text-white' : 'border text-secondary'} rounded-3 p-2 me-2 mb-2 text-capitalize`} name={day} onClick={handleDayClick} key={index}>{day}</span>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Followers Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#followers-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Followers Notification</h6>
                </div>

                <div id='followers-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Notification regarding new followers</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Ads Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#ads-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Ads Notification</h6>
                </div>

                <div id='ads-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Ads Insights - reach, impression */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Show notification regarding ads insights - reach, impression</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Likes on ads */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Show notification regarding likes on ads</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Comments on ads */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Show notification regarding comments on ads</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Likes and replies on your comments */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Show notification regarding likes and replies on your comments</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Sharing of your ads */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Show notification regarding sharing of your ads</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Content Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#content-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Content Notification</h6>
                </div>

                <div id='content-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Allow Push Notifications */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Allow Push Notifications</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='bg-secondary-subtle' />

                  {/* Allow Email Notifications */}
                  <div>
                    <h6 className='mb-0 fw-semibold'>Allow Email Notifications</h6>

                    <div className='d-flex justify-content-between my-3'>
                      <span>Email regarding activity on your posts & content as well as ads</span>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                          // value={value.postLike === 'true' ? false : true}
                          // onChange={(e) => handleOptionChange(e, 'privacy')} 
                          // checked={value.postLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                      <span>Email regarding emilo business manager features and services as well as help and support</span>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                          // value={value.postLike === 'true' ? false : true}
                          // onChange={(e) => handleOptionChange(e, 'privacy')} 
                          // checked={value.postLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='bg-secondary-subtle' />

                  {/* Likes */}
                  <div>
                    <h6 className='mb-0 fw-semibold d-inline-block px-3 py-2 shadow-sm rounded-3 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Notifications Regarding Likes</h6>

                    <div className='ms-3'>
                      {/* Post */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Post</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-post-on" name="n-likes-post" value='on' checked />
                              <label className="form-check-label" htmlFor="n-likes-post-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-post-off" name="n-likes-post" value='off' />
                              <label className="form-check-label" htmlFor="n-likes-post-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-post-followers" name="n-likes-post" value='followers' />
                              <label className="form-check-label" htmlFor="n-likes-post-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Flix */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Flix</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-flix-on" name="n-likes-flix" value='on' />
                              <label className="form-check-label" htmlFor="n-likes-flix-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-flix-off" name="n-likes-flix" value='off' checked />
                              <label className="form-check-label" htmlFor="n-likes-flix-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-flix-followers" name="n-likes-flix" value='followers' />
                              <label className="form-check-label" htmlFor="n-likes-flix-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Off:</span> You will not get any notifications.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Stories */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Stories</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-stories-on" name="n-likes-stories" value='on' />
                              <label className="form-check-label" htmlFor="n-likes-stories-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-stories-off" name="n-likes-stories" value='off' />
                              <label className="form-check-label" htmlFor="n-likes-stories-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-stories-followers" name="n-likes-stories" value='followers' checked />
                              <label className="form-check-label" htmlFor="n-likes-stories-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Followers:</span> You will see notifications only from your page followers.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Bestclips */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Bestclips</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-bestclips-on" name="n-likes-bestclips" value='on' checked />
                              <label className="form-check-label" htmlFor="n-likes-bestclips-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-bestclips-off" name="n-likes-bestclips" value='off' />
                              <label className="form-check-label" htmlFor="n-likes-bestclips-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-bestclips-followers" name="n-likes-bestclips" value='followers' />
                              <label className="form-check-label" htmlFor="n-likes-bestclips-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Memes */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Memes</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-memes-on" name="n-likes-memes" value='on' checked />
                              <label className="form-check-label" htmlFor="n-likes-memes-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-memes-off" name="n-likes-memes" value='off' />
                              <label className="form-check-label" htmlFor="n-likes-memes-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-memes-followers" name="n-likes-memes" value='followers' />
                              <label className="form-check-label" htmlFor="n-likes-memes-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Live */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Live</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-live-on" name="n-likes-live" value='on' checked />
                              <label className="form-check-label" htmlFor="n-likes-live-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-live-off" name="n-likes-live" value='off' />
                              <label className="form-check-label" htmlFor="n-likes-live-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-likes-live-followers" name="n-likes-live" value='followers' />
                              <label className="form-check-label" htmlFor="n-likes-live-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>

                  {/* Comments */}
                  <div>
                    <h6 className='mb-0 fw-semibold d-inline-block px-3 py-2 shadow-sm rounded-3 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Notifications Regarding Comments</h6>

                    <div className='ms-3'>
                      {/* Post */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Post</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-post-on" name="n-comments-post" value='on' checked />
                              <label className="form-check-label" htmlFor="n-comments-post-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-post-off" name="n-comments-post" value='off' />
                              <label className="form-check-label" htmlFor="n-comments-post-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-post-followers" name="n-comments-post" value='followers' />
                              <label className="form-check-label" htmlFor="n-comments-post-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Flix */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Flix</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-flix-on" name="n-comments-flix" value='on' />
                              <label className="form-check-label" htmlFor="n-comments-flix-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-flix-off" name="n-comments-flix" value='off' checked />
                              <label className="form-check-label" htmlFor="n-comments-flix-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-flix-followers" name="n-comments-flix" value='followers' />
                              <label className="form-check-label" htmlFor="n-comments-flix-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Off:</span> You will not get any notifications.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Stories */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Stories</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-stories-on" name="n-comments-stories" value='on' />
                              <label className="form-check-label" htmlFor="n-comments-stories-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-stories-off" name="n-comments-stories" value='off' />
                              <label className="form-check-label" htmlFor="n-comments-stories-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-stories-followers" name="n-comments-stories" value='followers' checked />
                              <label className="form-check-label" htmlFor="n-comments-stories-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Followers:</span> You will see notifications only from your page followers.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Bestclips */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Bestclips</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-bestclips-on" name="n-comments-bestclips" value='on' checked />
                              <label className="form-check-label" htmlFor="n-comments-bestclips-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-bestclips-off" name="n-comments-bestclips" value='off' />
                              <label className="form-check-label" htmlFor="n-comments-bestclips-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-bestclips-followers" name="n-comments-bestclips" value='followers' />
                              <label className="form-check-label" htmlFor="n-comments-bestclips-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Memes */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Memes</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-memes-on" name="n-comments-memes" value='on' checked />
                              <label className="form-check-label" htmlFor="n-comments-memes-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-memes-off" name="n-comments-memes" value='off' />
                              <label className="form-check-label" htmlFor="n-comments-memes-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-memes-followers" name="n-comments-memes" value='followers' />
                              <label className="form-check-label" htmlFor="n-comments-memes-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Live */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Live</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-live-on" name="n-comments-live" value='on' checked />
                              <label className="form-check-label" htmlFor="n-comments-live-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-live-off" name="n-comments-live" value='off' />
                              <label className="form-check-label" htmlFor="n-comments-live-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-comments-live-followers" name="n-comments-live" value='followers' />
                              <label className="form-check-label" htmlFor="n-comments-live-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>

                  {/* Likes and replies on your comments */}
                  <div>
                    <h6 className='mb-0 fw-semibold d-inline-block px-3 py-2 shadow-sm rounded-3 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Notifications Regarding likes and replies on your comments</h6>

                    <div className='ms-3'>
                      {/* Post */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Post</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-post-on" name="n-l&r-post" value='on' checked />
                              <label className="form-check-label" htmlFor="n-l&r-post-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-post-off" name="n-l&r-post" value='off' />
                              <label className="form-check-label" htmlFor="n-l&r-post-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-post-followers" name="n-l&r-post" value='followers' />
                              <label className="form-check-label" htmlFor="n-l&r-post-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Flix */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Flix</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-flix-on" name="n-l&r-flix" value='on' />
                              <label className="form-check-label" htmlFor="n-l&r-flix-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-flix-off" name="n-l&r-flix" value='off' checked />
                              <label className="form-check-label" htmlFor="n-l&r-flix-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-flix-followers" name="n-l&r-flix" value='followers' />
                              <label className="form-check-label" htmlFor="n-l&r-flix-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Off:</span> You will not get any notifications.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Stories */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Stories</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-stories-on" name="n-l&r-stories" value='on' />
                              <label className="form-check-label" htmlFor="n-l&r-stories-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-stories-off" name="n-l&r-stories" value='off' />
                              <label className="form-check-label" htmlFor="n-l&r-stories-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-stories-followers" name="n-l&r-stories" value='followers' checked />
                              <label className="form-check-label" htmlFor="n-l&r-stories-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Followers:</span> You will see notifications only from your page followers.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Bestclips */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Bestclips</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-bestclips-on" name="n-l&r-bestclips" value='on' checked />
                              <label className="form-check-label" htmlFor="n-l&r-bestclips-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-bestclips-off" name="n-l&r-bestclips" value='off' />
                              <label className="form-check-label" htmlFor="n-l&r-bestclips-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-bestclips-followers" name="n-l&r-bestclips" value='followers' />
                              <label className="form-check-label" htmlFor="n-l&r-bestclips-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Memes */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Memes</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-memes-on" name="n-l&r-memes" value='on' checked />
                              <label className="form-check-label" htmlFor="n-l&r-memes-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-memes-off" name="n-l&r-memes" value='off' />
                              <label className="form-check-label" htmlFor="n-l&r-memes-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-memes-followers" name="n-l&r-memes" value='followers' />
                              <label className="form-check-label" htmlFor="n-l&r-memes-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Live */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Live</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-live-on" name="n-l&r-live" value='on' checked />
                              <label className="form-check-label" htmlFor="n-l&r-live-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-live-off" name="n-l&r-live" value='off' />
                              <label className="form-check-label" htmlFor="n-l&r-live-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-l&r-live-followers" name="n-l&r-live" value='followers' />
                              <label className="form-check-label" htmlFor="n-l&r-live-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>

                  {/* Tag/Mention */}
                  <div>
                    <h6 className='mb-0 fw-semibold d-inline-block px-3 py-2 shadow-sm rounded-3 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Notifications Regarding tag/mention by someone</h6>

                    <div className='ms-3'>
                      {/* Post */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Post</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-post-on" name="n-t&m-post" value='on' checked />
                              <label className="form-check-label" htmlFor="n-t&m-post-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-post-off" name="n-t&m-post" value='off' />
                              <label className="form-check-label" htmlFor="n-t&m-post-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-post-followers" name="n-t&m-post" value='followers' />
                              <label className="form-check-label" htmlFor="n-t&m-post-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Stories */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Stories</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-stories-on" name="n-t&m-stories" value='on' />
                              <label className="form-check-label" htmlFor="n-t&m-stories-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-stories-off" name="n-t&m-stories" value='off' />
                              <label className="form-check-label" htmlFor="n-t&m-stories-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-stories-followers" name="n-t&m-stories" value='followers' checked />
                              <label className="form-check-label" htmlFor="n-t&m-stories-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>Followers:</span> You will see notifications only from your page followers.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Live */}
                      <div>
                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center'>
                          <h6 className='mb-0 fw-semibold'>Live</h6>

                          {/* Value */}
                          <div className='d-flex flex-column flex-sm-row my-2 my-sm-0'>
                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-live-on" name="n-t&m-live" value='on' checked />
                              <label className="form-check-label" htmlFor="n-t&m-live-on">On</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-live-off" name="n-t&m-live" value='off' />
                              <label className="form-check-label" htmlFor="n-t&m-live-off">Off</label>
                            </div>

                            <div className="form-check mb-0">
                              <input type="radio" className="form-check-input" id="n-t&m-live-followers" name="n-t&m-live" value='followers' />
                              <label className="form-check-label" htmlFor="n-t&m-live-followers">Followers</label>
                            </div>                            
                          </div>
                        </div>

                        <span><span className='text-primary'>On:</span> You will see notification from everyone.</span>
                      </div>

                      <hr className='hr bg-secondary' />

                      {/* Like, comments on post/content you are tagged in */}
                      <div className='d-flex justify-content-between'>
                        <h6 className='mb-0 fw-semibold'>Notifications regarding likes, Comments on post/content you are tagged in</h6>

                        <div className="form-check form-switch m-0 ms-3">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>

                  {/* Use of your content */}
                  <div>
                    <h6 className='mb-0 fw-semibold d-inline-block px-3 py-2 shadow-sm rounded-3 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Notifications Regarding use of your content by others</h6>

                    <div className='ms-3'>
                      {/* Audio */}
                      <div className='d-flex justify-content-between'>
                        <span>Use of your Audio</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Flix */}
                      <div className='d-flex justify-content-between my-3'>
                        <span>Use of your Flix</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Memes */}
                      <div className='d-flex justify-content-between'>
                        <span>Use of your Memes</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Suggestion Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#suggestion-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Suggestion Notification</h6>
                </div>

                <div id='suggestion-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Follow Suggestions */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Follow Suggestions</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Trending Contents */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Trending Contents</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Page you follow or liked */}
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h6 className='mb-0 fw-semibold'>Notifications regarding page you follow or liked</h6>
                      <span>Notifications regarding new products or services</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Other Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#other-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Other Notification</h6>
                </div>

                <div id='other-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* No of people */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>No of people showing intrest in your product/services</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Opportunity listing or invite */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding opportunity listing or invite</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Rating & Reviews */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding rating & reviews</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Chat Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#chat-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Chat Notification</h6>
                </div>

                <div id='chat-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* New & Unread Messages */}
                  <div className='d-flex justify-content-between'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding new & unread messages</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Priorities Notification */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#priorities-notification">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Priorities Notification</h6>
                </div>

                <div id='priorities-notification' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Your running ad campaigns */}
                  <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-3'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding your running ads campaigns</h6>

                    {/* Value */}
                    <div className='d-flex'>
                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-rac-1" name="n-rac" value='1' checked />
                        <label className="form-check-label" htmlFor="n-rac-1">1</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-rac-2" name="n-rac" value='2' />
                        <label className="form-check-label" htmlFor="n-rac-2">2</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-rac-3" name="n-rac" value='3' />
                        <label className="form-check-label" htmlFor="n-rac-3">3</label>
                      </div>                            
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Post/Content */}
                  <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-3'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding post/content</h6>

                    {/* Value */}
                    <div className='d-flex'>
                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-pc-1" name="n-pc" value='1' />
                        <label className="form-check-label" htmlFor="n-pc-1">1</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-pc-2" name="n-pc" value='2' checked />
                        <label className="form-check-label" htmlFor="n-pc-2">2</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-pc-3" name="n-pc" value='3' />
                        <label className="form-check-label" htmlFor="n-pc-3">3</label>
                      </div>                            
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Followers */}
                  <div className='d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-3'>
                    <h6 className='mb-0 fw-semibold'>Notifications regarding followers</h6>

                    {/* Value */}
                    <div className='d-flex'>
                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-followers-1" name="n-followers" value='1' />
                        <label className="form-check-label" htmlFor="n-followers-1">1</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-followers-2" name="n-followers" value='2' />
                        <label className="form-check-label" htmlFor="n-followers-2">2</label>
                      </div>

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-followers-3" name="n-followers" value='3' checked />
                        <label className="form-check-label" htmlFor="n-followers-3">3</label>
                      </div>                            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  

          {/* --- Privacy Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 collapsed shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#privacySetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>Privacy Setting</h6>
            </div>

            <div id='privacySetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              {/* Content Uses Setting */}
              <div className='my-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#content-uses-setting">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Content Uses Setting</h6>
                </div>

                <div id='content-uses-setting' className="accordion-collapse collapse ms-3 accordion accordion-flush">
                  <span className='ms-3'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error totam est blanditiis ducimus in suscipit eveniet quae sapiente optio animi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, nostrum? <span className='text-primary'>Learn More</span></span>

                  {/* Flix */}
                  <div>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm d-inline-block mt-4 mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Allow below permission for flix</h6>

                    <div className='ms-3'>
                      {/* Use of your flix */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Use of your flix</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Sharing of your flix */}
                      <div className='d-flex justify-content-between gap-3 my-3'>
                        <span>Sharing of your flix</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Allow others to tag/mention you on their flix */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Allow others to tag/mention you on their flix</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary ms-3' />

                  {/* Stories */}
                  <div>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm d-inline-block mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Allow below permission for stories</h6>

                    <div className='ms-3'>
                      {/* Sharing of your flix */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Sharing of your flix</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Allow other add your story to their stories */}
                      <div className='d-flex justify-content-between gap-3 my-3'>
                        <span>Allow other add your story to their stories</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Allow others to tag/mention you on their stories */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Allow others to tag/mention you on their stories</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary ms-3' />

                  {/* Tag/Mention */}
                  <div>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm d-inline-block mb-3' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Other tag/mention permissions</h6>

                    <div className='ms-3'>
                      {/* Post/Bestclip/Live */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Allow other person to tag/mention your page on their posts/bestclips/live</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Messages & Comments */}
                      <div className='d-flex justify-content-between gap-3 my-3'>
                        <span>Allow others to mention your page on thier messages and comments</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary ms-3' />

                  {/* Allow tagging after review */}
                  <div className='ms-3'>
                    <div className="form-check float-none mb-3 me-0">
                      <input type="checkbox" className="form-check-input" id="after-review" checked />

                      <div>
                        <label className="form-check-label mb-1 fw-semibold d-block" htmlFor="after-review">Allow tagging after review?</label>
                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, numquam.</span>
                      </div>
                    </div>

                    <span><span className='text-primary'>Note:</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, rem?</span>
                  </div>

                  <hr className='hr bg-secondary ms-3' />

                  {/* Product Showcase */}
                  <div className='ms-3'>
                    <h6 className='fw-semibold '>Allow post or content sharing by brand and emilo in their product showcase</h6>

                    <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, maxime!</span>

                    {/* Value */}
                    <div className='d-flex flex-column flex-sm-row my-3'>
                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-ps-always" name="n-ps" value='always' checked />
                        <label className="form-check-label" htmlFor="n-ps-always">Always Allow</label>
                      </div> 

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-ps-dont" name="n-ps" value='dont' />
                        <label className="form-check-label" htmlFor="n-ps-dont">Don't Allow</label>
                      </div> 

                      <div className="form-check mb-0">
                        <input type="radio" className="form-check-input" id="n-ps-request" name="n-ps" value='request' />
                        <label className="form-check-label" htmlFor="n-ps-request">Allow on Request</label>
                      </div> 
                    </div>

                    <span><span className='text-primary'>Note:</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad asperiores repellendus, inventore ipsum voluptatibus magnam.</span>
                  </div>

                  <hr className='hr bg-secondary ms-3' />
                </div>
              </div>

              {/* Hide Words */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#hide-words">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Hide Words</h6>
                </div>

                <div id='hide-words' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  <span className='mb-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat sed temporibus, Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, saepe. et nulla maiores assumenda quidem ullam unde laborum inventore <span className='text-primary'>Learn More.</span></span>

                  {/* Words */}
                  <div>
                    <h6 className='mb-0 fw-semibold'>Words</h6>

                    <div className='d-flex gap-3 my-3'>
                      <input type="text" className='form-control' placeholder='Word, word, w.o.r.d, #word, wrod' />

                      <button type="button" className='btn btn-primary'>Add</button>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                      {
                        hide.words.map((word, index) => (
                          <div className='bg-primary px-3 rounded-3 shadow-sm' key={index}>
                            <span className='text-white me-3'>{word}</span>
                            <span className='text-white cursor-pointer'>x</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Phrases */}
                  <div>
                    <h6 className='mb-0 fw-semibold'>Phrases</h6>

                    <div className='d-flex gap-3 my-3'>
                      <input type="text" className='form-control' placeholder='Phrases Sentence...' />

                      <button type="button" className='btn btn-primary'>Add</button>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                      {
                        hide.phrases.map((phrase, index) => (
                          <div className='bg-primary px-3 rounded-3 shadow-sm' key={index}>
                            <span className='text-white me-3'>{phrase}</span>
                            <span className='text-white cursor-pointer'>x</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Emojis */}
                  <div>
                    <h6 className='mb-0 fw-semibold'>Emojis</h6>

                    <div className='d-flex gap-3 my-3'>
                      <input type="text" className='form-control' placeholder='Select Emojis...' />

                      <button type="button" className='btn btn-primary'>Add</button>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                      {
                        hide.emojis.map((emoji, index) => (
                          <div className='px-2 rounded-pill shadow-sm d-flex align-items-center border' key={index}>
                            <span className='text-white me-3' style={{ fontSize: '25px' }}>{emoji}</span>
                            <span className='text-white cursor-pointer bg-danger rounded-circle px-2'>x</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Apply above filter to */}
                  <div>
                    <h6 className='mb-3 fw-semibold'>Apply above filter to</h6>

                    {/* Value */}
                    <div className='d-flex flex-wrap gap-3'>
                      {/* Post */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-post" checked />
                        <label className="form-check-label" htmlFor="apply-filter-post">Post</label>
                      </div>

                      {/* Flix */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-flix" checked />
                        <label className="form-check-label" htmlFor="apply-filter-flix">Flix</label>
                      </div>

                      {/* Story Replies */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-story-replies" checked />
                        <label className="form-check-label" htmlFor="apply-filter-story-replies">Story Replies</label>
                      </div>

                      {/* Bestclip */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-bestclip" checked />
                        <label className="form-check-label" htmlFor="apply-filter-bestclip">Bestclip</label>
                      </div>

                      {/* Meme */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-meme" checked />
                        <label className="form-check-label" htmlFor="apply-filter-meme">Meme</label>
                      </div>

                      {/* Messages and Chats */}
                      <div className="form-check float-none">
                        <input type="checkbox" className="form-check-input" id="apply-filter-message-chat" checked />
                        <label className="form-check-label" htmlFor="apply-filter-message-chat">Messages and Chats</label>
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />
                </div>
              </div>

              {/* Chat & Messages */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#chat-messages">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Chat & Messages</h6>
                </div>

                <div id='chat-messages' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Enable Chat */}
                  <div className='d-flex justify-content-between gap-3'>
                    <div>
                      <h6 className='mb-0 fw-semibold'>Enable chat on your page</h6>
                      <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas, amet?</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Chat Active */}
                  <div className='d-flex justify-content-between gap-3'>
                    <div>
                      <h6 className='mb-0 fw-semibold'>Allow user can view you are active on chat</h6>
                      <span>(Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas, amet?)</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Hide Chat */}
                  <div>
                    <h6 className='mb-0 fw-semibold'>Hide chat from specific people</h6>
                    <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut, deserunt.</span>

                    <div className='d-flex gap-3 my-3'>
                      <input type="text" className='form-control' placeholder='Select People...' />
                      <button type="button" className='btn btn-primary'>Add</button>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                      {
                        hide.users.map((user, index) => (
                          <div className='px-3 rounded-3 bg-primary shadow-sm d-inline-block' key={index}>
                            <span className='text-white me-3'>{user}</span>
                            <span className='text-white cursor-pointer'>x</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Show message read receipts? */}
                  <div className='d-flex justify-content-between gap-3'>
                    <div>
                      <h6 className='mb-1 fw-semibold'>Show message read receipts?</h6>
                      <span>(Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas, amet?)</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Links */}
                  <div>
                    <h6 className='mb-3 fw-semibold'>Action on clicking website link received on chat</h6>

                    {/* Value */}
                    <div className='d-flex flex-column flex-sm-row gap-3'>
                      <div className="form-check float-none mb-0">
                        <input type="radio" className="form-check-input" id="n-cm-internal" name="n-cm" value='internal' checked />
                        <label className="form-check-label" htmlFor="n-cm-internal">Open in app</label>
                      </div> 

                      <div className="form-check float-none mb-0">
                        <input type="radio" className="form-check-input" id="n-cm-external" name="n-cm" value='external' />
                        <label className="form-check-label" htmlFor="n-cm-external">Open in external web browser</label>
                      </div> 
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Greeting */}
                  <div className='d-flex justify-content-between gap-3'>
                    <h6 className='mb-0 fw-semibold'>Auto send greeting</h6>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>                    
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Message Template */}
                  <div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h6 className='mb-0 fw-semibold'>Message Template</h6>

                      <button type="button" className='btn btn-sm border rounded-3'>
                        <img src={`/images/business/icons/add-circle.svg`} className='me-2' style={{ height: '20px' }} alt="add" />
                        <span className='text-primary'>Add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Collaboration */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#collaboration">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Collaboration</h6>
                </div>

                <div id='collaboration' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Turn on/off collaboration */}
                  <div className='d-flex justify-content-between gap-3'>
                    <div>
                      <h6 className='mb-0 fw-semibold'>Turn on/off collaboration</h6>
                      <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo expedita adipisci quaerat atque iste laudantium accusantium perferendis et. Ipsum, debitis.</span>
                    </div>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                        // value={value.postLike === 'true' ? false : true}
                        // onChange={(e) => handleOptionChange(e, 'privacy')} 
                        // checked={value.postLike === 'true' ? true : false} 
                      />
                    </div>  
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Collaboration Preference */}
                  <div>
                    <div className='d-flex flex-column flex-sm-row justify-content-between gap-3'>
                      <h6 className='mb-0 fw-semibold'>Collaboration Preference</h6>

                      {/* Value */}
                      <div className='d-flex flex-column flex-sm-row gap-2 mb-3 mb-sm-0'>
                        <div className="form-check float-none mb-0">
                          <input type="radio" className="form-check-input" id="n-cp-public" name="n-cp" value='public' checked />
                          <label className="form-check-label" htmlFor="n-cp-public">Public</label>
                        </div> 

                        <div className="form-check float-none mb-0">
                          <input type="radio" className="form-check-input" id="n-cp-followers" name="n-cp" value='followers' />
                          <label className="form-check-label" htmlFor="n-cp-followers">Followers</label>
                        </div> 

                        <div className="form-check float-none mb-0">
                          <input type="radio" className="form-check-input" id="n-cp-specific" name="n-cp" value='specific' />
                          <label className="form-check-label" htmlFor="n-cp-specific">Specific</label>
                        </div> 
                      </div>
                    </div>

                    <span><span className='text-primary'>Public:</span> You will see notification from everyone.</span>

                    <div className='d-flex gap-3 my-3'>
                      <input type="text" className='form-control' placeholder='Select People...' />

                      <button type="button" className='btn btn-primary'>Add</button>
                    </div>

                    <div className='d-flex flex-wrap gap-3'>
                      {
                        hide.users.map((user, index) => (
                          <div className='bg-primary px-3 rounded-3 shadow-sm' key={index}>
                            <span className='text-white me-3'>{user}</span>
                            <span className='text-white'>x</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />
                </div>
              </div>

              {/* FAQ */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#faq">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Manage FAQs for your page</h6>
                </div>

                <div id='faq' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Question, Add, Edit & Delete */}
                  <div className='d-flex flex-wrap gap-3'>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 me-auto' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Questions</h6>

                    {/* Add */}
                    <button type="button" className='btn btn-sm border border-primary bg-transparent rounded-3'>
                      <img src={`/images/business/icons/add-circle.svg`} className='me-2' style={{ height: '20px' }} alt="add-circle" />
                      <span className='text-primary'>Add</span>
                    </button>

                    {/* Edit */}
                    <button type="button" className='btn btn-sm border bg-transparent rounded-3'>
                      <img src={`/images/business/icons/edit-black.svg`} className='me-2' style={{ height: '20px' }} alt="edit-black" />
                      <span>Edit</span>
                    </button>

                    {/* Delete */}
                    <button type="button" className='btn btn-sm border border-danger bg-transparent rounded-3'>
                      <img src={`/images/business/icons/delete-red.svg`} className='me-2' style={{ height: '20px' }} alt="delete-red" />
                      <span className='text-danger'>Delete</span>
                    </button>
                  </div>

                  {/* Accordions */}
                  <>
                    {
                      Array(4).fill(0).map((value, index) => (
                        <div className="form-check float-none ms-3">
                          <input type="checkbox" className="form-check-input mt-3 me-2" id="apply-filter-post" defaultChecked={index === 0} />

                          <div className='my-3 ms-2 ms-sm-3'>
                            <h6 className={`accordion-button ps-0 pe-4 py-3 bg-transparent border-bottom fw-semibold ${index > 0 && 'collapsed'}`} data-bs-target={`#faqs-${index+1}`} data-bs-toggle="collapse">Faq Accordion {index + 1}</h6>

                            <div id={`faqs-${index+1}`} className={`accordion-collapse collapse accordion accordion-flush ms-3 ${index === 0 && 'show'}`}>
                              <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quas aliquid molestias eum harum corrupti quos deleniti dignissimos eveniet sequi nisi mollitia, pariatur culpa quae ab consequuntur commodi qui earum cumque nobis, itaque sapiente! Aliquam in aliquid eius minus rem quibusdam, inventore dolor totam ex iure dicta autem labore ab magnam iste eaque quasi praesentium cum natus expedita earum fugit. Rerum, perspiciatis. Quibusdam temporibus, ipsam tempore unde quas laborum, eos voluptatem architecto quis sint obcaecati? Vero nobis modi fuga laboriosam atque mollitia ut magni quis hic eius reprehenderit veniam perspiciatis fugit illo soluta eum praesentium ratione qui sit, illum officiis.</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </>
                </div>
              </div>
            </div>
          </div> 

          {/* --- Language Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 collapsed shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#languageSetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>Language Setting</h6>
            </div>

            <div id='languageSetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              {/* Account Language */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#account-language">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>Account Language</h6>
                </div>

                <div id='account-language' className="accordion-collapse collapse show mx-3 accordion accordion-flush">
                  <h6 className='fw-semibold'>Select your prefered language</h6>
                  <span className='mb-3'>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, ipsa? consectetur adipisicing elit. Nihil eligendi sit amet vel aspernatur itaque consequatur ut sequi ipsa est!</span>

                  <div className="form-check float-none">
                    <input type="radio" className="form-check-input" id="ls-al-hindi" name="ls-al" value='hindi' />
                    <label className="form-check-label" htmlFor="ls-al-hindi">हिंदी</label>
                  </div> 

                  <div className="form-check float-none">
                    <input type="radio" className="form-check-input" id="ls-al-english" name="ls-al" value='english' checked />
                    <label className="form-check-label" htmlFor="ls-al-english">English</label>
                  </div> 
                </div>
              </div>
            </div>
          </div> 

          {/* --- Ad Preferences Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 collapsed shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#adPreferencesSetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>Ad Preferences Setting</h6>
            </div>

            <div id='adPreferencesSetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              <h6 className='fw-semibold'>Ad Preferences Setting</h6>

              <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem omnis amet officia esse rerum fugiat tenetur perspiciatis odit libero, asperiores ipsa similique architecto quia, autem deleniti unde, nesciunt voluptates cupiditate!</span>
            </div>
          </div>   

          {/* --- Suggestions Setting --- */}
          <div className='mb-3'>
            <div className='accordion-level-1 collapsed shadow-sm accordion-button px-4 py-3 rounded-3' style={{ backgroundColor: '#f0f1fe' }} data-bs-toggle="collapse" data-bs-target="#suggestionsSetting">
              <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
              <h6 className='fw-semibold mb-0'>Suggestions Setting</h6>
            </div>

            <div id='suggestionsSetting' className="accordion-collapse collapse accordion accordion-flush ms-3" data-bs-parent="#accountSettingToggle">
              {/* View & Manage Suggestions */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed px-4 py-3 rounded-3' style={{ backgroundColor: '#f5f5f5'}} data-bs-toggle="collapse" data-bs-target="#view-manage">
                  <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px' }} alt="lb-account-setting" />
                  <h6 className='fw-semibold mb-0'>View & Manage Suggestions</h6>
                </div>

                <div id='view-manage' className="accordion-collapse collapse mx-3 accordion accordion-flush">
                  {/* Follow Suggestions */}
                  <div>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 d-inline-block' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Follow Suggestions</h6>

                    <div className='ms-3'>
                      <span className='my-3'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum, ut. Accusamus labore laboriosam ratione modi dolore saepe odit non pariatur debitis alias, consequuntur in, eaque neque harum iure molestias ut.</span>

                      {/* Checkbox */}
                      <div className='d-flex flex-wrap gap-3'>
                        {/* Followers of Followers */}
                        <div className="form-check float-none">
                          <input type="checkbox" className="form-check-input" id="fs-fof" defaultChecked />
                          <label className="form-check-label fw-semibold" htmlFor="fs-fof">Followers of Followers</label>
                        </div>

                        {/* Searches */}
                        <div className="form-check float-none">
                          <input type="checkbox" className="form-check-input" id="fs-searches" />
                          <label className="form-check-label fw-semibold" htmlFor="fs-searches">Searches</label>
                        </div>

                        {/* Page Info */}
                        <div className="form-check float-none">
                          <input type="checkbox" className="form-check-input" id="fs-pi-1" defaultChecked />
                          <label className="form-check-label fw-semibold" htmlFor="fs-pi-1">Page Info</label>
                        </div>

                        {/* Profile Info */}
                        <div className="form-check float-none">
                          <input type="checkbox" className="form-check-input" id="fs-pi-2" />
                          <label className="form-check-label fw-semibold" htmlFor="fs-pi-2">Profile Info</label>
                        </div>

                        {/* Device Info */}
                        <div className="form-check float-none">
                          <input type="checkbox" className="form-check-input" id="fs-di" />
                          <label className="form-check-label fw-semibold" htmlFor="fs-di">Device Info</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary-subtle' />

                  {/* Content Suggestion */}
                  <div>
                    <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 d-inline-block' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Content Suggestions</h6>

                    {/* Page Info */}
                    <div className='ms-3'>
                      <span className='mt-3 mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni facere excepturi numquam id eveniet sint optio cumque dolorem architecto nesciunt molestias officiis cupiditate dolorum porro earum, tempora quae! Voluptate, nemo.</span>

                      <h6 className='fw-semibold'>Categories based on your profile info</h6>

                      <span className='mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores sequi quam, doloremque beatae itaque consectetur id ipsum deleniti voluptates soluta corporis nisi, enim omnis nam similique commodi alias adipisci accusantium!</span>

                      {/* Page category & sub category */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Page category & sub category</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" name='pc-sc' aria-checked defaultChecked
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Page Bio */}
                      <div className='d-flex justify-content-between gap-3 my-3'>
                        <span>Page Bio</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" name='pc-sc' aria-checked defaultChecked
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>

                      {/* Product & Services */}
                      <div className='d-flex justify-content-between gap-3'>
                        <span>Product & Services</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" name='pc-sc' aria-checked defaultChecked
                            // value={value.postLike === 'true' ? false : true}
                            // onChange={(e) => handleOptionChange(e, 'privacy')} 
                            // checked={value.postLike === 'true' ? true : false} 
                          />
                        </div>
                      </div>
                    </div>

                    <hr className='hr bg-secondary-subtle' />

                    {/* Activities, Interest & Preferences */}
                    <div className='ms-3'>
                      <h6 className='fw-semibold'>Categories based on your activities, interest & preferences.</h6>

                      <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Id eos quibusdam at deleniti porro nemo, beatae nostrum blanditiis a quisquam debitis corporis suscipit ullam consequatur, illo accusamus harum? Nostrum, facilis.</span>

                      {/* View More */}
                      <div className='mt-3'>
                        <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 d-inline-block' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>View More</h6>

                        <select className="form-select my-3" aria-label="Default select example">
                          <option selected>Select Categories</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                        <div className='d-flex flex-wrap gap-3'>
                          {
                            Array(4).fill(0).map((value, index) => (
                              <div className='bg-primary ps-3 pe-4 py-1 rounded-pill position-relative' key={index}>
                                <img src={`/images/business/icons/yes-circle-white.svg`} className='me-2' style={{ height: '20px' }} alt="yes-circle-white" />
                                <span className='text-white'>More {index+1}</span>
                                <span className='border border-white text-white bg-danger position-absolute translate-middle px-1 rounded-circle' style={{ fontSize: '13px', top: '11%', right: '-9%' }}>x</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>

                      <hr className='hr bg-secondary-subtle' />

                      {/* View Less */}
                      <div className='mt-3'>
                        <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 d-inline-block' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>View Less</h6>

                        <select className="form-select my-3" aria-label="Default select example">
                          <option selected>Select Categories</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                        <div className='d-flex flex-wrap gap-3'>
                          {
                            Array(4).fill(0).map((value, index) => (
                              <div className='bg-primary ps-3 pe-4 py-1 rounded-pill position-relative' key={index}>
                                <img src={`/images/business/icons/yes-circle-white.svg`} className='me-2' style={{ height: '20px' }} alt="yes-circle-white" />
                                <span className='text-white'>Less {index+1}</span>
                                <span className='border border-white text-white bg-danger position-absolute translate-middle px-1 rounded-circle' style={{ fontSize: '13px', top: '11%', right: '-9%' }}>x</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>

                      <hr className='hr bg-secondary-subtle' />

                      {/* Hide */}
                      <div className='mt-3'>
                        <h6 className='fw-semibold px-3 py-2 rounded-3 shadow-sm mb-0 d-inline-block' style={{ backgroundColor: 'rgb(240, 241, 254)' }}>Hide</h6>

                        <select className="form-select my-3" aria-label="Default select example">
                          <option selected>Select Categories</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                        <div className='d-flex flex-wrap gap-3'>
                          {
                            Array(4).fill(0).map((value, index) => (
                              <div className='bg-primary ps-3 pe-4 py-1 rounded-pill position-relative' key={index}>
                                <img src={`/images/business/icons/yes-circle-white.svg`} className='me-2' style={{ height: '20px' }} alt="yes-circle-white" />
                                <span className='text-white'>Hide {index+1}</span>
                                <span className='border border-white text-white bg-danger position-absolute translate-middle px-1 rounded-circle' style={{ fontSize: '13px', top: '11%', right: '-9%' }}>x</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </section> 
    </>
  )
}

export default AccountSetting;