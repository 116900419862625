import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import {  uploadPost } from '../../../UseContext/ApiHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import AddTags from '../../../Button/AddTags';
import axios from 'axios';
import { ApiContext } from '../../../UseContext/ApiCall';
import imageCompression from 'browser-image-compression';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../../../config';
import EmojiPicker from 'emoji-picker-react';
import MemeAdvanceSettingPopup from './MemeAdvanceSettingPopup';
import GetThumbnail from '../../../ThumbnailExtractor/GetThumbnails';
import { IoAddOutline, IoClose } from 'react-icons/io5';
import { BiTrash } from 'react-icons/bi';
import { createNotification } from "../../../UseContext/ApiHelpers";

const CreateMemes2 = ({ showCreateMemesPopup2, selectedEmoji,setSelectedMeme, setShowCreateMemesPopup1, selectedMeme, setShowCreateMemesPopup2 }) => {

  // const [showCreateMemesPopup3, setShowCreateMemesPopup3] = useState(false);
  const [description, setDescription] = useState('')
  const [loader, setLoader] = useState(false)
  const [sliderImage, setSliderImage] = useState([])
  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([])
  const [feelingActivity, setfeelingActivity] = useState(null);
  const [openEmoji, setOpenEmoji] = useState(false)
  const [videoIndex, setIndex] = useState(null)
  const [thumbnail, setThumbnail] = useState(false)
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { config, postOption, setallDataPost, loggedInUserId } = useContext(ApiContext);

  const isMeme = false;  

  useEffect(() => {
    setShowCreateMemesPopup1(false);
  }, [selectedMeme]);

  // Check if selectedMeme is an array before mapping over it
  useEffect(() => {
    // <GetThumbnail/>
    if (Array.isArray(selectedMeme)) {
      const urls = selectedMeme.map((file) => {
        const objectURL = URL.createObjectURL(file);
        return objectURL;
      });
      setSliderImage(urls);
      const index = selectedMeme.findIndex((file) => file.type.includes("video"));

      // Set the videoIndex state
      setIndex(index);
      setThumbnail(index !== -1);
      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    };
  }, [selectedMeme]);
  
  // To handle meme post
  const handleMemePost = async () => {
    try {
      let mediaUrls = [];
      let uploadResponse = '';

      setLoader(true);


      for (let i = 0; i < selectedMeme.length; i++) {
        const formData = new FormData();

        if (selectedMeme[i].type.slice(0, 5) === 'image') {
          const compressedPhotoAndVideo = await imageCompression(selectedMeme[i], postOption);
          formData.append("mediaUrls", compressedPhotoAndVideo);
          formData.append("postTumbnailUrl", compressedPhotoAndVideo)
        } else if (selectedMeme[i].type.slice(0, 5) === 'video') {
          formData.append("mediaUrls", selectedMeme[i]);
          formData.append("postTumbnailUrl", selectedImage)
        };

        uploadResponse = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config);

        // Get the media URL from the response and push it to mediaUrls array
        const uploadsdata = uploadResponse.data.mediaUrls[0]; // Access mediaUrls correctly
        mediaUrls.push(uploadsdata);
      };

      const mediaPostData = {
        category: "",
        description: description,
        title: "",
        emotion: selectedEmoji && selectedEmoji.emotion,
        emotionName: selectedEmoji && selectedEmoji.emotionName,
        emotionIcon: selectedEmoji && selectedEmoji.icon,
        mediaUrls: mediaUrls,
        postTumbnailUrl: uploadResponse.data.postTumbnailUrl,
        type: 'memes'
      };

      const savedPost = await uploadPost(mediaPostData, config)   // res.data dega 

      console.log("savedPost00", savedPost.post._id);

  

      // Handle uploaded files info and tags
      const uploadedFilesInfo = mediaUrls; // Use mediaUrls, not uploadResponse anymore
      const finalResponse = uploadedFilesInfo.map((fileInfo, index) => {
        const tags = tagsByCarouselItem[index] || [];
        return {
          ...fileInfo,
          tags: tags.map(tag => ({
            x: tag.xVal,
            y: tag.yVal,
            tagUserId: tag.tagUserId,
            fname: tag.fname,
            username: tag.username,
            profileImg: tag.profileImg,
            postedBy: loggedInUserId,
          })),
        };
      });


 const taguserids = finalResponse[0].tags.map(item => item.tagUserId);

 async function createNotifications() {
        for (let ids of taguserids) {
          const data = {
            targetId: ids,
            userType: "user",
            targetEvent: "tag",
            targetType: savedPost.post.type,
            targetEventId: savedPost.post._id,
            notificationType: "email",
          };
      
          try {
            const res = await createNotification(data, config);
            console.log("res", res);
          } catch (error) {
            console.error("Error creating notification for user", ids, error);
          }
        }
      }
      createNotifications();










      // Update the tags in the post
      await axios.post(`${BASEAPIURL}/post/updatetagpost`, {
        id: savedPost.post._id,
        mediaUrls: finalResponse,
      }, config);

      const finalData = {
        ...savedPost,
        mediaUrls: finalResponse,
      };
      setallDataPost(prev => [finalData.post, ...prev]);
      setLoader(false);
      setShowCreateMemesPopup2(false);
      setTagsByCarouselItem([])


    } catch (error) {
      console.log('err in memes', error)
      setLoader(false);
    }
  };

  const handleEmojiPick = (e) => {
    setDescription(prev => prev + e.emoji)
  };


  // ----- Advance Setting -----

  const [showMemeAdvaceSettingPopup, setShowMemeAdvaceSettingPopup] = useState(false);

  const inputRef = useRef();

  const handleAddMore = () => {
    inputRef.current.click()
  };

  const handleselectedMeme = async (e) => {
    // const photoAndVideo = e.target.files;
    const selectedFiles = Array.from(e.target.files);
    setSelectedMeme(prev => [...selectedFiles, ...prev])
  };

  const handleDelete = () => {
    
     if (Array.isArray(selectedMeme) && selectedMeme.length === 1) {
      setSelectedMeme([]);
      setShowCreateMemesPopup2(false);
    }

    else if (Array.isArray(selectedMeme) && selectedMeme.length > 1) {
      const updatedFiles = [...selectedMeme];
      updatedFiles.splice(activeIndex, 1); // Remove the file at the active index
      setSelectedMeme(updatedFiles);
      setActiveIndex(prev => prev - 1);
    }
  };
 


  return (
    <>
      <MemeAdvanceSettingPopup
        showMemeAdvaceSettingPopup={showMemeAdvaceSettingPopup}
        setShowMemeAdvaceSettingPopup={setShowMemeAdvaceSettingPopup}
        setShowCreateMemesPopup2={setShowCreateMemesPopup2}
      />

      <section>
        <Modal show={showCreateMemesPopup2} onHide={() => setShowCreateMemesPopup2(false)} centered>
          {/* backgroundImage: `url(https://demo3.sjainventures.com/e-milo/images/cmbgimg.png)`, */}
          <Modal.Body className='modal_body' style={{ backgroundImage: `url(${BASE_URL_STATIC}/cmbgimg.png)` }} >
            <section className='meme_section'>
              <div className='text-end mb-3'>
                <IoClose className='meme_close position-relative' onClick={() => setShowCreateMemesPopup2(false)} />
              </div>

              <div className="d-flex justify-content-between align-items-center m-2">
                <button onClick={handleAddMore} className="meme_add" style={{ color: "#64A6F0" }}> <IoAddOutline className="fw-bold" size={15} /> Add </button>

                <button className="pv_dlt" onClick={handleDelete}>
                  <BiTrash size={16} />
                </button>
              </div>

              <div className='createMeme2 position-relative'>
                <AddTags meme={isMeme} selectphotoAndVideo={selectedMeme} feelingActivity={feelingActivity} setfeelingActivity={setfeelingActivity} selectedMeme={selectedMeme} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} fileUrl={sliderImage} />
              </div>

              <input type="file" className="d-none" ref={inputRef} onChange={handleselectedMeme} accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime, video/webm" multiple />

              {thumbnail && <GetThumbnail videoIndex={videoIndex} selectedImage={selectedImage} setSelectedImage={setSelectedImage} file={selectedMeme[videoIndex]} status={status} setStatus={setStatus} />}

              <div>
                <div>
                  <form className="desp-form d-flex justify-content-center">
                    <label htmlFor="textArea"></label>
                    <textarea name="textArea" id="textArea" value={description} onChange={(e) => setDescription(e.target.value)} cols="39" rows="5" placeholder="Write Something" ></textarea>
                    <button type="button" className="smiley" >
                      <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpenEmoji(!openEmoji)} alt="" />
                    </button>

                    <span className='emoji-select-desp'>
                      {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
                    </span>
                  </form>
                </div>

                <div className='d-flex mt-1 justify-content-center' onClick={() => handleMemePost()}>
                  <button className='text_post_btn  col-6'>{loader ? <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> posting...</span> : 'post'}</button>
                </div>

                <div className='text-center text-white cursor-pointer mt-3'
                  onClick={() => {
                    setShowCreateMemesPopup2(false);
                    setShowMemeAdvaceSettingPopup(true);
                  }}
                >Advance Setting</div>
              </div>
            </section>
          </Modal.Body>


        </Modal>
      </section>

      {/* Create Memes Popup 3 */}
      {/* <CreateMemes3 showCreateMemesPopup3={showCreateMemesPopup3} selectedMeme={selectedMeme} setShowCreateMemesPopup3={setShowCreateMemesPopup3} /> */}
    </>
  )
}

export default CreateMemes2;
