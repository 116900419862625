import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import { BASEAPIURL } from '../../../../config';

const FollowedBy = ({ otherUserId }) => {

  const [name, setName] = useState('');
  const [number, setNumber] = useState(0);

  const { config } = useContext(ApiContext);

  const getFollowedBy = async() => {
    await axios.post(`${BASEAPIURL}/user/listofnamemutal`, { mutualFriendId: otherUserId }, config)
    .then(res => {
      if(res.data.mutualName.length !== 0) {
        setName(res.data.mutualName[0].fname);
        setNumber(res.data.mutualName.length - 1);
      };
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFollowedBy(otherUserId, config);
  }, [otherUserId]);

  return (
    <>
      <section>
        {
          name
          ?
            (number !== 0)
            ?
            <span className='rb-fby'>Followed by <span>{name}</span>  & {number} more</span>
            :
            <span className='rb-fby'>Followed by <span>{name}</span></span>
          :
          ""
        }
      </section>
    </>
  );
};

export default FollowedBy;
