import React, { useContext, useEffect, useState } from 'react'
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Rating from 'react-rating';
import { businessContext } from '../UseContext/BusinessContext';
import BusinessWriteReviewPopup from '../Popup/Home/BusinessWriteReviewPopup';
import BusinessReplyReviewPopup from '../Popup/Home/BusinessReplyReviewPopup';


const BusinessHomeReviews = () => {

  const { 
    paramsPageId,
    checkIsItMyPage, isItMyPage,
    setShowWriteReveiwPopup,
    pageReviews,
    setShowReplyReviewPopup,
    setReplyReviewData,
    getPageReviews,
    likeDislikeReview,
    setPageReviews,
  } = useContext(businessContext);

  console.log('pageReviews : ', pageReviews);


  // ----- Review (Show More/Less) -----
  const [expandedReviewIndex, setExpandedReviewIndex] = useState(null);

  const toggleShowMoreReview = (index) => {
    setExpandedReviewIndex(expandedReviewIndex === index ? null : index);
  };

  const truncateTextReview = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...'; 
  };


  // ----- Reply (Show More/Less) -----
  const [expandedReplyIndex, setExpandedReplyIndex] = useState(null);

  const toggleShowMoreReply = (index) => {
    setExpandedReplyIndex(expandedReplyIndex === index ? null : index);
  };

  const truncateTextReply = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text; 
    }
    return words.slice(0, wordLimit).join(' ') + '...'; 
  };


  // ----- Reply a Review -----
  const handleReplyReview = (review) => {
    setReplyReviewData(review);

    setShowReplyReviewPopup(true);
  };

  // To calculate average rating
  const averageRating = (reviews) => {
    if (reviews.length === 0) return 0;
  
    const totalRating = reviews.reduce((sum, review) => sum + Number(review.rating), 0); 
    return (totalRating / reviews.length).toFixed(1);
  };

  const countRatings = (reviews) => {
    const ratingCounts = reviews.reduce((acc, { rating }) => {
      const rate = Number(rating);
      acc[rate] = (acc[rate] || 0) + 1; // Count each rating (1-5)
      return acc;
    }, { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }); // Ensure all ratings (1-5) exist
  
    return ratingCounts;
  };
  
  const totalReviews = pageReviews.length;

  const ratingsData = countRatings(pageReviews);
  
  const getPercentage = (count) => {
    if (totalReviews === 0) return 0;
    return (count / totalReviews) * 100;
  };

  // To handle like dislike review 
  const handleLikeDislikeReview = (review) => {
    // Update in front end
    const updatedReviews = pageReviews.map((r) => {
      if (r._id === review._id) {
        return {
          ...r,
          liked: !r.liked,
          likeCount: r.liked ? r.likeCount - 1 : r.likeCount + 1,
        };
      }
      return r;
    });
  
    setPageReviews(updatedReviews);
  
    // Update in back end
    likeDislikeReview(review);
  };


  useEffect(() => {
    getPageReviews(paramsPageId);
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]); 
  

  return (
    <>
      {/* Write a Review Popup */}
      <BusinessWriteReviewPopup />

      {/* Reply a Review Popup */}
      <BusinessReplyReviewPopup />

      <section>
        {/* Rate this page or Write a reveiw */}
        <>
          {
            !isItMyPage
            &&
            <div className='rounded-3 shadow-sm mb-5 p-3' style={{ backgroundColor: '#f8f8f8' }}>
              <h5 className='fw-semibold mb-0'>Rate this page</h5>

              <small>Tell other's what you think about this business page</small>

              <div className='d-flex gap-3 flex-column flex-sm-row justify-content-between align-items-center mt-3'>
                <Rating
                  className='text-center'
                  readonly
                  emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '30px' }} alt="empty" />}
                  fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '30px' }} alt="fill" />}
                />

                <button type="button" className='btn btn-primary' onClick={() => setShowWriteReveiwPopup(true)}>Write Review</button>
              </div>
            </div>
          }
        </>

        {/* Reveiw & Rating details */}
        <div className='mb-5 d-flex flex-column  flex-md-row gap-3 justify-content-between'>
          <div className='border-end flex-grow-1'>
            <h6 className='fw-semibold mb-0'>Total Review</h6>

            <h3 className='fw-semibold mx-0 my-2'>{pageReviews.length}</h3>

            <div>
              <span className='text-info'>1.2k</span> <span className='text-secondary'>Review in last 1 month</span>
            </div>
          </div>

          <hr className='hr bg-secondary-subtle d-md-none' />

          <div className='border-end flex-grow-1'>
            <h6 className='fw-semibold mb-0'>Average Rating</h6>

            <div className='d-flex gap-3 my-2'>
              <h3 className='fw-semibold m-0'>{averageRating(pageReviews)}</h3>

              <Rating
                className='text-center'
                initialRating={4}
                readonly
                emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '25px' }} alt="empty" />}
                fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '25px' }} alt="fill" />}
              />
            </div>

            <div>
              <span className='text-info'>87%</span> <span className='text-secondary'>User recommend this page</span>
            </div>
          </div>

          <hr className='hr bg-secondary-subtle d-md-none' />

          <div className='flex-grow-1'>
            {/* 5 Star Rating */}
            <div className='d-flex align-items-center gap-1'>
              <small>5</small>
              <img src={`/images/icons/rating-2.svg`} style={{ height: '15px' }} alt="star" />
              <div className="progress mx-2" role="progressbar" aria-valuenow={getPercentage(ratingsData[5])} aria-valuemin="0" aria-valuemax="100" style={{ height: '10px', width: '200px' }}>
                <div className="progress-bar bg-success" style={{ width: `${getPercentage(ratingsData[5])}%` }}></div>
              </div>
              <small className='text-secondary'>{ratingsData[5]}</small> {/* Show count for 5-star */}
            </div>

            {/* 4 Star Rating */}
            <div className='d-flex align-items-center gap-1'>
              <small>4</small>
              <img src={`/images/icons/rating-2.svg`} style={{ height: '15px' }} alt="star" />
              <div className="progress mx-2" role="progressbar" aria-valuenow={getPercentage(ratingsData[4])} aria-valuemin="0" aria-valuemax="100" style={{ height: '10px', width: '200px' }}>
                <div className="progress-bar bg-info" style={{ width: `${getPercentage(ratingsData[4])}%` }}></div>
              </div>
              <small className='text-secondary'>{ratingsData[4]}</small> {/* Show count for 4-star */}
            </div>

            {/* 3 Star Rating */}
            <div className='d-flex align-items-center gap-1'>
              <small>3</small>
              <img src={`/images/icons/rating-2.svg`} style={{ height: '15px' }} alt="star" />
              <div className="progress mx-2" role="progressbar" aria-valuenow={getPercentage(ratingsData[3])} aria-valuemin="0" aria-valuemax="100" style={{ height: '10px', width: '200px' }}>
                <div className="progress-bar bg-warning" style={{ width: `${getPercentage(ratingsData[3])}%` }}></div>
              </div>
              <small className='text-secondary'>{ratingsData[3]}</small> {/* Show count for 3-star */}
            </div>

            {/* 2 Star Rating */}
            <div className='d-flex align-items-center gap-1'>
              <small>2</small>
              <img src={`/images/icons/rating-2.svg`} style={{ height: '15px' }} alt="star" />
              <div className="progress mx-2" role="progressbar" aria-valuenow={getPercentage(ratingsData[2])} aria-valuemin="0" aria-valuemax="100" style={{ height: '10px', width: '200px' }}>
                <div className="progress-bar bg-danger" style={{ width: `${getPercentage(ratingsData[2])}%` }}></div>
              </div>
              <small className='text-secondary'>{ratingsData[2]}</small> {/* Show count for 2-star */}
            </div>

            {/* 1 Star Rating */}
            <div className='d-flex align-items-center gap-1'>
              <small>1</small>
              <img src={`/images/icons/rating-2.svg`} style={{ height: '15px' }} alt="star" />
              <div className="progress mx-2" role="progressbar" aria-valuenow={getPercentage(ratingsData[1])} aria-valuemin="0" aria-valuemax="100" style={{ height: '10px', width: '200px' }}>
                <div className="progress-bar bg-success" style={{ width: `${getPercentage(ratingsData[1])}%` }}></div>
              </div>
              <small className='text-secondary'>{ratingsData[1]}</small> {/* Show count for 1-star */}
            </div>
          </div>
        </div>

        <hr className='hr bg-secondary-subtle' />

        {/* Reviews */}
        <div className='business-home-reviews pb-3'>
          {
            pageReviews.length > 0
            &&
            pageReviews.map((review, index) => (
              <div className='bg-white rounded-4 p-3 shadow-sm' key={index}>
                <div className='d-flex align-items-center'>
                  <img src={AwsFileUrl+review.userId.imageId} className='border rounded-circle object-fit-cover' style={{ height: '45px', width: '45px' }} alt="profile" />

                  <div className='ms-3 me-auto'>
                    <h6 className='fw-semibold mb-0'>{review.userId.fname}</h6>
                    <small className='text-secondary d-block'>
                      {
                        new Date(review.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'long', // You can use 'long' if you want the full month name
                          year: 'numeric',
                        })
                      }
                    </small>
                    
                    <Rating
                      className='text-center me-2'
                      initialRating={review.rating}
                      readonly
                      emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '15px' }} alt="empty" />}
                      fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '15px' }} alt="fill" />}
                    />

                    <small className='fw-semibold'>({review.rating}/5)</small>
                  </div>

                  {/* Three Dot */}
                  <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} className='cursor-pointer' style={{ height: '17px' }} alt="threeDot" />
                </div>
                
                {/* Review */}
                <p className='my-3'>
                  {expandedReviewIndex === index ? review.review : truncateTextReview(review.review, 10)} 
                  {
                    review.review.split(' ').length > 10 && 
                    <small className='text-primary cursor-pointer' onClick={() => toggleShowMoreReview(index)}>
                      {expandedReviewIndex === index ? ' Show less...' : ' Show more...'}
                    </small>
                  }
                </p>

                {/* Reply */}
                <>
                  {review.reply && (
                    <p className='bg-primary-subtle p-2 rounded-3 mb-3'>
                      {expandedReplyIndex === index ? review.reply : truncateTextReply(review.reply, 10)} 
                      {
                        review.reply.split(' ').length > 10 &&
                        <small className='text-primary cursor-pointer' onClick={() => toggleShowMoreReply(index)}>
                          {expandedReplyIndex === index ? ' Show less...' : ' Show more...'}
                        </small>
                      }
                    </p>
                  )}
                </>

                {/* Like & Reply */}
                <div className='d-flex justify-content-end'>
                  {/* Like */}
                  <div>
                    <img src={`${BASE_URL_STATIC}/icon/like-${review.liked ? '2' : '1'}.svg`} className='me-1 cursor-pointer' style={{ height: '25px' }} onClick={() => handleLikeDislikeReview(review)} alt="like" />
 
                    <small className='text-secondary'>{review.likeCount}</small>
                  </div>

                  {/* Reply */}
                  {
                    isItMyPage
                    &&
                    <div className='ms-4 cursor-pointer' onClick={() => handleReplyReview(review)}>
                      <img src={`/images/business/icons/review-reply.svg`} className='me-1' style={{ height: '23px' }} alt="review-reply" />
                      <small className='text-secondary'>Reply</small>
                    </div>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </section> 
    </>
  );
};

export default BusinessHomeReviews;