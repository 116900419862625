import React from 'react';
import PropTypes from 'prop-types'
import './ToggleButton.css'; // For styling the button

const ToggleButton = ({ Option, setOption }) => {

  const handleToggle = () => {
    setOption(!Option);
  };

  return (
    <button className={`toggle-button ${Option ? 'on' : 'off'}`} onClick={handleToggle}>
      {Option ? 'ON' : 'OFF'}
    </button>
  );
};

ToggleButton.propTypes = {
  Option: PropTypes.any,
  setOption: PropTypes.any,
}

export default ToggleButton;
