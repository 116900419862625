import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../config';
import { MdOutlineDone } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import CreatePhotoAndVideo2 from './CreateNewPost/CreatePhotoAndVideo/CreatePhotoAndVideo2';

const CameraPopup = ({ open, close, setShowCreatePostPopup, setSelectedPhotoVideo,
  selectphotoAndVideo,
  location,
  setLocation,
  showPV, setShowPv2 }) => {
  const videoRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [startV, setStartV] = useState(false)
  const [openPV , setOpenPv] = useState(false)


  const startCamera = async () => {
    try {
      setStartV(true)
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
      setStartV(false)
    }
  };

  useEffect(() => {
    setImageSrc(null)
  }, [])


  const captureImage = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a Blob
      canvas.toBlob(blob => {
        if (blob) {
          // Create a File object from the Blob
          const file = new File([blob], `${Date.now()}captured_image.jpeg`, { type: 'image/jpeg' });
          setImageSrc(file);
          setSelectedPhotoVideo(prev => [ file ,...prev]);

        }
      }, 'image/jpeg');
    }
  };

  const handleDone = (imageSrc) => {
    if (imageSrc) {
      setStartV(false)
      setShowPv2(true);
      setShowCreatePostPopup(false);
      close();
      setOpenPv(true)
      setImageSrc(null)
    }
  };



  return (
    <section className='camera-modal'>

      <Modal show={open} onHide={() => { close(); setImageSrc(null); setStartV(false) }} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header style={{ color: "white" }} closeButton>&nbsp;</Modal.Header>
        <Modal.Body style={{ background: "black" }}>
          <div className='position-relative'>
            <div className="row position-relative" style={{zIndex : "5"}}>
              <p className="col-1 text-white" style={{ cursor: "pointer" }}
                onClick={() => { setShowCreatePostPopup(true); close(); setImageSrc(null); setStartV(false) }}
              >
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
              </p>
              <p className="col-11 fs-6 text-center text-white">
                Camera
              </p>
            </div>

            <div className='d-flex flex-column justify-content-center align-items-center position-relative' style={{zIndex : "5"}}>
              {imageSrc !== null ? (
                <img src={URL.createObjectURL(imageSrc)} alt="Captured" className='my-3 rounded-3' />
              ) : (
                <>
                  {startV && <video className='my-3' ref={videoRef} autoPlay playsInline />}
                  <div className='camera_bg'>

                    {!startV && <img src={`${BASE_URL_STATIC}/camera-bg.png`} alt="" />}
                  </div>
                  {!startV && <button className='camera-btn' onClick={startCamera}>
                    <img src={`${BASE_URL_STATIC}/camera-on.png`} alt="" />
                    <span>Start</span>
                  </button>}
                  {startV && <button className='camera-btn' onClick={captureImage}>
                    <img src={`${BASE_URL_STATIC}/camera-started.png`}  alt="" />
                    <span>
                      Capture
                    </span>
                  </button>}
                </>
              )}
            </div>

            <div className='camera_start_loader'>
              <span className=' loader-camera'> </span> <small>Please wait...</small> 
            </div>

            {imageSrc && (<div className='d-flex justify-content-between align-items-center rounded-4 my-3'>
              <p onClick={() => { handleDone(imageSrc) }} className='rounded p-3 text-white fs-3' style={{ cursor: "pointer" }}><MdOutlineDone /></p>
              <p className='rounded p-3 text-white fs-3' onClick={() => { setImageSrc(null); setStartV(false) }} style={{ cursor: "pointer" }}><IoCloseOutline /></p>
            </div>)}

          </div>
        </Modal.Body>
      </Modal>

{openPV &&  <CreatePhotoAndVideo2 showPV={showPV} setShowPv2={setShowPv2}
        selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo}
        location={location} setLocation={setLocation} setShowCreatePostPopup={setShowCreatePostPopup} />}

    </section>

  );
};

export default CameraPopup;
