import React, { useEffect, useState } from 'react'
import { AwsFileUrl } from '../../../../config'
import { getDocFiles } from '../../UseContext/ApiHelpers'

const Getdocs = ({ chatId, config }) => {

    const [docFiles, setdocFiles] = useState([])

    useEffect(() => {
        getDocFiles(chatId, config).then((res) => {
            setdocFiles(res)
        })
    }, [])
    return (
        <div className='d-flex flex-wrap ms-4'>
            {docFiles.map((e, index) => (
                <div key={index} className='border-2 bg-slate-300 rounded-md p-1 flex info_set'>
                    <a target='_blank' rel="noopener noreferrer" href={AwsFileUrl + e.fileUrl} download={true}>
                        <img className='object-fit-cover' src="/images/icons/chat-pdf.svg" alt="" srcset="" />
                        <p className='fs-10'>{e.fileName}</p>
                    </a>
                </div>
            ))}
        </div>
    )
}

export default Getdocs