import React from 'react';
import Modal from "react-bootstrap/Modal";

const VisibilityMemePopup = ({ showVisibilityMemePopup, setShowVisibilityMemePopup }) => {

  return (
    <>
      <section className='visibilityMemePopups'>
        <Modal show={showVisibilityMemePopup} onHide={() => setShowVisibilityMemePopup(false)} size="sm" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='visibilityMemePopup'>
              <form className='visibilityMemePopup-form'>
                <input type="radio" id="following" name="visibility" />
                <label htmlFor="following">Following</label> 

                <input type="radio" id="everyone" name="visibility" />
                <label htmlFor="everyone">Everyone</label>
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default VisibilityMemePopup
