import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../../../UseContext/ApiCall';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AwsFileUrl } from '../../../../../../config';
import ReactPlayer from 'react-player';


const StoriesInsightsPopup = () => {

  const { 
    showStoriesInsightsPopup, setShowStoriesInsightsPopup, 
    setShowViewStoriesPopup,
    getInsightStories,
    getInsightsStoryViewsLikesComments,
    insightStories, setInsightStories,
    showInsightStory, setShowInsightStory,
    selectedInsightStory, setSelectedInsightStory,
    updateStoryAdvanceSetting,
    deleteStory,
  } = useContext(ApiContext);


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  // Handle change/update advance setting (audience, storyExpireTime, like & comment)
  const handleChangeStoryAdvanceSetting = (e) => {
    const { name, type, value, checked } = e.target;

    const newValue = type === 'checkbox' ? (checked ? 'on' : 'off') : value;

    // Update in front end 
    setSelectedInsightStory((prevState) => ({
      ...prevState,
      story: {
        ...prevState.story,
        [name]: newValue,
      },
    }));

    setInsightStories((prevState) => {
      return prevState.map((storyItem) => {
        if (storyItem._id === selectedInsightStory.story._id) {
          return {
            ...storyItem,
            [name]: newValue,
          };
        }
        return storyItem; 
      });
    });

    // Update in back end 
    updateStoryAdvanceSetting(selectedInsightStory.story._id, name, newValue);
  };

  useEffect(() => {
    showStoriesInsightsPopup && getInsightStories();
  }, []);

  useEffect(() => {
    showStoriesInsightsPopup && getInsightsStoryViewsLikesComments(showInsightStory.views ? 'views' : showInsightStory.likes ? 'likes' : showInsightStory.comments && 'comments');
  }, [selectedInsightStory.story]);

  return (
    <>
      <Modal show={showStoriesInsightsPopup} onHide={() => setShowStoriesInsightsPopup(false)} centered backdrop="static" size="md" keyboard={false} className="create-call show-stories-popup" >
      {/* <Modal show={true} onHide={() => setShowStoriesInsightsPopup(false)} centered backdrop="static" size="md" keyboard={false} className="create-call show-stories-popup" > */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className='p-0 rounded-3'>
          <section className='position-relative'>
            <div className='d-flex align-items-center my-3 ms-3'>
              <span className='cursor-pointer' onClick={() => {setShowViewStoriesPopup(true); setShowStoriesInsightsPopup(false)}}>⮜</span>
              <h5 className='mb-0 fw-semibold mx-auto'>Insights</h5>
            </div>

            {/* Stories */}
            <div className='mb-3'>
              <Slider {...sliderSettings} className='stories-insights-slick py-1'>
                {
                  insightStories.map((story, index) => (
                    <div style={{ height: '100px' }} key={index}
                      className={`${story._id === selectedInsightStory.story._id && 'border border-primary'} rounded-4 bg-dark d-flex align-items-center justify-content-center`}
                      onClick={() => setSelectedInsightStory(preState => ({ ...preState, story: story }))}
                    >
                      {
                        story.mediaUrls[0].filetype === 'image'
                        ?
                        <img src={AwsFileUrl+story.mediaUrls[0].url} className='h-100 w-100 rounded-4' alt="story" />
                        :
                        <ReactPlayer url={AwsFileUrl+story.mediaUrls[0].url} />
                      }
                    </div>
                  ))
                }
              </Slider>
            </div>

            <hr className='hr bg-secondary-subtle' />

            <div className='mx-3 mb-3 d-flex align-items-center gap-3'>
              {/* Audience */}
              <select className="form-select w-50" name="visibility" value={selectedInsightStory.story.visibility} onChange={handleChangeStoryAdvanceSetting}>
                <option value="public">Public</option>
                <option value="followers">Followers</option>
                <option value="fof">Followers of Followers</option>
                <option value="cf">Close Friends</option>
              </select>

              {/* Expire Time */}
              <select className="form-select w-25" name="storyExpireTime" value={selectedInsightStory.story.storyExpireTime} onChange={handleChangeStoryAdvanceSetting}>
                <option value="24">24 Hrs</option>
                <option value="48">48 Hrs</option>
              </select>

              {/* Show Views Count */}
              <>
                {
                  showInsightStory.views
                  &&
                  <div>
                    <img src="https://s3aws.emilo.in/images/icon/view-1.svg" className='me-2' style={{ height: '15px' }} alt="view" />
                    <span>{selectedInsightStory.views.length}</span>
                  </div>
                }
              </>

              {/* Show Likes Count */}
              <>
                {
                  showInsightStory.likes
                  &&
                  <div>
                    <img src="https://s3aws.emilo.in/images/icon/like-1.svg" className='me-2' style={{ height: '23px' }} alt="view" />
                    <span>{selectedInsightStory.likes.length}</span>
                  </div>
                }
              </>

              {/* Show Comments Count */}
              <>
                {
                  showInsightStory.comments
                  &&
                  <div>
                    <img src="https://s3aws.emilo.in/images/icon/comment-1.svg" className='me-2' style={{ height: '25px' }} alt="view" />
                    <span>{selectedInsightStory.comments.length}</span>
                  </div>
                }
              </>
            </div>

            {/* Likes */}
            <div className='mb-2 mx-3'>
              <span>Likes</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='like' 
                  checked={selectedInsightStory.story.like === 'on'}
                  onChange={handleChangeStoryAdvanceSetting}
                />
              </div>
            </div>

            {/* Comments */}
            <div className='mb-3 mx-3'>
              <span>Comments</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='comment' 
                  checked={selectedInsightStory.story.comment === 'on'}
                  onChange={handleChangeStoryAdvanceSetting}
                />
              </div>
            </div>

            <hr className='hr bg-secondary-subtle' />

            {/* Toggle > Views, Likes, Comments */}
            <div className='d-flex align-items-center gap-3 mx-3 mb-4'>
              <span className={`${showInsightStory.views && 'bg-primary text-white'} px-3 py-1 rounded-pill shadow cursor-pointer`}
                onClick={() => {
                  setShowInsightStory({ views: true, likes: false, comments: false });
                  getInsightsStoryViewsLikesComments('views');
                }}
              >Views</span>

              <span className={`${showInsightStory.likes && 'bg-primary text-white'} px-3 py-1 rounded-pill shadow cursor-pointer`}
                onClick={() => {
                  setShowInsightStory({ views: false, likes: true, comments: false });
                  getInsightsStoryViewsLikesComments('likes');
                }}
              >Likes</span>

              <span className={`${showInsightStory.comments && 'bg-primary text-white'} px-3 py-1 rounded-pill shadow cursor-pointer`}
                onClick={() => {
                  setShowInsightStory({ views: false, likes: false, comments: true });
                  getInsightsStoryViewsLikesComments('comments');
                }}
              >Comments</span>

              {/* Delete */}
              <img src="/images/icons/delete-black.svg" className='cursor-pointer ms-auto' style={{ height: '25px' }} onClick={deleteStory} alt="delete" />
            </div>

            {/* views users list */}
            <>
                {
                  showInsightStory.views
                  &&
                  <div className='mx-3 mb-3 overflow-y-scroll pe-2' style={{ maxHeight: '25vh' }}>
                    {
                      selectedInsightStory.views.length > 0
                      &&
                      selectedInsightStory.views.map((user, index) => (
                        <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                          {console.log('user : ', user)}
                          <img src={AwsFileUrl+user.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                          <div>
                            <h6 className='mb-0 fw-semibold'>{user.name} <small className='text-secondary ms-1'>● {user.timeAgo}</small></h6>
                            <span className='text-secondary'>{user.username ? user.username : 'sidddongre06@'}</span>
                          </div>

                          <img src="/images/icons/three-dot-black.svg" className='ms-auto' style={{ height: '20px' }} alt="three-dot" />
                        </div>
                      ))
                    }
                  </div>
                }
            </>

            {/* likes users list */}
            <>
              {
                showInsightStory.likes
                &&
                <div className='mx-3 mb-3 overflow-y-scroll pe-2' style={{ maxHeight: '25vh' }}>
                  {
                    selectedInsightStory.likes.length > 0
                    &&
                    selectedInsightStory.likes.map((user, index) => (
                      <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                        <img src={AwsFileUrl+user.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                        <div>
                          <h6 className='mb-0 fw-semibold'>{user.name}</h6>
                          <span className='text-secondary'>{user.username ? user.username : 'sidddongre06@'}</span>
                        </div>

                        <img src="/images/icons/three-dot-black.svg" className='ms-auto' style={{ height: '20px' }} alt="three-dot" />
                      </div>
                    ))
                  }
                </div>
              }
            </>

            {/* comments users list */}
            <>
              {
                showInsightStory.comments
                &&
                <div className='mx-3 mb-3 overflow-y-scroll pe-2' style={{ maxHeight: '25vh' }}>
                  {
                    selectedInsightStory.comments.length > 0
                    &&
                    selectedInsightStory.comments.map((user, index) => (
                      <div className='d-flex align-items-center gap-3 mb-3' key={index}>
                        <img src={AwsFileUrl+user.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                        <div>
                          <h6 className='mb-0 fw-semibold'>{user.name}</h6>
                          <span className='text-secondary'>{user.username ? user.username : 'sidddongre06@'}</span>
                          <span className='text-secondary d-block'>{user.comment ? user.comment : 'comment'}</span>
                        </div>

                        <img src="/images/icons/three-dot-black.svg" className='ms-auto' style={{ height: '20px' }} alt="three-dot" />
                      </div>
                    ))
                  }
                </div>
              }
            </>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoriesInsightsPopup;