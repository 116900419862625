import React, { useEffect, useContext } from 'react';
import { useInView } from "react-intersection-observer";
import CheckInCenterBlock from '../Home/CenterBlock/CenterBlockPosts/CheckInCenterBlock';
import { ApiContext } from '../UseContext/ApiCall';
import { postEvent } from '../UseContext/ApiHelpers';

const ViewCheckinText = ({ post }) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const { countPost,config } = useContext(ApiContext);

    useEffect(() => {
        if (inView) {
            postEvent(post.postId , "view" , post.userId , "post" , config);
        }
    }, [inView, post.postId, countPost]);

    // console.log("view", inView);
    

    return (
        <div ref={ref}>
            {inView && (
                <div>
                    {post.postId && post.type === 'checkin' && (
                        <>
                            <div className="checkinPost">
                                <CheckInCenterBlock post={post} />
                            </div>
                            <div className='text-start' dangerouslySetInnerHTML={{ __html: post.description }}></div>
                        </>
                    )}
                    {post.postId && post.type === 'textpost' && (
                        <div className="textPost">
                            <div className='html_class' dangerouslySetInnerHTML={{ __html: post.description }}></div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewCheckinText;
