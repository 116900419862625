import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import { settingContext } from '../../../UseContext/SettingContext';
import SettingInputUser from '../../../Profile/ProfileUser/ProfileUserSetting/SettingInputUser';
import { ApiContext } from '../../../UseContext/ApiCall';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';



const PostAdvanceSettingPopup = ({
  showPostAdvanceSetttingPopup, setShowPostAdvanceSetttingPopup,
  setShowPv2,
}) => {

  const { config } = useContext(ApiContext);
  const { value, setValue, handleOptionChange, setAdded, isAccountPrivate } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };

  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('privacy', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        // ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get (Followers Except, Hide From, Block Comments Users and Others)
    getAdded('privacy', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        // ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <> 
      <Modal show={showPostAdvanceSetttingPopup} onHide={() => setShowPostAdvanceSetttingPopup(false)} className='create-post m-0' backdrop="static" centered >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section>
            <div className='d-flex'>
              <span className='cursor-pointer' onClick={() => {setShowPostAdvanceSetttingPopup(false); setShowPv2(true)}}>⮜</span>
              <h4 className='mx-auto text-primary'>Advance Setting</h4>
            </div>

            <div className="accordion accordion-flush overflow-y-scroll pe-1 my-4" style={{ maxHeight: '50vh' }}>
              {/* Audience Control */}
              <div>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-audienceControl">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Audience Control</h5>
                </div>

                <div id='post-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* View */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#audienceControlView">Who can view your post?</h5>

                    <div id='audienceControlView' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postView-public" name="postView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.postView || value.postView === 'public'} 
                            disabled={isAccountPrivate === 'private'}
                          />
                          <label className="form-check-label" htmlFor="ps-postView-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postView-followers" name="postView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postView === 'followers'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postView-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postView-cf" name="postView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postView === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postView-cf">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postView-fof" name="postView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postView === 'fof'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postView-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.postView ? value.postView : 'public']} view your post)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.postView) || (value.postView === 'public') || (value.postView === 'followers') || (value.postView === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.postView || value.postView === 'public') ? 'postViewPublic' : 
                                value.postView === 'followers' ? 'postViewFollowers' : 
                                value.postView === 'fof' && 'postViewFOF'
                              }
                              settingType={value.postView ? value.postView : 'public'} 
                              header={
                                (!value.postView || value.postView === 'public') ? 'Hide post from specific people' :
                                value.postView === 'followers' ? 'Followers Except' :
                                value.postView === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your post' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Likes & Comments */}
              <div className='my-3'>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-likeAndComments">
                  <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                  <h5 className='fw-semibold ms-3 mb-0'>Likes & Comments</h5>
                </div>

                <div id='post-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Like Count */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0'>Hide like count</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' value={value.postLike === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.postLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <small className='text-secondary d-block ms-3 mt-2'>(Confirm if you want to hide the like counts of your post)</small>
                  </div>

                  <hr className='hr bg-secondary my-3 ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Comments */}
                  <div className='ms-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#likeAndCommentsComment">Who can comment on your post?</h5>

                    <div id='likeAndCommentsComment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postComment-public" name="postComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postComment && value.postView === 'public') || !value.postComment || value.postComment === 'public'} 
                            disabled={value.postView === 'fof' || value.postView === 'followers' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postComment-public">Public</label>
                        </div>


                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postComment-followers" name="postComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postComment && value.postView === 'followers') || value.postComment === 'followers'}
                            disabled={value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postComment-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postComment-cf" name="postComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postComment && value.postView === 'closeFriends') || value.postComment === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postComment-cf">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postComment-fof" name="postComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postComment && value.postView === 'fof') || value.postComment === 'fof'}
                            disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postComment-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.postComment ? value.postComment : 'public']} comment on your post.)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.postComment) || (value.postComment === 'public') || (value.postComment === 'followers') || (value.postComment === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.postComment || value.postComment === 'public') ? 'postCommentPublic' : 
                                value.postComment === 'followers' ? 'postCommentFollowers' : 
                                value.postComment === 'fof' && 'postCommentFOF'
                              }
                              settingType={value.postComment ? value.postComment : 'public'} 
                              header={
                                (!value.postComment || value.postComment === 'public') ? 'Hide post comment from specific people' :
                                value.postComment === 'followers' ? 'Followers Except' :
                                value.postComment === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your post comment' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Share & Download */}
              <div className='my-3'>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-shareAndDownload">
                  <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                  <h5 className='fw-semibold ms-3 mb-0'>Share & Download</h5>
                </div>

                <div id='post-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Share */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-share">Who can share your post?</h5>

                    <div id='shareAndDownload-share' className='row accordion-collapse show collapse mt-3 ms-3 me-0'> 
                      {/* value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postShare-public" name="postShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postShare === 'public' || !value.postShare}
                            disabled={value.postView === 'followers' || value.postView === 'fof' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postShare-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postShare-followers" name="postShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postShare && value.postView === 'followers') || value.postShare === 'followers'} 
                            disabled={value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postShare-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postShare-cf" name="postShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postShare && value.postView === 'closeFriends') || value.postShare === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postShare-cf">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postShare-fof" name="postShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postShare && value.postView === 'fof')  || value.postShare === 'fof'} 
                            disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postShare-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.postShare ? value.postShare : 'public']} share your post.)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.postShare) || (value.postShare === 'public') || (value.postShare === 'followers') || (value.postShare === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.postShare || value.postShare === 'public') ? 'postSharePublic' : 
                                value.postShare === 'followers' ? 'postShareFollowers' : 
                                value.postShare === 'fof' && 'postShareFOF'
                              }
                              settingType={value.postShare ? value.postShare : 'public'} 
                              header={
                                (!value.postShare || value.postShare === 'public') ? 'Hide post share from specific people' :
                                value.postShare === 'followers' ? 'Followers Except' :
                                value.postShare === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your post share' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>

                  {/* Download */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-download">Who can download your post?</h5>

                    <div id='shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postDownload-public" name="postDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postDownload === 'public' || !value.postDownload} 
                            disabled={value.postView === 'followers' || value.postView === 'fof' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postDownload-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postDownload-followers" name="postDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postDownload && value.postView === 'followers') || value.postDownload === 'followers'} 
                            disabled={value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postDownload-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postDownload-cf" name="postDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postDownload && value.postView === 'closeFriends') || value.postDownload === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postDownload-cf">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postDownload-fof" name="postDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.postDownload && value.postView === 'fof') || value.postDownload === 'fof'} 
                            disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-postDownload-fof">Followers of Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-postDownload-none" name="postDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.postDownload === 'none'} 
                          />
                          <label className="form-check-label" htmlFor="ps-postDownload-none">No one</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.postDownload ? value.postDownload : 'public']} download your post.)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.postDownload) || (value.postDownload === 'public') || (value.postDownload === 'followers') || (value.postDownload === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.postDownload || value.postDownload === 'public') ? 'postDownloadPublic' : 
                                value.postDownload === 'followers' ? 'postDownloadFollowers' : 
                                value.postDownload === 'fof' && 'postDownloadFOF'
                              }
                              settingType={value.postDownload ? value.postDownload : 'public'} 
                              header={
                                (!value.postDownload || value.postDownload === 'public') ? 'Hide post download from specific people' :
                                value.postDownload === 'followers' ? 'Followers Except' :
                                value.postDownload === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your post download' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>

                  {/* post share to story */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow sharing of your post on stories</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked defaultChecked />
                      </div>
                    </div>

                    <div className='ms-4 mt-3'>
                      <span>By default, your selected audience for the post will be able to share your post on their stories. However, if you want to disallow specific people from using your post, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='postShareToStory' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary my-3' style={{ width: '-webkit-fill-available' }} />
                  </div>
                </div>
              </div>

              {/* Other Permission */}
              <div>
                <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-otherPermission">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Other Permission</h5>
                </div>

                <div id='post-otherPermission' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Post Used In Meme */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow your post to be used in memes</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked defaultChecked />
                      </div>
                    </div>

                    <div className='ms-4'>
                      <span>By default, your selected audience for the post will be able to use your post for creating memes. However, if you want to disallow specific people from using your post, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='postUsedInMeme' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }} />
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className='btn btn-primary rounded-pill w-100'>Done</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PostAdvanceSettingPopup
