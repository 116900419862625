import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import ProfileImage from '../../../UseContext/ProfileImage';
import { getAllReels, uploadpostUrl, uploadPost } from '../../../UseContext/ApiHelpers';
// import CreateReel4details from '../../CreateReel4addOns/CreateReel4details';
import { ApiContext } from '../../../UseContext/ApiCall';
// import AddLocation from '../AddLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import CreateReel4details from '../../../CreateReel4addOns/CreateReel4details';
import EmojiPicker from 'emoji-picker-react';
import AddTags from '../../../Button/AddTags';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../../../config';
import EmojiPopup from '../../EmojiPopup';
import { createNotification } from "../../../UseContext/ApiHelpers";
// import { postReel } from '../../UseContext/ApiHelpers';

const CreateReels4 = ({ reels, selectedImage,setSelectedEmoji,selectedEmoji, setShowCreateReelsPopup2, showCreateReelsPopup4, setShowCreateReelsPopup4 }) => {

  const { config ,setallDataPost} = useContext(ApiContext)
  const [description, setDescription] = useState("");
  const [fileUrl, setFileUrl] = useState([])
  const [selectphotoAndVideo, setSelectphotoAndVideo] = useState([])
  const [feelingActivity, setfeelingActivity] = useState(null);
  const [openEmoji, setOpenEmoji] = useState(false)
  // const loggedInUserId = localStorage.getItem('loggedInUserId');
  const { loggedInUserId, loggedInUserName } = useContext(ApiContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loader, setLoader] = useState(false)
  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([]); 

  console.log("tagsByCarouselItem", tagsByCarouselItem)

  // const loggedInUserId = localStorage.getItem('loggedInUserId');

  useEffect(() => {
    if (reels) {
      const url = URL.createObjectURL(reels)
      setFileUrl([url]);
      setSelectphotoAndVideo([reels])
    }
  }, [reels]);

  const handleEmojiPick = (e) => {
    setDescription(prev => prev + e.emoji)
  }

  const handleCreateReelPopupPre4 = () => {
    setShowCreateReelsPopup2(true);
    setShowCreateReelsPopup4(false);
  };

  const handleCreateReelPopupNext4 = async () => {
    setLoader(true)
    const formData = new FormData()
    formData.append("mediaUrls", reels)
    formData.append("postTumbnailUrl", selectedImage);
    formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3')
    formData.append("start", 'ssss')
    formData.append("end", 'sss')

    await uploadpostUrl(formData, config)
      .then(async (res) => {
        setLoader(false);
        // setShowCreateReelsPopup4(false);
        getAllReels()
        const mediaPostData = {
          description: description,
          // title: selectedAddLocation && selectedAddLocation.place,
          emotion: selectedEmoji && selectedEmoji.emotion,
          emotionName: selectedEmoji && selectedEmoji.emotionName,
          emotionIcon: selectedEmoji && selectedEmoji.icon,
          mediaUrls: res.mediaUrls[0],
          postTumbnailUrl: res.postTumbnailUrl || '',
          type: 'flix'
        };
         
       const   response = await uploadPost(mediaPostData, config);
       const uploadedFilesInfomedia = res.mediaUrls;
       const finalResponse = uploadedFilesInfomedia.map((fileInfo, index) => {
          const tags = tagsByCarouselItem[index] || [];
             
            return {
              ...fileInfo,
              tags: tags.map(tag => ({
                x: tag.xVal,
                y: tag.yVal,
                tagUserId: tag.tagUserId,
                fname: tag.fname,
                username: tag.username,
                profileImg: tag.profileImg,
                postedBy: loggedInUserId,
              })),
            };
          });
       
        
         await axios.post(`${BASEAPIURL}/post/updatetagpost`, {
          id:  response.post._id,
          mediaUrls: finalResponse,
        }, config);

        const taguserids = finalResponse[0].tags.map(item => item.tagUserId);
        
        async function createNotifications() {
          for (let ids of taguserids) {
            const data = {
              targetId: ids,
              userType: "user",
              targetEvent: "tag",
              targetType:  "flix",
              targetEventId: response.post._id,
              notificationType: "email",
            };
            
            try {
              const res = await createNotification(data, config);
              console.log("res", res);
             
            } catch (error) {
              console.error("Error creating notification for user", ids, error);
            }
          }
        }

        createNotifications();
        toast.success(res.message)


        setallDataPost(prev => [response.post, ...prev]);
        setShowCreateReelsPopup4(false)
        


      })
      .catch((err) => {
        setLoader(false);
        console.log("err in reel post", err)
        toast.error("failed to post reel")
      })
    setDescription('');
    setSelectedCategory("")
    setTagsByCarouselItem([])
  };

  return (
    <>
      <section>
        <Modal
          // show={true}
          show={showCreateReelsPopup4}
          onHide={() => setShowCreateReelsPopup4(false)} backdrop className='create-call' centered >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body >
            {

          openEmoji
                ?
                <>
                  <div className="row">
                    <p className="col-1" style={{ cursor: "pointer" }}
                      onClick={() => { setOpenEmoji(false) }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                    </p>
                    <p className="col-11 fs-6 fw-bold text-center mb-2">
                      Create Post
                    </p>
                  </div>

                  <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                </>
                : 
                <div className="row">
                      <p className="col-1" style={{ cursor: "pointer" }}
                        onClick={() => { handleCreateReelPopupPre4() }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                      </p>
                      <p className="col-11 fs-6 fw-bold text-center mb-2">
                        Create Flix
                      </p>
                    </div>
            }


            <section id='create-reels-1'>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "14px 0px"
                }}
              >
                <div className='d-flex align-items-center gap-2'
                  style={{ padding: "15px 0px 15px 20px" }}
                >
                  <ProfileImage
                    userId={loggedInUserId}
                    height="50"
                    width="50"
                    borderRadius="50%"
                  />
                  <h6>{loggedInUserName}</h6>
                </div>

                <span>
                  {feelingActivity && feelingActivity.activity && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "5px",
                        paddingBottom: "30px",
                      }}
                    >
                      <span>
                        <b>&nbsp; &minus; &nbsp;</b>
                      </span>
                      <span>{feelingActivity.emoji}</span>
                      <small>{feelingActivity.activity}</small>
                    </span>
                  )}
                </span>

                <span>
                  {feelingActivity && feelingActivity.name && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                        gap: "5px",
                        paddingBottom: "30px",
                      }}
                    >
                      <small>
                        <b> &minus; &nbsp; feeling</b>
                      </small>
                      <span>{feelingActivity.emoji}</span>
                      <small>{feelingActivity.name}</small>
                    </span>
                  )}
                </span>
              </div>


              <AddTags setfeelingActivity={setfeelingActivity} selectphotoAndVideo={selectphotoAndVideo} fileUrl={fileUrl} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} />

              <form>
                <div style={{ margin: '20px 0px 15px 0px', }}>
                  <section className='reel4section'>
                    <label htmlFor="textArea"></label>
                    <form className="desp-form">
                      <label htmlFor="textArea"></label>
                      <textarea
                        name="textArea"
                        id="textArea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        cols="39"
                        rows="5"
                        placeholder="Write Something"
                        className='text-dark'
                      ></textarea>
                      <button type="button" className="smiley" >
                        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpenEmoji(!openEmoji)} alt="" />
                      </button>

                      <span className='emoji-select-desp'>

                        {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
                      </span>
                    </form>
                  </section>
                </div>

                <CreateReel4details selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

              </form>

              <div style={{ cursor: 'pointer' }}>
                <p
                  style={{
                    height: '43px',
                    width: '425px',
                    backgroundColor: '#64A6F0',
                    textAlign: 'center',
                    color: 'white',
                    margin: '10px 0px 10px 0px',
                    fontSize: '20px',
                    paddingTop: '10px',
                    borderRadius: '25px',
                  }}
                  onClick={handleCreateReelPopupNext4}
                >{loader ? <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> posting...</span> : 'post'}</p>
              </div>

              {/* <div>
                <p style={{ textAlign: 'center', marginLeft: '20px', color: '#2676E1' }}>Advance Setting</p>
              </div> */}
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default CreateReels4;
