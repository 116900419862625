import React, { useContext, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { WhatsappShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { IoIosCloseCircle } from "react-icons/io";
// import download from 'downloadjs';
import axios from 'axios';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL, BASEURL } from '../../../../config';
import { ApiContext } from '../../UseContext/ApiCall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../Loader';


const PostSharePopup = ({ shareData, showPostSharePopup, setShowPostSharePopup}) => {

  const { config } = useContext(ApiContext);
  const [inputValue, setInputValue] = useState("");
  const [searchedUsersList, setSearchedUsersList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [loader, setLoader] = useState(false)
  const [searchloader, setsearchLoader] = useState(false)


  // To search users
  const handleSearch = async (e) => {
    setInputValue(e.target.value);
    try {
      
      if (e.target.value.length > 0) {
        setsearchLoader(true)
        await axios.post(`${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config)
          .then(res => {
            setSearchedUsersList(res.data.users);
            setsearchLoader(false)
          })
          .catch(err => {
            console.log(err);
          })
      };
    } catch (error) {
      setsearchLoader(false)
    }
  };

  // To handle selected users
  const handleSelectUser = (user) => {
    setSelectedUsersList(preVal => [...preVal, user]);
  };

  // To handle remove selected users
  const handleSelectedRemove = (user) => {
    const updatedUsersList = selectedUsersList.filter((item) => item.userId !== user.userId);

    setSelectedUsersList(updatedUsersList);
  };

  const handleSend = async () => {
    setLoader(true);
    // create chat connection || update chatId
    try {
      for (let i = 0; i < selectedUsersList.length; i++) {
        await axios.post(`${BASEAPIURL}/user/createemchatconnection`, { 
          userId: selectedUsersList[i].userId, 
          isGroupChat: false 
        }, config).then((res) => {
          const chatId = res.data.chatId;
          let msg = '';
  
          // Check the type of shared data
          if (shareData.type === 'text') {
            msg = shareData.description;
          }
  
          // Ensure mediaUrls is an array before proceeding
          if (Array.isArray(shareData.mediaUrls) && shareData.mediaUrls.length > 0) {
            shareData.mediaUrls.forEach(async (e) => {
              let type = 'text';
  
              if (shareData.type === 'location') {
                type = 'location';
                let msgAddress = { lat: shareData.lat, lng: shareData.lng, l_address: shareData.city };
                msg = JSON.stringify(msgAddress);
              } else {
                if (e.filetype === 'image') type = 'img_file';
                else if (e.filetype === 'video') type = 'video_file';
                else if (e.filetype === 'audio') type = 'audio_file';
                else if (e.filetype === 'location') type = 'location';
              }
  
              const data = {
                chatId: chatId,
                message: msg,
                fileName: "",
                type: type,
                fileUrl: e.url,
                isForwarded: false,
                thumbnail: shareData.postTumbnailUrl,
              };
  
              await axios.post(`${BASEAPIURL}/user/createemmessage`, data, config);
            });
          } else {
            // Handle cases where there are no mediaUrls
            console.log('No media to share');
          }
        });
      }
    } catch (error) {
      setLoader(false);
      setSelectedUsersList([]);
      setShowPostSharePopup(false);
      console.log('error in share ', error);
    } finally {
      setLoader(false);
      setSelectedUsersList([]);
      setShowPostSharePopup(false);
    }
  };
   

  return (
    <>
      <section className='postSharePopup'>
        <Modal show={showPostSharePopup} onHide={() => setShowPostSharePopup(false)} size='md' centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='putdsp'>
              <span className='putdsp-head'>Share</span>

              {/* Search Bar */}
              <form className='putdsp-form' onSubmit={handleSearch}>
                <div className='putdsp-form-img'>
                  <img src={`${BASE_URL_STATIC}/icon/search-2.svg`} alt="search" />
                </div>

                <label htmlFor="search">
                  <input type="search" value={inputValue} onChange={handleSearch} id='search' name='search' placeholder='Search...' autoComplete='off' />
                </label>
              </form>

              {/* Searched Users List */}
              <div className='putdsp-users'>
                {!searchloader ?
                  searchedUsersList
                  &&
                  searchedUsersList.map((user, index) => (
                    <div className='putdsp-user' key={index} onClick={() => handleSelectUser(user)}>
                      <div className='putdsp-user-img'>
                        {/* <img src={user.profileImg} alt="profile" /> */}
                        <img src={AwsFileUrl + user.profileImg.imageId} alt="profile" />
                      </div>

                      <div className='putdsp-user-header'>
                        <span className='putdsp-user-name'>{user.fname}</span>
                        <span className='putdsp-user-userName'>{user.username ? user.username : '@username'}</span>
                      </div>
                    </div>
                  ))
                  :
                  <div className='flex justify-content-center'>
                   <Loader/>
                  </div>
                }
              </div>

              {/* Selected Users List */}
              <div className='putdsp-selected'>
                {
                  selectedUsersList
                  &&
                  selectedUsersList.map((user, index) => (
                    <div className='putdsp-selected-img' key={index}>
                        {/* <img src={user.profileImg} alt="selected profile" /> */}
                        <img src={AwsFileUrl + user.profileImg.imageId} alt="profile" />

                        <span className='putdsp-selected-remove' onClick={() => handleSelectedRemove(user)}>
                          <IoIosCloseCircle />
                        </span>
                      </div>
                  ))
                }
              </div>

              <span className='putdsp-send' onClick={handleSend}>
                {loader ?
                  <>
                    <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />
                    Sending...
                  </>
                  : "send"
                }
              </span>

              {/* Social Media Icons */}
              <div className='ps-sml'>
                <WhatsappShareButton url={`${BASEURL}/showCopyLink/${shareData && shareData._id}`} image={`https://cdn-icons-png.flaticon.com/512/3135/3135715.png`}>
                  <Link to={`https://web.whatsapp.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/whatsapp-1.svg`} alt="icon" />
                  </Link>
                </WhatsappShareButton>

                <LinkedinShareButton url={`${BASEURL}/showCopyLink/${shareData && shareData._id}`}>
                  <Link to={`https://in.linkedin.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/linkedIn-1.svg`} alt="icon" />
                  </Link>
                </LinkedinShareButton>

                <TwitterShareButton url={`${BASEURL}/showCopyLink/${shareData && shareData._id}`}>
                  <Link to={`https://twitter.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/twitter-1.svg`} alt="icon" />
                  </Link>
                </TwitterShareButton>

                <FacebookShareButton url={`${BASEURL}/showCopyLink/${shareData && shareData._id}`}>
                  <Link to={`https://www.facebook.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/facebook-1.svg`} alt="icon" />
                  </Link>
                </FacebookShareButton>

                <Link to={`https://www.instagram.com/direct/inbox/`} className='ps-sml-div'>
                  <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/instagram-1.png`} alt="icon" />
                </Link>

                <CopyToClipboard text={`${BASEURL}/showCopyLink/${shareData && shareData._id}`} onCopy={() => toast.success('Copied')} >
                  <Link className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/copy.svg`} alt="icon" />
                  </Link>
                </ CopyToClipboard>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default PostSharePopup
