import React from 'react'
import Skeleton from 'react-loading-skeleton'

const OthersSekeleton = () => {
   return (
      <>
         {Array(6).fill(0).map((e, i) => (
      <div className='peopleBox'key={i}>

               <div className="imgBox status">
                  <Skeleton width={65} height={65} circle />
               </div>

               <div className="contentBox">
                  <div className="blockOne">
                     <div className="nameBox">
                        <Skeleton width={150} height={20}/>
                        <Skeleton width={300} height={15} />
                     </div>

                  

                     <div className="followBox">
                        <Skeleton width={120} height={30} />
                     </div>
                  </div>
            </div>
      </div>
         ))}
      </>

   )
}

export default OthersSekeleton