import React, { useContext, useEffect, useRef, useState } from "react";
import { ScApiContext } from "../useContext/softCornerContext";
import { getMessage } from "../../Emilo/UseContext/ScApiHelpers";
import { Hearts, ThreeDots } from "react-loader-spinner";
import { useInView } from "react-intersection-observer";
import ScChatHeader from "./ScChatHeader";
import ScDeleteHeader from "./ScDeleteHeader";
import ScChatFooter from "./ScChatFooter";
import ScMessageCard from "./ScMessageCard";
import { Spinner } from 'react-bootstrap';
const ScChatAreaRight = () => {

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [sendMessage, setSendMessage] = useState("");
  const [arrivalMsg, setArrivalMsg] = useState([]);
  const [typing, setTyping] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [popoverOpenMap, setPopoverOpenMap] = useState({});
  const [clickedReplyIndex, setClickedReplyIndex] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(null);
  const [displayPop, setDisplayPop] = useState(false);
  const [sock, setSock] = useState(null);
  const [replyMsg, setReplyMsg] = useState(null);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [isEdit , setIsEdit] = useState(false)
  const [setChatLoader] = useState(false)

  const { scConfig, chatId } = useContext(ScApiContext);

  const SCUserId = localStorage.getItem("SCuserId");

  // useEffect(() => {
  //   setSpinner(true);
  //   getMessage(chatId, scConfig).then((res) => {
  //     setArrivalMsg(res);
  //     setSpinner(false);
  //   }).catch(() => setSpinner(false));
  // }, [scConfig]);
 
  //msg load more
  const { ref : getscmsg_ref, inView  } = useInView({
      threshold :  0,
      triggerOnce : false, 

  })

  const [seenSpin, setSeenSpin] = useState(true);
  const [loadPage, setLoadPage] = useState(1);

  useEffect(() =>{
      let isFetching  = false; 


       if(!chatId){

         console.log("chat id is not  found : error")
         return 
           
       }
        

        if(inView && !isFetching &&  seenSpin){
          isFetching = true ;
          setSeenSpin(false); 

          getMessage(loadPage,  chatId, scConfig).then((res) =>{
             console.log("error messages........", res);
             setArrivalMsg((prev) => [...res.map((msg) => ({ ...msg, loading: true })), ...prev]);
             setChatLoader(false);
             setLoadPage((prevPage) => prevPage + 1);
            }).catch((err)=>{
              console.error(err);
            }).finally(()=>{
               isFetching = false;
            })
          
          }

           if(!inView){
            setSeenSpin(true)
           }

  }, [seenSpin, inView, loadPage, scConfig, chatId])

  const userScrolled = false;

  useEffect(() => {
    if (!userScrolled && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    const dates = arrivalMsg.reduce((uniqueDates, msg) => {
      const msgDate = new Date(msg.createdTime).toLocaleDateString();
      if (!uniqueDates.includes(msgDate)) {
        uniqueDates.push(msgDate);
      }
      return uniqueDates;
    }, []);
    setUniqueDates(dates);
  }, [arrivalMsg, userScrolled]);


  useEffect(() => {
    sock && sock.on("message_viewed_sc", (data) => {
      if (data.chatId === chatId) {
        setArrivalMsg((list) => list.map(msg => msg.messageId === data.messageId ? { ...msg, isSeen: data.isSeen } : msg));
      }
    })

  }, [chatId, sock])

  const togglePopover = (index) => {
    setPopoverOpenMap((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleReplyButtonClick = (index) => {
    setDisplayPop(true);
    togglePopover(index);
    setClickedReplyIndex(index);
  };

  const addMessages = (msg) => {
    const isSelected = selectedMessages.includes(msg.messageId);
    if (isSelected) {
      // If selected, remove it from the array
      setSelectedMessages((prev) => prev.filter((id) => id !== msg.messageId));
    } else {
      // If not selected, add it to the array
      setSelectedMessages((prev) => [...prev, msg.messageId]);
    }
  };

  const chatRef = useRef();

  function formatDate(dateString) {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const parts = dateString.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);

    const inputDate = new Date(year, month - 1, day);
    const today = new Date();
   

    // Check if input date is today
    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    }

    // Check if input date is yesterday
    today.setDate(today.getDate() - 1); 
    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      return "Yesterday";
    }

    return `${day} ${months[month - 1]} ${year}`;
  };

  return (
    <>
      <section ref={chatRef} className="vh-100 overflow-y-scroll scrollbar-hidden position-relative" style={{ backgroundImage: `url(https://demo3.sjainventures.com/e-milo/images/chat-bg.jpg)` }}>
 
        {/* Header */}
        <>
          {
            selectedMessages && selectedMessages.length >= 1
              ?
              <ScDeleteHeader setArrivalMsg={setArrivalMsg} setSpinner={setSpinner} setSelectedMessages={setSelectedMessages} selectedMessages={selectedMessages} />
              :
              <ScChatHeader onlineStatus={onlineStatus} setSpinner={setSpinner} setArrivalMsg={setArrivalMsg} />
          }
        </>

   
        {/* Box */}
        <div className="sc-chat-box h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-lg-5">

        <div className='text-center'  ref={getscmsg_ref} >
                      <Spinner animation="border" variant="danger" className='msg_spin' />
        </div>

          {
            !spinner
              ?
              <div>
                {uniqueDates.map((date, i) => (
                  <div key={i}>
                    <div className="chat-date-header"><hr />{formatDate(date)}<hr /></div>
                    {
                      arrivalMsg && arrivalMsg.filter(msg => new Date(msg.createdTime).toLocaleDateString() === date).map((msg, index) => (
                        <div key={index}>
                      
                          <ScMessageCard
                          sendMessage={sendMessage} 
                           setSendMessage={setSendMessage}
                          isEdit={isEdit}  setIsEdit={setIsEdit}
                            socket={sock}
                            setArrivalMsg={setArrivalMsg}
                            arrivalMsg={arrivalMsg}
                            msg={msg}
                            togglePopover={togglePopover}
                            displayPop={displayPop}
                            index={index}
                            clickedReplyIndex={clickedReplyIndex}
                            selectedMessages={selectedMessages}
                            handleReplyButtonClick={handleReplyButtonClick}
                            addMessages={addMessages}
                            setSelectedMessages={setSelectedMessages}
                            setDisplayPop={setDisplayPop}
                            scConfig={scConfig}
                            chatId={chatId}
                            setReplyMsg={setReplyMsg}
                            popoverOpenMap={popoverOpenMap}
                            setClickedReplyIndex={setClickedReplyIndex}
                            SCUserId={SCUserId}
                          />
                        </div>
                      ))
                    }
                  </div>
                ))}
              </div>
              :
              <Hearts height="100" width="100" color="#ED1E24" ariaLabel="hearts-loading" wrapperStyle={{}} wrapperClass="gallary-loader" visible={true} />
          }

          {/* Typing */}
          <div className="scChatBox"
            style={{
              display: `${typing ? "block" : "none"}`,
              margin: `${!typing ? "50px 0px" : "0px"}`,
            }}
          >
            <span className="text-light">Typing</span>
            <span className="typingLoader">
              <ThreeDots
                visible={true}
                height="20"
                width="20"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </span>
          </div>
        </div>

        {/* Footer */}
        <ScChatFooter isEdit={isEdit} setSelectedMessages={setSelectedMessages}  setIsEdit={setIsEdit}
          replyMsg={replyMsg}
          setReplyMsg={setReplyMsg}
          sock={sock}
          sendMessage={sendMessage} 
          setSendMessage={setSendMessage}
          setSock={setSock}
          setArrivalMsg={setArrivalMsg}
          setOnlineStatus={setOnlineStatus}
          setTyping={setTyping}
        />
      </section>
    </>
  );
};

export default ScChatAreaRight;
