import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { reportMessage } from '../../UseContext/ApiHelpers';
import toast from 'react-hot-toast';

const ReportMessage = ({ open, close, message, config, setReportMessage }) => {

    const [inputVal, setInputVal] = useState("");
    const [loader, setLoader] = useState(false);

    console.log("message" , message)

    const handleSubmit = (e) => {
        e.preventDefault();

       
        if (!inputVal.trim()) {
            toast.error("Please enter a message to report.");
            return;
        }

        setLoader(true);

        reportMessage(message.chatId, message.messageId, message.senderUserId, inputVal,(message.message || message.fileUrl), config)
            .then((res) => {
                toast.success(res.message);
                setLoader(false);
                close();
                setReportMessage(""); 
            })
            .catch((error) => {
                toast.error("Something went wrong. Please try again.");
                setReportMessage(""); 
                setLoader(false);
            });
    };

    return (
        <div>
            <Modal
                show={open}
                onHide={() => { close(); setReportMessage(""); }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h4 className='w-100 text-center fw-bold'>Report chat</h4>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className='d-flex flex-column justify-content-center align-items-center gap-2'>
                        <p className='col-12 text-truncate mx-auto'>
                            {message.message}
                        </p>

                        <input
                            type="text"
                            className='form-control'
                            onChange={(e) => setInputVal(e.target.value)}
                            placeholder='Enter message....'
                            value={inputVal}
                        />

                        <button type='submit' className='btn btn-primary' disabled={loader}>
                            {loader ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                "Report"
                            )}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ReportMessage;
