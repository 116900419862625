import React, { useEffect, useState, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";

const GallaryPopup = ({ open, close, data }) => {
   const [showMore, setShowMore] = useState(false);
   const videosRef = useRef(null);

   useEffect(() => {
      if (data.description && data.description.length > 50) {
         setShowMore(true);
      } else {
         setShowMore(false);
      }
   }, [data]);

   const toggleShowMore = () => {
      setShowMore(!showMore);
   };

   const playPauseVideo = () => {
      const video = videosRef.current;
      if (video.paused) {
         videosRef.current.play();
         console.log("play");
      } else {
         videosRef.current.pause();
         console.log("pause");
      }
   };

   console.log("dfata" , data);

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
   };

   return (
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call">
         <Modal.Header closeButton>&nbsp;</Modal.Header>
         <Modal.Body>
            <div className='gallary-post-body'>
               <Slider {...settings}>
                  {data && data.details && data.details.map((item, index) => (
                     <div key={index}>
                        {item.imageId !== "" && <img className='gallary-body-img' src="https://img.freepik.com/free-photo/people-sitting-bench-forest-night-generative-ai_260559-472.jpg?w=740&t=st=1706098485~exp=1706099085~hmac=1a851d3619e039dea9d84ff530908449444544af7957fb4a786053c44754bd70" alt="img" />}
                        {item.videoId !== "" && (
                           <video ref={videosRef} muted onClick={() => playPauseVideo()}>
                              <source src='/fear.mp4' />
                           </video>
                        )}
                     </div>
                  ))}
               </Slider>

               <div className="gallary-post">
                  <div className='gallary-post-name'>
                     <span>
                        {data &&
                           data.profileImg ? (
                              <img
                                 className="gallary-post-img"
                                 src={data.profileImg}
                                 alt="lb-rectangle-1"
                                 height={125}
                                 width={125}
                              />
                           ) : (
                              <img
                                 className="gallary-post-img"
                                 src={`${BASE_URL_STATIC}/dummy.png`}
                                 alt="dummy"
                                 height={125}
                                 width={125}
                              />
                           )
                        }
                     </span>
                     <span>
                        {data && data.fname}
                     </span>
                  </div>

                  <div className='gallary-post-desp'>
                     {showMore
                        ? data.description
                        : data.description && data.description.length > 60
                           ? data.description.substr(0, 60) + "..."
                           : data.description}
                     {data.description && data.description.length > 60 && (
                        <span onClick={toggleShowMore}>
                           {showMore ? " ...Less" : " ...More"}
                        </span>
                     )}
                  </div>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default GallaryPopup;
