import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessAddressPopup = () => {

  const { 
    showAddressPopup, setShowAddressPopup, 
    newAddress, setNewAddress,
    cities, setCities,
    stateCityData,
    createPageAddress,
  } = useContext(businessContext);


  // To handle state or city/town change
  const handleStateCityChange = (e) => {
    const selectedState = e.target.value;
    const stateData = stateCityData.find(item => item.state === selectedState);

    setNewAddress((prevState) => ({
      ...prevState,
      state: selectedState,
      city: '' // Reset city when state changes
    }));

    // Set cities for the selected state
    if (stateData) {
      setCities(stateData.cities);
    } else {
      setCities([]);
    }
  };

  return (
    <>
      <Modal show={showAddressPopup} onHide={() => setShowAddressPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Address</h4>

          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-3'>Address 1</span>

          <div className='ms-3'>
            {/* Name */}
            <span className='text-secondary'>Address Name</span>
            <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='name' placeholder='Address Name...' 
              value={newAddress.name} onChange={(e) => setNewAddress((prevState) => ({ ...prevState, name: e.target.value }))}
            />

            {/* Line 1 */}
            <span className='text-secondary'>Address Line 1</span>
            <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='list1' placeholder='Address Line 1...' 
              value={newAddress.line1} onChange={(e) => setNewAddress((prevState) => ({ ...prevState, line1: e.target.value }))}
            />

            {/* Line 2 */}
            <span className='text-secondary'>Address Line 2</span>
            <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='list2' placeholder='Address Line 2...' 
              value={newAddress.line2} onChange={(e) => setNewAddress((prevState) => ({ ...prevState, line2: e.target.value }))}
            />

            {/* State */}
            <span className='text-secondary'>State</span>
            <select className='form-select border-0 border-bottom shadow-none mb-4' name='state' 
              value={newAddress.state} onChange={handleStateCityChange} aria-label="Select state" 
            >
              <option value="" disabled>Select your state here...</option>

              {
                stateCityData.map((item, index) => (
                  <option key={index} value={item.state}>{item.state}</option>
                ))
              }
            </select>

            {/* City/Town */}
            <span className='text-secondary'>City/Town</span>
            <select className='form-select border-0 border-bottom shadow-none mb-4' name='city' 
              value={newAddress.city} onChange={(e) => setNewAddress((prevState) => ({ ...prevState, city: e.target.value }))} disabled={!newAddress.state} aria-label="Select city" 
            >
              <option value={newAddress.city ? newAddress.city : ''} disabled>{newAddress.city ? newAddress.city : 'Select your city/town here...'}</option>

              {
                cities.map((city, index) => (
                  <option key={index} value={city}>{city}</option>
                ))
              }
            </select>

            {/* Pincode */}
            <span className='text-secondary'>Pincode</span>
            <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='pincode' placeholder='Pincode...' 
              value={newAddress.pincode} onChange={(e) => setNewAddress((prevState) => ({ ...prevState, pincode: e.target.value }))}
            />
          </div>

          {/* Save */}
          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createPageAddress}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessAddressPopup
