import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { ApiContext } from "../../../UseContext/ApiCall";
// import { BASE_URL_STATIC } from "../../../../../config";
import { cp_ChangePassword } from "../../../UseContext/ApiHelpers";
import toast from "react-hot-toast";

const ChangePassword = () => {
  // const nav = useNavigate();
  const [show, setShow] = useState({
    currentPass: false,
    password: false,
    confirmpassword: false,
  });
  const [passwords, setPasswords] = useState({
    currentPass: "",
    password: "",
    confirmpassword: "",
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { setUserInfo, config } = useContext(ApiContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({ ...prevPasswords, [name]: value }));
  };

  const isValidPassword = () => {
    const { password } = passwords;
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password); 
    const isLengthValid = password.length >= 8;
    // const passwordsMatch = password === confirmpassword;
    return hasSymbol && hasNumber && isLengthValid;
  };

  // Criteria checks
  const hasAlphanumeric = /[a-zA-Z]/.test(passwords.password) && /\d/.test(passwords.password);
  const hasSpecialChar = /[!@#$%^&*]/.test(passwords.password);
  const hasMinLength = passwords.password.length >= 9;

  const handleNextClick = () => {
    setShowErrorMessage(true);
    if (isValidPassword()) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        password: passwords.password,
      }));
      cp_ChangePassword(passwords.currentPass, passwords.password, passwords.confirmpassword, config)
        .then((res) => {
          if (res.status === 1) {
            toast.success(res.message);
            setPasswords({
              currentPass: "",
              password: "",
              confirmpassword: "",
            });
          } else {
            toast.error(res.message);
          }
          setShowErrorMessage(false);
          // nav("/");
        })
        .catch((err) => {
          setShowErrorMessage(false);
          toast.error(err.message);
          // toast.error("Please enter your current password correctly to proceed with the password change.");
        });
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 1000);
      toast.error("Please create a stronger password with letters, numbers, special characters, and a minimum of 8 characters.");
    }
  };

  const togglePasswordVisibility = (fieldName) => {
    setShow((prevShow) => ({
      ...prevShow,
      [fieldName]: !prevShow[fieldName],
    }));
  };

  return (
    <div className="card">
    <div className="card-body">
    <div className="row mx-auto">
      <section className="col-12 col-lg-12 p-0">
        <Form className="w-75 mx-auto">
          {/* <div className="fs-1 mb-3">Change passwords</div> */}
          <div className="my-2 ">
            <label>New Password</label>
            <Form.Group className="d-flex justify-content-center col-12 px-0 position-relative" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="p-3"
                onChange={handleChange}
                value={passwords.password}
                name="password"
                type={show.password ? "text" : "password"}
                placeholder="New password"
              />
              <span className="position-absolute top-50 end-0 translate-middle me-2 me-md-3" onClick={() => togglePasswordVisibility("password")}>
                {show.password ? <IoEyeOutline size={18} /> : <FaRegEyeSlash size={18} />}
              </span>
            </Form.Group>
          </div>

          
          <small className="mb-3">
            {/* <ul className="cp-rb-ul">
              <li>✔️ Must include both alphanumeric characters.</li>
              <li>❌ Should contain at least one special character.</li>
              <li>The length must be greater than 8 characters for enhanced security.</li>
            </ul> */}
            <ul className="cp-rb-ul" style={{ listStyleType: 'none', padding: 0 }}>
                <li style={{ color: hasAlphanumeric ? 'green' : 'red' }}>
                    {hasAlphanumeric ? '✔️' : '❌'} Must include both letters and numbers
                </li>
                <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
                    {hasSpecialChar ? '✔️' : '❌'} Should contain at least one special character
                </li>
                <li style={{ color: hasMinLength ? 'green' : 'red' }}>
                    {hasMinLength ? '✔️' : '❌'} Must be greater than 8 characters
                </li>
            </ul>
          </small>

          <div className="my-2 ">
            <label>Confirm Password</label>
            <Form.Group className="d-flex justify-content-center col-12 px-0 position-relative" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="p-3"
                onChange={handleChange}
                name="confirmpassword"
                type={show.confirmpassword ? "text" : "password"}
                placeholder="Re-Type password"
                value={passwords.confirmpassword}
              />
              <span className="position-absolute top-50 end-0 translate-middle me-2 me-md-3" onClick={() => togglePasswordVisibility("confirmpassword")}>
                {show.confirmpassword ? <IoEyeOutline size={18} /> : <FaRegEyeSlash size={18} />}
              </span>
            </Form.Group>
          </div>

          <div className="my-2 ">
            <label>Please enter your current Password!</label>
            <Form.Group className="d-flex justify-content-center col-12 px-0 position-relative" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="p-3"
                onChange={handleChange}
                name="currentPass"
                type={show.currentPass ? "text" : "password"}
                placeholder="current password"
                value={passwords.currentPass}
              />
              <span className="position-absolute top-50 end-0 translate-middle me-2 me-md-3" onClick={() => togglePasswordVisibility("currentPass")}>
                {show.currentPass ? <IoEyeOutline size={18} /> : <FaRegEyeSlash size={18} />}
              </span>
            </Form.Group>
          </div>

          {/* {showErrorMessage && <span style={{ color: "red" }}></span>} */}

          <Button className="w-100 rounded-pill mx-auto mt-3 py-2" disabled={showErrorMessage} onClick={handleNextClick}>Save</Button>
        </Form>
      </section>
    </div>
    </div>
    </div>
  );
};

export default ChangePassword;
