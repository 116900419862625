import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getSoftCornerProfile } from '../../Emilo/UseContext/ScApiHelpers';
import { ScApiContext } from '../useContext/softCornerContext';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import ProfileCenterBlock from './ProfileCenterBlock';

const Profile = () => {

  // const [userProfile , setUserProfile] = useState("")

  const { scConfig } = useContext(ScApiContext)
  const { loggedInUserId } = useContext(ApiContext)

  useEffect(() => {
    getSoftCornerProfile(loggedInUserId, scConfig).then((res) => {
      // setUserProfile(res.getProfile)
    })
  }, [])

  let location = useLocation();

  const pathParts = location.pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];

  return (
    <>
      <section className='row mx-auto'>
        <div className={`${lastPart !== 'Profile' && 'd-none'} col-lg-6 d-lg-block px-0`}>
          <ProfileCenterBlock />
        </div>

        <div className={`${lastPart === 'Profile' && 'd-none'} col-lg-6 d-lg-block px-0 shadow`}>
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default Profile;
