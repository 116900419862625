import React, { useState, useContext, useEffect } from 'react'
import { ApiContext } from '../../../../UseContext/ApiCall';
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import CopyToClipboard from 'react-copy-to-clipboard';
import { BASEURL, BASE_URL_STATIC } from '../../../../../../config';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import EditPostPopup from './EditPost/EditPostPopup';
import VisibilityPostPopup from './Visibility/VisibilityPostPopup';
import ReportPost from '../../../Report/ReportPost';
import ServicePost from '../../../Service/ServicePost';

const ProfileVideoThreeDotPopup = ({ showProfileVideoThreeDotPopup, setShowProfileVideoThreeDotPopup, setShowProfileVideosPopup, profileVideosPopupData, setProfileVideosPopupData }) => {

  const [isNotificationSet, setIsNotificationSet] = useState(false);
  const [isIntrested, setIsIntrested] = useState(false);

  const [showEditPostPopup, setShowEditPostPopup] = useState(false);
  const [showVisibilityPostPopup, setShowVisibilityPostPopup] = useState(false);

  const { config, profileUserPost, post, profileUserPostCount, profileUserId, loggedInUserId,
    checkFavouriteUser, isFavouriteUser, handleCreateFavouriteUser,
    checkBothFollow, showAddCloseFriend, checkCloseFriend, handleCreateCloseFriend, isCloseFriend,
    putSnooze, checkSnoozeUser, isUserSnooze, 
    handleHidePost,
    checkBlocked, handleBlockUnblock, isBlocked,
    setShowReportPostPopup, setShowServicePopup,
    isUnfollow, checkUnfollow, handleUnfollow,
  } = useContext(ApiContext);

  // Delete Profile Post 
  const deletePost = (postId, config) => {
    axios.post("https://emilonode.sjain.io/post/deletepost", { postId: postId }, config)
    .then(res => {
      // console.log(res);
      profileUserPost(profileUserId);
      post(loggedInUserId);
      profileUserPostCount(profileUserId);
      setShowProfileVideoThreeDotPopup(false);
      setShowProfileVideosPopup(false);
    })
    .catch(err => {
      return null;
    });
  };

  useEffect(() => {
    if(profileVideosPopupData) {
      checkFavouriteUser(profileVideosPopupData.userId, config);
      checkBothFollow(profileVideosPopupData.userId, config);
      checkCloseFriend(profileVideosPopupData.userId, config);
      checkSnoozeUser(profileVideosPopupData.userId, config);
      checkBlocked(profileVideosPopupData.userId);
      checkUnfollow(profileVideosPopupData.userId);
    };
  }, [profileVideosPopupData]);

  return (
    <>
      {/* Edit Post Popup */}
      <EditPostPopup showEditPostPopup={showEditPostPopup} setShowEditPostPopup={setShowEditPostPopup} setShowProfilePostThreeDotPopup={setShowProfileVideoThreeDotPopup} postsPopupData={profileVideosPopupData} setPostsPopupData={setProfileVideosPopupData} />

      {/* Visibility Popup */}
      <VisibilityPostPopup showVisibilityPostPopup={showVisibilityPostPopup} setShowVisibilityPostPopup={setShowVisibilityPostPopup} />

      {/* Report Post */}
      <ReportPost postsPopupData={profileVideosPopupData} />

      {/* Report Service */}
      <ServicePost type='post' />



      {
        profileVideosPopupData
        &&
        profileVideosPopupData.userId === loggedInUserId
        ?
        // It will show to logged in user popup
        <Modal show={showProfileVideoThreeDotPopup} onHide={() => setShowProfileVideoThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            {/* Add to album */}
            <Link href="/" className="linkBox">
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon5.png`} alt="" />
              </div>
              <div className="textBox">
                Add to album
                <span>Add image to album.</span>
              </div>
            </Link>

            {/* Edit */}
            <Link href="/" className="linkBox" onClick={() => setShowEditPostPopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="" />
              </div>
              <div className="textBox">
                Edit post
                <span>Update this post.</span>
              </div>
            </Link>

            {/* Visibility */}
            <Link href="/" className="linkBox" onClick={() => setShowVisibilityPostPopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon4.png`} alt="" />
              </div>
              <div className="textBox">
                Visibility
                <span>Want to change audience, who can see this post.</span>
              </div>
            </Link>

            {/* Notification */}
            <Link href="/" className="linkBox">
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
              </div>
              <div className="textBox">
                Don't show notification for this post
                <span>Do not notify when someone likes or comment to this post.</span>
              </div>
            </Link>

            {/* Copy link */}
            <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileVideosPopupData.postId}`} onCopy={() => toast.success('copied')}>
              <div className='linkBox' style={{ cursor: 'pointer' }}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                </div>
                <div className="textBox">
                  Copy Link
                  <span>Copy this post link & share it with your friend's and more.</span>
                </div>
              </div>
            </CopyToClipboard>

            {/* Implant */}
            <Link href="/" className="linkBox">
              <div className="imgBox">
                {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                <span className='implant-img'>{'</>'}</span>
              </div>
              <div className="textBox">
                Implant
                <span>Want to show this post in your website.</span>
              </div>
            </Link>

            {/* Delete */}
            <Link href="/" className="linkBox last" onClick={() => deletePost(profileVideosPopupData.postId, config)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/chat-icon12.png`} alt="" />
              </div>
              
              <div className="textBox">
                Delete Post
                <span>Want to delete this post.</span>
              </div>
            </Link>
          </Modal.Body>
        </Modal>
        :
        profileVideosPopupData
        &&
        // It will show to other user popup
        <Modal show={showProfileVideoThreeDotPopup} onHide={() => setShowProfileVideoThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <h5 className="you_follow">Why am I seeing this post? <Link href="/">Know more</Link></h5>
            
            {profileVideosPopupData.userId !== loggedInUserId && <h6 className="you_follow">You follow <Link href="/" style={{ textTransform: 'capitalize' }}>‘{profileVideosPopupData.fname}’</Link></h6>}

            {/* Add to favourites */}
            <>
              {
                profileVideosPopupData.userId !== loggedInUserId
                &&
                <div className="linkBox" onClick={() => handleCreateFavouriteUser(profileVideosPopupData.userId, config)}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                  </div>
                  {
                    isFavouriteUser
                    ?
                    <div className="textBox">
                      Add ‘{profileVideosPopupData.fname}’ to favourites
                      <span>Add to your favourites if you want to see his/her post more.</span>
                    </div>
                    :
                    <div className="textBox">
                      Remove ‘{profileVideosPopupData.fname}’ from favourites
                      <span>Remove from your favourites. Want less post from his/her.</span>
                    </div>
                  }
                </div>
              }
            </>

            {/* Add to close friends */}
            <>
              {
                (profileVideosPopupData.userId !== loggedInUserId && !showAddCloseFriend)
                &&
                <div className="linkBox" onClick={() => handleCreateCloseFriend(profileVideosPopupData.userId, config)}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                  </div>
                  {
                    isCloseFriend
                    ?
                    <div className="textBox">
                      Add ‘{profileVideosPopupData.fname}’ to close friend
                      <span>Add to your close friend if you want to see his/her post more.</span>
                    </div>
                    :
                    <div className="textBox">
                      Remove ‘{profileVideosPopupData.fname}’ from close friend
                      <span>Remove from close friend.</span>
                    </div>
                  }
                </div>
              }
            </>

            {/* Set notification for user post */}
            <>
              {
                profileVideosPopupData.userId !== loggedInUserId
                &&
                <Link href="/" className="linkBox" onClick={() => setIsNotificationSet(!isNotificationSet)}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon9.png`} alt="" />
                  </div>
                  <div className="textBox">
                    {isNotificationSet ? 'Unset' : 'Set'} notification for ‘{profileVideosPopupData.fname}’ post
                    <span>Want to {isNotificationSet ? 'stop notifying' : 'get notify'}  when ‘{profileVideosPopupData.fname}’ post something</span>
                  </div>
                </Link>
              }
            </>

            {/* Interested */}
            <Link href="/" className="linkBox" onClick={() => setIsIntrested(!isIntrested)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" />
              </div>

              <div className="textBox">
                {isIntrested ? 'Not Intrested' : 'Intrested'  }
                <span>Want {isIntrested ? 'less' : 'more'  } post like this to see in your feed.</span>
              </div>
            </Link>

            {/* Copy Link */}
            {profileVideosPopupData.postId && <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileVideosPopupData.postId}`} onCopy={() => toast.success('copied')}>
              <div className={profileVideosPopupData.userId === loggedInUserId ? `linkBox last` : `linkBox`} style={{ cursor: 'pointer' }}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                </div>
                <div className="textBox">
                  Copy Link
                  <span>
                    Copy this post link & share it with your friend's and more.
                  </span>
                </div>
              </div>
            </CopyToClipboard>}

            {/* Implant */}
            <Link href="/" className="linkBox">
              <div className="imgBox">
                {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                <span className='implant-img'>{'</>'}</span>
              </div>
              <div className="textBox">
                Implant
                <span>Want to show this post in your website.</span>
              </div>
            </Link>

            {/* Put user snooze for 30 days */}
            <>
            {
              (profileVideosPopupData.userId !== loggedInUserId)
              &&
              <Link href="/" className="linkBox" onClick={() => putSnooze(profileVideosPopupData.userId, config)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                </div>
                <div className="textBox">
                  {
                    isUserSnooze
                    ?
                    `Put ‘${profileVideosPopupData.fname}’ snooze for 30 Days`
                    :
                    `Remove ‘${profileVideosPopupData.fname}’ from snooze`
                  }
                  <span>Stop seeing post for 30 days.</span>
                </div>
              </Link>
            }
            </>

            {/* Hide This Post */}
            <>
              {
                profileVideosPopupData.postId !== loggedInUserId
                &&
                <Link href="/" className="linkBox" onClick={() => handleHidePost(profileVideosPopupData.postId)}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon5.png`} alt="" />
                  </div>
                  <div className="textBox">
                    Hide This Post
                    <span>Don't want to see this post</span>
                  </div>
                </Link>
              }
            </>

            {/* Unfollow */}
            <Link href="/" className="linkBox" onClick={() => handleUnfollow(profileVideosPopupData.userId)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
              </div>
              <div className="textBox">
                {isUnfollow ? 'Follow' : 'Unfollow'} ‘{profileVideosPopupData.fname}’
                <span>Stop seeing his/her post.</span>
              </div>
            </Link>

            {/* Report */}
            <Link href="/" className="linkBox" onClick={() => setShowReportPostPopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon7.png`} alt="" />
              </div>
              <div className="textBox">
                Report Post
                <span>Do you think this post is wrong, please let us know.</span>
              </div>
            </Link>

            {/* Report Service */}
            <Link href="/" className="linkBox" onClick={() => setShowServicePopup(true)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="pen" />
              </div>
              <div className="textBox">
                Report Service
                <span>Any suggestion or improvement you think to do.</span>
              </div>
            </Link>

            {/* Block */}
            <Link href="/" className="linkBox last" onClick={() => handleBlockUnblock(profileVideosPopupData.userId)}>
              <div className="imgBox">
                <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
              </div>
              <div className="textBox">
                {isBlocked ? 'Unblock' : 'Block'} ‘{profileVideosPopupData.fname}’
                <span>Stop seeing or contact each other.</span>
              </div>
            </Link>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default ProfileVideoThreeDotPopup
