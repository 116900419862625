import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import { FaceMesh } from '@mediapipe/face_mesh';
import * as cam from '@mediapipe/camera_utils';

let glassesImage = '';
let maskImage = '';

const ArVrEffectsSection = ({
    glassesImageUrl,
    maskImageUrl,
    windowWidth,
    canvasRef,
    lipColor='transparent',
    skinColor='transparent',
    loading,
    setLoading
}) => {
    const videoRef = useRef(null);

    glassesImage = new Image(); // Load the image for glasses
    glassesImage.src = glassesImageUrl; // Replace with your glasses image URL

    maskImage = new Image(); // Load the image for mask
    maskImage.src = maskImageUrl; // Replace with your mask image URL

    useEffect(() => {
      setLoading(true);
      let faceMesh = null
      try {
        faceMesh = new FaceMesh({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });
        faceMesh.setOptions({
            maxNumFaces: 2,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });
      } catch(e) {
        console.log(e.message);
      }

        const onResults = (results) => {
            try {
                if (!canvasRef.current) return;
                if (!canvasRef.current || !videoRef.current) return;
                const canvasCtx = canvasRef.current.getContext('2d') || canvasRef.current.getContext('webgl');
                // const canvasCtx = canvasRef.current.getContext('2d');
                const canvasWidth = canvasRef.current.width ? canvasRef.current.width : 300;
                const canvasHeight = canvasRef.current.height ? canvasRef.current.height : 100;

                canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear previous frames
                canvasCtx.drawImage(results.image, 0, 0, canvasWidth, canvasHeight);


                if (results.multiFaceLandmarks) {
                    for (const landmarks of results.multiFaceLandmarks) {

                        // this is for the maks on the Mouth
                            // Get key landmarks for mask positioning
                                const leftCheek = landmarks[234];
                                const rightCheek = landmarks[454];
                                const chin = landmarks[152];
                                const forehead = landmarks[10];

                            // Calculate mask position and size
                                const maskWidth = Math.abs(rightCheek.x - leftCheek.x) * canvasRef.current.width * 1.3;
                                const maskHeight = Math.abs(forehead.y - chin.y) * canvasRef.current.height * 1.3;

                            // Center the mask image between the landmarks
                                const maskX = (leftCheek.x * canvasRef.current.width + rightCheek.x * canvasRef.current.width) / 2 - maskWidth / 2;
                                const maskY = (forehead.y * canvasRef.current.height + chin.y * canvasRef.current.height) / 2 - maskHeight / 3;

                            // Draw the mask image on the canvas
                            // console.log(`maskImageUrl: ${maskImageUrl}`);
                                canvasCtx.drawImage(maskImage, maskX, maskY, maskWidth, maskHeight);

                        // this is for the maks on the Mouth



                        // this is for the Skin colors
                            const faceOutline = [
                                10, 338, 297, 332, 284, 251, 389, 356, 454, 323,
                                361, 288, 397, 365, 379, 378, 400, 377, 152, 148,
                                176, 149, 150, 136, 172, 58, 132, 93, 234, 127,
                                162, 21, 54, 103, 67, 109
                            ];
                            // Apply skin color effect on cheeks
                            console.error(`skinColor: ${skinColor}`);
                            canvasCtx.fillStyle = skinColor;
                            canvasCtx.beginPath();
                            faceOutline.forEach((index, i) => {
                                if (landmarks[index]) {
                                    const { x, y } = landmarks[index];
                                    const scaledX = x * canvasRef.current.width;
                                    const scaledY = y * canvasRef.current.height;
                                    i === 0 ? canvasCtx.moveTo(scaledX, scaledY) : canvasCtx.lineTo(scaledX, scaledY);
                                }
                            });
                            canvasCtx.closePath();
                            canvasCtx.fill();
                            // for the skin color will be here
                        // this is for the Skin colors



                        // this is for the Lips colors
                            const upperLip = [0,267,269,270,409,291,375,321,405,314,17,84,181,91,146,61,185,40,39,37];
                            const lowerLip = [13,312,311,310,415,308,324,318,402,317,14,87,178,88,95,78,191,80,81,82];

                            const upperLipPoint = landmarks[upperLip[0]]; // Use the first point of the upper lip
                            const lowerLipPoint = landmarks[lowerLip[0]]; // Use the first point of the lower lip
                            const distance = Math.abs(upperLipPoint.y - lowerLipPoint.y); // Calculate the vertical distance
                               
                            if ( distance < 0.1) {
                                // Draw lipstick effect
                                canvasCtx.fillStyle = lipColor; // Lipstick color
                                canvasCtx.beginPath();

                                // Draw upper lip
                                upperLip.forEach((index, i) => {
                                    const landmark = landmarks[index];
                                    const x = landmark.x * canvasRef.current.width;
                                    const y = landmark.y * canvasRef.current.height;
                                    if (i === 0) {
                                        canvasCtx.moveTo(x, y);
                                    } else {
                                        canvasCtx.lineTo(x, y);
                                    }
                                });

                                // Draw lower lip
                                lowerLip.forEach((index) => {
                                    const landmark = landmarks[index];
                                    const x = landmark.x * canvasRef.current.width;
                                    const y = landmark.y * canvasRef.current.height;
                                    canvasCtx.lineTo(x, y);
                                });

                                canvasCtx.closePath();
                                canvasCtx.fill(); // Fill the lip area with the chosen color
                            }
                        // this is for the Lips colors

                        // this is for the goggles on the Eyes
                            // Get positions for glasses
                            const leftEye = landmarks[33];
                            const rightEye = landmarks[263];

                            const glassesWidth = Math.abs(rightEye.x - leftEye.x) * canvasRef.current.width * 1.8;
                            const glassesHeight = glassesWidth / 2.5; // Adjust aspect ratio as needed
                            const glassesX = (leftEye.x * canvasRef.current.width + rightEye.x * canvasRef.current.width) / 2 - glassesWidth / 2;
                            const glassesY = leftEye.y * canvasRef.current.height - glassesHeight / 2;

                            // Draw the glasses image on the canvas
                            canvasCtx.drawImage(glassesImage, glassesX, glassesY, glassesWidth, glassesHeight);
                        // this is for the goggles on the Eyes

                        const ShowLandMarks = localStorage.getItem('ShowLandMarks');

                        if (ShowLandMarks && Number(ShowLandMarks) === 1) {
                            for (const landmark of landmarks) {
                                const x = landmark.x * canvasRef.current.width;
                                const y = landmark.y * canvasRef.current.height;
                                canvasCtx.beginPath();
                                canvasCtx.arc(x, y, 2, 0, 2 * Math.PI);
                                canvasCtx.fillStyle = 'blue';
                                canvasCtx.fill();
                            }
                        }
                    }
                }
            } catch(e) {
                console.error(e);
            }

        }

        
        if (typeof videoRef.current !== 'undefined' && videoRef.current !== null && videoRef.current) {
            const camera = new cam.Camera(videoRef.current, {
                onFrame: async () => {
                    // Reduce the frequency of send calls to lower memory usage
                    // if (loading) return; // Skip processing if loading is true
                    setLoading(true); // Toggle loading to avoid repeated processing
                    try {
                        await faceMesh.send({ image: videoRef.current });
                    } catch (error) {
                        console.error("Error during segmentation:", error);
                    } finally {
                        requestAnimationFrame(() => {  }); // Allow next frame after processing
                    }
                    setLoading(false)
                },
                frameRate: 15, // Reduced frame rate
            });
            camera.start();
        }
        // Assign the onResults callback to process the output
        faceMesh.onResults(onResults);
    }, [lipColor, skinColor]);
    // lipColor, skinColor, maskImageUrl

    return (
        <>
            <div className="text-center LoaderARVR pt-5 pb-5" style={{ background: '#2f2ee9', display: loading ? '' : 'none' }}>
                <img src="/loader.webp" alt="" style={{ maxWidth: '100px' }} />
                <br />
                <span className='text-center'>
                    Loading...
                </span>
            </div>
            <div style={{ opacity: loading ? 0 : 1 }}>
                <video
                    ref={videoRef}
                    style={{ display: 'none', width: `${windowWidth}px` }}
                    autoPlay
                    muted
                    className="videoCreateARVR"
                />
                <canvas ref={canvasRef} className="canvasCreateARVR" />
                <div className='clearBoth'></div>
            </div>
            <div className='clearBoth'></div>
        </>
    );
};

ArVrEffectsSection.propTypes = {
    glassesImageUrl: PropTypes.any.isRequired,
    windowWidth: PropTypes.any.isRequired,
}

export default ArVrEffectsSection;
