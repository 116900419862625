import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';

const OnboardingUser = () => {

  const { loggedInUserName } = useContext(ApiContext);

  const [isChecked , setIsChecked] = useState(false);

  return (
    <>
      <section className='onboardingUser row'>
        {/* Left */}
        <div className='d-none d-lg-block col-7'>
          <img src="/images/onboarding-sc.svg" alt="" />
        </div>

        {/* Right */}
        <div className='onboardingUser-area col-12 col-md-8 col-lg-5 mx-auto mt-5'>
          <div className='col-12 col-md-10 col-lg-7 mx-auto'>
          {/* Emilo Logo */}
          <div className='text-center'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='mt-5 d-flex flex-column align-items-center'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <br />
            <h4 className='fw-bold'>Soft Corner</h4>
          </div>

          {/* Data */}
          <div className='mt-5'>
            <p className='text-center my-3 my-lg-0' style={{fontSize : "13px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. <span className='onboardingUser-area-data-learnMore'>Learn more</span></p>

            <div className='onboardingUser-area-data-user'>
              <img src={`${BASE_URL_STATIC}/user-icon3.jpg`} alt="user-icon3" height={50} width={50} />

              <div className='onboardingUser-area-data-userName'>
                <h4>{loggedInUserName}</h4>
                <p>{loggedInUserName}</p>
              </div>

              <h2>&#129174;</h2>
            </div>

            <p className='onboardingUser-area-data-uploadImage p-2'>In order to Get started Please upload your live Image & Contact info</p>

            <div className='onboardingUser-area-data-termsAndConditions'>
              <form className='d-flex justify-content-center align-items-center'>
                <input type="checkbox" id='termsAndConditions' name='termsAndConditions' onChange={(e) => setIsChecked(e.target.checked)}/>
                <label htmlFor="termsAndConditions">I agree to E-Milo’s soft corner <span className='onboardingUser-area-data-tac'>Terms And conditions</span></label>
              </form>
            </div>
          </div>

          {/* Button */}
          <div className='row text-center'>
            <Link to={`/SoftCorner/OnboardingMobile`}>
              <button disabled={!isChecked} type="button" className='onboardingUser-area-btn col-8'>Start</button>
            </Link>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingUser;

