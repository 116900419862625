import React from 'react';
import { Link } from 'react-router-dom';
import GoogleLoginButton from './GoogleLogin';
import { BASE_URL_STATIC } from '../../../config';

const Continue = () => {


  return (
    <>
      <section className='row vh-100 mx-auto'>
        {/* Left */}
        <div className='d-none d-lg-block col-lg-6 p-0 h-100 overflow-y-hidden' >
          <div className='continue-left-image-1'>
            <img src={`${BASE_URL_STATIC}/continue-1.png`} alt="continue-1" />
          </div>

          <div className='continue-left-image-2'>
            <img src={`${BASE_URL_STATIC}/continue-2.png`} alt="continue-2" />
          </div>
        </div>

        {/* Right */}
        <section className='col-12 col-lg-6 p-0'>
          {/* Logo */}
          <div className="text-center">
            <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
          </div>

          {/* Continue With Options */}
          <div className='row mx-auto d-flex flex-column align-items-center ms-1'>
            <div className='continue-right-select col-auto'>
              <GoogleLoginButton />
            </div>

            <div className='continue-right-select col-auto' >
              <img className='continue-right-select-img ms-2 me-3' src={`${BASE_URL_STATIC}/icon/apple.svg`} alt="google" />
              <span className='continue-right-select-span'>Continue with Apple ID</span>
            </div>
          </div>

          {/* Or */}
          <div className='d-flex justify-content-center' >
            <div className='d-flex flex-column align-items-center w-100 px-0'>
              <div className='continue-right-or w-100'>
                <span className='continue-right-or-dash'></span>
                <span>Or</span>
                <span className='continue-right-or-dash'></span>
              </div>

              <Link to={`/signup/nameRegisteration`} className='btn btn-primary rounded-pill w-50 py-2 py-md-2 fs-6 my-4'>Create Account</Link>

              <div className='continue_text w-100'>
                <small className='continue-right-tac'>By tapping Continue, Create account, I agree to Company's
                  <Link to={`/termsAndConditions`}>Terms of Service</Link>, {' '}
                  <Link to={`/termsAndConditions`}>Payments Terms of Service</Link>, {' '}
                  <Link to={`/termsAndConditions`}>Privacy Policy</Link> and {' '}
                  <Link to={`/termsAndConditions`}>Non-discrimination Policy</Link>.
                </small>
              </div>

              <div className='continue-right-language'>
                <img src={`${BASE_URL_STATIC}/icon/language.svg`} alt="language" />
                <span>English | हिंदी</span>
              </div>

              <div className='mt-4'>Already have an account? <Link to={`/login`}>Log in</Link></div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Continue
