import React, { useContext, useEffect } from 'react';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";
import { businessContext } from '../UseContext/BusinessContext';


const BusinessHomePosts = () => {
  
  const { 
    paramsPageId,
    pageDetails,
    checkIsItMyPage, 
    // isItMyPage,
    getPages,
  } = useContext(businessContext);

  // console.log('isItMyPage : ', isItMyPage);

  const posts = [
    'https://newspaperads.ads2publish.com/wp-content/uploads/2017/08/new-pantene-thicker-stronger-hair-in-14-days-ad-times-of-india-delhi-13-08-2017.jpg',
    'https://images-static.nykaa.com/media/catalog/product/6/2/62da27f8901526010028_2.jpg',
    // 'https://i.pinimg.com/736x/ea/9d/46/ea9d46323473d7713eb19a495fe956c4.jpg',
  ];

  const ads = [
    {
      id: 1,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 2,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 3,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 1,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 2,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 3,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
  ];

  const adsSetting = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  const messages = [
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      message: 'Good Morning',
      time: '12:17 AM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      message: 'How are you doing today?',
      time: '1:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      message: 'Just finished my work.',
      time: '3:45 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      message: 'Looking forward to our meeting.',
      time: '5:15 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      message: 'Goodnight!',
      time: '10:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      message: 'Good Morning',
      time: '12:17 AM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      message: 'How are you doing today?',
      time: '1:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      message: 'Just finished my work.',
      time: '3:45 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      message: 'Looking forward to our meeting.',
      time: '5:15 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      message: 'Goodnight!',
      time: '10:30 PM'
    },
  ];

  const events = [
    {
      image: 'https://i.ytimg.com/vi/5DQckg0L0sk/maxresdefault.jpg',
      time: '25 Jan - 28 Jan',
      name: 'Loreal',
      address: 'Loreal, Sector-24, Atal Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://images-eu.ssl-images-amazon.com/images/G/31/img21/Beauty/MayART/BrandDay/AMZMASLK0520283_1_N_3125X1563_8fbbce25-d55f-4742-a876-40ac133615ac.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Lakme',
      address: 'Lakme, Lakhe Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/a5RiQXOnqAM/maxresdefault.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Sugar Cosmetics',
      address: 'Loreal, Sector-22, Gautam Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/5DQckg0L0sk/maxresdefault.jpg',
      time: '25 Jan - 28 Jan',
      name: 'Loreal',
      address: 'Loreal, Sector-24, Atal Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://images-eu.ssl-images-amazon.com/images/G/31/img21/Beauty/MayART/BrandDay/AMZMASLK0520283_1_N_3125X1563_8fbbce25-d55f-4742-a876-40ac133615ac.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Lakme',
      address: 'Lakme, Lakhe Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/a5RiQXOnqAM/maxresdefault.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Sugar Cosmetics',
      address: 'Loreal, Sector-22, Gautam Nagar, Raipur, Chhattisgarh',
    },
  ];
  
  const photos = [
    'https://i.pinimg.com/736x/ea/9d/46/ea9d46323473d7713eb19a495fe956c4.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv8fH0a8cejEZmiETT_nV5F-LXrsw-Ksqc7A&s',
    'https://down-id.img.susercontent.com/file/efc4f6f503f0765d9c0a61622ce46927',
    'https://i.pinimg.com/550x/b6/19/ff/b619fff8fe37807fe033baa02374e033.jpg',
    'https://static.theprint.in/wp-content/uploads/2022/01/worlds-leading-hair-removal-brand-nair-signs-on-bollywood-actress-mrunal-thakur-as-brand-ambassador.jpg',
    'https://live.staticflickr.com/3002/5750478430_746fe38236_b.jpg',
    'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
    'https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/stars-cosmetics-the-choice-og-bollywood-leading-makeup-artists-ad-times-of-india-pune-20-10-2017.jpg',
    'https://www.adgully.com/img/800/202212/khatija-lokhandwala-2022-12-07t104155-044.png.jpg',
    'https://i.pinimg.com/736x/ea/9d/46/ea9d46323473d7713eb19a495fe956c4.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv8fH0a8cejEZmiETT_nV5F-LXrsw-Ksqc7A&s',
    'https://down-id.img.susercontent.com/file/efc4f6f503f0765d9c0a61622ce46927',
    'https://i.pinimg.com/550x/b6/19/ff/b619fff8fe37807fe033baa02374e033.jpg',
    'https://static.theprint.in/wp-content/uploads/2022/01/worlds-leading-hair-removal-brand-nair-signs-on-bollywood-actress-mrunal-thakur-as-brand-ambassador.jpg',
    'https://live.staticflickr.com/3002/5750478430_746fe38236_b.jpg',
    'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
    'https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/stars-cosmetics-the-choice-og-bollywood-leading-makeup-artists-ad-times-of-india-pune-20-10-2017.jpg',
    'https://www.adgully.com/img/800/202212/khatija-lokhandwala-2022-12-07t104155-044.png.jpg',
  ];

  const followers = [
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      username: 'siddharth06@',
      followedBy: 'Avinash & 5 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      username: 'aarav_sharma@',
      followedBy: 'Priya & 2 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      username: 'nishapatel12@',
      followedBy: 'Rahul & 3 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      username: 'rahulverma89@',
      followedBy: 'Siddharth & 1 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      username: 'priya_mehta@',
      followedBy: 'Nisha & 4 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      username: 'siddharth06@',
      followedBy: 'Avinash & 5 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      username: 'aarav_sharma@',
      followedBy: 'Priya & 2 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      username: 'nishapatel12@',
      followedBy: 'Rahul & 3 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      username: 'rahulverma89@',
      followedBy: 'Siddharth & 1 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      username: 'priya_mehta@',
      followedBy: 'Nisha & 4 More'
    },
  ];


  useEffect(() => {
    checkIsItMyPage(paramsPageId);
    getPages(paramsPageId);
  }, [paramsPageId]);


  return (
    <>
      <section className='business-home-main'>
        {/* Main > Left */}
        <div className='col px-0'>
          {/* Intresting Facts */}
          <div className='rounded-3'>
            {
              posts.map((post, index) => (
                <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }} key={index}>
                  <div className='d-flex align-items-center'>
                    <img src={pageDetails && AwsFileUrl+pageDetails.imageId} className='rounded-circle' style={{ height: '43px', width: '43px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>Intresting Facts</h6>
                      <span className='text-primary'>Sponsored</span>
                    </div>

                    <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
                  </div>

                  <p className='my-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, corporis! Lorem ipsum dolor sit amet. <span className='text-primary'>#GoodHairDaysAhed</span></p>

                  {/* <img src={post} className='w-100 object-fit-contain bg-secondary-subtle' style={{ maxHeight: '60vh' }} alt="post" /> */}
                  <img src={post} className='w-100 object-fit-contain' alt="post" />

                  <div className='d-flex justify-content-between align-items-center my-3'>
                    <div>
                      <h6 className='fw-semibold mb-0'>www.example.com</h6>
                      <span>Lorem ipsum dolor sit amet.</span>
                    </div>

                    <button type="button" className='btn btn-primary'>Visit Us</button>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* View, Like, Comment and Share */}
                  <div className='d-flex align-items-center justify-content-between'>
                    {/* View */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} className='me-2' alt="view" />
                      <span>27k</span>
                    </div>

                    {/* Like  */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/like-2.svg`} className='me-2' alt="view" />
                      <span>4k</span>
                    </div>

                    {/* Comment */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} className='me-2' alt="view" />
                      <span>1k</span>
                    </div>

                    {/* Share */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} className='me-2' alt="view" />
                      <span>200</span>
                    </div>

                    {/* Boost Ad */}
                    <button type="button" className='btn btn-light shadow-sm'>
                      <img src={`/images/business/icons/boost-1-blue.svg`} className='shadow-sm me-2' style={{ height: '25px' }} alt="boost" />
                      <span className='text-primary'>Boost Ad</span>
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        {/* Main > Right */}
        <div className='col px-0'>
          {/* Ads */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between mb-3'>
              <div>
                <h6 className='fw-semibold mb-0'>Ads</h6>
                <span className='text-secondary'>Lorem ipsum dolor sit amet.</span>
              </div>

              <span className='text-primary'>See All</span>
            </div>

            {/* <div className='d-flex overflow-x-scroll scrollbar-hidden'> */}
            <Slider {...adsSetting} className='business-home-ads-slick'>
              {
                ads.map((ad, index) => (
                  <div className='p-3 bg-white rounded-3 shadow-sm' style={{ width: '300px' }} key={index}>
                    <div className='d-flex align-items-center'>
                      <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                      <div className='ms-3'>
                        <h6 className='fw-semibold mb-0'>Intresting Facts</h6>
                        <span className='text-primary'>Sponsored</span>
                      </div>
                    </div>

                    <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.</span>

                    <img src="https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg" className='rounded-3 my-2' alt="ads" />

                    <span className='fw-semibold'>www.example.com</span>
                    <span>Lorem ipsum dolor sit amet.</span>

                    <button type="button" className='btn btn-primary btn-sm w-100'>Visit Us</button>
                  </div>
                )) 
              }
            </Slider>
            {/* </div> */}
          </div>

          {/* Messages */}
          <div className='p-3 mb-3 rounded-3 bg-white shadow-sm'>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>Messages</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '250px' }}>
              {
                messages.map((message, index) => (
                  <div className='d-flex align-items-center mb-3 cursor-pointer' key={index}>
                    <img src={message.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{message.name}</h6>
                      <span className='text-secondary'>{message.message}</span>
                    </div>

                    <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
                  </div>
                ))
              }
            </div>
          </div>

          {/* Events */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between'>
              <h6 className='fw-semibold mb-0'>Events</h6>
              <span className='text-primary'>See All</span>
            </div>

            <span className='mb-3 text-secondary'>Lorem ipsum dolor sit amet.</span>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '300px' }}>
              {
                events.map((event, index) => (
                  <div className='d-flex mb-3' key={index}>
                    <img src={event.image} className='rounded-3 object-fit-cover' style={{ width: '100px' }} alt="profile" />

                    <div className='ms-3'>
                      <p className='text-secondary'>{event.time}</p>
                      <h6 className='fw-semibold mb-0'>{event.name}</h6>
                      <p className='text-secondary'>{event.address}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* Photos */}
          <div className='p-3 mb-3 rounded-3 bg-white shadow-sm'>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>Photos</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='business-home-photos overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '312px' }}>
              {
                photos.map((photo, index) => (
                  <div className='border px-0 position-relative shadow-sm rounded-3' style={{ height: '150px' }} key={index}>
                    <img src={photo} className='rounded-3 object-fit-cover h-100 w-100'  alt="profile" />

                    <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} className='position-absolute top-0 end-0 p-1 rounded-1 mt-2 me-2' style={{ height: '17px', backgroundColor: '#c2c3c3' }} alt="post" />
                  </div>
                ))
              }
            </div>
          </div>

          {/* New Followers */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>New Followers</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '300px' }}>
              {
                followers.map((follower, index)  => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <img src={follower.profile} className='rounded-circle border border-primary p-1' style={{ height: '60px', width: '60px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{follower.name}</h6>
                      <p className='text-secondary'>{follower.username}</p>
                      <p className='text-secondary'>Followed by {follower.followedBy}</p>
                    </div>

                    <button type="button" className='btn btn-primary ms-3'>Follow</button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BusinessHomePosts
