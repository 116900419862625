import React, { useState } from 'react';
import { useEffect } from 'react';

const PostTime = ({ date }) => {

  const [time, setTime] = useState(new Date(date));

  let postedTime, currentTime, getTime, seconds, minutes, hours, days, months, years, showTime;

  useEffect(() => {
    setTime(new Date(date));
  }, [date]);

  postedTime = new Date(time).getTime();
  currentTime = new Date().getTime();

  getTime = currentTime - postedTime;

  seconds = Math.floor(getTime / 1000);
  minutes = Math.floor(seconds / 60);
  hours = Math.floor(minutes / 60);
  days = Math.floor(hours / 24);
  months = Math.floor(days / 30);
  years = Math.floor(months / 12);

  if(years > 0) {
    showTime = years + (years === 1 ? "y" : "y");
  } else if (months > 0) {
    showTime = months + (months === 1 ? "M" : "M");
  } else if (days > 0) {
    showTime = days + (days === 1 ? "d" : "d");
  } else if (hours > 0) {
    showTime = hours + (hours === 1 ? "h" : "h");
  } else if (minutes > 0) {
    showTime = minutes + (minutes === 1 ? "m" : "m");
  } else {
    showTime = seconds + (seconds === 1 ? "s" : "s");
  };

  return (
    <>
      { time ? `${showTime}` : "" }
    </>
  );
};

export default PostTime;