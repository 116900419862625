import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGithub, FaSnapchat, } from "react-icons/fa";
import { getSocialDetails, socialMediaDetail, } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { BASE_URL_STATIC } from "../../../../../config";

const OtherSocialMediaPopup = ({ open, close }) => {

  const [input, setInput] = useState({
    plateform: "",
    sociallink: "",
    userhandle: "",
  });

  const socialsocialMedia = [
    {
      name: "instagram",
      icon: <FaInstagram />,
    },
    {
      name: "facebook",
      icon: <FaFacebook />,
    },
    {
      name: "twitter",
      icon: <FaTwitter />,
    },
    {
      name: "linkedin",
      icon: <FaLinkedin />,
    },
    {
      name: "github",
      icon: <FaGithub />,
    },
    {
      name: "snapchat",
      icon: <FaSnapchat />,
    },
  ];

  const { setSocial } = useContext(profileContext);

  async function hanldeSubmit(e) {
    e.preventDefault();

    close();

    console.log("input", input);
    await socialMediaDetail(input).then(() =>
      getSocialDetails().then((res) => setSocial(res))
    );
  };

  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section>
        <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={hanldeSubmit}>
              <h5 >Other Social Media</h5>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    marginRight: "5px",
                    background: "#f5f5f5",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "120px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/platform.svg`} alt="degree" />

                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Platform
                  </p>
                </span>

                <div className="col-lg-11">
                  <select
                    className="form-control"
                    required
                    onChange={handleChange}
                    name="plateform"
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #a6a6a659",
                      marginTop: "12px",
                    }}
                  >
                    <option
                      className=""
                      style={{ textAlign: "center", color: "#616161" }}
                    >
                      --select--
                    </option>
                    {socialsocialMedia.map((e, i) => (
                      <option key={i} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>

                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "100px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/degree.svg`} alt="degree" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    user id
                  </p>
                </span>

                <input
                  type="text"
                  onChange={handleChange}
                  name="userhandle"
                  style={{
                    border: "none",
                    borderBottom: "2px solid #d4d4d4",
                    width: "85%",
                    margin: "auto",
                    marginLeft: "10px",
                    padding: "5px 10px",
                  }}
                />

                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "100px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/link.svg`} alt="" />

                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Link
                  </p>
                </span>

                <input
                  type="text"
                  onChange={handleChange}
                  name="sociallink"
                  style={{
                    border: "none",
                    borderBottom: "2px solid #d4d4d4",
                    width: "85%",
                    margin: "auto",
                    marginLeft: "10px",
                    padding: "5px 10px",
                  }}
                />
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-dark mt-4 col-lg-4" >Save</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default OtherSocialMediaPopup;
