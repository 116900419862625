import React from 'react';
import { BASE_URL_STATIC } from '../../../../../config';
import SettingInputUser from './SettingInputUser';


const SuggestionSetting = () => {
  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='suggestionToggle1'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Suggestions</li>
          </ol>
        </nav>

        <div className='accordion accordion-flush'>
          {/* View & Manage Suggestions */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#viewManage">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="suggestion" />
              <h5 className='fw-semibold ms-3'>View & Manage Suggestions</h5>  
            </div>

            <div id='viewManage' className="accordion-collapse collapse show accordion accordion-flush mt-3 ms-3" data-bs-parent="#suggestionToggle1">
              {/* Follow Suggestions */}
              <div className='mb-3'>
                <div className='accordion-level-2 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#followSuggestion">
                  <img src={`${BASE_URL_STATIC}/icon/post.svg`} alt="post" />
                  <h5 className='fw-semibold ms-3'>Follow Suggestions</h5>
                </div>

                <div id='followSuggestion' className="accordion-collapse collapse show accordion accordion-flush mt-3 ms-3">
                  <span>You are being shown suggestions of people whom you may know and can follow based on below  information. You can manage whether you want to allow your below information to show Suggestions accordingly to you.</span>

                  <span className='my-2'>(Note: Multiple select option)</span>

                  <div className='ms-3'>
                    <p className='mb-1'>Followers of your followers</p>
                    <p className='mb-1'>Searches</p>
                    <p className='mb-1'>Profile Info</p>
                    <p className='mb-1'>Device Info</p>
                  </div>
                </div>
              </div>

              {/* Content Suggestions */}
              <div>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contentSuggestion">
                  <img src={`${BASE_URL_STATIC}/icon/post.svg`} alt="post" />
                  <h5 className='fw-semibold ms-3'>Content Suggestions</h5>
                </div>

                <div id='contentSuggestion' className="accordion-collapse collapse accordion accordion-flush mt-3 ms-3">
                  <span>You are being shown Suggestions related to below categories, you can manage whether you want to see more, less or hide Suggestions of similar categories in future. These actions will also effect suggesting of trending and popular contents.</span>

                  <hr className='hr bg-secondary' />

                  {/* Categories based on your profile info. */}
                  <div className='mt-3 ms-3'>
                    <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#basedOnProfile">Categories based on your profile info.</h5>

                    <div id='basedOnProfile' className='accordion-collapse collapse show mt-3 mx-0'>
                      <span className='mb-3'>We show Suggestions related to categories based on some of your profile info. You can manage whether you want to allow your below profile info to show Suggestions accordingly to you.</span>

                      {/* Jobs */}
                      <div className='mb-1'>
                        <span className='fw-semibold'>Jobs</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='categoriesBasedOn-jobs' value={true} checked={true} />
                        </div>
                      </div>

                      {/* Education */}
                      <div className='mb-1'>
                        <span className='fw-semibold'>Education</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='categoriesBasedOn-education' value={true} checked={true} />
                        </div>
                      </div>

                      {/* Hobbies */}
                      <div className='mb-1'>
                        <span className='fw-semibold'>Hobbies</span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='categoriesBasedOn-hobbies' value={true} checked={true} />
                        </div>
                      </div>

                      {/* Likes  */}
                      <div className='mb-1'>
                        <span className='fw-semibold'>Likes </span>

                        <div className="form-check form-switch m-0">
                          <input type="checkbox" className="form-check-input" role="switch" aria-checked name='categoriesBasedOn-likes ' value={true} checked={true} />
                        </div>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>

                  {/* Categories based on your activities, interest or preferences.	 */}
                  <div className='mt-3 ms-3'>
                    <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#basedOnActivities">Categories based on your activities, interest or preferences.</h5>

                    <div id='basedOnActivities' className='accordion-collapse collapse show mt-3 mx-0'>
                      <span className='mb-3'>We show Suggestions related to categories based on your activities, interest or preferences. You can manage whether you want to allow below categories to show Suggestions accordingly to you.</span>

                      {/* Motivational */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Motivational</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Spiritual  */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Spiritual </span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Educational */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Educational</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Business */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Business</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Political */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Political</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Entertainment */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Entertainment</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Social Activities  */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Social Activities </span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      {/* Science */}
                      <div className='d-flex justify-content-between mb-3'>
                        <span className='fw-semibold me-5'>Science</span>

                        <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                      </div>

                      <hr className='hr bg-secondary' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Hidden Categories */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#hiddenCategories">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="suggestion" />
              <h5 className='fw-semibold ms-3'>Hidden Categories</h5>  
            </div>

            <div id='hiddenCategories' className="accordion-collapse collapse accordion accordion-flush mt-3 ms-3" data-bs-parent="#suggestionToggle1">
              {/* Input */}
              <>
                <SettingInputUser 
                  settingNav='privacy' 
                  settingKey='suggestionHiddenCategories'
                  settingType='hiddenCategories'
                  header='Below are the Categories you have hidden'
                  message='You can select name of people whom you don’t want to show your post' 
                />
              </>
            </div>
          </div>
        </div>






        {/* <h4 className='fw-semibold mb-3'>Follow Suggestions</h4>
        <h4 className='fw-semibold mb-3'>Content Suggestions</h4>
        <h4 className='fw-semibold mb-3'>Interested</h4>
        <h4 className='fw-semibold mb-3'>Not Interested</h4> */}
      </section>
    </>
  )
}

export default SuggestionSetting
