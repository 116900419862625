import React, { useContext, useEffect, useState } from 'react'
import { BiHome } from 'react-icons/bi'
import Modal from 'react-bootstrap/Modal';

import { createAddress, getAddressDetails, selectCities, selectCountry, selectStates } from '../../../UseContext/ApiHelpers';
import { profileContext } from '../../../UseContext/ProfileContext';
import { BASE_URL_STATIC } from '../../../../../config';

const PlacesLivedPopup = ({ open, close }) => {

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [AddressMessage, setAddressMessage] = useState(false);
  const { setAddress } = useContext(profileContext);

  const [loader , setLoader] = useState(false)
  // const [getLoader , setgetLoader] = useState(false)

  const [address, setUserAddress] = useState({ addressType: "", country: '', state: '', city: '', aboutPlace: '' });

  useEffect(() => {

    setState([]);
    setCity([]);

  }, []);

  const userId = localStorage.getItem("profileUserId")

  function handleSubmit(e) {
    e.preventDefault();
    setLoader(true)
    createAddress(address, setAddressMessage).then(() => getAddressDetails(userId).then(res => {setAddress(res); close(); setLoader(false)}))
  }

  function handleChange(e) {
    // setgetLoader(true)
    setUserAddress({ ...address, [e.target.name]: e.target.value })
    selectCountry().then(res => setCountry(res))
    // setgetLoader(false)
  }



  function handleCountryChange(e) {
    // setgetLoader(true)
    setUserAddress({ ...address, country: e.target.value })
    selectStates(e.target.value).then(res => setState(res))
    // setgetLoader(false)
  }
  
  function handleStateChange(e) {
    // setgetLoader(true)
    setUserAddress({ ...address, state: e.target.value })
    selectCities(address.country, e.target.value).then(res => setCity(res))
    // setgetLoader(false)
  }


  return (
    <div>
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 className='text-center'>Places Lived</h5>

          <form action="" onSubmit={handleSubmit}>
            <div className="form-group">

              {AddressMessage ? <p className='text-danger'>
                <small>address type already exists</small>
                </p> : ''}

              <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className=" my-2 " style={{ maxWidth: "fit-content" }}>
                  <span
                    className="d-flex align-items-center justify-content-start gap-2"
                    style={{
                      marginRight: '5px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span>{<BiHome size={20} color='#ccc' />}</span>

                    <p
                      style={{
                        fontSize: '15px',
                        color: '#9f9797b8',
                      }}
                    >
                      Address Type
                    </p>
                  </span>
                </div>

              </div>

              <select name="addressType" id="addressType" onChange={handleChange} value={address.addressType} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                <option>select</option>
                <option value="permanent">Permanent</option>
                <option value="current">Current</option>
              </select>


              <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className=" my-2" style={{ width: "fit-content" }}>
                  <span
                    className="d-flex align-items-center gap-2"
                    style={{
                      marginRight: '5px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/flag.png`} alt='' /></span>

                    <p
                      style={{
                        fontSize: '15px',
                        margin: 'auto',
                        color: '#9f9797b8',

                      }}
                    >
                      Country
                    </p>
                  </span>
                </div>

              </div>

              <select name="country" id="country" onChange={handleCountryChange} value={address.country} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                <option>select Country</option>
                {country.map((countryName, index) => (

                  <option key={index} value={countryName.name}>{countryName.name}</option>

                ))}
              </select>

              {state && state.length > 0 && <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className=" my-2" style={{ maxWidth: "fit-content" }}>
                  <span
                    className="d-flex align-items-center gap-2"
                    style={{
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/location.svg`} alt='' width={15} /></span>
                    <p
                      style={{
                        fontSize: '15px',
                        margin: 'auto',
                        color: '#9f9797b8',
                      }}
                    >
                      State
                    </p>
                  </span>
                </div>
              </div>}


              {state && state.length > 0 && <select name="state" id="state" onChange={handleStateChange} value={address.state} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                <option value="">select state</option>
                {state.map((stateName, index) => (
                  <option key={index} value={stateName.name}>{stateName.name}</option>
                ))}
              </select>}

              {city && city.length > 0 && <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className=" " style={{ maxWidth: "fit-content" }}>
                  <span
                    className="d-flex align-items-center gap-2 "
                    style={{
                      margin: '10px 0px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/location.svg`} alt='' width={15} /></span>

                    <p
                      style={{
                        fontSize: '15px',
                        margin: 'auto',
                        color: '#9f9797b8',

                      }}
                    >
                      City
                    </p>
                  </span>
                </div>

                <div>

                  {city && city.length > 0 && <select name="city" id="city" onChange={handleChange} value={address.name} className="form-control form-box m-auto "
                    style={{
                      height: '50px',
                      fontWeight: '600',
                      border: '0px',
                      borderBottom: '2px solid #a6a6a659',
                      margin: '20px 0px',
                      width: '80%'
                    }}>
                    <option >select City</option>
                    {city.map((cityName, index) => (
                      <option key={index} value={cityName.name}>{cityName.name}</option>
                    ))}
                  </select>}

                </div>
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    padding: '10px 0px 0px 25px',
                  }}
                >
                  <div className="">
                    <span
                      className="d-flex align-items-center justify-content-start gap-2"
                      style={{
                        marginRight: '5px',
                        background: '#f5f5f5',
                        padding: '6px',
                        borderRadius: '10px',
                      }}
                    >
                      <span><img src={`${BASE_URL_STATIC}/icon/chat.svg`} alt='' width={20} /></span>
                      <p
                        style={{
                          fontSize: '15px',
                          color: '#9f9797b8',

                        }}
                      >
                        Tell me something about place
                      </p>
                    </span>
                    <textarea onChange={handleChange}
                      type="text" name='aboutPlace'
                      maxLength={150}
                      style={{
                        fontSize: '15px',
                        border: '1px solid #ccc',
                        marginLeft: '15px',
                        marginTop: '20px',
                        lineHeight: '50px',
                        width: '330px'
                      }}
                      className="form-control col-lg-12 "
                      placeholder="Add something..."
                    // value={user.fname}
                    />
                  </div>
                </div>

              </div>}


              {country && country.length > 0 && <div
                className="col-lg-12 d-flex"
                style={{
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <button type="submit" className="edit-modal-btn" >
                  { loader ? "saving..." : " Save " }
                </button>
              </div>}


            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PlacesLivedPopup