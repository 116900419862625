import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'

const PostSettings = () => {
  return (
    <div>
          <div className='py-2 d-flex justify-content-between align-items-center px-1 px-md-3 px-lg-5 text-preview'>
               <div className='d-flex justify-content-start align-items-center gap-4'>
                  <p style={{ width: "8%" , marginTop : "5px" }}>
                     <img className='w-100' src="/images/audience.svg" alt="" />
                  </p>
                  <small >
                     Audience
                  </small>
               </div>
               <p className='fw-light'><MdKeyboardArrowRight /></p>
            </div>

            <div className='d-flex justify-content-between  px-1 px-md-3'>
               <p className='py-3 fw-bold'>Advance Settings</p>
            </div>

            <div className='py-2 d-flex justify-content-between  px-1 px-md-3 px-lg-5 text-preview' >
               <small >Hide likes & views </small>
               <div className="form-switch">
                  <input className="form-check-input" aria-checked type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
               </div>
            </div>

            <div className='py-2 d-flex justify-content-between  px-1 px-md-3 px-lg-5 text-preview'>
               <small >Turn off comments </small>
               <div className="form-switch">
                  <input className="form-check-input" aria-checked type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
               </div>
            </div>
    </div>
  )
}

export default PostSettings