import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessBioPopup = () => {

  const { showBioPopup, setShowBioPopup, setPageAbout, createPageBio, pageDetails, setPageDetails } = useContext(businessContext);

  // To handle bio change
  const handleBioChange = (e) => {
    setPageAbout((prevState) => ({...prevState, bio: e.target.value}));
    setPageDetails((prevState) => ({...prevState, bio: e.target.value}));
  };
  
  return (
    <>
      <Modal show={showBioPopup} onHide={() => setShowBioPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center'>Bio</h4>

          <textarea id="businessBio" className="form-control my-4" style={{ height: '150px' }} placeholder='Write your bio here...'
            value={pageDetails && pageDetails.bio} onChange={handleBioChange} 
          ></textarea>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createPageBio}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessBioPopup;