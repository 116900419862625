import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link, useNavigate } from "react-router-dom";
import { loginSoftCorner } from '../../Emilo/UseContext/ScApiHelpers';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../config';
import toast from 'react-hot-toast';

const CheckSoftCornerPin = () => {

  const [checkSoftCornerPin, setCheckSoftCornerPin] = useState('');

  const navigate = useNavigate();
  const { setSwipeHeader} = useContext(ApiContext)
  const {config} = useContext(ApiContext);

  // To handle check soft corner pin
  const handleCheckSoftCornerPin = (checkSoftCornerPin) => {
    loginSoftCorner(checkSoftCornerPin , config).then(res => {
      localStorage.setItem("SCtoken" , res.data.token);
      localStorage.setItem("SCuserId" , res.data.scUserId);
      if(res.status === 201) {
        navigate('/SoftCorner');
        setSwipeHeader(true);
        setCheckSoftCornerPin('');
      };
    })
    .catch(err => {
      if(err.response.data.message === 'wrong password') {
        toast.error('Wrong Password')
      };
    });
  };

  return (
    <>
      <section className='checkSoftCornerPin'>
        <div className='checkSoftCornerPin-area'>
          {/* Emilo Logo */}
          <div className='checkSoftCornerPin-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='checkSoftCornerPin-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='checkSoftCornerPin-area-data w-100'>
            <h2 className='checkSoftCornerPin-area-data-head'>Enter Soft Corner Pin</h2>

            <p className='checkSoftCornerPin-area-data-message mb-3'>Enter 4-digit security PIN</p>

            <OtpInput
              value={checkSoftCornerPin}
              onChange={setCheckSoftCornerPin}
              numInputs={4}
              renderSeparator={<pre> </pre>}
              renderInput={(props) => <input {...props} className='checkSoftCornerPin-area-data-pin mt-3' onKeyDown={(e) => e.key === 'Enter' && handleCheckSoftCornerPin(checkSoftCornerPin)} />}
            />
          </div>

          <Link to={`/SoftCorner/forgotSoftcornerPin`} className='my-3'>Forgot Pin</Link>

          {/* Button */}
          <button className='checkSoftCornerPin-area-btn' type="button" onClick={() => handleCheckSoftCornerPin(checkSoftCornerPin)}>Submit</button>
        </div>
      </section>
    </>
  )
}

export default CheckSoftCornerPin
