import React, {  useContext, useEffect } from 'react'
import { ApiContext } from '../../../UseContext/ApiCall';
import { getValue } from '../../../UseContext/SettingHelper';
import { settingContext } from '../../../UseContext/SettingContext';
import { BASE_URL_STATIC } from '../../../../../config';

const Language = () => {

  const { 

    value, setValue,

    handleOptionChange,

  } = useContext(settingContext);

  // To get value
  useEffect(() => {
    getValue('language', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  const { config } = useContext(ApiContext);

  return (
    <>
      <section className='vh-100 overflow-scroll scrollbar-hidden px-3 px-lg-4 px-xl-5 pt-3 pt-md-4 pt-lg-5'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Languages</li>
          </ol>
        </nav>

        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contactInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Select your Preferred Language.</h5>
          </div>

          <div id='contactInfo' className="accordion-collapse show collapse ms-3" data-bs-parent="#accordionToggle1" >
            <h5 className='accordion-level-4 mt-3 mb-2'>Select your Preferred Language.</h5>

            <span className='text-secondary mb-3 d-block'>(You selected <span className='text-primary text-capitalize'>{value.preferredLanguage ? value.preferredLanguage : 'English'}</span> as your preferred language)</span>

            {/* हिन्दी */}
            <div className="mb-2 ms-4">
              <input type="radio" className="form-check-input" id="language-hindi" name="preferredLanguage" value="हिन्दी" checked={value.preferredLanguage === 'हिन्दी'} onChange={(e) => handleOptionChange(e, 'language')} />
              <label className="form-check-label ms-2" htmlFor="language-hindi">हिन्दी</label>
            </div>

            {/* English */}
            <div className="mb-2 ms-4">
              <input type="radio" className="form-check-input" id="language-english" name="preferredLanguage" value="english" checked={value.preferredLanguage === 'english' || !value.preferredLanguage} onChange={(e) => handleOptionChange(e, 'language')} />
              <label className="form-check-label ms-2" htmlFor="language-english">English</label>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default Language
