import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ScApiContext } from "../useContext/softCornerContext";
import { Hearts } from "react-loader-spinner";
import { getPosts } from "../../Emilo/UseContext/ScApiHelpers";
import { BASE_URL_STATIC } from "../../../config";
import ScPhotoPopup from "./ScPhotoPopup";

const GallaryAlbumPhotos = () => {
  const { scConfig } = useContext(ScApiContext);
  const [spinner, setSpinner] = useState(false);
  const [userPhotos, setUserPhotos] = useState([]);
  const [data , setData] = useState(null)
  const [openPopup , setOpenPopup] = useState(false)

  useEffect(() => {
    setSpinner(true);

    getPosts(scConfig).then((posts) => {
      const imageUrls = posts
        .flatMap((post) =>
          post.details && post.details.length > 0
            ? post.details.map((detail) => detail.imageUrl).filter(Boolean)
            : []
        );

      setUserPhotos(imageUrls);
      setSpinner(false);
    });
  }, [scConfig]);


  return (
    <>
    {data &&  <ScPhotoPopup open={openPopup} data={data} close={() => setOpenPopup(false)} />}
      <section className="sc-gallary" style={{ flexDirection: "column" }}>
        <section>
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`}
                alt="sc-gallary-photos"
                height={30}
                width={30}
              />
              <span>Photos</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Album`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`}
                alt="sc-gallary-album"
                height={30}
                width={30}
              />
              <span>Album</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Explore`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`}
                alt="sc-gallary-explore"
                height={30}
                width={30}
              />
              <span>Explore</span>
            </Link>
          </div>
        </section>

        <h2 style={{ margin: "20px 50px" }}>Photos</h2>

        <section>
          {spinner ? (
            <Hearts
              height="150"
              width="150"
              color="#ED1E24"
              ariaLabel="hearts-loading"
              wrapperStyle={{}}
              wrapperClass="gallary-loader"
              visible={true}
            />
          ) : (
            userPhotos &&
            userPhotos.length > 0 && (
              <div className="sc-gallary-older">
                <div className="older-posts">
                  {userPhotos.map((post, index) => (
                    <span onClick={() =>{setOpenPopup(true); setData(post)}} key={index} className="older-posts-span">
                        <img src={post} alt="" />
                    </span>
                  ))}
                </div>
              </div>
            )
          )}
        </section>
      </section>
    </>
  );
};

export default GallaryAlbumPhotos;
