import React, { useEffect, useState } from 'react'

const BestclipSearch = ({ searchList, setSearchList, setSelectedCategory, setShow }) => {
    const [inputValue, setInputValue] = useState('')
    const [originalData, setOriginalData] = useState([]);

    // On first render, store the original searchList data in originalData
    useEffect(() => {
        setOriginalData(searchList); // Preserves the original searchList on initial load
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.length > 0) {
            const filteredList = originalData.filter(item =>
                item.childCategoryName.toLowerCase().includes(value.toLowerCase())
            );
            setSearchList(filteredList);
        } else {
            // Reset to original data when input is cleared
            setSearchList(originalData);
        }
    }



    return (
        <div className="inputContainer">
            <div className="searchContainerMain">
                <div className="searchContainer_text">
                    <input
                        // ref={inputref}
                        type="text"
                        autoFocus
                        placeholder="Tag peoples"
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    <div className="searchDiv">
                        {searchList.length !== 0 &&
                            searchList.map((item, index) => (
                                <div onClick={() => { setSelectedCategory(item); setShow(false) }}
                                    className="tagUserLIst"
                                    key={index}
                                >
                                    <span>{item.childCategoryImage}</span>
                                    <span>
                                        <small>{item.childCategoryName}</small>
                                        <small>{item.parentCategoryName}</small>
                                    </span>
                                </div>
                            ))}
                    </div>

                </div>{" "}
            </div>

        </div>
    )
}

export default BestclipSearch