import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../../UseContext/BusinessContext';


const BusinessAddListingJobPopup = () => {

  const { 
    showAddJobsPopup, setShowAddJobsPopup,
    addNewJob, setAddNewJob,
    createJob,
  } = useContext(businessContext);


  const fileInputRef = useRef(null); 

  // handle click
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  };

  // handle change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setAddNewJob(prevState => ({ ...prevState, image: e.target.files[0] }));
    };
  };

  return (
    <>
      <Modal show={showAddJobsPopup} onHide={() => setShowAddJobsPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
      {/* <Modal show={true} onHide={() => setShowAddJobsPopup(false)} keyboard={false} className="create-post" backdrop="static" centered> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Job</h4>

          <>
            {
              addNewJob.image
              ?
              <div className='border mb-4 rounded-4' style={{ height: '250px' }}>
                <img src={URL.createObjectURL(addNewJob.image)} className='h-100 w-100 object-fit-fill rounded-4' alt="product" />
              </div>
              :
              <div className='d-flex flex-column align-items-center w-75 mx-auto rounded-3 py-4 mb-5 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                <img src={`/images/business/icons/upload-photo-blue.svg`} style={{ height: '80px' }} alt="upload-blue" />

                <small className='text-primary'>Upload</small>
                <small className='text-secondary'>or</small>
                <small>Drop your file here</small>

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
              </div>
            }
          </>

          <small className='text-secondary'>Heading</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='heading' placeholder='Heading...' 
            value={addNewJob.heading} onChange={(e) => setAddNewJob((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Description</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='description' placeholder='Description...' 
            value={addNewJob.description} onChange={(e) => setAddNewJob((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Salary</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='salary' placeholder='Salary...' 
            value={addNewJob.price} onChange={(e) => setAddNewJob((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Experience</small>
          <select className="form-select border-0 border-bottom shadow-none mb-4" name='experience'
            value={addNewJob.experience} onChange={(e) => setAddNewJob((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
          >
            <option value="fresher">Fresher</option>
            <option value="expert">Expert</option>
            <option value="pro">Pro</option>
          </select>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill'
              disabled={!(addNewJob.image && addNewJob.heading && addNewJob.description && addNewJob.salary && addNewJob.experience)}
              onClick={createJob}
            >Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessAddListingJobPopup;