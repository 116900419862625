import React from 'react';
import './reel4.css';

const CreateReel4details = ({selectedCategory , setSelectedCategory }) => {
  const categoryList = ["Film & Animation", "Autos & Vehicles", "Music", "Animals", "Sports", "Travel & Events", "Gaming", "People & Blogs", "Comedy", "Entertainment", "News & Politics", "How to & Style", "Education", "Science & Technology", "Nonprofits & Activism", "other"];

  return (
    <div className='reel4Body'>
    
      <div className="select">
        <select
          required
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option className='optionStyle' value=''  disabled selected >select category</option>
          
          {categoryList.map((category, i) => (
            <option key={i} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

     
      
    </div>
  );
};

export default CreateReel4details;
