import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../config';
import { useParams } from 'react-router-dom';


const AddEventHostsPopup = () => {

  const { eventId } = useParams();

  const { 
    showAddEventHostsPopup, setShowAddEventHostsPopup, 
    newEvent, setNewEvent,
    searchedHostsList, setSearchedHostsList,
    selectedHostsList, setSelectedHostsList,
    getHostsToAdd, 
    addEventHosts,
  } = useContext(businessContext);

  // handle search host change
  const handleSearchedHostChange = (e) => {
    setNewEvent(preState => ({ ...preState, host: e.target.value }));

    getHostsToAdd(e.target.value, eventId);
  };

  // handle searched host click 
  const handleSearchedHostClick = (host) => {
    // Remove the host from the searched host list
    setSearchedHostsList((prevSearched) => 
      prevSearched.filter(searchedHost => searchedHost._id !== host._id)
    );
  
    // Add the host to selected host list
    setSelectedHostsList((prevSelected) => 
      [...prevSelected, host]
    );
  };

  // handle selected host click 
  const handleSelectedHostClick = (host) => {
    // Remove the host from the selected host list
    setSelectedHostsList((prevSearched) => 
      prevSearched.filter(selectedHost => selectedHost._id !== host._id)
    );
  
    // Add the host to searched host list
    setSearchedHostsList((prevSelected) => 
      [...prevSelected, host]
    );
  };

  return (
    <>
      <Modal show={showAddEventHostsPopup} onHide={() => setShowAddEventHostsPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
      {/* <Modal show={true} onHide={() => setShowAddEventHostsPopup(false)} keyboard={false} className="create-post" backdrop="static" centered> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h5 className='text-center mb-5'>Add Event Hosts</h5>

            {/* Selected Host */}
            <>
              {
                selectedHostsList && selectedHostsList.length > 0
                &&
                <div className='mb-2 py-1 d-flex flex-wrap gap-3'>
                  {
                    selectedHostsList.map((host, index) => (
                      <div className='d-inline bg-primary text-white rounded-pill ps-3 pe-2 py-1' key={index}>
                        <span className='me-2'>{host.name}</span>

                        <span className='bg-danger text-white px-2 rounded-circle cursor-pointer' onClick={() => handleSelectedHostClick(host)}>x</span>
                      </div>
                    ))
                  }
                </div>
              }
            </>

            <div className='d-flex align-items-center gap-3'>
              <input type="text" className='form-control' value={newEvent.host} onChange={handleSearchedHostChange} placeholder='Search Here...' />

              <button type="button" className='btn btn-primary' onClick={() => addEventHosts(eventId)} disabled={selectedHostsList.length === 0}>Add</button>
            </div>

            {/* Searched Hosts List */}
            <>
              {
                searchedHostsList.length !== 0
                &&
                <div className='border mt-3 overflow-y-scroll px-2 pt-2' style={{ maxHeight: '275px' }}>
                  {
                    searchedHostsList 
                    &&
                    searchedHostsList.map((host, index) => (
                      <div className='mb-3 d-flex gap-3 align-items-center cursor-pointer' onClick={() => handleSearchedHostClick(host)} key={index}>
                        <img src={AwsFileUrl+host.imageId} className='border rounded-circle object-fit-cover' style={{ height: '40px', width: '40px' }} alt="profile" />

                        <div>
                          <h6 className='mb-0 fw-semibold'>{host.name}</h6>
                          <span>{host.username ? host.username : 'username06@'}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEventHostsPopup;