import React, { useState, useRef, useEffect } from 'react';
import { FaPause, FaPlay } from "react-icons/fa6";
import { AwsFileUrl } from '../../../../config';
import { IoMdDownload } from "react-icons/io";

const MsgAudioPlayer = ({ audioSrc, fileName, isOnChat }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);


    useEffect(() => {
        const audio = audioRef.current;

        const updateCurrentTime = () => {
            setCurrentTime(audio.currentTime);
        };

        const setAudioDuration = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener('timeupdate', updateCurrentTime);
        audio.addEventListener('loadedmetadata', setAudioDuration);

        return () => {
            audio.removeEventListener('timeupdate', updateCurrentTime);
            audio.removeEventListener('loadedmetadata', setAudioDuration);
        };
    }, []);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleSliderChange = (e) => {
        const newTime = e.target.value;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    // Resetting playback when audio ends
    useEffect(() => {
        const audio = audioRef.current;
        const handleEnded = () => {
            setIsPlaying(false);
            setCurrentTime(0);
        };

        audio.addEventListener('ended', handleEnded);
        return () => {
            audio.removeEventListener('ended', handleEnded);
        };
    }, []);
    return (
        <div className='d-flex flex-column justify-content-center'>
            <audio ref={audioRef} src={audioSrc} />
            <div className='d-flex align-items-center' >
                <img src={AwsFileUrl + `images/icon/audio.svg`} alt="" width={45} />
                <div className='d-flex align-items-center' onClick={togglePlayPause} style={{ cursor: 'pointer', fontSize: '20px' }}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="range"
                        min="0"
                        max={duration || 0}
                        value={currentTime}
                        onChange={handleSliderChange}
                        style={{ marginLeft: '10px', height: '1px' }}
                    />
                </div>

                {isOnChat && (
                    <a href={audioSrc} target='_blank' rel='noopener noreferrer' download={fileName}>
                        <span className='ms-2 fs-4'>
                            <IoMdDownload />
                        </span>
                    </a>
                )}

            </div>
            <div className='d-flex gap-1' style={{ fontSize: '10px' }}>
                <span className='w-50 text-truncate'>{fileName}</span>
                <span className='' >{formatTime(currentTime)} / {formatTime(duration)}</span>

            </div>
        </div>
    );
};

const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export default MsgAudioPlayer;