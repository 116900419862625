import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import { profileContext } from '../../UseContext/ProfileContext';


const ReportPost = () => {

  const { reportPost, setReportPost, handleReportPost, showReportPostPopup, setShowReportPostPopup } = useContext(ApiContext);

  const {profilePostData} = useContext(profileContext); 

  return (
    <>
      <section className='reportPostPopups'>
        <Modal show={showReportPostPopup} onHide={() => setShowReportPostPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report Post</h4>

              <textarea className='reportPostPopup-textarea' value={reportPost} onChange={(e) => setReportPost(e.target.value)} name="report" placeholder='Report here...'></textarea>

              <span className='reportPostPopup-report' onClick={() => handleReportPost( profilePostData._id, profilePostData.type ,profilePostData.postedBy, reportPost)}>Report</span>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportPost
