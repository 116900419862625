import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Parser from "html-react-parser";
import { Link } from 'react-router-dom';
import { getEventPopupData } from "../../UseContext/ApiHelpers";
import { AwsFileUrl, BASEURL } from "../../../../config";
import FollowUnfollowButton from "../FollowUnfollowButton";
import FollowedBy from "../../Others/FollowedBy/FollowedBy";
import { ApiContext } from "../../UseContext/ApiCall";

const ViewPopup = ({ open , close, postId }) => {
  const [viewPopupData, setViewPopupData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State to store the search input

  const { config, loggedInUserId } = useContext(ApiContext);

  useEffect(() => {
    getEventPopupData(postId,'view', config).then((res) => {
        setViewPopupData(res);
    })
  }, []);

  // Filter the viewPopupData based on the search query
  const filteredData = viewPopupData.filter((data) =>
    data.result && data.result.fname && data.result.fname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <section>
        <Modal
          show={open}
          onHide={() => close()}
          centered
          backdrop="static"
          keyboard={false}
          className="create-call"
        >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body >
            <h5 style={{ textAlign: "center" }}>Views</h5>

            {/* Search Bar */}
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search users"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="likepopup">
              {filteredData.length > 0 ? (
                filteredData.map((data, index) => {
                  return (
                    <div key={index} className="d-flex align-items-center mb-3 justify-content-between px-2">
                      <img
                        className="rounded-circle rb-profile"
                        src={AwsFileUrl + data.result.imageId}
                        alt="profile"
                      />

                      <div className="ms-2 ms-xl-3 me-auto">
                        <Link to={`${BASEURL}/profile/${data.result._id}`} className="rb-name">
                          {Parser(data.result.fname)}
                        </Link>

                        {loggedInUserId !== data.result._id && <FollowedBy otherUserId={data.result._id} />}
                      </div>

                      <div className="followBox">
                        {loggedInUserId !== data.result._id && <FollowUnfollowButton otherUserId={data.result._id} />}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className='fw-bold text-center'>No users found</p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ViewPopup;
