import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete"

const AddLocation = ({ location, setLocation }) => {

  const handlePlaceSelect = async (e) => {
    setLocation(e.label)
  }


  return (
    <>
      <form className='addLoaction w-100 text-truncate'>
        {location ?
         <p className='location-added '>
          {/* {location.length > 60 ? <p>{location.substr(0,60)+"..."}</p> : <p>{location}</p>} */}
          <p className='text-truncate location_name'>{location}</p>
          <span onClick={() => setLocation(null)}>

          <FontAwesomeIcon icon={faClose} />
          </span>
         </p> 
         
         :
          <GooglePlacesAutocomplete
            apiKey="AIzaSyCclwLbFfRxROyJuuluEBa9v-cu15SEf1U"
            selectProps={{
              placeholder : "Add location",
              onChange: handlePlaceSelect,
              styles: {
                input: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }
            }}
          />
        }
      </form>
    </>
  )
}

export default AddLocation;
