import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../config';
import Rating from 'react-rating';
import { useParams } from 'react-router-dom';


const BusinessReplyReviewPopup = () => {

  const { 
    showReplyReviewPopup, setShowReplyReviewPopup, 
    replyReviewData, setReplyReviewData,
    replyReview,
  } = useContext(businessContext);

  const { pageId: paramsPageId } = useParams();

  return (
    <>
      <Modal show={showReplyReviewPopup} onHide={() => setShowReplyReviewPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Reply</h4>

          <>
            {
              replyReviewData
              &&
              <div className='d-flex flex-column align-items-center'>
                <div style={{ height: '100px', width: '100px' }}>
                  <img src={AwsFileUrl+replyReviewData.userId.imageId} className='border rounded-circle h-100 w-100 object-fit-contain' alt="profile" />
                </div>

                <h5 className='mt-2 mb-0'>{replyReviewData.userId.fname}</h5>

                <small className='text-secondary d-block'>
                  {
                    new Date(replyReviewData.createdAt).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'long', // You can use 'long' if you want the full month name
                      year: 'numeric',
                    })
                  }
                </small>

                <div className='mb-3'>
                  <Rating
                    className='text-center me-2'
                    initialRating={replyReviewData.rating}
                    readonly
                    emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '15px' }} alt="empty" />}
                    fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '15px' }} alt="fill" />}
                  />

                  <small className='fw-semibold'>({replyReviewData.rating}/5)</small>
                </div>

                {
                  replyReviewData.review !== ''
                  &&
                  <textarea class="form-control rounded-3 mb-3" value={replyReviewData.review}></textarea>
                }

                <textarea class="form-control mb-3" value={replyReviewData.reply} onChange={(e) => setReplyReviewData({ ...replyReviewData, reply: e.target.value })} placeholder="Write your reply here..."></textarea>
              </div>
            }
          </>

          <div className='text-center'>
            <button type="button" className='btn btn-primary rounded-pill w-50' onClick={() => replyReview(paramsPageId)}>Reply</button>
          </div>
        </Modal.Body>
      </Modal> 
    </>
  )
}

export default BusinessReplyReviewPopup
