import React, { useContext, useState, useEffect } from 'react';
import { ApiContext } from '../UseContext/ApiCall';
import FollowUnfollowButton from '../Button/FollowUnfollowButton';
import PostTime from '../Home/CenterBlock/CenterBlockPosts/PostTime';
import NotificationNamePopup from '../Popup/Notification/NotificationNamePopup';
import { Link } from 'react-router-dom';
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC } from '../../../config';
import { Spinner } from 'react-bootstrap';
import { getNotification, seenNotifications } from '../UseContext/ApiHelpers';
import axios from 'axios';
import FollowRequestPopup from './FollowRequestPopup';


const Notifications = () => {

  const [showNotificationNamePopup, setShowNotificationNamePopup] = useState(false);
  const [notificationNamePopupData, setNotificationNamePopupData] = useState([]);
  const [notification, setnotification] = useState([])
  const [toggleFollow, setToggleFollow] = useState(false)
  const [openFollow, setOpenFollow] = useState(false)
  const [followData, setFollowData] = useState({})

  const { config, handleNotificationInfiniteScroll,
    oldNotifications, isNotificationStopped,
    handleChangeStopAllNotification,
    handleAcceptRelation, handleRejectRelation, showRealtionButton,
  } = useContext(ApiContext);

  localStorage.removeItem('showNewNotificationDot');

  useEffect(() => {
    if (openFollow === false) {
      getNotification(config).then((res) => {
        setnotification(res)
      })
    }
  }, [openFollow]);



  // To handle notification popup
  const handleNotificationNamePopup = (data) => {
    setNotificationNamePopupData(data);
    setShowNotificationNamePopup(true);
  };

  console.log("nx", notification)

  const handleAcceptandFollow = async (type, id, userID) => {
    if (type === 'accept') {
      await axios.post(`${BASEAPIURL}/user/acceptfollowuser`, { followerID: userID }, config)
      setToggleFollow(true);
      seenNotifications(id, config)

    } else if (type === 'reject') {
      seenNotifications(id, config)
    }
    setnotification((prev) => prev.filter((notif) => notif.notifications[0]._id !== id));
  };

  return (
    <>
      {/* Notification Name Popup */}
      <NotificationNamePopup showNotificationNamePopup={showNotificationNamePopup} setShowNotificationNamePopup={setShowNotificationNamePopup} notificationNamePopupData={notificationNamePopupData} />

      <FollowRequestPopup setFollowData={setFollowData} config={config} open={openFollow} close={() => setOpenFollow(false)} followData={followData} />
      <h6 className="trending_head pt-lg-5 mt-lg-5">Notifications</h6>
      {
        !isNotificationStopped
          ?
          // <section className='notifications'  onScroll={handleNotificationInfiniteScroll}>

          <section className='notification w-75 m-auto' onScroll={handleNotificationInfiniteScroll}>
            {/* New Notification */}
            {
              notification
              &&
              notification.map((n, index) => (
                <section className='oldNotification' key={index}>
                  {console.log("data notification", n)}
                 <Link to={`/showCopyLink/${n.targetEventId}`}>
                  <section className='flex p-2' >
                    <div className='flex justify-content-start gap-2'>
                      <img className='oldNotification-follow-1-img rounded-circle me-2 me-sm-3' src={AwsFileUrl + n.users[0].imageId} alt="profile" />

                      <div className='d-flex flex-column align-items-start'>
                        <p className='fs-14'>{n.description}</p>
                        <span className='fs-14 text-secondary'>
                          <PostTime date={n.notifications[0] && n.notifications[0].createdAt} />
                        </span>
                      </div>
                    </div>

                    {/* ---------- like ---------- */}

                    {n.notifications[0] && n.notifications[0].targetEvent !== 'follow' && <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" />}

                    {/* ---------- Follow ---------- */}

                    {/* <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" /> */}

                    {n.notifications.length === 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div>
                        {!toggleFollow &&
                          <div className='flex'>
                            <button type="button" className="followUnfollowBtn" onClick={() => handleAcceptandFollow('accept', n.notifications[0]._id, n.notifications[0].userID)}>
                              Accept
                            </button>

                            <button type="button" className="followUnfollowBtn bg-danger" onClick={() => handleAcceptandFollow('reject', n.notifications[0]._id)}>
                              Reject
                            </button>
                          </div>
                        }
                      </div>
                    }
                    {n.notifications.length > 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div>
                        <button type="button" className="followUnfollowBtn" onClick={() => { setOpenFollow(true); setFollowData({ notification: n.notifications, users: n.users }) }}>
                          View
                        </button>
                      </div>
                    }

                    {/* <FollowUnfollowButton otherUserId={n.notifications[0].createdAt} /> */}
                  </section>
                  </Link>

                </section>
              ))
            }

            {/* Old Notification */}
            {
              notification
                ?
                notification.map((notification, index) => (
                  <section className='oldNotification' key={index}>
                    {/* ---------- Follow ---------- */}
                    <>
                      {/* Follow > Single */}
                      <>
                        {
                          notification.type === 'follow' && notification.data.length === 1
                          &&
                          <section className='oldNotification-follow-1 px-1 py-2 d-flex align-items-center'>
                            <img className='oldNotification-follow-1-img rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />

                            <div className='me-auto'>
                              <div className='d-flex align-items-center'>
                                <Link to={`${BASEURL}/profile/${notification.data[0].senderUserId}`} className='me-2 me-sm-3 fw-bold text-dark'>{notification.data[0].fname}</Link>
                                <PostTime date={notification.data[0].createdAt} />
                              </div>

                              <span className='text-secondary'>{notification.data[0].message}</span>
                            </div>

                            <FollowUnfollowButton otherUserId={notification.data[0].senderUserId} />
                          </section>
                        }
                      </>

                      {/* Follow > Multiple */}
                      <>
                        {
                          notification.type === 'follow' && notification.data.length > 1
                          &&
                          <section className='oldNotification-follow-2 px-1 py-2 d-flex align-items-center'>
                            <img className='oldNotification-follow-1-img rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />

                            <div>
                              <div className='d-flex align-items-center'>
                                <div className='fw-bold text-dark' onClick={() => handleNotificationNamePopup(notification.data)}>{notification.data[0].fname} and {notification.data.length - 1} other
                                  <span className='fw-normal ms-2 ms-sm-3'><PostTime date={notification.data[0].createdAt} /></span>
                                </div>
                              </div>

                              <span className='text-secondary'>{notification.data[0].message}</span>
                            </div>
                          </section>
                        }
                      </>
                    </>

                    {/* ---------- Like ---------- */}
                    <>
                      {/* Like > Single */}
                      <>
                        {
                          notification.type === 'like' && notification.data.length === 1
                          &&
                          <section className='px-1 py-2 d-flex align-items-center'>
                            <img className='oldNotification-like-1-profile rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/user-icon2.jpg`} alt="profile" />

                            <div>
                              <div className='d-flex align-items-center'>
                                <Link to={`${BASEURL}/profile/${notification.data[0].senderUserId}`} className='me-2 me-sm-3 fw-bold text-dark'>{notification.data[0].fname}</Link>
                                <PostTime date={notification.data[0].createdAt} />
                              </div>

                              <span className='text-secondary'>{notification.data[0].message}</span>
                            </div>

                            <img className='oldNotification-like-1-post ms-auto' src={`${BASE_URL_STATIC}/cp-1.png`} alt="post" />
                          </section>
                        }
                      </>

                      {/* Like > Multiple */}
                      <>
                        {
                          notification.type === 'like' && notification.data.length > 1
                          &&
                          <section className='px-1 py-2 d-flex align-items-center'>
                            <img className='oldNotification-like-1-profile rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/user-icon3.jpg`} alt="profile" />

                            <div>
                              <div className='d-flex align-items-center'>
                                <div className='fw-bold text-dark' onClick={() => handleNotificationNamePopup(notification.data)}>{notification.data[0].fname} and {notification.data.length - 1} other
                                  <span className='fw-normal ms-2 ms-sm-3'><PostTime date={notification.data[0].createdAt} /></span>
                                </div>
                              </div>

                              <span className='text-secondary'>{notification.data[0].message}</span>
                            </div>

                            <img className='oldNotification-like-1-post ms-auto' src={`${BASE_URL_STATIC}/cp-2.png`} alt="post" />
                          </section>
                        }
                      </>
                    </>

                    {/* ---------- Comment ---------- */}
                    <>
                      {/* Comment > Parent Comment */}
                      <>
                        {
                          notification.type === "parentcomment"
                          &&
                          <section className='px-1 py-2 d-flex align-items-center'>
                            <img className='oldNotification-comment-1-profile rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/user-icon4.jpg`} alt="profile" />

                            <div>
                              <div className='d-flex align-items-center'>
                                <div className='me-2 me-sm-3 fw-bold text-dark' onClick={() => handleNotificationNamePopup(notification.data)}>{notification.data[0].fname}</div>
                                <PostTime date={notification.data[0].createdAt} />
                              </div>

                              <span className='text-secondary'>{notification.data[0].message}</span>
                            </div>

                            <img className='oldNotification-comment-1-post ms-auto' src={`${BASE_URL_STATIC}/cp-3.png`} alt="post" />
                          </section>
                        }
                      </>

                      {/* Comment > Child Comment */}
                      <>
                      </>
                    </>

                    {/* ---------- Relation ---------- */}
                    <>
                      {
                        notification.type === 'relation'
                        &&
                        <section className='px-1 py-2 d-flex align-items-center'>
                          <img className='oldNotification-comment-1-profile rounded-circle me-2 me-sm-3' src={`${BASE_URL_STATIC}/user-icon5.jpg`} alt="profile" />

                          <div className='me-auto'>
                            <div className='d-flex align-items-center'>
                              <Link to={`${BASEURL}/profile/${notification.senderUserId}`} className='me-2 me-sm-3 fw-bold text-dark'>{notification.data[0].firstUserName}</Link>
                              <PostTime date={notification.data[0].createdAt} />
                            </div>

                            <span className='text-secondary'>{notification.data[0].message}</span>
                          </div>

                          {
                            showRealtionButton
                            &&
                            <>
                              <button type="button" className='btn btn-outline-success me-1' onClick={() => handleAcceptRelation(notification.data[0]._id)}>&#10003;</button>
                              <button type="button" className='btn btn-outline-danger' onClick={() => handleRejectRelation(notification.data[0]._id)}>&#10005;</button>
                            </>
                          }
                        </section>
                      }
                    </>
                  </section>
                ))
                :
                <div className='oldNotification-spinner'>
                  <Spinner variant="secondary" />
                </div>
            }

            {oldNotifications.length !== 0 && <span className='notification-spinner'><Spinner variant="secondary" size="sm" /></span>}
          </section>

          :
          <section className='notifications-stop'>
            <div>
              <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="notification" />
            </div>

            <h6>You have stopped all notifications if you want to see again <span onClick={() => handleChangeStopAllNotification(config)}>click here</span></h6>
          </section>
      }
    </>
  )
}

export default Notifications;
