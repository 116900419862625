import React, { useEffect, useState } from 'react';
import TwoFactorPage from './TwoFactorPage';
import LoginAlert from './LoginAlert';
import ChangePassword from '../../../createUser/multiStepSignup/ChangePassword/ChangePassword';
const SecurityChecksPage = ({ handleOptionChange, value }) => {
    const [Page, setPage] = useState('');
    useEffect(() => {
        // Create a new link element for the CSS file
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/css/TwoFactorOtherPage.css'; // Replace with the path to your CSS file
        // Append the link element to the document head
        document.head.appendChild(link);
        // Cleanup function to remove the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
      }, []);

    return (
        <>
            {Page === 'loginAlert' && (
                <>
                    <LoginAlert value={value} handleOptionChange={handleOptionChange} />
                    <div className='card'>
                        <div className='card-body'>
                            <a
                                href="/"
                                className="btn btn-outline-secondary d-block rounded-pill "
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPage('');
                                }}
                            >
                                Back
                            </a>
                        </div>
                    </div>
                </>
            )}

            {Page === 'twoFactorPage' && (
                <>
                    <TwoFactorPage />
                    <div className='card'>
                        <div className='card-body'>
                            <a
                                href="/"
                                className="btn btn-outline-secondary d-block rounded-pill "
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPage('');
                                }}
                            >
                                Back
                            </a>
                        </div>
                    </div>
                </>
            )}

            {Page === 'changePassword' && (
                <>
                    <ChangePassword />
                    <div className='card'>
                        <div className='card-body pt-0'>
                            <div className='w-75 mx-auto'>
                                <a
                                    href="/"
                                    className="btn btn-outline-secondary d-block rounded-pill "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage('');
                                    }}
                                >
                                    Back
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {Page === 'passwordStrength' && (
                <div className="card boxShadow rounded-0">
                    <div className="card-body">
                        <p>If your eMilo password is used on other sites, your account's security may be at risk. Safeguard your account and your eMilo friends by choosing a strong, unique password.</p>
                        <ul className="mt-3" style={{ listStyle: 'circle' }}>
                            <li className="p-1">Select a password that's unique to eMilo and not used on other sites.</li>
                            <li className="p-1">Ensure it's easy for you to remember but hard for others to guess.</li>
                            <li className="p-1">Keep your password private and never share it with anyone.</li>
                        </ul>
                        <div className="d-flex mt-2 justify-content-between">
                            <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => { setPage('changePassword') }}
                            >
                                Change Password
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => { setPage('') }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {Page === '' && (
                <div className="card boxShadow rounded-0">
                    <div className="card-body">
                        <div
                            className="p-3 two_facetor_type"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage('passwordStrength');
                            }}
                        >
                            <div className="arrow pull-right mt-3">
                                <i className="fa fa-arrow-right me-3"></i>
                            </div>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <i className="fa fa-key me-3"></i>
                                <div>
                                    <b>Password Strength</b>
                                    <br />
                                    <small>Your Password Strength is good.</small>
                                </div>
                            </div>
                        </div>

                        <div
                            className="p-3 two_facetor_type"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage('twoFactorPage');
                            }}
                        >
                            <div className="arrow pull-right mt-3">
                                <i className="fa fa-arrow-right me-3"></i>
                            </div>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <i className="fa fa-check-double me-3"></i>
                                <div>
                                    <b>Two-factor Authentication</b>
                                    <br />
                                    <small>We&apos;ll send a code to your mobile via SMS.</small>
                                </div>
                            </div>
                        </div>

                        <div
                            className="p-3 two_facetor_type"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage('loginAlert');
                            }}
                        >
                            <div className="arrow pull-right mt-3">
                                <i className="fa fa-arrow-right me-3"></i>
                            </div>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <i className="fa fa-exclamation-triangle me-3"></i>
                                <div>
                                    <b>Login alerts</b>
                                    <br />
                                    <small>We&apos;ll send a code to your email.</small>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            )}
        </>
    );
};

export default SecurityChecksPage;
