import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import PostTime from '../../../Home/CenterBlock/CenterBlockPosts/PostTime';
import Slider from 'react-slick';
import ProfileVideoThreeDotPopup from './ThreeDot/ProfileVideoThreeDotPopup';
import ProfilePostCommentThreeDotPopup from './CommentThreeDot/ProfilePostCommentThreeDotPopup';
import axios from 'axios';
import { ApiContext } from '../../../UseContext/ApiCall';
import LikeButton from '../../../Button/LikeButton/LikeButton';
import { BASE_URL_STATIC } from '../../../../../config';
import FavouritePostButton from '../../../Button/FavouriteButton/FavouritePostButton';

const ProfileVideosPopup = ({ showProfileVideosPopup, setShowProfileVideosPopup, profileVideosPopupData, setProfileVideosPopupData }) => {

  const [createComment, setCreateComment] = useState('');
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState();
  const [showProfileVideoThreeDotPopup, setShowProfileVideoThreeDotPopup] = useState(false);
  const [showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup] = useState(false);

  const { config } = useContext(ApiContext);

  // console.log(profileVideosPopupData);

  // To get/update comment of post
  const getPostComment = (postId, config) => {
    axios.post('https://emilonode.sjain.io/post/getpostcomment', { postId: postId }, config )
    .then(res => {
      // console.log(res.data.commentList);
      setComments(res.data.commentList);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    if(profileVideosPopupData) {
      getPostComment(profileVideosPopupData.postId, config);
    };
  }, [profileVideosPopupData]);

  // To create comment on post
  const createPostComment = (postId, comment, config) => {
    axios.post('https://emilonode.sjain.io/post/createpostcomment', { postId: postId, comment: comment }, config )
    .then(res => {
      // console.log(res);
      setCreateComment('');
      getPostComment(postId, config);
    })
    .catch(err => {
      console.log(err);
    });
  };
  
  // To handle profile post comment three dot
  const handleProfilePostCommentThreeDot = (comment) => {
    setComment(comment);
    setShowProfilePostCommentThreeDotPopup(true);
  };

  const settings = {
    dots: true,
    className: "profileVideosPopup-post-slider",
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
  };
  
  return (
    <>
      {/* Profile Video Three Dot Popup */}
      <ProfileVideoThreeDotPopup showProfileVideoThreeDotPopup={showProfileVideoThreeDotPopup} setShowProfileVideoThreeDotPopup={setShowProfileVideoThreeDotPopup} setShowProfileVideosPopup={setShowProfileVideosPopup} profileVideosPopupData={profileVideosPopupData} setProfileVideosPopupData={setProfileVideosPopupData} />

      {/* Profile Photos And Videos Comment Three Dot Popup */}
      <ProfilePostCommentThreeDotPopup showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} comment={comment} getPostComment={getPostComment} />


      <Modal show={showProfileVideosPopup} onHide={() => setShowProfileVideosPopup(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            profileVideosPopupData
            &&
            <section className='profileVideosPopup'>
              {/* Profile Videos Popup video */}
              <section className='profileVideosPopup-video'>
                <Slider {...settings}>
                  {
                    profileVideosPopupData
                    &&
                    profileVideosPopupData.details.map((details, index) => (
                      // console.log(details)
                      details.imageId
                      ?
                      <div className="profileVideosPopup-video-pav">
                        <img className="profileVideosPopup-video-image" src={details.imageUrl} alt={details.imageId} height='100%' width='100%' />
                      </div>
                      :
                      <div className="profileVideosPopup-video-pav">
                        <video className="profileVideosPopup-video-video" autoPlay>
                          <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${details.videoId}`} />
                        </video>
                      </div>
                    ))
                  }
                </Slider>
              </section>

              {/* Profile Video Popup details */}
              <section className='profileVideosPopup-details'>
                {/* user */}
                <div className='profileVideosPopup-details-user'>
                  <img className='profileVideosPopup-details-profile' src={profileVideosPopupData.profileImg} alt={profileVideosPopupData.postId} height={50} width={50} />

                  <div className='profileVideosPopup-details-header'>
                    <div className='profileVideosPopup-details-head'>
                      <span className='profileVideosPopup-details-name'>{profileVideosPopupData.fname}</span>
                      
                      {
                        (profileVideosPopupData.activityEmoji !== 'undefined' && profileVideosPopupData.activityName !== 'undefined')
                        &&
                        <span className='profileVideosPopup-details-activity'>{profileVideosPopupData.activityEmoji} {profileVideosPopupData.activityName}</span>
                      }

                      {
                        (profileVideosPopupData.feelingEmoji !== 'undefined' && profileVideosPopupData.feelingName !== 'undefined')
                        &&
                        <span className='profileVideosPopup-details-activity'>{profileVideosPopupData.feelingEmoji} {profileVideosPopupData.feelingName}</span>
                      }
                    </div>

                    {
                      profileVideosPopupData.title
                      &&
                      <span className='profileVideosPopup-details-location'>{profileVideosPopupData.title}</span>
                    }

                    <span className='profileVideosPopup-details-time'>
                      <PostTime date={profileVideosPopupData.createdTime} />
                    </span>
                  </div>

                  <img className='profileVideosPopup-details-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" onClick={() => setShowProfileVideoThreeDotPopup(true)} />
                </div>

                <hr className='profileVideosPopup-details-hr' />

                {/* comments */}
                <div className='profileVideosPopup-details-comments'>
                  <h3 className='profileVideosPopup-details-comments-head'>Comments</h3>

                  <div className='profileVideosPopup-details-comments-show'>
                    {
                      comments
                      &&
                      comments.map((comment, index) => (
                        // console.log(comment)
                        <div className='profilePostsPopup-details-comments-show-user'>
                          {
                            comment.profileImg
                            ?
                            <img className='profilePostsPopup-details-comments-show-profile' src={comment.profileImg} alt={comment.parentCommentId} height={40} width={40} />
                            :
                            <img className='profilePostsPopup-details-comments-show-profile' src={`${BASE_URL_STATIC}/dummy.png`} alt="profile" height={40} width={40} />
                          }

                          <div className='profilePostsPopup-details-comments-show-head'>
                            <h6 className='profilePostsPopup-details-comments-show-name'>{comment.username}</h6>
                            <span className='profilePostsPopup-details-comments-show-time'>
                              <PostTime date={comment.createdTime} />
                            </span>
                            <img className='profilePostsPopup-details-comments-show-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="threeDot" onClick={() => handleProfilePostCommentThreeDot(comment)} />

                            <span className='profilePostsPopup-details-comments-show-comment'>{comment.comment}</span>
                          </div>

                          <img className='profilePostsPopup-details-comments-show-like' src={`${BASE_URL_STATIC}/feed-icon2.png`} alt="like" height={27} width={20}  />
                        </div>
                      ))
                    }
                  </div>

                  <div className='profileVideosPopup-details-comments-create'>
                    <img className='profileVideosPopup-details-comments-create-icon' src={`${BASE_URL_STATIC}/create-icon1.png`} alt="icon" height={27} width={27} />

                    <form className='profileVideosPopup-details-comments-create-form'>
                      <label htmlFor="comment">
                        <input type="text" id='comment' name='comment' value={createComment} onChange={(e) => setCreateComment(e.target.value)} placeholder='Add a comment' />
                      </label>
                    </form>

                    <span className='profileVideosPopup-details-comments-create-btn' onClick={() => createPostComment(profileVideosPopupData.postId, createComment, config)}>Comment</span>
                  </div>
                </div>

                <hr className='profileVideosPopup-details-hr' />

                {/* area */}
                <div className='profileVideosPopup-details-area'>
                  <div className='profileVideosPopup-details-area-div'>
                    {
                      (profileVideosPopupData.viewCount === 0)
                      ?
                      <img className='profileVideosPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="like" height={20} width={30} />
                      :
                      <img className='profileVideosPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="like" height={20} width={30} />
                    }
                    <span className='profileVideosPopup-details-area-count'>{profileVideosPopupData.viewCount}</span>
                  </div>

                  <div className='profileVideosPopup-details-area-div'>
                    <LikeButton postId={profileVideosPopupData.postId} />
                  </div>

                  <div className='profileVideosPopup-details-area-div'>
                    <img className='profileVideosPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" />
                    <span className='profileVideosPopup-details-area-count'>{profileVideosPopupData.commentCount}</span>
                  </div>

                  <div className='profileVideosPopup-details-area-div'>
                    <img className='profileVideosPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" />
                    <span className='profileVideosPopup-details-area-count'>{profileVideosPopupData.shareCount}</span>
                  </div>

                  <div className='profileVideosPopup-details-area-favourite'>
                    <FavouritePostButton postId={profileVideosPopupData.postId} />
                  </div>

                  <img className='profileVideosPopup-details-area-download' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="star" />
                </div>

                {/* description */}
                <div className='profileVideosPopup-details-description'>{profileVideosPopupData.description}</div>
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileVideosPopup;
