import React from 'react'
import { Modal } from 'react-bootstrap';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL, BASEURL } from '../../../config';
import { Link } from 'react-router-dom';
import { seenNotifications } from '../UseContext/ApiHelpers';
import axios from 'axios';

const FollowRequestPopup = ({ open, close, config, followData , setFollowData}) => {


    const handleAcceptandFollow = async (type, id, userID, username) => {
        try {
          if (type === 'accept') {
            await axios.post(`${BASEAPIURL}/user/acceptfollowuser`, { followerID: userID }, config);
          }
          
          // Ensure seenNotifications runs before updating state
          await seenNotifications(id, config);
      
          // Update followData after the request completes
          setFollowData({
            notification: followData.notification.filter((notif) => notif._id !== id),
            users: followData.users.filter((user) => user.username !== username),
          });
        } catch (error) {
          console.error('Error in accepting/rejecting follow request:', error);
        }
      };
      

    return (
        <>
            <section>
                {/* <Modal show={true} onHide={() => setShowFollowerPopup(false)} centered backdrop="static" keyboard={false} className="create-call" > */}
                <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
                    <Modal.Header closeButton>&nbsp;</Modal.Header>
                    <Modal.Body>
                        <h6 className='text-center mb-3 fw-bold'>Follow Requests</h6>

                        <div>
                            {
                                followData && followData.users && followData.users.map((follower, index) => (
                                    <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>

                                        <img className='pfp-profile rounded-circle' src={AwsFileUrl + follower.imageId} alt={follower.fname} onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />

                                        <Link to={`${BASEURL}/profile/${followData.notification[index].userID}`} className='ms-2 ms-sm-3 me-auto text-dark' onClick={() => close()} >
                                            <h6 className='pfp-name fw-bold'>{follower.fname}</h6>
                                            <small className='text-secondary'>{follower.username}</small>
                                        </Link>

                                        <div className='flex'>
                                            <button type="button" className="followUnfollowBtn" onClick={() => {
                                                console.log("followDatas", followData.notification);

                                                handleAcceptandFollow('accept', followData.notification[index]._id, followData.notification[index].userID , follower.username )
                                            }}>

                                                Accept
                                            </button>

                                            <button type="button" className="followUnfollowBtn bg-danger" onClick={() => handleAcceptandFollow('reject', followData.notification[index]._id , "" ,  follower.username)}>
                                                Reject
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </Modal.Body>
                </Modal>
            </section>
        </>
    );
}

export default FollowRequestPopup