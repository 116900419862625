import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ProfileUserSettingNav from "./ProfileUserSettingNav";
import LeftBlock from "../../../Home/LeftBlock/LeftBlock";
import BackToTop from "../../../Helper/BackToTop";



const ProfileUserSetting = ({ className }) => {

  const location = useLocation();

  // console.log('location : ', location.pathname);
  // console.log('location : ', location.pathname.split('/'));

  return (
    <>
      <section>
        <section className={`section-main ${className || ""}`}>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-3 d-none d-lg-block shadow">
                <LeftBlock />
              </div>

              <div className={`${location.pathname.split('/').length === 5 && 'd-none d-lg-block'} col-lg-4`}>
                <ProfileUserSettingNav />
              </div>

              <div className={`${location.pathname.split('/').length === 4 && 'd-none d-lg-block'} col-lg-5 shadow`} style={{ background: "#F8F8F8" }}>
                <Outlet />
              </div>
            </div>
          </div>
        </section>
        <BackToTop />
      </section>
    </>
  );
};

export default ProfileUserSetting;
