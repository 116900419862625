import React, { useContext, useEffect, useMemo, useState } from "react";
import { getDataTypePost } from "../../../UseContext/ApiHelpers";
import { ApiContext } from "../../../UseContext/ApiCall";
import { AwsFileUrl } from "../../../../../config";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const SuggestedFlix = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const { config, setSuggestedFlixIndex } = useContext(ApiContext);
  const nav = useNavigate();

  useEffect(() => {
    setLoader(true);
    getDataTypePost('flix', 'posttype', "", 1, config, 8)
      .then(res => {
        setLoader(false);
        setData(res);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const handleNav = (index, event) => {
    if (isScrolling) {
      // Prevent the click if scrolling is detected
      event.preventDefault();
      return;
    }
    setSuggestedFlixIndex(index); // Pass index directly
    nav(`/suggested_flix`);
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
    setIsScrolling(false); // Assume scrolling will start
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    if (!isScrolling) {
      setIsScrolling(false); // Ensure scroll is not considered a click
    }
  };

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      setIsScrolling(true); // Mark it as scroll if mouse moves
    }
  };

  const settings = useMemo(() => ({
    dots: false,
    speed: 800,
    slidesToShow: Math.min(5, (data && data.length) || 0), // Default to 0 if data is undefined
    slidesToScroll: 2,
    adaptiveHeight: true,
    infinite: (data && data.length) > 5, // Enable infinite only if data has more than 5 items
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(5, (data && data.length) || 0), // Dynamically adjust based on data length
          slidesToScroll: 2,
          infinite: (data && data.length) > 5, // Same for responsive breakpoints
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(4, (data && data.length) || 0), // Adjust slides for smaller screens
          slidesToScroll: 2,
          infinite: (data && data.length) > 4,
        },
      },
    ],
  }), [data]); // Only recalculate settings when data changes


  return (
    <>
      <section className="suggested-flix mx-2 mx-sm-3 mx-lg-0">
        <h6 className="fw-semibold mb-2">Suggested Flix</h6>
        <div className="slick-slider-wrapper">
          {loader ? (
            <div className="d-flex justify-content-between">
              {Array(5)
                .fill(0)
                .map((e, i) => (
                  <Skeleton height={215} width={150} key={i} className="suggested-flix-img rounded-2 object-fit-fill shadow me-2" />
                ))}
            </div>
          ) : (
            <Slider {...settings}>
              {data && data.map((flix, index) => (
                <div
                  onClick={(e) => handleNav(index, e)}
                  className="suggested-flix-img rounded-2 object-fit-fill shadow overflow-hidden d-flex align-items-center position-relative bg-dark "
                  key={index}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                >
                  {index === 0 ?
                     <video autoPlay muted loop style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                     <source src={AwsFileUrl + flix.mediaUrls[0].url} type="video/mp4" />
                     Your browser does not support the video tag.
                   </video>
                    :
                    <img
                      src={AwsFileUrl + flix.postTumbnailUrl}
                      alt="flix"
                      style={{ filter: "brightness(0.8)" }}
                    />}
                  <FontAwesomeIcon icon={faPlay} className="sg_filx_play" />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>

      <hr className="hr" />
    </>
  );
};

export default SuggestedFlix;
