import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../../../config';
import { settingContext } from '../../../UseContext/SettingContext';
import SettingInputUser from './SettingInputUser';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';


const Privacy = () => {

  const { 

    value, setValue,

    handleOptionChange,

    setAdded, 

    handleQuickApply,

    isAccountPrivate, handleChangeAccountPrivacy

  } = useContext(settingContext);

  const { config } = useContext(ApiContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };
  
  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('privacy', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get (Followers Except, Hide From, Block Comments Users and Others)
    getAdded('privacy', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);



  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 pb-5 pb-lg-0 overflow-scroll' id='privacyToggle1'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Privacy</li>
          </ol>
        </nav>

        {/* Account Privacy */}
        <div className='d-flex align-items-center'>
          <div className='me-auto'>
            <h4 className='fw-semibold'>Account Privacy</h4>
            <span className='text-secondary'>{isAccountPrivate}</span>
          </div>

          <div className="form-check form-switch">
            <input type="checkbox" className="form-check-input" checked={isAccountPrivate === 'public' ? false : true} onChange={() => handleChangeAccountPrivacy(config)} role="switch" aria-checked="false" />
          </div>
        </div>

        <hr className='w-100' />

        <div className='accordion accordion-flush'>
          {/* ----- Content And Post ----- */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contentAndPost">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Content And Post</h5>  
            </div>

            <div id='contentAndPost' className="accordion-collapse collapse show accordion accordion-flush my-0" data-bs-parent="#privacyToggle1">
              {/* --- Post --- */}
              <div className='ms-3 mt-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#ap-cp-post">
                  <img src={`${BASE_URL_STATIC}/icon/post.svg`} alt="post" />
                  <h5 className='fw-semibold ms-3'>Post</h5>
                </div>

                <div id='ap-cp-post' className="accordion-collapse collapse show ms-3 accordion accordion-flush my-0">
                  {/* Audience Control */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-audienceControl">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Audience Control</h5>
                    </div>

                    <div id='post-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* View */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#audienceControlView">Who can view your post?</h5>

                        <div id='audienceControlView' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postView-public" name="postView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.postView || value.postView === 'public'} 
                                disabled={isAccountPrivate === 'private'}
                              />
                              <label className="form-check-label" htmlFor="ps-postView-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postView-followers" name="postView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postView === 'followers'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postView-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postView-cf" name="postView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postView === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postView-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postView-fof" name="postView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postView === 'fof'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postView-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.postView ? value.postView : 'public']} view your post)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.postView) || (value.postView === 'public') || (value.postView === 'followers') || (value.postView === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.postView || value.postView === 'public') ? 'postViewPublic' : 
                                    value.postView === 'followers' ? 'postViewFollowers' : 
                                    value.postView === 'fof' && 'postViewFOF'
                                  }
                                  settingType={value.postView ? value.postView : 'public'} 
                                  header={
                                    (!value.postView || value.postView === 'public') ? 'Hide post from specific people' :
                                    value.postView === 'followers' ? 'Followers Except' :
                                    value.postView === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your post' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                          {/* Apply the same */}
                          <div className='d-flex align-items-start px-0'>
                            <div className='me-3'>
                              <span>Apply the same post view setting to Flix, Stories, Best Clips, Memes & Live</span>

                              <span className='text-secondary'>(If you choose this, you can still specifically make changes in each content setting.)</span>
                            </div>

                            <button type="button" className='btn btn-sm btn-primary ms-auto' onClick={handleQuickApply}>Apply</button>
                          </div>

                          <hr className='hr bg-secondary mt-3' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Likes & Comments */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-likeAndComments">
                      <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                      <h5 className='fw-semibold ms-3'>Likes & Comments</h5>
                    </div>

                    <div id='post-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Like Count */}
                      <div className='ms-3 mt-3 me-auto'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm'>Hide like count</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' value={value.postLike === 'true' ? false : true}
                              onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.postLike === 'true' ? true : false} 
                            />
                          </div>
                        </div>

                        <small className='text-secondary d-block ms-3 mt-2'>(Confirm if you want to hide the like counts of your post)</small>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Comments */}
                      <div className='ms-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#likeAndCommentsComment">Who can comment on your post?</h5>

                        <div id='likeAndCommentsComment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postComment-public" name="postComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postComment && value.postView === 'public') || !value.postComment || value.postComment === 'public'} 
                                disabled={value.postView === 'fof' || value.postView === 'followers' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postComment-public">Public</label>
                            </div>


                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postComment-followers" name="postComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postComment && value.postView === 'followers') || value.postComment === 'followers'}
                                disabled={value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postComment-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postComment-cf" name="postComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postComment && value.postView === 'closeFriends') || value.postComment === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postComment-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postComment-fof" name="postComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postComment && value.postView === 'fof') || value.postComment === 'fof'}
                                disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postComment-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.postComment ? value.postComment : 'public']} comment on your post.)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.postComment) || (value.postComment === 'public') || (value.postComment === 'followers') || (value.postComment === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.postComment || value.postComment === 'public') ? 'postCommentPublic' : 
                                    value.postComment === 'followers' ? 'postCommentFollowers' : 
                                    value.postComment === 'fof' && 'postCommentFOF'
                                  }
                                  settingType={value.postComment ? value.postComment : 'public'} 
                                  header={
                                    (!value.postComment || value.postComment === 'public') ? 'Hide post comment from specific people' :
                                    value.postComment === 'followers' ? 'Followers Except' :
                                    value.postComment === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your post comment' 
                                  commentNote={true}
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Share & Download */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-shareAndDownload">
                      <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                      <h5 className='fw-semibold ms-3'>Share & Download</h5>
                    </div>

                    <div id='post-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Share */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-share">Who can share your post?</h5>

                        <div id='shareAndDownload-share' className='row accordion-collapse show collapse mt-3 ms-3 me-0'> 
                          {/* value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postShare-public" name="postShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postShare === 'public' || !value.postShare}
                                disabled={value.postView === 'followers' || value.postView === 'fof' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postShare-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postShare-followers" name="postShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postShare && value.postView === 'followers') || value.postShare === 'followers'} 
                                disabled={value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postShare-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postShare-cf" name="postShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postShare && value.postView === 'closeFriends') || value.postShare === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postShare-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postShare-fof" name="postShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postShare && value.postView === 'fof')  || value.postShare === 'fof'} 
                                disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postShare-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.postShare ? value.postShare : 'public']} share your post.)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.postShare) || (value.postShare === 'public') || (value.postShare === 'followers') || (value.postShare === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.postShare || value.postShare === 'public') ? 'postSharePublic' : 
                                    value.postShare === 'followers' ? 'postShareFollowers' : 
                                    value.postShare === 'fof' && 'postShareFOF'
                                  }
                                  settingType={value.postShare ? value.postShare : 'public'} 
                                  header={
                                    (!value.postShare || value.postShare === 'public') ? 'Hide post share from specific people' :
                                    value.postShare === 'followers' ? 'Followers Except' :
                                    value.postShare === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your post share' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* Download */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-download">Who can download your post?</h5>

                        <div id='shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postDownload-public" name="postDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postDownload === 'public' || !value.postDownload} 
                                disabled={value.postView === 'followers' || value.postView === 'fof' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postDownload-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postDownload-followers" name="postDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postDownload && value.postView === 'followers') || value.postDownload === 'followers'} 
                                disabled={value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postDownload-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postDownload-cf" name="postDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postDownload && value.postView === 'closeFriends') || value.postDownload === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postDownload-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postDownload-fof" name="postDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.postDownload && value.postView === 'fof') || value.postDownload === 'fof'} 
                                disabled={value.postView === 'followers' || value.postView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-postDownload-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-postDownload-none" name="postDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.postDownload === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-postDownload-none">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.postDownload ? value.postDownload : 'public']} download your post.)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.postDownload) || (value.postDownload === 'public') || (value.postDownload === 'followers') || (value.postDownload === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.postDownload || value.postDownload === 'public') ? 'postDownloadPublic' : 
                                    value.postDownload === 'followers' ? 'postDownloadFollowers' : 
                                    value.postDownload === 'fof' && 'postDownloadFOF'
                                  }
                                  settingType={value.postDownload ? value.postDownload : 'public'} 
                                  header={
                                    (!value.postDownload || value.postDownload === 'public') ? 'Hide post download from specific people' :
                                    value.postDownload === 'followers' ? 'Followers Except' :
                                    value.postDownload === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your post download' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* post share to story */}
                      <div className='ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow sharing of your post on stories</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked defaultChecked />
                          </div>
                        </div>
 
                        <div className='ms-4 mt-3'>
                          <span>By default, your selected audience for the post will be able to share your post on their stories. However, if you want to disallow specific people from using your post, you can select their names below.</span>

                          <SettingInputUser settingNav='privacy' settingKey='postShareToStory' settingType='other' />
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>
                    </div>
                  </div>

                  {/* Other Permission */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#post-otherPermission">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Other Permission</h5>
                    </div>

                    <div id='post-otherPermission' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Post Used In Meme */}
                      <div className='ms-3 mt-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow your post to be used in memes</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked defaultChecked />
                          </div>
                        </div>
 
                        <div className='ms-4'>
                          <span>By default, your selected audience for the post will be able to use your post for creating memes. However, if you want to disallow specific people from using your post, you can select their names below.</span>

                          <SettingInputUser settingNav='privacy' settingKey='postUsedInMeme' settingType='other' />
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Flix --- */}
              <div className='ms-3 mt-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#ap-cp-flix">
                  <img src={`${BASE_URL_STATIC}/icon/flix.svg`} alt="flix" />
                  <h5 className='fw-semibold ms-3'>Flix</h5>
                </div>

                <div id='ap-cp-flix' className="accordion-collapse collapse show ms-3 accordion accordion-flush my-0">
                  {/* Audience Control */}
                  <div className='my-3'>
                    <div className='shadow-sm accordion-level-3 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-audienceControl">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Audience Control</h5>
                    </div>

                    <div id='flix-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* View */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#flix-audienceControlView">Who can view your flix?</h5>

                        <div id='flix-audienceControlView' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixView-public" name="flixView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')}  
                                checked={!value.flixView || value.flixView === 'public'} 
                                disabled={isAccountPrivate === 'private'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixView-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixView-followers" name="flixView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.flixView === 'followers'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixView-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixView-follower" name="flixView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.flixView === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixView-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixView-fof" name="flixView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.flixView === 'fof'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixView-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.flixView ? value.flixView : 'public']} view your flix)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.flixView) || (value.flixView === 'public') || (value.flixView === 'followers') || (value.flixView === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.flixView || value.flixView === 'public') ? 'flixViewPublic' : 
                                    value.flixView === 'followers' ? 'flixViewFollowers' : 
                                    value.flixView === 'fof' && 'flixViewFOF'
                                  }
                                  settingType={value.flixView ? value.flixView : 'public'} 
                                  header={
                                    (!value.flixView || value.flixView === 'public') ? 'Hide flix from specific people' :
                                    value.flixView === 'followers' ? 'Followers Except' :
                                    value.flixView === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your flix' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Likes & Comments */}
                  <div className='my-3'>
                    <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-likeAndComments">
                      <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                      <h5 className='fw-semibold ms-3'>Likes & Comments</h5>
                    </div>

                    <div id='flix-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Like Count */}
                      <div className='ms-3 mt-3 me-auto'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm'>Hide like count</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixLike' value={value.flixLike === 'true' ? false : true}
                              onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.flixLike === 'true' ? true : false} 
                            />
                          </div>
                        </div>

                        <small className='text-secondary d-block ms-3 mt-2'>(Confirm if you want to hide the like counts of your flix)</small>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Comments */}
                      <div className='ms-3'>   
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#flix-likeAndComments-Comment">Who can comment on your flix?</h5>

                        <div id='flix-likeAndComments-Comment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixComment-public" name="flixComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.flixComment || (!value.flixComment && value.flixView === 'public') || value.flixComment === 'public'} 
                                disabled={value.flixView === 'followers' || value.flixView === 'fof' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixComment-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixComment-followers" name="flixComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixComment && value.flixView === 'followers') || value.flixComment === 'followers'} 
                                disabled={value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixComment-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixComment-follower" name="flixComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixComment && value.flixView === 'closeFriends') || value.flixComment === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixComment-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixComment-fof" name="flixComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixComment && value.flixView === 'fof') || value.flixComment === 'fof'} 
                                disabled={value.flixView === 'followers' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixComment-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.flixComment ? value.flixComment : 'public']} comment on your flix)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.flixComment) || (value.flixComment === 'public') || (value.flixComment === 'followers') || (value.flixComment === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.flixComment || value.flixComment === 'public') ? 'flixCommentPublic' : 
                                    value.flixComment === 'followers' ? 'flixCommentFollowers' : 
                                    value.flixComment === 'fof' && 'flixCommentFOF'
                                  }
                                  settingType={value.flixComment ? value.flixComment : 'public'} 
                                  header={
                                    (!value.flixComment || value.flixComment === 'public') ? 'Hide flix comment from specific people' :
                                    value.flixComment === 'followers' ? 'Followers Except' :
                                    value.flixComment === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your flix comment' 
                                  commentNote={true}
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Share & Download */}
                  <div className='my-3'>
                    <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-shareAndDownload">
                      <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                      <h5 className='fw-semibold ms-3'>Share & Download</h5>
                    </div>

                    <div id='flix-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Share */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-share">Who can share your flix?</h5>

                        <div id='shareAndDownload-share' className='row accordion-collapse show collapse mt-3 ms-3 me-0'> 
                          {/* value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixShare-public" name="flixShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.flixShare || value.flixShare === 'public'}
                                disabled={value.flixView === 'followers' || value.flixView === 'fof' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixShare-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixShare-followers" name="flixShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixShare && value.flixView === 'followers') || value.flixShare === 'followers'} 
                                disabled={value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixShare-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixShare-cf" name="flixShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixShare && value.flixView === 'closeFriends') || value.flixShare === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixShare-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixShare-fof" name="flixShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixShare && value.flixView === 'fof')  || value.flixShare === 'fof'} 
                                disabled={value.flixView === 'followers' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixShare-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.flixShare ? value.flixShare : 'public']} share your flix.)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.flixShare) || (value.flixShare === 'public') || (value.flixShare === 'followers') || (value.flixShare === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.flixShare || value.flixShare === 'public') ? 'flixSharePublic' : 
                                    value.flixShare === 'followers' ? 'flixShareFollowers' : 
                                    value.flixShare === 'fof' && 'flixShareFOF'
                                  }
                                  settingType={value.flixShare ? value.flixShare : 'public'} 
                                  header={
                                    (!value.flixShare || value.flixShare === 'public') ? 'Hide flix share from specific people' :
                                    value.flixShare === 'followers' ? 'Followers Except' :
                                    value.flixShare === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your flix share' 
                                />
                              </>
                            }
                          </>
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>

                      {/* flix share to story */}
                      <div className='ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow sharing of your flix on stories</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShareToStory' onChange={(e) => handleOptionChange(e, 'privacy')} 
                              value={value.flixShareToStory === 'false' ? 'true' : 'false'}
                              checked={!value.flixShareToStory || value.flixShareToStory === 'true'} 
                            />
                          </div>
                        </div>                      

                        <span className='ms-3'>Allowing this will amplify your reach and will also foster a creative ecosystem where new content can be produced using elements of your content.</span>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Download */}
                      <div className='ms-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#flix-shareAndDownload-download">Who can download your flix?</h5>

                        <div id='flix-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixDownload-public" name="flixDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.flixDownload || value.flixDownload === 'public'} 
                                disabled={value.flixView === 'followers' || value.flixView === 'fof' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixDownload-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixDownload-followers" name="flixDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixDownload && value.flixView === 'followers') || value.flixDownload === 'followers'} 
                                disabled={value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixDownload-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixDownload-follower" name="flixDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixDownload && value.flixView === 'closeFriends') || value.flixDownload === 'closeFriends'} />
                              <label className="form-check-label" htmlFor="ps-flixDownload-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixDownload-fof" name="flixDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.flixDownload && value.flixView === 'fof') || value.flixDownload === 'fof'} 
                                disabled={value.flixView === 'followers' || value.flixView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-flixDownload-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-flixDownload-following" name="flixDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.flixDownload === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-flixDownload-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.flixDownload ? value.flixDownload : 'public']} download your flix)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.flixDownload) || (value.flixDownload === 'public') || (value.flixDownload === 'followers') || (value.flixDownload === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.flixDownload || value.flixDownload === 'public') ? 'flixDownloadPublic' : 
                                    value.flixDownload === 'followers' ? 'flixDownloadFollowers' : 
                                    value.flixDownload === 'fof' && 'flixDownloadFOF'
                                  }
                                  settingType={value.flixDownload ? value.flixDownload : 'public'} 
                                  header={
                                    (!value.flixDownload || value.flixDownload === 'public') ? 'Hide flix download from specific people' :
                                    value.flixDownload === 'followers' ? 'Followers Except' :
                                    value.flixDownload === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your flix download' 
                                />
                              </>
                            }
                          </>
                        </div>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Ad Promotion or Sponsoring */}
                      <div className='ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow Ad Promotion or Sponsoring of your FLIX by other EMILO users</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' />
                          </div>
                        </div>

                        <span className='ms-3'>Allowing other users to use your Flix in Ad promotion or for sponsoring , will allow users to Boost or Sponsor your flix in their Page or Profile. For this, Your account should be Public & you will not be paid for it.</span>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>

                  {/* Remix */}
                  <div className='my-3'>
                    <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-remix">
                      <img src={`${BASE_URL_STATIC}/icon/remix.svg`} alt="remix" />
                      <h5 className='fw-semibold ms-3'>Remix</h5>
                    </div>

                    <div id='flix-remix' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* use your flix */}
                      <div className='ms-3 mt-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow other EMILO users to use your flix</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixRemix' onChange={(e) => handleOptionChange(e, 'privacy')}
                              value={value.flixRemix === 'false' ? 'true' : 'false'}
                              checked={!value.flixRemix || value.flixRemix === 'true'} 
                            />
                          </div>
                        </div>
 
                        <div className='ms-3'>
                          <span>By default, your selected audience for the flix will be able to use your flix. However, if you want to disallow specific people from using your flix, you can select their names below.</span>

                          <SettingInputUser settingNav='privacy' settingKey='flixRemix' settingType='other' />
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                            
              {/* --- Bestclip --- */}
              <div className='ms-3 mt-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#ap-cp-bestclip">
                  <img src={`${BASE_URL_STATIC}/icon/bestclip.svg`} alt="bestclip" />
                  <h5 className='fw-semibold ms-3'>Bestclip</h5>
                </div>

                <div id='ap-cp-bestclip' className="accordion-collapse collapse show accordion accordion-flush my-0 ms-3">
                  {/* Audience Control */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-audienceControl">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Audience Control</h5>
                    </div>

                    <div id='bestclip-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* View */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#bc-audienceControl-view">Who can view your bestclip?</h5>

                        <div id='bc-audienceControl-view' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipView-public" name="bestclipView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.bestclipView || value.bestclipView === 'public'}  
                                disabled={isAccountPrivate === 'private'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipView-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipView-followers" name="bestclipView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.bestclipView === 'followers'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipView-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipView-follower" name="bestclipView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.bestclipView === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipView-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipView-fof" name="bestclipView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.bestclipView === 'fof'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipView-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.bestclipView ? value.bestclipView : 'public']} view your bestclip)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.bestclipView) || (value.bestclipView === 'public') || (value.bestclipView === 'followers') || (value.bestclipView === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.bestclipView || value.bestclipView === 'public') ? 'bestclipViewPublic' : 
                                    value.bestclipView === 'followers' ? 'bestclipViewFollowers' : 
                                    value.bestclipView === 'fof' && 'bestclipViewFOF'
                                  }
                                  settingType={value.bestclipView ? value.bestclipView : 'public'} 
                                  header={
                                    (!value.bestclipView || value.bestclipView === 'public') ? 'Hide bestclip from specific people' :
                                    value.bestclipView === 'followers' ? 'Followers Except' :
                                    value.bestclipView === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your bestclip' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Likes & Comments */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-likeAndComments">
                      <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                      <h5 className='fw-semibold ms-3'>Likes & Comments</h5>
                    </div>

                    <div id='bestclip-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Like Count */}
                      <div className='mt-3 ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm'>Hide like count</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='bestclipLike' value={value.bestclipLike === 'true' ? false : true}
                              onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.bestclipLike === 'true' ? true : false} 
                            />
                          </div>
                        </div>

                        <small className='text-secondary ms-3 mt-3'>(Confirm if you want to hide the like counts of your bestclip)</small>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Comments */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#bestclip-likeAndComments-comment">Who can comment on your bestclip?</h5>

                        <div id='bestclip-likeAndComments-comment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipComment-public" name="bestclipComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.bestclipComment || (!value.bestclipComment && value.bestclipView === 'public') || value.bestclipComment === "public"} 
                                disabled={value.bestclipView === 'fof' || value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipComment-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipComment-followers" name="bestclipComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipComment && value.bestclipView === 'followers') || value.bestclipComment === "followers"} 
                                disabled={value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipComment-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipComment-follower" name="bestclipComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipComment && value.bestclipView === 'closeFriends') || value.bestclipComment === "closeFriends"} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipComment-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipComment-fof" name="bestclipComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipComment && value.bestclipView === 'fof') || value.bestclipComment === 'fof'} 
                                disabled={value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipComment-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.bestclipComment ? value.bestclipComment : 'public']} comment on your bestclip)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.bestclipComment) || (value.bestclipComment === 'public') || (value.bestclipComment === 'followers') || (value.bestclipComment === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.bestclipComment || value.bestclipComment === 'public') ? 'bestclipCommentPublic' : 
                                    value.bestclipComment === 'followers' ? 'bestclipCommentFollowers' : 
                                    value.bestclipComment === 'fof' && 'bestclipCommentFOF'
                                  }
                                  settingType={value.bestclipComment ? value.bestclipComment : 'public'} 
                                  header={
                                    (!value.bestclipComment || value.bestclipComment === 'public') ? 'Hide bestclip comment from specific people' :
                                    value.bestclipComment === 'followers' ? 'Followers Except' :
                                    value.bestclipComment === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your bestclip comment' 
                                  commentNote={true}
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Share & Download */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#bestclip-shareAndDownload">
                      <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                      <h5 className='fw-semibold ms-3'>Share & Download</h5>
                    </div>

                    <div id='bestclip-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Share */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#shareAndDownload-share">Who can share your bestclip?</h5>

                        <div id='shareAndDownload-share' className='row accordion-collapse show collapse mt-3 ms-3 me-0'> 
                          {/* value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipShare-public" name="bestclipShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.bestclipShare || value.bestclipShare === 'public'}
                                disabled={value.bestclipView === 'followers' || value.bestclipView === 'fof' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipShare-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipShare-followers" name="bestclipShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipShare && value.bestclipView === 'followers') || value.bestclipShare === 'followers'} 
                                disabled={value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipShare-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipShare-cf" name="bestclipShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipShare && value.bestclipView === 'closeFriends') || value.bestclipShare === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipShare-cf">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipShare-fof" name="bestclipShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipShare && value.bestclipView === 'fof')  || value.bestclipShare === 'fof'} 
                                disabled={value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipShare-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.bestclipShare ? value.bestclipShare : 'public']} share your bestclip.)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.bestclipShare) || (value.bestclipShare === 'public') || (value.bestclipShare === 'followers') || (value.bestclipShare === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.bestclipShare || value.bestclipShare === 'public') ? 'bestclipSharePublic' : 
                                    value.bestclipShare === 'followers' ? 'bestclipShareFollowers' : 
                                    value.bestclipShare === 'fof' && 'bestclipShareFOF'
                                  }
                                  settingType={value.bestclipShare ? value.bestclipShare : 'public'} 
                                  header={
                                    (!value.bestclipShare || value.bestclipShare === 'public') ? 'Hide bestclip share from specific people' :
                                    value.bestclipShare === 'followers' ? 'Followers Except' :
                                    value.bestclipShare === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your bestclip share' 
                                />
                              </>
                            }
                          </>
                        </div>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>

                      {/* bestclip share to story */}
                      <div className='ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow sharing of your bestclip on Stories</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='bestclipShareToStory' onChange={(e) => handleOptionChange(e, 'privacy')} 
                              value={value.bestclipShareToStory === 'false' ? 'true' : 'false'}
                              checked={!value.bestclipShareToStory || value.bestclipShareToStory === 'true'} 
                            />
                          </div>
                        </div>

                        <span className='ms-3'>Allowing this will amplify your reach and will also foster a creative ecosystem where new content can be produced using elements of your content.</span>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Download */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#bestclip-shareAndDownload-download">Who can download your bestclip?</h5>

                        <div id='bestclip-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipDownload-public" name="bestclipDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.bestclipDownload || (!value.bestclipDownload && value.bestclipView === 'public') || value.bestclipDownload === 'public'}  
                                disabled={value.bestclipView === 'followers' || value.bestclipView === 'fof' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipDownload-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipDownload-followers" name="bestclipDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipDownload && value.bestclipView === 'followers') || value.bestclipDownload === 'followers'} 
                                disabled={value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipDownload-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipDownload-follower" name="bestclipDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipDownload && value.bestclipView === 'closeFriends') || value.bestclipDownload === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipDownload-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipDownload-fof" name="bestclipDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.bestclipDownload && value.bestclipView === 'fof') || value.bestclipDownload === 'fof'} 
                                disabled={value.bestclipView === 'followers' || value.bestclipView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipDownload-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-bestclipDownload-following" name="bestclipDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.bestclipDownload === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-bestclipDownload-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.bestclipDownload ? value.bestclipDownload : 'public']} download your bestclip)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.bestclipDownload) || (value.bestclipDownload === 'public') || (value.bestclipDownload === 'followers') || (value.bestclipDownload === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.bestclipDownload || value.bestclipDownload === 'public') ? 'bestclipDownloadPublic' : 
                                    value.bestclipDownload === 'followers' ? 'bestclipDownloadFollowers' : 
                                    value.bestclipDownload === 'fof' && 'bestclipDownloadFOF'
                                  }
                                  settingType={value.bestclipDownload ? value.bestclipDownload : 'public'} 
                                  header={
                                    (!value.bestclipDownload || value.bestclipDownload === 'public') ? 'Hide bestclip download from specific people' :
                                    value.bestclipDownload === 'followers' ? 'Followers Except' :
                                    value.bestclipDownload === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your bestclip download' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Meme --- */}
              <div className='ms-3 mt-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#ap-cp-meme">
                  <img src={`${BASE_URL_STATIC}/icon/meme.svg`} alt="meme" />
                  <h5 className='fw-semibold ms-3'>Meme</h5>
                </div>

                <div id='ap-cp-meme' className="accordion-collapse collapse show ms-3 accordion accordion-flush my-0">
                  {/* Audience Control */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-audienceControl">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Audience Control</h5>
                    </div>

                    <div id='meme-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* View */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#meme-audienceControl-view">Who can view your memes?</h5>

                        <div id='meme-audienceControl-view' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeView-public" name="memeView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.memeView || value.memeView === 'public'}  
                                disabled={isAccountPrivate === 'private'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeView-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeView-followers" name="memeView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.memeView === 'followers'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeView-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeView-follower" name="memeView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.memeView === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeView-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeView-fof" name="memeView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.memeView === 'fof'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeView-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.memeView ? value.memeView : 'public']} view your meme)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.memeView) || (value.memeView === 'public') || (value.memeView === 'followers') || (value.memeView === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.memeView || value.memeView === 'public') ? 'memeViewPublic' : 
                                    value.memeView === 'followers' ? 'memeViewFollowers' : 
                                    value.memeView === 'fof' && 'memeViewFOF'
                                  }
                                  settingType={value.memeView ? value.memeView : 'public'} 
                                  header={
                                    (!value.memeView || value.memeView === 'public') ? 'Hide meme from specific people' :
                                    value.memeView === 'followers' ? 'Followers Except' :
                                    value.memeView === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your meme' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Likes & Comments */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-likeAndComments">
                      <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                      <h5 className='fw-semibold ms-3'>Likes & Comments</h5>
                    </div>

                    <div id='meme-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Like Count */}
                      <div className='ms-3 mt-3'>
                        <div className='d-flex justify-content-between align-items-center my-2'>
                          <h5 className='accordion-level-4 shadow-sm'>Hide like count</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeLike' value={value.memeLike === 'true' ? false : true}
                              onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.memeLike === 'true' ? true : false} 
                            />
                          </div>
                        </div>

                        <small className='text-secondary ms-3 mt-3 d-block'>(Confirm if you want to hide the like counts of your meme)</small>
                      </div>

                      <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                      {/* Comments */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#meme-likeAndComments-comments">Who can comment on your memes?</h5>

                        <div id='meme-likeAndComments-comments' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeComment-public" name="memeComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.memeComment || (!value.memeComment && value.memeView === 'public') || value.memeComment === 'public'}  
                                disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeComment-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeComment-followers" name="memeComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeComment && value.memeView === 'followers') || value.memeComment === 'followers'} 
                                disabled={value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeComment-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeComment-follower" name="memeComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeComment && value.memeView === 'closeFriends') || value.memeComment === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeComment-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeComment-fof" name="memeComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeComment && value.memeView === 'fof') || value.memeComment === 'fof'} 
                                disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeComment-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.memeComment ? value.memeComment : 'public']} comment on your meme)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.memeComment) || (value.memeComment === 'public') || (value.memeComment === 'followers') || (value.memeComment === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.memeComment || value.memeComment === 'public') ? 'memeCommentPublic' : 
                                    value.memeComment === 'followers' ? 'memeCommentFollowers' : 
                                    value.memeComment === 'fof' && 'memeCommentFOF'
                                  }
                                  settingType={value.memeComment ? value.memeComment : 'public'} 
                                  header={
                                    (!value.memeComment || value.memeComment === 'public') ? 'Hide meme comment from specific people' :
                                    value.memeComment === 'followers' ? 'Followers Except' :
                                    value.memeComment === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your meme comment' 
                                  commentNote={true}
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Share & Download */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload">
                      <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                      <h5 className='fw-semibold ms-3'>Share & Download</h5>
                    </div>

                    <div id='meme-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Share */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload-share">Who can share your memes?</h5>

                        <div id='meme-shareAndDownload-share' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeShare-public" name="memeShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.memeShare || (!value.memeShare && value.memeView === 'public') || value.memeShare === 'public'}  
                                disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeShare-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeShare-followers" name="memeShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeShare && value.memeView === 'followers') || value.memeShare === 'followers'} 
                                disabled={value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeShare-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeShare-follower" name="memeShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeShare && value.memeView === 'closeFriends') || value.memeShare === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeShare-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeShare-fof" name="memeShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeShare && value.memeView === 'fof') || value.memeShare === 'fof'} 
                                disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeShare-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.memeShare ? value.memeShare : 'public']} share your meme)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.memeShare) || (value.memeShare === 'public') || (value.memeShare === 'followers') || (value.memeShare === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.memeShare || value.memeShare === 'public') ? 'memeSharePublic' : 
                                    value.memeShare === 'followers' ? 'memeShareFollowers' : 
                                    value.memeShare === 'fof' && 'memeShareFOF'
                                  }
                                  settingType={value.memeShare ? value.memeShare : 'public'} 
                                  header={
                                    (!value.memeShare || value.memeShare === 'public') ? 'Hide meme share from specific people' :
                                    value.memeShare === 'followers' ? 'Followers Except' :
                                    value.memeShare === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your meme share' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* Download */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload-download">Who can download your memes?</h5>

                        <div id='meme-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeDownload-public" name="memeDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.memeDownload || (!value.memeDownload && value.memeView === 'public') || value.memeDownload === 'public'} 
                                disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeDownload-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeDownload-followers" name="memeDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeDownload && value.memeView === 'followers') || value.memeDownload === 'followers'} 
                                disabled={value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeDownload-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeDownload-follower" name="memeDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeDownload && value.memeView === 'closeFriends') || value.memeDownload === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeDownload-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeDownload-fof" name="memeDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.memeDownload && value.memeView === 'fof') || value.memeDownload === 'fof'} 
                                disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-memeDownload-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-memeDownload-following" name="memeDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.memeDownload === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-memeDownload-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.memeDownload ? value.memeDownload : 'public']} download your meme)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.memeDownload) || (value.memeDownload === 'public') || (value.memeDownload === 'followers') || (value.memeDownload === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.memeDownload || value.memeDownload === 'public') ? 'memeDownloadPublic' : 
                                    value.memeDownload === 'followers' ? 'memeDownloadFollowers' : 
                                    value.memeDownload === 'fof' && 'memeDownloadFOF'
                                  }
                                  settingType={value.memeDownload ? value.memeDownload : 'public'} 
                                  header={
                                    (!value.memeDownload || value.memeDownload === 'public') ? 'Hide meme download from specific people' :
                                    value.memeDownload === 'followers' ? 'Followers Except' :
                                    value.memeDownload === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your meme download' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* meme share to story */}
                      <div className='ms-3 mt-3'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow sharing of your memes on stories</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeShareToStory' onChange={(e) => handleOptionChange(e, 'privacy')} 
                              value={value.memeShareToStory === 'false' ? 'true' : 'false'}
                              checked={!value.memeShareToStory || value.memeShareToStory === 'true'} 
                            />
                          </div>
                        </div>
 
                        <div className='ms-4'>
                          <span>By default, your selected audience for the MEMES will be able to share your MEMES on their stories. However, if you want to disallow specific people from using your MEMES, you can select their names below.</span>

                          <SettingInputUser settingNav='privacy' settingKey='memeShareToStory' settingType='other' />
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>
                    </div>
                  </div>

                  {/* Other Permission */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-otherPermission">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Other Permission</h5>
                    </div>

                    <div id='meme-otherPermission' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* used in templates */}
                      <div className='ms-3'>
                        <div className='d-flex justify-content-between align-items-center my-3'>
                          <h5 className='accordion-level-4 shadow-sm me-5'>Allow your memes to be used in templates</h5>

                          <div className="form-check form-switch m-0">
                            <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeUsedInTemplate' onChange={(e) => handleOptionChange(e, 'privacy')} 
                              value={value.memeUsedInTemplate === 'false' ? 'true' : 'false'}
                              checked={!value.memeUsedInTemplate || value.memeUsedInTemplate === 'true'} 
                            />
                          </div>
                        </div>
 
                        <div className='ms-4'>
                          <span>By default, your selected audience for the MEMES will be able to use your MEMES for creating memes. However, if you want to disallow specific people from using your MEMES, you can select their names below.</span>

                          <SettingInputUser settingNav='privacy' settingKey='memeUsedInTemplate' settingType='other' />
                        </div>

                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Stories --- */}
              <div className='ms-3 mt-3'>
                <div className='accordion-level-2 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#ap-cp-stories">
                  <img src={`${BASE_URL_STATIC}/icon/stories.svg`} alt="stories" />
                  <h5 className='fw-semibold ms-3'>Stories</h5>
                </div>

                <div id='ap-cp-stories' className="accordion-collapse collapse show ms-3 accordion accordion-flush my-0">
                  {/* Audience Control */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#stories-audienceControl">
                      <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                      <h5 className='fw-semibold ms-3'>Audience Control</h5>
                    </div>

                    <div id='stories-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* View */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#stories-audienceControl-view">Who can view your stories?</h5>

                        <div id='stories-audienceControl-view' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesView-public" name="storiesView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.storiesView || value.storiesView === 'public'}
                                disabled={isAccountPrivate === 'private'}  
                              />
                              <label className="form-check-label" htmlFor="ps-storiesView-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesView-followers" name="storiesView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesView === 'followers'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesView-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesView-follower" name="storiesView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesView === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesView-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesView-fof" name="storiesView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesView === 'fof'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesView-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.storiesView ? value.storiesView : 'public']} view your stories)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.storiesView) || (value.storiesView === 'public') || (value.storiesView === 'followers') || (value.storiesView === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.storiesView || value.storiesView === 'public') ? 'storiesViewPublic' : 
                                    value.storiesView === 'followers' ? 'storiesViewFollowers' : 
                                    value.storiesView === 'fof' && 'storiesViewFOF'
                                  }
                                  settingType={value.storiesView ? value.storiesView : 'public'} 
                                  header={
                                    (!value.storiesView || value.storiesView === 'public') ? 'Hide stories from specific people' :
                                    value.storiesView === 'followers' ? 'Followers Except' :
                                    value.storiesView === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your stories' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Replies */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#storiesReplies">
                      <img src={`${BASE_URL_STATIC}/icon/replies.svg`} alt="replies" />
                      <h5 className='fw-semibold ms-3'>Replies</h5>
                    </div>

                    <div id='storiesReplies' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Replies */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#stories-storiesReplies-replies">Who can reply on your stories?</h5>

                        <div id='stories-storiesReplies-replies' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesReplies-public" name="storiesReplies" value='public' onChange={(e) => handleOptionChange(e, 'privacy')}  
                                checked={!value.storiesReplies || (!value.storiesReplies && value.storiesView === 'public') || value.storiesReplies === 'public'} 
                                disabled={value.storiesView === 'followers' || value.storiesView === 'fof' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesReplies-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesReplies-followers" name="storiesReplies" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')}  
                                checked={(!value.storiesReplies && value.storiesView === 'followers') || value.storiesReplies === 'followers'}
                                disabled={value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesReplies-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesReplies-follower" name="storiesReplies" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')}  
                                checked={(!value.storiesReplies && value.storiesView === 'closeFriends') || value.storiesReplies === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesReplies-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesReplies-fof" name="storiesReplies" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesReplies && value.storiesView === 'fof') || value.storiesReplies === 'fof'} 
                                disabled={value.storiesView === 'followers' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesReplies-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesReplies-following" name="storiesReplies" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesReplies === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesReplies-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.storiesReplies ? value.storiesReplies : 'public']} reply on your stories)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.storiesReplies) || (value.storiesReplies === 'public') || (value.storiesReplies === 'followers') || (value.storiesReplies === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.storiesReplies || value.storiesReplies === 'public') ? 'storiesRepliesPublic' : 
                                    value.storiesReplies === 'followers' ? 'storiesRepliesFollowers' : 
                                    value.storiesReplies === 'fof' && 'storiesRepliesFOF'
                                  }
                                  settingType={value.storiesReplies ? value.storiesReplies : 'public'} 
                                  header={
                                    (!value.storiesReplies || value.storiesReplies === 'public') ? 'Hide stories replies from specific people' :
                                    value.storiesReplies === 'followers' ? 'Followers Except' :
                                    value.storiesReplies === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your stories replies' 
                                  repliesNote={true}
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Share & Download */}
                  <div className='my-3'>
                    <div className='accordion-level-3 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#stories-shareAndDownload">
                      <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                      <h5 className='fw-semibold ms-3'>Share & Download</h5>
                    </div>

                    <div id='stories-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                      {/* Share */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#stories-shareAndDownload-share">Who can share your stories?</h5>

                        <div id='stories-shareAndDownload-share' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShare-public" name="storiesShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.storiesShare || (!value.storiesShare && value.storiesView === 'public') || value.storiesShare === 'public'}  
                                disabled={value.storiesView === 'followers' || value.storiesView === 'fof' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShare-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShare-followers" name="storiesShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShare && value.storiesView === 'followers') || value.storiesShare === 'followers'} 
                                disabled={value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShare-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShare-follower" name="storiesShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShare && value.storiesView === 'closeFriends') || value.storiesShare === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShare-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShare-fof" name="storiesShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShare && value.storiesView === 'fof') || value.storiesShare === 'fof'} 
                                disabled={value.storiesView === 'followers' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShare-fof">Followers of Followers</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.storiesShare ? value.storiesShare : 'public']} share your stories)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.storiesShare) || (value.storiesShare === 'public') || (value.storiesShare === 'followers') || (value.storiesShare === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.storiesShare || value.storiesShare === 'public') ? 'storiesSharePublic' : 
                                    value.storiesShare === 'followers' ? 'storiesShareFollowers' : 
                                    value.storiesShare === 'fof' && 'storiesShareFOF'
                                  }
                                  settingType={value.storiesShare ? value.storiesShare : 'public'} 
                                  header={
                                    (!value.storiesShare || value.storiesShare === 'public') ? 'Hide stories share from specific people' :
                                    value.storiesShare === 'followers' ? 'Followers Except' :
                                    value.storiesShare === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your stories share' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* Share Stories to their sory */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#stories-shareAndDownload-storiesShareToStory">Who can share your Stories to their Story?</h5>

                        <div id='stories-shareAndDownload-storiesShareToStory' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Vaule */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShareToStory-public" name="storiesShareToStory" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.storiesShareToStory || (!value.storiesShareToStory && value.storiesView === 'public') || value.storiesShareToStory === 'public'}  
                                disabled={value.storiesView === 'followers' || value.storiesView === 'fof' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShareToStory-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShareToStory-followers" name="storiesShareToStory" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShareToStory && value.storiesView === 'followers') || value.storiesShareToStory === 'followers'} 
                                disabled={value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShareToStory-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShareToStory-follower" name="storiesShareToStory" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShareToStory && value.storiesView === 'closeFriends') || value.storiesShareToStory === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShareToStory-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShareToStory-fof" name="storiesShareToStory" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesShareToStory && value.storiesView === 'fof') || value.storiesShareToStory === 'fof'} 
                                disabled={value.storiesView === 'followers' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShareToStory-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesShareToStory-following" name="storiesShareToStory" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesShareToStory === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesShareToStory-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.storiesShareToStory ? value.storiesShareToStory : 'public']} share your stories to their story)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.storiesShareToStory) || (value.storiesShareToStory === 'public') || (value.storiesShareToStory === 'followers') || (value.storiesShareToStory === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.storiesShareToStory || value.storiesShareToStory === 'public') ? 'storiesShareToStoryPublic' : 
                                    value.storiesShareToStory === 'followers' ? 'storiesShareToStoryFollowers' : 
                                    value.storiesShareToStory === 'fof' && 'storiesShareToStoryFOF'
                                  }
                                  settingType={value.storiesShareToStory ? value.storiesShareToStory : 'public'} 
                                  header={
                                    (!value.storiesShareToStory || value.storiesShareToStory === 'public') ? 'Hide stories share to story from specific people' :
                                    value.storiesShareToStory === 'followers' ? 'Followers Except' :
                                    value.storiesShareToStory === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your stories share to story' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>

                      {/* Download */}
                      <div className='ms-3 mt-3'>
                        <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#stories-shareAndDownload-download">Who can download your stories?</h5>

                        <div id='stories-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                          {/* Value */}
                          <div className='row'>
                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesDownload-public" name="storiesDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={!value.storiesDownload || (!value.storiesDownload && value.storiesView === 'public') || value.storiesDownload === 'public'}  
                                disabled={value.storiesView === 'followers' || value.storiesView === 'fof' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesDownload-public">Public</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesDownload-followers" name="storiesDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesDownload && value.storiesView === 'followers') || value.storiesDownload === 'followers'} 
                                disabled={value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesDownload-followers">Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesDownload-follower" name="storiesDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesDownload && value.storiesView === 'closeFriends') || value.storiesDownload === 'closeFriends'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesDownload-follower">Close Friends</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesDownload-fof" name="storiesDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={(!value.storiesDownload && value.storiesView === 'fof') || value.storiesDownload === 'fof'} 
                                disabled={value.storiesView === 'followers' || value.storiesView === 'closeFriends'}
                              />
                              <label className="form-check-label" htmlFor="ps-storiesDownload-fof">Followers of Followers</label>
                            </div>

                            <div className="ms-2 mb-1 col-lg-5">
                              <input className="form-check-input" type="radio" id="ps-storiesDownload-following" name="storiesDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                                checked={value.storiesDownload === 'none'} 
                              />
                              <label className="form-check-label" htmlFor="ps-storiesDownload-following">No one</label>
                            </div>

                            <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.storiesDownload ? value.storiesDownload : 'public']} download your stories)</small>
                          </div>

                          {/* Input */}
                          <>
                            {
                              ((!value.storiesDownload) || (value.storiesDownload === 'public') || (value.storiesDownload === 'followers') || (value.storiesDownload === 'fof'))
                              &&
                              <>
                                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                                <SettingInputUser 
                                  settingNav='privacy' 
                                  settingKey={
                                    (!value.storiesDownload || value.storiesDownload === 'public') ? 'storiesDownloadPublic' : 
                                    value.storiesDownload === 'followers' ? 'storiesDownloadFollowers' : 
                                    value.storiesDownload === 'fof' && 'storiesDownloadFOF'
                                  }
                                  settingType={value.storiesDownload ? value.storiesDownload : 'public'} 
                                  header={
                                    (!value.storiesDownload || value.storiesDownload === 'public') ? 'Hide stories download from specific people' :
                                    value.storiesDownload === 'followers' ? 'Followers Except' :
                                    value.storiesDownload === 'fof' && 'Followers and their followers except'
                                  }
                                  message='You can select name of people whom you don’t want to show your stories download' 
                                />
                              </>
                            }
                          </>

                          <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Activity Control ----- */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#activityControl">
              <img src={`${BASE_URL_STATIC}/icon/activity-control.svg`} alt="activity-control" />
              <h5 className='fw-semibold ms-3'>Activity Control</h5>
            </div>

            <div id='activityControl' className="accordion-collapse collapse" data-bs-parent="#privacyToggle1">
              <div className='mt-3 ms-3'>
                <div className='d-flex justify-content-between align-items-center my-2'>
                  <h5 className='accordion-level-4 shadow-sm me-5'>Who can view you are Online/Active</h5>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='online' onChange={(e) => handleOptionChange(e, 'privacy')} 
                      value={value.online === 'false' ? 'true' : 'false'}
                      checked={!value.online || value.online === 'true'} 
                    />
                  </div>
                </div>

                <small className='text-secondary ms-3 mt-3 d-block'>(Confirm if you want to show you are Online/Active)</small>
              </div>

              <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
            </div>
          </div>

          {/* ----- Chat ----- */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#chat">
              <img src={`${BASE_URL_STATIC}/icon/chat-message.svg`} alt="chat-message" />
              <h5 className='fw-semibold ms-3'>Chat</h5>
            </div>

            <div id='chat' className="accordion-collapse collapse" data-bs-parent="#privacyToggle1">
              {/* Active */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#chat-active">Who can view you are active on chat?</h5>

                <div id='chat-active' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-chatActive-public" name="chatActive" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.chatActive === 'public' || !value.chatActive}  />
                      <label className="form-check-label" htmlFor="ps-chatActive-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-chatActive-followers" name="chatActive" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.chatActive === 'followers'} />
                      <label className="form-check-label" htmlFor="ps-chatActive-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-chatActive-follower" name="chatActive" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.chatActive === 'closeFriends'} />
                      <label className="form-check-label" htmlFor="ps-chatActive-follower">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-chatActive-fof" name="chatActive" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.chatActive === 'fof'} />
                      <label className="form-check-label" htmlFor="ps-chatActive-fof">Followers of Followers</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.chatActive ? value.chatActive : 'public']} view you are active on chat)</small>
                  </div>

                  {/* Input */}
                  <>
                    {
                      ((!value.chatActive) || (value.chatActive === 'public') || (value.chatActive === 'followers') || (value.chatActive === 'fof'))
                      &&
                      <>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                        <SettingInputUser 
                          settingNav='privacy' 
                          settingKey={
                            (!value.chatActive || value.chatActive === 'public') ? 'chatActivePublic' : 
                            value.chatActive === 'followers' ? 'chatActiveFollowers' : 
                            value.chatActive === 'fof' && 'chatActiveFOF'
                          }
                          settingType={value.chatActive ? value.chatActive : 'public'} 
                          header={
                            (!value.chatActive || value.chatActive === 'public') ? 'Hide chat active from specific people' :
                            value.chatActive === 'followers' ? 'Followers Except' :
                            value.chatActive === 'fof' && 'Followers and their followers except'
                          }
                          message='You can select name of people whom you don’t want to show your chat active' 
                        />
                      </>
                    }
                  </>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                </div>
              </div>

              {/* Send Message */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#chat-sendMessage">Who can send messages to you?</h5>

                <div id='chat-sendMessage' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-sendMessage-public" name="sendMessage" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.sendMessage === 'public' || !value.sendMessage}  />
                      <label className="form-check-label" htmlFor="ps-sendMessage-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-sendMessage-followers" name="sendMessage" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.sendMessage === 'followers'} />
                      <label className="form-check-label" htmlFor="ps-sendMessage-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-sendMessage-follower" name="sendMessage" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.sendMessage === 'closeFriends'} />
                      <label className="form-check-label" htmlFor="ps-sendMessage-follower">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-sendMessage-fof" name="sendMessage" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.sendMessage === 'fof'} />
                      <label className="form-check-label" htmlFor="ps-sendMessage-fof">Followers of Followers</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.sendMessage ? value.sendMessage : 'public']} send messages to you)</small>
                  </div>

                  {/* Input */}
                  <>
                    {
                      ((!value.sendMessage) || (value.sendMessage === 'public') || (value.sendMessage === 'followers') || (value.sendMessage === 'fof'))
                      &&
                      <>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                        <SettingInputUser 
                          settingNav='privacy' 
                          settingKey={
                            (!value.sendMessage || value.sendMessage === 'public') ? 'sendMessagePublic' : 
                            value.sendMessage === 'followers' ? 'sendMessageFollowers' : 
                            value.sendMessage === 'fof' && 'sendMessageFOF'
                          }
                          settingType={value.sendMessage ? value.sendMessage : 'public'} 
                          header={
                            (!value.sendMessage || value.sendMessage === 'public') ? 'Hide send message from specific people' :
                            value.sendMessage === 'followers' ? 'Followers Except' :
                            value.sendMessage === 'fof' && 'Followers and their followers except'
                          }
                          message='You can select name of people whom you don’t want to show your send message' 
                        />
                      </>
                    }
                  </>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                </div>
              </div>

              {/* Add In Group */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#chat-addInGroup">Who can add you in Group Chat?</h5>

                <div id='chat-addInGroup' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-addInGroup-public" name="addInGroup" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.addInGroup === 'public' || !value.addInGroup}  />
                      <label className="form-check-label" htmlFor="ps-addInGroup-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-addInGroup-followers" name="addInGroup" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.addInGroup === 'followers'} />
                      <label className="form-check-label" htmlFor="ps-addInGroup-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-addInGroup-follower" name="addInGroup" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.addInGroup === 'closeFriends'} />
                      <label className="form-check-label" htmlFor="ps-addInGroup-follower">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-addInGroup-fof" name="addInGroup" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.addInGroup === 'fof'} />
                      <label className="form-check-label" htmlFor="ps-addInGroup-fof">Followers of Followers</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.addInGroup ? value.addInGroup : 'public']} add you in Group Chat)</small>
                  </div>

                  {/* Input */}
                  <>
                    {
                      ((!value.addInGroup) || (value.addInGroup === 'public') || (value.addInGroup === 'followers') || (value.addInGroup === 'fof'))
                      &&
                      <>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                        <SettingInputUser 
                          settingNav='privacy' 
                          settingKey={
                            (!value.addInGroup || value.addInGroup === 'public') ? 'addInGroupPublic' : 
                            value.addInGroup === 'followers' ? 'addInGroupFollowers' : 
                            value.addInGroup === 'fof' && 'addInGroupFOF'
                          }
                          settingType={value.addInGroup ? value.addInGroup : 'public'} 
                          header={
                            (!value.addInGroup || value.addInGroup === 'public') ? 'Hide add in group from specific people' :
                            value.addInGroup === 'followers' ? 'Followers Except' :
                            value.addInGroup === 'fof' && 'Followers and their followers except'
                          }
                          message='You can select name of people whom you don’t want to show your add in group' 
                        />
                      </>
                    }
                  </>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                </div>
              </div>
            </div>
          </div>

          {/* ----- Tagging And Mention ----- */}
          <div className='mb-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#taggingAndMention">
              <img src={`${BASE_URL_STATIC}/icon/tag-mention.svg`} alt="tag-mention" />
              <h5 className='fw-semibold ms-3'>Tagging And Mention</h5>
            </div>

            <div id='taggingAndMention' className="accordion-collapse collapse" data-bs-parent="#privacyToggle1">
              {/* Tag Post */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#tag-post">Who can tag you on their post?</h5>

                <div id='tag-post' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-public" name="tagPost" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'public' || !value.tagPost}  />
                      <label className="form-check-label" htmlFor="ps-tagPost-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-followers" name="tagPost" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'followers'} />
                      <label className="form-check-label" htmlFor="ps-tagPost-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-following" name="tagPost" value='following' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'following'} />
                      <label className="form-check-label" htmlFor="ps-tagPost-following">Following</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-fof" name="tagPost" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'fof'} />
                      <label className="form-check-label" htmlFor="ps-tagPost-fof">Followers of Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-closeFriend" name="tagPost" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'closeFriends'} />
                      <label className="form-check-label" htmlFor="ps-tagPost-closeFriend">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-tagPost-following" name="tagPost" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.tagPost === 'none'} />
                      <label className="form-check-label" htmlFor="ps-tagPost-following">No one</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.tagPost ? value.tagPost : 'public']} tag you on their post)</small>
                  </div>

                  {/* Input */}
                  <>
                    {
                      ((!value.tagPost) || (value.tagPost === 'public') || (value.tagPost === 'followers') || (value.tagPost === 'fof'))
                      &&
                      <>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                        <SettingInputUser 
                          settingNav='privacy' 
                          settingKey={
                            (!value.tagPost || value.tagPost === 'public') ? 'tagPostPublic' : 
                            value.tagPost === 'followers' ? 'tagPostFollowers' : 
                            value.tagPost === 'fof' && 'tagPostFOF'
                          }
                          settingType={value.tagPost ? value.tagPost : 'public'} 
                          header={
                            (!value.tagPost || value.tagPost === 'public') ? 'Hide tag post from specific people' :
                            value.tagPost === 'followers' ? 'Followers Except' :
                            value.tagPost === 'fof' && 'Followers and their followers except'
                          }
                          message='You can select name of people whom you don’t want to show your tag post' 
                          tagNote={true}
                        />
                      </>
                    }
                  </>
                </div>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Allow tagging after review */}
              <div className='ms-3'>
                <div className='d-flex justify-content-between align-items-center my-2'>
                  <h5 className='accordion-level-4 shadow-sm me-5'>Allow Tagging after review.</h5>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' />
                  </div>
                </div>

                <span className='ms-3'>Review and select audience of Post you are tagged in. If someone tags you in their Post you can review it and manage audience of the Post before it appears on your profile.</span>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Mention Flix */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#mention-flix">Who can mention you on their flix?</h5>

                <div id='mention-flix' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-public" name="mentionFlix" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'public' || !value.mentionFlix}  />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-public">Public</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-followers" name="mentionFlix" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'followers'} />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-followers">Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-following" name="mentionFlix" value='following' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'following'} />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-following">Following</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-fof" name="mentionFlix" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'fof'} />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-fof">Followers of Followers</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-follower" name="mentionFlix" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'closeFriends'} />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-follower">Close Friends</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-mentionFlix-following" name="mentionFlix" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.mentionFlix === 'none'} />
                      <label className="form-check-label" htmlFor="ps-mentionFlix-following">No one</label>
                    </div>

                    <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.mentionFlix ? value.mentionFlix : 'public']} mention you on their flix)</small>
                  </div>

                  {/* Input */}
                  <>
                    {
                      ((!value.mentionFlix) || (value.mentionFlix === 'public') || (value.mentionFlix === 'followers') || (value.mentionFlix === 'fof'))
                      &&
                      <>
                        <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                        <SettingInputUser 
                          settingNav='privacy' 
                          settingKey={
                            (!value.mentionFlix || value.mentionFlix === 'public') ? 'mentionFlixPublic' : 
                            value.mentionFlix === 'followers' ? 'mentionFlixFollowers' : 
                            value.mentionFlix === 'fof' && 'mentionFlixFOF'
                          }
                          settingType={value.mentionFlix ? value.mentionFlix : 'public'} 
                          header={
                            (!value.mentionFlix || value.mentionFlix === 'public') ? 'Hide mention flix from specific people' :
                            value.mentionFlix === 'followers' ? 'Followers Except' :
                            value.mentionFlix === 'fof' && 'Followers and their followers except'
                          }
                          message='You can select name of people whom you don’t want to show your mention flix' 
                        />
                      </>
                    }
                  </>
                </div>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Allow mention after review */}
              <div className='d-flex justify-content-between align-items-center my-2 ms-3'>
                <h5 className='accordion-level-4 shadow-sm me-5'>Allow Mention after review.</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' />
                </div>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Allow mentions in comments */}
              <div className='d-flex justify-content-between align-items-center my-2 ms-3'>
                <h5 className='accordion-level-4 shadow-sm me-5'>Allow mentions in Comments.</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' />
                </div>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Ad Promotion or Sponsoring */}
              <div className='ms-3'>
                <div className='d-flex justify-content-between align-items-center my-2'>
                  <h5 className='accordion-level-4 shadow-sm me-5'>Allow Ad Promotion or Sponsoring of mentioned or tagged contents.</h5>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' />
                  </div>
                </div>

                <span className='ms-3'>Note : - allowing this will allow users to Boost or Sponsor the content in which you are mentioned or tagged.</span>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

              {/* Allow Post or content sharing */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#post-share-in-product">Allow Post or content sharing by Brand and eMILO in their product showcase.</h5>

                <div id='post-share-in-product' className='row accordion-collapse collapse show mt-2 ms-3 me-0'> 
                  <span className='px-0 pb-3'>(In case you tag/mention them on their product or use hashtags in your Post)</span>

                  {/* Value */}
                  <div className='row'>
                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-postShareInProduct-public" name="postShareInProduct" value='alwaysAllow' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.postShareInProduct === 'alwaysAllow' || !value.postShareInProduct}  />
                      <label className="form-check-label" htmlFor="ps-postShareInProduct-public">Always Allow</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-postShareInProduct-followers" name="postShareInProduct" value='dontAllow' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.postShareInProduct === 'dontAllow'} />
                      <label className="form-check-label" htmlFor="ps-postShareInProduct-followers">Don’t Allow</label>
                    </div>

                    <div className="ms-2 mb-1 col-lg-5">
                      <input className="form-check-input" type="radio" id="ps-postShareInProduct-following" name="postShareInProduct" value='allowOnRequest' onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.postShareInProduct === 'allowOnRequest'} />
                      <label className="form-check-label" htmlFor="ps-postShareInProduct-following">Allow  on Request</label>
                    </div>

                    <small className='text-secondary ps-1 pe-0 pt-2'>If your account is ‘Public’ and you have tagged or Mentioned a brand in your Post or Content or used their Hashtag in it, the particular brand and EMILO can share or show that Post or Content in their product showcase as per your permission . And also, you will not be paid for this by EMILO. This is not allowed if your account is ‘Private’.</small>
                  </div>
                </div>
              </div>

              <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />
            </div>
          </div>

          {/* ----- Hide Words ----- */}
          <div className='mb-3' id='hideWords'>
            <div className='accordion-level-1 shadow-sm mb-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#hideWord">
              <img src={`${BASE_URL_STATIC}/icon/hide-words.svg`} alt="hide-words" />
              <h5 className='fw-semibold ms-3'>Hide Words</h5>
            </div>

            <div id='hideWord' className="accordion-collapse collapse ms-3 mt-3 ms-3" data-bs-parent="#privacyToggle1">
              {/* Words, Phrase & emojis */}
              <div>
                <h5 className='accordion-level-4 shadow-sm'>Words, Phrase & emojis</h5>

                <div className='mt-3 ms-3'>
                  <span>You can select inappropriate words, phrases & emojis to hide / filter comments, story replies containing them. Variations and misspellings will be automatically included 	Learn More….</span>

                  {/* Words */}
                  <div className='d-flex my-2'>
                    <input type="text" className='form-control form-control-sm me-3' placeholder='Words...' />

                    <button type="button" className='btn btn-sm btn-primary'>Add</button>
                  </div>

                  {/* Phrases */}
                  <div className='d-flex my-2'>
                    <input type="text" className='form-control form-control-sm me-3' placeholder='Phrases...' />

                    <button type="button" className='btn btn-sm btn-primary'>Add</button>
                  </div>

                  {/* Emojis */}
                  <div className='d-flex my-2'>
                    <input type="text" className='form-control form-control-sm me-3' placeholder='Emojis...' />

                    <button type="button" className='btn btn-sm btn-primary'>Add</button>
                  </div>

                  <span>Apply above filters to : - </span>

                  <div className='row justify-content-between w-100 mx-auto'>
                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-post" defaultChecked />
                      <label className="form-check-label" htmlFor="hide-word-post">Post</label>
                    </div>

                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-flix" />
                      <label className="form-check-label" htmlFor="hide-word-flix">Flix</label>
                    </div>

                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-bestclip" defaultChecked />
                      <label className="form-check-label" htmlFor="hide-word-bestclip">Bestclips</label>
                    </div>

                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-meme" />
                      <label className="form-check-label" htmlFor="hide-word-meme">Memes</label>
                    </div>

                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-story" defaultChecked />
                      <label className="form-check-label" htmlFor="hide-word-story">Stories</label>
                    </div>

                    <div className="form-check d-block float-none col-3 ms-1">
                      <input type="checkbox" className="form-check-input" id="hide-word-chat" />
                      <label className="form-check-label" htmlFor="hide-word-chat">Chats</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Privacy;