import React, { useState, useContext } from 'react';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../config';



const OnboardingPin = () => {

  const [pin, setPin] = useState('');
  const nav = useNavigate(null)

  const { config } = useContext(ApiContext);

  // To handle create soft corner profile
  const handleCreateSoftCornerProfile = (pin) => {
    axios.post(`${BASEAPIURL}/softcorner/createscprofile`, { password: pin }, config)
      .then(res => {
        console.log('create pin : ', res.data);
        localStorage.setItem('SCtoken', res.data.token);
        localStorage.setItem('SCuserId', res.data.scUserId);
        nav('/SoftCorner/')
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <section className='onboardingPin'>
        <div className='onboardingPin-area'>
          {/* Emilo Logo */}
          <div className='onboardingPin-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingPin-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingPin-area-data w-100'>
            <h2 className='onboardingPin-area-data-head'>Create PIN</h2>

            <p className='onboardingPin-area-data-message'>Create 4-digit security PIN</p>

            <OtpInput
              value={pin}
              onChange={setPin}
              numInputs={4}
              renderSeparator={<pre> </pre>}
              renderInput={(props) => <input {...props} className='onboardingPin-area-data-pin' style={{ width: '70px' }} />}
            />
          </div>

          {/* Button */}
          <div className='onboardingPin-area-button'>
            <button type="button" className='onboardingPin-area-btn' onClick={() => handleCreateSoftCornerProfile(pin)}>Done</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingPin;
