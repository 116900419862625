import React, { useContext, useEffect,  useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import ProfileImage from '../../UseContext/ProfileImage';
// import AddLocation from './AddLocation';
import { ApiContext } from '../../UseContext/ApiCall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import PostSettings from '../../Button/PostSettings';
import EmojiPopup from '../EmojiPopup';
import parse from 'node-html-parser';
import * as htmlToImage from 'html-to-image';
import NotificationpostPopup from '../Notification/NotificationpostPopup';
import { uploadpostUrl, uploadPost } from '../../UseContext/ApiHelpers';
import { IoCloseCircle } from 'react-icons/io5';
import TagPeople from '../../Button/TagPeople';


const TextPreviewPopoup = ({ selectedAddLocation, handleCancleLocation, setCheckIn, setCheckinType, open, allbg, setAllbg, contentRef, setPageContent, setSelectedEmoji, selectedEmoji, activeIndex, setActiveIndex, bgImages, setShowCreatePostPopup, setSelectedBg, selectedBg, PageContent, close }) => {

   const [postLoader, setPostLoader] = useState(false);
   const [openEmoji, setOpenEmoji] = useState(false)
   const [openInput, setopenInput] = useState(false)
   const [show, setShow] = useState(false);
   const [selectedUser, setSelectedUser] = useState([]);
   const [tagMessage, setTagmessage] = useState([])
    console.log("selectedEmoji", selectedEmoji)
   
   const { loggedInUserId, loggedInUserName, config, setallDataPost } = useContext(ApiContext);

   // const currentpage = useRef(null)
   // console.log("currentpage", currentpage.current);
   const handleEdit = () => {
      setShowCreatePostPopup(true);
      close();
   };

   console.log("PageContent" , PageContent)

   const refs = useRef()
   useEffect(() => {
      if (contentRef.current && PageContent) {

         setSelectedBg(bgImages[1])
         setActiveIndex(0)
      }

   }, [PageContent])

   const dataURLtoBlob = (dataURL) => {
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
         uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
   };

   const handleConvertToPng = async () => {
      setPostLoader(true)
      if (selectedBg && PageContent.length <= 200 && refs.current) {
         htmlToImage.toPng(refs.current, { cacheBust: true })
            .then(async (dataUrl) => {

               const blob = dataURLtoBlob(dataUrl);
               const formData = new FormData();
               formData.append("mediaUrls", blob, 'downloaded-image.png');
               formData.append("postTumbnailUrl", blob, 'downloaded-image.png');
               formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3')
               formData.append("start", 'ssss')
               formData.append("end", 'sss')
               await uploadpostUrl(formData, config).then(async (res) => {
                  const mediaPostData = {
                     title: selectedAddLocation && selectedAddLocation.location,
                     emotion:  selectedEmoji && selectedEmoji.emotion,
                     emotionName:  selectedEmoji && selectedEmoji.emotionName,
                     emotionIcon:  selectedEmoji && selectedEmoji.icon,
                     postTumbnailUrl: res.postTumbnailUrl || '',
                     type: "post",
                     mediaUrls: [{
                        url: res && res.mediaUrls && res.mediaUrls[0].url,
                        filetype: 'image',
                        audio: '',
                        start: '',
                        end: '',
                        tags: selectedUser.map(user => ({
                           tagUserId: user.userId,
                           fname: user.fname,
                           username: user.username,
                           profileImg: user.profileImg,
                           postedBy: loggedInUserId,
                           x: '',
                           y: ''
                        }))
                     }]
                  };

                  const data = await uploadPost(mediaPostData, config)
                  setallDataPost(prev => [data.post, ...prev])
                  close()
                  setPostLoader(false)
               })
            })
            .catch((error) => {
               setPostLoader(false)
               console.error('Error converting HTML to PNG:', error);
            });
      }

      else {
         const mediaPostData = {
            description: PageContent,
            title: (selectedAddLocation &&  selectedAddLocation.location )|| "",
            type: PageContent.length > 0 ? "text" : "emotion",
            emotion: selectedEmoji.emotion || "defaultName",
            emotionName: selectedEmoji.emotionName || "",
            emotionIcon: selectedEmoji.icon || "",
            mediaUrls: [{
               tags: selectedUser.map(user => ({
                  tagUserId: user.userId,
                  fname: user.fname,
                  username: user.username,
                  profileImg: user.profileImg,
                  postedBy: loggedInUserId,
                  x: '',
                  y: ''
               }))
            }]
         };
         const data = await uploadPost(mediaPostData, config)
         setallDataPost(prev => [data.post, ...prev])
         close()
         setPostLoader(false)
      }
   };

   var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 5,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 2
            }
         }
      ]
   };

   const handleText = () => {
      const root = parse(PageContent);
      setPageContent(root.textContent)
   }

   return (

      <>
         <NotificationpostPopup show={show} setShow={setShow} />
         <Modal show={open} onHide={close} keyboard={false} className="create-post" backdrop="static" centered>
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               {
                  openEmoji ? <>
                  <div className="row">
                        <p className="col-1" style={{ cursor: "pointer" }}
                           onClick={() => { setOpenEmoji(false) }}
                        >
                           <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                        </p>
                        <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                           Create Post
                        </p>
                     </div>
                     <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                  </>
                     :
                     <>
                        <div className="row">
                           <p className="col-1" style={{ cursor: "pointer" }}
                              onClick={() => { close(); setShowCreatePostPopup(true) }}
                           >
                              <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                           </p>
                           <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                              Create Post
                           </p>
                        </div>

                        <div className="userDetail">
                           <div className="imgBox" >
                              <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                           </div>

                           <div className="nameBox">
                              <div className="d-flex flex-md-row align-items-center">
                                 <span className="fs-6">
                                    {loggedInUserName}
                                    {selectedEmoji && (
                                       <span className="ms-1 d-inline-block fs-14 text-secondary" onClick={() => setOpenEmoji(true)}>
                                          {selectedEmoji.emotion}
                                          <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                          {selectedEmoji.emotionName}{" "}
                                          <span>{tagMessage}</span>
                                       </span>
                                    )}
                                 </span>
                              </div>

                              <div className="add-location-div">
                                 <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />
                                 {/* <AddLocation location={location} setLocation={setLocation} /> */}
                                 {selectedAddLocation ?
                                    <>
                                       <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }}>{selectedAddLocation.place}</small>
                                       <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }} onClick={handleCancleLocation}> <IoCloseCircle /></span>
                                    </>
                                    :
                                    <small className="cp" onClick={() => { setCheckIn(true); setShowCreatePostPopup(false); setCheckinType("text") }}>Add Location</small>
                                 }
                              </div>

                           </div>
                        </div>
                              <p className='text-primary fw-bold cp text-end mb-2'>
                                 <span onClick={() => setopenInput(!openInput)}>
                                    Tag People
                                 </span>
                              </p>
                        
                        {openInput && <TagPeople setTagmessage={setTagmessage} selectedUser={selectedUser} setSelectedUser={setSelectedUser} config={config} />}

                        
                        {!openInput && PageContent !== "" &&
                           <>
                              <div
                                 className='html_png'
                                 ref={refs}
                                 style={{
                                    backgroundImage: allbg && PageContent.length < 200 && selectedBg.img ? `url(${selectedBg.img})` : 'red',
                                    backgroundSize: 'cover', // or 'contain' depending on your needs
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    Height: '200px'
                                 }}
                              >
                                 <div
                                    onClick={handleEdit}
                                    className="bg_style"
                                    style={{ overflow: 'hidden' }} // Hide scrollbar
                                 >
                                    <div
                                       className={`postBox position-relative ${!allbg ? "postBox_1" : ""}`}
                                       style={{ overflow:'hidden'}} // Hide scrollbar
                                    >
                                       <div
                                          className={`text-editor2 ${PageContent.length < 200 ? `page_edit1` : 'page_edit2'}`}
                                       >
                                          <div className="text_opener" dangerouslySetInnerHTML={{ __html: PageContent }}></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>


                              <div className="bg_image_head">
                                 {PageContent.length < 200 && allbg && <div className="slider-container">
                                    <Slider {...settings}>
                                       <span className="no_bg" onClick={() => { setAllbg(false); handleText(); }}>
                                          <img src={bgImages[0].img} alt="" />
                                       </span>
                                       {bgImages.map((e, i) => (
                                          <div
                                             key={i}
                                             className="bg-images"
                                             onClick={() => {
                                                setSelectedBg(e);
                                                setActiveIndex(i);
                                             }}
                                          >
                                             <img src={e.img} alt="" style={{
                                                border: activeIndex === i && "2px solid #64A6F0",
                                             }} />
                                          </div>
                                       ))}
                                    </Slider></div>}
                              </div>

                           </>}

                        <PostSettings />

                        <div className='col-8 m-auto'>
                           <button onClick={handleConvertToPng} className='text_post_btn'>{postLoader ? 'posting...' : "post"}</button>
                        </div>


                     </>}

            </Modal.Body>

         </Modal>
      </>
   );
};

export default TextPreviewPopoup;