import React, { useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';
import { useLocation } from 'react-router-dom';
import { ApiContext } from '../UseContext/ApiCall';
import { ScApiContext } from '../../SoftCorner/useContext/softCornerContext';

const MobileFooter = () => {

  const { config, handleSoftCornerEntry, isInSoftCorner } = useContext(ApiContext);

  const { btnEnable } = useContext(ScApiContext);

  const navigate = useNavigate();

  let location = useLocation();

  const pathParts = location.pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];

  // console.log('last part ', lastPart);

  useEffect(() => {
    handleSoftCornerEntry(config);
  }, []);


  const emiloUrls = ['login', 'signup', 'chats', 'basic-info', 'business'];
  
  const scUrls = ['OnboardingUser', 'OnboardingMobile', 'OnboardingOTP', 'OnboardingImage', 'OnboardingGender', 'OnboardingDOB', 'OnboardingPin', 'checkSoftCornerPin', 'forgotSoftcornerPin', 'chat'];

  return (
    <>
      {/* Emilo > Mobile > Footer */}
      <>
        {
          (!window.location.href.includes('SoftCorner'))
          &&
          <section className={`${emiloUrls.some(url => window.location.href.includes(url)) ? 'd-none' : 'd-flex'} d-lg-none justify-content-evenly w-100 text-center p-1 rounded-pill shadow position-fixed bottom-0 z-1 bg-white`}>
            <Link to={`/memes`}>
              <img className="mf-img" src={`${BASE_URL_STATIC}/icon/mf-meme.svg`} alt="Memes" />
              <p className="mf-text">Memes</p>
            </Link>

            <Link to={`/reels`}>
              {
                lastPart === 'reels'
                ?
                <img className="mf-img" src={`${BASE_URL_STATIC}/icon/profile-flix-2.svg`} alt="Flix" />
                :
                <img className="mf-img" src={`${BASE_URL_STATIC}/icon/mf-flix.svg`} alt="Flix" />
              }
              <p className="mf-text">Flix</p>
            </Link>

            {
              isInSoftCorner
              ?
              <Link to={`/SoftCorner/checkSoftCornerPin`}>
                <img className="mf-img" src={`${BASE_URL_STATIC}/top-icon3.png`} alt="Soft Corner" />
                <p className="mf-text">Soft Corner</p>
              </Link>
              :
              <Link to={`/SoftCorner/OnboardingUser`}>
                <img className="mf-img" src={`${BASE_URL_STATIC}/top-icon3.png`} alt="Soft Corner" />
                <p className="mf-text">Soft Corner</p>
              </Link>
            }

            <Link to={`/bestclips`}>
              {
                lastPart === 'bestclips'
                ?
                <img className="mf-img" src={`${BASE_URL_STATIC}/icon/profile-bestClip-2.svg`} alt="Best Clips" />
                :
                <img className="mf-img" src={`${BASE_URL_STATIC}/icon/mf-bestclip.svg`} alt="Best Clips" />
              }
              <p className="mf-text">Best Clips</p>
            </Link>

            <Link to={`/chats`}>
              <img className="mf-img" src={`${BASE_URL_STATIC}/icon/mf-chat.svg`} alt="Chats" />
              <p className="mf-text">Chats</p>
            </Link>
          </section>
        }
      </>

      {/* Softcorner > Mobile > Footer */}
      <>
        {
          (window.location.href.includes('SoftCorner'))
          &&
          <section className={`${scUrls.some(url => window.location.href.includes(url)) ? 'd-none' : 'd-flex'} d-lg-none justify-content-evenly w-100 text-center p-1 rounded-pill shadow position-fixed bottom-0 z-1 bg-white`}>
            {/* Home */}
            <Link to={`/SoftCorner`}>
              <img className="mf-img" src={`https://demo3.sjainventures.com/e-milo/images/SoftCorner/lb-home.png`} alt="Chats" />
              <p className="mf-text">Home</p>
            </Link>

            {/* Gallary */}
            <button className='p-0 border-0 bg-transparent' onClick={() => navigate(`/SoftCorner/Gallary`)} disabled={btnEnable}>
              <img className="mf-img" src={`https://demo3.sjainventures.com/e-milo/images/SoftCorner/lb-gallary.png`} alt="Best Clips" />
              <p className={`${btnEnable && 'text-secondary'} mf-text`}>Gallary</p>
            </button>

            {/* Date Planner */}
            <button className='p-0 border-0 bg-transparent' onClick={() => navigate(`/SoftCorner/DatePlanner-1`)} to={``} disabled={btnEnable}>
              <img className="mf-img" src={`https://demo3.sjainventures.com/e-milo/images/SoftCorner/lb-datePlanner.png`} alt="Soft Corner" />
              <p className={`${btnEnable && 'text-secondary'} mf-text`}>Date Planner</p>
            </button>

            {/* Special Days */}
            <button className='p-0 border-0 bg-transparent' onClick={() => navigate(`/SoftCorner/SpecialDays`)} disabled={btnEnable}>
              <img className="mf-img" src={`https://demo3.sjainventures.com/e-milo/images/SoftCorner/lb-specialDays.png`} alt="Flix" />
              <p className={`${btnEnable && 'text-secondary'} mf-text`}>Special Days</p>
            </button>

            {/* Life Partner */}
            <button className='p-0 border-0 bg-transparent' onClick={() => navigate(`/SoftCorner/LifePartner`)} disabled={btnEnable}>
              <img className="mf-img" src={`https://demo3.sjainventures.com/e-milo/images/SoftCorner/lb-lifePartner.png`} alt="Memes" />
              <p className={`${btnEnable && 'text-secondary'} mf-text`}>Life Partner</p>
            </button>
          </section>
        }
      </>
    </>
  )
}

export default MobileFooter
