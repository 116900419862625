import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";

const ProfileMemeCommentThreeDotPopup = ({ showProfileMemeCommentThreeDotPopup, setShowProfileMemeCommentThreeDotPopup, comment, getMemeComment }) => {

  const { config, loggedInUserId } = useContext(ApiContext);

  // console.log(comment);

    // To delete meme comment 
    const deleteMemeComment = (memeId, parentCommentId, config) => {
      axios.post('https://emilonode.sjain.io/post/deletememecomment', { memeId: memeId, parentMemeCommentId: parentCommentId }, config)
      .then(res => {
        console.log('delete comment : ', res.data.message);
        setShowProfileMemeCommentThreeDotPopup(false);
        getMemeComment(memeId, config);

        // To delete notification
        axios.post('https://emilonode.sjain.io/user/deletecommenteminnotify', { memeId: memeId, parentCommentId: parentCommentId }, config)
        .then(res => {
          console.log('delete comment notification : ', res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
      })
      .catch(err => {
        console.log(err);
      });
    };

  return (
    <>
      {
        comment
        &&
        comment.commentUserId === loggedInUserId
        ?
        <Modal show={showProfileMemeCommentThreeDotPopup} onHide={() => setShowProfileMemeCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter"  centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text profilePostThreeDotPopup-text-1" onClick={() => deleteMemeComment(comment.memeId, comment.parentCommentId, config)}>Delete Comment</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfileMemeCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
        :
        <Modal show={showProfileMemeCommentThreeDotPopup} onHide={() => setShowProfileMemeCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text" onClick={() => console.log('report')}>Report</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfileMemeCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default ProfileMemeCommentThreeDotPopup
