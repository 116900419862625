import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const OnboardingImage = () => {

  // const uploadLiveImageRef = useRef(null);

  // Handle click
  // const handleClick = () => {
  //   uploadLiveImageRef.current.click();
  // };

  // // Handle file select
  // const handleFileChange = (e) => {
  //   const liveImage = e.target.files[0];

  //   console.log('Live Image : ' + URL.createObjectURL(liveImage));
  // };

  return (
    <>
      <section className='onboardingImage'>
        <div className='onboardingImage-area'>
          {/* Emilo Logo */}
          <div className='onboardingImage-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingImage-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingImage-area-data'>
            <h2 className='onboardingImage-area-data-head'>Verify Live Image</h2>

            <p className='onboardingImage-area-data-message'>Make sure that the entire face is in view, including the ears, so that nothing is obstructing or hidden from sight.</p>

            <img src={`${BASE_URL_STATIC}/SoftCorner/verify-live-image.png`} alt="verify-live" height={200} width={200} />
          </div>

          {/* Button */}
          <div className='onboardingImage-area-button'>
            <Link to={`/SoftCorner/OnboardingGender`}>
              <button type="button" className='onboardingImage-area-btn' >Scan My Face</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingImage;
