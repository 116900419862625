import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessMissionVisionPopup = () => {

  const { showMissionVisionPopup, setShowMissionVisionPopup, pageAbout, setPageAbout, createMissionVission } = useContext(businessContext);

  return (
    <>
      <Modal show={showMissionVisionPopup} onHide={() => setShowMissionVisionPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Mission & Vision</h4>

          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm'>Mission</span>

          <textarea id="businessMission" className="form-control mt-3 mb-4 ms-3" style={{ height: '150px' }} placeholder='Write your mission here...'
            value={pageAbout.mission} onChange={(e) => setPageAbout((prevState) => ({ ...prevState, mission: e.target.value, }))}
          ></textarea>

          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm'>Vision</span>

          <textarea id="businessVission" className="form-control my-3 ms-3" style={{ height: '150px' }} placeholder='Write your vision here...'
            value={pageAbout.vission} onChange={(e) => setPageAbout((prevState) => ({ ...prevState, vission: e.target.value, }))}
          ></textarea>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createMissionVission}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessMissionVisionPopup;