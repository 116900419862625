import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../../config';

const VideoCallComingPopup = () => {

  const [showVideoCallComingPopup, setShowVideoCallComingPopup] = useState(false);

  return (
    <>
      <section className='videoCallComingPopups'>
      <Modal show={showVideoCallComingPopup} onHide={() => setShowVideoCallComingPopup(false)} className="main-modal videoCallComingPopup-modal" size="lg" centered backdrop="static" keyboard={false} >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body videoCallComingPopup-body">
            <section className='videoCallComingPopup'>
              {/* Logo */}
              <div className='vccp-logo'>
                <img className='vccp-logo-img' src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
              </div>

              {/* Person */}
              <div className='vccp-person'>
                <div className='vccp-person-1'>
                  <div className='vccp-person-1-image'>
                    <img src="https://img.indiaforums.com/person/480x360/1/1581-kiara-advani.jpg?c=8xOB8D" alt="profile" />
                  </div>

                  <span className='vccp-person-1-name'>Kiara Advani</span>
                </div>

                <div className='vccp-person-2'>
                  <video className='vccp-person-2-video' autoPlay loop>
                    <source src={`${BASE_URL_STATIC}/videos/videoCall-2.webm`} type="video/webm" />
                  </video>
                </div>
              </div>

              {/* Buttons */}
              <div className='vccp-btns'>
                <div className='vccp-btn-attend'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneAttend.svg`} alt="phoneCut" />
                </div>

                <div className='vccp-btn-cut'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneCut.svg`} alt="phoneCut" />
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default VideoCallComingPopup
