import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import Slider from 'react-slick/lib/slider';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from '../../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

const PhotoVideoTags = ({ photoVideos, tagsByCarouselItem, setShowStoryPhotoVideoPopup, setTagsByCarouselItem, config, setPhotoVideos }) => {

  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [openInput, setopenInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [xVal, setxVal] = useState(null);
  const [loader, setLoader] = useState(false);
  const [yVal, setyVal] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const inputref = useRef()
  const carouselRef = useRef(null);
  const audioRef = useRef([]);

  const settings = {
    dots: true, 
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    beforeChange: () => {
      setopenInput(false)
    },
  };

  const handleTag = (event) => {
    if (inputref.current) {
      inputref.current.focus();
    }
    setopenInput(!openInput);
    const rect = event.target.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;
    setxVal(x);
    setyVal(y);
    setPhotoVideos((prevState) =>
      prevState.map((item) => {
        return { ...item, isPlaying: false };
      })
    );
  };

  function handleDel(carouselIndex, tagIndex) {
    const updatedTags = [...tagsByCarouselItem];
    const currentTags = updatedTags[carouselIndex];

    if (currentTags && currentTags.length > tagIndex) {
      updatedTags[carouselIndex] = currentTags.filter(
        (_, index) => index !== tagIndex
      );

      // Update the state
      setTagsByCarouselItem(updatedTags);
    }
  };


  const clickTagUser = async (user) => {
    setInputValue("");
    setopenInput(false);

    // Conditionally set x and y values based on the type of media
    const x =
      photoVideos &&
        photoVideos[activeCarouselIndex] && photoVideos[activeCarouselIndex].type === "video/mp4"
        ? null
        : xVal;
    const y =
      photoVideos &&
        photoVideos[activeCarouselIndex] && photoVideos[activeCarouselIndex].type === "video/mp4"
        ? null
        : yVal;

    const updatedTags = [...tagsByCarouselItem];
    updatedTags[activeCarouselIndex] = [
      ...(updatedTags[activeCarouselIndex] || []),
      {
        user,
        xVal: x,
        yVal: y,
        tagUserId: user.userId,
        fname: user.fname,
        username: user.username,
        profileImg: user.profileImg,
        index: activeCarouselIndex,
        videoTag:
          photoVideos[activeCarouselIndex].type.includes("video"),
      },
    ];

    setTagsByCarouselItem(updatedTags);
    setSearchList([]);
  };

  const handleDelete = () => {

    if (Array.isArray(photoVideos) && photoVideos.length === 1) {
      setShowStoryPhotoVideoPopup(false)
      setPhotoVideos([]);
    }

    else if (Array.isArray(photoVideos) && photoVideos.length > 1) {
      const updatedFiles = [...photoVideos];
      updatedFiles.splice(activeIndex, 1); // Remove the file at the active index
      setPhotoVideos(updatedFiles);
      setActiveIndex(prev => prev - 1);
    }
  };


  // To handle play pause
  const handlePlayPause = (index) => {
    setPhotoVideos((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, isPlaying: !item.isPlaying };
        }
        return { ...item, isPlaying: false };
      })
    );
  };


  const handleNextRef = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext();
      setActiveCarouselIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevRef = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev();
      setActiveCarouselIndex((prevIndex) => prevIndex - 1);
    }
  };

  // To handle mute unmute
  const handleMuteUnmute = (index) => {
    photoVideos((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, isMute: !item.isMute };
        }
        return item;
      })
    );
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 0) {
      setLoader(true);
      axios
        .post(
          `${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config
        )
        .then((res) => {
          setSearchList(res.data.users);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoader(false);
        });
    } else {
      setSearchList([]);
      setLoader(false);
    }
  };

  // To handle select audio
  const handleSelectAudio = (index) => {
    audioRef.current[index].click();
  };

  // To handle audio change
  const handleAudioChange = (e, photoVideo, index) => {
    const files = e.target.files;

    photoVideos((prevState) =>
      prevState.map((item, idx) =>
        idx === index ? { ...item, audio: files[0] } : item
      )
    );
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
    setIsScrolling(false); // Assume scrolling will start
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    if (!isScrolling) {
      setIsScrolling(false); // Ensure scroll is not considered a click
    }
  };

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      setIsScrolling(true); // Mark it as scroll if mouse moves
    }
  };


  return (
    <div className='mainContainer'>

      <div  >
        <Slider ref={carouselRef} {...settings} className="stories-photoVideo mt-4 mb-5">
          {photoVideos.map((photoVideo, index) => (
            <div key={index} onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center mb-3">
                  {/* Select Audio */}
                  <div
                    className="p-2 bg-white rounded-circle shadow me-3 cursor-pointer"
                    onClick={() => handleSelectAudio(index)}
                  >
                    <img src={`/images/icons/music.svg`} style={{ width: "20px" }} alt="music" />
                    <input type="file" ref={(el) => (audioRef.current[index] = el)} style={{ display: "none" }} accept="audio/*" onChange={(e) => handleAudioChange(e, photoVideo, index)} />
                  </div>

                  {/* Mention */}
                  <div className="p-1 bg-white rounded-circle shadow cursor-pointer me-3" onClick={(event) => handleTag(event)} >
                    <img src={`/images/icons/mention.svg`} style={{ width: "30px" }} alt="mention" />
                  </div>

                  {/* Play or Pause */}
                  {photoVideo.type.slice(0, 5) === "video" && (
                    <div className="p-1 bg-white rounded-circle shadow me-3 cursor-pointer" onClick={() => handlePlayPause(index)}>
                      <img src={photoVideo.isPlaying ? `/images/icons/pause.svg` : `/images/icons/play.svg`} style={{ width: "30px" }} alt="Play/Pause" />
                    </div>
                  )}

                  {/* Mute or Unmute */}
                  {photoVideo.type.slice(0, 5) === "video" && (
                    <div className="p-2 bg-white rounded-circle shadow cursor-pointer me-3" onClick={() => handleMuteUnmute(index)}>
                      <img src={photoVideo.isMute ? `/images/icons/mute.svg` : `/images/icons/unmute.svg`} style={{ width: "20px" }} alt="Mute/Unmute" />
                    </div>
                  )}

                  {/* delete */}
                  <div className="p-1 bg-white rounded-circle shadow cursor-pointer me-3" onClick={handleDelete} >
                    <img src={`/images/icons/delete-black.svg`} style={{ width: "30px" }} alt="mention" />
                  </div>

                </div>

                <div className="d-flex align-items-center mb-3 me-2">

                  <button
                    onClick={handlePrevRef}
                    style={{
                      display:
                        activeCarouselIndex <= 0 || !photoVideo || photoVideo.length === 0
                          ? "none"
                          : "block",
                    }}
                    className="prevButton_story shadow"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>

                  <button
                    onClick={handleNextRef}
                    style={{
                      display:
                        activeCarouselIndex >= (photoVideo && photoVideo.length - 1)
                          ? "none"
                          : "block",
                    }}
                    className="nextButton_story shadow"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>


                </div>
              </div>


              {/* Photos */}
              {photoVideo.type.slice(0, 5) === "image" && (
                <div onClick={(event) => handleTag(event)} style={{ cursor: 'crosshair' }}>
                  <img src={URL.createObjectURL(photoVideo.file)} className="rounded-4 mx-auto" alt="story" />
                </div>
              )}

              {/* Videos */}
              {photoVideo.type.slice(0, 5) === "video" && (
                <ReactPlayer
                  className="bg-secondary-subtle w-100"
                  url={URL.createObjectURL(photoVideo.file)}
                  playing={photoVideo.isPlaying}
                  muted={photoVideo.isMute}
                />
              )}

              {/* Selected Audio */}
              {photoVideo.audio && <h1>wavesufer</h1>}
            </div>
          ))}
        </Slider>

      </div>

      <div className="inputContainer">
        {openInput && (
          <div className="searchContainerMain">
            <div className="searchContainer" style={{top : "51px"}}>
              <input
                ref={inputref}
                type="text"
                autoFocus
                placeholder="Mention peoples"
                value={inputValue}
                onChange={handleInputChange}
              />

              {loader ? (
                <FontAwesomeIcon
                  className="searchLoader"
                  icon={faSpinner}
                  spinPulse
                  style={{ color: "#402154" }}
                />
              ) : (
                <div className="searchDiv">
                  {searchList.length !== 0 &&
                    searchList.map((user, index) => (
                      <div
                        onClick={() => clickTagUser(user, index)}
                        className="tagUserLIst"
                        key={index}
                      >
                        <img
                          src={
                            user.profileImg
                              ? (AwsFileUrl + user.profileImg.imageId)
                              : `${BASE_URL_STATIC}/dummy.png`
                          }
                          alt=""
                        />
                        <span>
                          <small>{user.fname}</small>
                          <small>{user.username}</small>
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>{" "}
          </div>
        )}
      </div>

      {tagsByCarouselItem &&
        tagsByCarouselItem.map((tags, index) => (
          <div
            key={index}
            className={`${photoVideos && photoVideos[index].type.includes("video")
              ? "videoTags"
              : "taggedContainer"
              }`}
          >
            {activeCarouselIndex === index &&
              tags &&
              tags.map(
                (tag, tagIndex) =>
                  // Conditionally render tags based on media type
                  (photoVideos[index].type.includes("image") ||
                    (photoVideos[index].type.includes("video") &&
                      tag.videoTag)) && (
                    <div
                      key={tagIndex} className="rounded"
                      style={{ top: `${tag.yVal}%`, left: `${tag.xVal}%` }}
                    >
                      <small onClick={() => handleDel(index, tagIndex)}>{tag.user.fname}</small>
                    </div>
                  )
              )}
          </div>
        ))}

    </div>
  )
}

export default PhotoVideoTags