import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { BASE_URL_STATIC } from "../../../../../config";
import { ApiContext } from "../../../UseContext/ApiCall";
import { fp_CreateNewPassword } from "../../../UseContext/ApiHelpers";
import toast from "react-hot-toast";

const CreateNewPassword = () => {
  const [show, setShow] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [checkPassmatch , setPassMatch] = useState(false)
  const [passwords, setPasswords] = useState({
    password: "",
    confirmpassword: "",
  });

  const { setUserInfo, phoneNumber, otpEmail } = useContext(ApiContext);
  const nav = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({ ...prevPasswords, [name]: value }));
  };

  const isValidPassword = () => {
    const { password, confirmpassword } = passwords;
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password); // Check for at least one digit
    const isLengthValid = password.length >= 8;
    const passwordsMatch = password === confirmpassword;


    return hasSymbol && hasNumber && isLengthValid && passwordsMatch;
  };

  const handleNextClick = () => {
    if (isValidPassword()) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        password: passwords.password,
      }));

      fp_CreateNewPassword(phoneNumber, otpEmail, passwords.password).then((res) => {
        toast.success(res)
        nav("/login");
      }
      ).catch((err) => {
        console.log(err);
      }
      )
    } else {
      setShowErrorMessage(true);

    };
  };

  // Password validation checks
  const hasAlphanumeric = /[a-zA-Z]/.test(passwords.password) && /\d/.test(passwords.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(passwords.password);
  const hasMinLength = passwords.password.length >= 8;
  // const passwordsMatch = passwords.password === passwords.confirmpassword;

  

  return (
    <section className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100" >
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/createPassword.png`} alt="createPassword" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0">
        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <form className="w-75 mx-auto">
          <div className="fs-1 mb-3">Create password</div>

          <small className="mb-3">
            <ul className="ps-0">
              <li style={{ color: hasAlphanumeric ? 'green' : 'red' }}>
                {hasAlphanumeric ? '✔️ Must include both letters and numbers' : '❌ Must include both letters and numbers'}
              </li>
              <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
                {hasSpecialChar ? '✔️ Should contain at least one special character' : '❌ Should contain at least one special character'}
              </li>
              <li style={{ color: hasMinLength ? 'green' : 'red' }}>
                {hasMinLength ? '✔️ Password must be at least 8 characters long' : '❌ Password must be at least 8 characters long'}
              </li>

            </ul>
          </small>

          <div className="position-relative my-3">
            <input
              type={show ? "text" : "password"}
              className="form-control form-control-lg rounded-pill"
              onChange={handleChange}
              name="password"
              placeholder="Password"
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              onClick={() => setShow(!show)}
            >
              {show ? <IoEyeOutline size={20} /> : <FaRegEyeSlash size={20} />}
            </span>
          </div>

          <div className="position-relative my-3">
            <input
              type={show ? "text" : "password"}
              className="form-control form-control-lg rounded-pill"
              onChange={handleChange}
              name="confirmpassword"
              placeholder="Re-Type password"
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              onClick={() => setShow(!show)}
            >
              {show ? <IoEyeOutline size={20} /> : <FaRegEyeSlash size={20} />}
            </span>
          </div>

          {showErrorMessage && (
            <span style={{ color: "red" }}>Invalid Password! Please check the criteria.</span>
          )}

          <button type="button" className="btn btn-primary w-100 rounded-pill fs-5" onClick={handleNextClick}>Next</button>
        </form>
      </section>
    </section>
  );
};

export default CreateNewPassword;
