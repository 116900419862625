import React from 'react'
import { Modal } from 'react-bootstrap'
import { TiPin } from "react-icons/ti";
import { BiBlock, BiGroup, BiShow } from "react-icons/bi";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";

const FilterChatpopup = ({ open, close }) => {
   return (
      <section>
         <Modal size='sm'  show={open} onHide={close} centered backdrop="static" keyboard={false} className="" >
            <Modal.Header closeButton>
               <p className='chat-filter-head'>Filter</p>
            </Modal.Header>
            <Modal.Body>

               <ul className='chat-filter-body'>
                  <li><TiPin /> Pinned</li>
                  <li><MdOutlineMarkUnreadChatAlt /> Unread</li>
                  <li><BiBlock /> Blocked</li>
                  <li><BiShow /> show hidden chat</li>
                  <li><BiGroup /> Groups</li>
               </ul>

            </Modal.Body>
         </Modal>
      </section>
   )
}

export default FilterChatpopup