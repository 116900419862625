import React, { useState, useEffect, useContext } from 'react';
import ProfileUser from '../../Profile/ProfileUser/ProfileUser';
import Navbar from '../Navbar/Navbar';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../config';

const Tagged = () => {

  const [taggedPosts, setTaggedPosts] = useState([]);
  const [taggedBestClips, setTaggedBestClips] = useState([]);
  const [taggedMemes, setTaggedMemes] = useState([]);

  const { config, loggedInUserId } = useContext(ApiContext);

  // To get tagged posts
  const getTaggedPosts = (loggedInUserId, config) => {
    axios.post('https://emilonode.sjain.io/post/metaggedpost', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data.tagedPost);
      setTaggedPosts(res.data.tagedPost);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedPosts(loggedInUserId, config);
  }, []);

  // To get tagged Best Clips
  const getTaggedBestClips = (loggedInUserId, config) => {
    axios.post('https://emilonode.sjain.io/post/metaggedbestclip', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data.listBestclip);
      setTaggedBestClips(res.data.listBestclip);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedBestClips(loggedInUserId, config);
  }, []);

  // To get tagged Memes
  const getTaggedMemes = (loggedInUserId, config) => {
    axios.post('https://emilonode.sjain.io/post/metaggedmeme', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data.tagMeme);
      setTaggedMemes(res.data.tagMeme);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedMemes(loggedInUserId, config);
  }, []);

  return (
    <>
      <section className='tagged'>
        <ProfileUser />
        <Navbar />

        {/* Tagged Show */}
        <section className='tagged-show'>
          {/* Tagged Photos And Videos */}
          <Link to={`${BASEURL}/${loggedInUserId}/tagged-PhotosAndVideos`} className='tagged-show-pav'>
            <h4 className='tagged-show-pav-head'>Photos/Videos</h4>
            {
              taggedPosts.length !== 0
              ?
                // console.log(taggedPosts[taggedPosts.length - 1].details[0])
                taggedPosts[taggedPosts.length - 1].details[0].imageUrl
                ?
                <img src={taggedPosts[taggedPosts.length - 1].details[0].imageUrl} alt={taggedPosts[taggedPosts.length - 1].details[0].imageId} height={215} width={200} />
                :
                <video height={215} width={200} autoPlay> 
                  <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${taggedPosts[taggedPosts.length - 1].details[0].videoId}`} />
                </video>
              :
              ''
            }
          </Link>

          {/* Tagged Best Clips */}
          <Link to={`${BASEURL}/${loggedInUserId}/tagged-bestClips`} className='tagged-show-bestClip'>
            <h4 className='tagged-show-bestClip-head'>Best Clips</h4>
            {
              taggedBestClips.length !== 0
              &&
              // console.log(taggedBestClips[taggedBestClips.length -1].details[0].videoId)
              <video height={215} width={200} autoPlay> 
                <source src={`https://emilonode.sjain.io/post/getvideobyid?bestclipVideoId=${taggedBestClips[taggedBestClips.length -1].details[0].videoId}`} />
              </video>
            }
          </Link>

          {/* Tagged Memes */}
          <Link to={`${BASEURL}/${loggedInUserId}/tagged-memes`} className='tagged-show-meme'>
            <h4 className='tagged-show-meme-head'>Memes</h4>
            {
              taggedMemes.length !== 0
              ?
                // console.log(taggedMemes[taggedMemes.length - 1].details[0].imageId)  
                taggedMemes[taggedMemes.length - 1].details[0].imageId
                ?
                <img src={taggedMemes[taggedMemes.length - 1].details[0].imageUrl} alt={taggedMemes[taggedMemes.length - 1].details[0].imageId} height={215} width={200} />
                : 
                <video height={215} width={200} autoPlay> 
                  <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${taggedMemes[taggedMemes.length - 1].details[0].videoId}`} />
                </video>
              :
              ''
            }
          </Link>

          {/* Tagged Photos And Videos */}
          <Link to={`${BASEURL}/${loggedInUserId}/tagged-PhotosAndVideos`} className='tagged-show-pav'>
            <h4 className='tagged-show-pav-head'>Photos/Videos</h4>
            {
              taggedPosts.length !== 0
              ?
                // console.log(taggedPosts[taggedPosts.length - 1].details[0])
                taggedPosts[taggedPosts.length - 1].details[0].imageUrl
                ?
                <img src={taggedPosts[taggedPosts.length - 1].details[0].imageUrl} alt={taggedPosts[taggedPosts.length - 1].details[0].imageId} height={215} width={200} />
                :
                <video height={215} width={200} autoPlay> 
                  <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${taggedPosts[taggedPosts.length - 1].details[0].videoId}`} />
                </video>
              :
              ''
            }
          </Link>
        </section>

      </section> 
    </>
  );
};

export default Tagged;
