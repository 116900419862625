import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessMilestonePopup = () => {

  const { 
    showCreateMilestonePopup, setShowCreateMilestonePopup,
    milestone, setMilestone,
    createMilestone,
  } = useContext(businessContext);

  console.log('milestone : ', milestone);

  const fileInputRef = useRef(null); 

  // handle click
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  };

  // handle change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setMilestone((prev) => ({
        ...prev,
        image: e.target.files[0],
      }));
    }
  };

  return (
    <>
      <Modal show={showCreateMilestonePopup} onHide={() => setShowCreateMilestonePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Milestone</h4>

          <>
            {
              milestone.image
              ?
              <div className='mb-5' style={{ height: '200px' }}>
                <img src={URL.createObjectURL(milestone.image)} className='h-100 w-100 object-fit-fill rounded-4' alt="milestone" />
              </div>
              :
              <div className='d-flex flex-column align-items-center w-75 mx-auto rounded-3 py-4 mb-5 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                <img src={`/images/business/icons/upload-photo-blue.svg`} style={{ height: '80px' }} alt="upload-blue" />

                <small className='text-primary'>Upload</small>
                <small className='text-secondary'>or</small>
                <small>Drop your file here</small>

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
              </div>
            }
          </>

          <small className='text-secondary'>Heading</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='heading' placeholder='Heading...' 
            value={milestone.heading} onChange={(e) => setMilestone((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Year</small>
          <input type="date" className='form-control border-0 border-bottom shadow-none mb-4' name='date' 
            value={milestone.date} onChange={(e) => setMilestone((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-5" name='description' placeholder='Description...'
            value={milestone.description} onChange={(e) => setMilestone((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          ></textarea>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createMilestone} 
              disabled={!(milestone.image && milestone.heading && milestone.date && milestone.description)}
            >Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessMilestonePopup
