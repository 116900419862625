import React from 'react'
import { BASE_URL_STATIC } from '../../../../../config'
import SettingInputUser from './SettingInputUser'



const AdsPreferences = () => {
  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='accordionToggle1'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Ads Preferences</li>
          </ol>
        </nav>

        {/* View & Manage Ads you have seen */}
        <div className='accordion accordion-flush '>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#viewManageSeenAds">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="ads-preferences" />
            <h5 className='fw-semibold ms-3'>View & Manage Ads you have seen.</h5>
          </div>

          <div id='viewManageSeenAds' className="accordion-collapse collapse show ms-3 mt-3" data-bs-parent="#accordionToggle1">
            <span className='mb-4'>you have seen ads from below advertisers, you can manage whether you want to see more, less or hide Ads of similar advertisers in future.</span>

            <div className='d-flex mb-3 ms-3'>
              <span className='me-5 fw-semibold'>Name of advertisers</span>

              <span>Manage (clicking this will open - View More, View Less, Hide)</span>
            </div>

            <div className='d-flex mb-3 ms-3'>
              <span className='me-5 fw-semibold'>Name of advertisers</span>

              <span>Manage (clicking this will open - View More, View Less, Hide)</span>
            </div>

            <div className='d-flex ms-3'>
              <span className='me-5 fw-semibold'>Name of advertisers</span>

              <span>Manage (clicking this will open - View More, View Less, Hide)</span>
            </div>

            <hr className='hr bg-secondary' />
          </div>
        </div>

        {/* Hidden Advertisers */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#hiddenAds">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="ads-preferences" />
            <h5 className='fw-semibold ms-3'>Hidden Advertisers.</h5>
          </div>

          <div id='hiddenAds' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#accordionToggle1">
            {/* Input */}
            <SettingInputUser 
              settingNav='adsPreferences' 
              settingKey='hiddenAdsKey'
              settingType='hiddenAdsType'
              header='Below are the advertisers you have hidden'
              message='You can select advertisers whom you want to hide.' 
            />

            <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
          </div>
        </div>

        {/* View & Manage Ads Categories */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#viewManageAdsCategories">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="ads-preferences" />
            <h5 className='fw-semibold ms-3'>View & Manage Ads Categories.</h5>
          </div>

          <div id='viewManageAdsCategories' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#accordionToggle1">
            <span>you are being shown ads related to below categories, you can manage whether you want to see more, less or hide Ads of similar categories in future.</span>

            {/* Categories based on 1 */}
            <div className='my-3'>
              <h5 className='accordion-level-4 fw-semibold' data-bs-toggle="collapse" data-bs-target="#categoriesBasedOn-1">Categories based on your profile info.</h5>

              <div id='categoriesBasedOn-1' className="accordion-collapse collapse show ms-3 accordion accordion-flush">
                <span className='mb-3'>We show ads related to categories based on some of your profile info. You can manage whether you want to allow your below profile info to show ads accordingly to you.</span>

                <div className='d-flex mb-3'>
                  <span className='w-50 fw-semibold'>Jobs</span>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' checked={true} />
                  </div>
                </div>

                <div className='d-flex mb-3'>
                  <span className='w-50 fw-semibold'>Education</span>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' checked={true} />
                  </div>
                </div>

                <div className='d-flex mb-3'>
                  <span className='w-50 fw-semibold'>Hobbies</span>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' checked={true} />
                  </div>
                </div>

                <div className='d-flex mb-3'>
                  <span className='w-50 fw-semibold'>Likes</span>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' checked={true} />
                  </div>
                </div>

                <hr className='hr bg-secondary' />
              </div>
            </div>

            {/* Categories based on 2 */}
            <div className=''>
              <h5 className='accordion-level-4 fw-semibold' data-bs-toggle="collapse" data-bs-target="#categoriesBasedOn-2">Categories based on your activities, interest or preferences.</h5>

              <div id='categoriesBasedOn-2' className="accordion-collapse collapse show ms-3 accordion accordion-flush">
                <span className='mb-3'>We show ads related to categories based on your activities, interest or preferences. You can manage whether you want to allow below categories to show ads accordingly to you.</span>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Motivational</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Spiritual </span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Educational</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Business</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Political</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Entertainment</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Social Activities</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <div className='d-flex justify-content-between mb-3'>
                  <span className='fw-semibold me-5'>Science</span>
                  <span>Manage (clicking this will open - View More, View Less, Hide)</span>
                </div>

                <hr className='hr bg-secondary' />
              </div>
            </div>
          </div>
        </div>

        {/* Hidden Categories */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#hiddenCategories">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="ads-preferences" />
            <h5 className='fw-semibold ms-3'>Hidden Categories.</h5>
          </div>

          <div id='hiddenCategories' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#accordionToggle1">
            {/* Input */}
            <SettingInputUser 
              settingNav='adsPreferences' 
              settingKey='hiddenCategoriesKey'
              settingType='hiddenCategoriesType'
              header='Below are the Categories you have hidden'
              message='You can select categories whom you want to hide.' 
            />

            <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
          </div>
        </div>

        {/* Use of Ads Partners’ shared information */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#adsPartners">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="ads-preferences" />
            <h5 className='fw-semibold ms-3'>Use of Ad Partners’ shared information.</h5>
          </div>

          <div id='adsPartners' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#accordionToggle1">
            <span>Emilo tries to show you more personalized and relevant ads using your activity information shared by our Ad partners. This may be based on your online or instore visit. The information received is highly secured and only used to personalize your Ads. <span className='text-primary'>Learn more…</span></span>

            <div className='me-3'>
              <div className='mt-3 d-flex align-items-center'>
                <span className='accordion-level-4 me-5'>Allow EMILO to show you more personalized and relevant ads to you </span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' value={true} checked={true} />
                </div>
              </div>

              <span className='ms-3 mt-2'>If you choose No you will not see more personalized and relevant ads.</span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdsPreferences
