import React, { useContext, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import Modal from "react-bootstrap/Modal";
import { BASEAPIURL, BASE_URL_STATIC } from "../../../../../config";
import { useState } from "react";
import { ApiContext } from "../../../UseContext/ApiCall";
import axios from "axios";
import { profileContext } from "../../../UseContext/ProfileContext";
import { FaPen } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { checkNumber } from "../../../UseContext/ApiHelpers";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import Countdown from "react-countdown-now";

const ContactDetailPopup = ({ open, closeHandle }) => {

  const [userData, setUserData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [toggleMob, setToggleMob] = useState(false)
  const [numberMessage, setNumberMessage] = useState("")
  const [disableOTP, setdisableOTP] = useState(true)
  const [flipEmail, setFlipEmail] = useState(false)
  const [resendTimer, setResendTimer] = useState(59);
  const [otp, setOtp] = useState("")
  const [val, setVal] = useState(false)



  const Token = localStorage.getItem("token");
  const { config } = useContext(ApiContext)
  const { setUserProfile } = useContext(profileContext)

  const fetchData = async () => {
    try {
      const res = await fetch(`${BASEAPIURL}/user/myprofile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: JSON.stringify({}),
      });
      const resJson = await res.json();

      if (res.status === 200) {
        setUserData(resJson.user);
      } else {
        console.log(resJson.message);
      }
    } catch (err) {
      console.log(err);
    };
  };

  useEffect(() => {
    fetchData()
    setPhoneNumber("")

  }, []);

  const handlePhoneNumber = (value) => {
    setPhoneNumber(value)
    if (value && value.length === 13) {
      checkNumber(value).then((res) => {
        if (res === "mobile number already register") {
          setNumberMessage(res);
          setdisableOTP(true)
        } else if (res === "you can take this mobile") {
          setNumberMessage("");
          setdisableOTP(false)
        }
      });
    } else if (value && value.length > 13) {
      setNumberMessage("please enter valid mobile No.");
      setdisableOTP(true)
    }
    else if (value && value.length < 13) {
      setNumberMessage("");
      setdisableOTP(true)
    };
  }

  const updateProfile = async (mobile, config) => {
    await axios.post("https://emilonode.sjain.io/user/updateuserprofile", { mobile }, config).then(async () => {
      await axios.post("https://emilonode.sjain.io/user/myprofile", {}, config).then((res) => setUserProfile(res.data.user))
    })
  }

  const handleClose = () => {
    setToggleMob(false);
    setPhoneNumber("")
    setdisableOTP(true)
    setFlipEmail(false)
    setOtp(null)
  }

  function handleSubmit(e) {
    e.preventDefault();
    closeHandle();
  }

  const sendMobileotp = async () => {
    setFlipEmail(true)

    try {
      phoneNumber &&
        await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: phoneNumber }).then((res) => {
    updateProfile(phoneNumber, config)

          toast.success(res.data.message)
        })
    } catch (error) {
      toast.error("failed to send otp")
    }

  };

  const reSendOtp = async () => {
    try {
      await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: phoneNumber }).then((res) => {
        toast.success(res.data.message);
        setResendTimer(59);
      })
    } catch (error) {
      toast.error("failed to send otp")
    }
  }

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <span style={{ cursor: "pointer" }} onClick={() => reSendOtp()
      }>Resend OTP</span>;
    } else {
      return <span style={{ opacity: 0.75 }}> {`Resend OTP in 00 : ${seconds < 10 ? `0${seconds}` : seconds}`}</span>;
    }
  };

  const handleVerification = async (e) => {
    const requestData = { mobile: phoneNumber, otp };
    try {

      await axios.post("https://emilonode.sjain.io/user/otp-verify", requestData).then((res) => {
        toast.success(res.data.message);
        setToggleMob(false);
        setdisableOTP(true)
        setFlipEmail(false)
        setOtp(null)
        setVal(true)
      }
      )
    } catch (error) {
      toast.error(error.response.data.message);
    }

  }


  return (
    <>
      <Modal
        show={open}
        onHide={closeHandle}
        centered
        backdrop="static"
        keyboard={false}
        className="create-call"
      >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 >Contact Info</h5>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div
                className="row"
                style={{
                  display: "flex",
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div className="py-2 px-3 position-relative">
                  <div
                    className="d-flex gap-2"
                    style={{
                      background: "#f5f5f5",
                      width: "fit-content",
                      padding: "6px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img src={`${BASE_URL_STATIC}/icon/mobile.svg`} width="10px" alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",
                      }}
                    >
                      Mobile
                    </p>
                  </div>

                  {!toggleMob && <span className="position-absolute" style={{ cursor: "pointer", right: "17px", top: "15px" }} onClick={() => { setToggleMob(true); }}><FaPen color="#d3d3d3" /> </span>}
                  {toggleMob && <span className="position-absolute" style={{ cursor: "pointer", right: "17px", top: "15px" }} onClick={() => handleClose()}><IoMdClose color="#d3d3d3" /> </span>}


                </div>
                <div className="mt-4 position-relative">

                  <PhoneInput
                    placeholder={toggleMob && "Enter Mobile No."}
                    className="phone_input_contact text-center"
                    onChange={(e) => handlePhoneNumber(e)}
                    value={val ? "" : phoneNumber}
                    defaultCountry="IN"
                    limitMaxLength
                    readOnly={!toggleMob}
                  />

                  <p className="text-center text-danger">
                    <span>{numberMessage}</span>
                  </p>

                  {!toggleMob && <span className="position-absolute opacity-75" style={{ top: "17px", left: "26%" }} >
                   {val ? phoneNumber : userData.mobile}
                  </span>}

                  {!disableOTP && <span className="change_no_otp scale_in" onClick={sendMobileotp} >send otp</span>}

                </div>
              </div>
            </div>

            {!flipEmail ? <div className="form-group">
              <div
                className="row "
                style={{
                  padding: "10px 0px 0px 25px",
                }}
              >
                <div>
                  <div
                    className="d-flex gap-2"
                    style={{
                      background: "#f5f5f5",
                      width: "fit-content",
                      padding: "6px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img src={`${BASE_URL_STATIC}/icon/mail.svg`} width="15px" alt="" />
                    <p
                      style={{
                        margin: "auto",
                        color: "#9f9797b8",
                      }}
                    >
                      Email
                    </p>
                  </div>
                </div>
                <div>
                  {/* <span style={{ border: '0px 0px 2px 0px solid black' }}>
                                {user.fname}
                                </span> */}
                  <input
                    type="email"
                    style={{
                      fontSize: '18px',
                      border: '2px solid white',
                      marginTop: '20px',
                      fontWeight: '400',
                      borderBottom: '2px solid #f3f3f3',
                      lineHeight: '50px',
                      backgroundColor: "transparent",
                      textAlign: "center"
                    }}
                    className="form-control"
                    placeholder="email"
                    readOnly
                    value={userData.email}
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: 'center',
                    marginTop: '30px',
                  }}
                >
                  <div className="edit-modal-btn" onClick={closeHandle}>
                    Save
                  </div>
                </div>
              </div>
            </div> :

              <div className="form-group">
                <div className='afterGooglePopup-form-pin' style={{ padding: "20px 0px" }}>

                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<pre> </pre>}
                    renderInput={(props) => <input {...props} className='afterGooglePopup-pin' />}
                  />

                  <div>
                    <p
                      onClick={otp && otp.length === 4 ? handleVerification : undefined}
                      style={{
                        cursor: otp && otp.length === 4 ? "pointer" : "default",
                        color: '#fff'
                      }}
                      className="ph_verify_otp"
                      disabled={!(otp && otp.length === 4)}
                    >
                      verify
                    </p>
                  </div>


                  {/* <span>Resend otp in 01:00 seconds</span> */}
                  <span className="resendOTP">Didn’t get the OTP? <b><Countdown date={Date.now() + resendTimer * 1000} renderer={renderer} /></b></span>

                </div>
              </div>
            }
          </form>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactDetailPopup;
