import React from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../../config';

const VideoCallGoingPopup = ({ showVideoCallPopup, setShowVideoCallPopup, chatProfile, turnOnMicrophone, turnOnCamera, leaveChannel, isVideoSubed, isVideoOn }) => {

  // const [isVideoShowing, setIsVideoShowing] = useState(false);
  // const [isMute, setIsMute] = useState(false);

  // To handle show or hide video
  // const handleShowVideo = () => {
  //   turnOnCamera(isVideoShowing);
  //   setIsVideoShowing(!isVideoShowing);
  // };

  // To handle mute or unmute audio while video calling
  // const handleVideoCallMute = () => {
  //   turnOnMicrophone(isMute);
  //   setIsMute(!isMute);
  // };

  // To handle video call end
  // const handleVideoCallEnd = () => {
  //   leaveChannel();
  //   setShowVideoCallPopup(false);
  // };

  return (
    <>
      <section className='videoCallGoingPopups'>
        <Modal show={showVideoCallPopup} onHide={() => setShowVideoCallPopup(false)} className="main-modal videoCallGoingPopup-modal" size="lg" centered backdrop="static" keyboard={false} >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body videoCallGoingPopup-body">
            <section className='videoCallGoingPopup'>
              {/* Logo */}
              <div className='vcgp-logo'>
                <img className='vcgp-logo-img' src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
              </div>

              {/* Person */}
              <div className='vcgp-person'>
                <div className='vcgp-person-1'>
                  {/* <video className='vcgp-person-1-video' autoPlay loop>
                    <source src={`${BASE_URL_STATIC}/videos/videoCall-1.mp4`} type="video/mp4" />
                  </video> */}

                  <video id="remote-video" className='vcgp-person-1-video' hidden={isVideoSubed ? false : true}></video>
                </div>

                <div className='vcgp-person-2'>
                  {/* <video className='vcgp-person-2-video' autoPlay loop>
                    <source src={`${BASE_URL_STATIC}/videos/videoCall-2.webm`} type="video/webm" />
                  </video> */}

                  <video id="camera-video" className='vcgp-person-2-video' hidden={isVideoOn ? false : true}></video>
                </div>
              </div>

              {/* Buttons */}
              <div className='vcgp-btns'>
                {/* <div className='vcgp-btn-video' onClick={handleShowVideo}> */}
                <div className='vcgp-btn-video'>
                  {
                    // isVideoShowing
                    false
                    ?
                    <img src={`${BASE_URL_STATIC}/icon/videoCall-slash.svg`} alt="videoCall" />
                    :
                    <img src={`${BASE_URL_STATIC}/icon/videoCall.svg`} alt="videoCall" />
                  }
                </div>

                {/* <div className='vcgp-btn-cut' onClick={handleVideoCallEnd}> */}
                <div className='vcgp-btn-cut'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneCut.svg`} alt="phoneCut" />
                </div>

                {/* <div className='vcgp-btn-mute' onClick={handleVideoCallMute}> */}
                <div className='vcgp-btn-mute'>
                  {
                    // isMute
                    false
                    ?
                    <img src={`${BASE_URL_STATIC}/icon/microphone-slash.svg`} alt="microphone" />
                    :
                    <img src={`${BASE_URL_STATIC}/icon/microphone.svg`} alt="microphone" />
                  }
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default VideoCallGoingPopup
