import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';
import SettingInputUser from '../../../Profile/ProfileUser/ProfileUserSetting/SettingInputUser';



const FlixAdvanceSettingPopup = ({ 
  showFlixAdvanceSettingPopup, setShowFlixAdvanceSettingPopup, 
  setShowCreateReelsPopup2 
}) => {

  const { config } = useContext(ApiContext);
  const { value, setValue, handleOptionChange, setAdded, isAccountPrivate } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };

  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('privacy', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        // ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get (Followers Except, Hide From, Block Comments Users and Others)
    getAdded('privacy', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        // ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <Modal show={showFlixAdvanceSettingPopup} onHide={() => {setShowFlixAdvanceSettingPopup(false); setShowCreateReelsPopup2(true)}} className='create-post m-0' backdrop="static" centered >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section>
            <div className='d-flex'>
              <span className='cursor-pointer' 
                onClick={() => {
                  setShowFlixAdvanceSettingPopup(false);
                  setShowCreateReelsPopup2(true);
                }}>⮜</span>
              <h4 className='mx-auto text-primary'>Advance Setting</h4>
            </div>

            <div className="accordion accordion-flush overflow-y-scroll pe-1 my-4" style={{ maxHeight: '50vh' }}>
              {/* Audience Control */}
              <div className=''>
                <div className='shadow-sm accordion-level-3 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-audienceControl">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Audience Control</h5>
                </div>

                <div id='flix-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* View */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#flix-audienceControlView">Who can view your flix?</h5>

                    <div id='flix-audienceControlView' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixView-public" name="flixView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')}  
                            checked={!value.flixView || value.flixView === 'public'} 
                            disabled={isAccountPrivate === 'private'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixView-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixView-followers" name="flixView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.flixView === 'followers'} 
                          />
                          <label className="form-check-label" htmlFor="ps-flixView-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixView-follower" name="flixView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.flixView === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-flixView-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixView-fof" name="flixView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.flixView === 'fof'} 
                          />
                          <label className="form-check-label" htmlFor="ps-flixView-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.flixView ? value.flixView : 'public']} view your flix)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.flixView) || (value.flixView === 'public') || (value.flixView === 'followers') || (value.flixView === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.flixView || value.flixView === 'public') ? 'flixViewPublic' : 
                                value.flixView === 'followers' ? 'flixViewFollowers' : 
                                value.flixView === 'fof' && 'flixViewFOF'
                              }
                              settingType={value.flixView ? value.flixView : 'public'} 
                              header={
                                (!value.flixView || value.flixView === 'public') ? 'Hide flix from specific people' :
                                value.flixView === 'followers' ? 'Followers Except' :
                                value.flixView === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your flix' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Likes & Comments */}
              <div className='my-3'>
                <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-likeAndComments">
                  <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                  <h5 className='fw-semibold ms-3 mb-0'>Likes & Comments</h5>
                </div>

                <div id='flix-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Like Count */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0'>Hide like count</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixLike' value={value.flixLike === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.flixLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <small className='text-secondary ms-3 mt-3 d-block '>(Confirm if you want to hide the like counts of your flix)</small>
                  </div>

                  <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Comments */}
                  <div className='ms-3'>   
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#flix-likeAndComments-Comment">Who can comment on your flix?</h5>

                    <div id='flix-likeAndComments-Comment' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixComment-public" name="flixComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.flixComment || (!value.flixComment && value.flixView === 'public') || value.flixComment === 'public'} 
                            disabled={value.flixView === 'followers' || value.flixView === 'fof' || value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixComment-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixComment-followers" name="flixComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixComment && value.flixView === 'followers') || value.flixComment === 'followers'} 
                            disabled={value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixComment-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixComment-follower" name="flixComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixComment && value.flixView === 'closeFriends') || value.flixComment === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-flixComment-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixComment-fof" name="flixComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixComment && value.flixView === 'fof') || value.flixComment === 'fof'} 
                            disabled={value.flixView === 'followers' || value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixComment-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.flixComment ? value.flixComment : 'public']} comment on your flix)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.flixComment) || (value.flixComment === 'public') || (value.flixComment === 'followers') || (value.flixComment === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.flixComment || value.flixComment === 'public') ? 'flixCommentPublic' : 
                                value.flixComment === 'followers' ? 'flixCommentFollowers' : 
                                value.flixComment === 'fof' && 'flixCommentFOF'
                              }
                              settingType={value.flixComment ? value.flixComment : 'public'} 
                              header={
                                (!value.flixComment || value.flixComment === 'public') ? 'Hide flix comment from specific people' :
                                value.flixComment === 'followers' ? 'Followers Except' :
                                value.flixComment === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your flix comment' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Share & Download */}
              <div className='my-3'>
                <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-shareAndDownload">
                  <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                  <h5 className='fw-semibold ms-3 mb-0'>Share & Download</h5>
                </div>

                <div id='flix-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Share */}
                  <div className='ms-3 mt-3'>
                    {/* flix share */}
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow other EMILO users to share your flix</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShare' value={value.flixShare === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.flixShare === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='ms-3'>
                      <span>By default, your selected audience for the flix will be able to use your flix. However, if you want to disallow specific people from using your flix, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='flixShare' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                    {/* flix share to story */}
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow sharing of your Flix on Stories</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixShareToStory' value={value.flixShareToStory === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.flixShareToStory === 'true' ? true : false} 
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Download */}
                  <div className='ms-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#flix-shareAndDownload-download">Who can download your flix?</h5>

                    <div id='flix-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixDownload-public" name="flixDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.flixDownload || value.flixDownload === 'public'} 
                            disabled={value.flixView === 'followers' || value.flixView === 'fof' || value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixDownload-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixDownload-followers" name="flixDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixDownload && value.flixView === 'followers') || value.flixDownload === 'followers'} 
                            disabled={value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixDownload-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixDownload-follower" name="flixDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixDownload && value.flixView === 'closeFriends') || value.flixDownload === 'closeFriends'} />
                          <label className="form-check-label" htmlFor="ps-flixDownload-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixDownload-fof" name="flixDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.flixDownload && value.flixView === 'fof') || value.flixDownload === 'fof'} 
                            disabled={value.flixView === 'followers' || value.flixView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-flixDownload-fof">Followers of Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-flixDownload-following" name="flixDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.flixDownload === 'none'} 
                          />
                          <label className="form-check-label" htmlFor="ps-flixDownload-following">No one</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.flixDownload ? value.flixDownload : 'public']} download your flix)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.flixDownload) || (value.flixDownload === 'public') || (value.flixDownload === 'followers') || (value.flixDownload === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.flixDownload || value.flixDownload === 'public') ? 'flixDownloadPublic' : 
                                value.flixDownload === 'followers' ? 'flixDownloadFollowers' : 
                                value.flixDownload === 'fof' && 'flixDownloadFOF'
                              }
                              settingType={value.flixDownload ? value.flixDownload : 'public'} 
                              header={
                                (!value.flixDownload || value.flixDownload === 'public') ? 'Hide flix download from specific people' :
                                value.flixDownload === 'followers' ? 'Followers Except' :
                                value.flixDownload === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your flix download' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Remix */}
              <div className=''>
                <div className='shadow-sm accordion-level-3 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#flix-remix">
                  <img src={`${BASE_URL_STATIC}/icon/remix.svg`} alt="remix" />
                  <h5 className='fw-semibold ms-3 mb-0'>Remix</h5>
                </div>

                <div id='flix-remix' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* use your flix */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow other EMILO users to use your flix</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='flixRemix' value={value.flixRemix === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.flixRemix === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='ms-3'>
                      <span>By default, your selected audience for the flix will be able to use your flix. However, if you want to disallow specific people from using your flix, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='flixRemix' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary mb-0' style={{ width: '-webkit-fill-available' }} />
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className='btn btn-primary rounded-pill w-100'>Done</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FlixAdvanceSettingPopup
