import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getWorkDetails, updateWork } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { BASE_URL_STATIC } from "../../../../../config";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkLocation from "../../CreateNewPost/WorkLocation";

const EditworkPopup = ({ open, close, workId, workObj }) => {

  const { setWorkData, workInput, setworkInput } = useContext(profileContext);

  const [end, setEnd] = useState(true);
  const [fromInputType, setFromInputType] = useState("text");
  const [toInputType, setToInputType] = useState("text");
  const [location , setLocation] = useState("")


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setworkInput({
        ...workInput,
        [name]: checked,
        endDate: checked ? "" : workInput.endDate, // Reset endDate if checked
      });
      setEnd(!checked); // Toggle the visibility of the endDate input
    } else {
      setworkInput({
        ...workInput,
        [name]: value,
      });
    };
  };

  const workDetails = { workId, ...workInput , campanyLocation : location};

  useEffect(() => {
    setworkInput(() => ({
      companyName: workObj.companyName,
      startDate: workObj.startDate,
      endDate: workObj.endDate,
      currentWorking: workObj.currentlyWorking,
      aboutCompany: workObj.aboutCompany,
      jobTitle: workObj.jobTitle,
      workType: workObj.workType,
    }))
  }, [workObj])

  async function handleSubmit(e) {
    e.preventDefault();
    await updateWork(workDetails)
      .then(() => {
        getWorkDetails().then((res) => setWorkData(res));
      })
      .catch((error) => {
        console.error("Error creating work detail:", error);
      });

      close();
  };

  return (
    <>
     <section>
        <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={handleSubmit}>
              <h5 >Work</h5>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "109px",
                  }}
                >
                  {/* <img src={`${BASE_URL_STATIC}/icon/company.svg`}
                   alt="" /> */}

                  <FontAwesomeIcon icon={faUserCheck} style={{ color: "#A9A9A9", }} />

                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                    work type
                  </small>
                </span>
                <div className="w-100">

                  <select
                    className="form-control m-auto"
                    name="workType"
                    onChange={handleChange}
                    defaultValue={workObj.workType}
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #f3f3f3",
                      marginTop: "12px",
                      textAlign: "center",
                      width: "80%"
                    }}
                  >
                    <option value="" disabled>select</option>
                    <option value="Job">Job</option>
                    <option value="Permanent">Business</option>
                    <option value="Freelancer">Freelacner</option>
                    <option value="Professional">Professional</option>
                    <option value="Homemaker">Homemaker</option>
                  </select>
                </div>

              </div>

              <div style={{ display: `${workInput.workType === "Homemaker" ? "none" : "block"}` }} className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "150px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/company.svg`} alt="" />

                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                   {workInput.workType === "Professional" ? "Profession Name" : "Company Name"}
                  </small>
                </span>

                <div className="w-100 text-center">

                  <input
                    type="text"
                    onChange={handleChange}
                    name="companyName"
                    defaultValue={workObj.companyName}
                    style={{
                      border: "none",
                      borderBottom: "2px solid #f3f3f3",
                      width: "80%",
                      margin: "auto",
                      padding: "5px 10px",
                    }}
                  />
                </div>
              </div>

              <div style={{ display: `${(workInput.workType === ("Homemaker")) || (workInput.workType === ("Professional") ) ? "none" : "block"}` }} className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "100px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/job.svg`} alt="" />

                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                    Job Title
                  </small>
                </span>

                <div className="w-100 text-center">

                  <input
                    type="text"
                    onChange={handleChange}
                    defaultValue={workObj.jobTitle}
                    name="jobTitle"
                    style={{
                      border: "none",
                      borderBottom: "2px solid #f3f3f3",
                      width: "80%",
                      margin: "auto",
                      padding: "5px 10px",
                    }}
                  />
                </div>
              </div>

              <div style={{ display: `${(workInput.workType === ("Homemaker")) || (workInput.workType === ("Professional") ) ? "none" : "block"}` }} className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "100px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />
                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                    Location:
                  </small>
                </span>

                <div className="w-100 text-center">

                <WorkLocation defaultLocation = {workObj.campanyLocation} location={location} setLocation={setLocation}/>

                </div>
              </div>

              <div style={{ display: `${workInput.workType === "Homemaker" ? "none" : "block"}` }} className="flex">
                <span
                  className=""
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "150px",
                    fontSize: "12px",
                    color: "rgb(97 97 97 / 60%)"
                  }}
                >
                  {" "}
                  Currently work here
                </span>

                <span className="form-check form-switch">
                  <input
                    onChange={handleChange}
                    style={{cursor : "pointer"}}
                    checked = {workObj.currentlyWorking === 'true'}
                    defaultValue={workObj.currentlyWorking}
                    className="form-check-input"
                    name="currentWorking"
                    type="checkbox"
                    aria-checked
                    role="switch"
                    id="flexSwitchCheckChecked"
                  />
                </span>
              </div>

              <div style={{ display: `${workInput.workType === "Homemaker" ? "none" : "block"}` }} className="">
                <span
                  className="d-flex align-content-center gap-0"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "80px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/calender.svg`} alt="" />

                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                    Date
                  </small>
                </span>
                <div className="d-flex justify-content-between align-items-center gap-0">
                  <div
                    style={{
                      background: "#f5f5f5",
                      position: "relative",
                      borderRadius: "30px",
                      width: "35%",
                      padding: "5px 15px",
                      margin: "10px 0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      onChange={handleChange}
                      name="startDate"
                      type={fromInputType}
                      placeholder="From"
                      defaultValue={workObj.startDate}
                      onFocus={() => setFromInputType("date")}
                      onBlur={() => setFromInputType("text")}
                      className=""
                      style={{
                        border: "none",
                        background: "inherit",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    />

                    {fromInputType === "text" && (
                      <img
                        src={`${BASE_URL_STATIC}/icon/calender.svg`}
                        alt=""
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "30px",
                          height: "12px"
                        }}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      background: "#f5f5f5",
                      position: "relative",
                      borderRadius: "30px",
                      width: "35%",
                      padding: "5px 15px",
                      margin: "10px 0px",
                      display: `${end ? "flex" : "none"}`,
                      alignItems: "center",
                    }}
                  >
                    <input
                      onChange={handleChange}
                      type={toInputType}
                      name="endDate"
                      defaultValue={workObj.endDate}
                      placeholder="To"
                      onFocus={() => setToInputType("date")}
                      onBlur={() => setToInputType("text")}
                      className=""
                      style={{
                        border: "none",
                        background: "inherit",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    />

                    {toInputType === "text" && (
                      <img
                        src={`${BASE_URL_STATIC}/icon/calender.svg`}
                        alt=""
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "30px",
                          height: "12px"
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="">
                <span
                  className="hstack"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "fit-content",
                    gap: "10px"
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/messages.svg`} width="15px" alt="" />

                  <small
                    style={{
                      margin: "auto",
                      color: "rgb(97 97 97 / 60%)",
                    }}
                  >
                    Tell me something about place
                  </small>
                </span>
                <div className="w-100 text-center">

                  <textarea
                    type="text"
                    name="aboutCompany"
                    onChange={handleChange}
                    defaultValue={workObj.aboutCompany}
                    style={{
                      border: "1px solid #d4d4d4",
                      width: "80%",
                      margin: "auto",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                    rows="5"
                    placeholder="Add Something..."
                  />
                </div>

              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-dark mt-4 col-4">Save</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default EditworkPopup;
