import React, { useState, useContext, useRef } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../UseContext/ApiCall';
import LikeButton from '../Button/LikeButton/LikeButton';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from 'react-slick';
import { getOnePost, viewCountLikeCountforPost } from '../UseContext/ApiHelpers';
import AddComments from '../Button/AddComments';
import PostTime from '../Home/CenterBlock/CenterBlockPosts/PostTime';
import ReactPlayer from 'react-player';
import PostSharePopup from './Share/PostSharePopup';
import ProfilePostThreeDotPopup from './Profile/ProfileShow/ThreeDot/ProfilePostThreeDotPopup';

const ShowCopyLink = () => {


  const { postId } = useParams();
  const sliderRef = useRef(null);
  const [clickedreply, setClickedReply] = useState(false)
  const [viewReply, setViewReply] = useState(false)
  const [comment, setComment] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showProfilePostThreeDotPopup, setShowProfilePostThreeDotPopup] = useState(false);
  const [showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup] = useState(false);

  const [postsPopupData, setPostsPopupData] = useState(null);
  const [showPostSharePopup, setShowPostSharePopup] = useState(false);
  const [dataview, setDataView] = useState('')
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredlike, setIsHoveredlike] = useState(false);

  const { config, comments, setComments, parentSpinner, setParentSpinner } = useContext(ApiContext);
  console.warn(comment)


  useEffect(() => {
    setClickedReply(false)
    setViewReply(false)
  }, [postsPopupData]);

  const settings = {
    dots: true,
    className: "profilePostsPopup-post-slider",
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
    afterChange: (index) => setActiveIndex(index),
  };

  useEffect(() => {
    getOnePost(postId, config).then((res) => setPostsPopupData(res))
    viewCountLikeCountforPost(postId, config).then((res) => setDataView(res))
  }, [])

  console.log("dataview", dataview);
  console.log("showPostSharePopup", showPostSharePopup);
  const handleMouseOver = () => {
    setIsHovered(!isHovered);
  };
  const handleMouseOver2 = () => {
    setIsHoveredlike(!isHoveredlike);
  };





  return (
    <>
      <ProfilePostThreeDotPopup showProfilePostThreeDotPopup={showProfilePostThreeDotPopup} setShowProfilePostThreeDotPopup={setShowProfilePostThreeDotPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} />

      <PostSharePopup shareData={postsPopupData} showPostSharePopup={showPostSharePopup} setShowPostSharePopup={setShowPostSharePopup} />

      <section className="profilePostsPopup container-lg m-auto flex justify-content-center mt-lg-5 pt-lg-5">
        {/* Photos And Videos */}
        <section className="profilePostsPopup-post">
          <Slider {...settings} ref={sliderRef}>
            {
              postsPopupData !== null
              &&
              postsPopupData.mediaUrls.map((details, index) => (
                details.filetype === 'image'
                  ?
                  <div key={index} className="profilePostsPopup-post-pav">
                    <img className="profilePostsPopup-post-image" src={`${AwsFileUrl}${details.url}`} alt={details.imageId} onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-3.png`} height='100%' width='100%' />
                  </div>
                  :
                  <div key={index} className="profilePostsPopup-post-pav">
                    <ReactPlayer
                      url={AwsFileUrl + details.url} className="profilePostsPopup-post-video"
                      playing={index === activeIndex}
                      controls
                    />
                  </div>
              ))
            }
          </Slider>
        </section>

        {/* Details */}
        {postsPopupData !== null && <section className="profilePostsPopup-details">
          {/* User */}
          <div className='profilePostsPopup-details-user'>
            <img className='profilePostsPopup-details-profile' src={AwsFileUrl + postsPopupData.imageId} alt={postsPopupData.postId} height={50} width={50} />

            <div className='profilePostsPopup-details-header'>
              <div className='profilePostsPopup-details-head'>
                <span className='profilePostsPopup-details-name'>{postsPopupData.fname}</span>
                {
                  (postsPopupData.emotionIcon !== 'undefined' && postsPopupData.emotionName !== 'undefined')
                  &&
                  <span className='profilePostsPopup-details-activity'>{postsPopupData.emotionIcon} {postsPopupData.emotionName}</span>
                }

                {
                  (postsPopupData.emotionIcon !== 'undefined' && postsPopupData.emotionName !== 'undefined')
                  &&
                  <span className='profilePostsPopup-details-activity'>{postsPopupData.emotionIcon} {postsPopupData.emotionName}</span>
                }
              </div>

              {
                postsPopupData.title
                &&
                <span className='profilePostsPopup-details-location'>{postsPopupData.title}</span>
              }

              <span className='profilePostsPopup-details-time'>
                <PostTime date={postsPopupData.createdAt} />
              </span>
            </div>

            <img className='profilePostsPopup-details-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" onClick={() => setShowProfilePostThreeDotPopup(true)} />
          </div>

          <hr className="profilePostsPopup-details-hr" />

          {/* Comments */}
          <AddComments showProfilePostThreeDotPopup={showProfilePostThreeDotPopup} showProfilePostCommentThreeDotPopup={showProfilePostCommentThreeDotPopup} setShowProfilePostCommentThreeDotPopup={setShowProfilePostCommentThreeDotPopup} setComment={setComment} commentType={"post"} clickedreply={clickedreply} setClickedReply={setClickedReply} postId={postId} viewReply={viewReply} postsPopupData={postsPopupData} comments={comments} setComments={setComments} parentSpinner={parentSpinner} setParentSpinner={setParentSpinner} />


          <hr className="profilePostsPopup-details-hr" />

          {/* Area */}
          <div className='profilePostsPopup-details-area' >
            <div className='profilePostsPopup-details-area-div'>
              {
                (postsPopupData.viewCount === 0)
                  ?
                  <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
                  :
                  <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
              }
              <span className='profilePostsPopup-details-area-count'onMouseOver={handleMouseOver} onMouseOut={handleMouseOver}>{postsPopupData.viewcount}</span>
            </div>

            <div className='profilePostsPopup-details-area-div'>
              <div onMouseOver={handleMouseOver2} onMouseOut={handleMouseOver2}>

              <LikeButton postId={postsPopupData.postId}  />
              </div>
            </div>

            <div className='profilePostsPopup-details-area-div'>
              <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" />
              <span className='profilePostsPopup-details-area-count'>{postsPopupData.commentcount}</span>
            </div>

            <div className='profilePostsPopup-details-area-div' onClick={() => setShowPostSharePopup(true)}>
              <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" />
              <span className='profilePostsPopup-details-area-count'>{postsPopupData.shareCount}</span>
            </div>

            {/* <div className='profilePostsPopup-details-area-favourite'>
              <FavouritePostButton postId={postsPopupData.postId} />
            </div>


            <a href={AwsFileUrl + postsPopupData.mediaUrls[0].url} download={true} target='_blank' >
              <img className='profilePostsPopup-details-area-download' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="download" />
            </a> */}
{isHovered && 
            <div style={{ background: "#000000",  opacity : "0.8", position: "absolute", width: "150px",  height: 'auto',  zIndex: "99", bottom: "338px" }} className='rounded'>
              
              <div className='px-2 py-1'>   
                <strong> View </strong> 

                <p>
                {dataview.views.topNames.length > 0
                  ? dataview.views.topNames.join(", ")
                  : "No views yet"}

                </p>
           

              <p> and {dataview.views.count} more...</p>
              </div>
            </div>
}

{  isHoveredlike && 
  <div style={{ background: "#000000",  opacity : "0.8", position: "absolute", width: "150px",  height: 'auto',  zIndex: "99", bottom: "338px", right : "35%" }} className='rounded'>
              
               <div className='px-2 py-1'>   
                <strong> Like </strong> 

                <p>
                {dataview.likes.topNames.length > 0
                  ? dataview.likes.topNames.join(", ")
                  : "No views yet"}

                </p>
           

              <p> and {dataview.likes.count} more...</p>
              </div>
              
            </div>
}

          </div>
                

          {/* Description */}
          {postsPopupData && postsPopupData.details && postsPopupData.details.length > 0 && <div className='profilePostsPopup-details-description'>{postsPopupData.description}</div>}
        </section>}
      </section>
    </>
  );
};

export default ShowCopyLink;
