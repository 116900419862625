import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessCategoryPopup = () => {

  const { showCategoryPopup, setShowCategoryPopup, categories, pageAbout, setPageAbout, createCategorySubCategory, setPageDetails } = useContext(businessContext);

  // Function to get the subcategories for the selected category
  const getSubCategories = (selectedCategory) => {
    const categoryObj = categories.find(cat => cat.category === selectedCategory);

    return categoryObj ? categoryObj.subcategories : [];
  };

  // To handle category change
  const handleCategoryChange = (e) => {
    setPageAbout((prevState) => ({ ...prevState, category: e.target.value, subCategory: '' }));
    setPageDetails((prevState) => ({ ...prevState, pageCategory: e.target.value, pageSubCategory: '' }));
  };

  // To handle sub category change
  const handleSubCategoryChange = (e) => {
    setPageAbout((prevState) => ({ ...prevState, subCategory: e.target.value, }));
    setPageDetails((prevState) => ({ ...prevState, pageSubCategory: e.target.value, }));
  };

  return (
    <>
      <Modal show={showCategoryPopup} onHide={() => setShowCategoryPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Categories</h4>

          <span className='bg-secondary-subtle px-3 rounded-3 mb-3 shadow-sm'>Category</span>

          <select className="form-select ms-3 mb-5 border-0 border-bottom shadow-none" aria-label="Select Page Category" 
            value={pageAbout.category} onChange={handleCategoryChange} 
          >
            <option value="" disabled>{pageAbout.category}</option>

            {
              categories.map((cat, index) => (
                <option key={index} value={cat.category}>{cat.category}</option>
              ))
            }
          </select>

          <span className='bg-secondary-subtle px-3 rounded-3 mb-3 shadow-sm'>Sub Category</span>

          <select className="form-select ms-3 mb-4 border-0 border-bottom shadow-none" aria-label="Select Sub Category" 
            value={pageAbout.subCategory} onChange={handleSubCategoryChange}  
          >
            <option value="" disabled>{pageAbout.subCategory ? pageAbout.subCategory : 'Select Sub Category'}</option>

            {
              getSubCategories(pageAbout.category).map((subcat, index) => (
                <option key={index} value={subcat}>{subcat}</option>
              ))
            }
          </select>

          <div className='btn btn-primary btn-sm rounded-pill px-3 mb-5'>
            <img src={`/images/business/icons/add-circle-white.svg`} className='me-3' style={{ height: '20px' }} alt="add-circle" />

            <span>Add More Sub Categories</span>
          </div>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createCategorySubCategory}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessCategoryPopup
