import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const OnboardingDOB = () => {

  const [DOB, setDOB] = useState('');

  return (
    <>
      <section className='onboardingDOB'>
        <div className='onboardingDOB-area'>
          {/* Emilo Logo */}
          <div className='onboardingDOB-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingDOB-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingDOB-area-data'>
            <h2 className='onboardingDOB-area-data-head'>Date of Birth</h2>

            <p className='onboardingDOB-area-data-message'>We want to make sure you are over 14</p>

            <form>
              <label htmlFor="dob">
                <input type="date" name="dob" id="dob" value={DOB} onChange={(e) => setDOB(e.target.value)} />
              </label>
            </form>
          </div>

          {/* Button */}
          <div className='onboardingDOB-area-button w-100 text-center'>
            <Link to={`/SoftCorner/OnboardingPin`} className='w-75 mx-auto'>
              <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={() => localStorage.setItem('dob', DOB)}>Next</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingDOB;
