import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../../UseContext/ApiCall';

const VisibilityPostPopup = ({ postsPopupData }) => {

  const { config, visibility, setVisibility, checkVisibility, handleVisibility, showVisibilityPostPopup, setShowVisibilityPostPopup, } = useContext(ApiContext);

  useEffect(() => {
    postsPopupData && checkVisibility(postsPopupData.postId, config);
  }, [postsPopupData]);

  return (
    <>
      <section className='visibilityPostPopups'>
        <Modal show={showVisibilityPostPopup} onHide={() => setShowVisibilityPostPopup(false)} size="sm" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='visibilityPostPopup'>
              <form className='visibilityPostPopup-form'>
                <input type="radio" id="following" name="visibility" onChange={() => setVisibility('following')} checked={visibility === 'following'} />
                <label htmlFor="following">Following</label> 

                <input type="radio" id="everyone" name="visibility" onChange={() => setVisibility('everyone')} checked={visibility === 'everyone'} />
                <label htmlFor="everyone">Everyone</label>

                <span className='visibilityPostPopup-done' onClick={() => handleVisibility(postsPopupData.postId, visibility, config)}>Done</span>
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default VisibilityPostPopup
