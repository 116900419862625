import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css';
import { BASE_URL_STATIC, BASEAPIURL } from "../../../config";
import axios from "axios";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import '../../../assets/css/softcorner/search-box.css'
import { ScApiContext } from "../useContext/softCornerContext";

const ScHeader = () => {  

  const handleLocate = () => {
    window.location.href = "/";
  };

  const [users, setUsers] = useState([])
  const [inputValue , setinputValue] = useState("")

  const { config } = useContext(ApiContext)
  const {setRightBlockUser} = useContext(ScApiContext)
  
  const searchBarRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!searchBarRef.current || !searchBarRef.current.contains(event.target)) {
      setUsers([])
    }
  };


  const handleSearch = async (e) => {
    e.preventDefault();
    setinputValue(e.target.value)
    if (e.target.value.length > 0) {   
      try {
        const res = await axios.post(`${BASEAPIURL}/user/getalluser?page=1&search=${e.target.value}`, { type: 'softcorner' }, config)
        setUsers(res.data.users)
       
      } catch (error) {
        return error.response.data;
      }
    }else{
      setUsers([])
    }
  };

  const nav = useNavigate();

  const handleSearchAdd = (user) => {
    setRightBlockUser(user)
    setUsers([])
    setinputValue("")
    nav('/SoftCorner')
  };

  const urls = ['OnboardingUser', 'OnboardingMobile', 'OnboardingOTP', 'OnboardingImage', 'OnboardingGender', 'OnboardingDOB', 'OnboardingPin', 'checkSoftCornerPin', 'forgotSoftcornerPin', 'chat'];

  return (
    <>
      <section className={`${urls.some(url => window.location.href.includes(url)) ? 'd-none' : 'd-flex'} ${window.location.href.includes('chat') && 'd-none d-lg-flex'} justify-content-between align-items-center position-fixed z-2 w-100 mx-auto bg-white shadow-sm p-3 px-md-4 px-lg-5`}>
      {/* <section className={`d-none`}> */}
        {/* Logo */}
        <div className="">
          <img src={`${BASE_URL_STATIC}/logo.png`} className="sc-nav-logo" alt="logo" onClick={() => handleLocate()} />
        </div>

        {/* Search > Mobile */}
        <div className="d-md-none">
          <i className="fa fa-search" style={{ fontSize: '20px' }}></i>
        </div>

        {/* Search > Tab & Laptop */}
        <div className="d-none d-md-flex align-items-center px-3 rounded-pill w-50" style={{ boxShadow: '0px 0px 10px #d5d5d5' }}>
          <i className="fa fa-search me-2" style={{ fontSize: '20px' }}></i>
          <input type="search" className="form-control border-0 shadow-none bg-transparent w-100 rounded-pill" value={inputValue} onChange={handleSearch} placeholder="Search Users..." />
        </div>

        {/* Search > Users List */}
        <>
          {
            (users.length > 0) 
            && 
            <div className="list-body" ref={searchBarRef}>
              {
                users.map((user , i) => {
                  return (
                    <div key={i} className="list border-bottom" onClick={() => handleSearchAdd(user)}>
                      <img src={`${BASE_URL_STATIC}/dummy.png`} alt="" width='40px' />
                      <div className="flex w-100">
                        <div className="d-flex flex-column ml-3">
                          <span>{user.fname}</span>
                          <small>{user.address}</small>
                        </div>
                        {user.scProfileId && <img src={`${BASE_URL_STATIC}/softcornerLogo.png`} alt="" width='20px' />}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </>

        {/* Notification */}
        <Link to="/SoftCorner/notifications" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notification.svg`} alt="" />
          <span></span>
        </Link>

        {/* Chat */}
        <Link to="/SoftCorner/chat" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-chat.svg`} alt="" />
        </Link>

        {/* Profile */}
        <Link to="/SoftCorner/Profile" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-person.svg`} alt="" />
        </Link>
      </section>
    </>
  );
};

export default ScHeader;
