import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import { useParams } from 'react-router';

const ReportUser = () => {

  const { reportUser, setreportUser, handleReportPost, loggedInUserId, showReportUserPopup, setShowReportUserPopup } = useContext(ApiContext);

  const { paramsUserId } = useParams();


  return (
    <>
      <section className='reportPostPopups'>
        <Modal show={showReportUserPopup} onHide={() => setShowReportUserPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report</h4>

              <textarea className='reportPostPopup-textarea' value={reportUser} onChange={(e) => setreportUser(e.target.value)} name="report" placeholder='Report here...'></textarea>

              <span className='reportPostPopup-report' onClick={() => handleReportPost(paramsUserId , "User" , loggedInUserId, reportUser)}>Report</span>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportUser
