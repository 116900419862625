import React, { useContext, useEffect, useState} from "react";
import BackToTop from "../Helper/BackToTop.js";
import { getExplorePost } from "../UseContext/ApiHelpers";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../config.js";
import { ApiContext } from "../UseContext/ApiCall.js";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup.js";


const Explore = ({ className }) => {

  const [allPosts, setAllPosts] = useState([])
  const [postData, setPostData] = useState(null);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');

  console.log('allPosts : ', allPosts);

  const { config } = useContext(ApiContext)

  document.title = "Emilo-explore";

  useEffect(() => {
    getExplorePost(config).then((res) => {
      if (res.posts) {
        setAllPosts(res.posts);
      };
    });
  }, []);
  

  return (
    <>
      <section>
        {/* <ExplorePopup open={openPopup} postData={postData} close={() => setOpenPopup(false)} /> */}

        <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />

        <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
          <div className="explore-sec mt-3 mt-sm-5 mt-lg-5">
            {
              allPosts
              &&
              allPosts.map((post, index) => (
                <div className="rounded-4 position-relative cursor-pointer" style={{ height: '200px' }} onClick={() => { setShowPostsPopup(true); setPostId(post._id); setPostData(post); }} key={index}>
                  {
                    post.mediaUrls[0] && post.mediaUrls[0].filetype === 'image'
                    ?
                    <img src={AwsFileUrl+post.mediaUrls[0].url} className="h-100 w-100 rounded-4 object-fit-cover shadow-sm" alt="explore" />
                    :
                    <img src={AwsFileUrl+post.postTumbnailUrl} className="h-100 w-100 rounded-4 object-fit-cover shadow-sm" alt="explore" />
                  }

                  <div className="px-2 pb-1 rounded-3 position-absolute top-0 end-0 mt-3 me-3" style={{ backgroundColor: '#e2e3e591' }}>
                    <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="post" />
                  </div>

                  <div className="hover-info position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex align-items-center justify-content-center">
                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon4.png`} className="me-1" alt="Views" />
                      <span className="text-white">{post._id && post.viewcount}</span>
                    </span>

                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon5.png`} className="me-1" alt="Likes" />
                      <span className="text-white">{post._id && post.likecount}</span>
                    </span>

                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon6.png`} className="me-1" alt="Comments" />
                      <span className="text-white">{post._id && post.commentcount}</span>
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </section>


        {/* <section className={`section-main ${className || ""}`}>
          <div className="row no-gutters">
            <div className="col-lg-12">
              <div className="center-block">
                <div className="explore-block">
                  <div className="row no-gutters ">
                    <div className="masonary">
                      {
                        allPosts
                        &&
                        allPosts.map((posts, index) => (

                          <div className="imgBox explore" key={index}  >
                            {
                              posts._id && posts.mediaUrls[0] && posts.mediaUrls[0].filetype === 'image'
                                ?
                                <>
                                  <LazyLoad >
                                    <img className="explore-post-img" src={AwsFileUrl + posts.mediaUrls[0].url} alt="post" onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-1.png`} />
                                  </LazyLoad>

                                  <div className="explore-post-icon">
                                    <img className="explore-post-icon-img" src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="post" />
                                  </div>
                                </>
                                :
                                <>
                                  <LazyLoad>
                                    <img className="explore-post-img" src={AwsFileUrl + posts.postTumbnailUrl} alt="post" onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-1.png`} />
                                  </LazyLoad>

                                  <div className="explore-post-icon">
                                    <img className="explore-post-icon-img" src={`${BASE_URL_STATIC}/icon/white-videos.svg`} alt="icon" />
                                  </div>
                                </>
                            }

                            <div className="likeBox" onClick={() => { setShowPostsPopup(!showPostsPopup); setPostId(posts._id); setPostData(posts) }}>
                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon4.png`} alt="" />
                                {posts._id && posts.viewcount}
                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon5.png`} alt="" />
                                {posts._id && posts.likecount}
                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon6.png`} alt="" />
                                {posts._id && posts.commentcount}
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <BackToTop />
      </section>
    </>
  );
};

export default Explore;