import React, { useContext, useEffect } from 'react'
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import SettingInputUser from './SettingInputUser';
import { getValue, getAdded } from '../../../UseContext/SettingHelper';



const AccountProfile = () => {

  const { 
    // ---------- Value ----------

    value, setValue,

    handleOptionChange,

    // ---------- Followers Except ----------

    setAdded,

  } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can view your',
    closeFriends: 'Only your close friends can view your',
    followers: 'Only your followers can view your',
    fof: 'Your followers & as well as their followers can view your',
    none: 'No one can view your',
  };

  const { config, loggedInUserName, loggedInUserImage, loggedInUser } = useContext(ApiContext);


  // To get profile info Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('profile', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get added (Followers Except and Hide From)
    getAdded('profile', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);
  
  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 mb-5 mb-lg-0 overflow-scroll scrollbar-hidden' id='accordionToggle1'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Basic Info</li>
          </ol>
        </nav>

        {/* Profile */}
        <div className='text-center mb-5'>
          <img className='ps-profile rounded-circle' src={loggedInUserImage} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`}  />

          <h4 className='fw-bold mt-3'>{loggedInUserName}</h4>

          <span className='text-secondary'>Raipur, Chhattisgarh</span>
        </div>

        {/* Who can view your contact info? */}
        <div className='accordion accordion-flush mb-3'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contactInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Who can view your contact info?</h5>
          </div>

          <div id='contactInfo' className="accordion-collapse collapse ms-3" data-bs-parent="#accordionToggle1" >
            {/* Mobile Number */}
            <div className='my-3 accordion accordion-flush'> 
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#mobileNumber">Mobile Number</h5>

              <div id='mobileNumber' className="accordion-collapse collapse show mt-3 ms-3 me-0">
                <span className='py-1'>Current mobile no. is <span className='text-primary'>{loggedInUser && loggedInUser.mobile}</span></span>

                {/* Value */}
                <div className='row w-100'>
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-public" name="mobileNumber" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'public'} />
                    <label className="form-check-label" htmlFor="ps-mn-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-followers" name="mobileNumber" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'followers'} />
                    <label className="form-check-label" htmlFor="ps-mn-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-cf" name="mobileNumber" value='closeFriends' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'closeFriends'} />
                    <label className="form-check-label" htmlFor="ps-mn-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-fof" name="mobileNumber" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-mn-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-mn-none" name="mobileNumber" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.mobileNumber === 'none' || !value.mobileNumber} />
                    <label className="form-check-label" htmlFor="ps-mn-none">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.mobileNumber ? value.mobileNumber : "none"]} Mobile No.)</small>
                </div>

                {/* Input */}
                <>
                  {
                    ((value.mobileNumber === 'public') || (value.mobileNumber === 'followers') || (value.mobileNumber === 'fof'))
                    &&
                    <>
                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          value.mobileNumber === 'public' ? 'mobileNumberPublic' : 
                          value.mobileNumber === 'followers' ? 'mobileNumberFollowers' : 
                          value.mobileNumber === 'fof' && 'mobileNumberFOF'
                        }
                        settingType={value.mobileNumber && value.mobileNumber} 
                        header={
                          value.mobileNumber === 'public' ? 'Hide mobile number from specific people' :
                          value.mobileNumber === 'followers' ? 'Followers Except' :
                          value.mobileNumber === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your mobile number' 
                      />
                    </>
                  }
                </>

                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
              </div>
            </div>

            {/* Email Id */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#emailId">Email Id</h5>

              <div id='emailId' className="accordion-collapse collapse show mt-3 ms-3 me-0">
                <span className='py-1'>Current email id is <span className='text-primary'>{loggedInUser && loggedInUser.email}</span></span>

                {/* Value */}
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-public" name="emailId" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'public'} />
                    <label className="form-check-label" htmlFor="ps-eid-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-followers" name="emailId" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'followers'} />
                    <label className="form-check-label" htmlFor="ps-eid-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-cf" name="emailId" value='closeFriends' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'closeFriends'} />
                    <label className="form-check-label" htmlFor="ps-eid-cf">Close Friends</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-fof" name="emailId" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-eid-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-eid-none" name="emailId" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.emailId === 'none' || !value.emailId} />
                    <label className="form-check-label" htmlFor="ps-eid-none">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.emailId ? value.emailId : "none"] } Email Id.)</small>
                </div>

                {/* Input */}
                <>
                  {
                    ((value.emailId === 'public') || (value.emailId === 'followers') || (value.emailId === 'fof'))
                    &&
                    <>
                      <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                      <SettingInputUser 
                        settingNav='profile' 
                        settingKey={
                          value.emailId === 'public' ? 'emailIdPublic' : 
                          value.emailId === 'followers' ? 'emailIdFollowers' : 
                          value.emailId === 'fof' && 'emailIdFOF'
                        }
                        settingType={value.emailId && value.emailId} 
                        header={
                          value.emailId === 'public' ? 'Hide Email Id from specific people' :
                          value.emailId === 'followers' ? 'Followers Except' :
                          value.emailId === 'fof' && 'Followers and their followers except'
                        }
                        message='You can select name of people whom you don’t want to show your Email Id' 
                      />
                    </>
                  }
                </>

                <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
              </div>
            </div>
          </div>
        </div>

        {/* Who can view your other info? */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#otherInfo">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Who can view your other info?</h5>
          </div>

          <div id='otherInfo' className="accordion-collapse collapse ms-3" data-bs-parent="#accordionToggle1">
            {/* DOB */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4' data-bs-toggle="collapse" data-bs-target="#dob">Date of Birth</h5>

              <div id='dob' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-public" name="dob" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'public'}  />
                    <label className="form-check-label" htmlFor="ps-dob-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-followers" name="dob" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'followers' || !value.dob}  />
                    <label className="form-check-label" htmlFor="ps-dob-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-fof" name="dob" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-dob-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-dob-following" name="dob" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.dob === 'none'} />
                    <label className="form-check-label" htmlFor="ps-dob-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.dob ? value.dob : "followers"]} Date of Birth.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Place Lived */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#placeLived">Place Lived</h5>

              <div id='placeLived' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-public" name="placeLived" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'public'} />
                    <label className="form-check-label" htmlFor="ps-placeLived-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-followers" name="placeLived" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'followers' || !value.placeLived}  />
                    <label className="form-check-label" htmlFor="ps-placeLived-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-fof" name="placeLived" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-placeLived-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-placeLived-following" name="placeLived" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.placeLived === 'none'} />
                    <label className="form-check-label" htmlFor="ps-placeLived-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.placeLived ? value.placeLived : 'followers']} Place Lived.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Education */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#education">Education</h5>

              <div id='education' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-public" name="education" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'public'} />
                    <label className="form-check-label" htmlFor="ps-education-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-followers" name="education" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'followers' || !value.education}  />
                    <label className="form-check-label" htmlFor="ps-education-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-fof" name="education" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-education-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-education-following" name="education" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.education === 'none'} />
                    <label className="form-check-label" htmlFor="ps-education-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.education ? value.education : 'followers']} Education.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Work Place */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#workPlace">Work Place</h5>

              <div id='workPlace' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-workPlace-public" name="workPlace" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.workPlace === 'public'} />
                    <label className="form-check-label" htmlFor="ps-workPlace-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-workPlace-followers" name="workPlace" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.workPlace === 'followers' || !value.workPlace}  />
                    <label className="form-check-label" htmlFor="ps-workPlace-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-workPlace-fof" name="workPlace" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.workPlace === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-workPlace-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-workPlace-following" name="workPlace" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.workPlace === 'none'} />
                    <label className="form-check-label" htmlFor="ps-workPlace-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.workPlace ? value.workPlace : 'followers']} Work Place.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Marital Status */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#maritalStatus">Marital Status</h5>

              <div id='maritalStatus' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-public" name="maritalStatus" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'public'} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-followers" name="maritalStatus" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'followers' || !value.maritalStatus}  />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-fof" name="maritalStatus" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-maritalStatus-following" name="maritalStatus" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.maritalStatus === 'none'} />
                    <label className="form-check-label" htmlFor="ps-maritalStatus-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.maritalStatus ? value.maritalStatus : 'followers']} Marital Status.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Spouse Name */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#spouseName">Spouse Name</h5>

              <div id='spouseName' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-public" name="spouseName" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'public'} />
                    <label className="form-check-label" htmlFor="ps-spouseName-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-followers" name="spouseName" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'followers' || !value.spouseName}  />
                    <label className="form-check-label" htmlFor="ps-spouseName-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-fof" name="spouseName" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-spouseName-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-spouseName-following" name="spouseName" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.spouseName === 'none'} />
                    <label className="form-check-label" htmlFor="ps-spouseName-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.spouseName ? value.spouseName : 'followers']} Spouse Name.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Marriage Date */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#marriageDate">Marriage Date</h5>

              <div id='marriageDate' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-public" name="marriageDate" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'public'} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-followers" name="marriageDate" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'followers' || !value.marriageDate}  />
                    <label className="form-check-label" htmlFor="ps-marriageDate-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-fof" name="marriageDate" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-marriageDate-following" name="marriageDate" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.marriageDate === 'none'} />
                    <label className="form-check-label" htmlFor="ps-marriageDate-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.marriageDate ? value.marriageDate : 'followers']} Marriage Date.)</small>


                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Family And Relationship */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#familyRelationship">Family And Relationship</h5>

              <div id='familyRelationship' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-public" name="familyRelationship" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'public'} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-followers" name="familyRelationship" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'followers' || !value.familyRelationship}  />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-fof" name="familyRelationship" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-familyRelationship-following" name="familyRelationship" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.familyRelationship === 'none'} />
                    <label className="form-check-label" htmlFor="ps-familyRelationship-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.familyRelationship ? value.familyRelationship : 'followers']} Family And Relationship.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Skills And Hobbies */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#skillsHobbies">Skills And Hobbies</h5>

              <div id='skillsHobbies' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skillsHobbies-public" name="skillsHobbies" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skillsHobbies === 'public'} />
                    <label className="form-check-label" htmlFor="ps-skillsHobbies-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skillsHobbies-followers" name="skillsHobbies" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skillsHobbies === 'followers' || !value.skillsHobbies}  />
                    <label className="form-check-label" htmlFor="ps-skillsHobbies-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skillsHobbies-fof" name="skillsHobbies" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skillsHobbies === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-skillsHobbies-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-skillsHobbies-following" name="skillsHobbies" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.skillsHobbies === 'none'} />
                    <label className="form-check-label" htmlFor="ps-skillsHobbies-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.skillsHobbies ? value.skillsHobbies : 'followers']} Skills And Hobbies.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Most Hangout Places */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#hangoutPlaces">Most Hangout Places</h5>

              <div id='hangoutPlaces' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-public" name="hangoutPlaces" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'public'} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-followers" name="hangoutPlaces" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'followers' || !value.hangoutPlaces}  />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-fof" name="hangoutPlaces" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-hangoutPlaces-following" name="hangoutPlaces" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.hangoutPlaces === 'none'} />
                    <label className="form-check-label" htmlFor="ps-hangoutPlaces-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.hangoutPlaces ? value.hangoutPlaces : 'followers']} Most Hangout Places.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>

            {/* Other Social Media Links */}
            <div className='my-3 accordion accordion-flush'>
              <h5 className='shadow-sm accordion-level-4'  data-bs-toggle="collapse" data-bs-target="#socialMedia">Other Social Media Links</h5>

              <div id='socialMedia' className="accordion-collapse collapse show ms-3 mt-3">
                <div className='row w-100'> 
                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-public" name="socialMedia" value='public' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'public'} />
                    <label className="form-check-label" htmlFor="ps-socialMedia-public">Public</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-followers" name="socialMedia" value='followers' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'followers' || !value.socialMedia}  />
                    <label className="form-check-label" htmlFor="ps-socialMedia-followers">Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-fof" name="socialMedia" value='fof' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'fof'} />
                    <label className="form-check-label" htmlFor="ps-socialMedia-fof">Followers of Followers</label>
                  </div>

                  <div className="ms-4 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="ps-socialMedia-following" name="socialMedia" value='none' onChange={(e) => handleOptionChange(e, 'profile')} checked={value.socialMedia === 'none'} />
                    <label className="form-check-label" htmlFor="ps-socialMedia-following">No one</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2'>({message[value.socialMedia ? value.socialMedia : 'followers']} Other Social Media Links.)</small>

                  <hr className='hr bg-secondary mt-3 mb-0' style={{ width: '-webkit-fill-available' }}  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Who can view your common info? */}
        <div className=''>
          <div>Note : - </div>
        </div>










        {/* Close Friends */}
        {/* <Link to={`closeFriends`}>
          <div className='flex'>
            <div className='flex justify-content-start'>
              <img src={`${BASE_URL_STATIC}/icon/closeFriend.svg`} alt="" />
              <div>
                <span style={{ fontSize: "14px" }}>Close Friends</span>
                <small style={{ fontSize: "12px", color: "#848484" }}>Effortlessly add or remove friends posts, & updates on priority</small>
              </div>
            </div>

            <span>&gt;</span>
          </div>
          <hr />
        </Link> */}

        {/* Favourites People */}
        {/* <Link to={`favourites`}>
          <div className='flex'>
            <div className='flex justify-content-start'>
              <img src={`${BASE_URL_STATIC}/icon/friendFav.svg`} alt="" />
              <div>
                <span style={{ fontSize: "14px" }}>Favourites People</span>
                <small style={{ fontSize: "12px", color: "#848484" }}>Manage your Favourites lists to see your favourites posts, & updates on priority</small>
              </div>
            </div>

            <span>&gt;</span>
          </div>
          <hr />
        </Link> */}

        {/* Unfollow Users */}
        {/* <Link to={`unfollow`}>
          <div className='flex'>
            <div className='flex justify-content-start'>
              <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
              <div>
                <span style={{ fontSize: "14px" }}>Unfollow Users</span>
                <small style={{ fontSize: "12px", color: "#848484" }}>Stop seeing his/her post.</small>
              </div>
            </div>

            <span>&gt;</span>
          </div>
          <hr />
        </Link> */}

        {/* Block */}
        {/* <Link to={`block`}>
          <div className='flex'>
            <div className='flex justify-content-start'>
              <img src={`${BASE_URL_STATIC}/icon/friendBlock.svg`} alt="" />
              <div>
                <span style={{ fontSize: "14px" }}>Block</span>
                <small style={{ fontSize: "12px", color: "#848484" }}>Add people to block list or manage people you block before</small>
              </div>
            </div>

            <span>&gt;</span>
          </div>
        </Link> */}
      </section>
    </>
  );
};

export default AccountProfile;