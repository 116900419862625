import React, { useContext, useEffect, useRef, useState } from "react";
import CreateNewPost from "../../ScPopup/CreateNewPost";
import { ScApiContext } from "../../useContext/softCornerContext";
import { getPartnerinfo, getPartnerPosts, getPosts } from "../../../Emilo/UseContext/ScApiHelpers";
import Slider from "react-slick";
import PostTime from "../../../Emilo/Home/CenterBlock/CenterBlockPosts/PostTime";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../../config";
import { ApiContext } from "../../../Emilo/UseContext/ApiCall";
import { scResponsive } from "../../../Emilo/Data/EducationData";



const CenterBlock = ({ setdisplayCB }) => {

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [postId, setpostId] = useState(null);
  const [favoritePosts, setFavoritePosts] = useState({});
  const [openText, setOpenText] = useState(false)
  const [PageContent, setPageContent] = useState("")
  console.log(openText)


  

  const { config } = useContext(ApiContext);

  document.title = "Emilo"

  const { listPost, setProfileIndex, setListPost, setSlidertype, partnerPosts, allPosts, setAllPosts,
    setPartnerPosts, scConfig, setRightBlockUser, isSoftCornerPartnerExist, handleFileChange, posts, createPop, setCreatePop,
    softCornerUsers, setIsSoftCornerPartnerExist, setSoftCornerUsers, setDisableNext
  } = useContext(ScApiContext);

  const inputRef = useRef();

  useEffect(() => {
    getSoftCornerUsers();
    getPosts(scConfig).then((res) => setListPost(res));
    getPartnerPosts(scConfig).then((res) => setPartnerPosts(res));
  }, []);

  useEffect(() => {
    checkFavouritePostId(postId, scConfig);
  }, [postId]);

  useEffect(() => {
    getPartnerinfo(config).then((res) => {
      if (res.status === 200) {
        setIsSoftCornerPartnerExist(true);
      } 
      else if (res.status === 201) {
        setIsSoftCornerPartnerExist(false);
      } else {
        setIsSoftCornerPartnerExist(false)
      }
    })
  } , [isSoftCornerPartnerExist])


  useEffect(() => {
    if (partnerPosts && listPost) {
      const allPostsArray = [...partnerPosts, ...listPost];
      if (allPostsArray) {
        allPostsArray.sort(
          (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
        );
        setAllPosts(allPostsArray);
        const postIds = allPostsArray.map((post) => post.postId);
        postIds.forEach((postId) => {
          checkFavouritePostId(postId, scConfig);
        });
      }
    }
  }, [partnerPosts, listPost]);

  const feedslider = {
    autoplay: false,
    arrows: true,
    dots: false,
    slidesToShow: 5,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: scResponsive
  };

  const checkFavouritePostId = (postId, scConfig) => {
    axios
      .post(
        `${BASEAPIURL}/softcorner/checkscpostinfav`,
        { postId: postId },
        scConfig
      )
      .then((res) => {
        setFavoritePosts((prev) => ({ ...prev, [postId]: res.data.added }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // To add post to favourite
  const createFavouritePostId = async (postId, scConfig) => {
    try {
      const isPostFavorite = favoritePosts[postId] || false;
      let action = !isPostFavorite ? "remove" : "add";

      const res = await axios.post(
        `${BASEAPIURL}/softcorner/createscfavpost`,
        { postId: postId, action: action },
        scConfig  
      );

      // Update local state only if the API call is successful
      if (res) {
        setFavoritePosts((prev) => ({ ...prev, [postId]: !isPostFavorite }));
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // To get softcorner user list
  const getSoftCornerUsers = async () => {
    await axios.post(`${BASEAPIURL}/softcorner/scuserlist`, {}, scConfig)
      .then(res => {
        setSoftCornerUsers(res.data.message);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // To handle not intrested user of suggestion list
  const handleNotIntrestedUser = async (type, userId, scConfig) => {
   
     if (type === "sc") {
      setSoftCornerUsers((prev) => prev.filter((user) => user.emiloId !== userId));
    }

    try {
      await axios.post(`${BASEAPIURL}/softcorner/notintuser`, { userId }, scConfig);
    } catch (err) {
      console.error(err);
    }

  };

  // To add my profile view to other views
  const addProfileViews = async (type, otherUserId, config) => {
    // console.log(otherUserId);

    await axios.post(`${BASEAPIURL}/softcorner/createprofileviewsc`, { type, viewUserId: otherUserId }, config)
      .then(res => {
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="position-relative">
      {/* <ScTextPostPopup  PageContent={PageContent} setPageContent={setPageContent}  /> */}

      <CreateNewPost open={createPop} setCreatePop={setCreatePop} posts={posts} close={() => setCreatePop(!createPop)} />

      <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5 pb-lg-3">
        {
        !isSoftCornerPartnerExist
            ?
            <>
            {/* Div 3 */}
            <div className="py-3 pb-lg-0">
              <div className="py-3 py-sm-4">
                <div className="d-flex align-items-center">
                  <img className="ms-3 ms-md-4 ms-lg-3 ms-xl-4 me-2" src={`${BASE_URL_STATIC}/SoftCorner/suggestion-1.svg`} alt="suggestion" height={20} width={20} />
                  <span className="fw-semibold">Softcorner Users</span>
                </div>
              </div>

              <div className="sc-cb-user-cards">
                {
                  softCornerUsers.map((user, index) => (
                    <div className="sc-cb-user-card rounded-4 shadow-sm" key={index}>
                      <div className="position-relative">
                        {/* Profile */}
                        <div className="sc-cb-user-card-profile rounded-4">
                              <img src={`${AwsFileUrl + user.profileImg.imageId}`} className="h-100 w-100 object-fit-cover rounded-4" alt="user"  />
                        </div>

                        {/* Softcorner */}
                        <div className="sc-cb-user-card-icon-1 position-absolute top-100 translate-middle bg-white p-2 rounded-circle shadow-sm cursor-pointer" onClick={() => { addProfileViews("view", user.emiloId, config); setRightBlockUser(user); setProfileIndex(index); setSlidertype("softcorner"); setdisplayCB('right'); setDisableNext(false) }}>
                          <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} style={{ width: '20px' }} alt="logo" />
                        </div>

                        {/* Close */}
                        <div className="sc-cb-user-card-icon-2 position-absolute top-100 translate-middle bg-white px-2 pt-1 pb-2 rounded-circle shadow-sm cursor-pointer" onClick={() => handleNotIntrestedUser("sc", user.emiloId, scConfig)}>
                          <img src={`/images/icons/cut.svg`} style={{ height: '20px', width: '20px' }} alt="cut" />
                        </div>
                      </div>

                      <p className="mt-4 fw-bold text-center overflow-x-hidden text-nowrap text-truncate mx-auto" style={{ width: '90%' }}>{user.fname}</p>
                      <p className="mb-3 mx-auto overflow-hidden text-center sc-address" style={{ height: '27px', maxWidth: '80%', wordBreak: 'break-all' }}> {user.age} {user.address && "-"} {user.address && user.address}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </>
            :

            <>
            {/* Div 1 */}
            <div className="sc-cb-div-1 pt-4" >
              <form>
                <label htmlFor="save">
                  <div className="sc-postBox">
                    {PageContent.length > 0 ?
                      <div className="text-editor" style={{ marginTop: "42px" }}>
                        <div dangerouslySetInnerHTML={{ __html: PageContent }}></div>
                        <Button className="btn-1" onClick={() => setOpenText(true)}><CiEdit size={20} /></Button>
                        <Button className="btn-2" onClick={() => setPageContent("")}><IoClose size={20} /></Button>
                      </div>
                      :
                      <form className='image-wrap' id='image-wrap'>
                        <input type="text" readOnly className="cnp-textArea" name="createPostText" id="createPostText"
                          style={{
                            // backgroundImage: backgroundImage,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '437px 198px',
                          }}
                          // onChange={(e) => {setCreatePostText(e.target.value); setOpenText(true)}} 

                          onClick={() => setOpenText(true)}
                          cols="30" rows="5" placeholder="save a moment here "></input>
                      </form>
                    }
                  </div>
                </label>
                <label htmlFor="input">
                  <input type="file" id="input" ref={inputRef} multiple style={{ display: "none" }} onChange={(e) => handleFileChange(e)} />
                </label>
              </form>

              <div className="flex gap-3">
                <img onClick={() => inputRef.current.click()} src={`${BASE_URL_STATIC}/create-icon3.png`} alt="create-icon3" height={30} width={30} />
                <img src={`${BASE_URL_STATIC}/search.png`} alt="create-icon3" height={30} width={30} />
              </div>
            </div>

            {/* Div 2 */}
            <div className="sc-cb-div-2" >
              <div className="sc-cb-div-2-user-1">
                {
                  // allPosts 
                  allPosts.length !== 0
                    ?
                    allPosts.map((post, index) => (
                      <div key={index}>
                        {/* Profile */}
                        <div className="sc-cb-div-2-user-1-profile">
                          <div className="flex justify-content-start gap-3">
                            <div className="imgBox status">
                              {post.profileImg ? (
                                <img
                                  src={post.profileImg}
                                  alt="user-icon1"
                                  height={50}
                                  width={50}
                                />
                              ) : (
                                <img
                                  src={`${BASE_URL_STATIC}/dummy.png`}
                                  alt="user-icon1"
                                  height={50}
                                  width={50}
                                />
                              )}
                            </div>

                            <div className="sc-cb-div-2-user-1-profile-name">
                              <h5>{post.fname}</h5> <br />
                              <PostTime date={post.createdTime} />
                            </div>
                          </div>

                          <div className="flex justify-content-start gap-3">
                            <img
                              src={`${BASE_URL_STATIC}/create-icon1.png`}
                              alt="smiley"
                              height={30}
                              width={30}
                            />

                            {favoritePosts[post.postId] ? (
                              <img
                                onClick={() => {
                                  setpostId(post.postId);
                                  createFavouritePostId(post.postId, scConfig);
                                }}
                                src={`${BASE_URL_STATIC}/feed-icon5.png`}
                                alt="star"
                                height={30}
                                width={30}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setpostId(post.postId);
                                  createFavouritePostId(post.postId, scConfig);
                                }}
                                src={`${BASE_URL_STATIC}/favourite2.png`}
                                alt="star"
                                height={30}
                                width={30}
                              />
                            )}
                          </div>
                        </div>

                        {/* Post */}
                        <div className="sc-cb-div-2-user-1-post">
                          <Slider {...feedslider}>
                            {post.postId &&
                              post.details.map((image, index) => (
                                <div key={index}>
                                  {image.videoId && (
                                    <video
                                      autoPlay
                                      controls
                                      width="100%"
                                      style={{ borderRadius: "15px" }}
                                    >
                                      <source
                                        src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${image.videoId}`}
                                      />
                                    </video>
                                  )}
                                  {image.imageId && (
                                    <img src={image.imageUrl} alt="imageUrl" />
                                  )}
                                </div>
                              ))}
                          </Slider>
                        </div>

                        {/* Description */}
                        <div className="sc-cb-div-2-user-1-description py-4">
                          {post.description &&
                            post.description.length >= 100 &&
                            (expandedIndex === index
                              ? post.description
                              : post.description.substr(0, 100))}{" "}
                          <span
                            style={{
                              fontSize: "14px",
                              fontStyle: "italic",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setExpandedIndex(expandedIndex === index ? null : index)
                            }
                          >
                            {post.description &&
                              post.description.length >= 100 &&
                              (expandedIndex === index ? "...Less" : "...More")}
                          </span>
                        </div>
                      </div>
                    ))
                    :
                    <div className="sc-cb-div-2-user-1-blank">
                      <div className="sc-cb-div-2-user-1-blank-img">
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-no-post.svg`} alt="sc-no-post" />
                      </div>

                      <h3 className="sc-cb-div-2-user-1-blank-head">You have no activity : (</h3>
                      <span className="sc-cb-div-2-user-1-blank-msg">Looks like you hasn’t posted anything yet. Post an image to get started</span>
                    </div>
                }
              </div>
            </div>
          </>
            
        }

      </section>

      
      {/* <div className="sc-loader-head" style={{"zIndex" : 10}}> 
          <div className="sc-loader"></div>
        </div> */}
    </div>
  );
};

export default CenterBlock;
