import React, { useState } from 'react'
import PostTime from '../../Home/CenterBlock/CenterBlockPosts/PostTime'
import { deleteEmiloMsg } from '../../UseContext/ApiHelpers';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RiShareForwardFill } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import ForwardPop from "./ForwardPop"
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

import VoiceCallGoingPopup from "../../Popup/Chats/Chat/SingleCall/VoiceCallGoingPopup";
import VoiceCallComingPopup from "../../Popup/Chats/Chat/SingleCall/VoiceCallComingPopup";
import VideoCallGoingPopup from "../../Popup/Chats/Chat/SingleCall/VideoCallGoingPopup";
import VideoCallComingPopup from "../../Popup/Chats/Chat/SingleCall/VideoCallComingPopup";

// import { ICameraVideoTrack, IMicrophoneAudioTrack, IAgoraRTCClient, IAgoraRTCRemoteUser, } from "agora-rtc-sdk-ng";
// import { createClient, createCameraVideoTrack, createMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { AwsFileUrl, BASEURL, BASE_URL_STATIC } from '../../../../config';
import { Link } from 'react-router-dom';
import ChatInfo from './ChatInfo';

// Create Client
// const client: IAgoraRTCClient = createClient({
//   mode: "rtc",
//   codec: "vp8",
// });

// let audioTrack: IMicrophoneAudioTrack;
// let videoTrack: ICameraVideoTrack;


const ChatHeader = ({  messageObject,setChatUserList,chatUserList, setmessageObject, selectMsg, setArrivalMsg, setSelectedMsg, onlineStatus, chatProfile, chatId, config }) => {

  const [openFwd, setOpenFwd] = useState(false);

  const [show , setShow] = useState(false) 

  // const [showVoiceCallPopup, setShowVoiceCallPopup] = useState(false);
  // const [showVideoCallPopup, setShowVideoCallPopup] = useState(false);

  // const [isJoined, setIsJoined] = useState(false);
  // const [isAudioOn, setIsAudioOn] = useState(false);
  // const [isVideoOn, setIsVideoOn] = useState(false);
  // const [isAudioPubed, setIsAudioPubed] = useState(false);
  // const [isVideoPubed, setIsVideoPubed] = useState(false);
  // const [isVideoSubed, setIsVideoSubed] = useState(false);

  // To handle voice call
  // const handleVoiceCall = () => {
  //   console.log('**** Handle Voice Call *****');

  //   setShowVoiceCallPopup(true);

  //   joinChannel();
  //   publishAudio();
  // };



  // To handle video call
  // const handleVideoCall = () => {
  //   console.log('**** Handle Video Call *****');

  //   setShowVideoCallPopup(true);

  //   joinChannel();
  //   publishAudio();
  //   publishVideo();
  // };

  // To join channel
  // const joinChannel = async () => {
  //   console.log('***** Join Channel *****');

  //   if (isJoined) {
  //     await leaveChannel();
  //   };

  //   client.on("user-published", publishUser);

  //   await client.join(
  //     'c197571937024e8f9164185ebc639134',
  //     'first',
  //     '007eJxTYIioWHDk2+wGXw+9zNdnNFt8LvP53T5741mKzOZlrV9/3d+gwJBsaGluam5oaWxuYGSSapFmaWhmYmhhmpqUbGZsaWhsUnKUOa0hkJHBKkaelZEBAkF8Voa0zKLiEgYGAP1kILc=',
  //     null
  //   );

  //   setIsJoined(true);
  // };

  // To turn on microphone
  // const turnOnMicrophone = async (value) => {
  //   console.log('***** Turn On Microphone *****');

  //   // value = value ? value : !isAudioOn;

  //   // setIsAudioOn(value);

  //   if (audioTrack) {
  //     return audioTrack.setEnabled(value);
  //   };

  //   audioTrack = await createMicrophoneAudioTrack();
  //   audioTrack.play();
  // };

  // To publish audio
  // const publishAudio = async () => {
  //   console.log('***** Publish Audio *****');

  //   await turnOnMicrophone(true);

  //   // if (!isJoined) {
  //   //   await joinChannel();
  //   // };

  //   await client.publish(audioTrack);
  //   // setIsAudioPubed(true);
  // };

  // To turn on camera
  // const turnOnCamera = async (value) => {
  //   console.log('***** Turn On Camera *****');

  //   value = value ? value : !isVideoOn;
  //   setIsVideoOn(value);

  //   if (videoTrack) {
  //     return videoTrack.setEnabled(value);
  //   };

  //   videoTrack = await createCameraVideoTrack();
  //   videoTrack.play("camera-video");
  // };

  // To publish video
  // const publishVideo = async () => {
  //   await turnOnCamera(true);

  //   // if (!isJoined) {
  //   //   await joinChannel();
  //   // }

  //   await client.publish(videoTrack);
  //   // setIsVideoPubed(true);
  // };

  // To leave channel
  // const leaveChannel = async () => {
  //   console.log('***** leave *****');

  //   setIsJoined(false);
  //   // setIsAudioPubed(false);
  //   // setIsVideoPubed(false);

  //   turnOnMicrophone(false);
  //   turnOnCamera(false);

  //   await client.leave();
  // };

  // To publish user
  // const publishUser = async (user: IAgoraRTCRemoteUser, mediaType: "video" | "audio") => {
  //   console.log('***** Publish User *****');

  //   if (mediaType === "video") {
  //     const remoteTrack = await client.subscribe(user, mediaType);
  //     remoteTrack.play("remote-video");
  //     setIsVideoSubed(true);
  //   };

  //   if (mediaType === "audio") {
  //     const remoteTrack = await client.subscribe(user, mediaType);
  //     remoteTrack.play();
  //   };
  // };


  const formattedMessages = messageObject.map(({ createdTime, senderFname, message }) => {
    const date = new Date(createdTime);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
    const formattedTime = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

    return `[${formattedDate}, ${formattedTime}] ${senderFname}: ${message}\n`;
  });

  const messageString = formattedMessages.join('\n');

  const handleDelete = () => {
    selectMsg.forEach((messageId, i) => {
      deleteEmiloMsg(messageId, chatId, config)
        .then(() => {
          setSelectedMsg([]);

          setArrivalMsg((msgs) => msgs.filter((msg) => msg.messageId !== messageId));

          // getMessage(chatId, scConfig).then((res) => { setArrivalMsg(res); setSpinner(false) }).catch(() => setSpinner(false));
        })
        .catch((error) => {
          console.error(`Error deleting message ${messageId}:`, error);
        });
    });
  };

  if (selectMsg && selectMsg.length > 0) {
    return (
      <section className='chat-header position-absolute w-100 z-1 bg-white p-2 p-lg-3 d-flex align-items-center shadow-sm'>
        <ForwardPop open={openFwd} setArrivalMsg={setArrivalMsg} messageObject={messageObject} setmessageObject={setmessageObject} setSelectedMsg={setSelectedMsg} close={() => setOpenFwd(false)} />

        <div className='chats-rb-chat-t-header'>
          <div className='delete-header' >
            <div>
              <span onClick={() => setOpenFwd(true)}>
                <RiShareForwardFill />
              </span>
            </div>

            <div>
              <span>
                <CopyToClipboard text={messageString} onCopy={() => { setmessageObject([]); setSelectedMsg([]); toast.success("message copied") }}>
                  <MdOutlineContentCopy />
                </CopyToClipboard>
              </span>
            </div>

            <div className='del'>
              {selectMsg.length > 0 && <Button variant='danger' onClick={handleDelete}> <FontAwesomeIcon icon={faTrash} /> ({selectMsg.length})</Button>}

              {selectMsg.length > 0 && <Button variant='success' onClick={() => setSelectedMsg([])}> <FontAwesomeIcon icon={faClose} /></Button>}
            </div>
          </div>
        </div>
      </section>
    )
  };

  return (
    <>
      {/* Voice Call Going Popup */}
      {/* <VoiceCallGoingPopup showVoiceCallPopup={showVoiceCallPopup} setShowVoiceCallPopup={setShowVoiceCallPopup} chatProfile={chatProfile} turnOnMicrophone={turnOnMicrophone} leaveChannel={leaveChannel} /> */}
      <VoiceCallGoingPopup />

      {/* Voice Call Coming Popup */}
      <VoiceCallComingPopup />



      {/* Video Call Going Popup */}
      {/* <VideoCallGoingPopup showVideoCallPopup={showVideoCallPopup} setShowVideoCallPopup={setShowVideoCallPopup} chatProfile={chatProfile} turnOnMicrophone={turnOnMicrophone} turnOnCamera={turnOnCamera} leaveChannel={leaveChannel} isVideoSubed={isVideoSubed} isVideoOn={isVideoOn} /> */}
      <VideoCallGoingPopup />

      {/* Video Call Coming Popup */}
      <VideoCallComingPopup />

      <ChatInfo setChatUserList={setChatUserList} open={show} close={() => setShow(false)} chatProfile={chatProfile} chatId={chatId} config={config} chatUserList={chatUserList} />

      <section className='chat-header position-absolute w-100 z-1 bg-white p-2 p-lg-3 d-flex align-items-center shadow-sm'>
        {/* Back */}
        <Link to={`${BASEURL}/chats`} className='me-2 d-lg-none'>
          <img className='chat-rb-header-previous' src={`${BASE_URL_STATIC}/icon/previous.svg`} alt="<" />
        </Link>

        {/* Profile */}
        <>
          <img className='ch-profile rounded-circle' src={AwsFileUrl + (chatProfile.groupProfileImg || chatProfile.profileImg.imageId)} alt="profile" onClick={() => setShow(true)} onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
        </>

        {/* Name, online or offline, last seen */}
        <div className='col-5 col-sm-6 col-lg-5 col-xl-6 px-0 ms-2 ms-lg-3 me-auto'>
          <h6  className='text-dark text-nowrap overflow-hidden text-truncate mb-0'>{chatProfile.groupName || chatProfile.fname}</h6>

          {
            !chatProfile.groupName
            &&
            <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
              {!chatProfile.groupName && onlineStatus && onlineStatus.onlineStatus === "online" && <span className='online-status'></span>}
              {!chatProfile.groupName && onlineStatus && onlineStatus.onlineStatus === "online" ? onlineStatus.onlineStatus : <>last seen at <PostTime date={onlineStatus && onlineStatus.lastOnlineTime} /> ago</>}
            </small>
          }
        </div>
<div className='d-flex justify-content-end align-items-center gap-4'>

        {/* Voice Call */}
        {/* <img className='' src={`${BASE_URL_STATIC}/icon/voice-call.svg`} alt="voice" onClick={handleVoiceCall} /> */}
        <img className='' src={`${BASE_URL_STATIC}/icon/voice-call.svg`} alt="voice" />

        {/* Video Call */}
        {/* <img className='mx-auto' src={`${BASE_URL_STATIC}/icon/video-call.svg`} alt="video" onClick={handleVideoCall} /> */}
        <img className='mx-auto' src={`${BASE_URL_STATIC}/icon/video-call.svg`} alt="video" />

        {/* Three Dots */}
        <i className="far fa-ellipsis-v me-2 me-sm-3" />
</div>
      </section>
    </>

  )
}


export default ChatHeader;
