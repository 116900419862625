import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ApiContext } from '../../../../UseContext/ApiCall';
import Stories from 'react-insta-stories';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import StoriesInsightsPopup from './StoriesInsightsPopup';
import { BASEURL } from '../../../../../../config';
import PostSharePopup from '../../../Share/PostSharePopup';


const ViewStoriesPopup = () => {

  const [ showPostSharePopup, setShowPostSharePopup] = useState(false)

  const { 
    userStories, setUserStories,
    showViewStoriesPopup, setShowViewStoriesPopup,
    storyComment, setStoryComment,
    currentStory, setCurrentStory,      
    setShowStoriesInsightsPopup,
    loggedInUserId,
    likeDislikeStory,
    getInsightStories,
    viewStory,
    commentOnStory,
  } = useContext(ApiContext);  
  
  // To handle insights click
  const handleInsightsClick = () => {
    setShowStoriesInsightsPopup(true);
    setShowViewStoriesPopup(false);
    getInsightStories();
  };

  // To handle like dislike story
  const handleLikeDislikeStory = () => {
    // update in front end
    setCurrentStory((prevStory) => ({
      ...prevStory,
      liked: prevStory.liked === 'true' ? 'false' : 'true', 
    }));

    setUserStories((prevStories) => {
      return prevStories.map((story) => {
        if (story._id === currentStory._id) {
          return {
            ...story,
            liked: story.liked === 'true' ? 'false' : 'true', 
          };
        }
        return story;
      });
    });

    // update in back end
    likeDislikeStory();
  };


  return (
    <>
      {/* Stories Insights Popup */}
      <StoriesInsightsPopup />

      {/* share story popup */}
      <PostSharePopup shareData={currentStory} showPostSharePopup={showPostSharePopup} setShowPostSharePopup={setShowPostSharePopup} />

      <section className='viewStoriesPopup'>
        <Modal show={showViewStoriesPopup} onHide={() => {setShowViewStoriesPopup(false); setUserStories([])}} centered backdrop="true" size="md" keyboard={false} className="create-call show-stories-popup" >
          <Modal.Header>&nbsp;</Modal.Header>
          <Modal.Body className='p-0 rounded-3'>
            {
              userStories && userStories.length > 0
              &&
              <section className='position-relative'>
                <div className='story-view-popup'>
                  <Stories
                    stories={userStories}
                    defaultInterval={10000}
                    width='100%'
                    height='75vh'
                    onStoryStart={(index, story) => {
                      setCurrentStory(story);
                      viewStory(story);
                    }}
                    onStoryEnd={() => {
                      setCurrentStory({});
                    }}
                    
                    onAllStoriesEnd={() => {
                      setShowViewStoriesPopup(false);
                      setUserStories([]);
                    }}
                    isPaused={storyComment !== ''}
                  />
                  {/* Render tags for the current story */}
                  {
                    currentStory.tags &&
                    currentStory.tags.length > 0 &&
                    currentStory.tags.map((tag, index) => (
                      <div
                        className="displayTags_story"
                        key={index}
                        style={{ position: "absolute", top: `${tag.y}%`, left: `${tag.x}%` }}
                      >
                        <Link to={`${BASEURL}/profile/${tag.tagUserId}`}>
                          <small>{tag.fname}</small>
                        </Link>
                      </div>
                    ))
                  }
                </div>

                {
                  userStories[0].postedBy === loggedInUserId
                  ?
                  // It will show to logged in user
                  <div className='position-absolute w-100 bottom-0 px-3 pb-3 d-flex align-items-center'>
                    <div className='d-flex flex-column cursor-pointer' style={{ zIndex: 999 }} onClick={handleInsightsClick}>
                      <img src="/images/icons/view-white.svg" style={{ height: '25px' }} alt="view" />
                      <small className='text-white'>Insights</small>
                    </div>

                    <div className='ms-auto me-3' style={{ zIndex: 999 }} onClick={() => setShowPostSharePopup(true)}>
                      <img src="/images/icons/share-white.svg" className='cursor-pointer' style={{ height: '25px' }} alt="share" onClick={() => toast.success('share')} />
                    </div>

                    <div style={{ zIndex: 999 }}>
                      <img src="/images/icons/three-dot-white.svg" className='cursor-pointer' style={{ height: '25px' }} alt="three-dot" onClick={() => toast.success('three dot')} />
                    </div>
                  </div>
                  :
                  // It will show to other user
                  <div className='position-absolute w-100 bottom-0 px-3 pb-3 d-flex align-items-center gap-3'>
                    <input type="text" className='form-control rounded-pill shadow-none bg-transparent border border-white' style={{ zIndex: 999, '::placeholder': {color: 'white',} }} 
                      value={storyComment} onChange={(e) => setStoryComment(e.target.value)} disabled={currentStory.comment === 'off'}
                      placeholder={`${currentStory.comment === 'on' ? 'Comment here...' : 'Comment is off...'}`}
                    />

                    <>
                      {
                        storyComment === ''
                        ?
                        // Like, Share & Three dot
                        <>
                          {
                            currentStory.like === 'on'
                            &&
                            <img src={`/images/icons/like-${currentStory.liked === 'true' ? 'red' : 'white'}.svg`} className='cursor-pointer' style={{ height: '27px', zIndex: 999 }} alt="like" onClick={handleLikeDislikeStory} />
                          }
        
                          <img src="/images/icons/share-white.svg" className='cursor-pointer' style={{ height: '25px', zIndex: 999 }} alt="share" onClick={() => toast.success('share')} />
        
                          <img src="/images/icons/three-dot-white.svg" className='cursor-pointer' style={{ height: '25px', zIndex: 999 }} alt="three-dot" onClick={() => toast.success('three dot')} />
                        </>
                        :
                        // Send
                        <img src="https://s3aws.emilo.in/images/icon/send.svg" className='cursor-pointer' style={{ height: '25px', zIndex: 999 }} alt="send" onClick={() => commentOnStory()} />
                      }
                    </>
                  </div>
                }
              </section>
            }
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ViewStoriesPopup;
