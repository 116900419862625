import React from 'react'
import { BASE_URL_STATIC } from '../../../../config'
import Suggestions from './Suggestions'

const TrendingRightBlock = () => {
    return (
        <div className="right-block p-0 pt-5 px-5">
            <div className='pt-4'>
                {/* Sponsored */}
                <div>
                    <div className='sc-rb-area-add-header mx-auto mb-2 w-100'>
                        <h6 className='bg-white p-2 text-center fw-bold'>Sponsored</h6>
                    </div>

                    <div className='sc-rb-area-add w-100'>
                        <div className='sc-rb-area-add-usre'>
                            <img className='sc-rb-area-add-usre-profile' src={`${BASE_URL_STATIC}/user-icon3.jpg`} alt="profile" />

                            <div className='sc-rb-area-add-usre-header'>
                                <h6 className='sc-rb-area-add-usre-head'>Interesting Facts</h6>
                                <span className='sc-rb-area-add-usre-sponserd'>Sponsored</span>
                            </div>
                        </div>

                        <span className='sc-rb-area-add-usre-msg1'>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been industry’s standard dummy text.</span>

                        <img className='sc-rb-area-add-usre-img' src={`${BASE_URL_STATIC}/SoftCorner/rb-add.png`} alt="add" />

                        <span className='sc-rb-area-add-usre-link'>www.example.com</span>
                        <span className='sc-rb-area-add-usre-msg2'>Lorem Ipsum is simply dummy text.</span>

                        <span className='sc-rb-area-add-usre-btn'>Visit Us</span>
                    </div>
                </div>
            </div>

            <Suggestions />
        </div>
    )
}

export default TrendingRightBlock