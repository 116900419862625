import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import ProfileMemesPopup from '../../Popup/Profile/ProfileShow/ProfileMemesPopup';

const TaggedMemes = () => {

  const [taggedMemes, setTaggedMemes] = useState([]);
  const [showTaggedMemePopup, setshowTaggedMemePopup] = useState(false);
  const [taggedMemePopupData, settaggedMemePopupData] = useState();

  const { config, loggedInUserId } = useContext(ApiContext);

  // To get tagged Memes
  const getTaggedMemes = (loggedInUserId, config) => {
    axios.post('https://emilonode.sjain.io/post/metaggedmeme', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data);
      setTaggedMemes(res.data.tagMeme);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedMemes(loggedInUserId, config);
  }, [loggedInUserId]);

  // To handle tagged meme popup data
  const handleTaggedMemePopup = (taggedMeme) => {
    setshowTaggedMemePopup(true);
    settaggedMemePopupData(taggedMeme);
  };

  return (
    <>
      {/* Tagged Photos And Videos Popup */}
      <ProfileMemesPopup showProfileMemesPopup={showTaggedMemePopup} setShowProfileMemesPopup={setshowTaggedMemePopup} profileMemePopupData={taggedMemePopupData} />

      <section className='tagged-memes'>
        <h1 className='tagged-memes-head'>Memes</h1>

        <div className='tagged-memes-show'>
        {
            taggedMemes
            ?
            taggedMemes.map((taggedMeme, index) => (
              // console.log(taggedMeme.details)
              taggedMeme.details[0].imageId
              ?
              <img src={taggedMeme.details[0].imageUrl} alt={taggedMeme.details[0].imageId} height={215} width={200} onClick={() => handleTaggedMemePopup(taggedMeme)} />
              :
              <video height={215} width={200} autoPlay onClick={() => handleTaggedMemePopup(taggedMeme)}>
                <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${taggedMeme.details[0].videoId}`} />
              </video>
            ))
            :
            ''
          }
        </div>
      </section> 
    </>
  )
}

export default TaggedMemes
