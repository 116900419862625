import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { ApiContext } from "../../UseContext/ApiCall";
import { Link, NavLink, useParams } from "react-router-dom";
import FollowUnfollowButton from "../../Button/FollowUnfollowButton";
import ProfileFollowerPopup from "../../Popup/Profile/ProfileUser/ProfileFollowerPopup";
import ProfileFollowingPopup from "../../Popup/Profile/ProfileUser/ProfileFollowingPopup";
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC } from "../../../../config";
import Overlay from 'react-bootstrap/Overlay';
import imageCompression from 'browser-image-compression';
import ProfileUserTdSharePopup from "../../Popup/Profile/ProfileUser/ProfileUserTdSharePopup";
import ServiceProfile from "../../Popup/Service/ServiceProfile";
import ReportUser from "../../Popup/Report/ReportUser";
import { settingContext } from "../../UseContext/SettingContext";


const ProfileUser = () => {

  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState([]);
  const [followerCount, setFollowerCount] = useState("");
  const [followingCount, setFollowingCount] = useState("");

  const [showFollowerPopup, setShowFollowerPopup] = useState(false);
  const [showFollowingPopup, setShowFollowingPopup] = useState(false);

  const [showProfileUserTdSharePopup, setShowProfileUserTdSharePopup] = useState(false);

  const { config, profileOption, loggedInUserId, loggedInUser, loggedInUserImage,
    profileUserPostCount, profileUserPostNumber, updateProfileImage, followerList, 
    // putSnooze, checkSnoozeUser, isUserSnooze,
    checkFavouriteUser, isFavouriteUser, handleCreateFavouriteUser,
    checkBlocked, handleBlockUnblock, isBlocked,
    // setIsBlocked,
    setshowServiceProfilePopup, setShowReportUserPopup, switchAbout, setSwitchAbout
  } = useContext(ApiContext);

  const { isOtherUserAccountPrivate } = useContext(settingContext)



  const { paramsUserId } = useParams();

  localStorage.setItem("profileUserId", paramsUserId);

  const [show, setShow] = useState(false);
  const [showMyOverlay, setShowMyOverlay] = useState(false);
  const [userBio, setUserBio] = useState(null);

  const target = useRef(null);
  const myOverlayRef = useRef(null);

  useEffect(() => {
    checkFavouriteUser(paramsUserId, config);
  }, [paramsUserId]);

  // useEffect(() => {
  //   checkSnoozeUser(paramsUserId, config);
  // }, []);

  // To get profile user data
  useEffect(() => {
    axios.post(`${BASEAPIURL}/user/getoneuser`, { userId: paramsUserId })
      .then((res) => {
        console.log("paramsUserIds" , res)
        setUserId(res.data.user._id);
        setUserData(res.data.user);
        setUserBio(res.data.user.bio)
      })
      .catch((err) => {
        console.log(err);
      });

    profileUserPostCount(paramsUserId);
    checkBlocked(paramsUserId);
  }, [paramsUserId]);

  // To upate the profile Picture
  const updateProfilePicture = async (e) => {
    if (e.target.files[0]) {

      const formData = new FormData();

      const compressedImage = await imageCompression(e.target.files[0], profileOption);

      formData.append("image", compressedImage);

      updateProfileImage({ formData, config, userId: paramsUserId });
    };
  };

  // To show the follower and following count in the profile page
  useEffect(() => {
    if (userId) {
      axios.post(`${BASEAPIURL}/user/followercount`, { followingID: paramsUserId, })
        .then((res) => {
          setFollowerCount(res.data.follower);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (userId) {
      axios.post(`${BASEAPIURL}/user/followingcount`, { followerID: paramsUserId, })
        .then((res) => {
          setFollowingCount(res.data.following);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, [userId]);

  const handleMessage = async () => {
    await axios.post(`${BASEAPIURL}/user/createemchatconnection`, { userId, isGroupChat: false }, config)
      .then(res => {
        const chatId = res.data.chatId;
        const newUrl = `${BASEURL}/chats/${chatId}`;
        // Navigate to the new URL
        window.location.href = newUrl;
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {/* Follower popup */}
      <ProfileFollowerPopup showFollowerPopup={showFollowerPopup} setShowFollowerPopup={setShowFollowerPopup} />

      {/* Following popup */}
      <ProfileFollowingPopup showFollowingPopup={showFollowingPopup} setShowFollowingPopup={setShowFollowingPopup} />

      {/* Profile User > Three Dot > Share Popup */}
      <ProfileUserTdSharePopup showPopup={showProfileUserTdSharePopup} setShowPopup={setShowProfileUserTdSharePopup} shareUserId={paramsUserId} shareUserName={userData.fname} />

      {/* Report Service Profile */}
      <ServiceProfile type='profile' />

      {/* Report User Profile */}
      <ReportUser />

      <section className="profile row col-12 col-lg-10 col-xl-8 mx-auto p-0">
        <NavLink to="/" className='col-1 d-none d-lg-block align-self-center text-center px-0'>
          <i className="fas fa-chevron-left" style={{ fontSize: "25px" }} />
        </NavLink>

        {/* Profile Image */}
        <div className="col-12 col-lg-3 position-relative align-self-center px-0 text-center my-3 my-lg-0 pt-lg-5 mt-lg-5  ">
          {
            loggedInUserImage
              ?
              <img className='pu-p-img' src={AwsFileUrl +  userData.imageId} alt="profile" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} height={225} width={225} style={{ borderRadius: '50%', border: '1px solid #caced2' }} />
              :
              <img className='pu-p-img' src={`${BASE_URL_STATIC}/dummy.png`} alt="profile" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} height={225} width={225} style={{ borderRadius: '50%', border: '1px solid #caced2' }} />
          }

          {
            userId === loggedInUserId
            &&
            <label htmlFor="profile-edit" className="pu-p-icon position-absolute text-white bg-secondary-subtle px-2 py-1 rounded-circle">
              <i className="far fa-camera" style={{ cursor: "pointer" }} />
            </label>
          }

          <input type="file" accept="image/*" id="profile-edit" onChange={updateProfilePicture} style={{ display: "none", visibility: "none" }} />
        </div>

        <div className="col-12 col-lg-4 px-0 my-auto ">
          <div className="mb-0 mb-lg-4 d-flex flex-column align-items-center align-items-lg-start pt-lg-5 mt-lg-5">
            {userData.fname && <h3 className="pu-name m-0 fw-bold">{userData.fname}</h3>}

            <span style={{ color: "#784b84" }}>{userData.username && userData.username}</span>
          </div>

          <div className="row w-100 mx-auto justify-content-evenly justify-content-lg-between text-center my-3 my-lg-0 pt-lg-5 ">
            <div className="col-4 pu-ffp-box px-0 py-2 rounded-3 shadow cursor-pointer position-relative" onClick={() => setShowFollowerPopup(isBlocked ? false : !isOtherUserAccountPrivate ? false : true)}>
              {followerCount ? <p>{followerCount}</p> : <p>0</p>}
              <small>Followers</small>

              <div className="position-absolute start-50 translate-middle w-100" style={{ top: '-5px' }}>
                {
                  followerList
                  &&
                  followerList.slice(1, 5).map((follower, index) => (
                    follower.imageUrl
                      ?
                      <img className="pu-f-img" src={follower.imageUrl} alt='profile' key={index} onError={(e) => { e.target.src = `${BASE_URL_STATIC}/dummy.png` }} />
                      :
                      <img key={index} className="pu-f-img" src={`${BASE_URL_STATIC}/dummy.png`} alt="profile" />
                  ))
                }

                {
                  followerList
                    ?
                    (followerList.length - 4) >= 0
                      ?
                      <b className="pu-f-count">{followerList.length - 4}</b>
                      :
                      <b className="pu-f-count">0</b>
                    :
                    <b className="pu-f-count">0</b>
                }
              </div>
            </div>

            <div className="col-4 pu-ffp-box px-0 py-2 rounded-3 shadow cursor-pointer" onClick={() => setShowFollowingPopup(isBlocked ? false : !isOtherUserAccountPrivate ? false : true)}>
              {followingCount ? <p>{followingCount}</p> : <p>0</p>}
              <small>Following</small>
            </div>

            <div className="col-4 pu-ffp-box px-0 py-2 rounded-3 shadow cursor-pointer ">
              {profileUserPostNumber ? <p>{profileUserPostNumber}</p> : <p>0</p>}
              <small>Posts</small>
            </div>
          </div>
        </div>

        {
          (userId === loggedInUserId)
          ?
          // It will show in logged in user profile
          <div className="col-12 col-lg-4 px-0 d-flex justify-content-evenly align-items-start mt-lg-5 pt-lg-5">
            {/* Edit */}
            <Link to={`/profile/${paramsUserId}/profile-edit`} className="border border-secondary-subtle p-2 rounded-3 shadow-sm">
              <img className="me-1" src={`${BASE_URL_STATIC}/icon/editIcon.svg`} alt="Edit" style={{ height: '17px', width: '17px' }} />
              <small className="text-dark">Edit</small>
            </Link>

            {/* Setting */}
            <Link to={`/profile/${paramsUserId}/profile-setting`} className="border border-secondary-subtle p-2 rounded-3 shadow-sm">
              <img className="me-1" src={`${BASE_URL_STATIC}/icon/settingIcon.svg`} alt="Setting" style={{ height: '17px', width: '17px' }} />
              <small className="text-dark">Setting</small>
            </Link>

            {/* Share */}
            <div>
              <Link to={`/profile/${paramsUserId}/profile-share`} className="border border-secondary-subtle p-2 rounded-3 shadow-sm">
                <img className="me-1" src={`${BASE_URL_STATIC}/icon/Share.svg`} alt="Share" style={{ height: '17px', width: '17px' }} />
                <small className="text-dark">Share</small>
              </Link>

              {/* Three Dot */}
              <i className="far fa-ellipsis-v ms-2 ms-lg-3 ms-xl-4 mt-2" ref={myOverlayRef} style={{ cursor: "pointer" }} onClick={() => setShowMyOverlay(!showMyOverlay)} />
            </div>

            <Overlay target={myOverlayRef.current} show={showMyOverlay} placement="right">
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  {...props}
                  style={{
                    position: 'absolute',
                    border: '1px solid white',
                    padding: '5px 10px',
                    color: 'black',
                    margin: '100px 0px 0px 15px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 3px gray',
                    ...props.style,
                  }}
                >
                  <div className="profileUserThreeDotOverlay">
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/search.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">Search In Profile</span> <br />
                  </div>

                  <div className="profileUserThreeDotOverlay">
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/menu-icon7.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">My Profile Status</span> <br />
                  </div>

                  <div className="profileUserThreeDotOverlay">
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/icon/share-2.svg`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">Activity Log</span> <br />
                  </div>

                  <div className="profileUserThreeDotOverlay">
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/icon/settingIcon.svg`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">Profile Setting</span> <br />
                  </div>

                  <div className="profileUserThreeDotOverlayLast" onClick={() => setshowServiceProfilePopup(true)}>
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/pen.jpg`} alt="pen" />
                    <span className="profileUserThreeDotOverlaySpan">Report Service</span> <br />
                  </div>
                </div>
              )}
            </Overlay>
          </div>
          :
          // It will show in other user profile
          <div className="col-12 col-lg-4 px-0 d-flex justify-content-evenly align-items-center align-items-lg-start">
            {
              isBlocked
                ?
                <button type="button" className="followUnfollowBtn" onClick={() => handleBlockUnblock(paramsUserId)}>Unblock</button>
                :
                <FollowUnfollowButton otherUserId={paramsUserId} />
            }

            {/* <div className="profileUserThreeDotOverlay">
                <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/menu-icon7.png`} alt="popup-icon1" />
                  <span className="profileUserThreeDotOverlaySpan">
                    <Link to={`/profile/${paramsUserId}/about_user`} className="text-dark">
                    About this User
                    </Link>
                    </span> <br />
                </div> */}
            <small onClick={() => setSwitchAbout(!switchAbout)} className="border border-secondary-subtle p-1 px-xl-2 rounded-3" style={{ cursor: "pointer" }}>
              {/* <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/menu-icon7.png`} alt="popup-icon1" /> */}
              {switchAbout ? "Posts" : "About"}</small>

            <small className="border border-secondary-subtle p-1 px-xl-2 rounded-3" onClick={() => !isBlocked && handleMessage()}>Message</small>

            <i className="far fa-ellipsis-v mt-lg-1" style={{ cursor: "pointer" }} ref={target} onClick={() => setShow(!show)} />

            <Overlay target={target.current} show={show} placement="right">
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  {...props}
                  style={{
                    position: 'absolute',
                    border: '1px solid white',
                    padding: '5px 10px',
                    color: 'black',
                    margin: '100px 0px 0px 15px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 3px gray',
                    ...props.style,
                  }}
                >
                  {/* Add To Favourite */}
                  <div className="profileUserThreeDotOverlay" onClick={() => handleCreateFavouriteUser(paramsUserId, config)}>
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/star.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">{isFavouriteUser ? 'Add to' : 'Remove from'} favourite</span> <br />
                  </div>

                  {/* Add To Close Friend */}
                  <>
                    {
                      <div className="profileUserThreeDotOverlay">
                        <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="popup-icon1" />
                        <span className="profileUserThreeDotOverlaySpan">Add to close friend</span> <br />
                      </div>
                    }
                  </>

                  {/* Add To SoftCorner */}
                  <>
                    {
                      loggedInUser.gender !== userData.gender
                      &&
                      <div className="profileUserThreeDotOverlay" >
                        <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/top-icon3.png`} alt="popup-icon1" />
                        <span className="profileUserThreeDotOverlaySpan">Add to soft corner</span> <br />
                      </div>
                    }
                  </>

                  <div className="profileUserThreeDotOverlay">
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/search.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">Search in profile</span> <br />
                  </div>

                  {/* <div className="profileUserThreeDotOverlay">
                <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/menu-icon7.png`} alt="popup-icon1" />
                  <span className="profileUserThreeDotOverlaySpan">
                    <Link to={`/profile/${paramsUserId}/about_user`} className="text-dark">
                    About this User
                    </Link>
                    </span> <br />
                </div> */}

                  <div className="profileUserThreeDotOverlay">
                    {/* <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/icon/friends.svg`} alt="popup-icon1" /> */}
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/icon/friends.svg`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">See friendship</span> <br />
                  </div>

                  {/* Share */}
                  <div className="profileUserThreeDotOverlay" onClick={() => setShowProfileUserTdSharePopup(true)}>
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/icon/share-2.svg`} alt="popup" />
                    <span className="profileUserThreeDotOverlaySpan">Share</span> <br />
                  </div>

                  {/* Report */}
                  <div className="profileUserThreeDotOverlay" onClick={() => setShowReportUserPopup(true)}>
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/popup-icon7.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">Report</span> <br />
                  </div>

                  {/* Block */}
                  <div className="profileUserThreeDotOverlayLast" onClick={() => handleBlockUnblock(paramsUserId)}>
                    <img className="profileUserThreeDotOverlayImg" src={`${BASE_URL_STATIC}/chat-icon12.png`} alt="popup-icon1" />
                    <span className="profileUserThreeDotOverlaySpan">{isBlocked ? 'Unblock' : 'Block'}</span> <br />
                  </div>
                </div>
              )}
            </Overlay>
          </div>
        }

        { 
          userBio 
          && 
          <div className="row text-info pt-3 justify-content-end opacity-75">
            <p className="col-12 col-lg-4 mx-auto fs-14">{userBio} {paramsUserId !== loggedInUserId && <span onClick={() => setSwitchAbout(!switchAbout)} className="text-primary" style={{ cursor: "pointer" }}>...know {!switchAbout ? 'more' : 'less'}</span>} </p>
          </div>
        }
        <hr className="hr w-75 my-3 my-lg-5 mx-auto" />
      </section>
    </>
  );
};

export default ProfileUser;
