import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../UseContext/ApiCall";
import { BASE_URL_STATIC } from "../../../../config";

const NameRegistration = () => {
  const [btn, setBtn] = useState(false);
  const [errors, setErrors] = useState({});


  const { setUserInfo, userInfo } = useContext(ApiContext);
  const nav = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    let updatedValue = value;

    // Capitalize the first letter of each word of firstName, middleName and lastName
    if(name === "firstName" || name === "lastName" || name === "middleName") {
      updatedValue = value.charAt(0).toUpperCase() + value.slice(1);    
    }

    if (name === "gender") {
      updatedValue = e.target.id;
    }

    const spacePattern = /\s/;
    const numberPattern = /\d/;
    const specialCharPattern = /[!@#$%^&*()_+\-=\\[\]{};':"|,.<>/?]/;

    const newErrors = { ...errors };

    if (name === "dob") {
      // Date field validation
      setUserInfo((prevUser) => ({ ...prevUser, [name]: updatedValue }));
      delete newErrors[name];
    } else {
      if (spacePattern.test(value)) {
        console.log("Input field cannot contain spaces." , newErrors[name]);
       newErrors[name] = "Input field cannot contain spaces."; 
      } else if (numberPattern.test(value)) {
        newErrors[name] = "Input field cannot contain numbers.";
      } else if (specialCharPattern.test(value)) {
        newErrors[name] = "Input field cannot contain special characters.";
      } else {
        delete newErrors[name];
      }

      setUserInfo((prevUser) => ({ ...prevUser, [name]: updatedValue }));
      console.log('userInfo : ', userInfo);
      
    }

    setErrors(newErrors);
  };

  useEffect(() => {
    const hasErrors = Object.values(errors).some(error => error);
    const hasEmptyFields =
      userInfo.firstName.trim() === "" ||
      userInfo.lastName.trim() === "" ||
      userInfo.gender === "" ||
      userInfo.dob === "";
  
    setBtn(!hasErrors && !hasEmptyFields);
  }, [userInfo, errors]);
  
  // Calculate the maximum selectable date (18 years ago from today)
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    .toISOString()
    .split('T')[0]; // Format the date to YYYY-MM-DD

  return (
    <div className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100 ">
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/signup-img1.jpg`} alt="signup" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0">
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <form className="w-75 mx-auto">
          <div className="fs-1 mb-3 mb-lg-4">Personal Details</div>

          {/* Fname */}
          <div>
            <input type="text" className="form-control text-capitalize form-control-lg rounded-pill mb-3" onChange={handleChange} name="firstName" placeholder="First Name (Required)" />
            {errors.firstName && <small className="e01">{errors.firstName}</small>}
          </div>

          {/* Mname */}
          <div>
            <input type="text" className="form-control text-capitalize form-control-lg rounded-pill mb-3" onChange={handleChange} name="middleName" placeholder="Middle Name (optional)" />
            {errors.middleName && <small className="e02">{errors.middleName}</small>}
          </div>

          {/* Lname */}
          <div>
            <input type="text" className="form-control text-capitalize form-control-lg rounded-pill mb-3" onChange={handleChange} name="lastName" placeholder="Last Name (Required)" />
            {errors.lastName && <small className="e03">{errors.lastName}</small>}
          </div>

          {/* Gender */}
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-3" style={{ gap: '15px' }}>
            <div className="border border-secondary-subtle py-2 px-2 px-md-4 rounded-pill d-none d-sm-block">
             <span></span> Gender
              {errors.gender && <small>{errors.gender}</small>}
            </div>

            <div className="border border-secondary-subtle ps-2 ps-md-3 py-2 rounded-pill">
              <div className="form-check">
                <input type="radio" className="form-check-input" name="gender" id="Male" onChange={handleChange} />
                <label htmlFor="Male" className="form-check-label">Male</label>
              </div>
            </div>

            <div className="border border-secondary-subtle ps-3 py-2 rounded-pill mx-1 mx-sm-0">
              <div className="form-check">
                <input type="radio" className="form-check-input" name="gender" id="Female" onChange={handleChange} />
                <label htmlFor="Female" className="form-check-label">Female</label>
              </div>
            </div>

            <div className="border border-secondary-subtle ps-3 py-2 rounded-pill">
              <div className="form-check">
                <input type="radio" className="form-check-input" name="gender" id="Other" onChange={handleChange} />
                <label htmlFor="Other" className="form-check-label">Other</label>
              </div>
            </div>
          </div>

          {/* DOB */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <label id="dob" className="form-label border border-secondary-subtle py-2 px-4 rounded-pill mb-0 me-1 me-sm-3 me-lg-5">DOB</label>
            <input type="date" className="form-control rounded-pill py-4" for="dob" onChange={handleChange} name="dob"  placeholder="dd/mm/yyyy" min="1924-01-01"   max={maxDate} />
            {errors.dob && <small>{errors.dob}</small>}
          </div>

          <button type="button" className="btn btn-primary py-2 w-100 rounded-pill fs-5 fs-sm-4"  disabled={!btn} onClick={() => nav("/signup/emailandmobile")}>Next</button>
        </form>
      </section>
    </div>
  );
};

export default NameRegistration;
