import React, { useState, useContext } from 'react';
import { BASE_URL_STATIC } from '../../../../../config';
import { WhatsappShareButton } from 'react-share';
import { ApiContext } from '../../../UseContext/ApiCall';
// import InviteFriendViaEmailPopup from '../../../Popup/Profile/ProfileUser/Edit/Invite/InviteFriendViaEmailPopup';
import InviteFriendViaSmsPopup from '../../../Popup/Profile/ProfileUser/Edit/Invite/InviteFriendViaSmsPopup';


const InviteFollow = () => {

  // const [showInviteFriendViaEmailPopup, setShowInviteFriendViaEmailPopup] = useState(false);
  const [showInviteFriendViaSmsPopup, setShowInviteFriendViaSmsPopup] = useState(false);

  const [showList, setshowList] = useState(false);
  const [list1, setList1] = useState([
    "alice@gmail.com",
    "abhi@gmail.com",
    "amit@gmail.com",
    "bob@gmail.com",
    "babbu@gmail.com",
    "charlie@gmail.com",
    "david@gmail.com",
    "eve@gmail.com",
    "frank@gmail.com",
    "grace@gmail.com",
    "hank@gmail.com",
    "isabel@gmail.com",
    "jack@gmail.com",
    "karen@gmail.com",
    "larry@gmail.com",
    "maria@gmail.com",
    "nancy@gmail.com",
    "oscar@gmail.com",
    "peter@gmail.com",
    // 'pratik.kumar.kashyap@gmail.com',
    "quincy@gmail.com",
    "rachel@gmail.com",
    "steve@gmail.com",
    "tina@gmail.com",
    "ursula@gmail.com",
    "victor@gmail.com",
    "wanda@gmail.com",
    "xander@gmail.com",
    "yvonne@gmail.com",
    "zach@gmail.com"
  ]);
  const [list2, setList2] = useState([]);
  const [selected, setSelected] = useState([]);


  // To handle focus
  const handleFocus = () => {
    setshowList(true);
  };

  // To handle blur
  const handleBlur = () => {
    setshowList(false);
  };

  // To handle select 
  const handleSelect = (gmail) => {
    console.log('gmail : ', gmail);

    // Remove gmail from list2 and update list2
    const updatedList1 = list1.filter(email => email !== gmail);
    setList1(updatedList1);

    // Remove gmail from list2 and update list2
    const updatedList2 = list2.filter(email => email !== gmail);
    setList2(updatedList2);

    // Move gmail from list` and list2 to selected 
    setSelected(prevState => [...prevState, gmail]);
  };

  // To handle remove
  const handleRemove = (gmail) => {
    console.log('remove : ', gmail);

    // Filter out the selected Gmail address from selected list
    const updatedSelected = selected.filter(email => email !== gmail);
    // Add the removed Gmail address back to list1
    setList1(prevList1 => [...prevList1, gmail]);
    // Update the selected state
    setSelected(updatedSelected);
  };

  // To handle change
  const handleChange = (e) => {
    const { value } = e.target;

    const regex = new RegExp('^' + value.toLowerCase());

    setList2(list1.filter(email => regex.test(email.toLowerCase())));
  };

  // To handle invite friends via email
  const handleInviteEmail = () => {
    // Example email data
    const subject = 'Invitation from Emilo';
    const body = `Your friend ${loggedInUserName} invite you to join Emilo https://emilorj.sjain.io`;

    // Construct the mailto link
    const mailtoLink = `mailto:${selected.join(',')}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the mailto link
    window.location.href = mailtoLink;
  };


  const { loggedInUserName } = useContext(ApiContext);

  return (
    <>
      {/* Invite Friend Via Email Popup */}
      {/* <InviteFriendViaEmailPopup showInviteFriendViaEmailPopup={showInviteFriendViaEmailPopup} setShowInviteFriendViaEmailPopup={setShowInviteFriendViaEmailPopup} /> */}

      {/* Invite Friend Via Email Popup */}
      <InviteFriendViaSmsPopup showInviteFriendViaSmsPopup={showInviteFriendViaSmsPopup} setShowInviteFriendViaSmsPopup={setShowInviteFriendViaSmsPopup} />

      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden mb-5 mb-lg-0'>
        {/* ----------- New ----------- */}
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Invite & Follow</li>
          </ol>
        </nav>

        {/* Image */}
        <div className='w-75 mx-auto mb-5'>
          <img src={`${BASE_URL_STATIC}/settingsImage.svg`} width={550} alt="invite and follow" />
        </div>

        {/* --- Email --- */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 mb-3 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contactInfo">
            <img src={`${BASE_URL_STATIC}/icon/mailPlus.svg`} alt="invite-email" />
            <h5 className='fw-semibold ms-3'>Invite Friends via Email</h5>
          </div>

          <div id='contactInfo' className="accordion-collapse collapse show ms-3" data-bs-parent="#accordionToggle1" >
            <h5 className='accordion-level-4 shadow-sm'>Invite Friends via Email</h5>

            <div className='d-flex align-items-center mt-3 position-relative ms-3'>
              {/* Selected List */}
              <div className='w-100 me-3'>
                <div className={`${selected && selected.length > 0 && `border rounded-3 p-1`}`}>
                  {
                    selected.map((gmail, index) => (
                      <div className='px-2 py-1 bg-primary text-white shadow-sm d-inline-block rounded-3 m-1'>
                        <span className='me-3' key={index}>{gmail}</span>
                        <span className='cursor-pointer' onClick={() => handleRemove(gmail)}>x</span>
                      </div>
                    ))
                  }
                </div>

                <input type="text" className='form-control form-control-sm' onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} placeholder='Search Email...' />
              </div>

              {/* Search List */}
              <>
                {
                  showList 
                  && 
                  <div className='position-absolute top-100 mt-2 bg-white rounded-3 p-2 shadow-sm overflow-y-scroll' onMouseDown={(e) => e.preventDefault()} style={{ maxHeight: '200px' }}>
                    {
                      list2.length > 0
                      ?
                      list2.map((gmail, index) => (
                        <div className='mb-2 cursor-pointer' key={index} onClick={() => handleSelect(gmail)}>{gmail}</div>
                      ))
                      :
                      list1.map((gmail, index) => (
                        <div className='mb-2 cursor-pointer' key={index} onClick={() => handleSelect(gmail)}>{gmail}</div>
                      ))
                    }
                  </div>
                }
              </>

              <button type="button" className='btn btn-primary btn-sm' disabled={selected.length <= 0} onClick={handleInviteEmail}>Invite</button>
            </div>

            <hr className='hr bg-secondary' />
          </div>
        </div>

        {/* --- SMS --- */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 mb-3 accordion-button collapsed pe-3 pe-xl-4' onClick={() => setShowInviteFriendViaSmsPopup(true)}>
            <img src={`${BASE_URL_STATIC}/icon/message.svg`} alt="invite-sms" />
            <h5 className='fw-semibold ms-3'>Invite Friends via SMS</h5>
          </div>
        </div>

        {/* --- Whatsapp --- */}
        <div className='mb-3 accordion accordion-flush'>
          <WhatsappShareButton className='w-100' title={`Your friend ${loggedInUserName} invited you to join Emilo`} url={`https://emilorj.sjain.io/`} image={`https://cdn-icons-png.flaticon.com/512/3135/3135715.png`}>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4'>
              <img src={`${BASE_URL_STATIC}/icon/whatsapp.svg`} alt="invite-whatsapp" />
              <h5 className='fw-semibold ms-3 text-start'>Invite Friends via Whatsapp</h5>
            </div>
          </WhatsappShareButton>
        </div>

        {/* --- Follow contacts --- */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 mb-3 accordion-button collapsed pe-3 pe-xl-4'>
            <img src={`${BASE_URL_STATIC}/icon/contact.svg`} alt="invite-follow-contact" />
            <h5 className='fw-semibold ms-3'>Follow contacts</h5>
          </div>
        </div>

        {/* --- Share invite --- */}
        <div className='mb-3 accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4 mb-5 mb-lg-0'>
            <img src={`${BASE_URL_STATIC}/icon/sharePlus.svg`} alt="share-invite" />
            <h5 className='fw-semibold ms-3'>Share invite</h5>
          </div>
        </div>


        {/* ----------- Old ----------- */}

        {/* Email */}
        {/* <div className='mb-3 mb-lg-4' onClick={() => setShowInviteFriendViaEmailPopup(true)}>
          <img className='me-2 me-lg-3' src={`${BASE_URL_STATIC}/icon/mailPlus.svg`} alt="email" />
          <span>Invite Friends via email</span>
        </div> */}

        {/* SMS */}
        {/* <div className='mb-3 mb-lg-4' onClick={() => setShowInviteFriendViaSmsPopup(true)}>
          <img className='me-2 me-lg-3' src={`${BASE_URL_STATIC}/icon/message.svg`} alt="sms" />
          <span>Invite Friends via SMS</span>
        </div> */}

        {/* Whatsapp */}
        {/* <WhatsappShareButton title={`Your friend ${loggedInUserName} invited you to join Emilo`} url={`https://emilorj.sjain.io/`} image={`https://cdn-icons-png.flaticon.com/512/3135/3135715.png`}>
          <div className='mb-3 mb-lg-4'>
            <img className='me-2 me-lg-3' src={`${BASE_URL_STATIC}/icon/whatsapp.svg`} alt="whatsapp" />
            <span>Invite Friends via Whatsapp</span>
          </div>
        </WhatsappShareButton> */}

        {/* Follow Contacts */}
        {/* <div className='mb-3 mb-lg-4'>
          <img className='me-2 me-lg-3' src={`${BASE_URL_STATIC}/icon/contact.svg`} alt="contact" />
          <span>Follow contacts</span>
        </div> */}

        {/* Share Invite */}
        {/* <div className='mb-3 mb-lg-4'>
          <img className='me-2 me-lg-3' src={`${BASE_URL_STATIC}/icon/sharePlus.svg`} alt="share invite" />
          <span>Share invite</span>
        </div> */}
      </section>


    </>
  )
}

export default InviteFollow