import React, {useEffect, useState } from "react";
import { getFrames, VideoToFramesMethod } from "./VideoFrames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'
import "./thumbnail.css";

export default function BestClipThumbnail({ file , status, setStatus,selectedImage, setSelectedImage }) {
   const [images, setImages] = useState([]);
   // const [status, setStatus] = useState(false);
   // const [selectedImage, setSelectedImage] = useState()

   useEffect(() => {
      onInput(file)
   }, [])

   const onInput = async (file) => {
          if (!file) {
                    console.error("File is null or undefined.");
                    return;
                 }
      setImages([]);
      setStatus(true);
      const reader = new FileReader();
      reader.onload = async () => {
         const fileUrl = reader.result;

         const frames = await getFrames(
            fileUrl,
            30,
            VideoToFramesMethod.totalFrames
         );

         const imageFiles = await Promise.all(
            frames.map(async (imageUrl, index) => {
               const response = await fetch(imageUrl);
               const blob = await response.blob();
               return new File([blob], `${now}-${index + 1}.png`, { type: "image/png" });
            })
         );
         setStatus(false);
         setImages(imageFiles);
         setSelectedImage(imageFiles[0]); 
      };

      reader.readAsDataURL(file); 
   };

   const now = new Date().toDateString();

   return (
      <div className="container">
         {status ?
            <span>
               <FontAwesomeIcon icon={faArrowRotateLeft} spin />
               &nbsp; Generating thumbnail images...
            </span>
            :
            images.length > 0 && (
               <div className="output">
                  {images.map((imageFile, index) => (
                     <img
                        key={index}
                        className={`${selectedImage === imageFile && 'shadowe'}`}
                        src={URL.createObjectURL(imageFile)}
                        alt=""
                        onClick={() =>setSelectedImage(imageFile)}
                     />
                  ))}
               </div>
            )
         }
      </div>
   );
}
