import React from 'react';
import { Link } from 'react-router-dom'
import { BASEURL, BASE_URL_STATIC } from '../../../config'

const ProposeAndGetMarried = () => {
  return (
    <>
      <section className='proposeAndGetMarried'>
        <img className='proposeAndGetMarried-logo' src={`${BASE_URL_STATIC}/SoftCorner/atlp-logo.png`} alt="atlp-logo" height={60} width={60} />

        <div className='proposeAndGetMarried-head'>Propose & Get Married</div>
        
        <div className='proposeAndGetMarried-message'>When two gentle-hearted individuals unknowingly choose each other as life partners, they are destined to be a perfect match and will eventually tie the knot.</div>

        <div className='proposeAndGetMarried-tac'>
          <form>
            <input type="checkbox" id='termsAndConditions' name='termsAndConditions' />
            <label htmlFor="termsAndConditions">I agree to E-Milo’s soft corner <span>Terms And conditions</span></label>
          </form>
        </div>

        <Link to={`${BASEURL}/SoftCorner/ProposeOption`} className='proposeAndGetMarried-button'>Start</Link>
      </section>
    </>
  );
};

export default ProposeAndGetMarried;
