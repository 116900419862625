import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import { MdDriveFolderUpload } from "react-icons/md";
// import { IoMdPersonAdd } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';


const EditPagePopup = () => {

  const [option, setOption] = useState(1);

  const  { showEditPagePopup, setShowEditPagePopup } = useContext(businessContext);

  
  
  return (
    <>
      <Modal show={showEditPagePopup} onHide={() => setShowEditPagePopup(false)} centered backdrop="static" keyboard={false} className="create-call p-0" size="xl" >
      {/* <Modal show={true} onHide={() => setShowEditPagePopup(false)} centered backdrop="static" keyboard={false} className="create-call p-0 rounded-4" size="xl" > */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className='p-0 rounded-4' closeButton>
            <section className="row mx-auto" >
              {/* Left Block */}
              <div className="col-md-3 px-0 bg-primary rounded py-5 px-0 text-right">
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(1)} >Themes & Pics</button>
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(2)}>Page info</button>
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(3)}>Product & services</button>
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(4)}>Contact info</button>
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(5)}>Opportunity</button>
                <button className="btn btn-outline-light border-0  my-2 rounded-pill py-3 w-100" onClick={()=> setOption(6)}>Verification Badge</button>
              </div>

              {/* Right Block */}
              <div className="col-md-9 px-0 overflow-y-scroll fontPoppins" style={{ height: '75vh' }}>
                {/* themes & pics */}
                <>
                  {
                    option===1 
                    &&
                    <div>
                      <div style={{fontFamily:"Poppins"}} className='p-5'>
                        <div style={{backgroundColor:"#F8F8F8"}}  className="rounded-4 my-0 p-4 d-flex text-left flex-column rounded">
                          <h5 style={{fontFamily:"Poppins"}} className='text-left' >Profile Image</h5>
                          Aliquip do velit sunt consectetur eu irure esse. Duis elit ea ullamco cupidatat. Eu nulla consequat eiusmod ullamco sint irure. Tempor 
                          occaecat mollit ex irure id sit nulla nisi mollit. Duis proident dolore non sit qui elit veniam laboris consectetur ut.
                          <div className="row g-4 my-4">
                            <div className="border-end border-dark-subtle col-xl-4 d-flex justify-content-center  mx-0">
                              
                              <div className="rounded-circle bg-white " style={{padding:"2rem"}}>
                                <img  src={"/images/icons/profile.svg"} alt=''/>
                              </div>
                            </div>
                            <div className=' border-0  px-5 col-xl-8 d-flex align-items-center'>
                              
                            <button type="button" className="btn d-flex  btn-lg p-4 "  style={{border:"1px black dotted"}}>
                              <div><img  src={"/images/icons/imageUpload.svg"} alt='' /></div> 
                              <div>Upload or drop your file here. Accepted file types: jpg , .jpeg,.png </div> 
                              </button>
                            </div>
                          </div>
                        </div>

                        <div style={{backgroundColor:"#F8F8F8"}} className="rounded-4 my-4 p-4 rounded">
                          <h5 style={{fontFamily:"Poppins"}} className='text-left'>Cover Image</h5>
                          Aliquip do velit sunt consectetur eu irure esse. Duis elit ea ullamco cupidatat. Eu nulla consequat eiusmod ullamco sint irure. Tempor 
                          occaecat mollit ex irure id sit nulla nisi mollit. Duis proident dolore non sit qui elit veniam laboris consectetur ut.
                          
                          <div className="mt-4 row g-4">
                            <img src={`/images/DemoImage/Clear.png`} className=' rounded border-0 col-xl-8' style={{height:"10rem"}}  alt="banner" />
                            <button type="button" className="btn  btn-lg mb-2   col-xl-4"  style={{height:"10rem",border:"2px black dotted"}} >
                            <img  src={"/images/icons/imageUpload.svg"} alt='' />
                            <div>Upload or drop your file here</div> 
                            </button>
                          </div> 
                          <div className="mt-4 row g-4">
                            <img src={`/images/DemoImage/Clear.png`} className=' rounded border-0 col-xl-8' style={{height:"10rem"}}  alt="banner" />
                            <button type="button" className="btn  btn-lg mb-2   col-xl-4"  style={{height:"10rem",border:"2px black dotted"}} >
                            <img  src={"/images/icons/imageUpload.svg"} alt='' />
                            <div> Upload or drop your file here</div>
                            </button>
                          </div>      
                          <div className="text-center p-3">
                            <button type="button" className="btn btn-primary  rounded-pill px-5"> 
                              <span className="fs-5"><IoAddCircleOutline /></span>  Add More Address</button>
                          </div>              
                        </div>
                        <div className="my-5 p-4 rounded-4" style={{backgroundColor:"#F8F8F8"}}>
                          <h5 className="fw-bold text-left">Bio</h5>
                          <textarea  className="w-100 mt-3 p-4 rounded form-control" placeholder="tell people a little about what you do "/>
                        </div>
                      </div>

                      <div className="my-4 py-3 text-end sticky-bottom bg-white">
                        <button type="button" className="btn btn-secondary mx-3 ">Cancel</button>
                        <button type="button" onClick={()=> setOption(2)} className="btn btn-primary mx-3">Save</button>
                      </div>
                    </div>
                  }
                </>

                {/* Page Info */}
                <>
                  {
                    option===2 
                    &&
                    <div>
                      <div className="p-5 fontPoppins">
                        <div className="mb-5">
                          <h5 className="fw-bold text-left fontPoppins">Business Name</h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded-4 form-control"  placeholder="Please Enter your Business Name"/>
                        </div>
                        <div className="my-5">
                          <h5 className="fw-bold text-left fontPoppins">Incorporation Date</h5>
                          <input type="date" className="w-100 mt-3 p-4 rounded-4 form-control"  placeholder="Enter Date"/>
                        </div>
                        <div className="my-5">
                          <h5 className="fw-bold text-left fontPoppins">Brief Description</h5>
                          <textarea  className="w-100 mt-3 p-4 rounded-4 form-control" placeholder="Please Describe Your Business"/>
                        </div>
                        <div className="my-5">
                          <h5 className="fw-bold text-left fontPoppins">Mission</h5>
                          <p>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <textarea type="text" className="w-100 mt-3 p-4 rounded-4 form-control"  placeholder="Please Describe Your Mission"/>
                        </div>
                        <div className="my-5">
                          <h5 className="fw-bold text-left fontPoppins">Vision</h5>
                          <p>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <textarea type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Please Describe Your Vision"/>
                        </div>
                        <div className="my-5">
                          <h5 className="fw-bold text-left fontPoppins">Milestone</h5>
                          <p className='mb-4'>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <button type="button" className="w-100 btn btn-outline-primary btn-lg mb-2 "  style={{height:"10rem",border:"2px black dotted"}} >
                            <MdDriveFolderUpload /> Upload or drop your file here
                          </button>    
                          <div className='row'>
                            <div className='col-md-6 p-2'>
                              <input type="text" className="w-100  p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                            </div>
                            <div className='col-md-6 p-2'>
                              <input type="text" className="w-100  p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                            </div>
                          </div>
                          <textarea type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Please Describe Your Business"/>                    
                        </div>
                        <div>
                          <h5 className="fw-bold text-left fontPoppins">Founders</h5>
                          <p>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <input type="text" className="w-100  p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                          <div className="text-center p-5">
                            <button type="button" className="btn btn-primary  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Founders</button>
                          </div>
                        </div>
                        <div>
                          <h5 className="fw-bold text-left fontPoppins">Promoters</h5>
                          <p>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <input type="text" className="w-100  p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                          <div className="text-center p-5">
                            <button type="button" className="btn btn-primary  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Promoters</button>
                          </div>
                        </div>
                        <div>
                        <h5 className="fw-bold text-left fontPoppins">Locate Dealer Link</h5>
                          <p>
                            Occaecat velit aliqua exercitation aliquip irure deserunt.
                          </p>
                          <div class="input-group mb-3 w-100   rounded form-control">
                            <input type="text" class="form-control" placeholder="please enter dealer name" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                            <span class="input-group-text" id="basic-addon2">add link</span>
                          </div>

                        </div>
                      </div>
                        <div className=" mx-2 p-3 text-end sticky-bottom bg-white ">
                          <button type="button" onClick={()=> setOption(1)} className="btn btn-secondary mx-2 ">Cancel</button>
                          <button type="button" onClick={()=> setOption(3)} className="btn btn-primary ">Save</button>
                        </div>
                    </div>
                  }
                </>            

                {/* Products and Services */}
                <>
                  {
                    option===3 
                    && 
                    <div className="row w-100 p-5">
                      <div onClick={()=> setOption(3.1)} className="col-xl-5 rounded-4 p-3 m-4" style={{backgroundColor:"#F8F8F8"}}>
                        <div className="text-center text-primary" style={{fontSize:"5rem"}}>
                          <IoAddCircleOutline />
                        </div>
                        <h5 className="fw-bold">Add Product</h5>  
                        <p>Elit adipisicing sunt velit deserunt exercitation laboris. Sint ipsum dolor et nulla est mollit culpa enim dolor 
                        exercitation fugiat. Cupidatat sint consectetur labore sit ut duis tempor ut. Laborum amet occaecat laboris ex mollit.</p>   
                      </div>

                      <div onClick={()=> setOption(3.1)} className="col-xl-5 rounded-4 p-3 m-4" style={{backgroundColor:"#F8F8F8"}}>
                        <div className="text-center text-primary" style={{fontSize:"5rem"}}>
                          <IoAddCircleOutline />
                        </div>
                        <h5 className="fw-bold">Add Services</h5>  
                        <p>
                          Elit adipisicing sunt velit deserunt exercitation laboris. Sint ipsum dolor et nulla est mollit culpa enim dolor 
                          exercitation fugiat. Cupidatat sint consectetur labore sit ut duis tempor ut. Laborum amet occaecat laboris ex mollit.
                        </p>   
                      </div>

                      <div className=" py-3 text-end sticky-bottom bg-white">
                        <button type="button" onClick={()=> setOption(2)} className="btn btn-secondary mx-2 ">Cancel</button>
                        <button type="button"onClick={()=> setOption(4)} className="btn btn-primary mx-2">Save</button>
                      </div>
                    </div>    
                  }
                </>

                {/* Products and Services */}
                <>
                  {
                    option===3.1
                    &&
                    <div className="p-5">
                      <div className="my-5">
                        <h5 className="fw-bold text-left">Business Name</h5>
                        <p>Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.</p>
                        <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                      </div>
                      <div>
                        <h5 className="text-left">Product Image</h5>
                        <button type="button" className="btn btn-outline-primary btn-lg mb-2 w-100 "  style={{height:"10rem",border:"2px black dotted"}} >
                          <MdDriveFolderUpload /> Upload or drop your file here
                        </button>
                      </div>
                      <div className="my-5">
                        <h5 className="fw-bold text-left">Brief Description</h5>
                        <textarea  className="w-100 mt-3 p-4 rounded form-control" placeholder="Please Describe Your Business"/>
                      </div>
                      <div className="my-5">
                        <h5 className="fw-bold text-left">price</h5>
                        <p>Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.</p>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">Rs</span>
                          <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                      </div>
                      <div className="my-4 py-3 text-end sticky-bottom bg-white">
                        <button type="button" onClick={()=> setOption(2)} className="btn btn-secondary mx-2 ">Cancel</button>
                        <button type="button"onClick={()=> setOption(4)} className="btn btn-primary mx-2">Save</button>
                      </div>
                    </div>                
                  }
                </>

                {/* Contact Info */}
                <>
                  {
                    option===4
                    &&
                    <div className="p-5">
                      <div>
                        <div className="p-5 rounded-4" style={{backgroundColor :"#F8F8F8"}}>
                          <h5 className="fw-bold text-left">Address</h5>
                          <p>Est sint incididunt cillum nostrud nisi ipsum amet. Culpa ullamco sit excepteur est. Ut reprehenderit
                            mollit officia adipisicing ipsum.</p>
                          <form>
                            <div className="row">
                              <input type="text" className=" col-12 mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              <input type="text" className=" col-12 mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              <input type="text" className=" col-12 mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              <div className="col-lg-6 mt-4 p-0">
                                <input type="text" className=" w-100   p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              </div>
                              <div className="col-lg-6 mt-4 p-0">
                                <select className="h-100 p-2 w-100  form-select" aria-label="Default select example">
                                  <option disabled selected>Please Select Required Experience Level</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>

                              
                              <div className="col-lg-6 p-0">
                                <input type="text" className="w-100  mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              </div>
                              <div className="col-lg-6 p-0">
                                <input type="text" className="w-100  mt-3 p-4 rounded form-control"  placeholder="Enter your Business Name"/>
                              </div>
                            

                            </div>
                          </form>
                          <div className="text-center p-5">
                            <button type="button" className="btn btn-primary  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Address</button>
                          </div>
                        </div>

                        <div className="p-5 rounded-4 my-5" style={{backgroundColor :"#F8F8F8"}}>
                          <h5 className="fw-bold text-left">Contacts</h5>
                          <p>Est sint incididunt cillum nostrud nisi ipsum amet. Culpa ullamco sit excepteur est. Ut reprehenderit
                            mollit officia adipisicing ipsum.</p>
                          <div>
                            <div className='my-3'>
                              <h5 className=' d-flex justify-content-between'>
                                <span className=' fw-bold fs-5'>Department Name</span>
                                <span>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                    <label className="form-check-label fs-6" for="flexCheckChecked">
                                      Select this as primary number 
                                    </label>
                                  </div>
                                </span>
                              </h5>
                              <input type="text" className=" mt-3 p-4 rounded form-control"  placeholder="Department Name"/>

                            </div>
                            <div className='my-3'>
                              <h5 className='mb-3 fw-bold text-left'>
                                Mobile Number                             
                              </h5>
                              <input type="text" className=" mt-3 p-4 rounded form-control"  placeholder="+91"/>
                            </div>
                            <div className='my-3'>
                              <h5 className='mb-3 fw-bold text-left'>
                                Email ID                             
                              </h5>
                              <input type="text" className=" mt-3 p-4 rounded form-control"  placeholder="Example:businessmail@gmail.com"/>
                            </div>                        
                          </div>
                        

                          <div className="text-center p-5">
                            <button type="button" className="btn btn-primary  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Address</button>
                          </div>
                        </div>
                        <div className="p-5 rounded-4 " style={{backgroundColor :"#F8F8F8"}}>
                          <h5 className="fw-bold text-left">Websites and Page Link</h5>
                          <p>Est sint incididunt cillum nostrud nisi ipsum amet. Culpa ullamco sit excepteur est. Ut reprehenderit
                            mollit officia adipisicing ipsum.</p>
                            <div className='my-4'>
                              <h5 className='d-flex justify-content-between'>
                                <span className='fw-bold'>Website</span>   
                                <p><Link to="/" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fs-6">Add More</Link></p>                                                         
                              </h5>
                              <input type="text" className=" mt-3 p-4 rounded form-control"  placeholder="example:www.emilio.com"/>
                            </div>
                            <div>
                              <h5 className='text-left fw-bold'>Page Link                                                           
                              </h5>
                              <input type="text" className=" mt-3 p-4 rounded form-control"  placeholder="https://wikipedia.org/"/>
                            </div>
                            
                        </div>
                        <div className="p-5 rounded-4 my-5" style={{backgroundColor :"#F8F8F8"}}>
                          <h5 className="fw-bold text-left">Business Hour</h5>
                          <p>Est sint incididunt cillum nostrud nisi ipsum amet. Culpa ullamco sit excepteur est. Ut reprehenderit
                            mollit officia adipisicing ipsum.</p>
                            <div className='row my-3 px-0'>
                              <div className='col-md-4 border-right py-3 px-0'>
                                <button type="button" class="btn btn-outline-dark mx-1">9:00 AM</button> To <button type="button" class="mx-1 btn btn-outline-dark">9:00 PM</button>
                              </div>
                              <div className='col-md-8 py-3'>
                                <form className='d-flex justify-content-between flex-wrap'>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="sun" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="sun">Sun</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="mon" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="mon">Mon</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="tue" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="tue">tue</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="wed" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="wed">Wed</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="thu" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="thu">Thu</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="fri" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="fri">Fri</label>
                                  </div>
                                  <div>
                                    <input type="checkbox" class="btn-check" id="sat" autocomplete="off"/>
                                    <label class="btn btn-outline-primary" for="sat">Sat</label>
                                  </div>
                                </form>
                                
                              </div>
                            </div>
                        </div>
                        <div className="text-center p-5">
                          <button type="button" className="btn btn-primary  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Address</button>
                        </div>
                      </div>
                      <div className="my-4 py-3 text-end sticky-bottom bg-white">
                        <button type="button" onClick={()=> setOption(3)} className="btn btn-secondary mx-3 ">Cancel</button>
                        <button type="button" onClick={()=> setOption(5)} className="btn btn-primary mx-3">Save</button>
                      </div>
                    </div>
                  }
                </>

                {/* Opportunity */}
                <>
                  {
                    option===5 
                    && 
                    <div className="p-5">
                      <div className="my-4">
                        <h5 className="fw-bold text-left">Select type of Listing or an Invite </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <select className="form-select" aria-label="Default select example">
                            <option value="1">Job1</option>
                            <option value="2">Job2</option>
                            <option value="3">Job3</option>
                          </select>
                      </div>

                      <div className="my-4">
                        <h5 className="fw-bold text-left">Position </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Position Name"/>
                      </div>

                      <div className="my-4">
                        <h5 className="fw-bold text-left">Location </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Location of Job Posting"/>
                      </div>

                      <div className="my-4">
                        <h5 className="fw-bold text-left">Select Minimum Experience Level </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <select className="form-select" aria-label="Default select example">
                            <option disabled selected>Please Select Required Experience Level</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                      </div>
                      <div>
                        <h5 className="text-left">Upload Image</h5>
                        <button type="button" className="btn btn-outline-primary btn-lg mb-2 w-100 "  style={{height:"10rem",border:"2px black dotted"}} >
                          <MdDriveFolderUpload /> Upload or drop your file here
                        </button>
                      </div>
                      <div className="my-5">
                        <h5 className="fw-bold text-left">Brief Description of Listing or Invite</h5>
                        <textarea  className="w-100 mt-3 p-4 rounded form-control" placeholder="Please Describe Your Business"/>
                      </div>

                      <div className="my-4 mx-0">
                        <h5 className="fw-bold d-flex justify-content-between ">
                          <span>Related Questions</span>  
                          <span>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" role="switch" aria-checked id="flexSwitchCheckChecked" checked />
                            </div>
                          </span>
                        </h5>
                        <p>
                          Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                        </p>
                        <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Position Name"/>
                        <div className="text-center p-5">
                          <button type="button" className="btn btn-outline-primary border-0  rounded-pill px-5"> <span className="fs-5"><IoAddCircleOutline /></span>  Add More Address</button>
                        </div>
                      </div>


                      <div className="my-4 mx-0">
                        <h5 className="fw-bold text-left">Salary Range </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <div className="d-flex justify-content-between">
                            <input type="number" className="col-md-5 mt-3 p-4 rounded form-control"  placeholder="Minimum salary"/>
                            <input type="number" className="col-md-5 mt-3 p-4 rounded form-control"  placeholder="Minimum salary"/>
                          </div>
                      </div>

                      <div className="my-4 mx-0">
                        <h5 className="fw-bold text-left">Contact  </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Position Name"/>
                      </div>
                      <div className="my-4 mx-0">
                        <h5 className="fw-bold text-left">Image </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Position Name"/>
                      </div>

                      <div className="my-4 py-3 text-end sticky-bottom bg-white ">
                        <button type="button"onClick={()=> setOption(4)} className="btn btn-secondary mx-3 ">Cancel</button>
                        <button type="button" onClick={()=> setOption(6)} className="btn btn-primary mx-3">Save</button>
                      </div>
                    </div>
                  }    
                </>
                
                {/* Verification Page */}
                <>
                  {
                    option===6
                    &&
                    <div className="p-5">
                      <div className="m-4">
                        <h5 className="fw-bold text-left">
                          Verify Your Page
                        </h5>
                        <p>
                          Do dolore occaecat consequat eu veniam cillum officia tempor laboris dolore sunt eiusmod. Et sint sunt id occaecat minim
                          aliquip sit velit sint mollit. Consectetur nisi cillum occaecat eu tempor elit fugiat. Quis voluptate veniam dolore minim 
                          sint commodo. Fugiat excepteur culpa id aute irure aliquip et esse. Nulla adipisicing Lorem esse reprehenderit commodo incididunt.
                        </p>
                      </div>
                      <div className="m-4">
                        <h5 className="fw-bold text-left">Country </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Your Country Name"/>
                      </div>
                      <div className="m-4">
                        <h5 className="fw-bold text-left">Category</h5>
                        <select className="form-select" aria-label="Default select example">
                          <option disabled selected>Select Page Category</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="m-4">
                        <h5 className="fw-bold text-left">Interested Audience Type </h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Your page audience"/>
                      </div>
                      <div className='m-4'>
                        
                          <h5 className="text-left">Document Type</h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <select className="form-select p-3 my-4" aria-label="Default select example">
                            <option disabled selected>Select Page Category</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                          <button type="button" className="btn btn-outline-primary btn-lg mb-2 w-100 "  style={{height:"10rem",border:"2px black dotted"}} >
                            <MdDriveFolderUpload /> Upload or drop your file here
                          </button>
                        
                      </div>
                      <div className="m-4">
                        <h5 className="fw-bold text-left">Add Link</h5>
                          <p>
                            Lorem et commodo qui nulla ipsum exercitation voluptate adipisicing.
                          </p>
                          <input type="text" className="w-100 mt-3 p-4 rounded form-control"  placeholder="Enter Your Link"/>
                          <div className="text-center p-5">
                          <button type="button" className="btn btn-outline-primary   rounded-pill px-5"> 
                            <span className="fs-5"><IoAddCircleOutline /></span>  Add More Link
                          </button>
                        </div>
                      </div>
                      <div className="m-4 text-end ">
                        <button type="button" onClick={()=> setOption(5)} className="btn btn-secondary mx-3 ">Cancel</button>
                        <button type="button" className="btn btn-primary mx-3">Submit</button>
                      </div>
                    </div>
                  }
                </>
              </div>
            </section>
          </Modal.Body> 
      </Modal>
    </>
  );
};

export default EditPagePopup;