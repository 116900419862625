import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
// import CreateReels3 from './CreateReels3';
import CreateReels4 from './CreateReels4';
import GetThumbnails from '../../../ThumbnailExtractor/GetThumbnails';
import { BASE_URL_STATIC } from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import FlixAdvanceSettingPopup from './FlixAdvanceSettingPopup';



const CreateReels2 = ({ reels, thumbnailReel,setSelectedEmoji, showCreateReelsPopup1, setShowCreateReelsPopup1, location, setLocation }) => {

  const [showCreateReelsPopup2, setShowCreateReelsPopup2] = useState(false);
  // const [showCreateReelsPopup3, setShowCreateReelsPopup3] = useState(false);
  const [showCreateReelsPopup4, setShowCreateReelsPopup4] = useState(false);
  const [status, setStatus] = useState(false);

  const [selectedImage, setSelectedImage] = useState()



  useEffect(() => {
    if (reels) {
      setShowCreateReelsPopup1(false);
      setShowCreateReelsPopup2(true);
    }
  }, [reels]);

  const handleCreateReelPopupPre2 = () => {
    setShowCreateReelsPopup1(true);
    setShowCreateReelsPopup2(false);
  };

  // const handleCreateReelPopupAddAudio = () => {
  //   setShowCreateReelsPopup2(false);
  //   setShowCreateReelsPopup3(true);
  // };

  const handleCreateReelPopupNext2 = () => {
    setShowCreateReelsPopup2(false);
    setShowCreateReelsPopup4(true);
  };


  // ----- Advance Setting ----- 

  const [showFlixAdvanceSettingPopup, setShowFlixAdvanceSettingPopup] = useState(false);


  return (
    <>
      <FlixAdvanceSettingPopup 
        showFlixAdvanceSettingPopup={showFlixAdvanceSettingPopup}
        setShowFlixAdvanceSettingPopup={setShowFlixAdvanceSettingPopup}
        setShowCreateReelsPopup2={setShowCreateReelsPopup2}
      />

      {/* Create Reels Popup 4 */}
      <CreateReels4
        reels={reels}
        showCreateReelsPopup2={showCreateReelsPopup2} setSelectedEmoji={setSelectedEmoji} selectedImage={selectedImage} setShowCreateReelsPopup2={setShowCreateReelsPopup2}
        showCreateReelsPopup4={showCreateReelsPopup4} setShowCreateReelsPopup4={setShowCreateReelsPopup4}
      
      />

      <section>
        <Modal show={showCreateReelsPopup2} onHide={() => setShowCreateReelsPopup2(false)} backdrop className='create-call' centered >
        {/* <Modal show={true} onHide={() => setShowCreateReelsPopup2(false)} backdrop className='create-call' centered > */}
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <section id='create-reels-1' >
              <div
                style={{
                  display: 'flex'
                }}
              >
                <span style={{ fontSize: '25px', cursor: 'pointer' }} onClick={handleCreateReelPopupPre2}>
                  <FontAwesomeIcon  style={{fontSize: "20px" }} icon={faAngleLeft}/>
                </span>
                <p style={{ width: '440px', textAlign: 'center', fontSize: '20px', padding: '5px 0px' }}>Create Reels </p>
              </div>


              {/* Editing Tools */}
              {/* <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '20px 0px' }}>
                <img src={`${BASE_URL_STATIC}/icon/CRI1.svg`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri2.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri3.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri4.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri5.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri6.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
                <img src={`${BASE_URL_STATIC}/cri7.png`} alt="icon" height='40' width='40' style={{ backgroundColor: '#EBEBEB', borderRadius: '50%', padding: '5px' }} />
              </div> */}

              <div className='col-8' 
              style={{ 
                // height: '459px',
                //  width: '279px',
                  paddingRight: '21px',
                    margin:"20px auto"}}>
                {/* <img src={`${BASE_URL_STATIC}/cr2i8.png`} alt="cr2i8" /> */}
                {
                  reels
                    ?
                    <video height='100%' width='100%' controls style={{borderRadius:"10px", background:"black" }}>
                      <source src={URL.createObjectURL(reels)} type="video/mp4" />
                    </video>
                    :
                    ""
                }
              </div>

              {/* Add Rearrange Remove */}

              <p className='thumbnail'>
                <img src={`${BASE_URL_STATIC}/icon/poster.svg`} alt="" />
                  &nbsp;Thumbnails</p>

              <GetThumbnails 
              status={status} setStatus={setStatus}
              file={thumbnailReel} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                

              <div style={{ display: 'flex' }}>
                {/* <div style={{ display: 'flex', alignContent: "center", paddingTop: "20px" }}>
                  <img src={`${BASE_URL_STATIC}/trim.png`} alt="trim" height='15' width='17' />
                  <p style={{ padding: '0px 10px 0px 5px' }}>Trim</p>
                </div> */}
                {/* <p style={{ height: '26px', width: '100px', backgroundColor: '#EBEBEB', borderRadius: '21px', textAlign: 'center', marginRight: '10px' }}>Add Video</p>
                <p style={{ height: '26px', width: '100px', backgroundColor: '#EBEBEB', borderRadius: '21px', textAlign: 'center', marginRight: '10px' }}>Rearrange</p>
                <p style={{ height: '26px', width: '100px', backgroundColor: '#EBEBEB', borderRadius: '21px', textAlign: 'center', marginRight: '10px' }}>Remove</p> */}
              </div>



              {/* <div>
                <div style={{ height: '50px' }}>
                  
                  {
                    reels
                    &&
                    <video height='100%' width='100%'>
                      <source src={URL.createObjectURL(reels)} type="video/mp4" />
                    </video>
                  }
                </div>
                <pre style={{ letterSpacing: '2px', padding: '13px 0px 13px 13px' }}>| 1s | 2s | 3s | 4s | 5s | 6s | 7s | 8s | 9s |</pre>
              </div> */}

              {/* <div>
                <div style={{ display: 'flex' }}>
                  <img src={`${BASE_URL_STATIC}/cri4.png`} alt="cri4" height='20' width='20' style={{ margin: '0px 10px 0px 10px' }} />
                  <p>This video has no audio</p>
                </div>
                <div onClick={handleCreateReelPopupAddAudio}
                  style={{
                    width: '450px',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#0000002B',
                    borderRadius: '15px',
                    padding: '7px',
                    margin: '7px auto 20px',
                    cursor: 'pointer',
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/cri4.png`} alt="cri4" height='20' width='20' style={{ margin: '0px 10px 0px 10px' }} />
                  <p>Add Audio</p>
                </div>
              </div> */}

              <button className='reel2btn' onClick={handleCreateReelPopupNext2} disabled={status}>Next</button>

              <div className='text-center text-primary cursor-pointer mt-3' 
                onClick={() => {
                  setShowCreateReelsPopup2(false);
                  setShowFlixAdvanceSettingPopup(true);
                }}
              >Advance Setting</div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default CreateReels2;