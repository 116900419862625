import React, { useEffect, useState } from 'react';
import { convertS3UrlToBlobUrl, AwsFileUrl } from '../../../config';

export function VideoShow({ url, classHere, mute, videoRef = 0, controls = false, autoPlay = true }) {
    let theBlogURl = '';
    const [videoUrl, setVideoUrl] = useState(null);
    // const [videoUrl, setVideoUrl] = useState(`${AwsFileUrl}${url}`);

    const handleRightClick = (e) => {
        e.preventDefault();
        // Prevents the default right-click menu
        // alert("Right-click is disabled on this component");
    };

    useEffect(() => {
        let isMounted = true;

        async function fetchImageBlob() {
            const blobUrl = await convertS3UrlToBlobUrl(`${AwsFileUrl}${url}`);
            theBlogURl = blobUrl;
            if (isMounted) setVideoUrl(blobUrl);
        }
        fetchImageBlob();

        // Clean up the Blob URL when component unmounts
        return () => {
            isMounted = false;
            URL.revokeObjectURL(theBlogURl);
        };
    }, [url]);

    return (
        <>
            <video
                onContextMenu={handleRightClick}
                src={videoUrl}
                className={classHere}
                autoPlay={autoPlay}
                loop
                muted={mute}
                ref={videoRef ? videoRef : null}
                crossOrigin="anonymous"
                controls={controls}
            ></video>
        </>
    );
}

export default VideoShow;
