import React, { useContext, useEffect, useState } from "react";
import BackToTop from "../Helper/BackToTop";
import { ApiContext } from "../UseContext/ApiCall";
import {  getDataTypePost } from "../UseContext/ApiHelpers";
import Slider from "react-slick";
import axios from "axios";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../config";

import PostTime from "../Home/CenterBlock/CenterBlockPosts/PostTime";
import FollowUnfollowButton from "../Button/FollowUnfollowButton";
import FavouriteBestClipButton from "../Button/FavouriteButton/FavouriteBestClipButton";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup";

const Bestclips = ({ className }) => {

  const [bestClips, setBestClips] = useState([])
  // const [bestClipPopoup , setBestClipPopoup] = useState(false)

  const [page, setPage] = useState(1)
  const [category, setCategory] = useState([])
  const { config } = useContext(ApiContext);
  const [categoryType, setCategoryType] = useState("");
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setPostData] = useState(null);


  useEffect(() => {
    getDataTypePost('bestclips', 'posttype',categoryType, page, config).then(res => {
      setBestClips(res)
      setPage(page + 1)
    });
  }, [categoryType]);

  // To show next bestclip
  // const handleNextClip = () => {
  //   if (currentIndex < bestClips.length - 1) {
  //     setCurrentIndex(currentIndex + 1);
  //     setBestClipId(bestClips[currentIndex + 1].bestclipId);
  //   }
  // };

  // To show previous bestclip
  // const handlePreviousMeme = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //     setBestClipId(bestClips[currentIndex - 1].bestclipId);
  //   }
  // };

  const settings = {
    className: 'bestClips-categories',
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    // slidesToShow: 10,
    responsive: [
      {
        breakpoint: 2560, // Medium devices (tablets, laptops, etc.)
        settings: {
          slidesToShow: 10,
        }
      },
      {
        breakpoint: 425, // Small devices (portrait phones, less than 600px)
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  };


  const fetchCategories = async () => {
    try {
      const response = await axios.post(`${BASEAPIURL}/post/getBestclipCategories`);
      const categoryData = response.data.list;
      const parentCategories = [];
      categoryData.forEach(item => {
        if (!parentCategories.includes(item.parentCategoryName)) {
          parentCategories.push(item.parentCategoryName);
        }
      });
      setCategory(parentCategories);

    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories()
  }, []);


  return (
    <>
      {/* BestClips Popup */}
      {/* <BestClipPopup open={bestClipPopoup} close={() => setBestClipPopoup(false)} bestClipId={bestClipId} onNext={handleNextClip} /> */}

      {/* Profile Best Clip Popup */}
      {/* <ProfileBestClipPopup showProfileBestClipPopup={showProfileBestClipPopup} setShowProfileBestClipPopup={setShowProfileBestClipPopup} profileBestClipPopupData={profileBestClipPopupData} /> */}

      <section className="bestClips">
        <section className={`section-main ${className || ""}`}>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-12">
                <div className="center-block">
                  <div className="explore-block">
                    <div className="row no-gutters">
                      {category.length > 0 && <Slider {...settings}>
                        <span onClick={() => {setCategoryType(""); setPage(1);}} className="bestClips-categories-Span">All</span>
                        {
                          category.map((category, index) => (
                            <span style={{width : "200px"}} onClick={() => {setCategoryType(category); setPage(1);}} className=" text-truncate bestClips-categories-Span" key={index}>{category}</span>
                          ))
                        }
                      </Slider>
                      }

                      <div className="masonary bestclips">
                        {
                          bestClips && bestClips.map((clip, index) => (
                            
                            // <div className="imgBox" key={clip.bestclipId} onClick={() =>{ setBestClipPopoup(true); setBestClipId(clip.bestclipId)}}>
                            <div className="imgBox" key={clip.bestclipId}>
                           <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />
                              <img className="rounded" src={AwsFileUrl + clip.postTumbnailUrl} alt="thumbnail" onError={(e) => e.target.src = `${BASE_URL_STATIC}/feed-img1.jpg`} />

                              {/* {clip.details && clip.details[0] &&

                               (
                                <div>
                                 {clip.details[0].videoId && (<video controls autoPlay >
                                    <source src={`https://emilonode.sjain.io/post/getvideobyid?bestclipVideoId=${clip.details[0].videoId}`} />
                                  </video>)}
                                </div>
                              )} */}

                              <div className="iconBox">
                                <img src={`${BASE_URL_STATIC}/icon/white-bestclips.svg`} alt="bestclips" />
                              </div>

                              <div className="likeBox"   onClick={() =>{setShowPostsPopup(!showPostsPopup);  setPostId(clip._id); setPostData(clip)}}>
                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon4.png`} alt="" />
                                {clip._id && clip.viewcount}
                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon5.png`} alt="" />
                                {clip._id && clip.likecount}
                              </span>

                              <span>
                                <img src={`${BASE_URL_STATIC}/explore-icon6.png`} alt="" />
                                {clip._id && clip.commentcount}

                              </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>

                      {/* <BestClipPopup open={bestClipPopoup} close={() => setBestClipPopoup(false)} bestClipId={bestClipId} onNext={handleNextClip} onPrev={handlePreviousMeme}/> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <BackToTop />
      </section>




      {/* ----- Mobile > Bestclip ----- */}
      <section className="mobile-bestClips">
        {/* Categories */}
        <div className="mbc-cat">
          {
            category.map((category, index) => (
              <span className="mbc-cat-span">{category}</span>
            ))
          }
        </div>

        <div className="mbc-bestClips">
          {
            bestClips
            &&
            bestClips.map((bestClip, index) => (
              <div className="mbc-bestClip">
                {/* User */}
                <div className="mbc-user">
                  <div className="mbc-user-profile">
                    <img src={`${BASE_URL_STATIC}/user-icon${index + 1}.jpg`} alt="profile" />
                    <img src={`${BASE_URL_STATIC}/user-icon${index + 1}.jpg`} alt="profile" />
                  </div>

                  <div className="mbc-user-header">
                    <div className="mbc-user-header-1">
                      <span className="mbc-user-name">{bestClip.fname}</span>
                      <span className="mbc-user-activity">Activity</span>
                    </div>

                    <div className="mbc-user-header-2">
                      <span className="mbc-user-location">Location</span>
                      <span className="mbc-user-time">
                        <PostTime date={bestClip.createdTime} />
                      </span>
                    </div>
                  </div>

                  <div className="mbc-user-fubtn">
                    <FollowUnfollowButton otherUserId={bestClip.userId} />
                  </div>

                  <div className="mbc-user-threeDot">
                    <i className="far fa-ellipsis-v" />
                  </div>
                </div>

                {/* Show */}
                <div className="mbc-show">
                  <img src={`${BASE_URL_STATIC}/user-img${index + 1}.jpg`} alt="show" />
                </div>

                {/* Area */}
                <div className="mbc-area">
                  <div className="mbc-area-view">
                    {
                      bestClip.viewCount === 0
                        ?
                        <img src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
                        :
                        <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
                    }
                    <span>{bestClip.viewcount}</span>
                  </div>

                  <div className="mbc-area-like">
                    <img src={`${BASE_URL_STATIC}/icon/like-2.svg`} alt="view" />
                    <span>{bestClip.likecount}</span>
                  </div>

                  <div className="mbc-area-comment">
                    <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="view" />
                    <span>{bestClip.commentcount}</span>
                  </div>

                  <div className="mbc-area-share">
                    <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="view" />
                    <span>{bestClip.sharecount}</span>
                  </div>

                  <div className="mbc-area-favourite">
                    {/* <img src={`${BASE_URL_STATIC}/icon/favourite-1.svg`} alt="view" />   */}
                    <FavouriteBestClipButton bestClipId={bestClip.bestclipId} />
                  </div>

                  <div className="mbc-area-download">
                    <img src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="view" />
                  </div>
                </div>

                {/* Description */}
                {
                  bestClip.description
                  &&
                  <div className="mbc-description">
                    <span>{bestClip.description}</span>
                  </div>
                }

                <hr className="mbc-bestClip-hr" />
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
}

export default Bestclips;
