import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import TrendingFeeds from "./TrendingFeeds.js";
import Suggestions from "./Suggestions.js";

const RightBlock = () => {
  
  return (
    <>
      <section className="right-block">
        <TrendingFeeds />
        <Suggestions />
      </section>
    </>
  );
}

export default RightBlock;
