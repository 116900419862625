import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { ScApiContext } from "../useContext/softCornerContext";
import { addSCPartner, createConnection, getPartnerinfo } from "../../Emilo/UseContext/ScApiHelpers";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import axios from "axios";
import { BASEAPIURL } from "../../../config";
import toast from "react-hot-toast";

const AddRemoveBtn = ({ partnerUserId }) => {

  const [btnLoader, setbtnLoader ] = useState(false);

  const {
    fetchAddStatus, setCurrentCrush,setBtnEnable,setRightBlockUser,
    scConfig, setIsSoftCornerPartnerExist,setChatId
  } = useContext(ScApiContext);

  const { config, loggedInUserId } = useContext(ApiContext)

  useEffect(() => {
    fetchAddStatus(partnerUserId);
    setCurrentCrush(partnerUserId)
    
  }, []);


  const handleClick = () => {
    setbtnLoader(true);
    addSCPartner(loggedInUserId, partnerUserId, "add", scConfig).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message);
        setbtnLoader(false);
        setRightBlockUser(null)
      }
      else {
        toast.error(res.data.message);
        setbtnLoader(false);
      }
    }).then(() => {
      getPartnerinfo(config).then((res) => {
        if (res.status === 200) {
          setBtnEnable(false);
          setIsSoftCornerPartnerExist(true);
        } 
        else if (res.status === 201) {
          setBtnEnable(true);
          setIsSoftCornerPartnerExist(false);
        } else {
          setIsSoftCornerPartnerExist(false)
        }
      })
      createConnection(scConfig).then((res) => {
        setChatId(res.chatId)
      })
    })
    addProfileCrush('crush')
  }

  const addProfileCrush = async (type) => {
    await axios.post(`${BASEAPIURL}/softcorner/createprofileviewsc`, { type, crushUserId: partnerUserId }, config)
      .then(res => {
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <button
        // disabled={buttonDis}
        type="button"
        className="btn btn-primary px-5 rounded-5"
        onClick={() => {
          handleClick()

        }}
      >
        {btnLoader ? <span className="spinner-border p-2" role="status">
          <span className="visually-hidden"></span>
        </span> : 'Add'}
      </button>

    </>
  );
};

export default AddRemoveBtn;
