import React, { useContext, useEffect, useState } from 'react';
import CenterBlock from './CenterBlock/CenterBlock';
import RightBlock from './RightBlock/RightBlock';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';

const Home1 = () => {

  const { registeredImage, updateProfileImage, config } = useContext(ApiContext)
  const [displayCB , setdisplayCB] = useState('center')

  useEffect(() => {
    if (registeredImage !== null) {

      const formData = new FormData()

      formData.append("image", formData)

      updateProfileImage({ formData, config })
    }
  }, [registeredImage, config]);


  return (
    <>
      <section className='vh-100 row w-100 mx-auto'>
        <div className={`${displayCB === 'center' ? 'd-block' : 'd-none'} col-12 col-lg-8 d-lg-block px-0`}>
          <CenterBlock setdisplayCB={setdisplayCB} />
        </div>
        
        <div className={`${displayCB === 'right' ? 'd-block' : 'd-none'} col-12 col-lg-4 d-lg-block px-0`}>
          <RightBlock setdisplayCB={setdisplayCB}/>
        </div>
      </section>
    </>
  );
};

export default Home1;
