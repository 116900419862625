import React from 'react';
import Modal from "react-bootstrap/Modal";
import FollowUnfollowButton from '../../Button/FollowUnfollowButton';
import { Link } from 'react-router-dom';
import { BASEURL, BASE_URL_STATIC } from '../../../../config';

const NotificationNamePopup = ({ showNotificationNamePopup, setShowNotificationNamePopup, notificationNamePopupData }) => {

  // console.log(notificationNamePopupData);

  return (
    <>
      <Modal show={showNotificationNamePopup} onHide={() => setShowNotificationNamePopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section className='notificationNamePopup'>
            <div className='nnp-users'>
              {
                notificationNamePopupData.map(user => (
                  <div className='nnp-user'>
                    <div className='nnp-user-profile'>
                      <img src={user.profileImg} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                    </div>

                    <div className='nnp-user-header'>
                      <Link to={`${BASEURL}/profile/${user.senderUserId}`} className='nnp-user-name'>{user.fname}</Link>
                      <Link to={`${BASEURL}/profile/${user.senderUserId}`} className='nnp-user-username'>{user.fname}</Link>
                    </div>

                    <div className='nnp-user-btn'>
                      <FollowUnfollowButton otherUserId={user.senderUserId} />
                    </div>
                  </div>
                ))
              }
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationNamePopup;
