import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getSkillDetails, skillsDetail } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { skillsCategory } from "../../../Data/EducationData";
import { BASE_URL_STATIC } from "../../../../../config";

const SkillsPopup = ({ open, close }) => {
  
  const [range, setRange] = useState(1);
  const [category, setCategory] = useState("");
  const [skill, setSkills] = useState("");
  
  const { setSkillData } = useContext(profileContext);

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setCategory(category);
  };

  var filteredSkills = (
      skillsCategory.find(function (categoryObj) {
        return categoryObj.category_name === category;
      }) || {}
    ).skills || [];

  async function handleSubmit(e) {
    e.preventDefault();

    close();

    const input = { range, category, skill };
    await skillsDetail(input).then(() =>
      getSkillDetails().then((res) => setSkillData(res))
    );
  };

  return (
    <>
      <section>
        <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={handleSubmit}>
              <h5 >Skills</h5>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "150px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/platform.svg`} alt="" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Skill Categories
                  </p>
                </span>

                <div className="col-lg-11">
                  <select
                    className="form-control"
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #a6a6a659",
                      marginTop: "12px",
                    }}
                    onChange={handleCategoryChange}
                    value={category}
                  >
                    <option
                      className=""
                      style={{ textAlign: "center", color: "#616161" }}
                    >
                      --select--
                    </option>
                    {skillsCategory.map((e, i) => (
                      <option key={i} value={e.category_name}>
                        {e.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "120px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/degree.svg`} alt="" />

                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Skill Name
                  </p>
                </span>

                <div className="col-lg-11">
                  <select
                    className="form-control"
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #a6a6a659",
                      marginTop: "12px",
                    }}
                    value={skill}
                    onChange={(e) => setSkills(e.target.value)}
                  >
                    <option
                      className=""
                      style={{ textAlign: "center", color: "#616161" }}
                    >
                      --select--
                    </option>
                    {filteredSkills.map((skill, i) => (
                      <option key={i} value={skill}>
                        {skill}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "130px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Current Level
                  </p>
                </span>

                <div>
                  <input
                    type="range"
                    className="w-100"
                    min="1"
                    max="3"
                    value={range}
                    onChange={(e) => setRange(e.target.value)}
                    id="customRange2"
                    style={{ height: "2px", width: "full" }}
                  />

                  <div className="flex my-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setRange(1)}
                    >
                      Beginner
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setRange(2)}
                    >
                      Intermediate
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setRange(3)}
                    >
                      Expert
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-dark mt-4 col-lg-4">Save</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default SkillsPopup;
