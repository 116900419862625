import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPosts } from "../../Emilo/UseContext/ScApiHelpers";
import { ScApiContext } from "../useContext/softCornerContext";
import { Hearts } from "react-loader-spinner";
import GallaryPopup from "./GallaryPopup";
import { BASE_URL_STATIC } from "../../../config";

const Gallary = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [todayPost, setTodayPosts] = useState([]);
  const [yesterdayPost, setYesterdayPosts] = useState([]);
  const [olderPost, setOlderPosts] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [openPopup , setOpenPopup] = useState(false)
  const [data , setData] = useState(null)


  const { scConfig } = useContext(ScApiContext);

  useEffect(() => {
    setSpinner(true);
    getPosts(scConfig).then((res) => {
      setAllPosts(res);
      setSpinner(false);
    });
  }, []);

  useEffect(() => {
    if (allPosts) {
      const currentDate = new Date();
      const today = allPosts.filter((post) => {
        const postDate = new Date(post.createdTime);
        return postDate.toDateString() === currentDate.toDateString();
      });
      setTodayPosts(today);

      const yesterday = allPosts.filter((post) => {
        const postDate = new Date(post.createdTime);
        const yesterdayDate = new Date(currentDate - 86400000);
        return postDate.toDateString() === yesterdayDate.toDateString();
      });
      setYesterdayPosts(yesterday);

      const older = allPosts.filter((post) => {
        const postDate = new Date(post.createdTime);
        return (
          postDate < currentDate &&
          postDate.toDateString() !== currentDate.toDateString()
        );
      });
      setOlderPosts(older);
    }
  }, [allPosts]);

  return (
    <>
      {data &&  <GallaryPopup open={openPopup} data={data} close={() => setOpenPopup(false)} />}

      <section className="sc-gallary">
        <section className="sc-gallery-right">
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`} alt="sc-gallary-photos" height={30} width={30} />
              <span>Photos</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Album`} className="sc-gallary-nav-div">
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`} alt="sc-gallary-album" height={30} width={30} />
              <span>Album</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Explore`} className="sc-gallary-nav-div" >
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`} alt="sc-gallary-explore" height={30} width={30} />
              <span>Explore</span>
            </Link>
          </div>

          <section>
            { 
              spinner 
              ?
              <Hearts height="150" width="150" color="#ED1E24" ariaLabel="hearts-loading" wrapperStyle={{}} wrapperClass="gallary-loader" visible={true} />
              :
              <>
                {/* Today */}
                {todayPost && todayPost.length > 0 && (
                  <div className="sc-gallary-older">
                    <span className="sc-gallary-older-head">Today</span>
                    <div className="older-posts">
                      {todayPost.map((post, index) => (
                        <span key={index} className="older-posts-span" onClick={() =>{ setOpenPopup(true); setData(post)}}>
                          {post.details && post.details[0].videoId && (
                            <video>
                              <source src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${post.details[0].videoId}`} />
                            </video>
                          )}
                          {post.details && post.details[0].imageId && (
                            <img src={post.details[0].imageUrl} alt="" />
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Yesterday */}
                {yesterdayPost && yesterdayPost.length > 0 && (
                  <div className="sc-gallary-older" >
                    <span className="sc-gallary-older-head">Yesterday</span>
                    <div className="older-posts">
                      {yesterdayPost.map((post, index) => (
                        <span key={index} className="older-posts-span" onClick={() => {setOpenPopup(true); setData(post)}}>
                          {post.details && post.details[0].videoId && (
                            <video>
                              <source src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${post.details[0].videoId}`} />
                            </video>
                          )}
                          {post.details && post.details[0].imageId && (
                            <img src={post.details[0].imageUrl} alt="" />
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Older */}
                {olderPost && olderPost.length > 0 && (
                  <div className="sc-gallary-older" >
                    <span className="sc-gallary-older-head">Older</span>
                    <div className="older-posts">
                      {olderPost.map((post, index) => (
                        <span key={index} className="older-posts-span" onClick={() => {setOpenPopup(true); setData(post)}}>
                          {post.details && post.details[0].videoId && (
                            <video>
                              <source src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${post.details[0].videoId}`} />
                            </video>
                          )}
                          {post.details && post.details[0].imageId && (
                            <img src={post.details[0].imageUrl} alt="" />
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </>
            }
          </section>
        </section>
      </section>
    </>
  );
};

export default Gallary;
