import React from 'react'

const WebNavigation = () => {
  return (
    <>
      <div className='d-flex justify-content-center align-items-center text-center w-75 vh-100 mx-auto'>
        <p>If you want to understand how the e-milo website works, you can use its navigation features to move around the site and explore its content.</p>
      </div>
    </>
  )
}

export default WebNavigation