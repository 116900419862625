import React from 'react'
import { Modal } from 'react-bootstrap'

const ScPhotoPopup = ({ open, close }) => {
   return (
      <div>
         <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call">
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               <div>
                  <img src="https://img.freepik.com/free-photo/people-sitting-bench-forest-night-generative-ai_260559-472.jpg?w=740&t=st=1706098485~exp=1706099085~hmac=1a851d3619e039dea9d84ff530908449444544af7957fb4a786053c44754bd70" alt="" />
               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default ScPhotoPopup