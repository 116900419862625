import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { MdCancel } from "react-icons/md";
// import { MdOutlineAddLocation } from "react-icons/md";
import { BASE_URL_STATIC } from '../../../config';
import { TbDeviceDesktopUp } from "react-icons/tb";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const BusinessBoostPost2 = () => {



  const handleRemove = () => {
    console.log("handleRemove");
  }

  // const newLocal = "Text...";
  return (


    <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden" style={{ backgroundColor: "#F5F5F5" }}>


     <div
        className="shadow-sm btn-xs rounded  mt-3 mb-3 me-3 ms-2 rounded-lg d-flex align-items-center"
        style={{ borderRadius: "16px" }}
      >
        <h4 className="p-2 flex-grow-1 unnamed-character-style-1 font16">Boost Post</h4>
        <button
          className="btn btn-outline-secondary btn-sm me-2 d-flex gap-2 align-items-center"
        >
          <TbDeviceDesktopUp style={{ fontSize: "20px" }} />
          Publish Report
        </button>{" "}
      </div>




      <div className='row  m-0 g-4'>
        <div className='col-md-7   h-100 overflow-y-scroll scrollbar-hidden'>
          <div className='   p-3 shadow-sm rounded-4  w-100 h-100' style={{ background: "#fff" }}>

            <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 rounded-3 borderSec bg-white" >

              <p className='font18 fw-semibold'>Ad Creative</p>
              <input class="form-control form-control-lg p-3  p-3 placeholder-sizexyz" type="text" placeholder="Add Description" aria-label=".form-control-lg" />


              <div className="container borderSec text-center mt-3 mb-3 p-3  rounded-2" style={{ border: '1px dashed #B9B9B9' }}>


                <img src={`/images/icons/addimage.svg`} alt="xyz" className='m-2' />
                <p>Add Photos/Videos</p>
                <small>Or drag and drop</small>

                <input class="form-control  w-100" type="file" placeholder="Default input" aria-label="default input example" style={{ opacity: "0" }}></input>

              </div>

              <input class="form-control form-control-lg p-3  p-3 placeholder-sizexyz" type="text" placeholder="Ad Heading" aria-label=".form-control-sm" />




            </div>



            <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 rounded-3 borderSec bg-white" >

              <p className='font18 fw-semibold'>Categories</p>
              <small className='text-secondary py-2 font14'>  Select the  Categories  that best describe what this campaign will  adverites</small>
              <select class="form-select mt-1" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>

            </div>

            <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 rounded-3 borderSec bg-white">

              <p className='font18 fw-semibold'> Sub Categories</p>
              <small className='font14 text-secondary py-2'>  Select the  Categories  that best describe what this campaign will  adverites</small>

              <select class="form-select mt-1" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>


            <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 bg-white rounded-3 borderSec">

              <p className='font18 fw-semibold'>Select Saved Aoudience</p>
              <small className='font14 text-secondary py-2'>  Select the  Categories  that best describe what this campaign will  adverites</small>



              <select class="form-select mt-1" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div className=" mx-2  mt-3 p-3 shadow-none p-3 mb-5 bg-white borderSec text-center rounded-3">
              <h4>Create Audience</h4>
              <small>You don't have any save audience  <span className='text-primary'> click here </span> to create audience <br />
                You will redicreate to emilo business mangaement  page
              </small>

            </div>

            {/* Action Button */}
            <div className=" mx-2  p-3 shadow-none p-3 mb-5 bg-white borderSec rounded-3">

              <p className='font-18 fw-semibold'>Action Button</p>
              <small className='font-14 text-secondary py-2'>  Select the  Categories  that best describe what this campaign will  adverites</small>
              <select class="form-select mt-1" aria-label="Default select example">
                <option selected>Contact us</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>

              <div className="input-group mt-2 mb-3">
                <input type="text" className="form-control" placeholder="Enter Your website URL..." aria-label="Recipient's username" aria-describedby="basic-addon2" />

              </div>
            </div>
            {/* Location  */}
            <div className=" mx-2  mt-3 p-3 shadow-none p-3 bg-white borderSec rounded-3">

              <p className='font18 fw-semibold'>Location</p>
              <small className='font14 text-secondary py-2'>  Select the  Categories  that best describe what this campaign will  adverites</small>
              <br />

              <input type="radio" className='m-1' />
              <p className='fs-6 fw-1 d-inline-block'>Redius Selection</p>
              <br />
              <small className='text-secondary py-2 '>  this campaign will  adverites</small>

              <div class="text-center">
                <img src={`/images/DemoImage/india.png`} alt="location" className='' />

              </div>

              <input type="radio" className='m-1' />
              <p className='fs-6 fw-1 d-inline-block'>Custom Selection</p>
              <br />
              <small className='text-secondary py-2 '>  this campaign will  adverites</small>



              <p className='fs-6 fw-1 mt-1'>Country</p>



              <div class="input-group flex-nowrap">
                <span class="input-group-text" > <CiLocationOn className="input-icon text-primary" /></span>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping" />
              </div>

              <p className='fs-6 fw-1 mt-1'>State</p>

              <div class="input-group flex-nowrap">
                <span class="input-group-text" > <CiLocationOn className="input-icon text-primary" /></span>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping" />
              </div>




              <Stack className='row row-cols-4 row-cols-lg-4   row-cols-md-3 row-cols-sm-2 g-2 g-3 mt-1' direction="horizontal" gap={2}>
                <Badge bg="primary col cd">Chhattisgarh <span className='cp' onClick={handleRemove}> <MdCancel /></span></Badge>
                <Badge bg="primary col cd">Madhya Pradesh <span className='cp' onClick={handleRemove}> <MdCancel /></span></Badge>
                <Badge bg="primary col  cd">Maharashtra <span className='cp' onClick={handleRemove}> <MdCancel /></span></Badge>
                <Badge bg="primary col cd">Delhi <span className='cp' onClick={handleRemove}> <MdCancel /></span></Badge>
              </Stack>




              <p className='fs-6 fw-1 mt-1'>City</p>



              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>



              <p className='fs-6 fw-1 mt-1'>City (Exclude)</p>

              <select class="form-select" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>


            </div>


            <div className=" mx-2 borderSec  mt-3 p-3 shadow-none p-3 bg-white rounded-3">

              <p className='font18 fw-semibold'>Age</p>

              <input type="range" class="form-range" min="0" max="5" step="0.5" id="customRange3" />


            </div>



            <div className=" mx-2  mt-3 p-3 shadow-none p-3 bg-white rounded-3">

              <p className='fs-5 fw-1'>Gender</p>
              <div className='d-flex mt-2'>
                <div className='bg-white borderSec  px-4 py-2 rounded-3 me-3 text-nowrap cursor-pointer'>
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />

                  All

                </div>


                <div className='bg-white borderSec px-4 py-2 rounded-3 me-3 text-nowrap cursor-pointer'>
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />

                  Male

                </div>


                <div className='bg-white borderSec px-4 py-2 rounded-3 me-3 text-nowrap cursor-pointer'>
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />

                  Female

                </div>

              </div>

            </div>


            <div className=" mx-2  mt-3 mb-2 p-3 p-3 mb-5 bg-white borderSec rounded-3" >

              <p className='font18 fw-semibold'>Shcedule</p>
              <small className='font14 text-secondary py-2'>  Select the  Categories  that best describe what this campaign will  adverites</small>




              <p className='font18 fw-1 mt-2'>Start Date</p>

              <div className="input-group mt-2 mb-3">

                <input
                  type="date"
                  className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer "

                />


                <input type="time" id="appointment-time" name="appointment-time" className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer" />


                <div className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer ">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Set End Date


                </div>

              </div>

              <p className='fs-5 fw-1'>End Date</p>

              <div className="input-group mt-2 mb-3">

                <input
                  type="date"
                  className="bg-white px-5 py-2 borderSec rounded-3 me-3 text-nowrap cursor-pointer "

                />
                <input type="time" id="appointment-time" name="appointment-time" className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer " />

              </div>

            </div>

            <div className='row g-4'>

              <div className='col-md-6'><button type="button" className="btn btn-success mt-1 ml-1"> Save  Draft</button></div>


              <div className='col-md-3 d-flex justify-content-end '><button type="button" className="btn btn-secondary mt-1 ml-1">Cancel</button></div>


              <div className='col-md-3 d-flex justify-content-start'><button type="button" className="btn btn-primary mt-1 ml-1">Next</button></div>

            </div>


          </div>

        </div>


        {/* section-2 */}
        <div className='col-md-5 h-100  overflow-y-scroll scrollbar-hidden'>


          <div className='  p-3 borderSec bg-white rounded-4 w-100 h-100'>

            <p className='font18 fw-semibold'>Budget</p>

            <div className=" mx-2  mt-3 mb-2 p-3 p-3 bg-white borderSec rounded-3">


              <div className='d-flex justify-content-between'>
                <p className='font18 fw-semibold d-inline-block d-flex-grow-1'>  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" className='' />  <span className='text-primary'>eMilo</span> Estimated Budget </p>
                <small className='text-primary d-flex-grow-1'>Add custom Budget</small>

              </div  >
              <div className="container">
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <input
                      type="text"
                      className="form-control p-4  mt-2"
                      placeholder="2000rs"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <select
                      className="form-select mt-2"
                      aria-label="Default select example"
                      style={{ padding: "12px" }} // Applying inline style
                    >
                      <option value="" disabled selected>
                        Daily Budget
                      </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>

              <p className='font14 text-secondary text-left'> you will  spend  an avrage of 200rs per day, you maximum day spend is 250rs and you maximum  weekly spend  1400rs <span className='text-primary'>learn more</span></p>
              <p className='font18 mt-3 fw-semibold d-inline-block d-flex-grow-1'>  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" className='mt-1' />  Custom Budget </p>
              <div className="container">
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <input
                      type="text"
                      className="form-control p-4 mt-2"
                      placeholder="2000rs"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    // style={{ padding: "40px" }} // Applying inline style
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <select
                      className="form-select mt-2"
                      aria-label="Default select example"
                      style={{ padding: "12px" }} // Applying inline style
                    >
                      <option value="" disabled selected>
                        Daily Budget
                      </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>

              <p className='font14 text-secondary'> you will  spend  an avrage of 200rs per day, you maximum day spend is 250rs and you maximum  weekly spend  1400rs <span className='text-primary'>learn more</span></p>

              <p className='font18 mt-3 fw-semibold'> Estimate Reach </p>

              <div className='d-flex justify-content-evenly align-items-center mt-2 mb-1'>
                <span class="badge text-bg-primary ">Reach</span>
                <span class="badge text-bg-secondary">Leads</span>
                <span class="badge text-bg-secondary">impression</span>
                <span class="badge text-bg-secondary">click</span>
              </div>


              <div className='d-flex justify-content-between mt-3'>
                <p className='font18 fw-semibold d-inline-block d-flex-grow-1'> <span className='text-primary'>eMilo</span> Reach </p>
                <p className='font18 fw-semibold d-flex-grow-1'> Cost Per Reach (CPR)</p>
              </div  >

              <div className="container">
                <div className="row">
                  <div className="col-md-8 mb-3">

                    <input type="range" className="form-control p-4 mt-2"
                      id="customRange1"

                    // style={{ padding: "12px" }}
                    ></input>
                  </div>
                  <div className="col-md-4 mb-3">
                    <select
                      className="form-select mt-2"
                      aria-label="Default select example"
                      style={{ padding: "12px" }} // Applying inline style
                    >
                      <option value="" disabled selected>
                        Daily Budget
                      </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <div className=" mx-2  mt-3 mb-2 p-4 shadow-none p-3 mb-5 bg-body-tertiary rounded-3" style={{ background: "#fff" }}>
              <div className='p-3 bg-white rounded-3 shadow-sm' style={{ width: '100%' }} >
                <div className='d-flex align-items-center'>
                  <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                  <div className='ms-3'>
                    <h6 className='fw-semibold mb-0'>Intresting Facts</h6>
                    <span className='text-primary'>Sponsored</span>
                  </div>
                </div>

                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.</span>

                <img src="https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg" className='rounded-3 my-2' alt="ads" />

                <span className='fw-semibold'>www.example.com</span>
                <span>Lorem ipsum dolor sit amet.</span>

                <button type="button" className='btn btn-primary btn-sm w-100'>Visit Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>



  )
}

export default BusinessBoostPost2
