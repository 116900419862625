import React, { useContext, useEffect } from 'react'
import { getValue } from '../../../UseContext/SettingHelper';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import { BASE_URL_STATIC } from '../../../../../config';

const CellularWifiData = () => {

  const { 

    value, setValue,

    handleOptionChange,

  } = useContext(settingContext);

  const { config } = useContext(ApiContext);

  // To get value
  useEffect(() => {
    getValue('cellularWifiData', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <section className='vh-100 pt-4 pt-md-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='cellularWifiToggle'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Cellular/Wi-Fi Control</li>
          </ol>
        </nav>

        {/* Cellular and Wi-Fi Data */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#cellularWifi">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Cellular/Wi-Fi Control</h5>
          </div>

          <div id='cellularWifi' className="accordion-collapse collapse show ms-3 mt-3" data-bs-parent="#cellularWifiToggle">
            {/* Data Saver */}
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <h5 className='accordion-level-4 shadow-sm'>Data Saver</h5>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='dataSaver' 
                  value={value.dataSaver === 'true' ? 'false' : 'true'} checked={value.dataSaver === 'true'} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}
                />
              </div>
            </div>

            {/* WI-FI */}
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='accordion-level-4 shadow-sm'>Only on WI-FI</h5>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='onlyOnWiFi'
                  value={value.onlyOnWiFi === 'true' ? 'false' : 'true'} checked={value.onlyOnWiFi === 'true'} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />
          </div>
        </div>

        {/* Upload high quality media */}
        <div className='accordion accordion-flush my-3'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#uploadMedia">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Upload high quality media.</h5>
          </div>

          <div id='uploadMedia' className="accordion-collapse collapse ms-4 mt-3" data-bs-parent="#cellularWifiToggle">
            <div className="ms-4 mb-2">
              <input type="radio" className="form-check-input" id="uploadMedia-1" name="uploadMedia" value='auto' 
                checked={value.uploadMedia === 'auto' || !value.uploadMedia} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}  
              />
              <label className="form-check-label ms-2" htmlFor="uploadMedia-1">Auto adjust media quality as per network condition.</label>
            </div>

            <div className="ms-4 mb-2">
              <input type="radio" className="form-check-input" id="uploadMedia-2" name="uploadMedia" value='wifi' 
                checked={value.uploadMedia === 'wifi'} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}  
              />
              <label className="form-check-label ms-2" htmlFor="uploadMedia-2">Only on WIFI</label>
            </div>

            <div className="ms-4 mb-2">
              <input type="radio" className="form-check-input" id="uploadMedia-3" name="uploadMedia" value='network'
                  checked={value.uploadMedia === 'network'} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}  
              />
              <label className="form-check-label ms-2" htmlFor="uploadMedia-3">Upload photos and videos in high quality only when connected to WiFi. This ensures that uploading content does not consume excessive cellular data.</label>
            </div>

            <hr className='hr bg-secondary' />
          </div>
        </div>

        {/* Background Data Usage */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#dataUsage">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Background Data Usage.</h5>
          </div>

          <div id='dataUsage' className="accordion-collapse collapse ms-4 mt-3" data-bs-parent="#cellularWifiToggle">
            <div className="ms-4 mb-2">
              <input type="radio" className="form-check-input" id="dataUsage-1" name="dataUsage" value='wifi'
                checked={value.dataUsage === 'wifi' || !value.dataUsage} onChange={(e) => handleOptionChange(e, 'cellularWifiData')}
              />
              <label className="form-check-label ms-2" htmlFor="dataUsage-1">Only on WIFI</label>
            </div>

            <hr className='hr bg-secondary' />
          </div>
        </div>
      </section> 
    </>
  )
}

export default CellularWifiData
