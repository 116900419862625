// Profile setting page ke sabhi api yha pr hai. 

import axios from "axios";
import { BASEAPIURL } from "../../../config";
import toast from "react-hot-toast";


// ---------- Profile > Setting (value and input) ---------- 


// ----- Value -----

// To create value
const createValue = async (settingNav, settingKey, settingValue, config) => {
  await axios.post(`${BASEAPIURL}/setting/createValue`, { settingNav, settingKey, settingValue }, config)
  .then(res => {
    // console.log('res : ', res.data.message);
    // toast.success(res.data.message);
  })
  .catch(err => {
    // console.error('err : ', err);
    toast.error("Something went wrong, Please try again later.");
    toast.error(err.message);
  });
};

// To get value
const getValue = async (settingNav, config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/setting/getValue`, { settingNav }, config);

    return res;
  } catch (err) {
    return err;
  };
};



// ----- Input -----

// To create input 
const createUser = async (settingNav, settingKey, settingType, selected, config) => {
  try {
    const res = axios.post(`${BASEAPIURL}/setting/createUser`, { settingNav, settingKey, settingType, selected }, config)
    
    return res;
  } catch (err) {
    return err;
  };
};

// To get input 
const getAdded = async (settingNav, config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/setting/getAdded`, { settingNav }, config);

    return res;
  } catch (err) {
    return err;
  };
};

// To delete input 
const deleteUser = async (settingNav, settingKey, settingType, user, config) => {
  try {
    const res = axios.post(`${BASEAPIURL}/setting/deleteUser`, { settingNav, settingKey, settingType, user }, config)

    return res;
  } catch (err) {
    return err;
  };
};

// To get search users list
const getSearched = (settingNav, settingKey, settingType, config) => {
  try {
    const res = axios.post(`${BASEAPIURL}/setting/getSearched`, { settingNav, settingKey, settingType }, config);

    return res;
  } catch (err) {
    console.log('err : ', err);
    return err;
  };
};




export {
  createValue, getValue,
  createUser, getAdded, deleteUser, getSearched,
};