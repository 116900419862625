import React from "react";

const Dashboard = () => {

  const todos = [
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Publish Ads',
      description: '1 ad published',
      button: 'Create Ad'
    },
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Publish post on eMilo business page',
      description: '4 post published',
      button: 'Create Post'
    },
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Publish stories on eMilo business page',
      description: '5 stories published',
      button: 'Create Story'
    },
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Respond to user message & enquiry',
      description: 'Response rate is 89%',
      button: 'Message'
    },
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Publish flix on eMilo business page',
      description: '12 flix published',
      button: 'Create flix'
    },
    {
      image: '/images/business/icons/edit-blue.svg',
      head: 'Visit business page & insights',
      description: 'Visit rate high',
      button: 'Create Ad'
    },
  ];

  const recentAds = [
    {
      image: 'https://www.bigbasket.com/media/uploads/flatpages/pd/260634-01.1.jpg',
      heading: 'Revatilize your hair this summer with our latest shampoo for hair',
      time: 'Thu 2, May',
      badgeText: 'Running',
      badgeColor: 'primary',
    },
    {
      image: 'https://i.pinimg.com/736x/d3/4a/5d/d34a5db9cf00d724716a30487844199c.jpg',
      heading: 'Summer proof your hair with our new shampoo for latest growth',
      time: 'Thu 2, May',
      badgeText: 'Completed',
      badgeColor: 'success',
    },
  ];

  const messages = [
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Siddharth Dongre',
      time: '12:16 AM',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Abhishek Bhoir',
      time: 'Yesterday',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Sourya Ramteke',
      time: 'Today',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Siddharth Dongre',
      time: '12:16 AM',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Abhishek Bhoir',
      time: 'Yesterday',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
    {
      profile: 'https://s3aws.emilo.in/images/profile-img1.jpg',
      name: 'Sourya Ramteke',
      time: 'Today',
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, illum!',
    },
  ];

  const listings = [
    {
      image: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSUBrD8wqEkTLYL8E_Bh9fY-n4uP4MXlnuoG7EsmoGPuen6-2b0',
      heading: 'Hair Artist',
      badges: ['Expert', 'Fresher'],
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, magni.',
      salary: '15,000',
      type: 'job'
    },
    {
      image: 'https://i.pinimg.com/736x/5a/f8/d3/5af8d344abd2136801976b4867190af3.jpg',
      heading: 'Nail Artist',
      badges: ['Pro'],
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, magni.',
      salary: '27,896',
      type: 'job'
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdNxbryej6zqxhGkTzzV5Q_Pj_PGEOGWJv0hu76J_nf8pw6tVE',
      heading: 'Salon',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, magni.',
      type: 'franchise'
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdNxbryej6zqxhGkTzzV5Q_Pj_PGEOGWJv0hu76J_nf8pw6tVE',
      heading: 'Salon',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, magni.',
      type: 'franchise'
    },
  ];

  return (
    <>
      <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5" style={{ backgroundColor: "#F5F5F5" }} >        
        {/* Dashboarb */}
        <div className="my-3 bg-white rounded-3 shadow-sm p-3 d-flex justify-content-between align-items-center">
          <h5 className="fw-semibold mb-0">Dashboard</h5>

          <button type="button" className="btn btn-sm bg-transparent border">
            <img src={`/images/business/icons/publish-report.svg`} className="me-2" style={{ height: '20px' }} alt="publish-report" />
            <span>Publish Report</span>
          </button>
        </div>

        {/* Overview */}
        <div className="p-3 rounded-3 shadow-sm my-3" style={{ backgroundColor: '#fcfcfc' }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="fw-semibold mb-0">Overview</h5>

            <select className="form-select" style={{ width: '143px' }} aria-label="Default select example">
              <option selected>Last 1 Week</option>
              <option value="1">Last 1 Month</option>
              <option value="2">Last 1 Year</option>
            </select>
          </div>

          <div className="dashboard-overview">
            {/* Page Views */}
            <div className="bg-white rounded-3 shadow-sm p-3">
              <img src={`	https://s3aws.emilo.in/images/icon/view-1.svg`} className="me-2" style={{ height: '18px' }} alt="post-reach" />
              <span className="text-secondary">Page Views</span>

              <h5 className="fw-semibold my-2">1,32,850</h5>

              <div><span className="text-success px-0">▲ 48%</span> vs previous 7 day</div>
            </div>

            {/* Post Reach */}
            <div className="bg-white rounded-3 shadow-sm p-3">
              <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '25px' }} alt="post-reach" />
              <span className="text-secondary">Page Views</span>

              <h5 className="fw-semibold my-2">65,000</h5>

              <div><span className="text-success px-0">▲ 48%</span> vs previous 7 day</div>
            </div>

            {/* Post Reach */}
            <div className="bg-white rounded-3 shadow-sm p-3">
              <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '25px' }} alt="post-reach" />
              <span className="text-secondary">Page Views</span>

              <h5 className="fw-semibold my-2">65,000</h5>

              <div><span className="text-success px-0">▲ 48%</span> vs previous 7 day</div>
            </div>

            {/* Likes */}
            <div className="bg-white rounded-3 shadow-sm p-3">
              <img src={`	https://s3aws.emilo.in/images/icon/like-1.svg`} className="me-2" style={{ height: '25px' }} alt="post-reach" />
              <span className="text-secondary">Page Views</span>

              <h5 className="fw-semibold my-2">37,500</h5>

              <div><span className="text-success px-0">▲ 48%</span> vs previous 7 day</div>
            </div>
          </div>
        </div>

        {/* Main */}
        <div className="dashboard-main mt-3">
          {/* Left Block */}
          <div>
            {/* Page Visit */}
            <div className="p-3 rounded-3 mb-3 shadow-sm" style={{ backgroundColor: '#fcfcfc' }}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="fw-semibold mb-0">Page Visit</h5>

                <select className="form-select" style={{ width: '143px' }} aria-label="Default select example">
                  <option selected>Last 1 Week</option>
                  <option value="1">Last 1 Month</option>
                  <option value="2">Last 1 Year</option>
                </select>
              </div>

              <hr className="hr bg-secondary-subtle" />

              <h5 className="fw-semibold mb-0">33.84k</h5>

              <span className="text-primary">Unique Users</span>

              <div><span className="text-success px-0 mb-2">▲ 48%</span> vs previous 7 day</div>

              <div>
                <img src={`/images/business/page-visit-chart.png`} className="w-100" alt="chart" />
              </div>
            </div>

            {/* To-Do */}
            <div className="p-3 rounded-3 mb-3 shadow-sm" style={{ backgroundColor: '#fcfcfc' }}>
              <h5 className="fw-semibold mb-0">To-Do</h5>

              <small className="text-secondary">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam, esse. Voluptates reprehenderit possimus quod iste error repellat enim. Eveniet, cum.</small>

              <hr className="hr bg-secondary-subtle" />

              <div className="bg-white shadow-sm rounded-3 p-3 mb-4">
                <h6 className="fw-semibold mb-0">Progress</h6>

                <div className="progress my-2" role="progressbar"  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ height: '10px' }}>
                  <div className="progress-bar bg-primary" style={{ width: '200px' }}></div>
                </div>

                <div className="d-flex justify-content-between align-items-center gap-3">
                  <span>Complete your To-Do list to get your business to success</span>

                  <span>6 of 6 works completes</span>
                </div>
              </div>

              <div>
                {
                  todos.map((todo, index) => (
                    <div key={index}>
                      <div className="d-flex align-items-center gap-3">
                        <div className="p-2 rounded-circle d-inline-block" style={{ backgroundColor: '#edededc2' }}>
                          <img src={todo.image} style={{ height: '20px' }} alt="blue" />
                        </div>

                        <div className="me-auto">
                          <div className="d-flex flex-wrap align-items-center gap-2">
                            <h6 className="fw-semibold mb-0 d-inline-block">{todo.head}</h6>

                            <img src={`/images/business/icons/exclamation-circle-gray.svg`} style={{ height: '15px' }} alt="exclamation-circle-gray" />

                            <small className="badge bg-success-subtle text-success rounded-pill">Complete</small>
                          </div>

                          <small className="text-secondary">{todo.description}</small>
                        </div>

                        <button type="button" className="btn btn-sm btn-primary">{todo.button}</button>
                      </div>

                      {index !== 5 && <hr className="hr bg-secondary-subtle" />}
                    </div>
                  ))
                }
              </div>
            </div>

            {/* Events */}
            <div className="bg-white p-3 rounded-3 mb-3 shadow-sm">
              <h5 className="fw-semibold mb-3">Events</h5>

              <div className="d-flex gap-3 flex-wrap">
                <small className="badge bg-primary px-3 rounded-pill">Hosting</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Invites</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Intrested</small>
              </div>

              <hr className="hr bg-secondary-subtle" />

              <div>
                {
                  Array(2).fill(0).map((value, index) => (
                    <div className="d-flex flex-wrap gap-3 flex-column flex-sm-row rounded-3 p-2 mb-3" style={{ backgroundColor: '#f7f7f7' }} key={index}>
                      <div style={{ height: '100px', width: '225px' }}>
                        <img src={`https://scontent.frpr1-2.fna.fbcdn.net/v/t39.30808-6/298730628_5853789468015836_5666568048430720818_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=zB9RDsp-VvkQ7kNvgGzvkZQ&_nc_ht=scontent.frpr1-2.fna&_nc_gid=AVmbwWxAL1KKYQT2O-nKJ6N&oh=00_AYAwLAQr9kUnVVan-teMz3Bl7U5Sl96NzxM_PCgTXIC-eQ&oe=66F9CD1F`} className="rounded-3 h-100 w-100 object-fit-cover" alt="event" />
                      </div>

                      <div>
                        <h6 className="fw-semibold mb-0">We Create</h6>

                        <small className="d-block">SAT, Mar 25 - Mar 28</small>

                        <small className="d-block">Loreal Building, Sector - 24, Atal Nagar, Raipur, Chhattisgarh</small>

                        <div className="d-flex gap-3 mt-2">
                          <button type="button" className="btn btn-sm btn-primary rounded-pill">153 <small>Attending</small></button>

                          <button type="button" className="btn btn-sm border border-primary rounded-pill">
                            <img src={`/images/business/icons/share-blue.svg`} className="me-2" style={{ height: '15px' }} alt="share-white" />
                            <span className="text-primary">Share</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

              <div className="text-center">
                <small className="text-primary cursor-pointer">See All</small>
              </div>
            </div>
          </div>

          {/* Right Block */}
          <div>
            {/* Recente Ads */}
            <div className="bg-white p-3 rounded-3 mb-3 shadow-sm">
              <h5 className="fw-semibold mb-3">Recente Ads</h5>

              <div className="d-flex flex-wrap gap-3">
                <small className="badge bg-primary px-3 rounded-pill">All Ads</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Running</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Completed</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Drafts</small>
              </div>

              <hr className="hr bg-secondary-subtle" />

              {/* Ai Ad Suggestions */}
              <div className="rounded-3 mb-3">
                <div className="bg-primary-subtle p-2 ps-3" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                  <small className="text-primary">Ai Ad Suggestions</small>
                </div>

                <div className="d-flex gap-3 align-items-center p-2" style={{ backgroundColor: '#f5f5f5', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                  <div className="bg-primary p-2 rounded-circle">
                    <img src={`/images/business/icons/cloud-white.svg`} style={{ height: '20px' }} alt="cloud" />
                  </div>

                  <div>
                    <span className="d-block fw-semibold">Create new ads and let the best ones show</span>
                    <small className="text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque quidem fugiat atque deserunt quam dicta eius culpa cupiditate ullam.</small>
                  </div>

                  <button type="button" className="btn btn-sm border border-primary text-primary rounded-pill">+28.9%</button>
                </div>
              </div>

              <div>
                {
                  recentAds.map((ads, index) => (
                    <div className="p-3 rounded-3 mb-3" style={{ backgroundColor: '#f5f5f5' }} key={index}>
                      <div className="d-flex gap-3 flex-wrap">
                        <div className="rounded-3" style={{ height: '75px', width: '75px' }}>
                          <img src={ads.image} className="h-100 w-100 rounded-3 object-fit-cover" alt="ads" />
                        </div>

                        <div>
                          <p className="text-wrap fw-semibold">{ads.heading}</p>
                          <small className="text-secondary d-block">{ads.time}</small>
                          <small className={`badge rounded-pill bg-${ads.badgeColor}-subtle text-${ads.badgeColor}`}>{ads.badgeText}</small>
                        </div>
                      </div>

                      <hr className="hr bg-secondary-subtle" />

                      <div className="d-flex flex-wrap gap-3 justify-content-between">
                        {/* Posts Reach */}
                        <div className="text-center">
                          <div>
                            <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />

                            <small className="text-secondary">Post Reach</small>
                          </div>

                          <span className="fw-semibold">5300</span>
                        </div>

                        {/* Engagement */}
                        <div className="text-center">
                          <div>
                            <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />

                            <small className="text-secondary">Engagement</small>
                          </div>

                          <span className="fw-semibold">2890</span>
                        </div>

                        {/* Conversion */}
                        <div className="text-center">
                          <div>
                            <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />

                            <small className="text-secondary">Conversion</small>
                          </div>

                          <span className="fw-semibold">253</span>
                        </div>

                        {/* Amount Spent */}
                        <div className="text-center">
                          <div>
                            <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />

                            <small className="text-secondary">Amount Spent</small>
                          </div>

                          <span className="fw-semibold">7500</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

              {/* See All */}
              <div className="text-center">
                <small className="text-primary">See All</small>
              </div>
            </div>

            {/* Messages */}
            <div className="bg-white p-3 rounded-3 mb-3 shadow-sm">
              <h5 className="fw-semibold mb-3">Messages</h5>

              <div className="d-flex flex-wrap gap-3">
                <small className="badge bg-primary px-3 rounded-pill">All Messages</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Comments</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Messages</small>
              </div>

              <hr className="hr bg-secondary-subtle" />

              <div className="overflow-y-scroll scrollbar-hidden" style={{ maxHeight: '230px' }}>
                {
                  messages.map((message, index) => (
                    <div className="d-flex align-items-center mb-3" key={index} style={{ maxWidth: '100%' }}>
                      <img src={message.profile} className="rounded-circle" style={{ height: '50px', width: '50px' }} alt="profile" />

                      <div className="ms-3 me-auto" style={{ overflow: 'hidden' }}>
                        <div>
                          <h6 className="fw-semibold mb-0 d-inline-block me-3">{message.name}</h6>
                          <small className="text-secondary">{message.time}</small>
                        </div>

                        <small className="text-secondary text-truncate d-block" style={{ maxWidth: '250px' }}>{message.message}</small>
                      </div>

                      <img src="https://s3aws.emilo.in/images/icon/threeDot.svg" style={{ height: '20px' }} alt="three dot" />
                    </div>
                  ))
                }
              </div>

              <div className="text-center">
                <small className="text-primary">See All</small>
              </div>
            </div>

            {/* Listings */}
            <div className="bg-white p-3 rounded-3 mb-3 shadow-sm">
              <h5 className="fw-semibold mb-3">Listings</h5>

              <div className="d-flex flex-wrap gap-3">
                <small className="badge bg-primary px-3 rounded-pill">All Listings</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Jobs</small>
                <small className="badge bg-secondary-subtle text-secondary px-3 rounded-pill">Franchise</small>
              </div>

              <hr className="hr bg-secondary" />

              <div>
                {
                  listings.map((listing, index) => (
                    <>
                      {/* Listings > Jobs */}
                      <>
                        {
                          (listing.type === 'job')
                          &&
                          <div className="d-flex flex-wrap flex-column flex-sm-row gap-3 p-2 rounded-3 position-relative mb-3" style={{ backgroundColor: '#f5f5f5' }} key={index}>
                            <div style={{ height: '100px', width: '100px' }}>
                              <img src={listing.image} className="rounded-3 h-100 w-100 object-fit-cover" alt="job" />
                            </div>

                            <div className="flex-grow-1">
                              <div className="d-flex flex-wrap gap-3 align-items-center">
                                <h6 className="fw-semibold mb-0">{listing.heading}</h6>

                                <div className="d-flex flex-wrap gap-3">
                                  {
                                    listing.badges.map((badge, index) => (
                                      <small className="badge bg-secondary-subtle text-secondary" key={index}>{badge}</small>
                                    ))
                                  }
                                </div>
                              </div>

                              <small className="text-secondary">{listing.description}</small>

                              <div className="d-flex flex-wrap align-items-center gap-3 justify-content-between">
                                <div>
                                  <small className="fw-semibold">Salary</small>
                                  <span className="text-primary d-block fw-semibold">₹ {listing.salary}</span>
                                </div>

                                <div className="d-flex gap-3">
                                  <button type="button" className="btn btn-sm border border-primary">
                                    <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost" />
                                    <span className="text-primary">Boost</span>
                                  </button>

                                  <button type="button" className="btn btn-sm btn-primary">
                                    <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="share-white" />
                                    <span className="text-white">Share</span>
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Edit */}
                            <img src={`/images/business/icons/edit-blue.svg`} className="position-absolute top-0 end-0 mt-2 me-2" style={{ height: '17px' }} alt="edit" />
                          </div>
                        }
                      </>

                      {/* Listings > Franchise */}
                      <>
                        {
                          (listing.type === 'franchise')
                          &&
                          <div className="d-flex flex-wrap flex-column flex-sm-row gap-3 p-2 rounded-3 position-relative mb-3" style={{ backgroundColor: '#f5f5f5' }} key={index}>
                            <div style={{ height: '100px', width: '100px' }}>
                              <img src={listing.image} className="rounded-3 h-100 w-100 object-fit-cover" alt="job" />
                            </div>

                            <div className="flex-grow-1">
                              <h6 className="fw-semibold mb-0">{listing.heading}</h6>

                              <small className="text-secondary">{listing.description}</small>

                              <div className="d-flex gap-3 justify-content-end mt-2">
                                <button type="button" className="btn btn-sm border border-primary">
                                  <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost" />
                                  <span className="text-primary">Boost</span>
                                </button>

                                <button type="button" className="btn btn-sm btn-primary">
                                  <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="share-white" />
                                  <span className="text-white">Share</span>
                                </button>
                              </div>
                            </div>

                            {/* Edit */}
                            <img src={`/images/business/icons/edit-blue.svg`} className="position-absolute top-0 end-0 mt-2 me-2" style={{ height: '17px' }} alt="edit" />
                          </div>
                        }
                      </>
                    </>
                  ))
                }
              </div>

              <div className="text-center">
                <small className="text-primary">See All</small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;