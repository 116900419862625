import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../config';


const ShowMilestonePopup = () => {

  const { 
    showMilestonePopup, setShowMilestonePopup,
    milestone,
  } = useContext(businessContext);

  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedDescription = milestone.description.split(' ').slice(0, 50).join(' ');

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Modal show={showMilestonePopup} onHide={() => setShowMilestonePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h5 className='text-center mb-4 fw-semibold'>Milestone</h5>

            <div className='mb-3' style={{ height: '225px' }}>
              <img src={AwsFileUrl+milestone.url} className='h-100 w-100 rounded-4' alt="milestone" />
            </div>

            <div className='d-flex align-items-start justify-content-between mb-3'>
              <div>
                <h6 className='fw-semibold mb-1'>{milestone.heading}</h6>
                <span className='badge bg-primary'>{milestone.date}</span>
              </div>

              <button type="button" className='btn btn-sm btn-primary rounded-3 d-flex align-items-center'>
                <img src={`/images/business/icons/share-white.svg`} className='me-2' style={{ height: '17px' }} alt="share" />
                <small>Share</small>
              </button>
            </div>

            <small>
              {isExpanded ? milestone.description : `${truncatedDescription}...`}
              
              <small className='text-primary cursor-pointer' style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={toggleDescription}>
                {isExpanded ? 'Show Less' : 'Show More'}
              </small>
            </small>

            <div className='text-center mt-3'>
              <button type="button" className='btn btn-primary rounded-pill w-50' onClick={() => setShowMilestonePopup(false)}>Done</button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowMilestonePopup;