import React, { useEffect, useState } from 'react';
import { convertS3UrlToBlobUrl, AwsFileUrl } from '../../../config';

export function ImageShow({ url, classHere, altHere, styleHere={}, onClickhere=0, onClickPara=0 }) {
  let theBlogURl = '';
  const [imageUrl, setImageUrl] = useState(null);
  // const [imageUrl, setImageUrl] = useState(`${AwsFileUrl}${url}`);

  const handleRightClick = (e) => {
    e.preventDefault();
    // Prevents the default right-click menu
    // alert("Right-click is disabled on this component");
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchImageBlob() {
      const blobUrl = await convertS3UrlToBlobUrl(`${AwsFileUrl}${url}?version=blob`);
      theBlogURl = blobUrl;
      if (isMounted) setImageUrl(blobUrl);
    }
    fetchImageBlob();

    // Clean up the Blob URL when component unmounts
    return () => {
      isMounted = false;
      URL.revokeObjectURL(theBlogURl);
    };
  }, [url]);

  return (
    <>
      {imageUrl && (
        <img
          src={imageUrl}
          alt={altHere}
          className={classHere}
          onClick={() => {
            onClickhere && onClickPara && onClickhere(onClickPara);
          }}
          onContextMenu={handleRightClick}
          style={styleHere}
        />
      )}
    </>
  );
}

export default ImageShow;
