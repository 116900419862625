import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
// import { ApiContext } from '../UseContext/ApiCall';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faClose, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { ApiContext } from '../../UseContext/ApiCall';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../../config';
import { useParams } from 'react-router-dom';
import { AddUsers } from '../../UseContext/ApiHelpers';


const AddGroupMember = ({openAddUser,setIsAdded, closeAddUser,chatUserList }) => {

   const [loader, setLoader] = useState(false)
   const [inputValue, setinputValue] = useState('')
   const [searchList, setSearchList] = useState([])
   const [selectedMembers, setSelectedMembers] = useState([])
   const [addLoader, setAddLoader] = useState(false)

   const { config } = useContext(ApiContext);

   useEffect(() => {
      setinputValue("")
      setSearchList([])
      setAddLoader(false)
      // setSelectedMembers([...chatUserList])
      setSelectedMembers([])
   }, [chatUserList])

   const {chatId} = useParams()

  const handleSearch = async (e) => {
   setLoader(true);
   setinputValue(e.target.value);
   if (e.target.value.length > 0) {
      const res = await axios.post(`${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}` , {} , config);
      // Filter out users from res.data.users that are already in chatUserList
      const filteredUsers = res.data.users.filter(user => !chatUserList.some(chatUser => chatUser.userId === user.userId));
      setSearchList(filteredUsers);
      setLoader(false);
   } else {
      setSearchList([]);
      setinputValue('');
      setLoader(false);
   }
}

   const handleSelectedMembers = (user) => {
      if (selectedMembers.some((selectedUser) => selectedUser === user)) {
         toast.error(`${user.fname} already selected`);
         return;
      }
      setSelectedMembers((prev) => [...prev, user]);
   }

   const handleAdd = async () => {
      try {
        setAddLoader(true);
        const promises = selectedMembers.map(({ userId }) => AddUsers(userId, chatId, 'add', config));
        await Promise.all(promises);
        setAddLoader(false);
        closeAddUser();
        setIsAdded(true)
      } catch (error) {
        console.error("Error adding users:", error);
        setAddLoader(false);
        closeAddUser();
      }
    };
    
   const filterMember = (e) => {
      setSelectedMembers((prev) => prev.filter((user) => user !== e))
   }

   return (
      <div>
         <Modal show={openAddUser} onHide={closeAddUser} centered backdrop="static" keyboard={false} className="create-call modal-head-search" >
            <Modal.Header closeButton style={{ width: "100%" }}>
               <div className='create-group-header'>
                  <h5 >Add Group Members</h5>
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="search-box">
                  <div className="input-box">
                     <form action="#" className='search'>
                        <input type="text" placeholder="Search" onChange={handleSearch} value={inputValue} />
                        <button type="button">
                           <i className="far fa-search" />
                        </button>
                     </form>
                  </div>
{/* 
                  <div className='searchList'>

                     {inputValue.length === 0 && followerList && followerList.map((user, i) => (
                        <div className='flex' key={i} onClick={() => handleSelectedMembers(user)}>
                           <div className='name-img'>
                              <div>
                                 <img className="searchImage" src=
                                    {user.imageUrl ? user.imageUrl : `${BASE_URL_STATIC}/dummy.png`} alt="" />
                              </div>

                              <div className="userSearchList">
                                 <span >{user.fname}</span>
                                 <div>
                                    {user.username && <span>{user.username}</span>}
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div> */}

                  {inputValue &&
                     <div className="searchList">
                        {loader ?
                           <FontAwesomeIcon className='searchLoaderShare' icon={faSpinner} spinPulse style={{ color: "#000000", }} />

                           :

                           searchList.map((user, index) => (
                              <div className='flex' key={index} onClick={() => handleSelectedMembers(user)}>
                                 <div className='name-img'>
                                    <div>
                                       <img className="searchImage" src=
                                          {user.profileImg ? user.profileImg : `${BASE_URL_STATIC}/dummy.png`} alt="" />
                                    </div>

                                    <div className="userSearchList">
                                       <span >{user.fname}</span>
                                       <div>
                                          {user.username && <span>{user.username}</span>}
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           ))}
                     </div>}

                  {!loader && inputValue.length > 1 && searchList.length === 0 &&
                     <small className="searchList">
                        <i style={{ color: "#ff6863" }}>No user found</i>
                     </small>}


                  {selectedMembers && selectedMembers.length > 0 && <div className='selected-mem-list'>
                     {
                        selectedMembers.reverse().map((e, i) => (
                           <div key={i} className='selected-mem'>
                              <span>{e.fname}</span>
                              <span onClick={() => filterMember(e)}>
                                 <FontAwesomeIcon icon={faClose} />
                              </span>
                           </div>
                        ))
                     }
                  </div>}
               </div>

               <button onClick={handleAdd} className='group-create-button'>
               {addLoader ? (
                  <span className="flex justify-content-center">
                     <FontAwesomeIcon
                        icon={faCircleNotch}
                        style={{ color: "snow" }}
                        spin
                     />{" "}
                     Adding...
                  </span>
               ) : (
                  "Add"
               )}
               </button>


            </Modal.Body>
         </Modal>
      </div>
   )
}

export default AddGroupMember