import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../../../config';
import { getValue,getAdded,
} from '../../../UseContext/SettingHelper';
import SettingInputUser from './SettingInputUser';
import { settingContext } from '../../../UseContext/SettingContext';


const Notification = () => {

  const { 

    value, setValue,

    handleOptionChange,

    setAdded, 

  } = useContext(settingContext);


  // Messages to show according to option
  const message = {
    following: 'Birthday of People you follow will be notified',
    closeFriends: 'Birthday of People you added as close friends will be notified',

    on: 'You will see notifications from everyone',
    off: 'You will not get any notifications',
    verified: 'You will see notifications only from people with verified profile',
  };

  const { config, isNotificationStopped, handleChangeStopAllNotification, getIsNotificationStopped } = useContext(ApiContext);
  const { isAccountPrivate } = useContext(settingContext);

  console.log('isAccountPrivate 2 : ', isAccountPrivate);

  useEffect(() => {
    getIsNotificationStopped(config);

    // Value
    getValue('notification', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // Specific People (Birthday)
    getAdded('notification', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <section className='vh-100 px-2 px-sm-3 px-lg-4 px-xl-5 mb-5 mb-lg-0 overflow-scroll scrollbar-hidden' id='notificationToggle'>
        <div className='accordion accordion-flush pt-4'>
          {/* Breadcrumb */}
          <nav className='pt-4 pt-lg-5'>
            <ol className="breadcrumb bg-transparent p-0">
              <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
              <li className="breadcrumb-item active" aria-current="page">Notification</li>
            </ol>
          </nav>

          {/* Silent Mode */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm mb-3 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#silentMode">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Silent Mode</h5>
            </div>

            <div id='silentMode' className="accordion-collapse collapse accordion accordion-flush my-2 ms-3" data-bs-parent="#notificationToggle">
              {/* Take a break */}
              <div>
                <div className='d-flex align-items-center justify-content-between '>
                  <h5 className='fw-semibold accordion-level-4 mb-2'>Take a break</h5>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" checked={isNotificationStopped} onChange={() => handleChangeStopAllNotification(config)} role="switch" aria-checked="false" />
                  </div>
                </div>

                <span className='text-secondary ms-3'>Stop all the notifications</span>
              </div>

              <hr className='w-100 bg-secondary' />

              {/* Do not disturb */}
              <div className=''>
                <h6 className='fw-semibold mb-3 accordion-level-4'>Do not disturb</h6>

                <div className='mb-2 ms-3'>
                  <label htmlFor="startTime" className='me-2'>Start Time : - </label>
                  <input type="time" id='startTime' name='startTime' /> <br />
                </div>

                <div className='ms-3'>
                  <label htmlFor="endTime" className='me-2'>End Time : - </label>
                  <input type="time" id='endTime' name='endTime' />
                </div>

                <div className='d-flex ms-3'>
                  <span className='mt-2 me-2 text-nowrap'>Day Selection : -</span>

                  <div className='d-flex flex-wrap'>
                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-sun" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-sun">S</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-mon" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-mon">M</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-tue" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-tue">T</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-wed" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-wed">W</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-thu" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-thu">T</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-fri" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-fri">F</label>
                    </div>

                    <div className='m-1'>
                      <input type="checkbox" className="btn-check" id="daySelection-sat" autoComplete="off" />
                      <label className="btn m-0 rounded-pill" htmlFor="daySelection-sat">S</label>
                    </div>
                  </div>
                </div>
              </div>

              <hr className='w-100 bg-secondary' />
            </div>
          </div>

          {/* Preferred Notifications */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#preferredNotifications">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Preferred Notifications</h5>
            </div>

            <div id='preferredNotifications' className="accordion-collapse collapse accordion accordion-flush my-2 ms-3" data-bs-parent="#notificationToggle">
              {/* Topics & Contents */}
              <div className='mt-3'>
                <h5 className='accordion-level-4 shadow-sm'>Notify, based on my selected Topics & Contents</h5>

                <div className="d-sm-flex mt-3 ms-1">
                  <select className="form-select mb-2 mb-sm-0 me-sm-3" defaultValue="default">
                    <option value="default" disabled>Multiple Select Topics</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>

                  <select className="form-select" defaultValue="default">
                    <option value="default" disabled>Multiple Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <hr className='hr bg-secondary' />
              </div>

              {/* Specific People */}
              <div className='mt-3'>
                <h5 className='accordion-level-4 shadow-sm'>Notify, based on Specific People</h5>

                <div className="show d-sm-flex mt-3 ms-1">
                  <select className="form-select mb-2 mb-sm-0 me-sm-3" defaultValue="default">
                    <option value="default" disabled>Search Peoples</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>

                  <select className="form-select" defaultValue="default">
                    <option selecvalue="default" disabled>Multiple Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <hr className='hr bg-secondary' />
              </div>

              {/* Birthdays Notifications */}
              <div className='mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#birthdayNotifications">Birthdays Notifications</h5>

                <div id='birthdayNotifications' className="row accordion-collapse collapse show mt-3 ms-3 me-0">
                  <div className="ms-2 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="n-bn-following" name="birthdayNotification" value='following' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.birthdayNotification === 'following' || !value.birthdayNotification} />
                    <label className="form-check-label" htmlFor="n-bn-following">Following</label>
                  </div>

                  <div className="ms-2 mb-1 col-lg-5">
                    <input className="form-check-input" type="radio" id="n-bn-closeFriends" name="birthdayNotification" value='closeFriends' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.birthdayNotification === 'closeFriends'} />
                    <label className="form-check-label" htmlFor="n-bn-closeFriends">Close Friends</label>
                  </div>

                  <small className='pt-1 text-secondary ms-2 mb-3'>({message[value.birthdayNotification ? value.birthdayNotification : "following"]})</small>

                  <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

                  {/* Specific People */}
                  <SettingInputUser
                    settingNav='notification' settingKey='birthdayNotification' settingType='specificPeople' 
                    header='Specific People' message='You can select your favorite peoples, followers or any other person you want to wish. You will notify if they allow'
                  />
                </div>
              </div>

              <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />

              {/* New followers */}
              <>
                {
                  isAccountPrivate === 'public'
                  &&
                  <>
                    <div className='mt-3 d-flex justify-content-between align-items-center'>
                      <h5 className='accordion-level-4 shadow-sm me-5'>Notification regarding new followers</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='newFollowersNotification' onChange={(e) => handleOptionChange(e, 'notification')} 
                          value={value.newFollowersNotification === 'false' ? 'true' : 'false'}
                          checked={!value.newFollowersNotification || value.newFollowersNotification === 'true'} 
                        />
                      </div>
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                  </>
                }
              </>

              {/* Follow Request  */}
              <>
                {
                  isAccountPrivate === 'private'
                  &&
                  <>
                    <div className='mt-3 d-flex justify-content-between align-items-center'>
                      <h5 className='accordion-level-4 shadow-sm me-5'>Notification regarding Follow Request</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='followRequestNotification' onChange={(e) => handleOptionChange(e, 'notification')} 
                          value={value.followRequestNotification === 'false' ? 'true' : 'false'}
                          checked={!value.followRequestNotification || value.followRequestNotification === 'true'} 
                        />
                      </div>
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                  </>
                }
              </>

              {/* Acceptance of your follow request */}
              <>
                {
                  isAccountPrivate === 'private'
                  &&
                  <>
                    <div className='mt-3 d-flex justify-content-between align-items-center'>
                      <h5 className='accordion-level-4 shadow-sm me-5'>Notification regarding acceptance of your follow request</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='acceptFollowRequestNotification' onChange={(e) => handleOptionChange(e, 'notification')} 
                          value={value.acceptFollowRequestNotification === 'false' ? 'true' : 'false'}
                          checked={!value.acceptFollowRequestNotification || value.acceptFollowRequestNotification === 'true'} 
                        />
                      </div>
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                  </>
                }
              </>
            </div>
          </div>

          {/* Content Notifications */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contentNotifications">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Content Notifications</h5>
            </div>

            <div id='contentNotifications' className="accordion-collapse collapse accordion accordion-flush my-2" data-bs-parent="#notificationToggle">
              {/* Push Notifications */}
              <div className='ms-3 mt-3 d-flex justify-content-between align-items-center'>
                <h5 className='accordion-level-4 shadow-sm'>Allow Push Notifications</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='pushNotification' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.pushNotification === 'false' ? 'true' : 'false'}
                    checked={!value.pushNotification || value.pushNotification === 'true'} 
                  />
                </div>
              </div>

              <hr className='hr ms-3 bg-secondary' />

              {/* Email Notifications */}
              <div className='ms-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#emailNotifications">Allow Email Notifications</h5>

                <div id='emailNotifications' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Post and Content */}
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <span className='w-75'>Email regarding activity on your posts & contents</span>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='emailActivity' onChange={(e) => handleOptionChange(e, 'notification')} 
                        value={value.emailActivity === 'false' ? 'true' : 'false'}
                        checked={!value.emailActivity || value.emailActivity === 'true'} 
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />

                  {/* Feature and Services / Help and Support */}
                  <div className='d-flex justify-content-between align-items-center'>
                    <span className='w-75'>Email regarding emilo features and services as well as help and support</span>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='emailFeatures' onChange={(e) => handleOptionChange(e, 'notification')} 
                        value={value.emailFeatures === 'false' ? 'true' : 'false'}
                        checked={!value.emailFeatures || value.emailFeatures === 'true'} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr className='hr ms-3 bg-secondary' />

              {/* ----- Notifications Regarding ----- */}

              {/* Likes */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#notificationRegarding-likes">Notifications Regarding Likes</h5>

                <div id='notificationRegarding-likes' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Post */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Post</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-post-on"  name="likePost" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likePost === 'on' || !value.likePost}/>
                        <label className="form-check-label" htmlFor="like-post-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-post-off"  name="likePost" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likePost === 'off'}/>
                        <label className="form-check-label" htmlFor="like-post-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-post-verified"  name="likePost" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likePost === 'verified'}/>
                        <label className="form-check-label" htmlFor="like-post-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likePost ? value.likePost : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Flix */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Flix</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-flix-on"  name="likeFlix" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeFlix === 'on' || !value.likeFlix} />
                        <label className="form-check-label" htmlFor="like-flix-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-flix-off"  name="likeFlix" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeFlix === 'off'} />
                        <label className="form-check-label" htmlFor="like-flix-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-flix-verified"  name="likeFlix" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeFlix === 'verified'} />
                        <label className="form-check-label" htmlFor="like-flix-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likeFlix ? value.likeFlix : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Bestclip */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Bestclip</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-bestclip-on"  name="likeBestclip" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeBestclip === 'on' || !value.likeBestclip} />
                        <label className="form-check-label" htmlFor="like-bestclip-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-bestclip-off"  name="likeBestclip" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeBestclip === 'off'} />
                        <label className="form-check-label" htmlFor="like-bestclip-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-bestclip-verified"  name="likeBestclip" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeBestclip === 'verified'} />
                        <label className="form-check-label" htmlFor="like-bestclip-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likeBestclip ? value.likeBestclip : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Meme */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Meme</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-meme-on"  name="likeMeme" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeMeme === 'on' || !value.likeMeme} />
                        <label className="form-check-label" htmlFor="like-meme-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-meme-off"  name="likeMeme" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeMeme === 'off'} />
                        <label className="form-check-label" htmlFor="like-meme-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-meme-verified"  name="likeMeme" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeMeme === 'verified'} />
                        <label className="form-check-label" htmlFor="like-meme-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likeMeme ? value.likeMeme : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Stories */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Stories</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-stories-on" name="likeStories" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeStories === 'on' || !value.likeStories} />
                        <label className="form-check-label" htmlFor="like-stories-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-stories-off"  name="likeStories" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeStories === 'off'} />
                        <label className="form-check-label" htmlFor="like-stories-off">off</label>
                      </div>
                      
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-stories-verified"  name="likeStories" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeStories === 'verified'} />
                        <label className="form-check-label" htmlFor="like-stories-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likeStories ? value.likeStories : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Live */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Live</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-live-on"  name="likeLive" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeLive === 'on' || !value.likeLive} />
                        <label className="form-check-label" htmlFor="like-live-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-live-off"  name="likeLive" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeLive === 'off'} />
                        <label className="form-check-label" htmlFor="like-live-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="like-live-verified"  name="likeLive" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likeLive === 'verified'} />
                        <label className="form-check-label" htmlFor="like-live-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likeLive ? value.likeLive : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>
                </div>
              </div>

              {/* Comments */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#notificationRegarding-comments">Notifications Regarding Comments</h5>

                <div id='notificationRegarding-comments' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Post */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Post</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-post-on"  name="commentPost" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentPost === 'on' || !value.commentPost} />
                        <label className="form-check-label" htmlFor="comment-post-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-post-off"  name="commentPost" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentPost === 'off'} />
                        <label className="form-check-label" htmlFor="comment-post-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-post-verified"  name="commentPost" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentPost === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-post-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentPost ? value.commentPost : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Flix */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Flix</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-flix-on"  name="commentFlix" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentFlix === 'on' || !value.commentFlix} />
                        <label className="form-check-label" htmlFor="comment-flix-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-flix-off"  name="commentFlix" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentFlix === 'off'} />
                        <label className="form-check-label" htmlFor="comment-flix-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-flix-verified"  name="commentFlix" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentFlix === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-flix-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentFlix ? value.commentFlix : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Bestclip */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Bestclip</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-bestclip-on"  name="commentBestclip" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentBestclip === 'on' || !value.commentBestclip} />
                        <label className="form-check-label" htmlFor="comment-bestclip-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-bestclip-off"  name="commentBestclip" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentBestclip === 'off'} />
                        <label className="form-check-label" htmlFor="comment-bestclip-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-bestclip-verified"  name="commentBestclip" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentBestclip === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-bestclip-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentBestclip ? value.commentBestclip : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Meme */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Meme</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-meme-on"  name="commentMeme" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentMeme === 'on' || !value.commentMeme} />
                        <label className="form-check-label" htmlFor="comment-meme-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-meme-off"  name="commentMeme" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentMeme === 'off'} />
                        <label className="form-check-label" htmlFor="comment-meme-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-meme-verified"  name="commentMeme" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentMeme === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-meme-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentMeme ? value.commentMeme : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Stories */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Stories</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-stories-on" name="commentStories" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentStories === 'on' || !value.commentStories} />
                        <label className="form-check-label" htmlFor="comment-stories-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-stories-off"  name="commentStories" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentStories === 'off'} />
                        <label className="form-check-label" htmlFor="comment-stories-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-stories-verified"  name="commentStories" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentStories === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-stories-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentStories ? value.commentStories : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Live */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Live</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-live-on"  name="commentLive" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentLive === 'on' || !value.commentLive} />
                        <label className="form-check-label" htmlFor="comment-live-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-live-off"  name="commentLive" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentLive === 'off'} />
                        <label className="form-check-label" htmlFor="comment-live-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="comment-live-verified"  name="commentLive" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.commentLive === 'verified'} />
                        <label className="form-check-label" htmlFor="comment-live-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='d-block text-secondary'>({message[value.commentLive ? value.commentLive : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>
                </div>
              </div>

              {/* Likes and Replies on your Comments */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#notificationRegarding-likesAndReplies">Notifications regarding likes and replies on your comments</h5>

                <div id='notificationRegarding-likesAndReplies' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Post */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Post</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-post-on"  name="likesAndRepliesPost" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesPost === 'on' || !value.likesAndRepliesPost} />
                        <label className="form-check-label" htmlFor="likesAndReplies-post-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-post-off"  name="likesAndRepliesPost" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesPost === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-post-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-post-verified"  name="likesAndRepliesPost" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesPost === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-post-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesPost ? value.likesAndRepliesPost : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Flix */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Flix</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-flix-on"  name="likesAndRepliesFlix" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesFlix === 'on' || !value.likesAndRepliesFlix} />
                        <label className="form-check-label" htmlFor="likesAndReplies-flix-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-flix-off"  name="likesAndRepliesFlix" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesFlix === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-flix-off">off</label>
                      </div>
                      
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-flix-verified"  name="likesAndRepliesFlix" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesFlix === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-flix-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesFlix ? value.likesAndRepliesFlix : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Bestclip */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Bestclip</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-bestclip-on"  name="likesAndRepliesBestclip" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesBestclip === 'on' || !value.likesAndRepliesBestclip} />
                        <label className="form-check-label" htmlFor="likesAndReplies-bestclip-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-bestclip-off"  name="likesAndRepliesBestclip" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesBestclip === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-bestclip-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-bestclip-verified"  name="likesAndRepliesBestclip" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesBestclip === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-bestclip-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesBestclip ? value.likesAndRepliesBestclip : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Meme */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Meme</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-meme-on"  name="likesAndRepliesMeme" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesMeme === 'on' || !value.likesAndRepliesMeme} />
                        <label className="form-check-label" htmlFor="likesAndReplies-meme-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-meme-off"  name="likesAndRepliesMeme" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesMeme === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-meme-off">off</label>
                      </div>
                      
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-meme-verified"  name="likesAndRepliesMeme" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesMeme === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-meme-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesMeme ? value.likesAndRepliesMeme : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Stories */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Stories</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-stories-on" name="likesAndRepliesStories" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesStories === 'on' || !value.likesAndRepliesStories} />
                        <label className="form-check-label" htmlFor="likesAndReplies-stories-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-stories-off"  name="likesAndRepliesStories" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesStories === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-stories-off">off</label>
                      </div>
                      
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-stories-verified"  name="likesAndRepliesStories" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesStories === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-stories-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesStories ? value.likesAndRepliesStories : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Live */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Live</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-live-on"  name="likesAndRepliesLive" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesLive === 'on' || !value.likesAndRepliesLive} />
                        <label className="form-check-label" htmlFor="likesAndReplies-live-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-live-off"  name="likesAndRepliesLive" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesLive === 'off'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-live-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="likesAndReplies-live-verified"  name="likesAndRepliesLive" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.likesAndRepliesLive === 'verified'} />
                        <label className="form-check-label" htmlFor="likesAndReplies-live-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.likesAndRepliesLive ? value.likesAndRepliesLive : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>
                </div>
              </div>

              {/* Tag/Mention by someone */}
              <div className='ms-3 mt-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#notificationRegarding-tagMention">Notifications regarding tag/mention by someone</h5>

                <div id='notificationRegarding-tagMention' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Post */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Post</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-post-on"  name="tagMentionPost" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionPost === 'on' || !value.tagMentionPost} />
                        <label className="form-check-label" htmlFor="tagMention-post-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-post-off"  name="tagMentionPost" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionPost === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-post-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-post-verified"  name="tagMentionPost" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionPost === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-post-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionPost ? value.tagMentionPost : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Flix */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Flix</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-flix-on"  name="tagMentionFlix" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionFlix === 'on' || !value.tagMentionFlix} />
                        <label className="form-check-label" htmlFor="tagMention-flix-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-flix-off"  name="tagMentionFlix" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionFlix === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-flix-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-flix-verified"  name="tagMentionFlix" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionFlix === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-flix-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionFlix ? value.tagMentionFlix : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Bestclip */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Bestclip</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-bestclip-on"  name="tagMentionBestclip" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionBestclip === 'on' || !value.tagMentionBestclip} />
                        <label className="form-check-label" htmlFor="tagMention-bestclip-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-bestclip-off"  name="tagMentionBestclip" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionBestclip === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-bestclip-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-bestclip-verified"  name="tagMentionBestclip" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionBestclip === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-bestclip-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionBestclip ? value.tagMentionBestclip : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Meme */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Meme</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-meme-on"  name="tagMentionMeme" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionMeme === 'on' || !value.tagMentionMeme} />
                        <label className="form-check-label" htmlFor="tagMention-meme-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-meme-off"  name="tagMentionMeme" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionMeme === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-meme-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-meme-verified"  name="tagMentionMeme" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionMeme === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-meme-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionMeme ? value.tagMentionMeme : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Stories */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Stories</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-stories-on" name="tagMentionStories" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionStories === 'on' || !value.tagMentionStories} />
                        <label className="form-check-label" htmlFor="tagMention-stories-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-stories-off"  name="tagMentionStories" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionStories === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-stories-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-stories-verified"  name="tagMentionStories" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionStories === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-stories-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionStories ? value.tagMentionStories : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>

                  {/* Live */}
                  <div className='mb-2'>
                    <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                      <h6 className='d-inline fw-semibold w-25 mb-2 mb-sm-0'>Live</h6>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-live-on"  name="tagMentionLive" value='on' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionLive === 'on' || !value.tagMentionLive} />
                        <label className="form-check-label" htmlFor="tagMention-live-on">on</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-live-off"  name="tagMentionLive" value='off' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionLive === 'off'} />
                        <label className="form-check-label" htmlFor="tagMention-live-off">off</label>
                      </div>

                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input type="radio" className="form-check-input" id="tagMention-live-verified"  name="tagMentionLive" value='verified' onChange={(e) => handleOptionChange(e, 'notification')} checked={value.tagMentionLive === 'verified'} />
                        <label className="form-check-label" htmlFor="tagMention-live-verified">Only from verified profile</label>
                      </div>
                    </div>

                    <small className='text-secondary'>({message[value.tagMentionLive ? value.tagMentionLive : 'on']})</small>

                    <hr className='hr bg-secondary' />
                  </div>
                </div>
              </div>

              {/* Likes, Comments on post/content you are tagged in */}
              <div className='ms-3 mt-3 d-flex justify-content-between align-items-center'>
                <h5 className='accordion-level-4 shadow-sm me-5'>Notifications regarding likes, comments on post/content you are tagged in</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='contentTaggedIn' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.contentTaggedIn === 'false' ? 'true' : 'false'}
                    checked={!value.contentTaggedIn || value.contentTaggedIn === 'true'}
                  />
                </div>
              </div>

              <hr className='hr ms-3 bg-secondary' />

              {/* Use of your content by others */}
              <div className='ms-3'>
                <h5 className='accordion-level-4 shadow-sm' data-bs-toggle="collapse" data-bs-target="#notificationRegarding-useContent">Notifications regarding use of your content by others</h5>

                <div id='notificationRegarding-useContent' className="accordion-collapse collapse show ms-3 mt-3">
                  {/* Audio */}
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <span>Use of your Audio</span>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='useAudio' onChange={(e) => handleOptionChange(e, 'notification')} 
                        value={value.useAudio === 'false' ? 'true' : 'false'}
                        checked={!value.useAudio || value.useAudio === 'true'}
                      />
                    </div>
                  </div>

                  {/* Flix */}
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <span>Use of your Flix</span>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='useFlix' onChange={(e) => handleOptionChange(e, 'notification')} 
                        value={value.useFlix === 'false' ? 'true' : 'false'}
                        checked={!value.useFlix || value.useFlix === 'true'}
                      />
                    </div>
                  </div>

                  {/* Memes */}
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <span>Use of your Memes</span>

                    <div className="form-check form-switch m-0">
                      <input type="checkbox" className="form-check-input" role="switch" aria-checked name='useMemes' onChange={(e) => handleOptionChange(e, 'notification')} 
                        value={value.useMemes === 'false' ? 'true' : 'false'}
                        checked={!value.useMemes || value.useMemes === 'true'}
                      />
                    </div>
                  </div>

                  <hr className='hr bg-secondary' />
                </div>
              </div>
            </div>
          </div>

          {/* Suggestion Notifications */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#suggestionNotifications">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Suggestion Notifications</h5>
            </div>

            <div id='suggestionNotifications' className="accordion-collapse collapse show accordion accordion-flush mt- ms-3" data-bs-parent="#notificationToggle">
              {/* Follow Suggestions */}
              <div className='ms-3 d-flex justify-content-between align-items-center mb-2'>
                <span className='w-75'>Follow Suggestions</span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='followSuggestion' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.followSuggestion === 'false' ? 'true' : 'false'}
                    checked={!value.followSuggestion || value.followSuggestion === 'true'}
                  />
                </div>
              </div>

              {/* Trending Contents */}
              <div className='ms-3 d-flex justify-content-between align-items-center mb-2'>
                <span className='w-75'>Trending Contents</span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='trendingContents' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.trendingContents === 'false' ? 'true' : 'false'}
                    checked={!value.trendingContents || value.trendingContents === 'true'}
                  />
                </div>
              </div>

              {/* New Content */}
              <div className='ms-3 d-flex justify-content-between align-items-center mb-2'>
                <span className='w-75'>Show notifications of new content posted by Close Friends & Favourites</span>

                <div className="form-check form-switch m-0 ms-2">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='newContent' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.newContent === 'false' ? 'true' : 'false'}
                    checked={!value.newContent || value.newContent === 'true'}
                  />
                </div>
              </div>

              {/* Pages */}
              <div className='ms-3 d-flex justify-content-between align-items-center mb-2'>
                <span className='w-75'>Notifications Regarding Pages You Follow or Liked</span>

                <div className="form-check form-switch m-0 ms-2">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='pages' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.pages === 'false' ? 'true' : 'false'}
                    checked={!value.pages || value.pages === 'true'}
                  />
                </div>
              </div>

              {/* New Products */}
              <div className='ms-3 d-flex justify-content-between align-items-center'>
                <span className='w-75'>Notifications regarding new Products or Services</span>

                <div className="form-check form-switch m-0 ms-2">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='newProducts' onChange={(e) => handleOptionChange(e, 'notification')} 
                    value={value.newProducts === 'false' ? 'true' : 'false'}
                    checked={!value.newProducts || value.newProducts === 'true'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Notification;