import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from '../../../config';
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TagPeople = ({ config, selectedUser, setTagmessage, setSelectedUser, type }) => {
    const [loader, setLoader] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [searchList, setSearchList] = useState([]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);

        if (e.target.value.length > 0) {
            setLoader(true);

            axios.post(
                `${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config
            )
                .then((res) => {
                    // Get an array of usernames from the selectedUser array
                    const selectedUsernames = selectedUser.map(user => user.username.trim().toLowerCase());

                    // Filter out users whose usernames are present in selectedUsernames
                    const filterUser = res.data.users.filter(user =>
                        !selectedUsernames.includes(user.username.trim().toLowerCase())
                    );

                    setSearchList(filterUser);
                    setLoader(false);
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error);
                    setLoader(false);
                });
        } else {
            setSearchList([]);
            setLoader(false);
        }
    };


    const clickTagUser = async (user) => {
        setInputValue("");
        const updatedTags =
        {
            user,
            tagUserId: user.userId,
            fname: user.fname,
            username: user.username,
            profileImg: user.profileImg,
        }

        setSelectedUser([updatedTags, ...selectedUser])

        setSearchList([]);
    };

    const handleFilter = (user) => {
        const updateduser = selectedUser.filter((e) => e.username !== user.username)
        setSelectedUser(updateduser)
    }

    useEffect(() => {
        if (selectedUser.length > 0) {
            if (selectedUser.length < 2) {
                setTagmessage(` with ${selectedUser[0].fname}`)
            }
            else if (selectedUser.length < 3) {
                setTagmessage(` with ${selectedUser[0].fname} and  ${selectedUser[1].fname}`)
            }
            else if (selectedUser.length >= 3) {
                setTagmessage(` with ${selectedUser[0].fname} and  ${selectedUser.length - 1} others`)
            }
        }
        else {
            setTagmessage("")
        }

    }, [selectedUser])

    return (
        <div className="inputContainer">
            <div className="searchContainerMain">
                <div className="searchContainer_text">
                    <input
                        // ref={inputref}
                        type="text"
                        autoFocus
                        placeholder={type === 'story' ? 'Mention people' : 'Tag people'}
                        value={inputValue}
                        onChange={handleInputChange}
                    />

                    {selectedUser.length > 0 &&
                        <div className='tagged_user_head'>
                            <p className='fw-bold fs-14 text-start mt-2'>{type === 'story' ? 'Mentioned' : 'Tagged'}</p>
                            <div className='tagged_users'>
                                {selectedUser.map((user, index) => (
                                    <div key={index} >
                                        <span>{user.fname}</span>
                                        <FontAwesomeIcon
                                            onClick={() => handleFilter(user)}
                                            className='ms-2 cp'
                                            icon={faClose}
                                            style={{ color: "#ADD8E6" }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>}

                    {loader ? (
                        <div className='flex justify-content-center mt-5'>
                            <FontAwesomeIcon
                                icon={faSpinner}
                                spinPulse
                                style={{ color: "#402154" }}
                            />
                        </div>
                    ) : (
                        <div className="searchDiv">
                            {inputValue && searchList.length === 0 ? 
                            <p className="text-center fw-bold">No users found</p> :
                                searchList.map((user, index) => (
                                    <div
                                        onClick={() => clickTagUser(user, index)}
                                        className="tagUserLIst"
                                        key={index}
                                    >
                                        <img
                                            src={
                                                user.profileImg
                                                    ? (AwsFileUrl + user.profileImg.imageId)
                                                    : `${BASE_URL_STATIC}/dummy.png`
                                            }
                                            alt=""
                                        />
                                        <span>
                                            <small>{user.fname}</small>
                                            <small>{user.username}</small>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>{" "}
            </div>

        </div>

    )
}

export default TagPeople