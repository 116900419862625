import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProfileImage from '../UseContext/ProfileImage';
import { BASEURL, BASE_URL_STATIC } from '../../../config';
import CopyToClipboard from "react-copy-to-clipboard";
import { getMemebyID, sharememeCount } from '../UseContext/ApiHelpers';
import LikeMemeButton from '../Button/LikeButton/LikeMemeButton';
import Carousel from 'react-bootstrap/Carousel';
import { ApiContext } from '../UseContext/ApiCall';


const MemeSharePage = () => {

  const [memeUserId, setmemeUserId] = useState()
  const [isMemeFavourite, setIsMemeFavourite] = useState(false);
  const [showCopyLinkUserName, setshowCopyLinkUserName] = useState('');
  // const [shareCount, setshareCount] = useState(0)
  //  const [memeDescription, setmemeDescription] = useState()
  //  const [memeviewCount, setmemeviewCount] = useState()
  //  const [details , setDetails] = useState([])

  const [memeRes , setMemeRes] = useState([])

  const { config } = useContext(ApiContext);

  const { memeId } = useParams();

  useEffect(() => {
    getMemebyID(memeId).then(res => {
        if(res){
          setMemeRes(res)
          setmemeUserId(res[0] && res[0].userId)
        }
    })

    setmemeUserId(memeRes && memeRes[0])
  }, [memeId]);

  // To get show copy link user
  const getShowCopyLinkUser = () => {
    axios.post('https://emilonode.sjain.io/user/getoneuser', { userId: memeUserId })
        .then(res => {
          setshowCopyLinkUserName(res.data.user.fname);
        })
        .catch(err => {
          return null;
        });
  };

  useEffect(() => {
    getShowCopyLinkUser(memeUserId);
  }, [memeUserId]);

  // To handle download
  // function handleDownload() {
  //   const videoUrl = `https://emilonode.sjain.io/post/getvideobyid?reelId=${reelId}`;
  //   const fileName = `reel_${memeId}.jpg`;

  //   axios({
  //     url: videoUrl,
  //     method: 'GET',
  //     responseType: 'blob',
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
  // };

  const createFavouriteMeme = (memeId, config) => {
    let action = isMemeFavourite ? 'add' : 'remove';

    axios.post('https://emilonode.sjain.io/post/createfavmeme', { memeId: memeId, action: action }, config)
        .then(res => {
          setIsMemeFavourite(!isMemeFavourite);
        })
        .catch(err => {
          return null;
        });
  };

  return (
    <>
      {
        memeRes 
        && 
        memeRes.map((meme) => (
          <section key={memeId} >
            <section className="meme-flex" style={{ border: '1px solid black', width: '1077px', padding: "20px",  margin: '100px auto 0px'  }}>
              {
                (meme.details && meme.details.length === 1)
                ?
                <div className='memeDetails' >
                  {
                    meme.details && meme.details[0].imageId 
                    && 
                    <img className='memeImg' src={meme.details[0].imageUrl} alt='memeImg' width='60%' />
                  }

                  {
                    meme.details[0].videoId &&
                    <section className='video-wrap'>
                      <video controls autoPlay loop style={{ background: "black" }} >
                        <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${meme.details[0].videoId}`} />
                      </video>
                    </section>
                  }
                </div>
                :
                <Carousel className='memeCarousel' slide={false}>
                  {meme.details && meme.details.map((detail, index) => (
                    <Carousel.Item>
                      <div className='memeDetails' key={index}>
                        {detail.imageId && <img className='memeImg' src={detail.imageUrl} alt={detail.imageId} width='60%' />}
                        {
                          detail.videoId
                          &&
                          <section className='video-wrap'>
                            <video controls autoPlay loop style={{ background: "black" }} >
                              <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${detail.videoId}`} />
                            </video>
                          </section>
                        }
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              }

              <section>
                <div className="d-flex" style={{ width: "455px", justifyContent: "space-between" }} >
                  <div className="d-flex" style={{ marginTop: "10px" }}>
                    <div style={{ margin: "0px 10px", }} >
                      <ProfileImage userId={memeUserId} height='45' width='45' borderRadius='50%' />
                    </div>
                    <div>
                      <Link to={`${BASEURL}/profile/${memeUserId}`} style={{ color: 'black' }}>{showCopyLinkUserName}</Link>
                    </div>
                  </div>

                  <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" style={{ cursor: "pointer", marginRight: "10px" }} />
                </div>

                <hr
                  style={{
                    height: "0.1px",
                    width: "100%",
                    backgroundColor: "gray",
                    margin: "5px 0px",
                  }}
                />

                <div style={{ height: "400px", width: "457px" }}>
                  <h3 style={{ textAlign: "center" }}>Description</h3>
                  <span>{meme.memedescription}</span>
                </div>

                <hr
                  style={{
                    height: "0.1px",
                    width: "100%",
                    backgroundColor: "gray",
                    margin: "5px 0px",
                  }}
                />

                <div
                  className="d-flex"
                  style={{ width: "455px", marginTop: "10px" }}
                >
                  <div className="d-flex">
                    <img
                      src={`${BASE_URL_STATIC}/feed-icon1.png`}
                      alt="views"
                      height="70%"
                      width="100%"
                      style={{ margin: "5px 5px 0px 5px" }}
                    />
                    <p style={{ margin: "2px 25px 0px 0px" }}>{meme.memeView}</p>
                  </div>

                  <LikeMemeButton memeId={meme.memeId} />

                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <img
                      src={`${BASE_URL_STATIC}/feed-icon3.png`}
                      alt="comment"
                      style={{ marginRight: "5px" }}
                    />
                    <p style={{ marginRight: "25px" }}>1k</p>
                  </div>

                  <CopyToClipboard onCopy={() => sharememeCount(memeId)} text={`${BASEURL}/memeSharePage/${memeId}`} >

                    <div className="d-flex">
                      <img
                        src={`${BASE_URL_STATIC}/feed-icon4.png`}
                        alt="share"
                        style={{ marginRight: "5px" }}
                      />
                      <p style={{ marginRight: "127px" }}>

                        {meme.shareCount}
                      </p>
                    </div>
                  </CopyToClipboard>

                  <div style={{ marginRight: "20px" }}>
                    {
                      isMemeFavourite
                        ?
                        <img src={`${BASE_URL_STATIC}/feed-icon5.png`} alt="views" onClick={() => createFavouriteMeme(memeId, config)} style={{ cursor: 'pointer' }} />
                        :
                        <img src={`${BASE_URL_STATIC}/favourite2.png`} alt="views" onClick={() => createFavouriteMeme(memeId, config)} height={25} width={25} style={{ cursor: 'pointer' }} />
                    }
                  </div>

                  <div >
                    <img src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="views" />
                  </div>
                </div>

                <div
                  style={{
                    height: "140px",
                    width: "406px",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                </div>

                <div
                  style={{
                    height: "100px",
                    width: "406px",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  <p>
                    #videos #photos #reels #stories #photography #like4like
                    #travel #style #repost #summer #selfie <b>...More</b>
                  </p>
                </div>
              </section>
            </section>
          </section>
        ))
      }
    </>
  );
};

export default MemeSharePage;
