import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASEAPIURL, BASEURL } from '../../../../config';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { ApiContext } from '../../UseContext/ApiCall';
import { AddUsers, GetchatUserList, makeGroupAdmin } from '../../UseContext/ApiHelpers';
import axios from 'axios';

const GetGroupMembers = ({ chatUserList, config, setUserAdmin, close }) => {

   const [activePopover, setActivePopover] = useState(null);
   const { loggedInUserId } = useContext(ApiContext);
   const [isAdmin, setIsAdmin] = useState(null);
   const [isUserAdmin, setIsUserAdmin] = useState(false);
   const [groupData, setGroupData] = useState([...chatUserList])

   const handlePopoverClick = (user) => {
      console.log(user);
      setIsAdmin(user.check);
      if (activePopover === user.userId) {
         setActivePopover(null); // Close the active popover if clicked again
      } else {
         setActivePopover(user.userId); // Open the clicked popover
      }
   };

   const nav = useNavigate()

   const NavtoUser = async (userId) => {
      const isGroupChat = "false"
      await axios.post(`${BASEAPIURL}/user/createemchatconnection`, { userId, isGroupChat }, config)
         .then(res => {
            close();

            nav(`/chats/${res.data.chatId}`)
         })
         .catch(err => {
            console.log(err);
         });
   }

   const { chatId } = useParams();

   const CreateGroupAdmin = (userId) => {
      makeGroupAdmin(userId, chatId, isAdmin, config).then(() => {
         GetchatUserList(chatId, config).then((res) => { setGroupData(res); setActivePopover(null) })
      });
   };

   useEffect(() => {
      // Check if the logged-in user is an admin
      const user = groupData.find(e => e.userId === loggedInUserId);
      if (user && user.check) {
         setIsUserAdmin(true);
         setUserAdmin(true)
      } else {
         setIsUserAdmin(false);
         setUserAdmin(false)
      }
   }, [groupData, loggedInUserId]);

   console.log("gropda", groupData);


   return (
      <div className='canvas-groupMembers'>
         {groupData && groupData.map((user, index) => (
            <div className='canvas-groupMember' key={index}>
               {console.log('user : ', user)}
               <div className='canvas-groupMember-profile'>
                  <img src={user.profileImg} alt="profile" />
               </div>
               <Link to={`${BASEURL}/profile/${user.userId}`} className='canvas-groupMember-name'>{user.fname}
                  {user.check && <span className='canvas-groupMember-admin'>Admin</span>}
               </Link>
               <OverlayTrigger
                  trigger="click"
                  key={index}
                  placement='right'
                  show={activePopover === user.userId}
                  overlay={
                     <Popover id={`popover-positioned-right`}>
                        <Popover.Body>
                           <section className='canvas-groupMember-threeDot-popup'>

                              {/* message to self */}
                              <span onClick={() => NavtoUser(user.userId)}> Message {user.fname}</span>

                              {/* Remove from admin*/}
                              {isUserAdmin && (user.userId !== loggedInUserId) && user.check && (
                                 <span onClick={() => CreateGroupAdmin(user.userId)}>Remove {user.fname} from Admin</span>
                              )}

                              {/* Add as Admin */}
                              {isUserAdmin && (user.userId !== loggedInUserId) && !user.check && (
                                 <span onClick={() => CreateGroupAdmin(user.userId)}>Add {user.fname} as Admin</span>
                                 )}

                              {/* Remove user from group */}
                              {isUserAdmin && (user.userId !== loggedInUserId) &&(
                                 // <span onClick={() => CreateGroupAdmin(user.userId)}>Remove {user.fname} from group</span>
                                 <span onClick={() => AddUsers(user.userId, chatId, 'remove', config)}>Remove {user.fname} from group</span>
                              )}

                              {/* Delete and Leave */}
                              {user.userId === loggedInUserId &&
                                 <span className='group-del-leave'>Delete and Leave </span>
                              }

                           </section>
                        </Popover.Body>
                     </Popover>
                  }
               >
                  <span
                     className='canvas-groupMember-threeDot'
                     onClick={() => handlePopoverClick(user)}
                  >

                     <i className="far fa-ellipsis-v" />
                  </span>

               </OverlayTrigger>
            </div>
         ))}
      </div>
   );
};

export default GetGroupMembers;
