import { faFilm, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { favFood, getFavFood, } from '../../../UseContext/ApiHelpers';
import { ApiContext } from '../../../UseContext/ApiCall';
import { profileContext } from '../../../UseContext/ProfileContext';
import toast from 'react-hot-toast';
const FoodPopup = ({ open, close }) => {

   const [foodName, setfoodName] = useState("")
   const [foodDesc, setFoodDesc] = useState("")
   const [loader, setLoader] = useState(false)

   const { config } = useContext(ApiContext)
   const { setfoodDetails } = useContext(profileContext)

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoader(true)
      if (foodName === "" || foodDesc === "") {
         toast.error("please fill all fields")
         setLoader(false)
      }
      else {
         setLoader(true)
         favFood(foodName, foodDesc, config).then((res) => {
            getFavFood(config).then((res) => setfoodDetails(res))
            close()
            setLoader(false)
         }).catch((error) => {
            setLoader(false)
            return null;
         })

      }
   }

   return (
      <div>
         <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>

               <form onSubmit={handleSubmit}>
                  <h5>Favourite Foods</h5>

                  <div>
                     <p className='fav_movie_1'>
                        <span><FontAwesomeIcon icon={faFilm} style={{ color: "#bcbec2", }} /></span>
                        <span>Food Name</span>
                     </p>

                     <div className='fav_movie_2'>
                        <input type="text" onChange={(e) => setfoodName(e.target.value)} />
                     </div>

                     <p className='fav_movie_1'>
                        <span><FontAwesomeIcon icon={faNewspaper} style={{ color: "#bcbec2", }} /></span>
                        <span>About Food</span>
                     </p>

                     <div className='fav_movie_2'>
                        <textarea name="" id="" cols="30" rows="4"
                           onChange={(e) => setFoodDesc(e.target.value)} maxLength={100}
                        ></textarea>
                     </div>

                     <div className='text-center'>
                        <button type='submit' className='fav_movie_btn'>{loader ? "saving..." : "save"}</button>
                     </div>



                  </div>

               </form>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default FoodPopup;