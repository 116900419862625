import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';

const LikedBestClips = () => {

  const [likedBestClips, setLikedBestClips] = useState([]);

  const { config } = useContext(ApiContext);

  // To get liked best clips
  const GetLikedBestClips = async (config) => {
    await axios.post('https://emilonode.sjain.io/post/getbestcliplikebyuser', {}, config)
    .then(res => {
      // console.log(res.data.listBestclip);
      setLikedBestClips(res.data.listBestclip.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    GetLikedBestClips(config);
  }, []);

  return (
    <>
      <section className='liked-bestClips'>
        <h1 className='liked-bestClips-head'>BestClips</h1>

        <div className='liked-bestClips-imgs'>
          {
            likedBestClips.map((likedBestClip, index) => (
              // console.log(likedBestClip.tumbnailUrl)
              <img className='liked-bestClips-img' src={likedBestClip.tumbnailUrl} alt={likedBestClip.tumbnailId} height={215} width={200} />
            ))
          }
        </div>
      </section>
    </>
  )
}

export default LikedBestClips
