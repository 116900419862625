import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';
import { BASE_URL_STATIC } from '../../../../config';

const FavouriteBestClipButton = ({ bestClipId }) => {

  const [isBestClipFavourite, setIsBestClipFavourite] = useState(false);

  const { config } = useContext(ApiContext);

  // To check post is added to favourite or not
  const checkFavouriteBestClip = (bestClipId, config) => {
    axios.post("https://emilonode.sjain.io/post/checkfevbestclip", { bestclipId: bestClipId }, config)
    .then((res) => {
      // console.log(res.data.added);
      setIsBestClipFavourite(res.data.added);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    checkFavouriteBestClip(bestClipId, config);
  }, [bestClipId]);

  // To add post to favourite
  const createFavouriteBestClip = (bestClipId, config) => {
    let action = isBestClipFavourite ? "add" : "remove";

    axios.post("https://emilonode.sjain.io/post/createfavbestclip", { bestclipId: bestClipId, action: action }, config)
    .then((res) => {
      console.log(res.data.message);
      setIsBestClipFavourite(!isBestClipFavourite);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      {
        isBestClipFavourite 
        ? 
        <img className='favouriteBestClipBtn' src={`${BASE_URL_STATIC}/icon/favourite-1.svg`} alt="views" onClick={() => createFavouriteBestClip(bestClipId, config)} style={{ cursor: "pointer" }} />
        :
        <img className='favouriteBestClipBtn' src={`${BASE_URL_STATIC}/icon/favourite-2.svg`} alt="views" onClick={() => createFavouriteBestClip(bestClipId, config)} style={{ cursor: "pointer" }} />
      }
    </>
  )
}

export default FavouriteBestClipButton
