import React from 'react';

const LoginAlert = ({ value, handleOptionChange }) => {
    return (
        <div className="card boxShadow rounded-0">
            <div className="card-body">
                Let us know how you'd like to be notified if someone logs into your account from an unfamiliar location. We'll provide details on the device used and its location.
                <div className="p-3 two_facetor_type">
                    <div className="arrow pull-right mt-3">
                    <div className="form-check form-switch m-0">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        role="switch"
                        aria-checked
                        name='loginAlertApp'
                        value={value.loginAlertApp === 'true' ? 'false' : 'true'}
                        checked={value.loginAlertApp === 'true'}
                        onChange={(e) => handleOptionChange(e, 'security')} 
                        />
                    </div>
                    </div>
                    <div className='d-flex' style={{ alignItems: 'center' }}>
                        <i className="fa fa-mobile-alt me-3"></i>
                        <div>
                            <b>In App Notification</b>
                            <br />
                            <small>You&apos;ll get notification on your Device.</small>
                        </div>
                    </div>
                </div>
                <div className="p-3 two_facetor_type">
                    <div className="arrow pull-right mt-3">
                    <div className="form-check form-switch m-0">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        role="switch"
                        aria-checked
                        name='loginAlertEmail'
                        value={value.loginAlertEmail === 'true' ? 'false' : 'true'}
                        checked={value.loginAlertEmail === 'true'}
                        onChange={(e) => handleOptionChange(e, 'security')}
                        />
                    </div>
                    </div>
                    <div className='d-flex' style={{ alignItems: 'center' }}>
                        <i className="fa fa-envelope me-3"></i>
                        <div>
                            <b>Email Notification</b>
                            <br />
                            <small>You&apos;ll get notification on your email.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// TwoFactorPage.propTypes = { UserData: PropTypes.any }

export default LoginAlert;
