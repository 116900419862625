import React, { useState } from 'react';

const ProposeOptionMessage = () => {

  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  const handleClick = () => {
    console.log('Message : ' + message);
    console.log('Name : ' + name);

    setMessage('');
    setName('');
  };

  return (
    <>
      <section className='proposeOptionMessage'>
        <form>
          <textarea name="message" id="message" cols="40" rows="10" maxLength={350} value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Write your Message here...'></textarea>

          <label htmlFor="name">
            <input type="text" id='name' name='name' maxLength={20} value={name} onChange={(e) => setName(e.target.value)} placeholder='Siddharth Dongre' />
          </label>
        </form>

        <span className='proposeOptionMessage-button' onClick={handleClick}>Next</span>
      </section>
    </>
  );
};

export default ProposeOptionMessage;
