import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import Slider from "react-slick";
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';
import { AwsFileUrl, BASEAPIURL } from '../../../../config';
import { Link } from 'react-router-dom';

const TrendingFeeds = () => {

  const [trendingFeedsImages, setTrendingFeedsImages] = useState([]);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postsPopupData] = useState();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false); // New flag for detecting scroll
  const { config, setTrendingfeed } = useContext(ApiContext);
  const [loader, setLoader] = useState(false);

  // To get trending feeds
  const getTrendingFeeds = async () => {
    setLoader(true);
    await axios.post(`${BASEAPIURL}/post/trendingfeeds?skip=0&limit=10`, {}, config)
      .then(res => {
        setTrendingFeedsImages(res.data.posts);
        setLoader(false);
      })
      .catch(err => {
        console.log("Error in trending feeds", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getTrendingFeeds();
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
    setIsScrolling(false); // When mouse is down, assume scrolling will start
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    if (!isScrolling) {
      setIsScrolling(false); // Ensure scroll is not considered a click
    }
  };

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      setIsScrolling(true); // If mouse is moved while mouse down, mark it as a scroll
    }
  };

  const handleClick = (imgId, event) => {
    if (isScrolling) {
      // Prevent click if scrolling
      event.preventDefault();
      return;
    }
    // Proceed with the normal click behavior
    setTrendingfeed(imgId);
  };

  return (
    <>
      {/* Post image popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postsPopupData} />

      <section className='home-rb-trendingFeeds'>
        <div className="headBlock">
          <h6 className="mb-3 text-center bg-white p-2 fw-bold">{!loader ? "Trending Feeds" : <Skeleton width={100} height={15} />}</h6>
        </div>

        {!loader && trendingFeedsImages.length > 0 && (
          <Slider {...settings}>
            {trendingFeedsImages.map((images) =>
              images.mediaUrls.map((img) => (
                <Link
                  to='/trendings'
                  onClick={(e) => handleClick(img._id, e)}
                  key={img.url}
                >
                  <img
                    className='trendingFeedsImage px-1 mb-2'
                    src={`${AwsFileUrl}${img.url}`}
                    alt={img.url}
                    loading="eager"
                    height='100px'
                    width='100%'
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove} // Detect mouse movement while scrolling
                  />
                </Link>
              ))
            )}
          </Slider>
        )}

        {loader && (
          <div className="trendingBox">
            <div className="imgBox">
              <Skeleton width={150} height={150} />
            </div>
            <div className="imgBox">
              <Skeleton width={150} height={150} />
            </div>
            <div className="imgBox">
              <Skeleton width={150} height={150} />
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default TrendingFeeds;
