import React, { useContext,  useState } from 'react'
import GoogleMapApi from '../Button/GoogleMapApi'
import { Modal } from 'react-bootstrap'
import CheckinPreviewPopup from './CheckinPreviewPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { ApiContext } from '../UseContext/ApiCall'

const CheckInpopup = ({ setSelectedEmoji, openEm, open, setShowPv2, selectedEmoji, setOpenEmoji, setCheckIn, close, location, setLocation, setShowCreatePostPopup }) => {
  const [input, setInput] = useState("")
  const [err, setErr] = useState(false)
  const [openPreviewCI, setOpenPreviewCI] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { checkinType,selectedAddLocation,setSelectedAddLocation } = useContext(ApiContext)

  const handleClose = () => {
    if (checkinType === "check-in") {
      setShowCreatePostPopup(true)
    }
    else if (checkinType === "post") {
      setShowPv2(true)
    }
    else if (checkinType === "text") {
      setShowCreatePostPopup(true)
    }
    close();
  }; 
  

  return (
    <>
      <CheckinPreviewPopup selectedAddLocation={selectedAddLocation} setSelectedAddLocation={setSelectedAddLocation} setSelectedEmoji={setSelectedEmoji} openEmoji={openEm}  selectedLocation={selectedLocation} setCheckIn={setCheckIn} selectedEmoji={selectedEmoji} setOpenEmoji={setOpenEmoji} open={openPreviewCI} setOpenPreviewCI={setOpenPreviewCI} setLocation={setLocation} location={input} close={() => { setOpenPreviewCI(false) }} />

      <section>
        <Modal show={open} onHide={() => {close(); setSelectedAddLocation('');}} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className='position-relative'>

              <div className="row">
                <p className="col-1" style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                </p>
                <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                  Check In
                </p>
              </div>

              <GoogleMapApi selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} location={location} setErr={setErr} input={input} setInput={setInput} />

              {checkinType === "check-in" && <div className="d-flex justify-content-center">
                <button disabled={selectedLocation === null} onClick={() => { setOpenPreviewCI(true); close(); }} type="submit" className="btn btn-dark mt-4 col-6 col-lg-4">Next</button>
              </div>}

              {checkinType === "post" && <div className="d-flex justify-content-center">
                <button onClick={handleClose} type="submit" className="btn btn-dark mt-4 col-6 col-lg-4">Done</button>
              </div>}

              {checkinType === "text" && <div className="d-flex justify-content-center">
                <button onClick={handleClose} type="submit" className="btn btn-dark mt-4 col-6 col-lg-4">Done</button>
              </div>}

              <p className='position-absolute'>
                {err && <span className='text-danger'>Please enter a valid location</span>}
              </p>

            </div>

          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default CheckInpopup
