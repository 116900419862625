import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import { profileContext } from '../../UseContext/ProfileContext';

const ReportMeme = () => {

  const { reportMeme, setReportMeme, handleReportPost, showReportMemePopup, setShowReportMemePopup, } = useContext(ApiContext);

  // console.log('a : ', profileMemePopupData);

  const {profileMemeData} = useContext(profileContext)

  return (
    <>
      <section className='reportPostPopups'>
        <Modal show={showReportMemePopup} onHide={() => setShowReportMemePopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report Meme</h4>

              <textarea className='reportPostPopup-textarea' value={reportMeme} onChange={(e) => setReportMeme(e.target.value)} name="report" placeholder='Report here...'></textarea>

              <span className='reportPostPopup-report' onClick={() => handleReportPost( profileMemeData.memeId,'Meme', profileMemeData.userId, reportMeme)}>Report</span>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportMeme
