import React, { useEffect, useState } from 'react';
import { BASEAPIURL, formatedDateTime, headerUserAPI, showUserIP, showUserLatLong } from '../../../../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
const SavedLogin = () => {
    const [loginLogs, setLoginLogs] = useState([]);
    const [single, setSingle] = useState(false);
    async function getLoginLogs() {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const temp = await axios.post(`${BASEAPIURL}/user/getLoginLogs`, { get: new Date() }, { headers: headers });
            if (temp && temp.data && temp.data.data && temp.data.data.length > 0) {
                setLoginLogs(temp.data.data);
            } else {
                setLoginLogs([]);
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    async function endUserSession(sessionID) {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            await axios.post(`${BASEAPIURL}/user/endUserSession`, { get: new Date(), sessionID }, { headers: headers });
            getLoginLogs();
            setSingle(false);
        } catch (error) {
            console.error(error.message);
            getLoginLogs();
            setSingle(false);
        }
    }
    
    useEffect(() => {
        getLoginLogs();
        return () => {
            // document.head.removeChild(link);
        };
      }, []);

    return (
        <>
            {single && single._id ? (
                <div className="card boxShadow rounded-0">
                    <div className="card-body">
                        <table className="table table-hover table-bordered">
                            <tr>
                                <th>Device</th>
                                <td>{single.device}</td>
                            </tr>
                            <tr>
                                <th>OS</th>
                                <td>{single.type}</td>
                            </tr>
                            <tr>
                                <th>City</th>
                                <td>{single.LocCity}</td>
                            </tr>
                            <tr className='d-none'>
                                <th>Lat Long</th>
                                <td><div dangerouslySetInnerHTML={{ __html: showUserLatLong(single.LocLat, single.LocLong) }} /></td>
                            </tr>
                            <tr>
                                <th>IP</th>
                                <td dangerouslySetInnerHTML={{ __html: showUserIP(single.userip) }}></td>
                            </tr>
                            <tr>
                                <th>Started on</th>
                                <td>{formatedDateTime(single.createdAt)}</td>
                            </tr>
                            <tr>
                                <th>Last Update</th>
                                <td>{formatedDateTime(single.updatedAt)}</td>
                            </tr>
                        </table>
                        <div className='d-flex mt-1 justify-content-between'>
                            <a
                                href="/"
                                className='btn btn-sm btn-outline-danger'
                                onClick={(e) => {
                                    e.preventDefault();
                                    endUserSession(single._id);
                                }}
                            >
                                End Session
                            </a>
                            <a
                                href="/"
                                className='btn btn-sm btn-outline-secondary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSingle(false);
                                }}
                            >
                                Close Detail
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="card boxShadow rounded-0">
                    <div className="card-body" style={{ maxHeight: '350px', overflow: 'auto' }}>
                        {loginLogs.map((s) => (
                            <div
                                className="p-3 two_facetor_type"
                                onClick={() => {
                                    setSingle(s);
                                }}
                            >
                                <div className="arrow pull-right mt-3">
                                    <i className="fa fa-arrow-right me-3"></i>
                                </div>
                                <div className='d-flex' style={{ alignItems: 'center' }}>
                                    <i className="fa fa-key me-3"></i>
                                    <div style={{ minWidth: '50%' }}>
                                        <b>{s.device}</b>
                                        <br />
                                        <small className='d-flex mt-1 justify-content-between'>
                                            <span>City: {s.LocCity} &nbsp;</span>
                                            <span>IP: {s.userip} &nbsp;</span>
                                            <span>IP: {formatedDateTime(s.updatedAt)}</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='m-3 text-center text-info'>
                        <Link target="_BLANK" to="/user/logged/history">View all Login History </Link>
                    </div>
                </div>
            )}
        </>
    );
};

export default SavedLogin;
