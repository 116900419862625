import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ScApiContext } from "../useContext/softCornerContext";
import { Hearts } from "react-loader-spinner";
import { getPosts } from "../../Emilo/UseContext/ScApiHelpers";
import { BASE_URL_STATIC } from "../../../config";
import ScVideoPopup from "./SCVideoPopup";

const GallaryAlbumVideos = () => {
  const { scConfig } = useContext(ScApiContext);
  const [spinner, setSpinner] = useState(false);
  const [userVideos, setUserVideos] = useState([]);
  const [data , setData] = useState(null)
  const [openPopup , setOpenPopup] = useState(false)

  useEffect(() => {
    setSpinner(true);

    getPosts(scConfig).then((posts) => {
      const videoId = posts.flatMap((post) =>
        post.details && post.details.length > 0
          ? post.details.map((detail) => detail.videoId).filter(Boolean)
          : []
      );

      setUserVideos(videoId);
      setSpinner(false);
    });
  }, [scConfig]);

  return (
    <>
        {data &&  <ScVideoPopup open={openPopup} data={data} close={() => setOpenPopup(false)} />}
      <section className="sc-gallary" style={{ flexDirection: "column" }}>
        <section>
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`}
                alt="sc-gallary-photos"
                height={30}
                width={30}
              />
              <span>Photos</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Album`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`}
                alt="sc-gallary-album"
                height={30}
                width={30}
              />
              <span>Album</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Explore`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`}
                alt="sc-gallary-explore"
                height={30}
                width={30}
              />
              <span>Explore</span>
            </Link>
          </div>
        </section>

        <h2 style={{ margin: "20px 50px" }}>Videos</h2>

        <section>
          {spinner ? (
            <Hearts
              height="150"
              width="150"
              color="#ED1E24"
              ariaLabel="hearts-loading"
              wrapperStyle={{}}
              wrapperClass="gallary-loader"
              visible={true}
            />
          ) : (
            userVideos &&
            userVideos.length > 0 && (
              <div className="sc-gallary-older">
                <div className="older-posts">
                  {userVideos.reverse().map((post, index) => (
                    <span key={index} onClick={() =>{setOpenPopup(true); setData(post)}} className="older-posts-span">
                        <video autoPlay>
                          <source
                            src={`https://emilonode.sjain.io/post/getvideobyid?scVideoId=${post}`}
                          />
                        </video>
                    </span>
                  ))}
                </div>
              </div>
            )
          )}
        </section>
      </section>
    </>
  );
};

export default GallaryAlbumVideos;
