import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const OnboardingGender = () => {

  const [gender, setGender] = useState('');

  return (
    <>
      <section className='onboardingGender'>
        <div className='onboardingGender-area'>
          {/* Emilo Logo */}
          <div className='onboardingGender-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingGender-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingGender-area-data'>
            <h2 className='onboardingGender-area-data-head'>Choose gender</h2>

            <p className='onboardingGender-area-data-message'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>

            <form className='w-100'>
              <div className='onboardingGender-area-data-input'>
                <input type="radio" id="men" name="gender" value='men' onChange={() => setGender('men')} />
                <label htmlFor="men">Men</label> <br />
              </div>

              <div className='onboardingGender-area-data-input'>
                <input type="radio" id="woman" name="gender" value='woman' onChange={() => setGender('woman')} />
                <label htmlFor="woman">Woman</label>
              </div>
            </form>
          </div>

          {/* Button */}
          <div className='onboardingGender-area-button'>
            <Link to={`/SoftCorner/OnboardingDOB`}>
              <button type="button" className='onboardingGender-area-btn' onClick={() => localStorage.setItem('gender', gender)}>Next</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingGender;
