import { faFilm, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { getFavMovie, updateUserMovie } from '../../../UseContext/ApiHelpers';
import { ApiContext } from '../../../UseContext/ApiCall';
import { profileContext } from '../../../UseContext/ProfileContext';
import { toast } from 'react-hot-toast';
const MovieEditPopup = ({ open, close, movieObj }) => {

  const [movieName, setMovieName] = useState("")
  const [movieDesc, setMovieDesc] = useState("")
  const [loader, setLoader] = useState(false)

  const { config } = useContext(ApiContext)
  const { setMovieDetails,loggedInUserId } = useContext(profileContext)

  useEffect(() => {
    setMovieName(movieObj.movieName)
    setMovieDesc(movieObj.movieDescription)
  }, [movieObj])


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)
    if (movieName === "" || movieDesc === "") {
      toast.error("please fill all fields")
      setLoader(false)
    }
    else {
      setLoader(true)
      updateUserMovie(movieObj._id, movieName, movieDesc, config).then((res) => {
        getFavMovie(loggedInUserId).then((res) => setMovieDetails(res))
        close()
        setLoader(false)
      }).catch((error) => {
        setLoader(false)
        return null;
      })

    }
  }

  return (
    <div>
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>

          <form onSubmit={handleSubmit}>
            <h5>Favourite Movies</h5>

            <div>
              <p className='fav_movie_1'>
                <span><FontAwesomeIcon icon={faFilm} style={{ color: "#bcbec2", }} /></span>
                <span>Movie Name</span>
              </p>

              <div className='fav_movie_2'>
                <input defaultValue={movieObj.movieName} type="text" onChange={(e) => setMovieName(e.target.value)} />
              </div>

              <p className='fav_movie_1'>
                <span><FontAwesomeIcon icon={faNewspaper} style={{ color: "#bcbec2", }} /></span>
                <span>About Movie</span>
              </p>

              <div className='fav_movie_2'>
                <textarea defaultValue={movieObj.movieDescription} name="" id="" cols="30" rows="4" maxLength={100}
                  onChange={(e) => setMovieDesc(e.target.value)}
                ></textarea>
              </div>

              <div className='text-center'>
                <button type='submit' className='fav_movie_btn'>{loader ? "updating..." : "update"}</button>
              </div>
              
            </div>

          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MovieEditPopup