import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../../UseContext/BusinessContext';


const BusinessAddListingFrenchisePopup = () => {

  const { 
    showAddFrenchisePopup, setShowAddFrenchisePopup,
    addNewFrenchise, setAddNewFrenchise, 
    createFrenchise,
  } = useContext(businessContext);


  const fileInputRef = useRef(null); 

  // handle click
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  };

  // handle change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setAddNewFrenchise(prevState => ({ ...prevState, image: e.target.files[0] }));
    };
  };

  return (
    <>
      <Modal show={showAddFrenchisePopup} onHide={() => setShowAddFrenchisePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Frenchise</h4>

          <>
            {
              addNewFrenchise.image
              ?
              <div className='border mb-4 rounded-4' style={{ height: '250px' }}>
                <img src={URL.createObjectURL(addNewFrenchise.image)} className='h-100 w-100 object-fit-fill rounded-4' alt="product" />
              </div>
              :
              <div className='d-flex flex-column align-items-center w-75 mx-auto rounded-3 py-4 mb-5 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                <img src={`/images/business/icons/upload-photo-blue.svg`} style={{ height: '80px' }} alt="upload-blue" />

                <small className='text-primary'>Upload</small>
                <small className='text-secondary'>or</small>
                <small>Drop your file here</small>

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
              </div>
            }
          </>

          <small className='text-secondary'>Heading</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='heading' placeholder='Heading...' 
            value={addNewFrenchise.heading} onChange={(e) => setAddNewFrenchise((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Description</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='description' placeholder='Description...' 
            value={addNewFrenchise.description} onChange={(e) => setAddNewFrenchise((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Number</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='number' placeholder='Number...' 
            value={addNewFrenchise.price} onChange={(e) => setAddNewFrenchise((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Email</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='email' placeholder='Email...' 
            value={addNewFrenchise.price} onChange={(e) => setAddNewFrenchise((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill'
              disabled={!(addNewFrenchise.image && addNewFrenchise.heading && addNewFrenchise.description && addNewFrenchise.number && addNewFrenchise.email)}
              onClick={createFrenchise}
            >Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessAddListingFrenchisePopup;