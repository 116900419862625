
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ApiContext } from '../../UseContext/ApiCall';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../../config';
import toast from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner';
import { BsEmojiSmile } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBan, faCheck, faCopy, faPenToSquare, faReply, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GetchatProfile, GetchatUserList, createReaction, deleteEmiloMsg, deleteReaction, deleted_everyOne_Message } from '../../UseContext/ApiHelpers';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import { RiShareForwardFill } from "react-icons/ri";
import { useInView } from "react-intersection-observer";
import MessageCard from './MessageCard';
import EmojiPicker from "emoji-picker-react";
import ReactionPopup from './ReactionPopup';
import MessagePopup from './MessagePopup';
import ReportMessage from './ReportMessage';
import MsgAudioPlayer from './MsgAudioPlayer';


const ChatRigthBlock = () => {

  // const [sock, setSock] = useState(null);
  const [sendMessage, setSendMessage] = useState('');
  const [arrivalMsg, setArrivalMsg] = useState([]);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, settypingTimeout] = useState(null);
  const [typerFname, setTyperFname] = useState(null)
  const [typeChatId, setTypeChatId] = useState(null);
  const [online, setOnline] = useState(navigator.onLine)
  const [show, setShow] = useState(false);
  const [chatUserList, setChatUserList] = useState([])
  const [chatLoader, setChatLoader] = useState(false)
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [replyContent, setReplyContent] = useState(null)
  const [selectMsg, setSelectedMsg] = useState([])
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [uniqueDates, setUniqueDates] = useState([]);
  const [messageObject, setmessageObject] = useState([]);
  const [showUsers, setShowusers] = useState(false)
  const [tempUsers, setTempUsers] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [reactionId, setReactionId] = useState(null)
  const [openReaction, setOpenReaction] = useState(false)
  const [allReaction, setAllReactions] = useState([])
  const [msgType, setMsgType] = useState('text')
  const [msgPopup, setMsgPopup] = useState(false)
  const [mediaFiles, setMediaFiles] = useState([])
  const [activeMsgIndex, setActiveMsgIndex] = useState(null)
  const [reportMessage, setReportMessage] = useState('')
  const [openReport, setOpenReport] = useState(false)
  const [highlightIndex, setHighlightIndex] = useState(null);
  const [isScroll, setIsScroll] = useState(false)



  const elementsRef = useRef([]); // Array of refs for the messages

  const handleScrollToElement = (messageId) => {
    const element = elementsRef.current[messageId]; // Find the original message by messageId

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // Scroll the message into the center of the viewport
      });

      setHighlightIndex(messageId); // Set the highlight state

      // Remove the highlight after 2 seconds
      setTimeout(() => {
        setHighlightIndex(null);
      }, 2000);
    }
  };



  console.log("Arr", arrivalMsg)

  // const handleMsgType = (msg) => {
  //   if(msg.type === 'location'){
  //     return `${msg.message.l_address}`
  //   }

  //   else if(msg.type === 'contact'){
  //     return `${msg.message.contactName}`
  //   }

  //   else {
  //     return
  //   }
  // }


  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);

      // When online, send any messages stored in localStorage
      const offlineMessages = JSON.parse(localStorage.getItem('offlineMessages')) || [];
      offlineMessages.forEach(message => {
        // Send the message using your existing method (e.g., createEmiloMessage or ReplyEmiloMessage)
        // Make sure to handle success and error cases appropriately
      });
      localStorage.removeItem('offlineMessages');
    };

    const handleOffline = () => {
      setOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const chatBoxRef = useRef(null);

  const handleDownloadPdf = (fileUrl) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop(); // This will use the file name from the URL for downloading
    link.click();
  };


  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    };

    const dates = arrivalMsg.reduce((uniqueDates, msg) => {
      const msgDate = new Date(msg.createdTime).toLocaleDateString();
      if (!uniqueDates.includes(msgDate)) {
        uniqueDates.push(msgDate);
      }
      return uniqueDates;
    }, []);
    setUniqueDates(dates);
  }, [arrivalMsg]);


  const { chatId } = useParams();

  const { config, onlineStatus, isGroupchat, chatProfile, setChatProfile, setOnlineStatus, createEmiloMessage, loggedInUser, isUpdate, loggedInUserId, getEmiloMessage, socket, setChatData, setAdmincanMsg, rightReload, setRightReload } = useContext(ApiContext);

  const userId = loggedInUser && loggedInUser.fname;

  // socket && socket.emit("check_user_online_emilo", { chatId, userId: loggedInUserId })
  // TO join chat room
  useMemo(() => {
    socket && socket.emit("join_room_emilo", chatId && chatId);
    socket && socket.emit("check_user_online_emilo", { chatId, userId: loggedInUserId })

    const intervalId = setInterval(() => {
      socket && socket.emit("check_user_online_emilo", { chatId, userId: loggedInUserId });
    }, 5000);

    socket && socket.on("get_user_online_emilo", (e) => setOnlineStatus(e))

    return () => clearInterval(intervalId);

  }, [chatId, socket, loggedInUserId]);

  // To recieve message
  useEffect(() => {
    if (socket) {

      socket && socket.on("receive_message", (data) => {

        console.log("receive_message", data)

        setArrivalMsg((list) => [...list, { ...data, isSeen: "1", loading: true, }]);
      });

      socket && socket.on("msg_dlt_everyone_emilo", (data) => {
        setArrivalMsg((msgs) =>
          msgs.map((msg) => {
            if (msg.messageId === data.messageId || msg.replyMessageId === data.messageId) {
              return { ...msg, message: "This message was deleted", isEdited: "2" };
            }
            return msg;
          })
        );
      })

      socket && socket.on("message_viewed", (data) => {
        if (data.chatId === chatId) {
          setArrivalMsg((list) => list.map(msg => msg.messageId === data.messageId ? { ...msg, isSeen: data.isSeen } : msg));
        }
      })

      socket && socket.on("typing_started_from_server_emilo", (e) => {

        setTyping(true); setTyperFname(e.userId); setTypeChatId(e.chatId)
      });
      socket && socket.on("typing_stoped_from_server_emilo", (e) => { setTyping(false); setTyperFname(e); setTypeChatId(e.chatId) });

    };
  }, [socket, chatId]);


  // To get initial message
  useMemo(() => {
    if (chatId) {

      setChatLoader(true)
      getEmiloMessage(1, chatId, config).then((res) => {
        setArrivalMsg(res.map(msg => ({ ...msg, loading: true })));
        setChatLoader(false);
        setChatData(res);
        setRightReload(false)
      })
        .catch((err) => console.log(err));

      GetchatUserList(chatId, config).then((res) => { setChatUserList(res); setChatLoader(false); setTempUsers(res) })

      GetchatProfile(chatId, config).then((res) => {
        setChatProfile(res); setChatLoader(false); setAdmincanMsg(res);
      })
    }
  }, [chatId, isUpdate, rightReload]);


  // get all media files 
  useEffect(() => {
    const allfiles = arrivalMsg.map(msg => {
      if ((msg.type === "img_file" || msg.type === "video_file") && msg.fileUrl) {
        const data = { msgId: msg.messageId, type: msg.type, url: msg.fileUrl };
        return data;
      }
      return null;
    }).filter(file => file !== null);

    setMediaFiles(allfiles);

  }, [arrivalMsg]);


  // To handle input change
  const handleChange = (e) => {
    const value = e.target.value;

    if (value.includes('@')) {
      setShowusers(true);

      // Extract text after the last '@'
      const textParts = value.split('@');
      const text = textParts[textParts.length - 1];

      if (text) {
        setTempUsers(
          chatUserList.filter((user) => user.fname.toLowerCase().startsWith(text.toLowerCase()))
        );
      } else {
        setTempUsers(chatUserList);
      }
    } else {
      setShowusers(false);
      setTempUsers(chatUserList);
    }

    socket.emit("typing_started_emilo", { chatId, userId });

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    settypingTimeout(
      setTimeout(() => {
        socket.emit("typing_stoped_emilo", { chatId, userId });
      }, 1000)
    );

    setSendMessage(value);
  };


  const handleButtonClick = (id) => {
    if (selectMsg.length === 0) {
      setOpenPopoverId(openPopoverId === id ? null : id);
    }
  };

  const handleReply = (msg) => {

    setReplyContent(msg)
    setOpenPopoverId(null)
    setIsEdit(false);
  };


  const handleSelectMessage = (msg) => {
    setOpenPopoverId(null)
    setmessageObject(prev => {
      if (prev.includes(msg)) {
        return prev.filter(msgs => msgs !== msg);
      } else {
        return [...prev, msg];
      }
    });
  };

  const handleSelect = (msgId) => {
    setOpenPopoverId(null)

    setSelectedMsg(prev => {
      if (prev.includes(msgId)) {
        return prev.filter(msgs => msgs !== msgId);
      } else {
        return [...prev, msgId];
      }
    });
  };
  const handleDelete = (messageId) => {
    deleteEmiloMsg(messageId, chatId, config)
      .then(() => {
        setSelectedMsg([]);
        setOpenPopoverId(null)
        setArrivalMsg((msgs) => msgs.filter((msg) => (msg.messageId || msg.replyMessageId) !== messageId));
      })
      .catch((error) => {
        console.error(`Error deleting message ${messageId}:`, error);
      });
  };

  const handleDeleteEveryOne = (messageId, msg) => {
    deleted_everyOne_Message(messageId, msg.fileUrl, config)
      .then(() => {
        setSelectedMsg([]);
        setOpenPopoverId(null);

        const newmessage = { ...msg, message: "This message was deleted" }
        socket && socket.emit('msg_req_dlt_everyone_emilo', newmessage)

        setArrivalMsg((msgs) =>
          msgs.map((msg) => {
            if (msg.messageId === messageId || msg.replyMessageId === messageId) {
              return { ...msg, message: "You deleted this message", isEdited: "2" };
            }
            return msg;
          })
        );
      })
      .catch((error) => {
        console.error(`Error deleting message ${messageId}:`, error);
      });
  }


  const handleEditMessage = (msg) => {
    setReplyContent(msg)
    setIsEdit(true);
    setSendMessage(msg.message)
  }

  const handleReactionPick = (e, msg) => {
    setArrivalMsg((prev) =>
      prev.map((message) => {
        if (message.messageId === msg.messageId) {
          const existingReactionIndex = (message.reaction || []).findIndex(
            (reaction) => reaction.userId === loggedInUserId
          );

          let updatedReactions;
          if (existingReactionIndex !== -1) {
            updatedReactions = [...message.reaction];
            updatedReactions[existingReactionIndex] = {
              ...updatedReactions[existingReactionIndex],
              reactions: e.emoji,
            };
          } else {
            updatedReactions = [
              ...(message.reaction || []),
              { userId: loggedInUserId, reactions: e.emoji }
            ];
          }

          return {
            ...message,
            reaction: updatedReactions
          };
        }
        return message;
      })
    );

    setReactionId(null);
    createReaction(msg.messageId, msg.chatId, e.emoji, config);
  };


  const handleDeleteReaction = (reactionId, msgId) => {
    const updatedMessages = arrivalMsg.map((message) => {
      if (message.messageId === msgId) {
        const updatedReactions = message.reaction.filter(
          (reaction) => reaction.reactionId !== reactionId && reaction.userId !== loggedInUserId
        );

        return {
          ...message,
          reaction: updatedReactions
        };
      }

      // Return the other messages as is
      return message;
    });

    setArrivalMsg(updatedMessages);
    deleteReaction(msgId, reactionId, config);
  };

  const handleOpenReactions = (id) => {
    setReactionId(id)
  }


  const popover = (msg) => {

    if ((msg.side === 'left' && msg.message === "You deleted this message") || (reactionId !== null)) {
      return <></>;
    }

    //check for fifteen minutes
    function check(time) {
      let a = new Date(time);
      let b = new Date();
      let differenceInSeconds = (b - a) / 1000;
      let differenceInMinutes = differenceInSeconds / 60;
      if (differenceInMinutes < 59) return true
      else return false
    }

    return (
      <Popover id="popover-basic" className="chat-popover">
        <Popover.Body className='message-options'>
          <li onClick={() => handleReply(msg)}> <FontAwesomeIcon icon={faReply} /> &nbsp;  Reply</li>

          {check(msg.createdTime) && msg.type !== 'location' && msg.side === 'left' && <li onClick={() => handleEditMessage(msg)}> <FontAwesomeIcon icon={faPenToSquare} /> &nbsp;  Edit</li>}

          <li onClick={() => { handleSelect(msg.messageId); handleSelectMessage(msg) }}> <FontAwesomeIcon icon={faCheck} /> &nbsp; Select</li>

          <CopyToClipboard text={msg.message} onCopy={() => { toast.success("copied"); setOpenPopoverId(null) }}>
            <li>
              <span style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faCopy} /> &nbsp; Copy
              </span>
            </li>
          </CopyToClipboard>

          <li onClick={() => handleDelete(msg.messageId || msg.replyMessageId)}> <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete for me</li>

          <li onClick={() => { setOpenReport(true); setReportMessage(msg) }}> <FontAwesomeIcon icon={faReply} /> &nbsp; Report</li>

          {check(msg.createdTime) && msg.side === 'left' && <li className='option-del' onClick={() => handleDeleteEveryOne(msg.messageId || msg.replyMessageId, msg)}> <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete for everyone</li>}
        </Popover.Body>
      </Popover>
    )
  }
    ;

  function formatDate(dateString) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const parts = dateString.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);

    const inputDate = new Date(year, month - 1, day);
    const today = new Date();

    // Check if input date is today
    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    }

    // Check if input date is yesterday
    today.setDate(today.getDate() - 1); // Yesterday's date
    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      return "Yesterday";
    }

    return `${day} ${months[month - 1]} ${year}`;
  };

  function formatTime(timeString) {
    const time = new Date(timeString);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    return formattedTime;
  };

  // msg seen unseen
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (inView && !seen) {
      setSeen(true);
      // seenBy("3" , id).then((res) => {
      //   if (res.status === 201) {
      //     setSeen(true);
      //   }
      // });
    }
  }, [inView, seen]);


  // msg load more 

  const { ref: getmsg_ref, inView: spin_view } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [seenSpin, setSeenSpin] = useState(true);
  const [loadPage, setLoadPage] = useState(1);


  useEffect(() => {
    let isFetching = false;

    if (spin_view && !isFetching && seenSpin) {
      isFetching = true;
      setSeenSpin(false);

      getEmiloMessage(loadPage, chatId, config)
        .then((res) => {
          setArrivalMsg((prev) => [...res.map((msg) => ({ ...msg, loading: true })), ...prev]);
          setChatLoader(false);
          setChatData((prev) => [...prev, ...res]);
          setRightReload(false);
          setLoadPage((prevPage) => prevPage + 1);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          isFetching = false;
        });
    }

    if (!spin_view) {
      setSeenSpin(true);
    }
  }, [spin_view, seenSpin, loadPage, chatId, config]);

  // ----- Show/Hide Popup ----- 

  const optionShowHide = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!optionShowHide.current || !optionShowHide.current.contains(event.target)) {
      setOpenPopoverId(null)
      setShow(false)
    };
  };

  // show/Hide reaction emojis
  const reactionShow = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleReactionout);
    return () => {
      document.removeEventListener("mousedown", handleReactionout);
    };
  }, []);

  const handleReactionout = (event) => {
    if (!reactionShow.current || !reactionShow.current.contains(event.target)) {
      setReactionId(null)
    };
  };


  const extractMentions = (text) => {
    const mentionRegex = /@(\w+)/g;
    const mentions = [];
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      mentions.push(match[0]); // Include the '@'
    }

    return mentions;
  };
  const renderMessageWithLinks = (text) => {
    const mentions = extractMentions(text); // Assuming extractMentions returns mentions like @username
    let lastIndex = 0;
    const parts = [];

    mentions.forEach((mention, index) => {
      const startIndex = text.indexOf(mention, lastIndex);
      const endIndex = startIndex + mention.length;

      // Add the text before the mention
      if (startIndex > lastIndex) {
        parts.push(text.slice(lastIndex, startIndex));
      }

      // Extract the username without '@'
      const userName = mention.substring(1); // Removing '@'

      // Find the user in chatUserList using the 'username' field
      const user = chatUserList.find(
        (user) => user.username.toLowerCase() === userName.toLowerCase()
      );


      // If user is found, add a link to their profile
      if (user) {
        parts.push(
          <Link
            key={index}
            to={`/profile/${user.userId}`}
            style={{ color: '#9400d3', textDecoration: 'none' }}
          >
            {mention}
          </Link>
        );
      } else {
        // If user not found, just add the plain mention text
        parts.push(mention);
      }

      // Update lastIndex to continue from the end of the current mention
      lastIndex = endIndex;
    });

    // Add any remaining text after the last mention
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts;
  };

  const handleScroll = () => {
    setIsScroll(true)

    setTimeout(() => {
      setIsScroll(false)
    }, 2000);
  }

  return (
    <>

      <ReportMessage open={openReport} close={() => setOpenReport(false)} message={reportMessage} config={config} setReportMessage={setReportMessage} />

      <MessagePopup open={msgPopup} close={() => setMsgPopup(false)} mediaFiles={mediaFiles} activeMsgIndex={activeMsgIndex} setActiveMsgIndex={setActiveMsgIndex} />

      <ReactionPopup open={openReaction} setOpenReaction={setOpenReaction} setAllReactions={setAllReactions} close={() => setOpenReaction(false)} reactions={allReaction} handleDeleteReaction={handleDeleteReaction} />

      <chatProfile open={show} close={() => setShow(false)} />

      {
        !chatLoader
          ?
          <section className='' >
            {
              chatId && chatProfile
                ?
                <div className='vh-100'>
                  {/* Header */}
                  <ChatHeader setChatUserList={setChatUserList} chatUserList={chatUserList} setmessageObject={setmessageObject} messageObject={messageObject} config={config} chatId={chatId} selectMsg={selectMsg} setArrivalMsg={setArrivalMsg} setSelectedMsg={setSelectedMsg} onlineStatus={onlineStatus} netOnline={online} setShow={setShow} chatProfile={chatProfile} />

                  {/* Box */}
                  <div className='chat-box vh-100 overflow-y-scroll scrollbar-hidden px-3 px-lg-4 shadow' onScroll={handleScroll} >
                    {isScroll && <div className='text-center' ref={getmsg_ref} >
                      <Spinner animation="border" variant="primary" className='msg_spin' />
                    </div>}

                    {
                      uniqueDates.map((date, i) => (
                        <div key={i}>

                          <div className="chat-date-header"><hr />{formatDate(date)}<hr /></div>
                          {
                            arrivalMsg
                            &&
                            arrivalMsg.filter(msg => new Date(msg.createdTime).toLocaleDateString() === date).map((msg, index) => (
                              msg.senderUserId === loggedInUserId
                                ?
                                <div key={index} className='position-relative show_em'>
                                  <div key={index} optionShowHide={optionShowHide} className={`chats-rb-chat-t-box-left ${highlightIndex === msg.messageId ? 'highlight' : ''} `}>
                                    <OverlayTrigger trigger="click" placement="left" overlay={popover(msg)} show={openPopoverId === msg.createdTime}
                                      rootClose
                                      onHide={() => setOpenPopoverId(null)}>
                                      <div ref={ref} className='position-relative'>
                                        <div
                                          style={{
                                            backgroundColor: selectMsg.includes(msg.messageId) ? "#FFC1C3" : "#C4E9FD",
                                          }}
                                          className='chats-rb-chat-t-box-left-msg'
                                          onClick={() => {
                                            handleButtonClick(msg.createdTime);
                                            if (selectMsg.length > 0) {
                                              handleSelect(msg.messageId);
                                              handleSelectMessage(msg);
                                            }
                                          }}
                                        >

                                          {reactionId === msg.messageId &&
                                            <div ref={reactionShow}>
                                              < EmojiPicker
                                                className='reaction_picker'
                                                onEmojiClick={(emoji) => {
                                                  handleReactionPick(emoji, msg); // Ensure proper argument passing
                                                }}
                                                autoFocusSearch={false}
                                              />
                                            </div>
                                          }

                                          {msg.isEdited !== '2' ?
                                            <div  >
                                              <div className='chat-rb-msg-left'>
                                                <small className='chat-senderFname d-flex justify-content-between w-50 mb-1'>
                                                  {msg.senderUserId === loggedInUserId ? "you" : msg.senderFname}
                                                  {msg.isEdited === '1' && <span className='font-italic text-muted mx-2'>edited</span>}
                                                </small>
                                                {msg.isForwarded === 'true' && <small className='fwd-symbol'><RiShareForwardFill /></small>}

                                                {/* Reply message */}
                                                {(msg.replyMessage || msg.replyfileUrl) ?
                                                  <div onClick={() => handleScrollToElement(msg.replyMessageId)} >
                                                    {/* types of files */}

                                                    {/* text messsage */}
                                                    <div className='reply_box_reply'>

                                                      {/* img file */}
                                                      {msg && msg.replyfileType === "img_file" &&
                                                        <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.replyfileUrl}`} alt="" style={{ maxWidth: "50px" }} />}


                                                      {/* video file */}
                                                      {msg && msg.replyfileType === "video_file" &&
                                                        <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.replyThumbnailUrl}`} alt="" style={{ maxWidth: '50px' }} />
                                                      }


                                                      {/* audio file */}
                                                      {msg && msg.replyfileType === "audio_file" &&
                                                      <div className='d-flex justify-content-between align-items-center'>
                                                        <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + 'images/icon/audio.svg'}`} alt="" style={{ maxWidth: '20px' }} />
                                                        {/* <span className='fs-10'>{msg.replyMessage}</span> */}
                                                      </div>
                                                      }
                                                      

                                                      {/* location */}
                                                      {msg && msg.replyfileType === "location" &&
                                                        <div onClick={(e) => e.stopPropagation()} className='d-flex flex-column' style={{ maxWidth: "30px" }}>
                                                          <img src={AwsFileUrl + 'images/location.png'} alt='location' width='30px' />
                                                          {msg.type === 'location' && <span className='fs-10 mt-2'>{msg.message && msg.message.l_address}</span>}
                                                        </div>
                                                      }

                                                      {/* PDF file */}
                                                      {msg && msg.replyfileType === "pdf_file" && (
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                          <img src="/images/icons/chat-pdf.svg" alt="chat" width={30} />
                                                          <span className='fs-10'>{msg.fileName}</span>
                                                        </div>
                                                      )}

                                                      {/* contact type */}
                                                      {msg && msg.replyfileType === "contact" && (
                                                        <span className='msg_contact_relpy' >
                                                          <img src={AwsFileUrl + 'default/user.webp'} alt="" width='45px' />
                                                          <small>{msg.message.contactName}</small>
                                                        </span>
                                                      )}

                                                      <div className='reply-msg'>{msg.replyMessage.length > 50 ? msg.replyMessage.substr(0, 100) + "..." : msg.replyMessage}</div>
                                                    </div>

                                                    {/* <span className='fs-10'>{renderMessageWithLinks(msg.message)}</span> */}
                                                    <div className='reply-box'>
                                                      {msg && <span className='fs-10'>{renderMessageWithLinks(msg.message)}</span>}
                                                    </div>

                                                    <div className='deliver-time '>
                                                      <time>{formatTime(msg.createdTime)}</time>
                                                      {msg.loading ?

                                                        <img className='ms-1' src={`${msg.isSeen === "1" ? '/images/double-check.svg' : "/images/double-gray.svg"}`} alt="" />
                                                        :
                                                        <img src='/images/load-msg.svg' alt="" />
                                                      }
                                                    </div>

                                                  </div>
                                                  :
                                                  // text message
                                                  <div >
                                                    <div className='reply-box-1' ref={(el) => { (elementsRef.current[msg.replyMessageId || msg.messageId] = el); }}>


                                                      {/* types of files */}

                                                      {/* text messsage */}

                                                      {/* img file */}
                                                      {msg && msg.type === "img_file" &&
                                                        <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.fileUrl}`} alt="" style={{ maxWidth: '100%' }} />}


                                                      {/* video file */}
                                                      {msg && msg.type === "video_file" &&
                                                        <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.thumbnail}`} alt="" style={{ maxWidth: '100%' }} />
                                                      }

                                                      {msg && msg.type === "audio_file" &&
                                                        <div onClick={(e) => { e.stopPropagation(); setActiveMsgIndex(msg.messageId) }}>
                                                          <MsgAudioPlayer isOnChat={true} fileName={msg.fileName}  audioSrc={`${AwsFileUrl + msg.fileUrl}`} />
                                                        </div>
                                                      }

                                                      {/* location */}
                                                      {msg && msg.type === "location" &&
                                                        <div onClick={(e) => e.stopPropagation()} className='d-flex flex-column' style={{ maxWidth: "200px" }}>
                                                          <Link target='_blank' to={`https://maps.google.com/maps?q=${msg.message.lat}%2C${msg.message.lng}&z=17&hl=en`}>
                                                            <img src={`${msg.fileUrl !== '' ? AwsFileUrl + msg.fileUrl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMmXcY-Go8LEGF9QR-nS328jtjUCfMTWAzFg&s"} `} alt='url' />
                                                          </Link>
                                                          {msg.type === 'location' && <span className='fs-10 mt-2'>{msg.message && msg.message.l_address}</span>}
                                                        </div>
                                                      }

                                                      {/* PDF file */}
                                                      {msg && msg.type === "pdf_file" && (
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                          <img src="/images/icons/chat-pdf.svg" alt="" width={50} />
                                                          <span className='fs-10'>{msg.fileName}</span>
                                                          <button className='download_btn' onClick={(e) => { handleDownloadPdf(AwsFileUrl + msg.fileUrl); e.stopPropagation(); }}>
                                                            <FontAwesomeIcon icon={faArrowDown} />
                                                          </button>
                                                        </div>
                                                      )}

                                                      {msg && msg.type === "contact" && (
                                                        <span className='msg_contact' >
                                                          <a href={AwsFileUrl + msg.fileUrl} download={msg.message.fileName} >
                                                            <img src={AwsFileUrl + 'default/user.webp'} alt="" width='45px' />
                                                            <small>{msg.message.contactName}</small>
                                                          </a>
                                                        </span>
                                                      )}
                                                      {/* <span className='fs-10'>{renderMessageWithLinks(msg.message)}</span> */}
                                                    </div>
                                                    <div>
                                                      {msg && (msg.type !== "location" && msg.type !== 'contact') && <span className='fs-10'>{renderMessageWithLinks(msg.message)}</span>}
                                                    </div>
                                                    <div className='deliver-time '>
                                                      <time>{formatTime(msg.createdTime)}</time>
                                                      {msg.loading ?
                                                        <img className='ms-1' src={`${msg.isSeen === "1" ? '/images/double-check.svg' : "/images/double-gray.svg"}`} alt="" />
                                                        :
                                                        <img src='/images/load-msg.svg' alt="" />
                                                      }
                                                    </div>
                                                  </div>
                                                }

                                              </div>
                                            </div>
                                            :
                                            <div>
                                              <span className='emoji_reaction' onClick={() => handleOpenReactions(msg.messageId)}><BsEmojiSmile size={18} /></span>
                                              <div className='chat-rb-msg-left'>
                                                {msg && (msg.type !== "location" && msg.type !== "contact") && <span className='fs-10 fst-italic'><FontAwesomeIcon icon={faBan} /> {msg.message}</span>}
                                              </div>
                                            </div>
                                          }

                                        </div>
                                        {/* Reactions */}

                                        <span className='emoji_reaction' onClick={() => handleOpenReactions(msg.messageId)}><BsEmojiSmile size={18} /></span>
                                      </div>
                                    </OverlayTrigger>
                                  </div>



                                  {msg.reaction && msg.reaction.length > 0 &&
                                    <div className='reactions' onClick={() => { setAllReactions(msg); setOpenReaction(true); }}>
                                      <span>{msg.reaction.slice(msg.reaction.length - 2).map((r, index) => (
                                        <span key={index} className='reaction'>{r.reactions}</span>
                                      ))}</span>
                                      <span className='reaction_count'>{msg.reaction.length}</span>
                                    </div>
                                  }

                                </div>
                                :
                                // --- Right ---
                                <div key={index}>
                                  <MessageCard elementsRef={elementsRef} highlightIndex={highlightIndex} handleScrollToElement={handleScrollToElement} setMsgPopup={setMsgPopup} setActiveMsgIndex={setActiveMsgIndex} handleDownloadPdf={handleDownloadPdf} reactionShow={reactionShow} reactionId={reactionId} setOpenReaction={setOpenReaction} setAllReactions={setAllReactions} handleOpenReactions={handleOpenReactions} handleReactionPick={handleReactionPick} optionShowHide={optionShowHide} renderMessageWithLinks={renderMessageWithLinks} socket={socket} config={config} key={index} msg={msg} id={msg._id} popover={popover} handleSelectMessage={handleSelectMessage} loggedInUserId={loggedInUserId} handleButtonClick={handleButtonClick} handleSelect={handleSelect} selectMsg={selectMsg} openPopoverId={openPopoverId} setOpenPopoverId={setOpenPopoverId} message={msg.message} formatTime={formatTime} isSeen={msg.isSeen} />
                                </div>

                            ))
                          }
                        </div>
                      ))
                    }
                  </div>

                  {/* Footer */}
                  <ChatFooter msgType={msgType} setMsgType={setMsgType} setSelectedMsg={setSelectedMsg} isEdit={isEdit} setIsEdit={setIsEdit} setShowusers={setShowusers} showUsers={showUsers} optionShowHide={optionShowHide} tempUsers={tempUsers} setOnline={setOnline} typing={typing} loggedInUserId={loggedInUserId} chatId={chatId} userId={userId} replyContent={replyContent} setReplyContent={setReplyContent} sendMessage={sendMessage} setSendMessage={setSendMessage} online={online} handleChange={handleChange} ThreeDots={ThreeDots} setArrivalMsg={setArrivalMsg} config={config} isGroupchat={isGroupchat} selectedPhotos={selectedPhotos} typerFname={typerFname} socket={socket} createEmiloMessage={createEmiloMessage} setSelectedPhotos={setSelectedPhotos} typeChatId={typeChatId} />
                </div>
                :
                <div className='chats-rb-chat-f shadow vh-100'>
                  <img className='chats-rb-chat-f-img' src={`${BASE_URL_STATIC}/chat-bg.jpg`} alt="chat background" />
                </div>
            }
          </section>
          :
          <div className="commentLoader"></div>
      }
    </>
  )
}

export default ChatRigthBlock
