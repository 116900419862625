import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EmojiPopup from '../EmojiPopup';
import CreatePhotoAndVideo2 from './CreatePhotoAndVideo/CreatePhotoAndVideo2';
import { gifArray } from '../../Data/EducationData';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

async function urlToFile(url, filename, mimeType) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
}

async function convertUrlsToFiles(urlArray) {
    const filePromises = urlArray.map((url, index) => {
        const filename = `gif_${index}.gif`;
        return urlToFile(url, filename, 'image/gif');
    });

    const files = await Promise.all(filePromises);
    console.log("files",files);
    return files;
}

function CreateGif({ open,gifType, setShowPv2, selectphotoAndVideo, setSelectedPhotoVideo, setSelectedEmoji, selectedEmoji, close, setOpenEmoji, openEmoji, setShowCreatePostPopup }) {

   const fileInputRef = useRef(null);
   const [fileUrl, setFileUrl] = useState([]);
   const [selectGif, setSelectGif] = useState([]);

   useMemo(() => {
      if (Array.isArray(selectphotoAndVideo)) {
         const urls = selectphotoAndVideo.map((file) => URL.createObjectURL(file));
         setFileUrl(urls);

         return () => {
            urls.forEach((url) => URL.revokeObjectURL(url));
         };
      }
   }, [selectphotoAndVideo]);

   const handleSelectPhotoAndVideo = async (e) => {
      const selectedFiles = Array.from(e.target.files);
      setSelectedPhotoVideo(prev => [...selectedFiles, ...prev ]);
      close();
      setShowPv2(true);
   };

   const handleImageClick = () => {
      fileInputRef.current.click();
   };

   
   const handleClose = () => {
      if (gifType === "post") {
         setShowPv2(true)
      }
      else if (gifType === "gif") {
         setShowCreatePostPopup(true)
         setShowPv2(false)
      }
      close();
      setOpenEmoji(false);
   }

   const handleSelectGif = (i) => {
      if (selectGif.includes(i)) {
         setSelectGif(selectGif.filter(e => e !== i));
      } else {
         setSelectGif([...selectGif, i]);
      }
   };

   useEffect(() => {
      setSelectGif([])
   } , [open])

   const handleUpload = async () => {
      const selectedGifUrls = selectGif.map(index => gifArray[index]);
      const files = await convertUrlsToFiles(selectedGifUrls);
      setSelectedPhotoVideo([...files,...selectphotoAndVideo ])
      close();
      setShowPv2(true);
      console.log(files);
      // Now you can upload the files to the server
   };

   return (
      <>
         <Modal show={open} onHide={handleClose} centered backdrop="static" keyboard={false} className="create-call">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
               <div className="row">
                  <p className="col-1" style={{ cursor: "pointer" }} onClick={handleClose}>
                     <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                  </p>
                  <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                     Create GIF
                  </p>
               </div>
               <>
                  {openEmoji ? (
                     <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                  ) : (
                     <Tabs defaultActiveKey="GIF" id="uncontrolled-tab-example" className="mb-3 w-100">
                        <Tab style={{position : "relative"}} eventKey="GIF" title="GIF" className="api_gif">
                           <div className="row row-cols-3 align-items-center m-auto ">
                              {gifArray.map((e, i) => (
                                 <div key={i} className='col-4 shadow p-0 position-relative '>
                                 <img
                                    onClick={() => handleSelectGif(i)}
                                    style={{cursor : "pointer"}}
                                       className="rounded-2"
                                       key={i}
                                       src={e}
                                       alt="gif_images"
                                       />
                                      {selectGif.includes(i) && 
                                      <img className='checked_img' src="/images/checked.svg" alt="" />
                                      }
                                       </div>

                              ))}
                           </div>
                           <div className="col-12 col-lg-8 m-auto position-sticky bottom-0">
                              <button disabled={selectGif.length === 0} onClick={handleUpload} className="text_post_btn shadow">{`${selectGif.length === 0 ? 'Next' : `Next (${selectGif.length})` }`}</button>
                           </div>
                        </Tab>
                        <Tab eventKey="Browse GIF" title="Browse GIF" className="local_gif">
                           <div className="d-flex flex-column justify-content-center align-items-center" style={{ border: '2px dashed #d3d3d3' }}>
                              {fileUrl.length > 0 ? (
                                 <CreatePhotoAndVideo2 selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo} />
                              ) : (
                                 <img
                                    src="https://img.freepik.com/premium-vector/business-cloud-computing-data-provision-cloud-computing-services-web-page-banner-presentation-social-media-documents-cards-posters-vector-illustration_98702-1814.jpg"
                                    alt="git_pics"
                                    onClick={handleImageClick}
                                    style={{ cursor: 'pointer' }}
                                 />
                              )}
                              <input className="d-none" type="file" onChange={handleSelectPhotoAndVideo} ref={fileInputRef} multiple accept="image/gif" />
                           </div>
                           <div className="col-12 col-lg-8 m-auto">
                              <button onClick={handleImageClick} className="text_post_btn">Choose from gallery</button>
                           </div>
                        </Tab>
                     </Tabs>
                  )}
               </>
            </Modal.Body>
         </Modal>
      </>
   );
}

export default CreateGif;
