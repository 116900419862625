import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../../../config';
import Slider from "react-slick";



const ProposeOptionGifts1 = () => {

  const navigate = useNavigate();

  // Ads
  const ads = [
    `${BASE_URL_STATIC}/SoftCorner/sc-pog-add.png`,
    `https://static.vecteezy.com/system/resources/previews/000/370/726/original/vector-text-lettering-happy-valentines-day-banners.jpg`,
    `https://img.freepik.com/free-vector/realistic-valentine-s-day-twitch-banner_23-2149225924.jpg?t=st=1722854353~exp=1722857953~hmac=9d3c04b3f83b51be29ff4e97017d84e6e5c2c8218904f7b48344bd806512ed87&w=1380`,
    `${BASE_URL_STATIC}/SoftCorner/sc-pog-add.png`,
    `https://static.vecteezy.com/system/resources/previews/000/370/726/original/vector-text-lettering-happy-valentines-day-banners.jpg`,
    `https://img.freepik.com/free-vector/realistic-valentine-s-day-twitch-banner_23-2149225924.jpg?t=st=1722854353~exp=1722857953~hmac=9d3c04b3f83b51be29ff4e97017d84e6e5c2c8218904f7b48344bd806512ed87&w=1380`,
    `${BASE_URL_STATIC}/SoftCorner/sc-pog-add.png`,
    `https://static.vecteezy.com/system/resources/previews/000/370/726/original/vector-text-lettering-happy-valentines-day-banners.jpg`,
    `https://img.freepik.com/free-vector/realistic-valentine-s-day-twitch-banner_23-2149225924.jpg?t=st=1722854353~exp=1722857953~hmac=9d3c04b3f83b51be29ff4e97017d84e6e5c2c8218904f7b48344bd806512ed87&w=1380`,
    `${BASE_URL_STATIC}/SoftCorner/sc-pog-add.png`,
    `https://static.vecteezy.com/system/resources/previews/000/370/726/original/vector-text-lettering-happy-valentines-day-banners.jpg`,
    `https://img.freepik.com/free-vector/realistic-valentine-s-day-twitch-banner_23-2149225924.jpg?t=st=1722854353~exp=1722857953~hmac=9d3c04b3f83b51be29ff4e97017d84e6e5c2c8218904f7b48344bd806512ed87&w=1380`,
  ];

  const adsSettings = {
    className: 'sc-gift-ads',
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: null, 
    nextArrow: null,
  };

  // Categories
  const categories = [
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-1.png`,
      name: 'Flowers'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-2.png`,
      name: 'Rings'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-1.png`,
      name: 'Flowers'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-2.png`,
      name: 'Rings'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-1.png`,
      name: 'Flowers'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-2.png`,
      name: 'Rings'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-1.png`,
      name: 'Flowers'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-2.png`,
      name: 'Rings'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-1.png`,
      name: 'Flowers'
    },
    {
      image: `${BASE_URL_STATIC}/SoftCorner/sc-pog-category-2.png`,
      name: 'Rings'
    },
  ];

  const categoriesSettings = {
    className: 'sc-gift-categories',
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  // Products
  const products = [
    {
      id: 11,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Bucket Gift',
      price: 1700,
      rating: 5,
    },
    {
      id: 12,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black Orchids Bucket Gift',
      price: 699,
      rating: 5,
    },
    {
      id: 13,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
      name: 'Rotating Love Couple',
      price: 1500,
      rating: 5,
    },
    {
      id: 14,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Stuffed Pink Teddy',
      price: 1899,
      rating: 5,
    },
    {
      id: 12,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black',
      price: 699,
      rating: 5,
    },
    {
      id: 13,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
      name: 'Rotating Love Couple',
      price: 1500,
      rating: 5,
    },
    {
      id: 14,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Stuffed Pink Teddy',
      price: 1899,
      rating: 5,
    },
    {
      id: 15,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-5.png',
      name: 'Pink Love Soft Cushion',
      price: 999,
      rating: 5,
    },
    {
      id: 11,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Bucket Gift',
      price: 1700,
      rating: 5,
    },
    {
      id: 12,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black',
      price: 699,
      rating: 5,
    },
    {
      id: 13,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
      name: 'Rotating Love Couple',
      price: 1500,
      rating: 5,
    },
    {
      id: 14,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Stuffed Pink Teddy',
      price: 1899,
      rating: 5,
    },
    {
      id: 15,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-5.png',
      name: 'Pink Love Soft Cushion',
      price: 999,
      rating: 5,
    },
    {
      id: 15,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-5.png',
      name: 'Pink Love Soft Cushion',
      price: 999,
      rating: 5,
    },
    {
      id: 11,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Bucket Gift',
      price: 1700,
      rating: 5,
    },
    {
      id: 12,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black',
      price: 699,
      rating: 5,
    },
    {
      id: 13,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
      name: 'Rotating Love Couple',
      price: 1500,
      rating: 5,
    },
    {
      id: 14,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Stuffed Pink Teddy',
      price: 1899,
      rating: 5,
    },
    {
      id: 15,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-5.png',
      name: 'Pink Love Soft Cushion',
      price: 999,
      rating: 5,
    },
    {
      id: 11,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Bucket Gift',
      price: 1700,
      rating: 5,
    },
    {
      id: 12,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black',
      price: 699,
      rating: 5,
    },
    {
      id: 13,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-3.png',
      name: 'Rotating Love Couple',
      price: 1500,
      rating: 5,
    },
    {
      id: 14,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Stuffed Pink Teddy',
      price: 1899,
      rating: 5,
    },
    {
      id: 15,
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-5.png',
      name: 'Pink Love Soft Cushion',
      price: 999,
      rating: 5,
    },
  ];

  return (
    <>
      <section className='pt-5 pb-5 pb-lg-0 vh-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-lg-5'>
        {/* Search */}
        <div className='mt-4 mt-md-5 mb-4 px-3 py-1 d-flex align-items-center rounded-pill bg-white mx-auto shadow'>
          <img src={`${BASE_URL_STATIC}/search.png`} alt="search" height={23} width={25} />
          <input type="search" className='form-control mx-3 bg-transparent border-0 shadow-none' id='search' name='search' placeholder='Search...' />
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-pog-adjust.png`} alt="sc-pog-adjust" height={25} width={25} />         
        </div>

        {/* Ads */}
        <div className='mb-4'>
          <Slider {...adsSettings}>
            {
              ads.map((ad, index) => (
              <div className='' key={index}>
                <img src={ad} className='w-100 object-fit-cover rounded-4' style={{ height: '50vh' }} alt="sc-pog-add" />
              </div>
              ))
            }
          </Slider>
        </div>

        {/* Category */}
        <div className='my-4 my-sm-5'>
          <span className='bg-secondary text-white px-3 py-2 rounded-pill me-3 mb-3 shadow-sm'>Categories</span>

          <Slider {...categoriesSettings}>
            {
              categories.map((category, index) => (
                <div className='d-flex align-items-center' key={index}>
                  <img src={category.image} className='rounded-circle object-fit-cover position-relative z-1 shadow-sm' style={{ height: '50px', width: '50px' }} alt="sc-pog-category-1"  />
                  <span className='bg-white ps-4 pe-3 py-2 shadow-sm rounded-pill position-relative' style={{ right: '17px' }}>{category.name}</span>
                </div>
              ))
            }
          </Slider>          
        </div>

        {/* Products */}
        <div className='sc-products pb-3'>
          {
            products.map((product, index) => (
              <div className='sc-product bg-white rounded-3 shadow-sm p-2 p-sm-3 position-relative' onClick={() => navigate(`item`)} key={index}>
                {/* star */}
                <img className='position-absolute top-0 end-0 mt-2 mt-sm-3 me-2 me-sm-3' src={`${BASE_URL_STATIC}/star.png`} alt="star" />

                {/* Product Image */}
                <img src={product.img} className='object-fit-contain w-100' style={{ height: '125px' }} alt="sc-pog-img-1" />

                {/* Name */}
                <span className='my-2 fw-semibold overflow-hidden' style={{ height: '25px' }}>{product.name}</span>

                <div className='d-flex justify-content-between'>
                  {/* Price */}
                  <span><span>&#8377;</span> {product.price}</span>

                  {/* Rating */}
                  <div className='bg-primary px-2 rounded-pill d-flex align-items-center'>
                    <span className='text-white me-1'>{product.rating}</span>
                    <img src={`${BASE_URL_STATIC}/star.png`} alt="star" height={15} width={15} />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
};

export default ProposeOptionGifts1;
