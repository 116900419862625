import React, { useState } from 'react';
import AddNewAddress from '../Popup/Profile/AddNewAddress';
// import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { BASE_URL_STATIC } from '../../../config';

const ProfileAddress = () => {

  const [showAddNewAddressPopup, setShowAddNewAddressPopup] = useState(false);

  return (
    <>
      {/* Popup */}
      <AddNewAddress showAddNewAddressPopup={showAddNewAddressPopup} setShowAddNewAddressPopup={setShowAddNewAddressPopup} />

      {/* Address */}
      <section className='vh-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5 py-5'>
        {/* Image */}
        <div className='text-center mt-5'>
          <img src={`${BASE_URL_STATIC}/emailbg.jpg`} className='rounded-4 shadow' style={{ height: '30vh', width: '100%' }} alt="emailbg" />
        </div>

        {/* Address */}
        <form className='my-5'>
          <h4 className='mb-3'>Select Address</h4>

          {/* Home */}
          <label htmlFor="home" className='d-flex align-items-center justify-content-evenly shadow rounded-4 mb-4 py-2'>
            <input type="radio" className='mx-2 mx-sm-3' id='home' name='address' value='home' />

            <div className='text-center'>
              <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="create-icon2" height={25} width={25} />
              <p>Home</p>
            </div>

            <span className='mx-2 mx-sm-3'>864 Alta Loma Dr South San Francisco, California(CA), 94080</span>

            <img className='' src={`${BASE_URL_STATIC}/call-icon1.png`} alt="create" height={20} width={20} />
            <img className='mx-2 mx-sm-3' src={`${BASE_URL_STATIC}/delete.png`} alt="delete" height={20} width={20} />
          </label>

          {/* Office */}
          <label htmlFor="office" className='d-flex align-items-center justify-content-evenly shadow rounded-4 mb-4 py-2'>
            <input type="radio" className='mx-2 mx-sm-3' id='office' name='address' value='office' />

            <div className='text-center'>
              <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="create-icon2" height={25} width={25} />
              <p>Office</p>
            </div>

            <span className='mx-2 mx-sm-3'>864 Alta Loma Dr South San Francisco, California(CA), 94080</span>

            <img className='' src={`${BASE_URL_STATIC}/call-icon1.png`} alt="create" height={20} width={20} />
            <img className='mx-2 mx-sm-3' src={`${BASE_URL_STATIC}/delete.png`} alt="delete" height={20} width={20} />
          </label>

          {/* Partner */}
          <label htmlFor="partner" className='d-flex align-items-center justify-content-evenly shadow rounded-4 mb-4 py-2'>
            <input type="radio" className='mx-2 mx-sm-3' id='partner' name='address' value='partner' />

            <div className='text-center'>
              <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="create-icon2" height={25} width={25} />
              <p>Partner</p>
            </div>

            <span className='mx-2 mx-sm-3'>864 Alta Loma Dr South San Francisco, California(CA), 94080</span>

            <img className='' src={`${BASE_URL_STATIC}/call-icon1.png`} alt="create" height={20} width={20} />
            <img className='mx-2 mx-sm-3' src={`${BASE_URL_STATIC}/delete.png`} alt="delete" height={20} width={20} />
          </label>
        </form>

        {/* Add New Address */}
        <div className='text-center py-2 rounded-5 shadow mx-auto mb-4 mb-lg-0' style={{ width: '175px' }} onClick={() => setShowAddNewAddressPopup(true)}>
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} className='mb-2' alt="add" height={25} width={25} />
          <p>Add New Address</p>
        </div>
      </section>
    </>
  );
};

export default ProfileAddress;
