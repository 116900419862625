import React from 'react'
import { BASE_URL_STATIC } from '../../../config';

const RightSpecialDays = ({ specialDay }) => {
   const formattedDate = (inputDateString) => {
      const monthNames = [
         'Jan', 'Feb', 'Mar', 'Apr',
         'May', 'Jun', 'Jul', 'Aug',
         'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const inputDate = new Date(inputDateString);

      const month = monthNames[inputDate.getMonth()];
      const day = inputDate.getDate().toString().padStart(2, '0');
      const year = inputDate.getFullYear();

      const formattedDateString = `${month} ${day}, ${year}`;

      return formattedDateString;
   };
   
   const formatDate = (dateString) => {
      const options = { day: "numeric", month: "short", year: "numeric" };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
  
    const formatTime = (dateTimeString) => {
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const date = new Date(dateTimeString);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

   console.log("spe" ,  specialDay);

   return (
      <section className='sc-specialDays-rightBlock'>
         {/* Div */}
         <div className='sc-specialDays-rightBlock-div'>
            {/* Div 1 */}
            <div className='sc-specialDays-rightBlock-div-1'>
               <h2>{specialDay.dateTime && formattedDate(specialDay.dateTime)}</h2>

               <div>
                  <h4>{specialDay.createdTime && Math.floor((Date.now() - new Date(specialDay.createdTime)) / (1000 * 60 * 60 * 24)) + " Days"}</h4>
               </div>
            </div>

            <hr className='sc-specialDays-rightBlock-hr' />

            {/* Div 2 */}
            <div className='sc-specialDays-rightBlock-div-2'>
               <div className='sc-specialDays-rightBlock-div-2-img'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} alt="sc-sd-coffee" height={35} width={25} />
               </div>

               <div className='sc-specialDays-rightBlock-div-2-head'>
                  <h5>{specialDay.title}</h5>
                  <span>{specialDay.location}</span>
               </div>
            </div>

            {/* Div 3 */}
            <div className='sc-specialDays-rightBlock-div-3'>
               <form>
                  {/* Location */}
                  <label htmlFor="location" className='sc-specialDays-rightBlock-div-3-location'>
                     <div>

                     <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} id='location' name='location' />
                    <span>{specialDay.location}</span>
                     </div>

                     <span>See map</span>
                  </label> <br />

                  {/* Date And Time */}
                  <div className='flex justify-content-start' style={{color:"#2676E1"}}>
                <label htmlFor="repeat" className='sc-single-plans justify-content-start gap-3' style={{width:"306px" }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                  <div className='d-flex'>
                    <span className="sc-singlePlan-dates">
                      {formatDate(specialDay.createdTime !== "" && specialDay.createdTime)}
                    </span>
                  </div>
                </label>
                <label htmlFor="repeat" className='sc-single-plans gap-3 justify-content-start' >
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                  <div className='d-flex gap-2'>
                    <span className="">
                      {formatTime(specialDay.createdTime !== "" && specialDay.createdTime)}
                    </span>
                  </div>
                </label>
              </div>


                  {/* Alert */}
                  {specialDay && specialDay.alertTime.map((e, i) => (
                <div key={i}>
                  <label htmlFor="alert" className='sc-single-plans' >
                    <div className='flex justify-content-start'>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-alert.png`} alt="sc-sd-repeat" height={20} width={20} />
                      <span style={{ fontSize: "18px", fontWeight: "500"}}>Alert</span>
                    </div>
                    <span style={{ fontSize: "15px", fontWeight: "500", color:"#2676E1"  }}>
                      {`- Before ` +
                        ((e === "5_min" && "5 min") || (e === "1_min" && "1 min") || (e === "1_week" && "1 week") || (e === "1_month" && "1 month"))
                      }</span>
                  </label>
                  <br />
                </div>
              ))}
               </form>
            </div>

            {/* Div 4 */}
            <div className='sc-specialDays-rightBlock-div-4'>
               <span>Add to Google Calendar</span>
            </div>

            {/* Div 5 */}
            <div className='sc-specialDays-rightBlock-div-5'>
               <div className='sc-specialDays-rightBlock-div-5-notes'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} alt="" height={20} width={20} />
                  <span>Notes</span>
               </div>

               <span className='sc-specialDays-rightBlock-div-5-textArea'>{specialDay.note}</span>
            </div>
         </div>
      </section>
   )
}

export default RightSpecialDays