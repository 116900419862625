import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import { getToken} from 'firebase/messaging';
import axios from 'axios';
import { BASEAPIURL } from './config';

const firebaseConfig = {
  apiKey: "AIzaSyAiuj217FZVUzVDdOZ2sNfbjSQQzqQbNHU",
  authDomain: "emilo-becec.firebaseapp.com",
  projectId: "emilo-becec",
  storageBucket: "emilo-becec.appspot.com",
  messagingSenderId: "802602994771",
  appId: "1:802602994771:web:23049834219825cea60aa6",
  measurementId: "G-SN8EJYSHE9"
};
 
initializeApp(firebaseConfig);

export const askForPermissionToReceiveNotifications = () => {
    const messaging = getMessaging();
    let device = "Website";
    return getToken(messaging, { vapidKey: 'BNyo07KPNUb4b9itgRQuKyCS957hXjo70tcK6coxENj-VSxSlt4ga-7wHhUM5gw8m3Gdxj8DWcB9BEHqE1bwUJ8' })
      .then(async(token) => {
        // console.log("token", token);
        localStorage.setItem('fbToken', token);
        if (token) {
            try {
              const url = `${BASEAPIURL}/user/firebaseMsg`;
              const config = {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    device: localStorage.getItem('deviceDetail') ? localStorage.getItem('deviceDetail') : 'unknown',
                    userip: localStorage.getItem('deviceIp') ? localStorage.getItem('deviceIp') : 0,
                    devicetype: localStorage.getItem('deviceOs') ? localStorage.getItem('deviceOs') : 'unknown',
                    locuserlong: localStorage.getItem('LocUserLong') ? localStorage.getItem('LocUserLong') : 0,
                    locuserlat: localStorage.getItem('LocUserLat') ? localStorage.getItem('LocUserLat') : 0,
                    locusermsg: localStorage.getItem('LocUserMsg') ? localStorage.getItem('LocUserMsg') : 'NA',
                    locusercity: localStorage.getItem('localityCity') ? localStorage.getItem('localityCity') : 'unknown',
                  },
              };

              const DeviceDetails = localStorage.getItem('DeviceDetails');

              if (DeviceDetails && DeviceDetails !== '') {
                device = DeviceDetails;
              }

              await axios.post(url, { fbtoken : token , device }, config)
                    .then((response) => {
                        // console.log("res" , response.data);
                    });
            } catch (err) {
                console.log(err);
            }
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };