import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { ApiContext } from "../../UseContext/ApiCall";
import { BASE_URL_STATIC } from "../../../../config";

const LikeBestClipButton = ({ bestClipId }) => {

  const [like, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const { config, socket } = useContext(ApiContext);

  // To fetch best clip like status
  const getBestClipLikeStatus = async (bestClipId, config) => {
    await axios.post("https://emilonode.sjain.io/post/checkbestcliplike", { bestclipId: bestClipId }, config )
    .then((res) => {
      // console.log(res.data.liked);
      setLike(res.data.liked);
    })
    .catch((err) => {
      return null;
    });
  };

  useEffect(() => {
    getBestClipLikeStatus(bestClipId, config);
  }, [bestClipId]);

  // To fetch best clip like count
  const getBestClipLikeCount = async (bestClipId, config) => {
    await axios.post("https://emilonode.sjain.io/post/countbestcliplike", { bestclipId: bestClipId }, config )
    .then((res) => {
      // console.log(res.data);
      setLikeCount(res.data.bestclipCount);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    getBestClipLikeCount(bestClipId, config);
  }, [likeCount]);

  // To handle like or dislike
  const handleBestClipLike = async (bestClipId, config) => {
    const action = like ? "like" : "dislike";

    await axios.post( "https://emilonode.sjain.io/post/likebestclip", { bestclipId: bestClipId, action: action }, config )
    .then((res) => {
      // console.log(res.data);
      setLike(!like);

      if (like) {
        setLikeCount(likeCount + 1);
      } else {
        setLikeCount(likeCount - 1);
      };

      // For notification
      axios.post('https://emilonode.sjain.io/user/createemnotification', { bestclipId: bestClipId, action: action, type: 'like' }, config)
      .then(res => {
        // console.log(res);

        let notificationData = {
          type: res.data.type,
          action: res.data.action,
          senderUserId: res.data.senderUserId && res.data.senderUserId,
          receiverUserId: res.data.receiverUserId && res.data.receiverUserId,
          message: res.data.message && res.data.message,
          createdAt: res.data.createdTime && res.data.createdTime,
          fname: res.data.fname && res.data.fname,
          bestclipId: res.data.bestclipId && res.data.bestclipId,
          postImg: res.data.postImg && res.data.postImg,
          profileImg: res.data.profileImg && res.data.profileImg,
          tumbnailImg: res.data.tumbnailImg && res.data.tumbnailImg,
        }

        socket.emit("send_message_emilo_notification", notificationData);
        // console.log(notificationData);
      })
      .catch(err => {
        console.log(err);
      });
    })
    .catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      {
        like
        ?
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <img className="likeBestClipBtn" src={`${BASE_URL_STATIC}/icon/like-1.svg`} alt="comment" onClick={() => handleBestClipLike(bestClipId, config)} style={{ marginRight: "5px", cursor: 'pointer' }} />
          <span>{likeCount}</span>
        </div>
        :
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <img className="likeBestClipBtn" src={`${BASE_URL_STATIC}/icon/like-2.svg`} alt="comment" onClick={() => handleBestClipLike(bestClipId, config)} style={{ marginRight: "5px", cursor: 'pointer' }} />
          <span>{likeCount}</span>
        </div>
      }
    </>
  );
};

export default LikeBestClipButton;
