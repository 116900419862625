import React from 'react'

const Addsong = () => {
  return (
    <div>
      <h6>Add song</h6>
    </div>
  )
}

export default Addsong
