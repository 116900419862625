import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessDealerPopup = () => {

  const { 
    showDealerPopup, setShowDealerPopup, 
    dealer, setDealer,
    createDealer,
  } = useContext(businessContext);
 
  return (
    <>
      <Modal show={showDealerPopup} onHide={() => setShowDealerPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Dealer</h4>

          <small className="form-label text-secondary">Dealer Name</small>
          <input type="text" className="form-control border-0 border-bottom shadow-none mb-4" name='name' placeholder='Siddharth Dongre...' 
            value={dealer.name} onChange={(e) => setDealer((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className="form-label text-secondary">Dealer Website</small>
          <input type="text" className="form-control border-0 border-bottom shadow-none" name='website' placeholder='www.example.com...' 
            value={dealer.website} onChange={(e) => setDealer((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />
          <div className="form-text text-secondary">Enter website link that have all your dealers details</div>

          <div className='text-center mt-5'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createDealer}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessDealerPopup
