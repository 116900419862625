import React, { useContext, useEffect } from 'react';
import { getValue } from '../../../UseContext/SettingHelper';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import { BASE_URL_STATIC } from '../../../../../config';

const DeviceAndBrowser = () => {

  const { 

    value, setValue,

    handleOptionChange,

  } = useContext(settingContext);

  // To get value
  useEffect(() => {
    // Value
    getValue('deviceAndBrowser', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  const { config } = useContext(ApiContext);

  return (
    <>
      <section className='vh-100 pt-4 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='deviceAndBrowserToggle1'>
        <div className='accordion accordion-flush '>
          {/* Breadcrumb */}
          <nav className='pt-4 pt-lg-5'>
            <ol className="breadcrumb bg-transparent p-0">
              <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
              <li className="breadcrumb-item active" aria-current="page">Device & Browser</li>
            </ol>
          </nav>

          {/* All devices you used to login */}
          <div className='mt-3'>
            <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#contentAndPost">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>All devices you used to Login.</h5>
            </div>

            <div id='contentAndPost' className="accordion-collapse collapse show ms-3 mt-3" data-bs-parent="#deviceAndBrowserToggle1">
              <span>(Device Name, Login Counts, Last Login from Device, View)</span>

              <hr className='hr bg-secondary' />
            </div>
          </div>

          {/* Device Permissions */}
          <div className='mt-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#devicePermissions">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Device Permissions.</h5>
            </div>

            <div id='devicePermissions' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#deviceAndBrowserToggle1">
              {/* Camera */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Camera</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='camera' value={value.camera === 'true' ? 'false' : 'true'} 
                    checked={value.camera === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Contacts */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Contacts</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='contact' value={value.contact === 'true' ? 'false' : 'true'} 
                    checked={value.contact === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Message (SMS) */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Message (SMS)</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='message' value={value.message === 'true' ? 'false' : 'true'} 
                    checked={value.message === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Location */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Location</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='location' value={value.location === 'true' ? 'false' : 'true'} 
                    checked={value.location === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Microphone */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Microphone</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked  name='microphone' value={value.microphone === 'true' ? 'false' : 'true'} 
                    checked={value.microphone === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Photos & Videos */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Photos & Videos</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='photoVideo' value={value.photoVideo === 'true' ? 'false' : 'true'} 
                    checked={value.photoVideo === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              {/* Biometric */}
              <div className='mb-3 d-flex align-items-center'>
                <img src={`${BASE_URL_STATIC}/icon/web-navigation-1.svg`} alt="icons" style={{ height: '17px' }} />

                <h5 className='ms-3 me-auto'>Biometric</h5>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='biometric' value={value.biometric === 'true' ? 'false' : 'true'} 
                    checked={value.biometric === 'true'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} 
                  />
                </div>
              </div>

              <hr className='hr bg-secondary' />
            </div>
          </div>

          {/* Open Link */}
          <div className='mt-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#openLink">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Action on clicking website link over emilo app & web.</h5>
            </div>

            <div id='openLink' className="accordion-collapse collapse ms-3 mt-3 row" data-bs-parent="#deviceAndBrowserToggle1">
              {/* Internal */}
              <div className="form-check ms-1 mb-2">
                <input type="radio" className="form-check-input" id="openLink-internal" name="openLink" value="internal" checked={value.openLink === 'internal' || !value.openLink} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} />
                <label className="form-check-label ms-1" htmlFor="openLink-internal">Open In-APP</label>
              </div>

              {/* External */}
              <div className="form-check ms-1">
                <input type="radio" className="form-check-input" id="openLink-external" name="openLink" value="external" checked={value.openLink === 'external'} onChange={(e) => handleOptionChange(e, 'deviceAndBrowser')} />
                <label className="form-check-label ms-1" htmlFor="openLink-external">Open In External Web Browser</label>
              </div>

              <hr className='hr bg-secondary mt-3 mb-0' />
            </div>
          </div>

          {/* Visits & Browsing History */}
          <div className='mt-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#visitsBrowsing">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Manage Website Visits & Browsing History.</h5>
            </div>

            <div id='visitsBrowsing' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#deviceAndBrowserToggle1">
              <p className='mb-2'>See List of  Website Visited In-App</p>
              <p>Clear Browsing History (it will clear data you visited)</p>

              <hr className='hr bg-secondary' />
            </div>
          </div>

          {/* Auto Fill Forms */}
          <div className='mt-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#fillForm">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Allow emilo to auto fill forms.</h5>
            </div>

            <div id='fillForm' className="accordion-collapse collapse ms-3 mt-3" data-bs-parent="#deviceAndBrowserToggle1">
              <span className='mb-2 mb-lg-3'>(While visiting any Ad link on EMILO In-App you may be asked by the website owner or Advertiser to fill a form for completing any required action. If you wish to allow auto fill these forms please fill below details which you allow us to auto fill in the form)</span>

              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='Name...' />
              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='Address...' />
              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='City...' />
              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='Contact No...' />
              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='Mail ID...' />
              <input type="text" className='form-control mb-2 mb-lg-3' placeholder='Payment Method...' />

              <hr className='hr bg-secondary' />
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default DeviceAndBrowser
