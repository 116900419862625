import React from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../../config';

const VoiceCallGoingPopup = ({ showVoiceCallPopup, setShowVoiceCallPopup, chatProfile, turnOnMicrophone, leaveChannel }) => {

  // const [isMute, setIsMute] = useState(false);

  // To handle voice call mute
  // const handleVoiceCallMute = () => {
  //   turnOnMicrophone(isMute);
  //   setIsMute(!isMute);
  // };

  // To handle voice call end
  // const handleVoiceCallEnd = () => {
  //   leaveChannel();
  //   setShowVoiceCallPopup(false);
  // };

  console.log("chatProfile",chatProfile);
  

  return (
    <>
      <section className='voiceCallPopups'>
        <Modal show={showVoiceCallPopup} onHide={() => setShowVoiceCallPopup(false)} className="main-modal voiceCallPopup-modal" size="md" centered backdrop="static" keyboard={false}  >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body voiceCallPopup-modal-body">
            <section className='voiceCallPopup'>
              <div className='voiceCallPopup-logo'>
                <img src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
              </div>

              <div className='voiceCallPopup-profile'>
                <img className='voiceCallPopup-profile-img' src="https://img.indiaforums.com/person/480x360/1/1581-kiara-advani.jpg?c=8xOB8D" alt="profile" />
              </div>

             {chatProfile && <h3 className='voiceCallPopup-name'>{chatProfile.groupName || chatProfile.fname}</h3>}

              <span className='voiceCallPopup-calling'>Calling...</span>

              <div className='voiceCallPopup-btns'>
                  {
                    // isMute
                    false
                    ?
                    // <div className='voiceCallPopup-unmute' onClick={handleVoiceCallMute}>
                    <div className='voiceCallPopup-unmute'>
                      <img src={`${BASE_URL_STATIC}/icon/microphone-slash.svg`} alt="microphone" />
                    </div>
                    :
                    // <div className='voiceCallPopup-mute' onClick={handleVoiceCallMute}>
                    <div className='voiceCallPopup-mute'>
                      <img src={`${BASE_URL_STATIC}/icon/microphone.svg`} alt="microphone" />
                    </div>
                  }
                
                {/* <div className='voiceCallPopup-cut' onClick={handleVoiceCallEnd}> */}
                <div className='voiceCallPopup-cut'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneCut.svg`} alt="phoneCut" />
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default VoiceCallGoingPopup
;
