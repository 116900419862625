import React, { useContext, useState, useMemo } from "react";
import { ApiContext } from "../../../../UseContext/ApiCall";
import Modal from 'react-bootstrap/Modal';
import PhotoVideoTags from "./PhotoVideoTags";
import GetThumbnail from "../../../../ThumbnailExtractor/GetThumbnails";

const StoryPhotoVideoPopup = () => {

  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([])
  const [videoIndex, setIndex] = useState(null)
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState(false);

  const {
    showAddToStoriesBtn,
    photoVideos, setPhotoVideos,
    showStoryPhotoVideoPopup, setShowStoryPhotoVideoPopup,
    createUserStories,
    setshowCreateStoriesPopup,
    setShowStoriesAdvanceSettingPopup,
    setStoriesAdvanceSetting,
    config
  } = useContext(ApiContext);

  // To handle create stories
  const handleCreateStories = async () => {
    await photoVideos.forEach((file, i) => {
      const formData = new FormData();
      formData.append('mediaUrls', file.file);
      formData.append('postTumbnailUrl', selectedImage)
      createUserStories(formData, 'post', tagsByCarouselItem[i] , "");
    });

    setShowStoryPhotoVideoPopup(false);
    // setPhotoVideos([]);

    setStoriesAdvanceSetting({
      visibility: 'public',
      expire: '24',
      like: 'on',
      comment: 'on',
    });
  };

  useMemo(() => {
    if (Array.isArray(photoVideos)) {
      // Map through photoVideos and create object URLs for each file
      const urls = photoVideos.map((item) => {
        if (item.file) {
          return URL.createObjectURL(item.file);
        }
        return null; // Handle cases where item.file might be missing
      }).filter(Boolean); // Remove any null values


      // Find the index of the first video file in photoVideos
      const index = photoVideos.findIndex((item) => item.file && item.file.type.includes("video"));
      setIndex(index);

      ;

      // Cleanup function for revoking object URLs
      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }
  }, [photoVideos]);


  return (
    <>
      <Modal show={showStoryPhotoVideoPopup} onHide={() => setShowStoryPhotoVideoPopup(false)} centered backdrop="static" keyboard={false} className="create-call create-stories-popup" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className='p-3'>
          {/* Header */}
          <div className='d-flex mt-3'>
            <span className='cursor-pointer' onClick={() => { setShowStoryPhotoVideoPopup(false); setshowCreateStoriesPopup(true) }}>⮜</span> 
            <h5 className='mb-0 mx-auto fw-semibold'>Add to Stories</h5>
          </div>

          <PhotoVideoTags setShowStoryPhotoVideoPopup={setShowStoryPhotoVideoPopup} config={config} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} setPhotoVideos={setPhotoVideos} photoVideos={photoVideos} />

          <div className="d-none">
            {videoIndex >= 0 && <GetThumbnail videoIndex={videoIndex} selectedImage={selectedImage} setSelectedImage={setSelectedImage} file={photoVideos[videoIndex] && photoVideos[videoIndex].file} status={status} setStatus={setStatus} />}
          </div>


          {/* Button */}
          <div className="text-center mt-4">
            <button type="button" className="btn btn-primary w-50 rounded-5" disabled={photoVideos.length === 0} onClick={handleCreateStories}>{showAddToStoriesBtn ? 'Adding' : 'Add to Stories'}</button>
          </div>

          {/* Advance Setting */}
          <div className="text-center">
            <small className="text-primary cursor-pointer" onClick={() => {
              setShowStoriesAdvanceSettingPopup(true);
            }}>Advance Setting</small>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryPhotoVideoPopup;