import React, { useState, useContext } from 'react';
import ProfileUser from '../../Profile/ProfileUser/ProfileUser';
import Navbar from '../Navbar/Navbar';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import { useEffect } from 'react';
import {BASE_URL_STATIC } from '../../../../config';

import { Link } from 'react-router-dom';
import FavouritePosts from './FavouritePosts';
import FavouriteReels from './FavouriteReels';
import FavouriteBestClips from './FavouriteBestClips';
import FavouriteMemes from './FavouriteMemes';

const Favourite = () => {
  const [SelectedSection, setSelectedSection] = useState(null)
  const [favouritePosts, setFavouritePosts] = useState([]);
  const [favouriteReels, setFavouriteReels] = useState([]);
  const [favouriteBestClips, setFavouriteBestClips] = useState([]);
  const [favouriteMemes, setFavouriteMemes] = useState([]);
  const { config} = useContext(ApiContext);

  // To get favourite posts
  const getFavouritePosts = (config) => {
    axios.post('https://emilonode.sjain.io/post/getfevpost', {}, config)
    .then(res => {
      console.log(res.data.fvPost);
      setFavouritePosts(res.data.fvPost);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouritePosts(config);
  }, []);

  // To get favourite reels
  const getFavouriteReels = (config) => {
    axios.post('https://emilonode.sjain.io/post/getfavreel', {}, config)
    .then(res => {
      // console.log(res.data.reelLikeCount);
      setFavouriteReels(res.data.reelLikeCount);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteReels(config);
  }, []);

  // To get favourite bestClips
  const getFavouriteBestClips = (config) => {
    axios.post('https://emilonode.sjain.io/post/getfavbestclip', {}, config)
    .then(res => {
      // console.log(res.data.listBestclip);
      setFavouriteBestClips(res.data.listBestclip);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteBestClips(config);
  }, []);

  // To get favourite memes
  const getFavouriteMemes = (config) => {
    axios.post('https://emilonode.sjain.io/post/getfevmeme', {}, config)
    .then(res => {
      // console.log(res.data.listMeme);
      setFavouriteMemes(res.data.listMeme);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteMemes(config);
  }, []);

 

  return (
    <>
      <section className='favourite'>
        <ProfileUser/>
       

        {SelectedSection === 1 ?(<FavouritePosts />) : SelectedSection === 2 ? (<FavouriteReels/>) :  SelectedSection === 3 ? (<FavouriteBestClips />) :
        SelectedSection === 4 ? (<FavouriteMemes/>) :
        
        
        
        ( 
          <>
        <Navbar />

        {/* Favourite */}
        <section className='favourite-show'>
          {/* To show recent favourite posts */}
          <Link className='favourite-show-posts favourite-show-div' onClick={() => setSelectedSection(1)}>
            <h3 className='favourite-show-posts-head favourite-show-head'>Posts</h3>
            
            {
              favouritePosts
              &&
              favouritePosts.map((favouritePost, index) => (
                ((favouritePosts.length - 1) === index)
                ?
                  // console.log(favouritePost.details[0])
                  favouritePost.details[0].imageId
                  ?
                  <img className='favourite-show-posts-img favourite-show-img' src={favouritePost.details[0].imageUrl} alt={favouritePost.details[0].imageId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-1.png`} />
                  :
                  <video className='favourite-show-posts-video favourite-show-video'>
                    <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${favouritePost.details[0].videoId}`} />
                  </video>
                :
                ''
              ))
            }
          </Link>

          {/* To show recent favourite reels */}
          <Link className='favourite-show-reels favourite-show-div'  onClick={() => setSelectedSection(2)}>
            <h3 className='favourite-show-reels-head favourite-show-head'>Flix</h3>
            {
              favouriteReels
              &&
              favouriteReels.map((favouriteReel, index) => (
                ((favouriteReels.length - 1) === index)
                &&
                // console.log(favouriteReel)
                <img className='favourite-show-reels-img favourite-show-img' src={favouriteReel.tumbnailUrl} alt={favouriteReel.tumbnailId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-2.png`} height={215} width={200} />
                
              ))
             
            }
          </Link>

          {/* To show recent favourite bestClips */}
          <Link  className='favourite-show-bestClips favourite-show-div'  onClick={() => setSelectedSection(3)}>
            <h3 className='favourite-show-bestClips-head favourite-show-head'>BestClips</h3>
            {
              favouriteBestClips
              &&
              favouriteBestClips.map((favouriteBestClip, index) => (
                ((favouriteBestClips.length - 1) === index)
                &&
                // console.log(favouriteBestClip)
                <img className='favourite-show-bestClips-img favourite-show-img' src={favouriteBestClip.tumbnailUrl} alt={favouriteBestClip.tumbnailId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-3.png`} height={215} width={200} />
                
              ))
              
            }
          </Link>

          {/* To show recent favourite memes */}
          <Link className='favourite-show-memes favourite-show-div'  onClick={() => setSelectedSection(4)}>
            <h3 className='favourite-show-memes-head favourite-show-head'>Memes</h3>
            {
              favouriteMemes
              &&
              favouriteMemes.map((favouriteMeme, index) => (
                ((favouriteMemes.length - 1) === index)
                ?
                  // console.log(favouriteMeme.details[0])
                  favouriteMeme.details[0].imageId
                  ?
                  <img className='favourite-show-memes-img favourite-show-img' src={favouriteMeme.details[0].imageUrl} alt={favouriteMeme.details[0].imageId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-4.png`} height={215} width={200} />
                  :
                  <video className='favourite-show-memes-video favourite-show-video' height={215} width={200}>
                    <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${favouriteMeme.details[0].videoId}`} />
                  </video>
                :
                ''
              ))
            }
          </Link>
        </section>
        </>
        )}
      </section>
    </>
  );
};

export default Favourite;
