import React from "react";
import { NavLink } from "react-router-dom";

const Error = () => {
  return (
    <>
      <section className="error-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="errorBox">
                <h1>
                  4<span>0</span>4
                </h1>
                <h2>oops... page not found</h2>
                <NavLink to="/" className="main-btn">
                  Back To Home
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error;
