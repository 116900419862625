import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../../UseContext/ApiCall";
import { BASE_URL_STATIC, AwsFileUrl } from "../../../../config";

import { settingContext } from "../../UseContext/SettingContext";
import { getPostData } from '../../UseContext/ApiHelpers'
import ProfilePostPopup from "../../Popup/Profile/ProfileShow/ProfilePostPopup";
import { useParams } from "react-router-dom";

const ProfileBestClips = () => {
  const {  isBlocked, config } = useContext(ApiContext);
  const { isOtherUserAccountPrivate } = useContext(settingContext);
  const [postData, setpostData] = useState([]);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData , setmainpoastData] = useState('')
  
  useEffect(() => {
    getProfileData();
  }, [])

  const { paramsUserId } = useParams();


  const getProfileData = async () => {
    try {
      const responsedata = await getPostData( paramsUserId,'bestclips', config)
      console.log("responsedataapi", responsedata.data);
      setpostData(responsedata)
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />
      <section className='ml-md-5 pl-md-5'>
      <div className="col-12 col-lg-10 col-xl-8 mx-auto p-0 row text-center d-flex">
        {
          !isBlocked
            ?
            isOtherUserAccountPrivate
              ?
              postData
                ?
                postData.map((item) => (
                  <div key={item._id} className='px-0 col-2 mx-1 mb-1 mx-lg-2 mb-lg-3'>
                   <div style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '200px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden' }}>
                      {item.postTumbnailUrl && (
                        <img src={`${AwsFileUrl + item.postTumbnailUrl}`} alt={item.title} className='img-fluid' style={{ height: '100%', maxWidth: '100%' , objectFit : "cover" }}  onClick={() =>{setShowPostsPopup(!showPostsPopup); setmainpoastData(item); setPostId(item._id)}}  />
      
                      )}
      
                    </div>
      
      
                  </div>
                ))
                :
                <div className='w-100 d-flex justify-content-center'>
                  <span className="loaderMain"></span>
                </div>
              :
              <section className="profileBestClips-private">
                <div className="profileBestClips-private-img">
                  <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountPrivacy" />
                </div>

                <span className="profileBestClips-private-head">Account is private</span>
              </section>
            :
            <section className="profilePost-block">
              <div className="profilePost-block-img">
                <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountBlock" />
              </div>

              <span className="profilePost-block-head">Account is Blocked</span>
            </section>
        }

        {
         
        }
      </div>
      </section>
    </>
  );
}

export default ProfileBestClips;
