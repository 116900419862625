import React, { useState } from "react";
import { profileNav } from "../../../Data/EducationData";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_STATIC } from "../../../../../config";
// import { ApiContext } from "../../../UseContext/ApiCall";



const ProfileUserSettingNav = () => {
  
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  // const [profileStyle, setProfileStyle] = useState(true);


  const userId = localStorage.getItem("profileUserId");

  const params = useParams();

  const navigate = useNavigate();

  // const { loggedInUserName, loggedInUserImage } = useContext(ApiContext);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    // setProfileStyle(false);

    if (profileNav[index].route === "web-navigation") {
      navigate(`/profile/${userId}/profile-setting/${profileNav[index].route}`);
    }
  };

  return (
    <>
      <section className="vh-100 overflow-scroll scrollbar-hidden pt-4 pt-md-5 px-1 px-md-2">
        {/* Search Bar */}
        <div className='d-flex rounded-pill bg-light shadow-sm mt-5 mb-3 mb-md-4 mx-3 mx-sm-4 mx-md-5 mx-lg-4 mx-xl-5'>
          <img className='ps-4 pe-2 rounded-pill' src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          <input type="search" className='form-control rounded-pill border-0 shadow-none bg-transparent' onKeyPress={(e) => { e.key === 'Enter' && navigate(`#${e.target.value}`) }} placeholder='Search Users...' />
        </div>

        {/* Profile */}
        {/* <div className={`${profileStyle ? "selected" : ""} d-flex align-items-center px-3 px-sm-4 px-md-5 px-lg-4 px-xl-5 py-3 cursor-pointer`} 
          onClick={() => { setProfileStyle(true); setSelectedItemIndex(10); navigate(`/profile/${userId}/profile-setting/account-profile`); }}
        >
          <img className="rounded-circle border border-white" style={{ height: '45px', width: '45px' }} src={loggedInUserImage} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />

          <div className="ms-3 ms-xl-4 me-auto">
            <h5>Profile</h5>
            <span className={`${profileStyle ? "text-white" : "text-dark"}`}>{loggedInUserName}</span>
          </div>

          <span>&#11166;</span>
        </div> */}

        {/* <div className="px-3 px-sm-4 px-md-5 px-lg-4 px-xl-5 text-secondary">Manage settings of your profile like posts, message, privacy, notifications and other settings.</div> */}

        {/* <hr className="hr" /> */}

        {/* Options */}
        <>
          {
            profileNav.map((item, index) => (
              <div key={index} className={`${index === selectedItemIndex ? "selected" : ""} d-flex px-3 px-sm-4 px-md-5 px-lg-4 px-xl-5 py-3 cursor-pointer`} 
                onClick={() => { handleItemClick(index); navigate(`/profile/${params.paramsUserId}/profile-setting/${item.route}`); }}
              >
                <img src={index === selectedItemIndex ? item.icon2 : item.icon1} alt="icon" />

                <div className="ms-3 ms-xl-4">
                  <h5>{item.name}</h5>
                  <small className={`${index === selectedItemIndex ? 'text-white' : 'text-secondary'}`}>{item.description}</small>
                </div>
              </div>
            ))
          }
        </>

        <hr className="hr" />

        {/* Footer */}
        <div className="d-flex flex-column px-3 px-sm-4 px-md-5 px-lg-4 px-xl-5">
          <span className="mb-2">Privacy Policy</span>
          <span className="mb-2">Terms of service</span>
          <span className="mb-2">Cookies policy</span>
          <span className="mb-2">About</span>
          <span className="mb-2">Copyright &copy;2023</span>
          <span className="mb-2">Version 1.0.00</span>
        </div>
      </section>
    </>
  );
};

export default ProfileUserSettingNav;
