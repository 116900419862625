import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';

const TaggedPosts = () => {

  const [taggedPosts, setTaggedPosts] = useState([]);
  const [showTaggedPostsPopup, setShowTaggedPostsPopup] = useState(false);
  const [taggedPostPopupData, setTaggedPostPopupData] = useState();

  const { config, loggedInUserId } = useContext(ApiContext);

  // console.log(taggedPosts);

  // To get tagged posts
  const getTaggedPosts = (loggedInUserId, config) => {
    axios.post('https://emilonode.sjain.io/post/metaggedpost', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data.tagedPost);
      setTaggedPosts(res.data.tagedPost);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedPosts(loggedInUserId, config);
  }, [loggedInUserId]);

  // To handle tagged post popup
  const handleTaggedPostPopup = (taggedPost) => {
    setShowTaggedPostsPopup(true);
    setTaggedPostPopupData(taggedPost);
  };

  return (
    <>
      {/* Tagged Photos And Videos Popup */}
      <ProfilePostPopup showPostsPopup={showTaggedPostsPopup} setShowPostsPopup={setShowTaggedPostsPopup} postsPopupData={taggedPostPopupData} />

      <section className='tagged-pav'>
        <h1 className='tagged-pav-head'>Photos And Videos</h1>

        <div  className='tagged-pav-show'>
          {
            taggedPosts
            ?
            taggedPosts.map((taggedPost, index) => (
              // console.log(taggedPost.details)
              taggedPost.details[0].imageUrl
              ?
              <img src={taggedPost.details[0].imageUrl} alt={taggedPost.details[0].imageId} height={215} width={200} onClick={() => handleTaggedPostPopup(taggedPost)} />
              :
              <video height={215} width={200} autoPlay onClick={() => handleTaggedPostPopup(taggedPost)}>
                <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${taggedPost.details[0].videoId}`} />
              </video>
            ))
            :
            ''
          }
        </div>
      </section> 
    </>
  );
};

export default TaggedPosts;
