import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { getWorkDetails } from "./ApiHelpers";


export const profileContext = createContext();

export const ProfileProvider = ({ children }) => {
        const [userProfile, setUserProfile] = useState("")
        const [saveLoader, setSaveLoader] = useState(false)
        const [address, setAddress] = useState([])
        const [hangoutDetails, setHangoutDetails] = useState([])
        const [skillData, setSkillData] = useState([])
        const [social, setSocial] = useState([])
        const [workData, setWorkData] = useState([])
        const [zodiacVal, setzodiacVal] = useState('')
        const [educationDetails, setEducationDetails] = useState([])
        const [selectedHobbies, setSelectedHobbies] = useState([]);
        const [bioInput, setBioInput] = useState('')
        const [movieDetails, setMovieDetails] = useState([])
        const [foodDetails, setfoodDetails] = useState([])
        const [relationsData, setRelationsData] = useState([])
        const [workInput, setworkInput] = useState({ companyName: "", startDate: '', endDate: '', currentWorking: '', about: '', jobTitle: '', campanyLocation: '', workType: '' })


        const [profilePostData , setProfilePostData] = useState("")
        const [profileReelData , setProfileReelData] = useState("")
        const [profileMemeData , setProfileMemeData] = useState("")
        const [profileBestclipData , setProfileBestclipData] = useState("")


        useEffect(() => {
                getWorkDetails()
        }, [])


        const myProfile = async () => {
                const res = await axios.post('https://emilonode.sjain.io/user/myprofile',
                        {
                                userId: localStorage.getItem('profileUserId')
                        },
                        {
                                headers: {
                                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                                }
                        })

                return res.data.user
        }

        const value = {
                social, setSocial,
                 saveLoader, setSaveLoader,
                 address, userProfile, setUserProfile,
                 setAddress,
                 educationDetails, setEducationDetails,
                 zodiacVal, setzodiacVal,
                 bioInput, selectedHobbies, setSelectedHobbies,
                 setBioInput,
                 workData, setWorkData,
                hangoutDetails, setHangoutDetails,
                 skillData, setSkillData,
                 myProfile, workInput, setworkInput,
                 movieDetails, setMovieDetails,
                 foodDetails, setfoodDetails,
                 relationsData, setRelationsData,
                 profilePostData , setProfilePostData,
                 profileReelData , setProfileReelData,
                 profileBestclipData , setProfileBestclipData,
                 profileMemeData , setProfileMemeData,

        }


        return <profileContext.Provider value={value}>{children}</profileContext.Provider>
}