import React from 'react'
import { Modal } from 'react-bootstrap'
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton'
import { AwsFileUrl, BASE_URL_STATIC, BASEURL } from '../../../../../config'
import { Link } from 'react-router-dom'

const PostTagModel = ({ tagOpen, setTagOpen, mediaTags }) => {
    

    return (
        <>
        <Modal show={tagOpen} onHide={() => setTagOpen(false)} centered backdrop="static" keyboard={false} >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                    {mediaTags && mediaTags.length > 0 && 
                        mediaTags.map((tag, index) => (
                            <div
                                className="tagProfilePopUp"
                                key={index}
                            >
                                <Link to={`${BASEURL}/profile/${tag.tagUserId}`}>
                                    <img src={tag.profileImg ? (AwsFileUrl + tag.profileImg.imageId) : `${BASE_URL_STATIC}/dummy.png`} alt="profileImage" />
                                    <small>{tag.fname}</small>
                                </Link>
                                <div>
                                    <FollowUnfollowButton otherUserId={tag.tagUserId} />
                                </div>
                            </div>
                        ))}

            </Modal.Body>
        </Modal>
        </>
    )
}

export default PostTagModel