import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { ApiContext } from "../../UseContext/ApiCall";
import { checkEmail, checkNumber } from "../../UseContext/ApiHelpers";
import { BASEAPIURL, BASE_URL_STATIC } from "../../../../config";
import axios from "axios";
import toast from "react-hot-toast";

const EmailandMobile = () => {
  const { setUserInfo, userInfo, sg_isMobileVerified, sg_isEmailVerified } = useContext(ApiContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [numberMessage, setNumberMessage] = useState(false);
  const [disableOTP, setdisableOTP] = useState(false);
  const [disableEmail, setdisableEmail] = useState(false);

  console.log("numberMessage" , numberMessage , disableOTP)

  function handleChange(e) {
    const { name, value } = e.target;

    setUserInfo((prevUser) => ({ ...prevUser, [name]: value }));

    if (value.includes("@") && value.includes(".")) {
      checkEmail(value).then((res) => {
        if (res === "email already taken") {
          if (value) {
            setEmailMessage("email already registered");
            setdisableEmail(true);
          } else {
            setEmailMessage("");
            setdisableEmail(false);
          }
        } else {
          setEmailMessage(null);
          setdisableEmail(false);
        }
      });
    } else {
      setEmailMessage("");
    }
  }

  function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  async function handlePhoneChange(value) {
    setPhoneNumber(value);

    setUserInfo((prevUser) => ({ ...prevUser, phoneNumber: value }));

    checkNumber(value).then((res) => {
      if (res === "mobile number already register") {
        if (phoneNumber && phoneNumber.length > 9) {
          setNumberMessage("mobile number already registered");
          setdisableOTP(true);
        } else {
          setNumberMessage("");
          setdisableOTP(false);
        }
      } else {
        setNumberMessage("");
        setdisableOTP(false);
      }
    });
  }

  const nav = useNavigate();

  const sendEmailotp = async () => {
    if (!isValidEmail(userInfo.email)) {
      toast.error("Enter valid email");
    } else {
      try {
        userInfo.email &&
          await axios.post(`${BASEAPIURL}/user/otp-signupmail`, { email: userInfo.email }).then((res) => {
            toast.success(res.data.message);
            nav("/signup/CodeVerificationEmail");
          });
      } catch (error) {
        toast.error("Failed to send otp");
      }
    }
  };

  const sendMobileotp = async () => {
    if (phoneNumber.length < 13 || phoneNumber.length > 13) {
      toast.error("Enter valid mobile number");
    } else {
      try {
        phoneNumber &&
          await axios.post(`${BASEAPIURL}/user/otp-send`, { mobile: phoneNumber }).then((res) => {
            toast.success(res.data.message);
            nav("/signup/codeverification");
          });
      } catch (error) {
        toast.error("Failed to send otp");
      }
    }
  };

  const handleNext = async () => {
    if (!sg_isEmailVerified) {
      toast.error("Please verify your email first.");
      return; // Prevent navigation if email is not verified
    }

    if (!sg_isMobileVerified) {
      toast.error("Please verify your mobile number first.");
      return; // Prevent navigation if mobile number is not verified
    }

    sessionStorage.setItem("email", userInfo.email);
    nav("/signup/createpassword");
  };

  return (
    <section className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100 ">
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/EnterYourEmail.png`} alt="EnterYourEmail" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0">
        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <form className="w-75 mx-auto">
          <div className="fs-1 mb-3 mb-lg-5">Enter your Email id & Mobile?</div>

          {/* Email */}
          <div className="position-relative">
            <input
              type="email"
              readOnly={sg_isEmailVerified}
              defaultValue={userInfo.email}
              className="form-control form-control-lg rounded-pill"
              onChange={handleChange}
              name="email"
              placeholder="user@email.com"
            />
            {sg_isEmailVerified ? (
              <span className="position-absolute top-50 end-0 translate-middle-y me-3 fs-6 text-success" style={{ cursor: 'pointer' }}>
                verified
              </span>
            ) : (
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 fs-6 text-primary"
                onClick={!disableEmail && sendEmailotp}
                style={{ cursor: 'pointer' }}
              >
                Send OTP
              </span>
            )}
          </div>

          <small className="text-danger ms-3">{emailMessage}</small>

          {/* Number */}
          <div className="position-relative">
            <PhoneInput
              className="fs-6"
              readOnly={sg_isMobileVerified}
              style={{ fontSize: "16px" }}
              value={userInfo.phoneNumber}
              onChange={handlePhoneChange}
              defaultCountry="IN"
              placeholder="Enter phone number"
              limitMaxLength
            />

            {sg_isMobileVerified ? (
              <span className="position-absolute top-50 end-0 translate-middle-y me-3 fs-6 text-success" style={{ cursor: 'pointer' }}>
                verified
              </span>
            ) : (
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 fs-6 text-primary"
                // onClick={!disableOTP && sendMobileotp}
                onClick={sendMobileotp}  // removed mobile restriction
                style={{ cursor: 'pointer' }}
              >
                Send OTP
              </span>
            )}
          </div>

          {/* <div className="text-danger ms-4">{numberMessage}</div> */}

          <button
            type="button"
            className="w-100 btn btn-primary rounded-pill py-1 py-md-2 fs-5 mt-5"
            onClick={handleNext}
            disabled={!(sg_isEmailVerified && sg_isMobileVerified)} // Disable if either is not verified
          >
            Next
          </button>
        </form>
      </section>
    </section>
  );
};

export default EmailandMobile;
