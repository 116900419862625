import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import ProfileBestClipPopup from '../../Popup/Profile/ProfileShow/ProfileBestClipPopup';

const TaggedBestClips = () => {

  const [taggedBestClips, setTaggedBestClips] = useState([]);
  const [showTaggedBestClipPopup, setShowTaggedBestClipPopup] = useState(false);
  const [taggedBestClipPopupData, settaggedBestClipPopupData] = useState()

  const { config, loggedInUserId } = useContext(ApiContext);

  // To get tagged Best Clips
  const getTaggedBestClips = () => {
    axios.post('https://emilonode.sjain.io/post/metaggedbestclip', { userId: loggedInUserId }, config)
    .then(res => {
      // console.log(res.data.listBestclip);
      setTaggedBestClips(res.data.listBestclip);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getTaggedBestClips(loggedInUserId, config);
  }, [loggedInUserId]);

  // To handle tagged best clip
  const handleTaggedBestClipPopup = (taggedBestClip) => {
    setShowTaggedBestClipPopup(true);
    settaggedBestClipPopupData(taggedBestClip);
  };

  return (
    <>
      {/* Tagged Best Clips */}
      <ProfileBestClipPopup showProfileBestClipPopup={showTaggedBestClipPopup} setShowProfileBestClipPopup={setShowTaggedBestClipPopup} profileBestClipPopupData={taggedBestClipPopupData} />

      <section className='tagged-bestClips'>
        <h1 className='tagged-bestClips-head'>Best Clips</h1>

        <div  className='tagged-bestClips-show'>
          {
            taggedBestClips
            &&
            taggedBestClips.map((taggedBestClip, index) => (
              // console.log(taggedBestClip.details[0].videoId)
              taggedBestClip.details[0].videoId
              &&
              <video height={215} width={200} autoPlay onClick={() => handleTaggedBestClipPopup(taggedBestClip)}>
                <source src={`https://emilonode.sjain.io/post/getvideobyid?bestclipVideoId=${taggedBestClip.details[0].videoId}`} />
              </video>
            ))
          }
        </div>
      </section> 
    </>
  )
}

export default TaggedBestClips
