import React, { useRef, useEffect, useContext, useState, useMemo } from "react";
import { ApiContext } from "../UseContext/ApiCall";
import "../Reels/Reels.css";
// import { AwsFileUrl, BASE_URL_STATIC } from "../../../config";
import { postEvent } from "../UseContext/ApiHelpers";
import VideoTag from "../../Common/assets/videoTag";

const ViewReelCount = ({ postedBy, reelId, showPostsPopup }) => {

  const refObs = useRef();
  const { config } = useContext(ApiContext);
  const videoRef = useRef();
  const [mute, setMute] = useState(true)
  const [play, setPlay] = useState(true)

  const handleVisibilityChange = async (reelId, isVisible) => {
    if (isVisible && reelId) {
      postEvent(reelId, "view", postedBy, "flix", config)
      if (videoRef.current) {
        videoRef.current.play();
        setMute(false)
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
        setMute(true)
      }
    }
  };

  useEffect(() => {
    if (showPostsPopup) {
      if (videoRef.current) {
        videoRef.current.pause();
        setMute(true)
      }
    }
  }, [showPostsPopup])


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          handleVisibilityChange(reelId, entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (refObs.current) {
      observer.observe(refObs.current);
    }

    return () => {
      if (refObs.current) {
        observer.unobserve(refObs.current);
      }
    };
  }, [reelId]);

  const handlePlayPause = () => {
    if(videoRef.current){

      if (play) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }

  useMemo(() => {
    handlePlayPause()
  }, [play])

  return (
    <div className="reelCount" ref={refObs}>
      <VideoTag
        url={reelId}
        classHere="reelsVideo"
        mute={mute}
        videoRef={videoRef}
        onClick={() => setPlay(!play)}
      />
      {/* <video className="reelsVideo" autoPlay loop muted={mute} ref={videoRef} onClick={() => setPlay(!play)}>
        <source src={AwsFileUrl + reelId} onError={(e) => e.target.src = `${BASE_URL_STATIC}/videos/videoCall-1.mp4`} />
      </video> */}
    </div>
  );
};

export default ViewReelCount;
