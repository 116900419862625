import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { BASEAPIURL, BASE_URL_STATIC } from "../../../../config";
import Countdown from 'react-countdown-now';
import { ApiContext } from "../../UseContext/ApiCall";
import axios from "axios";
import toast from "react-hot-toast";

const CodeVerificationEmail = () => {

  const [OTP, setOTP] = useState("");
  const [btnDisable, setbtnDisable] = useState(true)
  const [resendTimer, setResendTimer] = useState(59);

  const { userInfo,  set_sg_isEmailVerified } = useContext(ApiContext)

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <span style={{cursor : "pointer"}} onClick={() => reSendOtp()}>Resend OTP</span>;
    } else {
      return <span style={{opacity : 0.75}}> {`Resend OTP in 00 : ${seconds < 10 ? `0${seconds}` : seconds}`}</span>;
    }
  };

  const reSendOtp = async () => {
    try {
      userInfo.email  &&
      await axios.post(`${BASEAPIURL}/user/otp-send`, { email:userInfo.email  }).then((res) => {
          toast.success(res.data.message)
          setResendTimer(59)
        })
    } catch (error) {
      toast.error("failed to send otp")
    }
  };

  const nav = useNavigate();

  useEffect(() => {
    if (OTP.length < 4) {
      setbtnDisable(true)
    } else {
      setbtnDisable(false)
    }
  }, [OTP]);

  const verifyOtp = async () => {
    try {

      await axios.post(`${BASEAPIURL}/user/otp-verify`, { email: userInfo.email , otp: OTP }).then((res) => {
        toast.success(res.data.message)
        set_sg_isEmailVerified(true)
        setbtnDisable(false)
        nav("/signup/emailandmobile")
      })
    } catch (error) {
      toast.error("Failed to verify OTP")

    }
  };

  return (
    <div className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100" >
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/otp.png`} alt="" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0 d-flex flex-column align-items-center">
        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <h2 className="onboardingOTP-area-data-head">Code Verification</h2>

        <p className="onboardingOTP-area-data-message">
          Enter One Time Password Sent to {" "}
          <strong>{userInfo.phoneNumber}</strong>
        </p>

        <OtpInput value={OTP} onChange={setOTP} numInputs={4} renderSeparator={<pre> </pre>}
          renderInput={(props) => (
            <input {...props} className="onboardingOTP-area-data-inputOTP" style={{ width: "70px" }} />
          )}
        />

        <button type="button" className="btn btn-primary w-50 mt-4 mb-2 rounded-pill fs-5" disabled={btnDisable} onClick={verifyOtp}>Next</button>

        <small>Didn’t get the OTP? <b className="text-primary"><Countdown date={Date.now() + resendTimer * 1000} renderer={renderer} /></b></small>
      </section>
    </div>
  );
};

export default CodeVerificationEmail;