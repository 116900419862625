import { useParams } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../../UseContext/ApiCall";
import ProfilePostPopup from "../../Popup/Profile/ProfileShow/ProfilePostPopup";
import { AwsFileUrl } from "../../../../config";
import { settingContext } from '../../UseContext/SettingContext';
import { getPostData } from '../../UseContext/ApiHelpers'

const ProfilePosts = () => {

  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setpostData] = useState([]);
  const [mainpoastData, setmainpoastData] = useState('')

  const { config, profilePost } = useContext(ApiContext);

  const { checkOtherUserAccountPrivacy } = useContext(settingContext);

  const { paramsUserId } = useParams();

  useEffect(() => {
    checkOtherUserAccountPrivacy(paramsUserId, config);
    getProfileData();
  }, [paramsUserId]);


  const getProfileData = async () => {
    try {
      const responsedata = await getPostData(paramsUserId ,'post', config)
      setpostData(responsedata)
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {/* Post image popup */}
      {/* <ProfilePostPopup showPostsPopup={showPostsPopup} postId={postId} setShowPostsPopup={setShowPostsPopup} postsPopupData={postsPopupData} setPostsPopupData={setPostsPopupData} /> */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />
      <section className="col-12 col-lg-10 col-xl-8 mx-auto p-0 row text-center d-flex justify-content-evenly">

        {
          profilePost
            ?
            postData.map((item) => (
              <div key={item._id} className='post-container col-2 mx-1 mb-1 mx-lg-2 mb-lg-3' onClick={() => { setShowPostsPopup(!showPostsPopup); setmainpoastData(item); setPostId(item._id) }}>

                <div >
                  <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '200px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden'
                  }}>
                    {item.mediaUrls[0] && item.mediaUrls[0].filetype === 'video' ?
                      <img
                        src={AwsFileUrl + item.postTumbnailUrl}
                        alt={item.title}
                        className='img-fluid rounded'
                        loading="lazy"
                        style={{ height: '100%', maxWidth: '100%', objectFit : "cover" }}
                      />
                      :
                      <img
                        src={AwsFileUrl + (item.mediaUrls[0] && item.mediaUrls[0].url)}
                        alt={item.title}
                        className='img-fluid rounded'
                        loading="lazy"
                        style={{ height: '100%', maxWidth: '100%' ,  objectFit : "cover"}}
                      />
                    }
                  </div>


                </div>

              </div>
            ))
            :
            <div className='w-100 d-flex justify-content-center'>
              <span className="loaderMain"></span>
            </div>
        }
      </section>
    </>
  );
}

export default ProfilePosts;
