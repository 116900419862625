import React, { useEffect } from 'react';

export const VideoToFramesMethod = {
  fps: 0,
  totalFrames: 1,
};

export const getFrames = async (videoUrl, amount, type = VideoToFramesMethod.fps) => {
  return new Promise((resolve, reject) => {
    let frames = [];
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    let duration;

    let video = document.createElement("video");
    video.preload = "auto";
    const eventCallback = async () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      duration = video.duration;

      for (let i = 1; i <= 6; i++) {
        const time = (i / 6) * duration;
        frames.push(await getVideoFrame(video, context, canvas, time));
      }
      resolve(frames);
    };

    const getVideoFrame = (video, context, canvas, time) => {
      return new Promise((resolve, reject) => {
        const seekedCallback = () => {
          video.removeEventListener("seeked", seekedCallback);
          storeFrame(video, context, canvas, resolve);
        };
        video.addEventListener("seeked", seekedCallback);
        video.currentTime = time;
      });
    };

    const storeFrame = (video, context, canvas, resolve) => {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      resolve(canvas.toDataURL());
    };

    video.addEventListener("loadeddata", eventCallback);
    video.src = videoUrl;
    video.load();

    return () => {
      // Cleanup logic, if needed
    };
  });
};

const VideoToFrames = () => {
  useEffect(() => {
    const videoUrl = 'url_here'; 
    getFrames(videoUrl, 5, VideoToFramesMethod.totalFrames)
  }, []);

  return <div>Your component content here</div>;
};

export default VideoToFrames;
