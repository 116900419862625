import React, { useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall';
import { Link } from "react-router-dom";
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';

const ProfileFollowerPopup = ({ showFollowerPopup, setShowFollowerPopup }) => {

  const [followerSearchUsersList, setFollowerSearchUsersList] = useState([]);

  const { loggedInUserId, followerList } = useContext(ApiContext);

  const handleFollowerSearch = (e) => {
    setFollowerSearchUsersList(followerList.filter((name) => name.fname.includes(e.target.value)));
  };

  return (
    <>
      <section>
        {/* <Modal show={true} onHide={() => setShowFollowerPopup(false)} centered backdrop="static" keyboard={false} className="create-call" > */}
        <Modal show={showFollowerPopup} onHide={() => setShowFollowerPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h6 className='text-center mb-3 fw-bold'>Follower</h6>

          {/* Search */}
          <div className='pfp-search'>
            <form className='pfp-search-form'>
              <img className='pfp-search-img' src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />

              <label htmlFor="followerSearch">
                <input type="text" onChange={handleFollowerSearch} id='followerSearch' placeholder='Search' />
              </label>
            </form>

            <span className='pfp-search-filter ms-2 ms-md-0'>Filter</span>
          </div>

          {
            followerSearchUsersList.length !== 0
            ?
            <div>
              {
                followerSearchUsersList.map((follower, index) => (
                  <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>
                    {
                      follower.imageUrl 
                      ? 
                      <img className='pfp-profile rounded-circle' src={follower.imageUrl} alt={follower.fname} onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                      :
                      <img className='pfp-profile rounded-circle' src={`${BASE_URL_STATIC}/dummy.png`} alt={follower.fname} />
                    }

                    <Link to={`${BASEURL}/profile/${follower.userId}`} className='ms-2 ms-sm-3 me-auto text-dark' onClick={() => setShowFollowerPopup(false)} >
                      <h6 className='pfp-name fw-bold'>{follower.fname}</h6>
                      <small className='text-secondary'>{follower.fname}</small>
                    </Link>

                    {
                      follower.userId !== loggedInUserId 
                      &&
                      <FollowUnfollowButton otherUserId={follower.userId} />
                    }
                  </div>
                ))
              }
            </div>
            : 
            <div>
              {
                followerList
                &&
                followerList.map((follower, index) => (
                  <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>
                    {
                      follower.imageUrl 
                      ? 
                      <img className='pfp-profile rounded-circle' src={follower.imageUrl} alt={follower.fname} onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                      : 
                      <img className='pfp-profile rounded-circle' src={`${BASE_URL_STATIC}/dummy.png`} alt={follower.fname} />
                    }

                    <Link to={`${BASEURL}/profile/${follower.userId}`} className='ms-2 ms-sm-3 me-auto text-dark' onClick={() => setShowFollowerPopup(false)} >
                      <h6 className='pfp-name fw-bold'>{follower.fname}</h6>
                      <small className='text-secondary'>{follower.fname}</small>
                    </Link>

                    {
                      follower.userId !== loggedInUserId 
                      &&
                      <FollowUnfollowButton otherUserId={follower.userId} />
                    }
                  </div>
                ))}
            </div>
          }
        </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ProfileFollowerPopup;

