import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { matchPartner } from "../../Emilo/UseContext/ScApiHelpers";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import { BASEAPIURL } from "../../../config";

export const ScApiContext = createContext();

export const ScApiCall = ({ children }) => {

  const [partnerUserId, setPartnerUserId] = useState();
  const [followStatusLoader, setFollowStatusLoader] = useState(false);
  const [buttonDis, setButtonDis] = useState(false);
  const [listPost, setListPost] = useState([]);
  const [partnerPosts, setPartnerPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [createPop, setCreatePop] = useState(false);
  const [posts, setPosts] = useState([]);
  const [disableNext , setDisableNext] = useState(false)
  const [chatId, setChatId] = useState(null);
  // const [partnerImage, setPartnerImage] = useState("");
  const [btnEnable , setBtnEnable] = useState()
  const [profileIndex ,setProfileIndex] = useState(-1)
  const [softCornerUsers, setSoftCornerUsers] = useState([]);
  const [isMatched , setIsMatched] = useState(false)

  const [slidertype , setSlidertype] = useState("")
  const [currentCrush , setCurrentCrush] = useState(null)


  const [rightBlockUser, setRightBlockUser] = useState();
  const [isSoftCornerPartnerExist, setIsSoftCornerPartnerExist] = useState(false);

  const { config, socket } = useContext(ApiContext);

  const scConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("SCtoken")}`,
    },
  };

  // setInterval(async () => {
  //   try {

  //     const alertTime = await axios.post("https://emilonode.sjain.io/softcorner/getspecialdayalert" , {} ,  {headers: {
  //       Authorization: `Bearer ${localStorage.getItem("SCtoken")}`,
  //     }})

  //   if(alertTime){
  //     toast.success(alertTime.data.responses.message)
  //   }
  // } catch (error) {

  //   toast.error(" nothing")
  //   }
  // }, 1000)

  const inputRef = useRef();



  // ***** Image Compression ***** 

  const scPostOption = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 175,
    useWebWorker: true,
  };


  // Add Remove button
  const handleAddRemoveButton = async (myUserId, partnerUserId, setbtnLoader, action) => {
    setPartnerUserId(partnerUserId);
    matchPartner(scConfig);
    setbtnLoader(true);
    try {
      await axios.post(`${BASEAPIURL}/softcorner/addSCPartner`, {emiloUserId:myUserId, partnerUserId: partnerUserId, action: action }, scConfig)
        .then(async (res) => {
          setRightBlockUser(true)
          setIsSoftCornerPartnerExist(false)
          setBtnEnable(false)
          // For notification
          axios.post('https://emilonode.sjain.io/user/createemnotification', { userId: partnerUserId, type: 'softcorner' }, config)
          .then(res => {
            console.log('sc notification : ', res);
            
            let notificationData = {
              type: res.data.type,
              receiverUserId: res.data.receiverUserId && res.data.receiverUserId,
              message: res.data.message && res.data.message,
              createdAt: res.data.createdTime && res.data.createdTime,
            };
            
            socket.emit("send_message_emilo_notification", notificationData);
            console.log('sc notificationData : ', notificationData);
          })
          .catch(err => {
            console.log(err);
            setBtnEnable(false)
          });
        })
        .catch((err) => {
          setbtnLoader(false);
          setBtnEnable(false)
          setRightBlockUser(false)
          setIsSoftCornerPartnerExist(true)
          
        });
        // await disableButton(action)
        
      } catch (error) {
        setIsSoftCornerPartnerExist(true)
        setBtnEnable(false)
        setRightBlockUser(false)
        setbtnLoader(false);
        
      } finally {
        setRightBlockUser(false)
        setBtnEnable(false)
        setIsSoftCornerPartnerExist(true)
        setbtnLoader(false);
    }
  }

  useEffect(() => {
    disableButton();
  }, []);


  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setPosts(selectedFiles);
    setCreatePop(!createPop);
  };

  const disableButton = async () => {
    const res = await axios.post(
      "https://emilonode.sjain.io/softcorner/disablebutton",
      { partnerUserId },
      scConfig
    );

    // console.log("disableLog", res.data);
    setButtonDis(res.data.disable);
  };

  const fetchAddStatus = async (partnerUserId, setIsAdded) => {
    try {
      setFollowStatusLoader(true);
      await axios
        .post(
          "https://emilonode.sjain.io/softcorner/checkuserinsc",
          { partnerUserId: partnerUserId },
          scConfig
        )
        .then((res) => {
          setIsAdded(res.data.add);

          // if (res.data.add) {
          //   disableButton(res.data.add);
          // }

          setTimeout(() => {
            setFollowStatusLoader(false);
          }, 500);
        });
    } catch (error) {
      setFollowStatusLoader(false);
    }

  };

  // To check is soft corner partner exist or not  
  const checkSoftCornerPartnerExist = (scConfig) => {
    axios.post(`${BASEAPIURL}/softcorner/checkparterhaiyaNnhi`, {}, scConfig)
      .then(res => {
        // console.log("eeee",res.data.added);
        setIsSoftCornerPartnerExist(res.data.added);
      })
      .catch(err => {
        // console.log(err);
      });
  };


  useEffect(() => {
    checkSoftCornerPartnerExist(scConfig);
  }, []);

  const value = {
    scConfig,
    partnerUserId,
    scPostOption,
    fetchAddStatus,
    createPop, setCreatePop,
    handleAddRemoveButton,
    disableNext , setDisableNext,
    posts, setPosts,
    followStatusLoader,
    setFollowStatusLoader,
    buttonDis,
    setButtonDis,
    partnerPosts,
    setPartnerPosts,
    listPost,
    slidertype , setSlidertype,
    profileIndex ,setProfileIndex,
    setListPost,
    allPosts,
    btnEnable , setBtnEnable, 
    currentCrush , setCurrentCrush,
    softCornerUsers, setSoftCornerUsers,  
    setAllPosts, handleFileChange,
    inputRef, chatId, setChatId,
    isMatched , setIsMatched,
    rightBlockUser, setRightBlockUser,
    isSoftCornerPartnerExist, setIsSoftCornerPartnerExist
  };

  return (
    <ScApiContext.Provider value={value}>{children}</ScApiContext.Provider>
  );
};
