import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../config';

const FavouritePeoplePopup = ({ showFavouritePeoplePopup, setShowFavouritePeoplePopup }) => {

  const { config, getFavouritePeople, favouritePeoples, handleRemoveFavouritePeople } = useContext(ApiContext);

  useEffect(() => {
  getFavouritePeople(config);
  }, [showFavouritePeoplePopup]);

  return (
    <>
      <Modal show={showFavouritePeoplePopup} onHide={() => setShowFavouritePeoplePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5>Favourite People</h5>

          <div className='favouritePeoplePopup'>
            {
              favouritePeoples
              ?
              favouritePeoples.map((favouritePeople, index) => (
                // console.log(favouritePeople)
                <div className='favouritePeoplePopup-user' key={index}>
                  {
                    favouritePeople.imageUrl
                    ?
                    <img className='favouritePeoplePopup-user-profile' src={favouritePeople.imageUrl} alt={favouritePeople.userId} height={50} width={50} style={{ borderRadius: '50%' }} />
                    :
                    <img className='favouritePeoplePopup-user-profile' src={`${BASE_URL_STATIC}/dummy.png`} alt='dummy' height={50} width={50} style={{ borderRadius: '50%' }} />
                  }

                  <h6 className='favouritePeoplePopup-user-name'>{favouritePeople.fname}</h6>

                  <span className='favouritePeoplePopup-user-button' onClick={() => handleRemoveFavouritePeople(favouritePeople.userId, config)}>Remove</span>
                </div>
              ))
              :
              ''
            }
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default FavouritePeoplePopup
