import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { BASEURL, BASE_URL_STATIC } from "../../../config";
import { HiMiniXMark } from "react-icons/hi2";
import 'react-loading-skeleton/dist/skeleton.css'
import { ApiContext } from "../UseContext/ApiCall";
import axios from "axios";
import CreateNewPostPopup from "../Popup/CreateNewPost/CreateNewPostPopup";

const MobileHeader = () => {

  const [inputValue, setinputValue] = useState('')
  const [searchList, setSearchList] = useState([])
  const [recentSearch, setrecentSearch] = useState([])
  const [showrecentSearch, setShowrecentSearch] = useState(false)
  const [showCreatePostPopup, setShowCreatePostPopup] = useState(false);

  const { config, setLoader, loggedInUserId } = useContext(ApiContext);

  // console.log(loggedInUserImage);

  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, []);

  const handleSearch = async (e) => {
    setinputValue(e.target.value)
    if (e.target.value.length > 0) {
      const res = await axios.post(`https://emilonode.sjain.io/user/getalluser?page=1&sort=1&search=${e.target.value}`)
      setSearchList(res.data.users)
      setShowrecentSearch(false)
    }
    else {
      setSearchList([])
      setinputValue('')
    }
  };

  const createRecentSearch = async (userId) => {
    setrecentSearch([])
    setSearchList([]);
    setinputValue('')
    await axios.post("https://emilonode.sjain.io/user/createrecentsearch", { searchUserId: userId }, config)
  };

  const getRecentSearch = async () => {
    setShowrecentSearch(true)
    const res = await axios.post("https://emilonode.sjain.io/user/getrecentsearch", {}, config)
    setrecentSearch(res.data.recentSearch)
  };

  const clearSearch = async () => {
    await axios.post("https://emilonode.sjain.io/user/deleteallrecentsearch", {}, config)
    setrecentSearch([])
    setShowrecentSearch(false)
  };

  const deleteOneSearch = async (userId) => {
    await axios.post("https://emilonode.sjain.io/user/deleterecentsearch", { searchUserId: userId }, config).then(() => getRecentSearch())
  };

  // const urls = ['login', 'signup', 'chats', 'basic-info', 'business'];

  return (
    <>

      {/* Create New Post Popup */}
      <CreateNewPostPopup show={showCreatePostPopup} onHide={() => setShowCreatePostPopup(false)} showCreatePostPopup={showCreatePostPopup} setShowCreatePostPopup={setShowCreatePostPopup} />

      {/* Responsive > Mobile > Header */}
      {/* <section className={`${urls.some(url => window.location.href.includes(url)) && 'd-block d-lg-none'} emilo-mobile-header navbar bg-body-tertiary fixed-top`}> */}
      <section className={`d-none`}>
        <div className="flex gap-post" >
          <Link to={`/profile/${loggedInUserId}`}>
            <img className="emilo-mobile-header-profile rounded-circle" src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />
          </Link>

          <div className="">
            <div className="btn-box-mob text-center " >
              <span onClick={() => setShowCreatePostPopup(true)} className="new-post p-md-1 p-lg-3 ">
                <i className="fal fa-plus-circle" />
                <span className="sm-new-post d-none d-sm-none d-md-inline">post</span>
              </span>
            </div>
          </div>
        </div>

        <Link to={`/`} className="emilo-mobile-header-logos">
          <img className="emilo-mobile-header-logo" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </Link>

        <div className="flex gap-post" >
          <div className="emilo-mobile-header-search navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar">
            <img className="emilo-mobile-header-search-img" src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />
          </div>

          {/* It will show when someone click on search icon of header in mobile */}
          <div className="offcanvas offcanvas-end emilo-mobile-header-search-offcanvas" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="row offcanvas-header">
              <button type="button" style={{marginTop:"0px"}} className="col-1 btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              <img className="col-3 emilo-mobile-header-search-toggle-logo" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" data-bs-dismiss="offcanvas" />
            </div>

            <div className="offcanvas-body">
              <div className="emilo-mobile-header-search-toggle-input">
                <div className="input-box">
                  <form>
                    <img src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
                    <input type="text" value={inputValue} onChange={handleSearch} onFocus={getRecentSearch} placeholder="Search" />
                  </form>
                </div>

                {
                  inputValue
                  &&
                  <div className="searchList" data-bs-dismiss="offcanvas">
                    {searchList.map((user, index) => (
                      <Link key={index} onClick={() => createRecentSearch(user.userId)} to={`${BASEURL}/profile/${user.userId}`} style={{ color: 'black' }}>
                        <div>
                          <img className="searchImage" src=
                            {user.profileImg ? user.profileImg : `${BASE_URL_STATIC}/dummy.png`} alt="" />
                        </div>

                        <div className="userSearchList">
                          <span >{user.fname}</span>
                          <div>
                            {user.username && <span>{user.username}</span>}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                }

                {
                  recentSearch.length >= 1 && showrecentSearch
                  &&
                  <div className="searchList" data-bs-dismiss="offcanvas">
                    <div className="flex mb-3" style={{ display: `${!showrecentSearch && "hidden"}` }}>
                      <span className="text-muted">Recent Search</span>
                      <span className="clearSearch" onClick={clearSearch}>clear all</span>
                    </div>

                    {
                      recentSearch.map((user, index) => (
                        <div className="flex" style={{ display: `${!showrecentSearch && "hidden"}` }}>
                          <Link key={index} onClick={() => createRecentSearch(user.userId)} to={`${BASEURL}/profile/${user.userId}`} style={{ color: 'black' }}>
                            <div>
                              <img className="searchImage" src={user.profileImg ? user.profileImg : `${BASE_URL_STATIC}/dummy.png`} alt="" />
                            </div>

                            <div className="userSearchList">
                              <span >{user.fname}</span>
                              <div>
                                {user.username && <span>{user.username}</span>}
                              </div>
                            </div>
                          </Link>

                          <div style={{ cursor: "pointer" }} onClick={() => deleteOneSearch(user.userId)}><HiMiniXMark size={20} /></div>
                        </div>
                      ))
                    }
                  </div>
                }

                {
                  inputValue.length > 1 && searchList.length === 0
                  &&
                  <small className="searchList" data-bs-dismiss="offcanvas">
                    <i style={{ color: "#ff6863" }}>No user found</i>
                  </small>
                }
              </div>
            </div>
          </div>

          <Link to={`/notifications`} className="emilo-mobile-header-nortification">
            <img className="emilo-mobile-header-nortification-img" src={`${BASE_URL_STATIC}/icon/Notifications.svg`} alt="nortification" />
          </Link>
        </div>

        {/* <Link to={`/`} className="emilo-mobile-header-logos" onClick={handleLogout}>
          <img className="emilo-mobile-header-logo" src={`${BASE_URL_STATIC}/icon/logout.svg`} alt="logo" />
        </Link> */}
      </section>
    </>
  )
}

export default MobileHeader;