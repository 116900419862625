import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProfileImage from '../UseContext/ProfileImage';
import { BASEURL, BASE_URL_STATIC } from '../../../config';
import { getReelsDetails } from '../UseContext/ApiHelpers';
import LikeReelButton from '../Button/LikeButton/LikeReelButton';

const ReelSharePage = () => {

  const { reelId } = useParams()
  const [reelDescription, setReelDescription] = useState()
  const [reelUserId, setreelUserId] = useState()
  const [reelviewCount, setReelviewCount] = useState()
  const [showCopyLinkUserName, setshowCopyLinkUserName] = useState('');
  const [shareCount, setshareCount] = useState(0)

  useEffect(() => {
    getReelsDetails(reelId)
      .then((res) => {
        console.log("res", res);
        setReelDescription(res.reelDescription)
        setreelUserId(res.userId)
        setReelviewCount(res.reelViewCount)
        setshareCount(res.shareCount)
      })
      .catch((error) => {
        console.error('Error fetching reel details:', error);
      });
  }, [reelId]);

  // To get show copy link user
  const getShowCopyLinkUser = (reelUserId) => {
    axios.post('https://emilonode.sjain.io/user/getoneuser', { userId: reelUserId })
      .then(res => {
        // console.log(res.data.user);
        setshowCopyLinkUserName(res.data.user.fname);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShowCopyLinkUser(reelUserId);
  }, [reelUserId]);

  function handleDownload() {
    const videoUrl = `https://emilonode.sjain.io/post/getvideobyid?reelId=${reelId}`;
    const fileName = `reel_${reelId}.mp4`;

    axios({
      url: videoUrl,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  return (
    <>
      <section>
        <section className="flex" style={{ border: '1px solid black', width: '1100px', margin: '100px auto 0px' }}>
          <section className='video-wrap'>
            <video
              controls
              autoPlay
              loop
              className="reeelShareVideo"
              style={{ background: "black" }}
            >
              <source
                src={`https://emilonode.sjain.io/post/getreelbyreelid?reelId=${reelId}`}
              />
            </video>
          </section>

          <section>
            <div className="d-flex" style={{ width: "455px", justifyContent: "space-between" }} >
              <div className="d-flex" style={{ marginTop: "10px" }}>
                <div style={{ margin: "0px 10px", }} >
                  <ProfileImage postId={reelUserId} height='45' width='45' borderRadius='50%' />
                </div>
                <div>
                  <Link to={`${BASEURL}/profile/${reelUserId}`} style={{ color: 'black' }}>{showCopyLinkUserName}</Link>
                </div>
              </div>

              <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" style={{ cursor: "pointer", marginRight: "10px" }} />
            </div>

            <hr
              style={{
                height: "0.1px",
                width: "100%",
                backgroundColor: "gray",
                margin: "5px 0px",
              }}
            />

            <div style={{ height: "400px", width: "457px" }}>
              <h3 style={{ textAlign: "center" }}>Description</h3>
              <span>{reelDescription}</span>
            </div>

            <hr
              style={{
                height: "0.1px",
                width: "100%",
                backgroundColor: "gray",
                margin: "5px 0px",
              }}
            />

            <div
              className="d-flex"
              style={{ width: "455px", marginTop: "10px" }}
            >
              <div className="d-flex">
                <img
                  src={`${BASE_URL_STATIC}/feed-icon1.png`}
                  alt="views"
                  height="70%"
                  width="100%"
                  style={{ margin: "5px 5px 0px 5px" }}
                />
                <p style={{ margin: "2px 25px 0px 0px" }}>{reelviewCount}</p>
              </div>

              <div className="d-flex">
                <div style={{ marginRight: "25px" }}>
                  <LikeReelButton reelId={reelId} />
                </div>
              </div>

              <div className="d-flex">
                <img
                  src={`${BASE_URL_STATIC}/feed-icon3.png`}
                  alt="views"
                  style={{ marginRight: "5px" }}
                />
                <p style={{ marginRight: "25px" }}>1k</p>
              </div>

              <div className="d-flex">
                <img
                  src={`${BASE_URL_STATIC}/feed-icon4.png`}
                  alt="views"
                  style={{ marginRight: "5px" }}
                />
                <p style={{ marginRight: "127px" }}>{shareCount}</p>
              </div>

              <div style={{ marginRight: "20px" }}>
                <img src={`${BASE_URL_STATIC}/feed-icon5.png`} alt="views" />
              </div>

              <div onClick={handleDownload}>
                <img src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="views" />
              </div>
            </div>

            <div
              style={{
                height: "140px",
                width: "406px",
                overflowY: "auto",
                padding: "10px",
              }}
            >
            </div>

          </section>
        </section>
      </section>
    </>
  );
};

export default ReelSharePage;
