import React, { useState, useContext } from "react";
import { ApiContext } from "../UseContext/ApiCall";
import { useEffect } from "react";
import { BASEAPIURL } from "../../../config";
import axios from "axios";
import { checkAccountPrivacy, createNotification } from "../UseContext/ApiHelpers";
import toast from "react-hot-toast";

const FollowUnfollowButton = ({ otherUserId, type }) => {

  const [isFollowing, setIsFollowing] = useState(false);

  const { fetchFollowStatus, setOtherUserId, config, getFollowerList, profileUserId, getFollowingList } = useContext(ApiContext);
  
  const handleFollowUnfollow = async (otherUserId, isFollowing, setIsFollowing) => {
    setOtherUserId(otherUserId);
    //For notification

    checkAccountPrivacy(otherUserId , config).then(async(res) => {
      if (res==='private' && action === 'follow') {
        const data = {
          targetId: otherUserId,
          userType: "user",
          targetEvent: "follow",
          targetType: "follow",
          notificationType: "emilo",
          // targetEventId: postId,
          isSeen: 0,
        }
        createNotification(data, config)
        toast.success("Follow request sent")
  
      }
      else {
        await axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action }, config)
          .then(async (res) => {
            console.log(res.data);
            setIsFollowing(!isFollowing);
  
            getFollowerList(profileUserId);
            getFollowingList(profileUserId);
  
          })
          .catch((err) => {
            console.log("err in follow ", err)
            return null;
          });
      }
    })
    
    const action = isFollowing ? "follow" : "unfollow";
  };

  useEffect(() => {
    fetchFollowStatus(otherUserId, setIsFollowing);
  }, [otherUserId]);

  return (
    <>
      {type === 'flix' ?
        <button type="button" className="followUnfollowBtn_flix fs-10" onClick={() => handleFollowUnfollow(otherUserId, isFollowing, setIsFollowing)}>
          {isFollowing ? "  Follow  " : "Unfollow"}
        </button>
        :
        <button type="button" className="followUnfollowBtn" onClick={() => handleFollowUnfollow(otherUserId, isFollowing, setIsFollowing)}>
          {isFollowing ? "  Follow  " : "Unfollow"}
        </button>
      }
    </>
  );
};

export default FollowUnfollowButton;
