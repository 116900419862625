import React, { useContext, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import EmojiPicker from 'emoji-picker-react';
import { BASEAPIURL, BASE_URL_STATIC } from '../../../config';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { ApiContext } from '../UseContext/ApiCall';

const SetChatGroupName = ({ open, close,isAdmincanMsg, selectedMembers, setCreateGroupFinal }) => {

   const [groupName, setGroupName] = useState("")
   const [groupDesc , setgroupDesc ] = useState("")
   const [groupIcon, setGroupIcon] = useState(null)
   const [openEmoji, setOpenEmoji] = useState(false)
   const [createLoader, setCreateLoader] = useState(false)
   const [opendesEmoji , setOpendesEmoji] = useState(false)

   const { config , setisnewGroupAdded } = useContext(ApiContext)

   const fileRef = useRef()

   const handleFileSelect = () => {
      if (fileRef) {
         fileRef.current.click()
      }
   }
   const handleEmojiPick = (e) => {
      setGroupName(prev => prev + e.emoji)
   }
   const handleEmojiPickDes = (e) => {
      setgroupDesc(prev => prev + e.emoji)
   }

   const handleCreate = async () => {
      if (groupName === "") {
         toast.error("Please enter group name")
      }
      else if (groupIcon === null) {
         toast.error("Please upload group icon")
      }
      else {
         setCreateLoader(true)
         const formData = new FormData();

         console.log('selectedMembers : ', selectedMembers);

         selectedMembers.forEach((e, i) => {
            console.log('selectedMember : ', selectedMembers[i]);
            
            formData.append("userId", selectedMembers[i].userId)
         })

         formData.append("groupName", groupName)
         formData.append("isGroupChat", "true")
         formData.append("image", groupIcon)
         formData.append("description", groupDesc)
         formData.append("isAdmincanMsg", isAdmincanMsg)
         try {
            await axios.post(`${BASEAPIURL}/user/createemchatconnection`, formData, config).then((res) => {
               console.log("create", res);
               setCreateGroupFinal(false)
               setCreateLoader(false)
               setisnewGroupAdded(true)
            })
         } catch (error) {
            console.log(error);
            toast.error("failed to create group")
            setCreateLoader(false)
         }
      }
   }

   return (
      <div>
         <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call modal-head-search" >
            <Modal.Header closeButton style={{ width: "100%" }}>
               <div className='create-group-header'>
                  <h5 >Create Group</h5>
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="group-final">
                  <form>
                     <input type="file" ref={fileRef} accept='image/*' style={{ display: "none" }} onChange={(e) => setGroupIcon(e.target.files[0])} />

                     <span onClick={handleFileSelect} className='select-group-icon'>
                        {groupIcon ? <img className='select-group-icon-selected' src={URL.createObjectURL(groupIcon)} alt="" /> :
                           <img className='select-group-icon-dummy' src={`${BASE_URL_STATIC}/group.png`} alt="" />
                        }

                     </span>

                     <div className='group-name'>
                        <input type="text" value={groupName} className='group-name-input' placeholder='group name' onChange={(e) => setGroupName(e.target.value)} />


                        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpenEmoji(!openEmoji)} alt="" />


                        <span className='emoji-select-group'>

                           {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
                        </span>
                     </div>

                     <div className='group-desc'>
                        <textarea type="text" value={groupDesc} rows={8} maxLength={300} className='group-desc-input' placeholder='group description' onChange={(e) => setgroupDesc(e.target.value)} />

                        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpendesEmoji(!opendesEmoji)} alt="" />

                        <span className='emoji-select-group'>

                           {opendesEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPickDes(res)} />}
                        </span>
                     </div>

                  </form>


               </div>

               <button className='group-create-button' onClick={handleCreate}> {createLoader ? (
                  <span className="flex justify-content-center">
                     <FontAwesomeIcon
                        icon={faCircleNotch}
                        style={{ color: "snow" }}
                        spin
                     />{" "}
                     creating...
                  </span>
               ) : (
                  "create"
               )}</button>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default SetChatGroupName