import React from 'react'

const ProfilePayment = () => {
  return (
    <> 
      <section className='vh-100 pt-5'>
        <h1 className='text-center mt-5'>Payment soon..</h1>
      </section>
    </>
  )
}

export default ProfilePayment