import React, { useContext, useEffect, useRef, useState } from "react";
import { bgImages } from "../../../../Data/EducationData.jsx";
import * as htmlToImage from "html-to-image";
import { ApiContext } from "../../../../UseContext/ApiCall.js";
import StoryAdvanceSettingPopup from "./StoryAdvanceSettingPopup.js";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import TextTags from './TextTags';
import StoryTextPostPop from "../../../CreateNewPost/StoryTextPostPop.js";

const StoryTextPopup = () => {
  const refs = useRef();
  const [openTag, setOpentag] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [tagMessage, setTagmessage] = useState('');
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [displayTags, setDisplayTags] = useState(true)

  console.log("tagMessage" , tagMessage)

  const {
    storyText, setStoryText,
    config,
    createUserStories,
    showAddToStoriesBtn, setShowAddToStoriesBtn,
    setshowCreateStoriesPopup,
    showStoryTextPopup, setShowStoryTextPopup,
    setShowStoriesAdvanceSettingPopup,
    previewBackground, setPreviewBackground,
    setStoriesAdvanceSetting,
  } = useContext(ApiContext);


  useEffect(() => {
    setPreviewBackground(bgImages[0].img);
    setOpentag(false);
    setSelectedUser([]);
    setTagmessage('');
    setTaggedUsers([])
  }, []);

  const handleBgSelection = (index) => {
    setPreviewBackground(bgImages[index].img);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  // Convert to PNG and send the story
  const handleConvertToPng = async () => {
    setShowAddToStoriesBtn(true);
    setDisplayTags(false)
    setOpentag(false)


    if (refs.current) {
      htmlToImage
        .toPng(refs.current, { cacheBust: true })
        .then(async (dataUrl) => {
          const blob = dataURLtoBlob(dataUrl);
          const file = new File([blob], 'image.png', { type: 'image/png' });
          const formData = new FormData();
          formData.append('mediaUrls', file);
          createUserStories(formData, 'text', taggedUsers , "");
          setShowStoryTextPopup(false);
          setStoryText('');
          setPreviewBackground('');
          setTaggedUsers([])
          setShowAddToStoriesBtn(false);
          setStoriesAdvanceSetting({
            visibility: 'public',
            expire: '24',
            like: 'on',
            comment: 'on',
          });
        })
        .catch((error) => {
          console.error("Error converting HTML to PNG:", error);
        }).finally(() => {
          setTaggedUsers([])
          setDisplayTags(true)
        });
    }
  };

  // Handle clicking on the preview section
  const handlePreviewClick = (e) => {
    setOpentag(true);
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setSelectedUser({ x, y });
  };

  const handleTagUser = (userWithPosition) => {
    setTaggedUsers(prevUsers => [...prevUsers, userWithPosition]);
  };

  const filterUser = (user) => {
    setOpentag(false)
    setTaggedUsers((prev) => {
      const updatedUsers = prev.filter((u) => u.userId !== user.userId);
      return updatedUsers;
    });
  }


  return (
    <>
      {/* Stories Advance Setting Popup */}
      <StoryAdvanceSettingPopup />

      <Modal
        show={showStoryTextPopup}
        onHide={() => {
          setShowStoryTextPopup(false);
          setOpentag(false);
          setshowCreateStoriesPopup(true);
        }}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        className="create-call create-stories-popup"
      >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className='p-3' style={{ height: '75vh' }}>
          {/* Header */}
          <div className='d-flex mt-3'>
            <span className='cursor-pointer' onClick={() => { setShowStoryTextPopup(false); setshowCreateStoriesPopup(true); }}>⮜</span>
            <h5 className='mb-0 mx-auto fw-semibold'>Add to Stories</h5>
          </div>

          <div className="d-flex gap-3 my-4" style={{ height: '77%' }}>
            {/* Editor & Background */}
            <div className="w-50 d-flex gap-2 flex-column justify-content-between">
              {/* Editor */}
              <div>
                <StoryTextPostPop />
              </div>

              {/* Background */}
              <div className="border rounded-3 mt-2 p-3 flex-grow-1">
                <h5 className="mb-4">Background</h5>

                <div className="d-flex flex-wrap gap-3 scrollbar-hidden" style={{ height: '120px' }}>
                  {
                    bgImages.map((bgImage, index) => (
                      <img
                        key={index}
                        src={bgImage.img}
                        alt="background"
                        className={`rounded-4 img-fluid shadow-sm cursor-pointer`}
                        style={{ width: "50px", height: "50px" }}
                        onClick={() => handleBgSelection(index)}
                      />
                    ))
                  }
                </div>
              </div>
            </div>

            {/* Preview */}
            <div className="border rounded-4 w-50 px-2 pb-2 d-flex flex-column position-relative">
              <small className="story_tag_btn" onClick={() => setOpentag(!openTag)}>
                <FontAwesomeIcon className="icon" icon={faUserTag} style={{ color: "#000" }} />
              </small>

              <h5 className="text-black my-2"> Preview </h5>

              {openTag && (
                <TextTags taggedUsers={taggedUsers} openTag={openTag} setOpentag={setOpentag} selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  config={config}
                  handleTagUser={handleTagUser}
                  setTaggedUsers={setTaggedUsers}
                />
              )}

              <div
                ref={refs}
                className="aspect-ratio-9by16 border overflow-hidden scrollbar-hidden flex-grow-1 pb-0"
                style={{ backgroundImage: `url(${previewBackground})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
                onClick={handlePreviewClick}  // Add the click handler here
              >
                <div className="content d-flex justify-content-center align-items-center">
                  <div className="text_opener" dangerouslySetInnerHTML={{ __html: storyText }}></div>
                </div>

                {/* Show TextTags Component if Tagging is Open */}


                {/* Render Tagged Users */}
                {displayTags && taggedUsers.map((user, index) => (
                  <div className="preview_tags"
                    onClick={(e) => { e.stopPropagation(); filterUser(user); }}
                    key={index}
                    style={{
                      left: `${user.x}%`,
                      top: `${user.y}%`,
                    }}
                  >
                    @{user.username}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Button */}
          <div className="text-center mt-4">
            <button type="button" className="btn btn-primary w-50 rounded-5" disabled={showAddToStoriesBtn} onClick={handleConvertToPng}>
              {showAddToStoriesBtn ? 'Adding' : 'Add to Stories'}
            </button>
          </div>

          {/* Advance Setting */}
          <div className="text-center">
            <small className="text-primary cursor-pointer" onClick={() => {
              setShowStoriesAdvanceSettingPopup(true);
            }}>Advance Setting</small>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryTextPopup;
