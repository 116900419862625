import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import EmojiPopup from '../EmojiPopup';
import PostSettings from '../../Button/PostSettings';
import { IoAddOutline } from 'react-icons/io5';
import AddLocation from './AddLocation';
import Addsong from './Addsong';
import ProfileImage from '../../UseContext/ProfileImage';
import { ApiContext } from '../../UseContext/ApiCall';
import { BiTrash } from 'react-icons/bi';

const MusicPreview = ({ setOpenEmoji, location, setLocation, setSelectedMusic, selectedMusic, open, close, setSelectedEmoji, selectedEmoji, openEmoji, }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRefs = useRef([]);

    const inputRef = useRef()

    console.log("selected", selectedMusic)
    const { loggedInUserId, loggedInUserName } = useContext(ApiContext)

    useEffect(() => {
        // Pause all audio players initially
        audioRefs.current.forEach((audioPlayer, index) => {
            if (audioPlayer && index !== activeIndex) {
                audioPlayer.audioEl.current.pause();
            }
        });

        // Play the audio of the current active slide
        if (audioRefs.current[activeIndex]) {
            audioRefs.current[activeIndex].audioEl.current.play();
            setIsPlaying(true);
        }

        // Cleanup function to pause the audio when the component unmounts or index changes
        return () => {
            if (audioRefs.current[activeIndex]) {
                audioRefs.current[activeIndex].audioEl.current.pause();
                setIsPlaying(false);
            }
        };
    }, [activeIndex]);

    const handleSlideChange = (index) => {
        // Pause the audio of the previous slide
        if (audioRefs.current[activeIndex]) {
            audioRefs.current[activeIndex].audioEl.current.pause();
            setIsPlaying(false);
        }
        // Update the active index
        setActiveIndex(index);
    };

    const handlePlayPause = () => {
        if (audioRefs.current[activeIndex]) {
            if (isPlaying) {
                audioRefs.current[activeIndex].audioEl.current.pause();
            } else {
                audioRefs.current[activeIndex].audioEl.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => handleSlideChange(next),
    };
    const handleAddMore = () => {
        inputRef.current.click()
    }

    // const handleAddMusic = (e) => {
    //     const musics = Array.from(e.target.files);
    //     setSelectedMusic([...selectedMusic, ...musics])
    // }

    const handleDelete = () => {
        if (Array.isArray(selectedMusic) && selectedMusic.length > 0) {
          const updatedFiles = [...selectedMusic];
          updatedFiles.splice(activeIndex, 1); // Remove the file at the active index
          setSelectedMusic(updatedFiles);
          setActiveIndex(prev => prev - 1);
        }
      };

    return (
        <Modal show={true} onHide={close} centered keyboard={false} className="create-call" backdrop="static">
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
                {
                    openEmoji ?
                        <>
                            <div className="row">
                                <p className="col-1" style={{ cursor: "pointer" }}
                                    onClick={() => { setOpenEmoji(false) }}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                                </p>
                                <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                                    Create Post
                                </p>
                            </div>

                            <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />

                        </> :
                        <>
                            <div className="row">
                                <p className="col-1" style={{ cursor: "pointer" }} onClick={() => close()}>
                                    <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                                </p>
                                <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                                    Post Music
                                </p>
                            </div>

                            <div className="userDetail d-flex gap-2">
                                <div className="imgBox" style={{ marginLeft: '15px' }}>
                                    <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                                </div>
                                <div className="nameBox">
                                    <div className="d-flex flex-md-row align-items-center">
                                        <span className="fs-6">
                                            {loggedInUserName}
                                            {selectedEmoji && (
                                                <span className="ms-1 d-inline-block" onClick={() => setOpenEmoji(true)}>
                                                    {selectedEmoji.emotion}
                                                    <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                                    {selectedEmoji.emotionName}
                                                </span>
                                            )}
                                        </span>
                                    </div>

                                    <div className="add-location-div">
                                        <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />
                                        <AddLocation location={location} setLocation={setLocation} />
                                        <Addsong/>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <textarea
                                    style={{ resize: "none", width: "97%" }}
                                    className='m-2 border border-1 rounded-2 p-2'
                                    name="textArea"
                                    id="textArea"
                                    rows="3"
                                    //  onChange={(e) => setDescription(e.target.value)}
                                    placeholder="description"
                                ></textarea>
                            </div>

                            <div className="position-relative">

                                <div className="d-flex justify-content-between align-items-center m-2">
                                    <button onClick={handleAddMore} className="pv_btn" style={{ color: "#64A6F0" }}> <IoAddOutline className="fw-bold" size={15} /> Add </button>

                                    {selectedMusic && selectedMusic.length > 1 && <button className="pv_dlt" onClick={handleDelete}>
                                        <BiTrash size={16} />
                                    </button>}
                                </div>

                                {/* <AddTags setActiveIndexPv={setActiveIndex} photoVideo={isPhotoVideo} setfeelingActivity={setfeelingActivity} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} selectphotoAndVideo={selectphotoAndVideo} fileUrl={fileUrl} setCheckOpen={setCheckOpen} setShowPv2={setShowPv2} /> */}

                                <input type="file" className="d-none" ref={inputRef} onChange={handleAddMore} accept="audio/mp3" multiple />

                            </div>

                            <div>
                                <Slider {...settings}>
                                    {selectedMusic.map((item, index) => (
                                        <div key={item.id} className='d-flex flex-column align-items-center'>
                                            <img src="https://img.freepik.com/free-vector/gradient-album-cover-template_23-2150597431.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1717977600&semt=ais_user" alt={item.title} />

                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <img width="75%" className={`${isPlaying ? 'cd_loader' : ""}`} src="/images/disc.png" alt="" />
                                                </div>
                                                <div className='w-75'>
                                                    <p className='w-100 text-truncate'>{item.title}</p>
                                                    <small className='w-100 text-truncate d-block'>{item.artist}</small>
                                                </div>
                                                <div onClick={handlePlayPause} style={{ cursor: "pointer" }}>
                                                    {isPlaying && activeIndex === index ? (
                                                        <img width={40} src="/images/pause.svg" alt="Pause" />
                                                    ) : (
                                                        <img width={40} src="/images/play.svg" alt="Play" />
                                                    )}
                                                </div>
                                            </div>
                                            <ReactAudioPlayer
                                                ref={(el) => (audioRefs.current[index] = el)}
                                                src={item.audio}
                                                style={{ display: 'none' }}
                                                onPlay={() => {
                                                    // Pause all other audio players
                                                    audioRefs.current.forEach((audioPlayer, i) => {
                                                        if (audioPlayer && i !== index) {
                                                            audioPlayer.audioEl.current.pause();
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                            <PostSettings />

                            <div className='col-8 m-auto'>
                                <button className='text_post_btn'>Post</button>
                            </div>
                        </>
                }
            </Modal.Body>
        </Modal>
    );
};

export default MusicPreview;
