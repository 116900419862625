import React, { useEffect } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import { scheduler } from "dhtmlx-scheduler";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL_STATIC } from '../../../config';


const DatePlanner2 = ({ plan, setShowPlanDetails }) => {


  // DHTML Scheduler
  useEffect(() => {
    scheduler.config.first_hour = 6;
    scheduler.config.last_hour = 19;
    scheduler.init('scheduler_here', new Date(), 'month');
    scheduler.parse([

    ]);

    return () => {
      scheduler.clearAll();
    };
  }, []);

  // Function to format date
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatTime = (dateTimeString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateTimeString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };



  return (
    <>
      <section className=''>

        <div className='plan-back-btn'>
          <FontAwesomeIcon color='#64A6F0' style={{ padding: "20px 20px 0px 30px", fontSize: "20px" }} icon={faAngleLeft} onClick={() => setShowPlanDetails(false)} />
        </div>
        {/* Div */}
        <div className='sc-datePlanner-rb-div'>
          {/* Div 1 */}
          <div className='sc-datePlanner-rb-div-1'>
            <h2> {formatDate(plan.startDateTime !== "" && plan.startDateTime)}</h2>
          </div>

          <hr className='sc-datePlanner-rb-hr' />

          {/* Div 2 */}
          <div className='sc-datePlanner-rb-div-2'>
            <div className='sc-datePlanner-rb-div-2-img'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} alt="sc-sd-coffee" height={35} width={25} />
            </div>

            <div className='sc-datePlanner-rb-div-2-head'>
              <h4>{plan.title}</h4>
              <span>{plan.location}</span>
            </div>
          </div>

          {/* Div 3 */}
          <div className='sc-datePlanner-rb-div-3'>
            <form>
              {/* Location */}
              <label htmlFor="location" className='sc-single-plans'>
                <div className='flex justify-content-start'>
                  <img src={`${BASE_URL_STATIC}/sc-location.svg`} alt="sc-sd-repeat" height={20} width={20} id='location' name='location' />
                  <span style={{ fontSize: "18px", fontWeight: "500" }}>{plan.location && plan.location.length > 30 ? (plan.location.substr(0, 30) + "...") : plan.location}</span>
                </div>
                <small style={{ color: "#2676E1" }}>See map</small>
              </label> <br />

              {/* Date And Time */}
              <div className='flex justify-content-start' style={{color:"#2676E1"}}>
                <label htmlFor="repeat" className='sc-single-plans justify-content-start gap-3' style={{width:"306px" }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                  <div className='d-flex'>
                    <span className="sc-singlePlan-dates">
                      {formatDate(plan.startDateTime !== "" && plan.startDateTime)}
                    </span>
                  </div>
                </label>
                <label htmlFor="repeat" className='sc-single-plans gap-3 justify-content-start' >
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                  <div className='d-flex gap-2'>
                    <span className="">
                      {formatTime(plan.startDateTime !== "" && plan.startDateTime)}
                    </span>
                    <span>-</span>
                    <span className=""> 
                      {plan.allDay === true ? "All Day" : formatTime(plan.endDateTime !== "" && plan.endDateTime)}
                    </span>
                  </div>
                </label>
              </div>

              <br />


              {/* Repeat */}
              <label htmlFor="repeat" className='sc-single-plans' >
                <div className='flex justify-content-start'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                  <span style={{ fontSize: "18px", fontWeight: "500" }}>Repeat</span>
                </div>
                <span style={{ fontSize: "15px", fontWeight: "500" , color:"#2676E1" }}>{
                  (plan.repeat === "norepeat" && "No Repeat") ||
                  (plan.repeat === "daily" && "Daily") ||
                  (plan.repeat === "weekly" && "Weekly") ||
                  (plan.repeat === "monthly" && "Monthly") ||
                  (plan.repeat === "yearly" && "Yearly")
                }</span>
              </label> <br />


              {/* Alert */}
              {plan && plan.alertTime.map((e, i) => (
                <div key={i}>
                  <label htmlFor="alert" className='sc-single-plans' >
                    <div className='flex justify-content-start'>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-alert.png`} alt="sc-sd-repeat" height={20} width={20} />
                      <span style={{ fontSize: "18px", fontWeight: "500"}}>Alert</span>
                    </div>
                    <span style={{ fontSize: "15px", fontWeight: "500", color:"#2676E1"  }}>
                      {`- Before ` +
                        ((e === "5_min" && "5 min") || (e === "1_min" && "1 min") || (e === "1_week" && "1 week") || (e === "1_month" && "1 month"))
                      }</span>
                  </label>
                  <br />
                </div>
              ))}

              {
                plan.customAlertTime !== "T"  && <div>
                <label htmlFor="alert" className='sc-single-plans' >
                  <div className='flex justify-content-start'>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-alert.png`} alt="sc-sd-repeat" height={20} width={20} />
                    <span style={{ fontSize: "18px", fontWeight: "500" }}>Alert</span>
                  </div>
                  <span style={{ fontSize: "15px", fontWeight: "500" ,  color:"#2676E1" }}>
                    {`- Before ` + formatDate(plan.customAlertTime) + " " + formatTime(plan.customAlertTime)
                    }</span>
                </label>
                <br />
              </div>
              }

            </form>
          </div>

          {/* Div 4 */}
          <div className='sc-datePlanner-rb-div-4'>
            <span>Add to Google Calendar</span>
          </div>

          {/* Div 5 */}
        {plan.note &&  <div className='sc-datePlanner-rb-div-5'>
            <div className='sc-datePlanner-rb-div-5-notes'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} alt="sc-notes" height={20} width={20} />
              <span>Notes</span>
            </div>

            <span className='sc-datePlanner-rb-div-5-textArea'>{plan.note}</span>
          </div>}
        </div>
      </section>
    </>
  );
};

export default DatePlanner2;
