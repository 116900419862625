import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../../UseContext/ApiCall';
import { getValue } from '../../../UseContext/SettingHelper';
import { settingContext } from '../../../UseContext/SettingContext';
import { BASE_URL_STATIC } from '../../../../../config';

const OtherSetting = () => {

  const { 

    value, setValue,

    handleOptionChange,

  } = useContext(settingContext);

  const { config } = useContext(ApiContext);

  // To get value
  useEffect(() => {
    getValue('otherSetting', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <section className='vh-100 pt-4 pt-md-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden'>
        <div>
          {/* Breadcrumb */}
          <nav className='pt-4 pt-lg-5'>
            <ol className="breadcrumb bg-transparent p-0">
              <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
              <li className="breadcrumb-item active" aria-current="page">Other</li>
            </ol>
          </nav>

          <div className='accordion accordion-flush'>
            <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#darkMode">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='fw-semibold ms-3'>Light/Dark Mode</h5>
            </div>

            <div id='darkMode' className="ms-3 d-flex justify-content-between align-items-center">
              <h5 className='accordion-level-4 my-3'>Dark Mode</h5>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='darkMode' 
                  value={value.darkMode === 'true' ? 'false' : 'true'} onChange={(e) => handleOptionChange(e, 'otherSetting')} checked={value.darkMode === 'true'} 
                />
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default OtherSetting
