import React, { useContext, useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL_STATIC } from '../../../../config';
import { ApiContext } from '../../UseContext/ApiCall';
import { UpdatechatProfile } from '../../UseContext/ApiHelpers';
import { useParams } from 'react-router-dom';

const ChatProfileUpdate = ({ openProfile, closeProfile, chatProfile }) => {
   const [groupName, setGroupName] = useState('');
   const [groupDesc, setGroupDesc] = useState('');
   const [groupIcon, setGroupIcon] = useState(null);
   const [openEmoji, setOpenEmoji] = useState(false);
   const [opendesEmoji, setOpendesEmoji] = useState(false);
   const [createLoader, setCreateLoader] = useState(false);
   const { config , setisUpdate} = useContext(ApiContext);
   const fileRef = useRef();

   const {chatId} = useParams()

   useEffect(() => {
      if (chatProfile) {
         setGroupName(chatProfile.groupName || '');
         setGroupDesc(chatProfile.description || '');
         setGroupIcon(chatProfile.groupProfileImage || null);
      }
   }, [chatProfile]);

   const handleFileSelect = () => {
      if (fileRef.current) {
         fileRef.current.click();
      }
   };

   const handleEmojiPick = (e) => {
      setGroupName(prev => prev + e.emoji);
   };

   const handleEmojiPickDes = (e) => {
      setGroupDesc(prev => prev + e.emoji);
   };

   const handleCreate = async () => {
      if (groupName === "") {
         toast.error("Please enter group name");
      }  
      //  else if (groupIcon === null) {
      //    toast.error("Please upload group icon");
      // } 
      else {
         setCreateLoader(true);
         const formData = new FormData();
         formData.append("groupName", groupName);
         formData.append("isGroupChat", "true");
         formData.append("image", groupIcon);
         formData.append("chatId", chatId);
         formData.append("description", groupDesc);
         UpdatechatProfile(formData , config).then(() =>{ setisUpdate(true); setCreateLoader(false); closeProfile();})
      }
   };

   return (
      <div>
         <Modal show={openProfile} onHide={closeProfile} centered backdrop="static" keyboard={false} className="create-call modal-head-search" >
            <Modal.Header closeButton style={{ width: "100%" }}>
               <div className='create-group-header'>
                  <h5>Update Group Profile</h5>
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="group-final">
                  <form>
                     <input type="file" ref={fileRef} accept='image/*' style={{ display: "none" }} onChange={(e) => setGroupIcon(e.target.files[0])}   />

                     <span onClick={handleFileSelect} className='select-group-icon'>
                        {groupIcon ? <img className='select-group-icon-selected' src={URL.createObjectURL(groupIcon)} alt="" /> :
                           <img className='select-group-icon-dummy' src={`${BASE_URL_STATIC}/group.png`} alt="" />
                        }

                     </span>

                     <div className='group-name'>
                        <input type="text" value={groupName} className='group-name-input' placeholder='group name' onChange={(e) => setGroupName(e.target.value)} />

                        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpenEmoji(!openEmoji)} alt="" />

                        <span className='emoji-select-group'>
                           {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
                        </span>
                     </div>

                     <div className='group-desc'>
                        <textarea value={groupDesc} rows={8} maxLength={300} className='group-desc-input' placeholder='group description' onChange={(e) => setGroupDesc(e.target.value)} />

                        <img src={`${BASE_URL_STATIC}/emoji-smiley.png`} onClick={() => setOpendesEmoji(!opendesEmoji)} alt="" />

                        <span className='emoji-select-group'>
                           {opendesEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPickDes(res)} />}
                        </span>
                     </div>

                  </form>
               </div>

               <button className='group-create-button' onClick={handleCreate}>
                  {createLoader ? (
                     <span className="flex justify-content-center">
                        <FontAwesomeIcon
                           icon={faCircleNotch}
                           style={{ color: "snow" }}
                           spin
                        />{" "}
                        Updating...
                     </span>
                  ) : (
                     "Update"
                  )}
               </button>
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default ChatProfileUpdate;
