import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { ScApiContext } from "../useContext/softCornerContext";
import imageCompression from 'browser-image-compression';


const CreateNewPost = ({ open, posts, close }) => {
  
  const [postUrl, setPostUrl] = useState([]);
  const [description, setDescription] = useState("");
  const [postLoader, setPostLoader] = useState(false);

  const { scPostOption } = useContext(ScApiContext);

  const clearObjectURLs = () => {
    postUrl.forEach((url) => URL.revokeObjectURL(url));
  };

  useEffect(() => {
    if (Array.isArray(posts) && posts.length > 0) {
      const urls = posts.map((file) => URL.createObjectURL(file));
      setPostUrl(urls);
      return clearObjectURLs;
    }
  }, [posts]);

  // To handle create soft corner post
  const handleCreatePosts = async () => {
    setPostLoader(true);

    const formData = new FormData();

    formData.append("description", description);
    
    posts.map(async (e, i) => {

      if (posts[i].type.slice(0, 5) === 'image') {
        const compressedPhotoAndVideo = await imageCompression(posts[i], scPostOption);

        formData.append("image", compressedPhotoAndVideo);
      } else if (posts[i].type.slice(0, 5) === 'video') {
        console.log('video : ', posts[i]);
        formData.append("image", posts[i]);
      };

      return null;
    });

    // createPosts(formData, scConfig).then((res) => {
    //   setCreatePop(!open);
    //   setPostLoader(false);
    //   getPosts(scConfig).then((res) => setListPost(res));
    // });
  };

  return (
    <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
      <Modal.Header closeButton>&nbsp;</Modal.Header>
      <Modal.Body>
        <section className="sc-cnpp">
          <h5 className="sc-cnpp-head">Create New Post</h5>

          <Carousel className="SC-postCarousel" slide={false}>
            {postUrl && postUrl.map((item, index) => (
              <Carousel.Item key={index}>
                {posts && posts[index].type === "video/mp4" ? (
                  <video className="Sc-videoCarousel" controls autoPlay>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={item} className="Sc-imgCarousel" alt={`Media ${index}`} width="100%" />
                )}
              </Carousel.Item>
            ))}
          </Carousel>

          <div style={{ margin: "20px 0px 15px 13px" }}>
            <form>
              <label htmlFor="textArea"></label>
              <textarea name="textArea" id="textArea" value={description} onChange={(e) => setDescription(e.target.value)} cols="39" rows="5" placeholder="Write Something"
                style={{
                  fontSize: "20px",
                  padding: "5px",
                  border: "1px solid #EBEBEB",
                  borderRadius: "15px",
                  resize: "none",
                }}
              ></textarea>
            </form>
          </div>

          <button type="button" onClick={() => handleCreatePosts(posts)}
            style={{
              height: "50px",
              width: "425px",
              color: "white",
              backgroundColor: "#64A6F0",
              margin: "10px 0px 10px 13px",
              borderRadius: "23px",
              border: "none",
            }}
          >
            {
              postLoader 
              ? 
              <span className="flex justify-content-center">
                <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />{" "}
                posting...
              </span>
              :
              "post"
            }
          </button>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewPost;
