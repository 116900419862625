import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { DatePlannerCategory } from "../../../Emilo/Data/EducationData";
import { createDatePlanner } from "../../../Emilo/UseContext/ScApiHelpers";
import { ScApiContext } from "../../useContext/softCornerContext";
import { Accordion, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { BASE_URL_STATIC } from "../../../../config";

const NewPlan = ({ showNewPlanPopup, setShowNewPlanPopup }) => {

  const { scConfig } = useContext(ScApiContext);
  const [loader, setLoader] = useState(false)
  const [inputData, setinputData] = useState({
    title: "",
    setAlerts: [],
    repeat: "norepeat",
    category: "",
    location: "",
    startDate: "",
    startDateTime: "",
    endDate: "",
    endDateTime: "",
    startTime: "",
    endTime: "",
    notes: "",
    customDate: "",
    customTime: "",
    isAllDay: false,
    customAlertTime: ""
  });

  useEffect(() => {
    setLoader(false)
  }, [])

  const [toggle, setToggle] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "isAllDay") {
        setinputData((prevInputData) => ({
          ...prevInputData,
          [name]: checked,
          ...(checked && { endDate: "", endTime: "" }),
        }));
      } else {
        const newSetAlerts = checked
          ? [...inputData.setAlerts, name]
          : inputData.setAlerts.filter((alert) => alert !== name);

        setinputData((prevInputData) => ({
          ...prevInputData,
          setAlerts: newSetAlerts,
        }));
      }

      if (inputData.customDate > Date.now()) {
        toast.error("invalid date")
      }
    } else {
      setinputData((prevInputData) => ({
        ...prevInputData,
        [name]: value,
      }));
    }
  };

  console.log("Payload", {
    ...inputData,
    startDateTime: `${inputData.startDate}T${inputData.startTime}`,
    endDateTime: inputData.isAllDay ? "" : `${inputData.endDate}T${inputData.endTime}`,
    customAlertTime: `${inputData.customDate}T${inputData.customTime}`,
  });

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    const toLocalISOString = (dateString, timeString) => {
      if (!dateString || !timeString) {
        return "";
      }
      const combinedDateTimeString = `${dateString}T${timeString}`;
      const combinedDateTime = new Date(combinedDateTimeString);
      const localISOString = new Date(
        combinedDateTime.getTime() - combinedDateTime.getTimezoneOffset() * 60000
      ).toISOString();

      return localISOString;
    };

    const isoFormatStart = toLocalISOString(
      inputData.startDate,
      inputData.startTime
    );
    const isoFormatEnd = toLocalISOString(inputData.endDate, inputData.endTime);
    
    // const customIsoFormat = toLocalISOString(
    //   inputData.customDate,
    //   inputData.customTime
    // );

    if (!inputData.title) {
      toast.error("please fill title")
      setLoader(false)
    }
    else if (!inputData.category) {
      toast.error("please select category")
      setLoader(false)
    }
    else if (!inputData.location) {
      toast.error("please add location")
      setLoader(false)
    }
    else if (!isoFormatStart) {
      toast.error("Please select start date and time");
      setLoader(false);
    } else if (
      !inputData.isAllDay &&
      (new Date(isoFormatEnd) < new Date(isoFormatStart) ||
        !inputData.endTime)
    ) {
      toast.error("Invalid end date or time");
      setLoader(false);
    } else if (new Date(isoFormatEnd) < Date.now()) {
      toast.error("Invalid end date. Please select a future date");
      setLoader(false);
    } else if (new Date(isoFormatStart) < Date.now()) {
      toast.error("Invalid start date");
      setLoader(false);
    }
    else if (inputData.customDate !== "" && inputData.customTime === "") {
      toast.error("please fill custom time")
      setLoader(false);
    }
    else if (inputData.customDate === "" && inputData.customTime !== "") {
      toast.error("please fill custom date")
      setLoader(false);
    }

    else {
      try {
        const res = await createDatePlanner(
          {
            ...inputData,
            startDateTime: `${inputData.startDate}T${inputData.startTime}`,
            endDateTime: inputData.isAllDay ? "" : `${inputData.endDate}T${inputData.endTime}`,
            customAlertTime: `${inputData.customDate}T${inputData.customTime}`
          },
          scConfig
        );

        toast.success(res.message)
        setShowNewPlanPopup(false)

        setinputData({
          title: "",
          setAlerts: [],
          repeat: "",
          category: "",
          location: "",
          startDate: "",
          startDateTime: "",
          endDate: "",
          endDateTime: "",
          startTime: "",
          endTime: "",
          notes: "",
          customDate: "",
          customTime: "",
          isAllDay: false,
          customAlertTime: ""
        })

        setLoader(false)

      } catch (error) {
        toast.error("error")
        setLoader(false)
      }
    }
  };

  return (
    <>
      <Modal
        show={showNewPlanPopup}
        onHide={() => setShowNewPlanPopup(false)}
        size="md"
        centered
        backdrop="static"
        keyboard={false}
        className="create-call"
      >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section className="newPlanPopup">
            <h6 className="newPlanPopup-head">New Plan</h6>

            <section className="newPlanPopup-formField">
              <form onSubmit={handleSubmit}>
                {/* Title */}
                <label
                  htmlFor="title"
                  className="newPlanPopup-formField-label-all newPlanPopup-formField-title-label"
                >
                  <img
                    src={`${BASE_URL_STATIC}/SoftCorner/sc-title.png`}
                    alt="sc-title"
                    height={20}
                    width={20}
                  />
                  <span>Title</span>
                </label>{" "}
                <br />
                <input
                  type="text"
                  className="newPlanPopup-formField-input-all newPlanPopup-formField-title-input"
                  value={inputData.title}
                  id="title"
                  maxLength={"30"}
                  name="title"
                  onChange={handleChange}
                  placeholder="Enter Title"
                />{" "}
                <br />
                {/* Category */}
                <label
                  htmlFor="category"
                  className="newPlanPopup-formField-label-all newPlanPopup-formField-category-label"
                >
                  <img
                    src={`${BASE_URL_STATIC}/SoftCorner/sc-category.png`}
                    alt="sc-category"
                    height={17}
                    width={17}
                  />
                  <span>Category</span>
                </label>{" "}
                <br />
                <select
                  id="category"
                  name="category"
                  className="newPlanPopup-formField-input-all newPlanPopup-formField-category-input"
                  value={inputData.category}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    select
                  </option>
                  {DatePlannerCategory.map((plan, index) => (
                    <option key={index} value={plan.name}>{plan.name}</option>
                  ))}
                </select>
                {/* Location */}
                <label
                  htmlFor="location"
                  className="newPlanPopup-formField-label-all newPlanPopup-formField-location-label"
                >
                  <img
                    src={`${BASE_URL_STATIC}/SoftCorner/sc-location.png`}
                    alt="sc-location"
                    height={17}
                    width={15}
                  />
                  <span>Location</span>
                </label>{" "}
                <br />
                <input
                  type="text"
                  className="newPlanPopup-formField-input-all newPlanPopup-formField-location-input"
                  value={inputData.location}
                  id="location"
                  name="location"
                  maxLength={70}
                  onChange={handleChange}
                  placeholder="Enter Location Name"
                />{" "}
                <br />
                {/* Date & Time */}
                <label
                  htmlFor="title"
                  className="newPlanPopup-formField-label-all newPlanPopup-formField-dateAndTime-label"
                >
                  <img
                    src={`${BASE_URL_STATIC}/SoftCorner/sc-DateAndTime.png`}
                    alt="sc-DateAndTime"
                    height={17}
                    width={17}
                  />
                  <span>Date & Time</span>
                </label>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label="All Day"
                  name="isAllDay"
                  checked={inputData.isAllDay}
                  onChange={handleChange}
                />{" "}
                <br />
                <label
                  htmlFor="start"
                  className="newPlanPopup-formField-dat-label"
                >
                  Starts
                </label>{" "}
                <br />
                <input
                  type="date"
                  className="newPlanPopup-formField-dat-date"
                  value={inputData.startDate}
                  name="startDate"
                  id="start"
                  onChange={handleChange}
                />
                <input
                  type="time"
                  className="newPlanPopup-formField-dat-time"
                  value={inputData.startTime}
                  name="startTime"
                  id="start"
                  onChange={handleChange}
                />{" "}
                <br />
                {!inputData.isAllDay && (
                  <>
                    <label
                      htmlFor="ends"
                      className="newPlanPopup-formField-dat-label"
                    >
                      Ends
                    </label>{" "}
                    <br />
                    <input
                      type="date"
                      className="newPlanPopup-formField-dat-date"
                      value={inputData.endDate}
                      name="endDate"
                      id="ends"
                      onChange={handleChange}
                    />
                    <input
                      type="time"
                      className="newPlanPopup-formField-dat-time"
                      value={inputData.endTime}
                      name="endTime"
                      id="ends"
                      onChange={handleChange}
                    />{" "}
                    <br />
                  </>
                )}
                <br />
                <label
                  htmlFor="repeat"
                  className="newPlanPopup-formField-dat-label"
                >
                  Repeat
                </label>
                <select
                  id="repeat"
                  name="repeat"
                  className="newPlanPopup-formField-input-all newPlanPopup-formField-category-input"
                  value={inputData.repeat}
                  onChange={handleChange}
                >
                  <option value="norepeat">No Repeat</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {/* Set Alert */}
                <div style={{ position: "relative" }}>

                  <label
                    htmlFor="setAlert"
                    className="newPlanPopup-formField-label-all newPlanPopup-formField-setAlert-label"
                  >
                    <img
                      src={`${BASE_URL_STATIC}/SoftCorner/sc-alert.png`}
                      alt="sc-alert"
                      height={20}
                      width={20}
                    />
                    <span>Set Alert</span>
                  </label>{" "}
                  <div style={{ position: "absolute", right: "0px", top: "0px" }}>
                    <button className="customBtn" type="button" onClick={() => setToggle(!toggle)}>custom</button>
                  </div>
                </div>

                <div className="sc-alerts my-3">
                  <span>
                    <label htmlFor="1_min"></label>
                    <input
                      type="checkbox"
                      id="1_min"
                      name="1_min"
                      checked={inputData.setAlerts.includes("1_min")}
                      onChange={handleChange}
                    />
                    1 min
                  </span>
                  <span>
                    <label htmlFor="5_min"></label>
                    <input
                      type="checkbox"
                      id="5_min"
                      name="5_min"
                      checked={inputData.setAlerts.includes("5_min")}
                      onChange={handleChange}
                    />
                    5 min
                  </span>
                  <span>
                    <label htmlFor="1_week"></label>
                    <input
                      type="checkbox"
                      id="1_week"
                      name="1_week"
                      checked={inputData.setAlerts.includes("1_week")}
                      onChange={handleChange}
                    />
                    1 week
                  </span>
                  <span>
                    <label htmlFor="1_month"></label>
                    <input
                      type="checkbox"
                      id="1_month"
                      name="1_month"
                      checked={inputData.setAlerts.includes("1_month")}
                      onChange={handleChange}
                    />
                    1 month
                  </span>
                </div>

                {/* Accordian */}

                <Accordion activeKey={toggle ? "0" : null}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Body>
                      <input
                        type="date"
                        className="newPlanPopup-formField-dat-date"
                        value={inputData.customDate}
                        name="customDate"
                        id="customDate"
                        onChange={handleChange}
                      />
                      <input
                        type="time"
                        className="newPlanPopup-formField-dat-time"
                        value={inputData.customTime}
                        name="customTime"
                        id="customTime"
                        onChange={handleChange}
                      />{" "}
                    </Accordion.Body>
                  </Accordion.Item></Accordion>

                {/* Notes */}
                <label
                  htmlFor="notes"
                  className="newPlanPopup-formField-label-all newPlanPopup-formField-notes-label"
                >
                  <img
                    src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`}
                    alt="sc-notes"
                    height={20}
                    width={17}
                  />
                  <span>Notes</span>
                </label>{" "}
                <br />
                <textarea
                  className="newPlanPopup-formField-notes-textArea"
                  value={inputData.notes}
                  id="notes"
                  name="notes"
                  rows={5}
                  cols={40}
                  onChange={handleChange}
                  placeholder="Write something here..."
                />{" "}
                <br />
              </form>
            </section>

            <div className="newPlan-Btn">

              <Button style={{ backgroundColor: "#64A6F0" }} onClick={handleSubmit} >{!loader ?
                "Next" : <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> please wait </span>}
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewPlan;


