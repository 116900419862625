import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_STATIC, BASEAPIURL, formatedDate, formatSecondsToTime, getTodayDate, headerUserAPI } from '../../../../../config'
import { ApiContext } from '../../../UseContext/ApiCall';
import { createValue, getValue } from '../../../UseContext/SettingHelper';
import axios from 'axios';
import { Chart } from "react-google-charts";
// https://codesandbox.io/p/sandbox/github/RakanNimer/react-google-charts/tree/master/sandboxes/bar-chart/labels?file=%2FApp.tsx%3A31%2C18-31%2C26&from-embed
export const options = {
  title: "Last 7 Days Usages",
  // width: 600,
  // height: 400,
  // bar: { groupWidth: "95%" },
  legend: { position: "none" },
};

const UsageTime = () => {

  const [value, setValue] = useState('');
  const [weeklyUsages, setweeklyUsages] = useState([]);
  const [dailyAverage, setDailyAverage] = useState(0);

  // To handle value change
  const handleValueChange = async (e) => {
    const { name, value } = e.target;
    setValue(value);
    await createValue('usageTime', name, value, config);
    window.location.reload();
  };

  async function getWeeklyUsages() {
    try {
        const headers = headerUserAPI(); // Assuming this returns headers
        const temp = await axios.post(`${BASEAPIURL}/user/getWeeklyUsages?ry=sjain`, { forDate: getTodayDate(), get: new Date() }, { headers: headers });
        if (temp && temp.data && temp.data.averageSeconds) {
          setDailyAverage(temp.data.averageSeconds);
        }
        if (temp && temp.data && temp.data.data && temp.data.data.length > 0) {
          const graphData = [['Date', 'Usages in Minutes']];
          temp.data.data.reverse().forEach((item) => {
            // console.log("Item:", item.forDate);
            graphData.push([formatedDate(item.forDate), item.seconds/60])
            // You can perform any other actions here with each item
          });
          setweeklyUsages(graphData);
        }
    } catch (error) {
        console.error(error.message);
        setDailyAverage(0);
        setweeklyUsages([]);
        // You could also handle displaying error feedback to the user here
    }
  }

  const { config } = useContext(ApiContext);

  // To get value
  useEffect(() => {
    getWeeklyUsages()
    getValue('usageTime', config)
    .then(res => {
      setValue(res.data.message.setReminder);
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  const reminderValue = [
    { id: 7, value: '0.5', title: '1 Minute' },
    { id: 1, value: '15', title: '15 Minutes' },
    { id: 2, value: '30', title: '30 Minutes' },
    { id: 3, value: '60', title: '1 Hours' },
    { id: 4, value: '90', title: '1 Hours and 30 Minutes' },
    { id: 5, value: '120', title: '2 Hours' },
    { id: 6, value: '84400', title: 'Off' }
  ];

  return (
    <>
      <section className='vh-100 pt-3 pt-lg-4 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='usageTimeToggle1'>
        <div className='accordion accordion-flush pt-5'>
          {/* Breadcrumb */}
          <nav className=''>
            <ol className="breadcrumb bg-transparent p-0">
              <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
              <li className="breadcrumb-item active" aria-current="page">Usage Time</li>
            </ol>
          </nav>
          
          {/* Time Spent */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#timeSpent">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='ms-3 fw-semibold'>Time Spent</h5>
            </div>

            <div id='timeSpent' className="accordion-collapse collapse show" data-bs-parent="#usageTimeToggle1">
              {/* <span>In this section we have to show App usage details – Average time spent, No. of visits, duration of use, day or night time usage</span> */}
              <div className="card boxShadow rounded-0">
                <div className="card-body">
                  <p className='mb-2 text-center text-info'>
                    Your Daily Average usages on the eMilo is {formatSecondsToTime(dailyAverage)}.
                  </p>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={weeklyUsages}
                    options={options}
                  />
                </div>
              </div>

              {/* <hr className='hr bg-secondary' /> */}
            </div>
          </div>

          {/* Set Reminders */}
          <div className='mt-3'>
            <div className='accordion-level-1 shadow-sm accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#setReminder">
              <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
              <h5 className='ms-3 fw-semibold'>Set Reminders</h5>
            </div>

            <div id='setReminder' className="accordion-collapse collapse" data-bs-parent="#usageTimeToggle1">

              <div className="card boxShadow rounded-0">
                <div className="card-body">
                  
                  <div className='d-flex flex-column align-items-start'>
                    <h5>Apply daily limit on App usage</h5>

                    <div className='mt-3 ms-3 d-flex flex-column'> 
                      <span className='text-secondary mb-2'>You will be reminded to close the app once you spend above set time limit</span>

                      {reminderValue.map((s) => (
                        <div key={s.value} className="form-check mb-2">
                          <input
                            type="radio"
                            className="form-check-input"
                            id={`setReminder-${s.value}`}
                            name="setReminder"
                            value={s.value}
                            checked={value === s.value}
                            onChange={handleValueChange}
                          />
                          <label className="form-check-label" htmlFor={`setReminder-${s.value}`}>
                            {s.title}
                          </label>
                        </div>
                      ))}
                        
                    </div>
                  </div>
                  
                </div>
              </div>


             
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default UsageTime
