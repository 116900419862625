import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import toast from 'react-hot-toast';


const BusinessActiveHoursPopup = () => {

  const { 
    showActiveHoursPopup, setShowActiveHoursPopup, 
    activeHours, setActiveHours,
    createActiveHours,
  } = useContext(businessContext);

  const [days, setDays] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  // To handle day click
  const handleDayClick = (e) => {
    const value = e.target.innerText.toLowerCase();
    const selectedDays = Object.keys(days).filter(day => days[day]);
  
    if (!days[value] && selectedDays.length >= 2) {
      toast.error("You can only select two days");
      return;
    };
  
    setDays(prev => {
      const updatedDays = { ...prev, [value]: !prev[value] };
      const newSelectedDays = Object.keys(updatedDays).filter(day => updatedDays[day]);
      setActiveHours({ ...activeHours, startDay: newSelectedDays[0] || '', endDay: newSelectedDays[1] || '' });
      return updatedDays;
    });
  };

  const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  return (
    <>
      <Modal show={showActiveHoursPopup} onHide={() => setShowActiveHoursPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Active Hours</h4>

          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-4'>Time</span>

          <div className='d-flex flex-column flex-sm-row align-items-center ms-3 mb-5'>
            {/* Start Time */}
            <input type="time" className='form-control border-0 border-bottom' name='startTime'
              value={activeHours.startTime} onChange={(e) => setActiveHours((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
            />

            <span className='mx-3'>To</span>

            {/* End Time */}
            <input type="time" className='form-control border-0 border-bottom' name='endTime'
              value={activeHours.endTime} onChange={(e) => setActiveHours((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
            />
          </div>

          {/* Day */}
          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-4'>Day</span>

          <div className='ms-3 mb-5'>
            {
              week.map((day, index) => (
                <span key={index} className={`${days[day] ? 'bg-primary text-white' : 'border text-secondary'} rounded-3 p-2 me-2 mb-2 text-capitalize`}onClick={handleDayClick}>{day}</span>
              ))
            }
          </div>

          <div className='text-center'>
            <button type="button" className='btn btn-primary rounded-pill w-50' onClick={createActiveHours}>Done</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessActiveHoursPopup
