import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BASEURL, BASE_URL_STATIC } from '../../../../config';
import ProposeOptionMemories from '../../Popup/ProposeOptionMemories/ProposeOptionMemories';

const ProposeOption = () => {

  const [showProposeOptionMemoriesPopup, setShowProposeOptionMemoriesPopup] = useState(false);
  
  return (
    <>
      <ProposeOptionMemories showProposeOptionMemoriesPopup={showProposeOptionMemoriesPopup} setShowProposeOptionMemoriesPopup={setShowProposeOptionMemoriesPopup} />

      <section className='proposeOption'>
        <img className='proposeOption-logo' src={`${BASE_URL_STATIC}/SoftCorner/po-logo.png`} alt="atlp-logo" height={60} width={60} />

        <div className='proposeOption-head'>Propose in a way that you prefer</div>

        {/* Couple Image */}
        <div className='proposeOption-couple'>
          <img className='proposeOption-couple-male' src={`${BASE_URL_STATIC}/SoftCorner/po-male.png`} alt="po-male" height={250} width={250} />
          <img className='proposeOption-couple-female' src={`${BASE_URL_STATIC}/SoftCorner/po-female.png`} alt="po-female" height={275} width={275} />
        </div>

        {/* Propose Option */}
        <div className='proposeOption-options'>
          <div className='proposeOption-options-all'>
            <img className='proposeOption-options-all-img1' src={`${BASE_URL_STATIC}/SoftCorner/po-circle.png`} alt="po-circle" height={25} width={25} />
            <Link to={`${BASEURL}/SoftCorner/ProposeOption-Message`}>
              <img className='proposeOption-options-all-img2' src={`${BASE_URL_STATIC}/SoftCorner/po-message.png`} alt="po-message" height={140} width={140} />
            </Link>
            <Link to={`${BASEURL}/SoftCorner/ProposeOption-Message`}>Message</Link>
          </div>

          <div className='proposeOption-options-all'>
            <img className='proposeOption-options-all-img1' src={`${BASE_URL_STATIC}/SoftCorner/po-circle.png`} alt="po-circle" height={25} width={25} />
            <img className='proposeOption-options-all-img2' src={`${BASE_URL_STATIC}/SoftCorner/po-memories.png`} alt="po-memories" height={140} width={140} onClick={() => setShowProposeOptionMemoriesPopup(true)} />
            <Link onClick={() => setShowProposeOptionMemoriesPopup(true)}>Memories</Link>
          </div>

          <div className='proposeOption-options-all'>
            <img className='proposeOption-options-all-img1' src={`${BASE_URL_STATIC}/SoftCorner/po-circle.png`} alt="po-circle" height={25} width={25} />
            <Link to={`${BASEURL}/SoftCorner/ProposeOption-Gifts`}>
              <img className='proposeOption-options-all-img2' src={`${BASE_URL_STATIC}/SoftCorner/po-gifts.png`} alt="po-gifts" height={140} width={140} />
            </Link>
            <Link to={`${BASEURL}/SoftCorner/ProposeOption-Gifts`}>Gifts</Link>
          </div>
        </div>

        <Link to={`${BASEURL}/SoftCorner/ProposeOption`} className='proposeOption-button'>Next</Link>
      </section>
    </>
  );
};

export default ProposeOption;
