import React, { useContext } from "react";
import ProfileUser from "./ProfileUser/ProfileUser";
import ProfileNavigate from "./ProfileNavigate/ProfileNavigate";
import { Outlet } from "react-router-dom";
import { ApiContext } from "../UseContext/ApiCall";
import AboutUser from "./ProfileUser/ProfileUserShare/AboutUser";


const Profile = () => {

  const { switchAbout } = useContext(ApiContext);

  document.title = "Emilo-profile";
  
  return (
    <>
      <section className="border border-white ">
        <ProfileUser />
        {!switchAbout ? 
          <>
          <ProfileNavigate />
          <Outlet />
          </> : <AboutUser/>
        }
        {/* <BackToTop /> */}
      </section>
    </>
  );
}

export default Profile;
