import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";

const ProfilePostCommentThreeDotPopup = ({ showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup, comment, getPostComment }) => {

  const { config, loggedInUserId } = useContext(ApiContext);

  // console.log(comment);

    // To delete comment of post
    const deletePostComment = (postId, parentCommentId, config) => {
      axios.post('https://emilonode.sjain.io/post/deletepostcomment', { postId: postId, parentCommentId: parentCommentId }, config)
      .then(res => {
        console.log('delete comment : ', res.data.message);
        setShowProfilePostCommentThreeDotPopup(false);
        getPostComment(postId, config);

        // To delete notification
        axios.post('https://emilonode.sjain.io/user/deletecommenteminnotify', { postId: postId, parentCommentId: parentCommentId }, config)
        .then(res => {
          console.log('delete comment notification : ', res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
      })
      .catch(err => {
        console.log(err);
      });
    };

  return (
    <>
      {
        comment
        &&
        comment.commentUserId === loggedInUserId
        ?
        <Modal show={showProfilePostCommentThreeDotPopup} onHide={() => setShowProfilePostCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter"  centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text profilePostThreeDotPopup-text-1" onClick={() => deletePostComment(comment.postId, comment.parentCommentId, config)}>Delete Comment</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfilePostCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
        :
        <Modal show={showProfilePostCommentThreeDotPopup} onHide={() => setShowProfilePostCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text" onClick={() => console.log('report')}>Report</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfilePostCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default ProfilePostCommentThreeDotPopup
