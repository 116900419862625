import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../UseContext/BusinessContext';


const ApplyJobPopup = () => {

  const { showApplyJobPopup, setShowApplyJobPopup } = useContext(businessContext);

  return (
    <>
      <Modal show={showApplyJobPopup} onHide={() => setShowApplyJobPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h5 className='text-center mb-4 fw-semibold'>Hair Artist</h5>

            <small className='text-secondary'>Full Name</small>
            <input type="text" className='form-control border border-0 border-bottom shadow-none mb-4' placeholder='Enter Full Name...' />

            <small className='text-secondary'>Mobile</small>
            <input type="text" className='form-control border border-0 border-bottom shadow-none mb-4' placeholder='+91 74157 47501...' />

            <small className='text-secondary'>Email</small>
            <input type="text" className='form-control border border-0 border-bottom shadow-none mb-4' placeholder='siddharth@gmail.com...' />

            <small className='text-secondary'>Introduce Yourself/Cover Letter</small>
            <textarea class="form-control mt-1 mb-4" placeholder="Write about yourself..."></textarea>

            <small className='text-secondary'>Resume</small>

            <div className='d-flex align-items-center gap-3 justify-content-center rounded-4 py-3 mt-1 mb-3' style={{ border: '1px dashed gray' }}>
              <div>
                <img src={`/images/business/icons/apply-job-resume.svg`} style={{ height: '40px' }} alt="apply-job-resume" />
              </div>

              <div>
                <div><span className='text-primary fw-semibold'>Upload</span> <span className='text-secondary'>or drop your file here</span></div>
                <div>
                  <span className='fw-semibold me-2'>Accepted file types :</span> 
                  <small className='badge bg-primary rounded-pill me-2'>PDF</small>
                  <small className='badge bg-primary rounded-pill me-2'>JPEG</small>
                  <small className='badge bg-primary rounded-pill'>JPG</small>
                </div>
              </div>
            </div>

            <div className='text-center'>
              <button type="button" className='btn btn-primary rounded-pill w-50'>Apply</button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ApplyJobPopup
