import React from 'react';


const BusinessHomeTagged = () => {

  const photos = [
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
    'https://images.pexels.com/photos/584314/pexels-photo-584314.jpeg',
    'https://images.pexels.com/photos/2085773/pexels-photo-2085773.jpeg',
    'https://images.pexels.com/photos/371985/pexels-photo-371985.jpeg',
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
    'https://images.pexels.com/photos/1855579/pexels-photo-1855579.jpeg',
    'https://images.pexels.com/photos/584314/pexels-photo-584314.jpeg',
    'https://images.pexels.com/photos/2085773/pexels-photo-2085773.jpeg',
    'https://images.pexels.com/photos/371985/pexels-photo-371985.jpeg',
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
    'https://images.pexels.com/photos/1855579/pexels-photo-1855579.jpeg',
    'https://images.pexels.com/photos/1536619/pexels-photo-1536619.jpeg',
    'https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg',
    'https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg',
    'https://images.pexels.com/photos/2065203/pexels-photo-2065203.jpeg',
    'https://images.pexels.com/photos/2813995/pexels-photo-2813995.jpeg',
    'https://images.pexels.com/photos/1855579/pexels-photo-1855579.jpeg',
    'https://images.pexels.com/photos/584314/pexels-photo-584314.jpeg',
    'https://images.pexels.com/photos/2085773/pexels-photo-2085773.jpeg',
    'https://images.pexels.com/photos/371985/pexels-photo-371985.jpeg',
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
    'https://images.pexels.com/photos/584314/pexels-photo-584314.jpeg',
    'https://images.pexels.com/photos/2085773/pexels-photo-2085773.jpeg',
    'https://images.pexels.com/photos/371985/pexels-photo-371985.jpeg',
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
    'https://images.pexels.com/photos/1855579/pexels-photo-1855579.jpeg',
    'https://images.pexels.com/photos/584314/pexels-photo-584314.jpeg',
    'https://images.pexels.com/photos/2085773/pexels-photo-2085773.jpeg',
    'https://images.pexels.com/photos/371985/pexels-photo-371985.jpeg',
    'https://images.pexels.com/photos/1040945/pexels-photo-1040945.jpeg',
    'https://images.pexels.com/photos/844864/pexels-photo-844864.jpeg',
    'https://images.pexels.com/photos/7260432/pexels-photo-7260432.jpeg',
  ];

  return (
    <>
      <section className='business-home-tagged'>
        {
          photos.map((photo, index) => (
            <div className='rounded-4 shadow-sm' style={{ height: '225px' }} key={index}>
              <img src={photo} className='h-100 w-100 object-fit-cover rounded-4 shadow-sm' alt="tagged" />
            </div>
          ))
        }
      </section> 
    </>
  )
}

export default BusinessHomeTagged
