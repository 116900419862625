import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../../UseContext/ApiCall';
import toast from 'react-hot-toast';

const EditPostPopup = ({ showEditPostPopup, setShowEditPostPopup, setShowProfilePostThreeDotPopup, postsPopupData, setPostsPopupData }) => {

  const [editPostPopupData, setEditPostPopupData] = useState({
    location: '',
    description: ''
  });

  const { config } = useContext(ApiContext);

  // it will set previous data to fields
  useEffect(() => {
    postsPopupData
    &&
    setEditPostPopupData({
      location: postsPopupData.title,
      description: postsPopupData.description
    });
  }, [postsPopupData]);

  // it will change data
  const handleEditPostPopupDataChange = (e) => {
    const { name, value } = e.target;

    setEditPostPopupData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // To handle edit post
  const handleEditPost = async (editPostPopupData, config) => {

    await axios.post('https://emilonode.sjain.io/post/editPost', { 
      postId: postsPopupData.postId, 
      location: editPostPopupData.location, 
      description: editPostPopupData.description 
    }, config)
    .then(res => {
      console.log('res : ', res);

      setShowEditPostPopup(false);
      setShowProfilePostThreeDotPopup(false);

      toast.success(res.data.message);

      setPostsPopupData(preVal => ({
        ...preVal,
        title: editPostPopupData.location,
        description: editPostPopupData.description
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  return (
    <>
      <section className='editPostPopups'>
        <Modal show={showEditPostPopup} onHide={() => setShowEditPostPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='editPostPopup'>
              <h5 className='editPostPopup-head'>Edit Post</h5>

              <form className='editPostPopup-form'>
                <label htmlFor="location">
                  <input type="text" value={editPostPopupData.location} onChange={handleEditPostPopupDataChange} id='location' name='location' placeholder='Location...' />
                </label> <br />

                <textarea value={editPostPopupData.description} onChange={handleEditPostPopupDataChange} id="description" name="description" cols="51" rows="10" placeholder='Description...'></textarea>

                <span className='editPostPopup-btn' onClick={() => handleEditPost(editPostPopupData, config)}>Edit Post</span>
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default EditPostPopup
