import React, { useEffect, useState } from 'react'
import { getMediaFiles } from '../../UseContext/ApiHelpers'
import { AwsFileUrl } from '../../../../config'

const GetMediaFiles = ({ chatId, config }) => {
    const [mediaData, setMediaData] = useState([])

    useEffect(() => {
        getMediaFiles(chatId, config).then((res) => {
            setMediaData(res)
        })
    }, [])

    return (
        <div className='d-flex flex-wrap ms-4'>
            {mediaData.map((e, index) => (
                <div key={index} className='border-2 bg-slate-300 rounded-md p-1 flex info_set'>
                    <img className='object-fit-cover' src={AwsFileUrl + (e.thumbnail || e.fileUrl)} alt="" srcset="" />
                </div>
            ))}
        </div>
    )
}

export default GetMediaFiles