import React from 'react'

const TermsAndConditions = () => {
  return (
    <>
      <section className='termsAndConditions'>
        <h1 className='termsAndConditions-header'>Terms And Conditions</h1>

        <div className='termsAndConditions-area'>
          <h2 className='termsAndConditions-head'>First</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>

          <h2 className='termsAndConditions-head'>Second</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Third</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Fourth</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>

          <h2 className='termsAndConditions-head'>Fifth</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Six</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>

          <h2 className='termsAndConditions-head'>First</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>

          <h2 className='termsAndConditions-head'>Second</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Third</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Fourth</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>

          <h2 className='termsAndConditions-head'>Fifth</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
          
          <h2 className='termsAndConditions-head'>Six</h2>
          <span className='termsAndConditions-msg'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquam tenetur optio suscipit quisquam assumenda commodi laboriosam est amet enim repellat saepe quae quod eos laborum vero eligendi a ipsam, dolor dolorum, sit repudiandae! Consectetur dolor sapiente quaerat alias mollitia esse, architecto ea cumque, nihil facere rerum distinctio nostrum quis aliquid deleniti commodi! Accusamus libero delectus officia dignissimos laboriosam? Fugiat, architecto non dolor aliquid facere officiis illum repellendus. Veniam dolore assumenda itaque eum facere natus quibusdam harum at, officiis porro laboriosam quasi quod quas repudiandae voluptatem deserunt consequuntur odit necessitatibus sint voluptas voluptatum? Culpa, accusantium eos facere consequatur ut numquam quae!</span>
        </div>
      </section> 
    </>
  )
}

export default TermsAndConditions
