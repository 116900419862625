import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Link,NavLink } from 'react-router-dom';

const HeaderSkeleton = () => {
  return (
    <>
      {/* Create Post Popup */}

      <header className="header-area header-emilo-width">
        <div className="container-fluid">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-3 col-12">
                <div className="logo-box">
                 <Skeleton width={200} height={50}/>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="input-box">
                  <form action="#">
                    <span>
                    <Skeleton width={400} height={45} style={{borderRadius:"30px"}}/>
                    </span>
                  </form>
                </div>
              </div>
              <div className="col-lg-2 col-12">
                <div className="btn-box text-center">
                  <span >
                    <Skeleton width={200} style={{marginLeft:"40px"}} borderRadius={50} height={45} />
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="notify-box">
                  <Link >
                    <Skeleton width={30} height={30}/>
                  </Link>
                  <NavLink>
                    <Skeleton width={30} height={30}/>
                  </NavLink>
                  <Link>
                    <Skeleton width={30} height={30}/>
                  </Link>
                  <Skeleton width={60} height={50}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderSkeleton