import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL_STATIC } from '../../../config';

const GallaryExplore = () => {
  return (
    <>
      <section className='sc-gallary'>

        <section>
          {/* Navbar */}
          <div className='sc-gallary-nav'>
            <Link to={`/SoftCorner/Gallary`} className='sc-gallary-nav-div'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`} alt="sc-gallary-photos" height={30} width={30} />
              <span>Photos</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Album`} className='sc-gallary-nav-div'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`} alt="sc-gallary-album" height={30} width={30} />
              <span>Album</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Explore`} className='sc-gallary-nav-div'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`} alt="sc-gallary-explore" height={30} width={30} />
              <span>Explore</span>
            </Link>
          </div>

          {/* Div 1 */}
          <div className='sc-gallary-explore-div-1'>
            <img src={`${BASE_URL_STATIC}/cp-5.png`} alt="cp-1" height={175} width={375} />
            <img src={`${BASE_URL_STATIC}/cp-2.png`} alt="cp-2" height={175} width={375} />
            <img src={`${BASE_URL_STATIC}/cp-3.png`} alt="cp-3" height={175} width={375} />
          </div>

          {/* Memories */}
          <div className='sc-gallary-memories'>
            <h6 className='sc-gallary-memories-head'>Memories</h6>

            <div className='sc-gallary-memories-images'>
              <img src={`${BASE_URL_STATIC}/cp-3.png`} alt="cp-1" height={275} width={375} />
              <img src={`${BASE_URL_STATIC}/cp-5.png`} alt="cp-2" height={275} width={375} />
            </div>

          </div>
        </section>
      </section>
    </>
  )
}

export default GallaryExplore
