import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ChatCenterBlock from './Chat/ChatCenterBlock';
import CallCenterBlock from './Call/CallCenterBlock';
import ChatRigthBlock from './Chat/ChatRigthBlock';
import CallRigthBlock from './Call/CallRigthBlock';
import { BASE_URL_STATIC } from '../../../config';

const Chats = () => {

  const [cbShow, setCbShow] = useState(false);
  const [chatRbUser, setChatRbUser] = useState();
  const [callRbUser, setCallRbUser] = useState();

  const { chatId } = useParams();

  // console.log('params : ', chatId);

  document.title = "Emilo-Chat";
  
  return (
    <>
      <section className="row mx-auto vh-100">
        {/* Center Block */}
        <div className={`${chatId !== undefined && `d-none d-lg-block`} col-12 col-lg-5 px-0 vh-100 overflow-y-scroll scrollbar-hidden pt-3 pt-lg-5`}>
          <div className='d-flex justify-content-evenly pt-lg-5'>
            <Link to={`/chats`} className='' onClick={() => setCbShow(false)}>
              <img className='me-2' src={`${BASE_URL_STATIC}/icon/chat.svg`} alt="chat" />
              <span className='text-dark'>Chat</span>
            </Link>

            <Link to={`/chats/call`} className='' onClick={() => setCbShow(true)}>
              <img className='me-2' src={`${BASE_URL_STATIC}/icon/call.svg`} alt="call" />
              <span className='text-dark'>Call</span>
            </Link>
          </div>

          <hr className='hr mx-2 mx-md-3 mx-lg-4' />

          {
            cbShow
            ?
            <CallCenterBlock setCallRbUser={setCallRbUser} />
            :
            <ChatCenterBlock setChatRbUser={setChatRbUser} />
          }
        </div>

        {/* Right Block */}
        <div className={`${chatId === undefined && `d-none d-lg-block`} col-12 col-lg-7 px-0 shadow-sm`}  style={{backgroundImage : `url(${BASE_URL_STATIC}/chat-bg.jpg)`}}>
          {
            cbShow
            ?
            <CallRigthBlock callRbUser={callRbUser} />
            :
            <ChatRigthBlock chatRbUser={chatRbUser} />
          }
        </div>
      </section>
    </>
  )
}

export default Chats
