import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import ProposeOptionMemoriesEdit from './ProposeOptionMemoriesEdit';
import { BASE_URL_STATIC } from '../../../../config';

const ProposeOptionMemories = ({ showProposeOptionMemoriesPopup, setShowProposeOptionMemoriesPopup }) => {

  const [showProposeOptionMemoriesEditPopup, setShowProposeOptionMemoriesEditPopup] = useState(false);

  const handleEdit = () => {
    setShowProposeOptionMemoriesPopup(false);
    setShowProposeOptionMemoriesEditPopup(true);
  };

  return (
    <>
      <ProposeOptionMemoriesEdit showProposeOptionMemoriesEditPopup={showProposeOptionMemoriesEditPopup} setShowProposeOptionMemoriesEditPopup={setShowProposeOptionMemoriesEditPopup} setShowProposeOptionMemoriesPopup={setShowProposeOptionMemoriesPopup} />

      <Modal show={showProposeOptionMemoriesPopup} onHide={() => setShowProposeOptionMemoriesPopup(false)} size='lg' centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>

        <Modal.Body>
          <section className='proposeOptionMemories'>
            <div>
              <div className='proposeOptionMemories-edit' onClick={handleEdit}>
                <img src={`${BASE_URL_STATIC}/edit.png`} alt="edit" height={20} width={20} />
                <span>Edit</span>
              </div>

              <img className='proposeOptionMemories-image' src={`${BASE_URL_STATIC}/cp-3.png`} alt="cp-3" height={325} width={375} />
            </div>

            <div className='proposeOptionMemories-album'>
              <h6 className='proposeOptionMemories-album-head'>Album</h6>

              <div className='proposeOptionMemories-album-img'>
                <div className='proposeOptionMemories-album-img-div'>
                  <img src={`${BASE_URL_STATIC}/cp-1.png`} alt="cp-1" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-2.png`} alt="cp-2" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-3.png`} alt="cp-3" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-4.png`} alt="cp-1" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-5.png`} alt="cp-2" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-2.png`} alt="cp-3" height={100} width={100} />
                </div>

                <div className='proposeOptionMemories-album-img-div'>
                  <img src={`${BASE_URL_STATIC}/cp-4.png`} alt="cp-1" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-5.png`} alt="cp-2" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-1.png`} alt="cp-3" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-2.png`} alt="cp-1" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-4.png`} alt="cp-2" height={100} width={100} />
                  <img src={`${BASE_URL_STATIC}/cp-1.png`} alt="cp-3" height={100} width={100} />
                </div>
              </div>
            </div>

            <button type="button" className='proposeOptionMemories-button' onClick={() => setShowProposeOptionMemoriesPopup(false)}>Start</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProposeOptionMemories;
