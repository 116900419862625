import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { profileContext } from '../../../UseContext/ProfileContext';
import { createHobby, getHobby } from '../../../UseContext/ApiHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonArrowUpFromLine } from '@fortawesome/free-solid-svg-icons';
import { FaPlusCircle } from 'react-icons/fa';
import { IoCloseCircleSharp } from "react-icons/io5";

const HobbyPopup = ({ show, close, hobbyObj }) => {

  const { setSelectedHobbies } = useContext(profileContext);
  const [otherInput, setOtherInput] = useState('')
  const [otherHobbies, setOtherHobbies] = useState([])
  const [saveLoader, setSaveLoader] = useState(false)

  function handleSubmit(e) {
    e.preventDefault();
    setSaveLoader(true)
    createHobby(otherHobbies).then(() => getHobby().then(res => { setSelectedHobbies(res); setSaveLoader(false); close(); }))
  };

  useEffect(() => {
    setOtherHobbies(hobbyObj.map(item => item.hobbyName))
  }, [hobbyObj])

  const handleAdd = () => {
    if (otherInput !== "") {
      setOtherHobbies([...otherHobbies, otherInput]);
    }
    setOtherInput("")
  }

  const handleOtherhobbies = (e) => {
    const newArr = otherHobbies.filter(item => item !== e);
    setOtherHobbies(newArr);
  }

  return (
    <div>
      <Modal show={show} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <form action="" onSubmit={handleSubmit}>
            <h5>Hobbies</h5>
            <div className="flex px-4">
              <div
                className="d-flex align-items-center gap-2"
                style={{
                  marginRight: '5px',
                  background: '#f5f5f5',
                  padding: '6px 10px',
                  borderRadius: '10px',
                  width: '160px',
                }}
              >
                <FontAwesomeIcon icon={faPersonArrowUpFromLine} />
                <p
                  style={{
                    fontSize: '15px',
                    margin: 'auto',
                    color: '#616161',
                  }}
                >
                  skills categories
                </p>
              </div>
            </div>

            <div className="hobby_pop">
              <div className='flex container justify-content-center gap-2 my-4 '>

                <input type="text" placeholder='enter hobby' maxLength='30' value={otherInput} onChange={(e) => setOtherInput(e.target.value)} />

                <p onClick={() => { handleAdd() }} style={{ cursor: "pointer" }} className=' fs-5' ><FaPlusCircle /></p>
              </div>

              <div className='flex container justify-content-center gap-2 my-4 '>
                <div className='d-flex flex-column justify-content-start align-items-start hobby_other_li'>
                  {otherHobbies.map((e, i) => (
                    <li key={i} className='hobby_li'>
                      <span>{e}</span>
                      <span style={{ cursor: "pointer" }} onClick={() => handleOtherhobbies(e)}><IoCloseCircleSharp size={15} /></span>
                    </li>
                  ))}
                </div>
              </div>
            </div>



            <div className='d-flex justify-content-center'>
              <button type="submit" className="btn btn-dark mt-4 col-lg-4">{saveLoader ? "saving..." : "save"}</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HobbyPopup;
