import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import Modal from 'react-bootstrap/Modal';


const RestrictUserPopup = ({ restrictPopupUserId }) => {

  const { showRestrictUserPopup, setShowRestrictUserPopup, resrictUser, setResrictUser } = useContext(ApiContext);

  console.log('restrictPopupUserId : ', restrictPopupUserId);

  // To handle restrict change
  const handleRestrictChange = (e) => {
    const { name, value } = e.target;

    console.log(name, value);
  };

  useEffect(() => {
    setResrictUser(preState => ({
      ...preState,
      restrictedUserId: restrictPopupUserId,
    }));
  }, [restrictPopupUserId]);

  return (
    <>
     <Modal show={showRestrictUserPopup} onHide={() => setShowRestrictUserPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
     {/* <Modal show={true} onHide={() => setShowRestrictUserPopup(false)} centered backdrop="static" keyboard={false} className="create-call" > */}
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <h3 className='m-0 text-center mb-4'>Restrict</h3>

            {/* Like */}
            <div>
              <span>Like</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='like' 
                  value={resrictUser.like === 'true' ? 'false': 'true'}
                  checked={resrictUser.like === 'true'} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />

            {/* Comment */}
            <div>
              <span>Comment</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='comment' 
                  // value={true}
                  // checked={false} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />

            {/* Share */}
            <div>
              <span>Share</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='share' 
                  // value={true}
                  // checked={false} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />

            {/* Tag */}
            <div>
              <span>Tag</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='tag' 
                  // value={true}
                  // checked={false} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />

            {/* Mention */}
            <div>
              <span>Mention</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='mention' 
                  // value={true}
                  // checked={false} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <hr className='hr bg-secondary' />

            {/* Chat */}
            <div>
              <span>Chat</span>

              <div className="form-check form-switch m-0">
                <input type="checkbox" className="form-check-input" role="switch" aria-checked name='chat' 
                  // value={true}
                  // checked={false} 
                  onChange={(e) => handleRestrictChange(e)} 
                />
              </div>
            </div>

            <div className='text-center mt-4'>
              <button type="button" className='btn btn-primary'>Done</button>
            </div>
          </Modal.Body>
        </Modal> 
    </>
  )
}

export default RestrictUserPopup
