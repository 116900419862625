import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";

const ProfileBestClipCommentThreeDotPopup = ({ showProfileBestClipCommentThreeDotPopup, setShowProfileBestClipCommentThreeDotPopup, comment, getBestClipComment }) => {

  const { config, loggedInUserId } = useContext(ApiContext);

  // console.log(comment);

  // To delete comment of post
  const deleteBestClipComment = (bestclipId, parentCommentId, config) => {
    axios.post('https://emilonode.sjain.io/post/deletebestclipcomment', { bestclipId: bestclipId, parentBestclipCommentId: parentCommentId }, config)
    .then(res => {
      console.log('delete comment', res);
      
      setShowProfileBestClipCommentThreeDotPopup(false);
      getBestClipComment(bestclipId, config);

      // To delete notification
      axios.post('https://emilonode.sjain.io/user/deletecommenteminnotify', { bestclipId: bestclipId, parentCommentId: parentCommentId }, config)
      .then(res => {
        console.log('delete comment notification: ', res.data.message);
      })
      .catch(err => {
        console.log(err);
      });
    })
    .catch(err => {
      console.log(err);
    });
  };

  return (
    <>
      {
        comment
        &&
        comment.commentUserId === loggedInUserId
        ?
        <Modal show={showProfileBestClipCommentThreeDotPopup} onHide={() => setShowProfileBestClipCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter"  centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text profilePostThreeDotPopup-text-1" onClick={() => deleteBestClipComment(comment.bestclipId, comment.parentCommentId, config)}>Delete Comment</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfileBestClipCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
        :
        <Modal show={showProfileBestClipCommentThreeDotPopup} onHide={() => setShowProfileBestClipCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text" onClick={() => console.log('report')}>Report</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfileBestClipCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default ProfileBestClipCommentThreeDotPopup
