import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import { BASE_URL_STATIC } from "../../../../../config";
import { useNavigate } from "react-router";
import { ApiContext } from "../../../UseContext/ApiCall";
import axios from "axios";
import toast from "react-hot-toast";
import { email_otp_send } from "../../../UseContext/ApiHelpers";

const ChangePassoword1 = () => {
   const nav = useNavigate()
   const { loggedInUserId , selectDevice , setSelectDevice} = useContext(ApiContext)
   const [profileUser, setProfileUser] = useState(null)

   console.log("selectDevice" , selectDevice);


   console.log("profileuser", profileUser);

   useEffect(() => {
      getProfileUser(loggedInUserId)
   }, [loggedInUserId])


   const getProfileUser = async (loggedInUserId) => {
      await axios.post("https://emilonode.sjain.io/user/getoneuser", { userId: loggedInUserId })
         .then(res => {
            // console.log(res.data.user);
            setProfileUser(res.data.user);
         })
         .catch(err => {
            return null
         });
   };


   const sendotp = async () => {

      if(selectDevice === "mobile"){

         try {
            await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: profileUser.mobile }).then((res) => {
               toast.success(res.data.message)
               nav("/signup/cp-verify-otp")
   
            })
         } catch (error) {
            toast.error("failed to send otp")
         }
      }else {
         email_otp_send(profileUser.email).then((res) => {
            toast.success(res)
            nav("/signup/cp-verify-otp")
         }).catch(() => {
            toast.error("failed to send otp")
         })

      }
   }


   return (
      <div className="col-lg-12  username createPassword">
         <section className="col-lg-6 createPassword-lb" style={{ background: "#79B5F8" }} >
            <img src={`${BASE_URL_STATIC}/forgot-pass-select.png`} alt="" />
         </section>

         {profileUser && <section className="col-lg-6 createPassword-rb">
            <div className="usernameForm cp-rb-form">
               <img className="cp-rb-logo" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />

               <h4 className="cp-rb-header">Select Options</h4>
               <p>Select from which contact detail you want to reset Your password.</p>

               {profileUser && profileUser.mobile && <div className={`forgotpass-select ${selectDevice === "mobile" && "select-color"}`} onClick={() => setSelectDevice("mobile")} >
                  <img className="cp-rb-logo" src={`${BASE_URL_STATIC}/icon/mobile.svg`} alt="logo" />
                  <div>
                     <span> Via SMS</span>
                     {/* <span>+91 7878787878</span> */}
                     <span>{profileUser.mobile}</span>
                  </div>
               </div>}

               <div className={`forgotpass-select ${selectDevice === "email" && "select-color"}`} onClick={() => setSelectDevice("email")} >
                  <img className="cp-rb-logo" src={`${BASE_URL_STATIC}/icon/mail.svg`} alt="logo" />
                  <div>
                     <span> Via Email</span>
                     <span>{profileUser && profileUser.email}</span>
                  </div>
               </div>

               <Button onClick={sendotp}>send OTP</Button>


            </div>
         </section>}
      </div>
   );
};

export default ChangePassoword1;
