import React, { useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../../UseContext/ApiCall';


const InviteFriendViaSmsPopup = ({ showInviteFriendViaSmsPopup, setShowInviteFriendViaSmsPopup }) => {

  const [number, setNumber] = useState();
  const [numbers, setNumbers] = useState([]);

  console.log('number : ', number);

  const { loggedInUserName } = useContext(ApiContext);

    // To handle add number
    const handleAddNumber = (e) => {
      e.preventDefault();

      const formattedNumber = "+91" + number;
  
      setNumbers([...numbers, formattedNumber]);

      setNumber('');
    };
  
    // To handle remove selected number
    const handleRemoveSelectedNumber = (number) => {
      const updatedEmails = numbers.filter((item) => item !== number);
  
      setNumbers(updatedEmails);
    };
  
    // To handle invite friend via email
    const handleInviteFriendViaSms = () => {
      setShowInviteFriendViaSmsPopup(false);

      // Construct the sms link
      const smsLink = `sms:${numbers.join(',')}&body='Your friend ${loggedInUserName} invite you to join Emilo https://emilorj.sjain.io'`;

      // Open the sms link
      window.location.href = smsLink;
  
      setNumbers([]);
    };

  return (
    <>
      <section>
        <Modal show={showInviteFriendViaSmsPopup} onHide={() => setShowInviteFriendViaSmsPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <section>
              <h5 className='text-center'>Invite Friend Via Sms</h5>

              <form className='d-flex my-3' onSubmit={handleAddNumber}>
                <input type="tel" className='form-control me-3' value={number} onChange={(e) => setNumber(e.target.value)} name="number" placeholder='Add Number Here...' />

                <button type='button' className='btn btn-primary' onClick={handleAddNumber} disabled={number === undefined || number === ''}>Add</button>
              </form>

              {
                numbers.length > 0
                &&
                <div className='border shadow-sm p-1 rounded-3 my-3'>
                  {
                    numbers.map((number, index) => (
                      <div className='px-2 py-1 rounded-pill bg-primary text-white d-inline-block m-1'>
                        <span className='me-3' key={index}>{number}</span>
                        <span onClick={() => handleRemoveSelectedNumber(number)}>x</span>
                      </div>
                    ))
                  }
                </div>
              }

              <button type='button' className='d-block w-50 btn btn-primary mx-auto' onClick={handleInviteFriendViaSms} disabled={numbers.length <= 0}>Send</button>
            </section>
          </Modal.Body>
        </Modal>
      </section>  
    </>
  )
}

export default InviteFriendViaSmsPopup
