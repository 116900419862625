import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';

const LikedPosts = () => {

  const [likedPosts, setLikedPosts] = useState([]);

  // console.log(likedPosts);

  const { config } = useContext(ApiContext);

  // To get liked posts
  const GetLikedPosts = async (config) => {
    await axios.post('https://emilonode.sjain.io/post/getpostlikebyuser', {}, config)
    .then(res => {
      // console.log(res.data.postLike);
      setLikedPosts(res.data.postLike.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    GetLikedPosts(config);
  }, []);

  return (
    <>
      <section className='liked-posts'>
        <h1 className='liked-posts-head'>Photos/Videos</h1>

        <div className='liked-posts-imgs'>
          {
            likedPosts.map((likedPost, index) => (
              // console.log(likedPost.details);
              likedPost.details.map(detail => (
                // console.log(detail)
                <img className='liked-posts-img' src={detail.imageUrl} alt={detail.imageId} height={215} width={200} />
              ))
            ))
          }
        </div>
      </section>
    </>
  )
}

export default LikedPosts
