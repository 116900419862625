import React, { useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { NavLink } from 'react-router-dom'
import { ApiContext } from '../../UseContext/ApiCall'

const LeftBlockSkeleton = () => {

  const {setLeftBlockLoader,LeftBlockLoader} =  useContext(ApiContext)

  useEffect(() => {
   setLeftBlockLoader(false)
  },[LeftBlockLoader ])

   return (
      <>
         <div className="left-block">
            <div className="profileBox">

               <div className="imgBox">
                  <Skeleton height='150px' width='250px' circle />
               </div>

               <h3 style={{marginTop:"20px"}}> <Skeleton width={180} height={20} /></h3>
               <span><Skeleton width={150} height={15} /></span>
            </div>

            <div className="followBox">
               <Skeleton width={90} height={90} />
               <Skeleton width={90} height={90} />
               <Skeleton width={90} height={90} />
            </div>

            <div className="main-menu">
               {/* <Navigation /> */}
               <ul>
                  {Array(6).fill(0).map((e, i) => (
                     <li key={i}>
                        <NavLink>
                          <span><Skeleton width={30} height={30} circle /></span> 
                           <Skeleton width={100} />
                        </NavLink>
                     </li>
                  ))}
               </ul>

            </div>

            <section className="footer-area">
               <div className="copyright">
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
                 <span><Skeleton width={50} /></span> 
               
               </div>
            </section>
         </div>
      </>
   )
}

export default LeftBlockSkeleton