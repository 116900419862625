import React, { useRef, useEffect, useState } from 'react';

const CanvasRecorder = () => {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [videoURL, setVideoURL] = useState('');
  
    let muxer;
    let animationFrameId;
  
    const drawOnCanvas = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let x = 0;
      const draw = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.arc(x, 100, 50, 0, Math.PI * 2);
        ctx.fillStyle = '#ff0000';
        ctx.fill();
        x += 5;
        if (x > canvas.width) x = 0;
        animationFrameId = requestAnimationFrame(draw);
      };
      draw();
    };
  
    const startRecording = () => {
      muxer = new window.Mp4Muxer();  // Use global `Mp4Muxer` from the CDN
      setIsRecording(true);
  
      const captureFrame = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        muxer.addFrame(imageData.data, canvas.width, canvas.height);
        if (isRecording) {
          animationFrameId = requestAnimationFrame(captureFrame);
        }
      };
      captureFrame();
    };
  
    const stopRecording = () => {
      cancelAnimationFrame(animationFrameId);
      setIsRecording(false);
      const mp4Blob = muxer.finalize();
      setRecordedBlob(mp4Blob);
      setVideoURL(URL.createObjectURL(mp4Blob));
    };
  
    useEffect(() => {
      drawOnCanvas();
      return () => cancelAnimationFrame(animationFrameId);
    }, []);
  
    return (
      <div>
        <canvas ref={canvasRef} width={640} height={480} style={{ border: '1px solid black' }}></canvas>
        <div>
          <button onClick={startRecording} disabled={isRecording}>Start Recording</button>
          <button onClick={stopRecording} disabled={!isRecording}>Stop Recording</button>
        </div>
        
        {recordedBlob && (
          <div>
            <h3>Playback the Recorded Video</h3>
            <video ref={videoRef} src={videoURL} controls width="640" height="480"></video>
          </div>
        )}
  
        {recordedBlob && (
          <button onClick={() => {
            const a = document.createElement('a');
            a.href = videoURL;
            a.download = 'recorded-video.mp4';
            a.click();
          }}>
            Download MP4
          </button>
        )}
      </div>
    );
  };
  
  export default CanvasRecorder;
  