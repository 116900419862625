import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';

const ServiceProfile = () => {

  const { showServiceProfilePopup, setshowServiceProfilePopup, service, setService, handleReportPost, loggedInUserId } = useContext(ApiContext);



  return (
    <>
      <section className='servicePostPopups'>
        <Modal show={showServiceProfilePopup} onHide={() => setshowServiceProfilePopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='servicePostPopup'>
              <h4 className='servicePostPopup-head'>Report Service</h4>

              <textarea className='servicePostPopup-textarea' value={service} onChange={(e) => setService(e.target.value)} name="report" placeholder='Any suggestion or improvement you think to do....'></textarea>

              <span className='servicePostPopup-report'onClick={() => handleReportPost(loggedInUserId ,"Service Profile",loggedInUserId, service)}>Report Service</span>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default ServiceProfile
