export const VideoToFramesMethod = {
    fps: 0,
    totalFrames: 1,
    timestamp: 1, // Adding timestamp method
  };
  
  export const getFrames = async (videoUrl, amount, type = VideoToFramesMethod.fps, timestamp = null) => {
    return new Promise((resolve, reject) => {
      let frames = [];
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      let duration;
  
      let video = document.createElement("video");
      video.preload = "auto";
      const eventCallback = async () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        duration = video.duration;
  
        if (type === VideoToFramesMethod.timestamp && timestamp !== null) {
          // Extract a single frame at the specific timestamp
          frames.push(await getVideoFrame(video, context, canvas, timestamp));
        } else {
          // Default behavior: Extract multiple frames
          for (let i = 1; i <= amount; i++) {
            const time = (i / amount) * duration;
            frames.push(await getVideoFrame(video, context, canvas, time));
          }
        }
  
        resolve(frames);
      };
  
      const getVideoFrame = (video, context, canvas, time) => {
        return new Promise((resolve, reject) => {
          const seekedCallback = () => {
            video.removeEventListener("seeked", seekedCallback);
            storeFrame(video, context, canvas, resolve);
          };
          video.addEventListener("seeked", seekedCallback);
          video.currentTime = time;
        });
      };
  
      const storeFrame = (video, context, canvas, resolve) => {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      };
  
      video.addEventListener("loadeddata", eventCallback);
      video.src = videoUrl;
      video.load();
  
      return () => {
        // Cleanup logic, if needed
      };
    });
  };
  