import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessAddProductPopup = () => {

  const { 
    showAddProductPopup, setShowAddProductPopup,
    showAddServicePopup, setShowAddServicePopup,
    addNewProductService, setAddNewProductService,
    createNewProductService,
  } = useContext(businessContext);  

  const fileInputRef = useRef(null); 

  // handle click
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  };

  // handle change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setAddNewProductService(prevState => ({ ...prevState, image: e.target.files[0] }));
    };
  };

  return (
    <>
      <Modal keyboard={false} className="create-post" backdrop="static" centered show={showAddProductPopup || showAddServicePopup}  onHide={() => {setShowAddProductPopup(false); setShowAddServicePopup(false);}} >
      {/* <Modal show={true} onHide={() => setShowAddProductPopup(false)} keyboard={false} className="create-post" backdrop="static" centered> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Add {showAddProductPopup && 'Product'}{showAddServicePopup && 'Services'}</h4>

          <>
            {
              addNewProductService.image
              ?
              <div className='border mb-4 rounded-4' style={{ height: '250px' }}>
                <img src={URL.createObjectURL(addNewProductService.image)} className='h-100 w-100 object-fit-fill rounded-4' alt="product" />
              </div>
              :
              <div className='d-flex flex-column align-items-center w-75 mx-auto rounded-3 py-4 mb-5 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                <img src={`/images/business/icons/upload-photo-blue.svg`} style={{ height: '80px' }} alt="upload-blue" />

                <small className='text-primary'>Upload</small>
                <small className='text-secondary'>or</small>
                <small>Drop your file here</small>

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
              </div>
            }
          </>

          <small className='text-secondary'>Heading</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='heading' placeholder='Heading...' 
            value={addNewProductService.heading} onChange={(e) => setAddNewProductService((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' placeholder='Description...'
            value={addNewProductService.description} onChange={(e) => setAddNewProductService((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          ></textarea>

          <small className='text-secondary'>Price</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='price' placeholder='Price...' 
            value={addNewProductService.price} onChange={(e) => setAddNewProductService((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
          />

          <>
            {
              showAddProductPopup
              &&
              <>
                <small className='text-secondary'>Buy Now Link</small>
                <input type="text" className='form-control border-0 border-bottom shadow-none mb-5' name='link' placeholder='Price...' 
                  value={addNewProductService.link} onChange={(e) => setAddNewProductService((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
                />
              </>
            }
          </>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' 
              onClick={() => createNewProductService(showAddProductPopup ? 'product' : showAddServicePopup && 'service')}
              disabled={!(addNewProductService.image && addNewProductService.heading && addNewProductService.description && addNewProductService.price)}
            >Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessAddProductPopup;