import React from 'react'
import { Modal } from 'react-bootstrap'

const ScVideoPopup = ({ open, close }) => {
   return (
      <div>
         <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call">
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               <div>
                  <video controls muted>
                    <source src='/fear.mp4'/>
                  </video>
               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default ScVideoPopup