import React, { useContext, useEffect } from "react";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../config";
import Slider from "react-slick";
import ApplyJobPopup from "../Popup/ApplyJobPopup";
import { businessContext } from "../UseContext/BusinessContext";
import BusinessAddListingJobPopup from "../Popup/Home/Listings/BusinessAddListingJobPopup";
import BusinessAddListingFrenchisePopup from "../Popup/Home/Listings/BusinessAddListingFrenchisePopup";



const BusinessHomeListings = () => {

  const { 
    paramsPageId,
    setShowApplyJobPopup,
    setShowAddJobsPopup,
    setShowAddFrenchisePopup,
    checkIsItMyPage, isItMyPage,
    getJobs, jobs, deleteJob,
    getFrenchises, frenchises, deleteFrenchise,
  } = useContext(businessContext);

  const jobSetting = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const frenchiseSetting = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  useEffect(() => {
    checkIsItMyPage(paramsPageId);
    getJobs(paramsPageId);
    getFrenchises(paramsPageId);
  }, [paramsPageId]);


  return (
    <>
      {/* Apply job popup */}
      <ApplyJobPopup />

      {/* Add job popup */}
      <BusinessAddListingJobPopup />

      {/* Add frenchise popup */}
      <BusinessAddListingFrenchisePopup />


      <section className="">
        <div className="rounded-3 shadow-sm px-3 py-2 mb-5 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-3" style={{ backgroundColor: '#f5f5f5' }}>
          <h4 className="mb-0">Your Listings</h4>

          {/* Search Bar */}
          <div className="bg-white px-3 py-1 rounded-pill d-flex flex-grow-1">
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className="ms-2 me-2" alt="search" />
            <input type="search" className="form-control border-0 shadow-none" placeholder="Search..." />
          </div>

          <button type="button" className="btn bg-white">
            <img src={`/images/business/icons/add-circle.svg`} className="me-2" style={{ height: '20px' }} alt="add-circle" />
            <span className="text-primary">Add Listing</span>
          </button>
        </div>

        <>
          {
            isItMyPage
            ?
            // ----- It will show to logged in user -----
            <>
              {/* Jobs */}
              <div className="mb-5">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Jobs</h5>
                  <img src={`/images/business/icons/add-circle.svg`} className="ms-2 me-auto cursor-pointer" style={{ height: '20px' }} alt="add" onClick={() => setShowAddJobsPopup(true)} />
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...jobSetting} className='listing-job-slick'>
                    {
                      jobs && jobs.length > 0
                      && 
                      jobs.map((job, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2 position-relative' key={index}>
                          <div>
                            <img src={AwsFileUrl+job.image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="job" />
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h5 className="fw-semibold mb-0 d-inline-block text-nowrap text-truncate" style={{ maxWidth: '125px', overflow: 'hidden' }}>{job.heading}</h5>

                              <small className="badge bg-secondary-subtle text-dark">{job.experience}</small>
                            </div>

                            <small className="d-block text-secondary mt-1 mb-2">
                              {job.description && `${job.description.slice(0, 50)}...`}
                            </small>

                            <small className="d-block fw-semibold">Salary</small>
                            <h5 className="text-primary fw-semibold m-0">₹ {job.salary}</h5>

                            <div className="d-flex flex-wrap gap-3 mt-3">
                              <button type="button" className="btn btn-sm border border-primary d-flex">
                                <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-primary">Boost</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Share</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Enquiry</span>
                              </button>
                            </div>
                          </div>

                          {/* Delete */}
                          <img src={`/images/icons/delete-black.svg`} className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer" style={{ height: '20px' }} onClick={() => deleteJob(job._id)} alt="edit" />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>

              {/* Frenchise */}
              <div className="mb-5">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Frenchise</h5>
                  <img src={`/images/business/icons/add-circle.svg`} className="ms-2 me-auto cursor-pointer" style={{ height: '20px' }} alt="add" onClick={() => setShowAddFrenchisePopup(true)} />
                  <span className="text-primary">See All</span>
                </div>

                <div>
                  <Slider {...frenchiseSetting} className='listing-frenchise-slick'>
                    {
                      frenchises && frenchises.length > 0
                      &&
                      frenchises.map((frenchise, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2 position-relative' key={index}>
                          <div>
                            <img src={AwsFileUrl+frenchise.image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="frenchise" />
                          </div>

                          <div>
                            <h5 className="fw-semibold mb-0 d-inline">{frenchise.heading}</h5>

                            <small className="d-block text-secondary mt-1 mb-2">
                              {frenchise.description && `${frenchise.description.slice(0, 75)}...`}
                            </small>

                            {/* Phone */}
                            <div className="border border-primary px-2 rounded-pill d-inline-block me-3">
                              <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '23px' }} alt="call" />
                            </div>

                            {/* Email */}
                            <div className="border border-primary px-2 rounded-pill d-inline-block">
                              <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '23px' }} alt="call" />
                            </div>

                            <div className="d-flex flex-wrap gap-3 mt-3">
                              <button type="button" className="btn btn-sm border border-primary d-flex">
                                <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-primary">Boost</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Share</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Enquiry</span>
                              </button>
                            </div>
                          </div>

                          {/* Delete */}
                          <img src={`/images/icons/delete-black.svg`} className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer" style={{ height: '20px' }} onClick={() => deleteFrenchise(frenchise._id)} alt="edit" />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </>
            :
            // ----- It will show to other user -----
            <>
              {/* Jobs */}
              <div className="mb-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Jobs</h5>
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...jobSetting} className='listing-job-slick'>
                    {
                      jobs && jobs.length > 0
                      &&
                      jobs.map((job, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2' key={index}>
                          <div>
                            <img src={AwsFileUrl+job.image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="job" />
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h5 className="fw-semibold mb-0 d-inline-block text-nowrap text-truncate" style={{ maxWidth: '125px', overflow: 'hidden' }}>{job.heading}</h5>

                              <small className="badge bg-secondary-subtle text-dark">{job.experience}</small>
                            </div>

                            <small className="d-block text-secondary mt-1 mb-2">
                              {job.description && `${job.description.slice(0, 75)}...`}
                            </small>

                            <small className="d-block fw-semibold">Salary</small>
                            <h5 className="text-primary fw-semibold m-0">₹ {job.salary}</h5>

                            <div className="d-flex flex-wrap align-items-center gap-3 mt-3">
                              <button type="button" className="btn btn-primary" onClick={() => setShowApplyJobPopup(true)}>Apply Now</button>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/message-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>

              {/* Frenchise */}
              <div className="mb-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Frenchise</h5>
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...frenchiseSetting} className='listing-frenchise-slick'>
                    {
                      frenchises && frenchises.length > 0
                      &&
                      frenchises.map((frenchise, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2' key={index}>
                          <div>
                            <img src={AwsFileUrl+frenchise.image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="frenchise" />
                          </div>

                          <div>
                            <h5 className="fw-semibold mb-0 d-inline">{frenchise.heading}</h5>

                            <small className="d-block text-secondary mt-1 mb-2">
                              {frenchise.description && `${frenchise.description.slice(0, 75)}...`}
                            </small>

                            <div className="d-flex flex-wrap align-items-center gap-3">
                              <button type="button" className="btn btn-primary">Contact Us</button>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/message-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </>
          }
        </>
      </section>
    </>
  );
};

export default BusinessHomeListings;