import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { BASEAPIURL } from '../../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const TextTags = ({ selectedUser,openTag,taggedUsers,
  setOpentag, config, handleTagUser }) => {
  const [inputValue, setInputValue] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [loader, setLoader] = useState(false);
  const inputRef = useRef(null); 

  useEffect(() => {
    if (openTag && inputRef.current) {
      inputRef.current.focus();
    }
  }, [openTag]);

  const handleInputChange = async (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 0) {
      setLoader(true);
      await axios
        .post(`${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config)
        .then((res) => { 
          const filterUser = res.data.users.filter((user) => {
            return !taggedUsers.some((taggedUser) => taggedUser.userId === user.userId);
          });
          setSearchList(filterUser);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoader(false);
        });
    } else {
      setSearchList([]);
      setLoader(false);
    }
  };

  
  const handleUserClick = (user, e) => {
    const userWithPosition = {
      ...user, 
   ...selectedUser
    };
    handleTagUser(userWithPosition);
    setInputValue('');
    setSearchList([]);
    setOpentag(false); 
  };



  return (
    <div className="text-tags-container">
      {/* Preview Section */}
      <div
        className="preview-section"
      >

        {/* Render tagged users in the preview */}
        {selectedUser.length > 0 && selectedUser.map((user, index) => (
          <div 
          className='preview_tags'
            key={index}
            style={{
              left: `${user.x}px`,
              top: `${user.y}px`,
            }}
          >
            {user.username}
          </div>
        ))}
      </div>

      {/* Search Input - Open when clicking on the preview section */}
      {openTag && (
        <div className="search-section" style={{ zIndex: 1 }}>
          <input
            type="text"
            //ref={inputRef} // Attach ref to input field for focusing
            value={inputValue}
            autoFocus
            onChange={handleInputChange}
            placeholder="Search for users to tag"
          />
          {loader && <FontAwesomeIcon
                  className="searchLoader_story"
                  icon={faSpinner}
                  spinPulse
                  style={{ color: "#402154" }}
                />}
          {searchList.length > 0 && (
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {searchList.map((user , i) => (
                <li
                  key={i}
                  onClick={(e) =>{ handleUserClick(user, e);}}
                  style={{ cursor: 'pointer', padding: '5px 0' }}
                >
                  {user.username}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default TextTags;
