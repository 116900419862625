import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { ApiContext } from "../../UseContext/ApiCall";
import { BASE_URL_STATIC } from "../../../../config";

const LikeReelButton = ({ reelId }) => {

  const [like, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const { config, socket } = useContext(ApiContext);

  // To fetch reel like status
  const getReelLikeStatus = (reelId, config) => {
    axios.post('https://emilonode.sjain.io/post/checklikereel', { reelId: reelId }, config)
    .then(res => {
      // console.log(res.data.liked);
      setLike(res.data.liked);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getReelLikeStatus(reelId, config);
  }, [reelId]);

  // To fetch reel count
  const getReelLikeCount = (reelId, config) => {
    axios.post('https://emilonode.sjain.io/post/countreellike', { reelId: reelId }, config)
    .then(res => {
      // console.log(res);
      setLikeCount(res.data.reelLikeCount);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getReelLikeCount(reelId, config);
  }, [likeCount]);

  // To handle reel like or dislike
  const handleReelLike = async (reelId) => {
    const action = like ? "like" : "dislike";

    await axios.post( "https://emilonode.sjain.io/post/likereel", { reelId: reelId, action: action }, config )
    .then((res) => {
      // console.log(res.data);
      setLike(!like);

      if (like) {
        setLikeCount(likeCount + 1);
      } else {
        setLikeCount(likeCount - 1);
      };

      // For notification
      axios.post('https://emilonode.sjain.io/user/createemnotification', { reelId: reelId, action: action, type: 'like' }, config)
      .then(res => {
        // console.log(res);

        let notificationData = {
          type: res.data.type,
          action: res.data.action,
          senderUserId: res.data.senderUserId && res.data.senderUserId,
          receiverUserId: res.data.receiverUserId && res.data.receiverUserId,
          message: res.data.message && res.data.message,
          createdAt: res.data.createdTime && res.data.createdTime,
          fname: res.data.fname && res.data.fname,
          reelId: res.data.reelId && res.data.reelId,
          postImg: res.data.postImg && res.data.postImg,
          profileImg: res.data.profileImg && res.data.profileImg,
          tumbnailImg: res.data.tumbnailImg && res.data.tumbnailImg,
        }

        socket.emit("send_message_emilo_notification", notificationData);
        console.log(notificationData);
      })
      .catch(err => {
        console.log(err);
      });
    })
    .catch((err) => {
      console.log(err);
    });
  };

  return (
    <div>
      {like ? (
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <img className="likeReelBtn" src={`${BASE_URL_STATIC}/icon/like-1.svg`} alt="like" style={{ cursor: "pointer", marginRight: "5px" }} onClick={() => handleReelLike(reelId)} />
          <span>{likeCount}</span>
        </div>
      ) : (
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <img className="likeReelBtn" src={`${BASE_URL_STATIC}/icon/like-2.svg`} alt="like" style={{ cursor: "pointer", marginRight: "5px" }} onClick={() => handleReelLike(reelId)} />
          <span>{likeCount}</span>
        </div>
      )}
    </div>
  );
};

export default LikeReelButton;
