import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ApiContext } from '../../../../UseContext/ApiCall';
import 'react-h5-audio-player/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AwsFileUrl } from '../../../../../../config';
import StoryTextPopup from './StoryTextPopup';
import StoryPhotoVideoPopup from './StoryPhotoVideoPopup';
import StoryEmiloGallaryPopup from './StoryEmiloGallaryPopup';


const CreateStoriesPopup = () => {

  const { 
    showCreateStoriesPopup, setshowCreateStoriesPopup,
    setShowStoryTextPopup,
    setPhotoVideos,
    setShowStoryPhotoVideoPopup,
    setShowStoriesEmiloGalleryPopup,
  } = useContext(ApiContext);
 

  // ----- For Photo and Video ----- 

  const fileInputRef = useRef(null);  // Reference to the file input

  // To open the file input on click
  const handlePhotosVideosClick = () => {
    fileInputRef.current.click();
  };

  // Handle selected photos/videos
  const handlePhotosVideosChange = (e) => {
    const files = Array.from(e.target.files).map(file => ({
      file,
      type: file.type,
      isPlaying: false,
      isMute: false,
      duration: 0
    }));
  
    const validFiles = [];
    let processedFilesCount = 0;
    let hasInvalidVideo = false;
  
    files.forEach(fileObj => {
      if (fileObj.type.startsWith("video")) {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(fileObj.file);
        video.onloadedmetadata = () => {
          processedFilesCount++;
          if (video.duration > 30) {
            hasInvalidVideo = true;
            alert(`The video ${fileObj.file.name} is longer than 6 seconds and will not be uploaded.`);
          } else {
            fileObj.duration = video.duration;
            validFiles.push(fileObj);
            console.log(`Valid video added: ${fileObj.file.name}, duration: ${video.duration} seconds`);
          }
          if (processedFilesCount === files.length) {
            setPhotoVideos(validFiles);
            setShowStoryPhotoVideoPopup(validFiles.length > 0);
            if (!hasInvalidVideo) setshowCreateStoriesPopup(false);
          }
        };
      } else {
        validFiles.push(fileObj);
        processedFilesCount++;
        console.log(`Valid non-video file added: ${fileObj.file.name}`);
        
        if (processedFilesCount === files.length) {
          setPhotoVideos(validFiles);
          setShowStoryPhotoVideoPopup(validFiles.length > 0);
          if (!hasInvalidVideo) setshowCreateStoriesPopup(false);
        }
      }
    });
  };
  
  


  return (
    <>
      {/* Text Popup */}
      <StoryTextPopup />

      {/* Photos and Videos */}
      <StoryPhotoVideoPopup />

      {/* Emilo Gallary */}
      <StoryEmiloGallaryPopup />


      <section className='createStoriesPopup'>
        <Modal show={showCreateStoriesPopup} onHide={() => setshowCreateStoriesPopup(false)} centered backdrop="static" keyboard={false} className="create-call create-stories-popup" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className='p-3'>
            {/* Header */}
            <div className='d-flex mt-3'>
              <h5 className='mb-0 mx-auto fw-semibold'>Add to Stories</h5>
            </div>

            {/* Text / PhotosVideos / Emilo Gallary */}
            <div className='d-flex gap-4 gap-sm-3 flex-column flex-sm-row my-5'>
              {/* Text */}
              <div className='flex-grow-1 text-center rounded-3 shadow py-3 cursor-pointer' onClick={() => {setShowStoryTextPopup(true); setshowCreateStoriesPopup(false)}}>
                <img src={`${AwsFileUrl + 'images/icon/text.svg'}`} className='mb-2' style={{ height: '75px' }} alt="current-location" />
                <p className='fs-14'>Text</p>
              </div>

              {/* PhotosVideos */}
              <div className='flex-grow-1 text-center rounded-3 shadow py-3 cursor-pointer' onClick={handlePhotosVideosClick}>
                <img src={`${AwsFileUrl + 'images/icon/media.svg'}`} className='mb-2' style={{ height: '75px' }} alt="current-location" />
                <p className='fs-14'>Photos / Videos</p>
                <input type="file" ref={fileInputRef} onChange={handlePhotosVideosChange} accept="image/*,video/*" style={{ display: 'none' }} multiple />
              </div>

              {/* Emilo Gallary */}
              <div className='flex-grow-1 text-center rounded-3 shadow py-3 cursor-pointer' 
                onClick={() => {setShowStoriesEmiloGalleryPopup(true); setshowCreateStoriesPopup(false);}}
              >
                <img src={`${AwsFileUrl + 'images/icon/gallery.svg'}`} className='mb-2' style={{ height: '75px' }} alt="current-location" />
                <p className='fs-14'>Emilo Gallary</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default CreateStoriesPopup;