import React, { useState } from 'react';
import { BASE_URL_STATIC } from '../../../config';
import OtpInput from "react-otp-input";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


const ForgotSoftcornerPin = () => {

  const [showSelectOption, setShowSelectOption] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [showChangePin, setShowChangePin] = useState(false);

  const [selectedOption, setSelectedOption] = useState('sms');
  const [OTP, setOTP] = useState("");
  const [pin, setPin] = useState('');

  const navigate = useNavigate();

  console.log('selectedOption : ', selectedOption);
  console.log('OTP : ', OTP);
  console.log('pin : ', pin);


  // To handle select option
  const handleSelectOption = () => {
    setShowSelectOption(false); 
    setShowOtp(true);
  };

  // To handle otp
  const handleOtp = () => {
    setShowOtp(false); 
    setShowChangePin(true);
  };

  // To handle change pin
  const handleChangePin = () => {
    navigate(`/SoftCorner/checkSoftCornerPin`);
    toast.success('Softcorner pin changed successfully');
  };

  return (
    <>
      <section className='border border-danger vh-100 overflow-y-scroll scrollbar-hidden row mx-auto'>
        {/* Left */}
        <div className='border border-success d-none d-lg-block col-6 px-0'>
          <img src={`/images/sc-fp.png`} className='bg-primary vh-100 w-100 object-fit-contain' alt="logo" />
        </div>

        {/* Right  */}
        <div className='border border-success col-12 col-lg-6 px-0 pb-5'>
          {/* Emilo Logo */}
          <div className='text-center my-4'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='text-center pt-3 pb-2 mx-auto shadow rounded-4 mb-4' style={{ width: '200px' }}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Select Option */}
          <>
            {
              showSelectOption
              &&
              <div className='w-75 mx-auto'>
                <h3 className='m-0'>Select Option</h3>

                <span className='mb-3'>Select from which contact detail you want to reset your pin.</span>

                {/* Mobile */}
                <div className='mb-3'>
                  <input type="radio" className="btn-check" id="select-option-mobile" name="select-option" value='sms' onChange={(e) => setSelectedOption(e.target.value)} checked={selectedOption === 'sms'} autocomplete="off" />
                  <label className={`btn btn-outline-${selectedOption === 'sms' ? 'primary' : 'secondary'} d-flex`} for="select-option-mobile">
                    <img src={`/images/icons/sc-fp-sms-${selectedOption === 'sms' ? 'w' : 'b'}.svg`} className='me-3' style={{ width: '45px' }} alt="mobile" />

                    <div>
                      <p className={`${selectedOption === 'sms' ? 'text-white' : 'text-dark'} text-start`}>Via SMS</p>
                      <p className={`${selectedOption === 'sms' ? 'text-white' : 'text-dark'}`}>7415741574</p>
                    </div>
                  </label>
                </div>

                {/* Email */}
                <div className='mb-3'>
                  <input type="radio" className="btn-check" id="select-option-email" name="select-option" value='email' onChange={(e) => setSelectedOption(e.target.value)} checked={selectedOption === 'email'} autocomplete="off" />
                  <label className={`btn btn-outline-${selectedOption === 'email' ? 'primary' : 'secondary'} d-flex`} for="select-option-email">
                    <img src={`/images/icons/sc-fp-email-${selectedOption === 'email' ? 'w' : 'b'}.svg`} className='me-3' style={{ width: '33px' }} alt="mobile" />

                    <div>
                      <p className={`${selectedOption === 'email' ? 'text-white' : 'text-dark'} text-start`}>Via Email</p>
                      <p className={`${selectedOption === 'email' ? 'text-white' : 'text-dark'}`}>siddharth@gmail.com</p>
                    </div>
                  </label>
                </div>

                {/* Button */}
                <div className='text-center'>
                  <button type="button" className='btn btn-primary rounded-pill px-5' onClick={handleSelectOption}>Send OTP</button>
                </div>
              </div>
            }
          </>

          {/* OTP */}
          <>
            {
              showOtp
              &&
              <div className='w-75 mx-auto text-center'>
                <h3 className='m-0'>Enter OTP</h3>

                <span className='mb-3'>We have sent an OTP to your mobile number 7415741574.</span>

                <OtpInput containerStyle={{ justifyContent: 'center' }} value={OTP} onChange={setOTP} numInputs={4} renderSeparator={<pre> </pre>}
                  renderInput={(props) => (
                    <input {...props} className="onboardingOTP-area-data-inputOTP mb-3" style={{ width: "70px" }} />
                  )}
                />

                {/* Button */}
                <div className='text-center'>
                  <button type="button" className='btn btn-primary rounded-pill px-5' onClick={handleOtp}>Done</button>
                </div>
              </div>
            }
          </>

          {/* Pin */}
          <>
            {
              showChangePin
              &&
              <div className='w-75 mx-auto text-center'>
                <h3 className='m-0'>Change Pin</h3>

                <span className='mb-3'>Now you can change your softcorner pin.</span>

                <OtpInput containerStyle={{ justifyContent: 'center' }} value={pin} onChange={setPin} numInputs={4} renderSeparator={<pre> </pre>}
                  renderInput={(props) => (
                    <input {...props} className="onboardingOTP-area-data-inputOTP mb-3" style={{ width: "70px" }} />
                  )}
                />

                {/* Button */}
                <div className='text-center'>
                  <button type="button" className='btn btn-primary rounded-pill px-5' onClick={handleChangePin}>Done</button>
                </div>
              </div>
            }
          </>
        </div>
      </section> 
    </>
  )
}

export default ForgotSoftcornerPin
