import React, { useEffect } from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'
import { getTodayDate } from '../../../config';
// const randonValue = Math.floor(Math.random() * 3) + 1;
const randonValue = 3;
const UsageLimitAlert = ({ setTwoFactor }) => {
    useEffect(() => {
        // Create a new link element for the CSS file
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/css/TwoFactorVerifyPage.css'; // Replace with the path to your CSS file
        // Append the link element to the document head
        document.head.appendChild(link);
        return () => {
            // Cleanup function to remove the link when the component unmounts
            document.head.removeChild(link);
        };

      }, []);

    

    return (
        <div className="login-area">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={6} xs={12}>
                        <div className="TwoFactorVerifyDiv p-4 mt-5">
                            <div>
                                {/* <h3 className="text-info ml-0 mr-0">Daily Limiy Reminder</h3> */}
                                <div className="text-center">
                                    <img src={`/images/reminder/${randonValue}.png`} alt="" />
                                </div>
                                <h4 className='text-info text-center mt-4 mb-4' style={{ lineHeight: '2.5rem' }}>
                                    You have exceeded your set time limit in this app, Please consider taking a break!
                                </h4>
                                <div className='d-flex mt-3 justify-content-between'>
                                    <button
                                        className='btn btn-success text-white'
                                        onClick={() => {
                                            setTwoFactor(4);
                                        }}
                                    >
                                        Remind me Again
                                    </button>
                                    <button
                                        className='btn btn-info text-white'
                                        onClick={() => {
                                            localStorage.setItem('UsageLimitForgot', getTodayDate());
                                            setTwoFactor(4);
                                        }}
                                    >
                                        Forgot Limit Today
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default UsageLimitAlert
