import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from './ApiCall';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';

const ProfileImage = ({ userId, height, width, borderRadius }) => {

  const [profileImage, setProfileImage] = useState()

  const { getProfileImage, } = useContext(ApiContext);

  useEffect(() => {
    getProfileImage(userId, setProfileImage)
  }, [userId]);


  return (
    <>
    {
      profileImage
      ? 
      <>
      {profileImage && <img className='profile-user' src={AwsFileUrl +  profileImage.imageId} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/dummy.png`} height={height} width={width} style={{ borderRadius: borderRadius, border: '1px solid #caced2' }} />}
      </>
      : 
      <img src={`${BASE_URL_STATIC}/dummy.png`} alt="dummy" height={height} width={width} style={{ borderRadius: borderRadius, border: '1px solid #caced2' }} />
    }
  </>
  )  
}

export default ProfileImage;

