import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { BASEURL, BASE_URL_STATIC } from "../../../../../../config";
import toast from "react-hot-toast";
import EditBestClipPopup from "./EditPost/EditBestClipPopup";
import VisibilityBestClipPopup from "./Visibility/VisibilityBestClipPopup";
import ReportBestClip from "../../../Report/ReportBestClip";
import ServicePost from "../../../Service/ServicePost";
import { profileContext } from "../../../../UseContext/ProfileContext";

const ProfileBestClipThreeDotPopup = ({ showProfileBestClipThreeDotPopup, iframeCode, setShowProfileBestClipThreeDotPopup, setShowProfileBestClipPopup, profileBestClipPopupData, setprofileBestClipPopupData }) => {

  const [isNotificationSet, setIsNotificationSet] = useState(false);
  const [isIntrested, setIsIntrested] = useState(false);

  const [showEditBestClipPopup, setShowEditBestClipPopup] = useState(false);
  const [showVisibilityBestClipPopup, setShowVisibilityBestClipPopup] = useState(false);

  const { config, loggedInUserId, profileUserId, profileUserPostCount, getProfileBestClips,
    checkFavouriteUser, isFavouriteUser, handleCreateFavouriteUser,
    checkBothFollow, showAddCloseFriend, checkCloseFriend, handleCreateCloseFriend, isCloseFriend,
    putSnooze, checkSnoozeUser, isUserSnooze,
    handleHideBestClip,
    checkBlocked, handleBlockUnblock, isBlocked,
    setShowReportBestClipPopup, setShowServicePopup,
    isUnfollow, checkUnfollow, handleUnfollow,
  } = useContext(ApiContext);

  const {setProfileBestclipData} = useContext(profileContext)

  // console.log(profileBestClipPopupData);

  // Delete best clip
  const deleteBestClip = (bestClipId, config) => {
    axios.post('https://emilonode.sjain.io/post/deleteuserbestclip', { bestclipId: bestClipId }, config)
      .then(res => {
        // console.log(res);
        setShowProfileBestClipThreeDotPopup(false);
        setShowProfileBestClipPopup(false);
        getProfileBestClips(profileUserId, config);
        profileUserPostCount(profileUserId);
      })
      .catch(err => {
        return null;
      });
  };

  useEffect(() => {
    if (profileBestClipPopupData) {
      checkFavouriteUser(profileBestClipPopupData.userId, config);
      checkBothFollow(profileBestClipPopupData.userId, config);
      checkCloseFriend(profileBestClipPopupData.userId, config);
      checkSnoozeUser(profileBestClipPopupData.userId, config);
      checkBlocked(profileBestClipPopupData.userId);
      checkUnfollow(profileBestClipPopupData.userId);
      setProfileBestclipData(profileBestClipPopupData)
    };
  }, [profileBestClipPopupData]);

  return (
    <>
      {/* Edit BestClip Popup */}
      <EditBestClipPopup showEditBestClipPopup={showEditBestClipPopup} setShowEditBestClipPopup={setShowEditBestClipPopup} setShowProfileBestClipThreeDotPopup={setShowProfileBestClipThreeDotPopup} profileBestClipPopupData={profileBestClipPopupData} setprofileBestClipPopupData={setprofileBestClipPopupData} />

      {/* Visibility BestClip Popup */}
      <VisibilityBestClipPopup showVisibilityBestClipPopup={showVisibilityBestClipPopup} setShowVisibilityBestClipPopup={setShowVisibilityBestClipPopup} />

      {/* Report BestClip */}
      <ReportBestClip profileBestClipPopupData={profileBestClipPopupData} />

      {/* Report Service */}
      <ServicePost type='bestclip' />



      {
        profileBestClipPopupData
          &&
          profileBestClipPopupData.userId === loggedInUserId
          ?
          // It will show to logged in user popup
          <Modal show={showProfileBestClipThreeDotPopup} onHide={() => setShowProfileBestClipThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body className="popupModal-body">
              {/* Add to album */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon5.png`} alt="" />
                </div>
                <div className="textBox">
                  Add to album
                  <span>Add image to album.</span>
                </div>
              </div>

              {/* Edit */}
              <div className="linkBox" onClick={() => setShowEditBestClipPopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="" />
                </div>
                <div className="textBox">
                  Edit Bestclip
                  <span>Update this bestclip.</span>
                </div>
              </div>

              {/* Visibility */}
              <div className="linkBox" onClick={() => setShowVisibilityBestClipPopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon4.png`} alt="" />
                </div>
                <div className="textBox">
                  Visibility
                  <span>Want to change audience, who can see this bestclip.</span>
                </div>
              </div>

              {/* Notification */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                </div>
                <div className="textBox">
                  Don't show notification for this bestclip
                  <span>Do not notify when someone likes or comment to this bestclip.</span>
                </div>
              </div>

              {/* Copy link */}
              <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileBestClipPopupData.bestclipId}`} onCopy={() => toast.success('copied')}>
                <div className='linkBox' style={{ cursor: 'pointer' }}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                  </div>
                  <div className="textBox">
                    Copy Link
                    <span>Copy this bestclip link & share it with your friend's and more.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Implant */}
              <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
                <div className="linkBox">
                  <div className="imgBox">
                    {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                    <span className='implant-img'>{'</>'}</span>
                  </div>
                  <div className="textBox">
                    Implant
                    <span>Want to show this bestclip in your website.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Use in reel */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/menu-icon6.png`} alt="" />
                </div>
                <div className="textBox">
                  Use in reel
                  <span>Want to use it to create reel.</span>
                </div>
              </div>

              {/* Delete */}
              <div className="linkBox last" onClick={() => deleteBestClip(profileBestClipPopupData.bestclipId, config)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon12.png`} alt="" />
                </div>

                <div className="textBox">
                  Delete Bestclip
                  <span>Want to delete this bestclip.</span>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          :
          profileBestClipPopupData
          &&
          // It will show to other user popup
          <Modal show={showProfileBestClipThreeDotPopup} onHide={() => setShowProfileBestClipThreeDotPopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body className="popupModal-body">
              <h5>Why am I seeing this bestclip? <div>Know more</div></h5>

              {profileBestClipPopupData.userId !== loggedInUserId && <h6>You follow <div style={{ textTransform: 'capitalize' }}>‘{profileBestClipPopupData.fname}’</div></h6>}

              {/* Add to favourites */}
              <>
                {
                  profileBestClipPopupData.userId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => handleCreateFavouriteUser(profileBestClipPopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                    </div>
                    {
                      isFavouriteUser
                        ?
                        <div className="textBox">
                          Add ‘{profileBestClipPopupData.fname}’ to favourites
                          <span>Add to your favourites if you want to see his/her bestclip more.</span>
                        </div>
                        :
                        <div className="textBox">
                          Remove ‘{profileBestClipPopupData.fname}’ from favourites
                          <span>Remove from your favourites. Want less bestclip from his/her.</span>
                        </div>
                    }
                  </div>
                }
              </>

              {/* Add to close friends */}
              <>
                {
                  (profileBestClipPopupData.userId !== loggedInUserId && !showAddCloseFriend)
                  &&
                  <div className="linkBox" onClick={() => handleCreateCloseFriend(profileBestClipPopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                    </div>
                    {
                      isCloseFriend
                        ?
                        <div className="textBox">
                          Add ‘{profileBestClipPopupData.fname}’ to close friend
                          <span>Add to your close friend if you want to see his/her bestclip more.</span>
                        </div>
                        :
                        <div className="textBox">
                          Remove ‘{profileBestClipPopupData.fname}’ from close friend
                          <span>Remove from close friend.</span>
                        </div>
                    }
                  </div>
                }
              </>

              {/* Set notification for user post */}
              <>
                {
                  profileBestClipPopupData.userId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => setIsNotificationSet(!isNotificationSet)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon9.png`} alt="" />
                    </div>

                    <div className="textBox">
                      {isNotificationSet ? 'Unset' : 'Set'} notification for ‘{profileBestClipPopupData.fname}’ bestclip
                      <span>Want to {isNotificationSet ? 'stop notifying' : 'get notify'}  when ‘{profileBestClipPopupData.fname}’ post something</span>
                    </div>
                  </div>
                }
              </>

              {/* Interested */}
              <div className="linkBox" onClick={() => setIsIntrested(!isIntrested)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" />
                </div>
                <div className="textBox">
                  {isIntrested ? 'Not Intrested' : 'Intrested'}
                  <span>Want {isIntrested ? 'less' : 'more'} bestclip like this to see in your feed.</span>
                </div>
              </div>

              {/* Copy Link */}
              {profileBestClipPopupData.bestclipId && <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileBestClipPopupData.bestclipId}`} onCopy={() => toast.success('copied')}>
                <div className={profileBestClipPopupData.userId === loggedInUserId ? `linkBox last` : `linkBox`} style={{ cursor: 'pointer' }}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                  </div>
                  <div className="textBox">
                    Copy Link
                    <span>
                      Copy this bestclip link & share it with your friend's and more.
                    </span>
                  </div>
                </div>
              </CopyToClipboard>}

              {/* Implant */}
              <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
                <div className="linkBox">
                  <div className="imgBox">
                    {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                    <span className='implant-img'>{'</>'}</span>
                  </div>
                  <div className="textBox">
                    Implant
                    <span>Want to show this bestclip in your website.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Put user snooze for 30 days */}
              <>
                {
                  (profileBestClipPopupData.userId !== loggedInUserId)
                  &&
                  <div className="linkBox" onClick={() => putSnooze(profileBestClipPopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                    </div>
                    <div className="textBox">
                      {
                        isUserSnooze
                          ?
                          `Put ‘${profileBestClipPopupData.fname}’ snooze for 30 Days`
                          :
                          `Remove ‘${profileBestClipPopupData.fname}’ from snooze`
                      }
                      <span>Stop seeing post for 30 days.</span>
                    </div>
                  </div>
                }
              </>

              {/* Hide This Bestclip */}
              <>
                {
                  profileBestClipPopupData.postId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => handleHideBestClip(profileBestClipPopupData.bestclipId)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon5.png`} alt="" />
                    </div>
                    <div className="textBox">
                      Hide This Bestclip
                      <span>Don't want to see this bestclip</span>
                    </div>
                  </div>
                }
              </>

              {/* Unfollow */}
              <div className="linkBox" onClick={() => handleUnfollow(profileBestClipPopupData.userId)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
                </div>
                <div className="textBox">
                  {isUnfollow ? 'Follow' : 'Unfollow'} ‘{profileBestClipPopupData.fname}’
                  <span>Stop seeing ‘{profileBestClipPopupData.fname}’ bestclip.</span>
                </div>
              </div>

              {/* Report */}
              <div className="linkBox" onClick={() => setShowReportBestClipPopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon7.png`} alt="" />
                </div>
                <div className="textBox">
                  Report Bestclip
                  <span>Do you think this bestclip is wrong, please let us know.</span>
                </div>
              </div>

              {/* Report Service */}
              <div className="linkBox" onClick={() => setShowServicePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="pen" />
                </div>
                <div className="textBox">
                  Report Service
                  <span>Any suggestion or improvement you think to do.</span>
                </div>
              </div>

              {/* Use in reel */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                </div>
                <div className="textBox">
                  Use in reel
                  <span>Want to use it to create reel.</span>
                </div>
              </div>

              {/* Block */}
              <div className="linkBox last" onClick={() => handleBlockUnblock(profileBestClipPopupData.userId)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
                </div>
                <div className="textBox">
                  {isBlocked ? 'Unblock' : 'Block'} ‘{profileBestClipPopupData.fname}’
                  <span>Stop seeing or contact each other.</span>
                </div>
              </div>
            </Modal.Body>
          </Modal>
      }
    </>
  )
}

export default ProfileBestClipThreeDotPopup
