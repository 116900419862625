import React, { useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import Rating from 'react-rating';


const BusinessWriteReviewPopup = () => {

  const { 
    paramsPageId,
    showWriteReveiwPopup, setShowWriteReveiwPopup,
    addNewReview, setAddNewReview,
    createPageReview,
    getPageReviews,
  } = useContext(businessContext);


  useEffect(() => {
    getPageReviews(paramsPageId);
  }, []);

  return (
    <>
      <Modal show={showWriteReveiwPopup} onHide={() => setShowWriteReveiwPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
      {/* <Modal show={true} onHide={() => setShowWriteReveiwPopup(false)} keyboard={false} className="create-post" backdrop="static" centered> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Write a Review</h4>

          <div className='text-center'>
            <Rating
              className=''
              initialRating={addNewReview.rating}
              onClick={(value) => setAddNewReview((prevReview) => ({ ...prevReview, rating: value }))}
              emptySymbol={<img src={`/images/icons/rating-1.svg`} className='me-1' style={{ width: '30px' }} alt="empty" />}
              fullSymbol={<img src={`/images/icons/rating-2.svg`} className='me-1' style={{ width: '30px' }} alt="fill" />}
            />
          </div>

          <textarea
            className='border rounded-3 p-3 my-3 w-100'
            style={{ height: '150px' }}
            value={addNewReview.review}
            onChange={(e) => setAddNewReview({ ...addNewReview, review: e.target.value })} // Update review text
            placeholder="Write your review here..."
          />

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={() => createPageReview(paramsPageId)}
              disabled={addNewReview.rating === '' || addNewReview.review === ''}
            >Submit</button>
          </div>
        </Modal.Body>
      </Modal> 
    </>
  )
}

export default BusinessWriteReviewPopup
