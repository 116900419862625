import React from 'react';
import ScChatAreaRight from './ScChatAreaRight';

const ChatHome = () => {
  return (
    <>
      <ScChatAreaRight />
    </>
  )
}

export default ChatHome