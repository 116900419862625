import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/css/bootstrap.min.css';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/main.css';
import './assets/css/userregistration.css'
import './assets/css/style.css';
import './assets/css/softcorner/softcorner.css';
import './assets/css/responsive/mobile1.css';
import './assets/css/responsive/mobile2.css';
import './assets/css/responsive/tab1.css';
import './assets/css/responsive/lg1.css';
import './assets/css/emilo.css';
import './assets/css/Business/Business.css'

import './assets/css/bootstrap/xs.css';
import './assets/css/bootstrap/sm.css';
import './assets/css/bootstrap/md.css';
import './assets/css/bootstrap/lg.css';
import './assets/css/bootstrap/xl.css';
import './assets/css/bootstrap/xll.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);
// root.render("<div>safj</div>");

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
