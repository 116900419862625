import React, { useState, useEffect, useContext } from "react";
import NewPlan from "../Popup/DatePlanner/NewPlan";
import { Hearts } from "react-loader-spinner";

import "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css";
import { scheduler } from "dhtmlx-scheduler";
import { getDatePlanner } from "../../Emilo/UseContext/ScApiHelpers";
import { ScApiContext } from "../useContext/softCornerContext";
import DatePlanner2 from "./DatePlanner2";
import NoupcommingEvents from "./NoupcommingEvents";
import { BASE_URL_STATIC } from "../../../config";


const DatePlanner = () => {
  const [showNewPlanPopup, setShowNewPlanPopup] = useState(false);
  const { scConfig } = useContext(ScApiContext);
  const [plans, setPlans] = useState([]);

  const [singlePlan, setSinglePlan] = useState(null)
  const [showPlanDetails, setShowPlanDetails] = useState(false)
  const [loader, setLoadr] = useState(false)

  useEffect(() => {
    setLoadr(true);
    getDatePlanner(scConfig)
      .then((res) => {
        const filteredPlans = res.filter((plan) => {
          const planDate = new Date(plan.startDateTime);
          const currentDate = new Date();
          return planDate >= currentDate;
        });
        setLoadr(false);
        setPlans(filteredPlans);
      })
      .catch((error) => {
        console.error("Error fetching date planner:", error);
        setLoadr(false);
      });
  }, [showPlanDetails, showNewPlanPopup]);

  useEffect(() => {
    scheduler.config.first_hour = 6;
    scheduler.config.last_hour = 1;
    scheduler.config.header = [
      "day",
      "week",
      "month",
      "date",
      "prev",
      "today",
      "next",
    ];
  
    scheduler.init("scheduler_here", new Date(), "month");
  
    const addLeadingZero = (value) => (value < 10 ? `0${value}` : value);
  
    const formatDateTime = (dateTimeString) => {
      const date = new Date(dateTimeString);
      // Convert to UTC+5:30 (Indian Standard Time)
      // date.setUTCHours(date.getUTCHours() + 5, date.getUTCMinutes() + 30);
  
      const formattedDate = `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(date.getDate())}`;
      const formattedTime = `${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
      
      return `${formattedDate} ${formattedTime}`;
    };
  
    const formatEndDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      // Convert to UTC+5:30 (Indian Standard Time)
      date.setUTCHours(date.getUTCHours() + 5, date.getUTCMinutes() + 30);
  
      const formattedDate = `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(date.getDate())}`;
        console.log("data" , `${formattedDate} 12:00`);
      return `${formattedDate} 23:59`;
    };
  
    scheduler.parse(
      plans.map((plan) => ({
        id: plan.createdTime,
        start_date: `${formatDateTime(plan.startDateTime)}`,
        end_date: `${
          plan.allDay === false
            ? formatDateTime(plan.endDateTime)
            : formatEndDate(plan.startDateTime)
        }`,
        text: plan.title,
      }))
    );


  
    return () => {
      scheduler.clearAll();
    };
  }, [plans]);
  

  // Function to format date
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const formatTime = (dateTimeString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateTimeString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  console.log("plans", plans);


  return (
    <>
      <NewPlan
        showNewPlanPopup={showNewPlanPopup}
        setShowNewPlanPopup={setShowNewPlanPopup}
      />

      <section className="sc-datePlanner">
        <section className="sc-datePlanner-centerBlock">
          <div className="sc-datePlanner-centerBlock-div-1">
            <form>
              <label htmlFor="search">
                <img
                  src={`${BASE_URL_STATIC}/search.png`}
                  alt="search"
                  height={23}
                  width={25}
                />
                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Search"
                />
              </label>
            </form>

            <div
              className="sc-datePlanner-centerBlock-div-1-asd"
              onClick={() => setShowNewPlanPopup(true)}
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`}
                alt="sc-addAlbum"
                height={23}
                width={23}
              />
              <span>New Plan</span>
            </div>
          </div>

          {/* Div 2 */}
          <div className="sc-datePlanner-centerBlock-div-2">
            <div
              id="scheduler_here"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </section>

        <section className="sc-datePlanner-rb">

          {showPlanDetails && <DatePlanner2 plan={singlePlan} setShowPlanDetails={setShowPlanDetails} />}
          {!showPlanDetails &&
            <div>
              <h2 className="sc-datePlanner-rb-head-1">Upcoming events</h2>
              <p className="sc-datePlanner-rb-head-2">
                Don’t miss any of your scheduled events
              </p>
            </div>
          }

          <div>
            {loader ? (
              <Hearts
                height="100"
                width="100"
                color="#ED1E24"
                ariaLabel="hearts-loading"
                wrapperStyle={{}}
                wrapperClass="gallary-loader"
                visible={true}

              />
            ) :
              <>
                {plans && plans.length > 0 ? (
                  plans.slice(0,5).map((plan, index) => (

                    <div key={index}>
                      {!showPlanDetails &&

                        <div
                          onClick={() => { setShowPlanDetails(true); setSinglePlan(plan) }}

                          className="sc-datePlanner-rb-plan"
                        >
                          <span className="sc-datePlanner-rb-plan-date">
                            {formatDate(plan.startDateTime !== "" && plan.startDateTime)}{plan.endDateTime && " - " + formatDate(plan.endDateTime !== "" && plan.endDateTime)}
                          </span>
                          <div className="sc-datePlanner-rb-plan-d1">
                            <div className="sc-datePlanner-dates">

                              <span className="sc-datePlanner-rb-plan-time child1" style={{ fontSize: "18px", fontWeight: "400" }}>
                                {formatTime(plan.startDateTime !== "" && plan.startDateTime)}  -
                              </span>
                              <small className="sc-datePlanner-rb-plan-time">
                                {plan.allDay === true ? "All Day" : formatTime(plan.endDateTime !== "" && plan.endDateTime)}
                              </small>

                            </div>

                            <div className="sc-datePlanner-rb-plan-img">
                              <img
                                src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`}
                                alt="coffe"
                                height={25}
                                width={25}
                              />
                            </div>
                            <div className="sc-datePlanner-rb-plan-name">
                              <p style={{ fontWeight: "500" }}>{plan.title}</p>
                              <span>{plan.location}</span>
                            </div>
                          </div>
                        </div>
                      }

                    </div>

                  ))
                ) : (
                  <NoupcommingEvents />
                )}
              </>

            }
          </div>

        </section>

      </section>

    </>
  );
};

export default DatePlanner;
