import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL_STATIC } from "../../../config";

const GallaryAlbum = () => {
  return (
    <>
      <section className="sc-gallary">
        <section>
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`}
                alt="sc-gallary-photos"
                height={30}
                width={30}
              />
              <span>Photos</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Album`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`}
                alt="sc-gallary-album"
                height={30}
                width={30}
              />
              <span>Album</span>
            </Link>

            <Link
              to={`/SoftCorner/Gallary-Explore`}
              className="sc-gallary-nav-div"
            >
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`}
                alt="sc-gallary-explore"
                height={30}
                width={30}
              />
              <span>Explore</span>
            </Link>
          </div>

          <div className="sc-gallary-album-images">
            <div className="sc-gallary-album-images-div">
              <Link to={`/SoftCorner/Gallary-Album/favourites`}>
                <img
                  src={`${BASE_URL_STATIC}/cp-5.png`}
                  alt="cp-2"
                  height={325}
                  width={325}
                />
                <h3>Favorites</h3>
              </Link>
            </div>

            <div className="sc-gallary-album-images-div">
              <Link to={`/SoftCorner/Gallary-Album/photos`}>
                <img
                  src={`${BASE_URL_STATIC}/cp-3.png`}
                  alt="cp-2"
                  height={325}
                  width={325}
                />
                <h3>Photos</h3>
              </Link>
            </div>

            <div className="sc-gallary-album-images-div">
              <Link to={`/SoftCorner/Gallary-Album/videos`}>
                <img
                  src={`${BASE_URL_STATIC}/cp-4.png`}
                  alt="cp-2"
                  height={325}
                  width={325}
                />
                <h3>Videos</h3>
              </Link>
            </div>
          </div>

          <div className="sc-gallary-album-div">
            <div className="sc-gallary-album-div-addAlbum">
              <img
                src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`}
                alt="sc-addAlbum"
                height={50}
                width={50}
              />
              <span>Add Album</span>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default GallaryAlbum;
