import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';

const LikedReels = () => {

  const [likedReels, setLikedReels] = useState([]);

  const { config } = useContext(ApiContext);

  // To get liked reels
  const GetLikedReels = async (config) => {
    await axios.post('https://emilonode.sjain.io/post/getreellikebyuser', {}, config)
    .then(res => {
      // console.log(res.data.reelLikeCount);
      setLikedReels(res.data.reelLikeCount.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    GetLikedReels(config);
  }, []);

  return (
    <>
     <section className='liked-reels'>
        <h1 className='liked-reels-head'>Reels</h1>

        <div className='liked-reels-imgs'>
          {
            likedReels.map((likedReel, index) => (
              // console.log(likedReel)
              <img className='liked-reels-img' src={likedReel.tumbnailUrl} alt={likedReel.tumbnailId} height={215} width={200} />
            ))
          }
        </div>
      </section> 
    </>
  )
}

export default LikedReels
