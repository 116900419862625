import React, { useState } from 'react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { BASE_URL_STATIC } from '../../../../../config';

const DeactivateDelete = () => {

  const [showDeactivateReasons, setShowDeactivateReasons] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState();
  const [deactivateTime, setDeactivateTime] = useState();
  const [password, setPassword] = useState();
  const [showDeactivateOtp, setShowDeactivateOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [showDeactivateBtn, setShowDeactivateBtn] = useState(false);

  const [showDeleteReasons, setShowDeleteReasons] = useState(false);
  const [deleteReason, setDeleteReason] = useState();
  const [showDeleteOtp, setShowDeleteOtp] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);

  // To handle deactivate cancel
  const handleDeactivateCancel = () => {
    setShowDeactivateReasons(false);
    setDeactivateReason();
    setDeactivateTime();
    setShowDeactivateOtp(false);
    setOtp();
    setShowDeactivateBtn(false);
  };

  // To handle deactivate
  const handleDeactivate = () => {
    setShowDeactivateReasons(false);
    setDeactivateReason();
    setDeactivateTime();
    setShowDeactivateOtp(false);
    setOtp();
    setShowDeactivateBtn(false);

    toast.success('Account Deactivated Successfully');
  };

  // To handle delete cancel
  const handleDeleteCancel = () => {
    setShowDeleteReasons(false);
    setDeleteReason();
    setShowDeleteOtp(false);
    setOtp();
    setShowDeleteBtn(false);
  };

  // To handle delete 
  const handleDelete = () => {
    setShowDeleteReasons(false);
    setDeleteReason();
    setShowDeleteOtp(false);
    setOtp();
    setShowDeleteBtn(false);

    toast.success('Account Deleted Successfully');
  };

  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden pb-5 pb-lg-0' id='deactivateDeleteToggle'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Deactivate & Delete</li>
          </ol>
        </nav>

        {/* ----- Deactivate ----- */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#deactivateAccount">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Deactivate account</h5>
          </div>

          <div id='deactivateAccount' className='accordion-collapse collapse show ms-3' data-bs-parent="#deactivateDeleteToggle">
            <h5 className='accordion-level-4 shadow-sm bg-warning text-white px-4 py-2 my-3'>Deactivate account</h5>

            <span className='text-secondary mb-2 ms-3'>Deactivating your account will temporarily hide your profile and activity from other users. You can activate your account any time.</span>

            {/* Reason */}
            <>
              {
                showDeactivateReasons
                &&
                <div className=' ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>Reason for Deactivation</h5>

                  <div className='mt-3 ms-3 d-flex flex-column'>
                    <span className='text-secondary mb-2'>We value your feedback. Please let us know why you're choosing to delete your account. Your insights can help us improve our platform.</span>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deactivateReason-1" name="deactivateReasons" value='deactivateReason-1' onChange={(e) => setDeactivateReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deactivateReason-1">My account was hacked.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deactivateReason-2" name="deactivateReasons" value='deactivateReason-2' onChange={(e) => setDeactivateReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deactivateReason-2">I have a privacy concern.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deactivateReason-3" name="deactivateReasons" value='deactivateReason-3' onChange={(e) => setDeactivateReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deactivateReason-3">I spend too much time on emilo.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deactivateReason-4" name="deactivateReasons" value='deactivateReason-4' onChange={(e) => setDeactivateReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deactivateReason-4">I don't understand how to use emilo.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deactivateReason-5" name="deactivateReasons" value='deactivateReason-5' onChange={(e) => setDeactivateReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deactivateReason-5">Other Reason.</label>
                    </div>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {/* Time */}
            <>
              {
                deactivateReason !== undefined
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>Deactivation Time Period</h5>

                  <div className='mt-3 ms-3'>
                    <span className='text-secondary mb-2'>Select period of time till you want to deactivate your account, after selected time period end your account will activate automatically.</span>

                    <div className="form-floating">
                      <select className="form-select" id="deactivateTime" onChange={(e) => setDeactivateTime(e.target.value)}>
                        <option value='1'>1 day</option>
                        <option value="2">1 week</option>
                        <option value="3">1 month</option>
                        <option value="3">1 year</option>
                        <option value="none">Don't activate automatically</option>
                      </select>

                      <label htmlFor="deactivateTime">Automatically active my account in</label>
                    </div>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {/* Password */}
            <>
              {
                deactivateTime !== undefined
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>Password</h5>

                  <div className='mt-3 ms-3'>
                    <div className='d-flex '>
                      <input type='password' className='form-control me-2 me-xl-3' onChange={(e) => setPassword(e.target.value)} placeholder='Enter your emilo password...' />
                      <button type="button" className='btn btn-primary' disabled={password === undefined || password  === ''} onClick={() => {setShowDeactivateOtp(true); setDeactivateTime();}}>Done</button>
                    </div>

                    <span className='form-text'>For the security of your account please enter your emilo password so that we can ensure that you are deactivating your account.</span>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {/* OTP */}
            <>
              {
                showDeactivateOtp 
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>OTP</h5>

                  <div className='mt-3 ms-3'>
                    <span className='text-secondary mb-2'>We have send an OTP to your register email id <span className='text-primary'>siddharth@gmail.com</span></span>

                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<pre> </pre>}
                      renderInput={(props) => <input {...props} className='rounded-3 border text-center fs-3 text-secondary' style={{ height: '45px', width: '45px' }} />}
                    />

                    <button type="button" className='btn btn-primary mt-2' disabled={String(otp).length !== 4} onClick={() => {setShowDeactivateBtn(true); setShowDeactivateOtp(false)}}>Done</button>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {!showDeactivateReasons && <button type="button" className='btn btn-outline-warning rounded-pill px-5 ms-3' onClick={() => setShowDeactivateReasons(true)}>Continue</button>}
            {showDeactivateReasons && <button type="button" className='btn btn-outline-primary rounded-pill px-3 px-lg-5 me-3 ms-3' onClick={handleDeactivateCancel}>Cancel</button>}
            {showDeactivateBtn && <button type="button" className='btn btn-primary rounded-pill px-3 px-lg-5' onClick={handleDeactivate}>Deactivate</button>}

            <hr className='hr bg-secondary' />
          </div>
        </div>

        {/* ----- Delete ----- */}
        <div className='accordion accordion-flush'>
          <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#deleteAccount">
            <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" />
            <h5 className='fw-semibold ms-3'>Delete account</h5>
          </div>

          <div id='deleteAccount' className='accordion-collapse collapse ms-3' data-bs-parent="#deactivateDeleteToggle">
            <h5 className='accordion-level-4 shadow-sm bg-danger text-white px-4 py-2 my-3'>Delete account</h5>

            <span className='text-secondary mb-2 ms-3'>Deleting your account will permanently remove all your data, including your profile, posts, flix, bestclip, meme and etc. your account will delete after 30 day till you can cancel the deletion any time.</span>

            {/* Reason */}
            <>
              {
                showDeleteReasons
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>Reason for Delete</h5>

                  <div className='mt-3 ms-3 d-flex flex-column'>
                    <span className='text-secondary mb-2'>We value your feedback. Please let us know why you're choosing to deactivate your account. Your insights can help us improve our platform.</span>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deleteReason-1" name="deleteReasons" value='deleteReason-1' onChange={(e) => setDeleteReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deleteReason-1">This is temporary, i'll be back.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deleteReason-2" name="deleteReasons" value='deleteReason-2' onChange={(e) => setDeleteReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deleteReason-2">I have a privacy concern.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deleteReason-3" name="deleteReasons" value='deleteReason-3' onChange={(e) => setDeleteReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deleteReason-3">I spend too much time on emilo.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deleteReason-4" name="deleteReasons" value='deleteReason-4' onChange={(e) => setDeleteReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deleteReason-4">I don't understand how to use emilo.</label>
                    </div>

                    <div className="form-check ms-1 mb-1">
                      <input type="radio" className="form-check-input" id="deleteReason-5" name="deleteReasons" value='deleteReason-5' onChange={(e) => setDeleteReason(e.target.value)} />
                      <label className="form-check-label" htmlFor="deleteReason-5">Other Reason.</label>
                    </div>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {/* Password */}
            <>
              {
                deleteReason !== undefined
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>Password</h5>

                  <div className='mt-3 ms-3'>
                    <div className='d-flex'>
                      <input type='password' className='form-control me-2 me-xl-3' onChange={(e) => setPassword(e.target.value)} placeholder='Enter your emilo password...' />
                      <button type="button" className='btn btn-primary' disabled={password === undefined || password  === ''} onClick={() => {setShowDeleteOtp(true); setDeleteReason();}}>Done</button>
                    </div>

                    <span className='form-text'>For the security of your account please enter your emilo password so that we can ensure that you are deactivating your account.</span>
                  </div>
                  <hr className='hr' />
                </div>
              }
            </>

            {/* OTP */}
            <>
              {
                showDeleteOtp 
                &&
                <div className='ms-3'>
                  <h5 className='accordion-level-4 shadow-sm'>OTP</h5>

                  <div className='mt-3 ms-3'>
                    <span className='text-secondary mb-2'>We have send an OTP to your register email id <span className='text-primary'>siddharth@gmail.com</span></span>

                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<pre> </pre>}
                      renderInput={(props) => <input {...props} className='rounded-3 border text-center fs-3 text-secondary' style={{ height: '45px', width: '45px' }} />}
                    />

                    <button type="button" className='btn btn-primary mt-2' disabled={String(otp).length !== 4} onClick={() => {setShowDeleteBtn(true); setShowDeleteOtp(false);}}>Done</button>
                  </div>

                  <hr className='hr' />
                </div>
              }
            </>

            {!showDeleteReasons && <button type="button" className='btn btn-outline-danger rounded-pill px-5 ms-3' onClick={() => setShowDeleteReasons(true)}>Continue</button>}
            {showDeleteReasons && <button type="button" className='btn btn-outline-primary rounded-pill px-3 px-lg-5 me-3 ms-3' onClick={handleDeleteCancel}>Cancel</button>}
            {showDeleteBtn && <button type="button" className='btn btn-danger rounded-pill px-3 px-lg-5' onClick={handleDelete}>Delete</button>}

            <hr className='hr bg-secondary' />
          </div>
        </div>
      </section> 
    </>
  )
}

export default DeactivateDelete;
