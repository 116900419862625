import React from "react";
import { BASE_URL_STATIC } from "../../../config";


const BusinessCreatePost = () => {

  const cardData = [
    {
      image: `/images/business/icons/post-blue.svg`,
      title: "Post",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/boost-svgrepo-com.svg`,
      title: "Boost Post",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/advertising.svg`,
      title: "Create Campaign",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/event-svgrepo.svg`,
      title: "Create Event",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/advertising.svg`,
      title: "Create Boost Post",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/clapboard-play-svgrepo-com.svg`,
      title: "Behind The Scenes",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/time-quarter.svg`,
      title: "Upcoming Projects",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
    {
      image: `/images/business/icons/cycle-svgrepo-com.svg`,
      title: "Social Activity",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo tenetur at magnam.",
    },
  ]; 

  return (
    <>
      <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5" style={{ backgroundColor: "whitesmoke" }} >
        {/* Search Bar, Notification, Chat and Profile */}
        <div className='bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3 my-3'>
          {/* Search Bar */}
          <div className='d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className='me-2 me-sm-3' alt="search" />

            <input type="search" className='w-100 bg-transparent border-0' placeholder='Search...' />
          </div>

          {/* Notification */}
          <div className='mx-3 mx-sm-4 mx-md-5'>
            <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
          </div>

          {/* Chat */}
          <div>
            <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
          </div>

          {/* Profile */}
          <div className='ms-3 ms-sm-4 ms-md-5'>
            <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '30px', width: '30px' }} alt="profile" />
          </div>
        </div>

        <div className="business-create-post p-3 p-sm-4 rounded-3 shadow-sm" style={{ backgroundColor: '#fbfcfd' }}>
          {
            cardData.map((data, index) => (
              <div className="d-flex flex-column align-items-center justify-content-center gap-3 p-3 rounded-3 shadow-sm bg-white" key={index}>
                <img src={data.image} style={{ height: '100px' }} alt="business" />

                <h5 className="fw-semibold mb-0">{data.title}</h5>

                <small className="text-center">{data.description}</small>
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
};

export default BusinessCreatePost;
