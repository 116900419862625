import React, { useContext, useEffect, useState } from 'react';
import AddSpecialDays from '../Popup/SpecialDays/AddSpecialDays';
import { BASE_URL_STATIC } from '../../../config';
import { ScApiContext } from '../useContext/softCornerContext';
import { getSpecialDays } from '../../Emilo/UseContext/ScApiHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import RightSpecialDays from './RightSpecialDays';
import { Hearts } from 'react-loader-spinner';

const SpecialDays = () => {

  const [showAddSpecialDaysPopup, setShowAddSpecialDaysPopup] = useState(false);
  const [specialDays, setSpecialDays] = useState([])
  const [index , setIndex] = useState(0)

  const { scConfig } = useContext(ScApiContext)
  
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [loader , setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    getSpecialDays(scConfig).then(res =>{ setSpecialDays(res) ;setLoader(false)}).catch(() => setLoader(false))
  
  }, [])

  const toggleBackground = (index) => {
    setSelectedItemIndex(index);
  }


  const formatDate = (inputDateString) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const inputDate = new Date(inputDateString);

    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear();

    const formattedDateString = `${month} ${day}, ${year}`;

    return formattedDateString;
  };

  const SpecialDayComponent = ( specialDay ) => {
    // Calculate the number of days since the special day was created
    const daysDifference = Math.floor((Date.now() - new Date(specialDay)) / (1000 * 60 * 60 * 24));

    return daysDifference
  }
 
  return (
    <>
      {/* Popup */}

      <AddSpecialDays setSpecialDays={setSpecialDays} showAddSpecialDaysPopup={showAddSpecialDaysPopup} setShowAddSpecialDaysPopup={setShowAddSpecialDaysPopup} />

      {loader ? <Hearts  height="100"
                width="100"
                color="#ED1E24"
                ariaLabel="hearts-loading"
                wrapperStyle={{}}
                wrapperClass="gallary-loader"
                visible={true}/> : <section className='sc-specialDays'>

        {/* Center Block */}
        <section className='sc-specialDays-centerBlock'>
          {/* Div 1 */}
          <div className='sc-specialDays-centerBlock-div-1'>
            <form>
              <label htmlFor="search">
                <img src={`${BASE_URL_STATIC}/search.png`} alt="search" height={23} width={25} />
                <input type="search" name="search" id="search" placeholder='Search' />
              </label>
            </form>

            <div className='sc-specialDays-centerBlock-div-1-asd' onClick={() => setShowAddSpecialDaysPopup(true)}>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} alt="sc-addAlbum" height={23} width={23} />
              <span>Add Special Days</span>
            </div>
          </div>

          {/* Div 2 */}
          <div className='sc-specialDays-centerBlock-div-3'>
            {
              specialDays && specialDays.map((specialDay, index) => (
                <div key={index} className= 'flex'  onClick={() =>{ setIndex(index); toggleBackground(index);} }>
                  <div className='sc-specialDays-centerBlock-div-3-specialDays' style={{backgroundColor : `${index===selectedItemIndex ? "#FF6969" : ""}`}}>
                    <FontAwesomeIcon style={{color : `${index === selectedItemIndex ? "snow" : "#BFBFBF"}`}} icon={faBars}/>

                    <div className='sc-specialDays-centerBlock-div-3-specialDays-icon'>
                      <img src={specialDay.img} alt="sc-sd-heart" height={30} width={30} />
                    </div>

                    <div className='sc-specialDays-centerBlock-div-3-specialDays-head'>
                      <p  style={{color : `${index === selectedItemIndex ? "snow" : ""}`}}>{specialDay.title}</p>
                      <span style={{color : `${index === selectedItemIndex ? "snow" : "#2676E1"}`}}>{specialDay.location}</span>
                    </div>

                    <div className='sc-specialDays-centerBlock-div-3-specialDays-date'>
                      <h4 style={{color : `${index === selectedItemIndex ? "snow" : ""}`}}>{specialDay.dateTime && SpecialDayComponent(specialDay.dateTime) && SpecialDayComponent(specialDay.dateTime) < 0 ? -(SpecialDayComponent(specialDay.dateTime))+" Days Left" : SpecialDayComponent(specialDay.dateTime)+" Days"}</h4>
                      <span style={{color : `${index === selectedItemIndex ? "snow" : "#BFBFBF"}`}}>{specialDay.dateTime && formatDate(specialDay.dateTime)}</span>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        {/* Right Block */}
      {specialDays && specialDays.length > 0 && <RightSpecialDays specialDay={specialDays[index]}/>}
      </section>}
    </>
  )
}

export default SpecialDays
