import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as cam from '@mediapipe/camera_utils';
let backgroundImage = '';
// , setLoadingBG, loadingBG
const ArVrBgEffectsSection = ({ bgImgUrl, windowWidth, canvasRef }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // setLoading(true);
    backgroundImage = new Image();
    backgroundImage.src = bgImgUrl; // Replace with your background image path
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });
    selfieSegmentation.setOptions({
      modelSelection: 1, // 0 for general-purpose, 1 for close-up
    });

    selfieSegmentation.onResults((results) => {
      if (!canvasRef.current) return;
      // const canvasCtx = canvasRef.current.getContext('2d');
      const canvasCtx = canvasRef.current.getContext('2d') || canvasRef.current.getContext('webgl');
      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;

      // Step 1: Draw the blurred background
      canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear previous frames
      canvasCtx.filter = 'blur(-10px)'; // Apply a blur filter
      canvasCtx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);

      // Step 2: Apply the segmentation mask to cut out the person from the blurred background
      canvasCtx.globalCompositeOperation = 'destination-out';
      canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasWidth, canvasHeight);

      // Step 3: Draw the unblurred person (foreground)
      canvasCtx.globalCompositeOperation = 'destination-over';
      canvasCtx.filter = 'none'; // Remove blur for the person
      canvasCtx.drawImage(results.image, 0, 0, canvasWidth, canvasHeight);

      // Step 4: Reset composite operation to default
      canvasCtx.globalCompositeOperation = 'source-over';
    });

    // if (videoRef.current) {
    if (typeof videoRef.current !== 'undefined' && videoRef.current !== null && videoRef.current) {
      const camera = new cam.Camera(videoRef.current, {
        onFrame: async () => {
          await selfieSegmentation.send({ image: videoRef.current });
          // setLoading(false);
        },
      });
      camera.start();
    }
  }, [bgImgUrl]);

  return (
    <>
      {/* {loadingBG && (
          <div className="text-center pt-5 pb-5" style={{ background: '#2f2ee9' }}>
              <img src="/loader.webp" alt="" style={{ maxWidth: '100px' }} />
              <br />
                    <span className='text-center'>
                        Loading...
                    </span>
          </div>
      )} */}
      {/* <div style={{ opacity: loadingBG ? 0 : 1 }}></div> */}
      <div>
        <video
            ref={videoRef}
            style={{
                display: 'none',
                width: `${windowWidth}px`,
            }}
            autoPlay
            muted
            className="videoCreateARVR"
        />
        <canvas ref={canvasRef} className="canvasCreateARVR" />
      </div>
    </>
  );
};


ArVrBgEffectsSection.propTypes = {
  bgImgUrl: PropTypes.any.isRequired,
  windowWidth: PropTypes.any.isRequired,
  setLoading: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
}

export default ArVrBgEffectsSection;
