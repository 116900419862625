import React, { useContext, useEffect } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
// import Header from './Header/Header';
import LeftBlock from './LeftBlock/LeftBlock';
import { ApiContext } from '../UseContext/ApiCall';
import { ApiVersionCode, BASEAPIURL } from '../../../config';
import axios from 'axios';

const Home1 = ({className}) => {
  const { swipeHeader } = useContext(ApiContext);
  // const nav2 = useNavigate();

  useEffect(() => {
    localStorage.removeItem('SoftCornerEntry');
    const finduserlogin = async () => {
      try {
        const response = await axios.post(
          `${BASEAPIURL}/user/finduserlogin`,
          {
            // Data to send in the POST request body
            action: 'update',
            // Add more key-value pairs as needed
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              device: localStorage.getItem('deviceDetail'),
              devicetype: "browser",
              versioncode: ApiVersionCode,
            },
          }
        );
        if (response.data.status !== 1) {
          nav(`/login?actionToken=${btoa(window.location.href)}&actiomFrom=${window.location.href}`)
        }
      } catch (error) {
        console.clear();
        console.error(error);
        nav(`/login?actionToken=${btoa(window.location.href)}&actiomFrom=${window.location.href}`)
      }
    };
     // Fetch data initially
     finduserlogin();
     // Set up interval to fetch data every 5 seconds
     const intervalId = setInterval(finduserlogin, 60000);
     // Clear interval when component unmounts
     return () => clearInterval(intervalId);
  }, []);

  const nav = useNavigate()

  const token = localStorage.getItem("token")
  
  useEffect(() => {
    if (token === 0 || token === undefined || token === null) {
     nav(`/login?actionToken=${btoa(window.location.href)}&actiomFrom=${window.location.href}`)
    }
  }, [])


  document.title = "E-Milo";

  return (
    <>
      <section style={{display: `${!swipeHeader ? "block" : "none"}`}} className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters mx-auto">
            <div className="col-lg-3 px-0 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>
            <div className="col-lg-9 px-0" style={{ background: "#F8F8F8", height: '100vh' }}>
             {token && <Outlet /> }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home1;