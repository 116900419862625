import React, { useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall';
import { Link } from "react-router-dom";
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { AwsFileUrl, BASEURL, BASE_URL_STATIC } from '../../../../../config';


const ProfileFollowingPopup = ({ showFollowingPopup, setShowFollowingPopup }) => {

  const [followingSearchUsersList, setFollowingSearchUsersList] = useState([]);

  const { loggedInUserId, followingList } = useContext(ApiContext)

  const handleFollowingSearch = (e) => {
    setFollowingSearchUsersList(followingList.filter((name) => name.fname.includes(e.target.value)));
  };

  console.log('followingList : ', followingList);

  return (
    <>
      <section>
        <Modal show={showFollowingPopup} onHide={() => setShowFollowingPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <h6 className='text-center mb-3 fw-bold'>Following</h6>

            {/* Search */}
            <div className='pfp-search'>
              <form className='pfp-search-form'>
                <img className='pfp-search-img' src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />

                <label htmlFor="followerSearch">
                  <input type="text" onChange={handleFollowingSearch} name='followerSearch' id='followerSearch' placeholder='Search' />
                </label>
              </form>

              <span className='pfp-search-filter ms-2 ms-md-0'>Filter</span>
            </div>

            {
              followingSearchUsersList.length !== 0
              ?
              <div>
                {followingSearchUsersList.map((following, index) => (
                  <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>
                    {
                      following.imageUrl 
                      ? 
                      <img className='pfp-profile rounded-circle' src={following.imageUrl} alt={following.fname} onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                      :   
                      <img className='pfp-profile rounded-circle' src={`${BASE_URL_STATIC}/dummy.png`} alt={following.fname} />
                    }

                    <Link to={`${BASEURL}/profile/${following.userId}`} className='ms-2 ms-sm-3 me-auto text-dark' onClick={() => setShowFollowingPopup(false)} >
                      <h6 className='pfp-name fw-bold'>{following.fname}</h6>
                      <small className='text-secondary'>{following.fname}</small>
                    </Link>

                    {
                      following.userId !== loggedInUserId 
                      &&
                      <FollowUnfollowButton otherUserId={following.userId} />
                    }
                  </div>
                ))}
              </div>
              : 
              <div>
                {
                  followingList
                  &&
                  followingList.map((following, index) => (
                    <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>
                        {
                          following.profile 
                          ? 
                          <img className='pfp-profile rounded-circle object-fit-contain border' src={AwsFileUrl+following.profile} alt='profile' onError={(e) => e.target.src=`${BASE_URL_STATIC}/dummy.png`} />
                          : 
                          <img className='pfp-profile rounded-circle object-fit-contain border' src={`${BASE_URL_STATIC}/dummy.png`} alt='dummy' />
                        }

                        <Link to={`${BASEURL}/profile/${following.userId}`} className='ms-2 ms-sm-3 me-auto text-dark' onClick={() => setShowFollowingPopup(false)} >
                          <h6 className='pfp-name fw-bold mb-0'>{following.fname} {following.mname && following.mname} {following.lname && following.lname}</h6>
                          <small className='text-secondary'>{following.userName ?  following.userName : 'username06@'}</small>
                        </Link>

                        {
                          following.userId !== loggedInUserId
                          &&
                          <FollowUnfollowButton otherUserId={following.userId} />
                        }
                    </div>
                  ))
                }
              </div>
            }
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ProfileFollowingPopup;
