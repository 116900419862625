import React, { useState } from 'react';
import { BASE_URL_STATIC } from '../../../../config';
import ViewPopup from './ViewPopup';

const ViewPostButton = ({ viewCount  , postId}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ViewPopup open={open} close={() => setOpen(false)} postId={postId} />
      {
        (viewCount === 0)
          ?
          <div className='d-flex' style={{ alignItems: 'center' }}>
            <img className='viewPostBtn' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
            <span>{viewCount}</span>
          </div>
          :
          <div className='d-flex' style={{ alignItems: 'center' }}>
            <img className='viewPostBtn' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
            <span onClick={() => setOpen(true)}>{viewCount}</span>
          </div>
      }
    </>
  );
};

export default ViewPostButton;
