import React, { useEffect, useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import axios from 'axios';
import { ApiContext } from '../../../UseContext/ApiCall';
import LikeMemeButton from '../../../Button/LikeButton/LikeMemeButton';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import ProfileMemeThreeDotPopup from './ThreeDot/ProfileMemeThreeDotPopup';
import PostTime from '../../../Home/CenterBlock/CenterBlockPosts/PostTime';
import ProfileMemeCommentThreeDotPopup from './CommentThreeDot/ProfileMemeCommentThreeDotPopup';
import FavouriteMemeButton from '../../../Button/FavouriteButton/FavouriteMemeButton';
import AddComments from '../../../Button/AddComments';


const ProfileMemesPopup = ({ showProfileMemesPopup, setShowProfileMemesPopup, profileMemePopupData, setProfileMemePopupData, memeId,}) => {

  const [showProfileMemeThreeDotPopuop, setshowProfileMemeThreeDotPopuop] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showProfileMemeCommentThreeDotPopup, setShowProfileMemeCommentThreeDotPopup] = useState(false);

  //------------------- comment states-----------------
  const [clickedreply, setClickedReply] = useState(false)
  const [viewReply, setViewReply] = useState(false)
  const [comment, setComment] = useState();
  const [comments, setComments] = useState([]);
  const [parentSpinner, setParentSpinner] = useState(false)
  

  const { config , loggedInUserId } = useContext(ApiContext);

  const getShowCopyLinkUser = (userId) => {
    axios.post('https://emilonode.sjain.io/user/getoneuser', { userId })
    .then(res => {
    })
    .catch(err => {
      return null;
    });
  };

  useEffect(() => {
    getShowCopyLinkUser(loggedInUserId);
  }, []);

  useEffect(() => {
    if(profileMemePopupData) {
      setClickedReply(false)
      setViewReply(false)
      getMemeComment();
    };
  }, [profileMemePopupData]);

  // To get meme comment
  const getMemeComment = () => {
    axios.post('https://emilonode.sjain.io/post/getpostcomment', { type:"meme" ,  memeId }, config)
      .then(res => {
        setComments(res.data.commentList);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (profileMemePopupData) {
      getMemeComment();
    };

    setClickedReply(false)
  }, [profileMemePopupData]);

  // To create meme comment
  // const createMemeComment = (memeId, comment, config) => {
  //   axios.post('https://emilonode.sjain.io/post/creatememecomment', { memeId: memeId, comment: comment }, config)
  //     .then(res => {
  //       setCreateComment('');
  //       getMemeComment(memeId, config);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  const settings = {
    dots: true,
    className: "profilePostsPopup-post-slider",
    infinite: false,
    // centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
    afterChange: (index) => setActiveIndex(index),
  };

  return (
    <>
      {/* Profile Meme Three Dot Popup */}
      <ProfileMemeThreeDotPopup showProfileMemeThreeDotPopuop={showProfileMemeThreeDotPopuop} setshowProfileMemeThreeDotPopuop={setshowProfileMemeThreeDotPopuop} setShowProfileMemesPopup={setShowProfileMemesPopup} profileMemePopupData={profileMemePopupData} setProfileMemePopupData={setProfileMemePopupData} />

      {/* Profile Meme Comment Three Dot Popup */}
      <ProfileMemeCommentThreeDotPopup showProfileMemeCommentThreeDotPopup={showProfileMemeCommentThreeDotPopup} setShowProfileMemeCommentThreeDotPopup={setShowProfileMemeCommentThreeDotPopup} comment={comment} getMemeComment={getMemeComment} />

      <Modal show={showProfileMemesPopup} onHide={() => setShowProfileMemesPopup(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            profileMemePopupData
            &&
            <section className="profilePostsPopup">
              {/* Memems */}
              <section className="profilePostsPopup-post">
                <Slider {...settings} >
                  {
                    profileMemePopupData && profileMemePopupData.length > 0 
                    &&
                    profileMemePopupData.details.map((detail, index) => (
                      detail.imageId
                      ?
                      <div className="profilePostsPopup-post-pav">
                        <img className="profilePostsPopup-post-image" src={detail.imageUrl} alt={detail.imageId} onError={(e) => e.target.src=`${BASE_URL_STATIC}/cp-4.png`} height='100%' width='100%' />
                      </div>
                      :
                      <div className="profilePostsPopup-post-pav">
                       
                        <ReactPlayer
                            url={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${detail.videoId}`} className="profilePostsPopup-post-video"
                            playing={index === activeIndex}
                            controls
                          />
                      </div>
                    ))
                  }
                </Slider>
              </section>

              {/* Details */}
              <section className="profilePostsPopup-details">
                {/* User */}
                <div className='profilePostsPopup-details-user'>
                  <img className='profilePostsPopup-details-profile' src={profileMemePopupData.profileImg} alt={profileMemePopupData.memeId} height={50} width={50} />

                  <div className='profilePostsPopup-details-header'>
                    <div className='profilePostsPopup-details-head'>
                      <span className='profilePostsPopup-details-name'>{profileMemePopupData.fname}</span>
                    </div>

                    <span className='profilePostsPopup-details-location'>{profileMemePopupData.title}</span>

                    <span className='profilePostsPopup-details-time'>
                      <PostTime date={profileMemePopupData.createdTime} />
                    </span>
                  </div>

                  <img className='profilePostsPopup-details-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" onClick={() => setshowProfileMemeThreeDotPopuop(true)} />
                </div>

                <hr className="profilePostsPopup-details-hr" />

                {/* Comments */}
                <AddComments postId={memeId} setComment={setComment} commentType="meme"  setShowProfilePostCommentThreeDotPopup={setShowProfileMemeCommentThreeDotPopup} clickedreply = {clickedreply} setClickedReply = {setClickedReply} viewReply={viewReply}  postsPopupData={profileMemePopupData} comments={comments} setComments={setComments} parentSpinner={parentSpinner} setParentSpinner={setParentSpinner}/>

                <hr className="profilePostsPopup-details-hr" />

                {/* Area */}
                <div className='profilePostsPopup-details-area'>
                  <div className='profilePostsPopup-details-area-div'>
                    {
                      (profileMemePopupData.viewCount === 0)
                        ?
                        <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="like" height={20} width={30} />
                        :
                        <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="like" height={20} width={30} />
                    }
                    <span className='profilePostsPopup-details-area-count'>{profileMemePopupData.viewCount}</span>
                  </div>
                  

                  <div className='profilePostsPopup-details-area-div'>
                    <LikeMemeButton memeId={profileMemePopupData.memeId} />
                  </div>

                  <div className='profilePostsPopup-details-area-div'>
                    <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="comment" />
                    <span className='profilePostsPopup-details-area-count'>{profileMemePopupData.commentCount}</span>
                  </div>

                  <div className='profilePostsPopup-details-area-div'>
                    <img className='profilePostsPopup-details-area-icon' src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="share" />
                    <span className='profilePostsPopup-details-area-count'>{profileMemePopupData.shareCount}</span>
                  </div>

                  <div className='profilePostsPopup-details-area-favourite'>
                    <FavouriteMemeButton memeId={profileMemePopupData.memeId} />
                  </div>

                  <img className='profilePostsPopup-details-area-download' src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="star" />
                </div>

                {/* Description */}
                <div className='profilePostsPopup-details-description'>{profileMemePopupData.description}</div>
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>

      {/* Avinash ko show krna h then delete krna hai isme prev and next wala lgaya hai */}
      {/* <Modal show={showProfileMemesPopup} size='xl' onHide={() => setShowProfileMemesPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='popupbody'>
          {
            Array.isArray(singleMeme)
            &&
            singleMeme.map((meme) => (
              <section key={profileMemePopupData.memeId} >

                <section className="meme-flex" style={{ border: '1px solid black', width: '1077px', padding: "20px", margin: '45px auto 0px' }}>
                  {
                    (meme.details && meme.details.length === 1)
                      ?
                      <div className='memeDetails' >
                        {meme.details && meme.details[0].imageId && <img className='memeImg' src={meme.details[0].imageUrl} width='60%' alt=''/>}
                        {
                          meme.details[0].videoId &&
                          <section className='video-wrap'>
                            <video
                              controls
                              autoPlay
                              loop
                              style={{ background: "black" }}
                            >
                              <source
                                src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${meme.details[0].videoId}`}
                              />
                            </video>
                          </section>
                        }
                      </div>
                      :
                      <Carousel className='memeCarousel' slide={false}>
                        {meme.details && meme.details.map((detail, index) => (
                          <Carousel.Item>
                            <div className='memeDetails' key={index}>
                              {detail.imageId && <img className='memeImg' src={detail.imageUrl} alt={detail.imageId} width='60%' />}
                              {
                                detail.videoId
                                &&
                                <section className='video-wrap'>
                                  <video controls autoPlay loop style={{ background: "black" }} >
                                    <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${detail.videoId}`} />
                                  </video>
                                </section>
                              }
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                  }
                  <section>
                    <div className="d-flex" style={{ width: "455px", justifyContent: "space-between" }} >
                      <div className="d-flex" style={{ marginTop: "10px" }}>
                        <div style={{ margin: "0px 10px", }} >
                          <ProfileImage userId={userId} height='45' width='45' borderRadius='50%' />
                        </div>
                        <div>
                          <Link to={`${BASEURL}/profile/${userId}`} style={{ color: 'black' }}>{showCopyLinkUserName}</Link>
                        </div>
                      </div>

                      <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" style={{ cursor: "pointer", marginRight: "10px" }} />
                    </div>

                    <hr
                      style={{
                        height: "0.1px",
                        width: "100%",
                        backgroundColor: "gray",
                        margin: "5px 0px",
                      }}
                    />

                    <div style={{ height: "400px", width: "457px" }}>
                      <h3 style={{ textAlign: "center" }}>Description</h3>
                      <span>{meme.memedescription}</span>
                    </div>

                    <hr
                      style={{
                        height: "0.1px",
                        width: "100%",
                        backgroundColor: "gray",
                        margin: "5px 0px",
                      }}
                    />

                    <div
                      className="d-flex"
                      style={{ width: "455px", marginTop: "10px" }}
                    >
                      <div className="d-flex">
                        <img
                          src={`${BASE_URL_STATIC}/feed-icon1.png`}
                          alt="views"
                          height="70%"
                          width="100%"
                          style={{ margin: "5px 5px 0px 5px" }}
                        />
                        <p style={{ margin: "2px 25px 0px 0px" }}>{meme.memeView}</p>
                      </div>

                      <LikeMemeButton profileMemePopupData.memeId={meme.profileMemePopupData.memeId} />

                      <div className="d-flex" style={{ cursor: "pointer" }}>
                        <img
                          src={`${BASE_URL_STATIC}/feed-icon3.png`}
                          alt="comment"
                          style={{ marginRight: "5px" }}
                        />
                        <p style={{ marginRight: "25px" }}>1k</p>
                      </div>

                      <CopyToClipboard text={`${BASEURL}/memeSharePage/${profileMemePopupData.memeId}`} onCopy={() => sharememeCount(profileMemePopupData.memeId)}  >

                        <div className="d-flex">
                          <img
                            src={`${BASE_URL_STATIC}/feed-icon4.png`}
                            alt="share"
                            style={{ marginRight: "5px" }}
                          />
                          <p style={{ marginRight: "127px" }}>

                            {meme.shareCount}
                          </p>
                        </div>
                      </CopyToClipboard>

                      <div style={{ marginRight: "20px" }}>
                        {
                          isMemeFavourite
                            ?
                            <img src={`${BASE_URL_STATIC}/feed-icon5.png`} alt="views" onClick={() => createFavouriteMeme(profileMemePopupData.memeId, config)} style={{ cursor: 'pointer' }} />
                            :
                            <img src={`${BASE_URL_STATIC}/favourite2.png`} alt="views" onClick={() => createFavouriteMeme(profileMemePopupData.memeId, config)} height={25} width={25} style={{ cursor: 'pointer' }} />
                        }
                      </div>

                      <div >
                        <img src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="views" />
                      </div>
                    </div>

                    <div
                      style={{
                        height: "140px",
                        width: "406px",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >
                    </div>

                    <div
                      style={{
                        height: "100px",
                        width: "406px",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >
                    
                    </div>
                  </section>
                </section>
              </section>
            ))
          }
        </Modal.Body>

        <div className='popupButtons'>
          <button onClick={onPrev} className='popBtnPrev'>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button onClick={onNext} className='popBtnNext'>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default ProfileMemesPopup;
