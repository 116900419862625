import React from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../config';

const ProposeOptionMemoriesEdit = ({ showProposeOptionMemoriesEditPopup, setShowProposeOptionMemoriesEditPopup, setShowProposeOptionMemoriesPopup }) => {

  const handleNext = () => {
    setShowProposeOptionMemoriesEditPopup(false);
    setShowProposeOptionMemoriesPopup(true);
  };

  return (
    <>
      <Modal show={showProposeOptionMemoriesEditPopup} onHide={() => setShowProposeOptionMemoriesEditPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>

        <Modal.Body>
          <section className='proposeOptionMemoriesEdit'>
            {/* Div 1 */}
            <div className='proposeOptionMemoriesEdit-div-1'>
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
              <img src={`${BASE_URL_STATIC}/explore-icon1.png`} alt="icon" height={35} width={35} />
            </div>
            
            {/* Div 2 */}
            <div className='proposeOptionMemoriesEdit-div-2'>
              <img src={`${BASE_URL_STATIC}/cp-3.png`} alt="cp-3" height={350} width={375} />
            </div>

            {/* Div 3 */}
            <div className='proposeOptionMemoriesEdit-div-3'>
              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>

              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>

              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>

              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>

              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>

              <div className='proposeOptionMemoriesEdit-div-3-all'>
                <img src={`${BASE_URL_STATIC}/background.png`} alt="background" height={15} width={15} />  
                <span>Background</span>
              </div>
            </div>

            {/* Div 4 */}
            <div className='proposeOptionMemoriesEdit-div-4'>
              <button type="button" onClick={handleNext}>Next</button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProposeOptionMemoriesEdit
