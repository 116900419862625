import React, { useContext, useEffect, useRef } from 'react';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { businessContext } from '../UseContext/BusinessContext';
import EditPagePopup from '../Popup/EditPage/EditPagePopup';
import CreateNewPostPopup from '../../Emilo/Popup/CreateNewPost/CreateNewPostPopup';
import BusinessBioPopup from '../Popup/Home/BusinessBioPopup';


const BusinessHome2 = () => { 

  const location = useLocation();
  const navigate = useNavigate();

  const { 
    paramsPageId, 
    getPageDetails, pageDetails, 
    uploadPageProfile,
    setShowBioPopup, setShowEditPagePopup,
    showCreatePostPopup, setShowCreatePostPopup,
    pageBanners, createPageBanner, getPageBanner, deletePageBanner,
    checkIsItMyPage, isItMyPage,
  } = useContext(businessContext);


  const bannerSetting = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const options = [
    { name: 'Posts', navigate: `/business/${paramsPageId}` },
    { name: 'About', navigate: `/business/about/${paramsPageId}` },
    { name: 'Product & Services', navigate: `/business/product-services/${paramsPageId}` },
    { name: 'Tagged', navigate: `/business/tagged/${paramsPageId}` },
    { name: 'Reviews', navigate: `/business/reviews/${paramsPageId}` },
    { name: 'Listings', navigate: `/business/listings/${paramsPageId}` },
  ];


  // ----- Profile ----- 
  const profileInputRef = useRef(null);

  const handleProfileClick = () => {
    profileInputRef.current.click(); 
  };

  const handleProfileChange = (e) => {
    const profile = e.target.files[0];

    if (profile) {  
      uploadPageProfile(profile);
    };
  };



  // ----- Banner ----- 
  const bannerInputRef = useRef(null);

  const handleBannerClick = () => {
    if (bannerInputRef.current) {
      bannerInputRef.current.click();
    };
  };

  const handleBannerChange = (e) => {
    const selectedFile = e.target.files;

    if (selectedFile) {
      createPageBanner(selectedFile);
    };
  };

  useEffect(() => {
    getPageBanner(paramsPageId);
    getPageDetails(paramsPageId);
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]);

  return (
    <>
      {/* Popup */}
      <>
        {/* Edit Page Popup */}
        <EditPagePopup />

        {/* Create post popup */}
        <CreateNewPostPopup showCreatePostPopup={showCreatePostPopup} setShowCreatePostPopup={setShowCreatePostPopup} />

        {/* Bio Popup */}
        <BusinessBioPopup />
      </>

      <section className='business-rb h-100 overflow-y-scroll scrollbar-hidden pt-3 px-2 px-sm-3 px-md-4 px-xl-5' style={{ backgroundColor: '#edf1f4' }}>
        {/* Search Bar, Notification, Chat and Profile */}
        <div className='bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3'>
          {/* Search Bar */}
          <div className='d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className='me-2 me-sm-3' alt="search" />

            <input type="search" className='w-100 bg-transparent border-0' placeholder='Search...' />
          </div>

          {/* Notification */}
          <div className='mx-3 mx-sm-4 mx-md-5'>
            <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
          </div>

          {/* Chat */}
          <div>
            <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
          </div>

          {/* Profile */}
          <div className='ms-3 ms-sm-4 ms-md-5'>
            <img src={pageDetails && AwsFileUrl+pageDetails.imageId} className='rounded-circle' style={{ height: '33px', width: '33px' }} alt="profile" />
          </div>
        </div>

        {/* Phone, Mail and Link */}
        <div className='my-4 d-flex flex-wrap justify-content-end gap-3 gap-md-4'>
          {/* Phone */}
          <>
            {
              pageDetails && pageDetails.mobile
              &&
              <div className='bg-white rounded-3 px-3 shadow-sm d-inline-block'>
                <img src={`/images//business/icons/phone-blue.svg`} style={{ width: '30px' }} alt="phone" />
                <span className='ms-2 fw-semibold'>{pageDetails.mobile}</span>
              </div>
            }
          </>

          {/* Email */}
          <>
            {
              pageDetails && pageDetails.email
              &&
              <div className='bg-white rounded-3 px-3 shadow-sm d-inline-block'>
                <img src={`/images//business/icons/mail-blue.svg`} style={{ width: '27px' }} alt="mail" />
                <span className='ms-2 fw-semibold'>{pageDetails.email}</span>
              </div>
            }
          </>

          {/* Link */}
          <>
            {
              pageDetails && pageDetails.websiteLink
              &&
              <div className='bg-white rounded-3 px-3 shadow-sm d-inline-block'>
                <img src={`/images//business/icons/website-black.svg`} style={{ width: '27px' }} alt="link" />
                <span className='ms-2 fw-semibold text-primary'>{pageDetails.websiteLink}</span>
              </div>
            }
          </>
        </div>

        {/* Profile and Banner */}
        <div className='position-relative'>
          {
            pageBanners.length > 0
            ?
            <Slider {...bannerSetting} className='business-banner-slider shadow-sm rounded-3'>
              {
                pageBanners.map((banner, index) => (
                  <div className='position-relative' key={index}>
                    <img src={AwsFileUrl+banner.banner} className='w-100 object-fit-cover rounded-3' style={{ height: '300px' }} alt="banner" />

                    {
                      isItMyPage
                      &&
                      <div className='position-absolute top-0 end-0 mt-2 me-2 d-flex gap-2'>
                        {/* Banner > Add */}
                        <div className='bg-white p-1 rounded-circle cursor-pointer'>
                          <img src={`/images/business/icons/add-circle.svg`} style={{ width: '20px' }} onClick={handleBannerClick} alt="add-circle" />

                          <input type='file' ref={bannerInputRef} style={{ display: 'none' }} onChange={handleBannerChange} multiple />
                        </div>

                        {/* Banner > Delete */}
                        <div className='bg-white p-1 rounded-circle cursor-pointer' onClick={() => deletePageBanner(banner)}>
                          <img src={`/images/business/icons/delete-red.svg`} style={{ width: '20px' }} alt="delete-red" />
                        </div>
                      </div>
                    }

                  </div>
                ))
              }
            </Slider>
            :
            <div className='position-relative bg-primary-subtle rounded-3 shadow-sm' style={{ height: '300px' }}>
              <div className='position-absolute top-0 end-0 mt-2 me-2 d-flex gap-2'>
                {/* Banner > Add */}
                {
                  isItMyPage
                  &&
                  <div className='bg-white p-1 pt-0 rounded-circle cursor-pointer'>
                    <img src={`/images/business/icons/add-circle.svg`} style={{ width: '20px' }} onClick={handleBannerClick} alt="add-circle" />

                    <input type='file' ref={bannerInputRef} style={{ display: 'none' }} onChange={handleBannerChange} multiple />
                  </div>
                }
              </div>
            </div>
          }

          {/* Profile */}
          <div className='business-banner-profile position-absolute'>
            <div className='position-relative'>
              <img src={pageDetails && AwsFileUrl+pageDetails.imageId} className='business-banner-profile-img rounded-circle bg-white object-fit-contain' alt="profile" />

              {
                isItMyPage
                &&
                <div className='bg-secondary position-absolute top-100 start-50 translate-middle px-1 pb-1 rounded-circle cursor-pointer' onClick={handleProfileClick}>
                  <img src={`/images/business/icons/camera-white.svg`}  style={{ width: '20px' }} alt="camera" />

                  <input type="file" ref={profileInputRef} style={{ display: 'none' }}  onChange={handleProfileChange}  accept="image/*"  />
                </div>
              }
            </div>
          </div>
        </div>

        {/* Details */}
        <div className='my-4 rounded-3 shadow-sm p-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Div 1 */}
          <div className='d-flex flex-wrap align-items-start gap-3 mb-3'>
            <div>
              <h3 className='m-0 fw-semibold'>{pageDetails && pageDetails.fname}</h3>
              <span>{pageDetails && pageDetails.pageCategory} ({pageDetails && pageDetails.pageSubCategory})</span>
            </div>

            <button type="button" className='btn btn-sm text-secondary rounded-pill shadow-sm border px-3 me-auto'>Edit Profile</button>

            {
              isItMyPage
              ?
              <>
                {/* Location  */}
                <button type="button" className='btn border border-primary'>
                  <img src={`/images/business/icons/location-blue.svg`} style={{ width: '25px' }} alt="location" />
                  <span className='text-primary ms-2'>Location</span>
                </button>

                {/* Share  */}
                <button type="button" className='btn bg-primary'>
                  <img src={`/images/business/icons/share-white.svg`} style={{ width: '25px' }} alt="share" />
                  <span className='text-white ms-2'>Share</span>
                </button>

                {/* Edit Page  */}
                <button type="button" className='btn shadow-sm' onClick={() => setShowEditPagePopup(true)}>
                  <img src={`/images/business/icons/phone-blue.svg`} style={{ width: '25px' }} alt="edit page" />
                  <span className='text-primary ms-2'>Edit Page</span>
                </button>
              </>
              :
              <>
                {/* Follow  */}
                <button type="button" className='btn border border-primary'>
                  <img src={`/images/business/icons/follow-red.svg`} style={{ width: '25px' }} alt="location" />
                  <span className='text-primary ms-2'>Follow</span>
                </button>

                {/* Message  */}
                <button type="button" className='btn border border-primary'>
                  <img src={`/images/business/icons/message-blue-2.svg`} style={{ width: '25px' }} alt="location" />
                  <span className='text-primary ms-2'>Message</span>
                </button>

                {/* Location  */}
                <button type="button" className='btn border border-primary'>
                  <img src={`/images/business/icons/location-blue.svg`} style={{ width: '25px' }} alt="location" />
                  <span className='text-primary ms-2'>Location</span>
                </button>

                {/* Share  */}
                <button type="button" className='btn bg-primary'>
                  <img src={`/images/business/icons/share-white.svg`} style={{ width: '25px' }} alt="share" />
                  <span className='text-white ms-2'>Share</span>
                </button>
              </>
            }


            {/* Three Dot */}
            <button type="button" className='btn shadow-sm'>
              <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
            </button>
          </div>

          {/* Div 2 */}
          <div className='d-flex flex-wrap align-items-start gap-3'>
            {/* Followes */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>40M</h5>
              <span className='text-secondary'>Followers</span>
            </div>

            {/* Likes */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>250k</h5>
              <span className='text-secondary'>Likes</span>
            </div>

            {/* Posts */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>125</h5>
              <span className='text-secondary'>Posts</span>
            </div>

            {/* Bio */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm flex-grow-1'>
              <div className='mb-2 d-flex align-items-center'>
                <h5 className='me-3 d-inline mb-0'>Bio</h5>

                {
                  isItMyPage
                  &&
                  <img src={`/images/business/icons/edit-blue.svg`} className='cursor-pointer' style={{ height: '20px', width: '20px' }} onClick={() => setShowBioPopup(true)} alt="edit" />
                }
              </div>

              <>
                {
                  pageDetails
                  ?
                  <span className='text-primary'>{pageDetails.bio}</span>
                  :
                  <span className='text-secondary'>Write your bio here...</span>
                }
              </>
            </div>
          </div>
        </div>

        {/* Create Post and Search */}
        <div className='p-3 d-flex flex-wrap gap-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
          <button type='button' className='btn btn-primary rounded-pill' onClick={() => setShowCreatePostPopup(true)}>
            <img src={`/images/business/icons/add-white.svg`} className='me-2' style={{ height: '20px', width: '20px' }} alt="add" />

            <span>Create Post</span>
          </button>

          <div className='d-flex flex-grow-1 align-items-center rounded-pill ps-3 ps-sm-4 py-2 bg-white shadow-sm'>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />

            <input type="search" className='w-100 bg-transparent border-0 ms-2 ms-sm-3' placeholder='Search Post, Product & Services...' />
          </div>
        </div>


        {/* Options */}
        <div className='d-flex my-4 overflow-x-scroll scrollbar-hidden pb-1'>
          {
            options.map((option, index) => (
              <div 
                className={`${location.pathname === option.navigate ? 'bg-primary text-white' : 'bg-white'} shadow-sm px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer`} key={index} 
                onClick={() => navigate(option.navigate)}
              >
                {option.name}
              </div>
            ))
          }
        </div>

        <Outlet />
      </section>
    </>
  )
}

export default BusinessHome2;