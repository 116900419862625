import React from 'react';
import ProfileUser from '../../Profile/ProfileUser/ProfileUser';
import Navbar from '../Navbar/Navbar';

const Liked = () => {

  // const [likedReels, setLikedReels] = useState([]);
  // const [likedBestClips, setLikedBestClips] = useState([]);
  // const [likedMemes, setLikedMemes] = useState([]);

  // const { config, loggedInUserId } = useContext(ApiContext);

  // To get liked posts
  // const GetLikedPosts = async (config) => {
  //   await axios.post('https://emilonode.sjain.io/post/getpostlikebyuser', {}, config)
  //   .then(res => {
  //     // console.log(res.data.postLike);
  //     setLikedPosts(res.data.postLike);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // };

  // useEffect(() => {
  //   GetLikedPosts(config);
  // }, []);

  // To get liked reels
  // const GetLikedReels = async (config) => {
  //   await axios.post('https://emilonode.sjain.io/post/getreellikebyuser', {}, config)
  //   .then(res => {
  //     // console.log(res.data.reelLikeCount);
  //     setLikedReels(res.data.reelLikeCount);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // };

  // useEffect(() => {
  //   GetLikedReels(config);
  // }, []);

  // To get liked best clips
  // const GetLikedBestClips = async (config) => {
  //   await axios.post('https://emilonode.sjain.io/post/getbestcliplikebyuser', {}, config)
  //   .then(res => {
  //     // console.log(res.data.listBestclip);
  //     setLikedBestClips(res.data.listBestclip);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // };

  // useEffect(() => {
  //   GetLikedBestClips(config);
  // }, []);

  // To get liked memes
  // const GetLikedMemes = async (config) => {
  //   await axios.post('https://emilonode.sjain.io/post/getmemelikebyuser', {}, config)
  //   .then(res => {
  //     // console.log(res.data.listMeme);
  //     setLikedMemes(res.data.listMeme);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // };

  // useEffect(() => {
  //   GetLikedMemes(config);
  // }, []);

  // It will mix all data in one array
  // useEffect(() => {
  //   const mixLiked = [...likedPosts, ...likedReels, ...likedBestClips, ...likedMemes];
  
  //   mixLiked.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));

  //   setLiked(mixLiked);
  // }, [likedPosts, likedReels, likedBestClips, likedMemes]);

  return (
    <>
      <section className='liked'>
        <ProfileUser />
        <Navbar />

        {/* Liked */}
        <section className='liked-show'>
          {/* To show recent liked post */}
          {/* <Link to={`${BASEURL}/${loggedInUserId}/liked-posts`} className='liked-show-post liked-show-div'>
            <h3 className='liked-show-post-head liked-show-head'>Photos/Videos</h3>
            {
              likedPosts
              ?
              likedPosts.map((likedPost, index) => (
                ((likedPosts.length - 2) === index)
                ?
                // console.log(likedPost.details)
                likedPost.details.map(detail => (
                  // console.log(detail.imageUrl)
                  <img src={detail.imageUrl} alt={detail.imageId} height={215} width={200} />
                ))
                :
                ''
              ))
              :
              ''
            }
          </Link> */}

          {/* To show recent liked reels */}
          {/* <Link to={`${BASEURL}/${loggedInUserId}/liked-reels`} className='liked-show-reel liked-show-div'>
            <h3 className='liked-show-reels-head liked-show-head'>Reels</h3>
            {
              likedReels.map((likedReel, index) => (
                ((likedReels.length - 1) === index)
                ?
                // console.log(likedReel)
                <img src={likedReel.tumbnailUrl} alt={likedReel.tumbnailId} height={215} width={200} />
                :
                ''
              ))
            }
          </Link> */}

          {/* To show recent liked best clip */}
          {/* <Link to={`${BASEURL}/${loggedInUserId}/liked-bestClips`} className='liked-show-bestClip liked-show-div'>
            <h3 className='liked-show-bestClip-head liked-show-head'>BestClips</h3>
            {
              likedBestClips.map((likedBestClip, index) => (
                ((likedBestClips.length - 1) === index)
                ?
                // console.log(likedBestClip)
                <img src={likedBestClip.tumbnailUrl} alt={likedBestClip.tumbnailId} height={215} width={200} />
                :
                ''
              ))
            }
          </Link> */}

          {/* To show recent liked meme */}
          {/* <Link to={`${BASEURL}/${loggedInUserId}/liked-memes`} className='liked-show-meme liked-show-div'>
            <h3 className='liked-show-meme-head liked-show-head'>Memes</h3>
            {
              likedMemes.map((likedMeme, index) => (
                ((likedMemes.length - 1) === index)
                ?
                // console.log(likedMeme.details)
                likedMeme.details.map(detail => (
                  // console.log(detail.imageUrl)
                  <img src={detail.imageUrl} alt={detail.imageId} height={215} width={200} />
                ))
                :
                ''
              ))
            }
          </Link> */}
        </section>
      </section>
    </>
  );
};

export default Liked;
