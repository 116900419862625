import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { WhatsappShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { IoIosCloseCircle } from "react-icons/io";
import download from 'downloadjs';
import axios from 'axios';


const ProfileUserTdSharePopup = ({ showPopup, setShowPopup, shareUserId, shareUserName }) => {

  const [inputValue, setInputValue] = useState();
  const [searchedUsersList, setSearchedUsersList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);

  // To search users
  const handleSearch = (e) => {
    setInputValue(e.target.value);

    if(e.target.value.length > 0){
      axios.post(`https://emilonode.sjain.io/user/getalluser?page=1&sort=1&search=${e.target.value}`)
      .then(res => {
        setSearchedUsersList(res.data.users);
      })
      .catch(err => {
        console.log(err);
      })
    };
  };

  // To handle selected users
  const handleSelectUser = (user) => {
    console.log('click : ', user);
    setSelectedUsersList(preVal => [...preVal, user]);
  };

  // To handle remove selected users
  const handleSelectedRemove = (user) => {
    const updatedUsersList = selectedUsersList.filter((item) => item.userId !== user.userId);

    setSelectedUsersList(updatedUsersList);
  };

  return (
    <>
      <section className='profileUserTdSharePopup'>
        <Modal show={showPopup} onHide={() => setShowPopup(false)} size='md' centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='putdsp'>
              <span className='putdsp-head'>Share</span>

              {/* Search Bar */}
              <form className='putdsp-form' onSubmit={handleSearch}>
                <div className='putdsp-form-img'>
                  <img src={`${BASE_URL_STATIC}/icon/search-2.svg`} alt="search" />
                </div>

                <label htmlFor="search">
                  <input type="search" value={inputValue} onChange={handleSearch} id='search' name='search' placeholder='Search...' autoComplete='off' />
                </label>
              </form>

              {/* Searched Users List */}
              <div className='putdsp-users'>
                {
                  searchedUsersList 
                  && 
                  searchedUsersList.map((user, index) => (
                    <div className='putdsp-user' key={index} onClick={() => handleSelectUser(user)}>
                      <div className='putdsp-user-img'>
                        {/* <img src={user.profileImg} alt="profile" /> */}
                        <img src={`${BASE_URL_STATIC}/user-icon${index+1}.jpg`} alt="profile" />
                      </div>

                      <div className='putdsp-user-header'>
                        <span className='putdsp-user-name'>{user.fname}</span>
                        <span className='putdsp-user-userName'>{user.username ? user.username : '@username'}</span>
                      </div>
                    </div>
                  ))
                }
              </div>

              {/* Selected Users List */}
              <div className='putdsp-selected'>
                {
                  selectedUsersList
                  &&
                  selectedUsersList.map((user, index) => (
                    <>
                      <div className='putdsp-selected-img' key={index}>
                        {/* <img src={user.profileImg} alt="selected profile" /> */}
                        <img src={`${BASE_URL_STATIC}/user-icon${index+1}.jpg`} alt="selected profile" />

                        <span className='putdsp-selected-remove' onClick={() => handleSelectedRemove(user)}>
                          <IoIosCloseCircle />
                        </span>
                      </div>

                    </>
                  ))
                }
              </div>

              <span className='putdsp-send'>Send</span>

              {/* Social Media Icons */}
              <div className='ps-sml'>
                <WhatsappShareButton url={`https://emilorj.sjain.io/profile/${shareUserId}`} title={shareUserName} image={`https://cdn-icons-png.flaticon.com/512/3135/3135715.png`}>
                  <Link to={`https://web.whatsapp.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/whatsapp-1.svg`} alt="icon" />
                  </Link>
                </WhatsappShareButton>

                <TwitterShareButton url={`${BASEURL}/profile/${shareUserId}`}>
                  <Link to={`https://twitter.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/twitter-1.svg`} alt="icon" />
                  </Link>
                </TwitterShareButton>

                <FacebookShareButton url={`${BASEURL}/profile/${shareUserId}`}>
                  <Link to={`https://www.facebook.com/`} className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/facebook-1.svg`} alt="icon" />
                  </Link>
                </FacebookShareButton>

                <Link to={`https://www.instagram.com/direct/inbox/`} className='ps-sml-div'>
                  <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/instagram-1.png`} alt="icon" />
                </Link>

                <Link className='ps-sml-div' onClick={() => download(`https://api.qrserver.com/v1/create-qr-code/?data=https://emilorj.sjain.io/profile/${shareUserId}`)}>
                  <img id='putdsqr' className='ps-sml-img' src={`https://api.qrserver.com/v1/create-qr-code/?data=https://emilorj.sjain.io/profile/${shareUserId}`} alt="icon" />
                </Link>

                <CopyToClipboard text={`${BASEURL}/profile/${shareUserId}`} onCopy={() => toast.success('Copied')} >
                  <Link className='ps-sml-div'>
                    <img className='ps-sml-img' src={`${BASE_URL_STATIC}/icon/copy.svg`} alt="icon" />
                  </Link>
                </ CopyToClipboard>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default ProfileUserTdSharePopup
