import React from 'react'

const ProfileAbout = () => {
  return (
    <div className='vh-100 overflow-y-scroll scrollbar-hidden pt-5'>
        <h1 className='text-center mt-4'>About soon..</h1>
    </div>
  )
}

export default ProfileAbout