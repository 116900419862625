import React, { useEffect, useState, useContext } from 'react';
import { ApiContext } from '../../UseContext/ApiCall';
import { BASE_URL_STATIC, AwsFileUrl } from "../../../../config";
import { settingContext } from '../../UseContext/SettingContext';
import { getPostData } from '../../UseContext/ApiHelpers'
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';
import { useParams } from 'react-router-dom';

const ProfileMemes = () => {

  const [postData, setpostData] = useState([]);
  const { profileUserId, isBlocked, config } = useContext(ApiContext);
  const { isOtherUserAccountPrivate } = useContext(settingContext);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData, setmainpoastData] = useState('')

  // To get profile memes
  useEffect(() => {
    getProfileData()
  }, [profileUserId])

  const { paramsUserId } = useParams();


  const getProfileData = async () => {
    try {
      const responsedata = await getPostData(paramsUserId ,'memes', config)
      setpostData(responsedata)
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {/* Profile Memes Popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />

      <section className='col-12 col-lg-10 col-xl-8 mx-auto p-0 row text-center d-flex '>
        {
          !isBlocked
            ?
            isOtherUserAccountPrivate
              ?
              postData
                ?
                postData.map((item) => (
                  <div key={item._id} className='px-0 col-2 mx-1 mb-1 mx-lg-2 mb-lg-3 d-flex justify-content-start'>
                    <div style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '200px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden'
                    }}>
                      {item.postTumbnailUrl && (
                        <img src={`${AwsFileUrl + item.postTumbnailUrl}`} alt={item.title} className='img-fluid' style={{ height: '100%', maxWidth: '100%' , objectFit : "cover" }} onClick={() => { setShowPostsPopup(!showPostsPopup); setmainpoastData(item); setPostId(item._id) }} />

                      )}

                    </div>


                  </div>
                ))

                :
                <div className='w-100 d-flex justify-content-center'>
                  <span className="loaderMain"></span>
                </div>
              :
              <section className="profileMemes-private">
                <div className="profileMemes-private-img">
                  <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountPrivacy" />
                </div>

                <span className="profileMemes-private-head">Account is private</span>
              </section>
            :
            <section className="profilePost-block">
              <div className="profilePost-block-img">
                <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountBlock" />
              </div>

              <span className="profilePost-block-head">Account is Blocked</span>
            </section>
        }








     
      </section>
    </>
  )
}

export default ProfileMemes;