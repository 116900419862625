// utils/thumbnailUtils.js
import { getFrames, VideoToFramesMethod } from './VideoFrames';

export const generateThumbnail = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const fileUrl = reader.result;

      try {
        const frames = await getFrames(
          fileUrl,
          1, // Request only one frame
          VideoToFramesMethod.timestamp, // Method to get frame by timestamp
          2 // Timestamp in seconds
        );

        if (frames.length > 0) {
          const imageUrl = frames[0]; // Only one frame needed
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const imageFile = new File([blob], `thumbnail-${Date.now()}.png`, { type: "image/png" });
          resolve(imageFile);
        } else {
          reject('No frames found.');
        }
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsDataURL(file);
  });
};
