import React from 'react';
import { Chart } from "react-google-charts";


const Insights = () => {

  const pageOverviews = [
    {
      icons: '/images/business/icons/post-reach.svg',
      number: '65,000',
      text: 'Posts Reach'
    },
    {
      icons: '	https://s3aws.emilo.in/images/icon/like-1.svg',
      number: '10,500',
      text: 'Likes'
    },
    {
      icons: '	https://s3aws.emilo.in/images/icon/comment-1.svg',
      number: '5,680',
      text: 'Comments'
    },
    {
      icons: '/images/business/icons/post-reach.svg',
      number: '2,600',
      text: 'New Followers'
    },
  ];

  const pageOverviewData = [
    ["Year", "Sales"],
    ["2013", 175],
    ["2014", 125],
    ["2015", 115],
    ["2016", 150],
  ];
  
  const pageOverviewOtions = {
    hAxis: { titleTextStyle: { color: "#333" } },
  };

  const posts = [
    'https://d3jmn01ri1fzgl.cloudfront.net/photoadking/webp_thumbnail/link-water-and-mischka-hair-oil-ad-template-0vz73od10532f5.webp',
    'https://i.pinimg.com/550x/1b/0b/81/1b0b81969e3f5bb5ea7d29b55f90b722.jpg',
    'https://i.pinimg.com/736x/19/2e/23/192e231459017e0950e043ffd98f4a50.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ0eBZGPBqXfte6TajvkGwDlmzEzauDLIsHA&s',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCunx4nqZ01UsaBneQw7jjD4TdJ0FH3WTvUQ&s',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHDHWLbAV6aznvZslzX9zAkNJzu92_huSoKg&s',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzTztyVleBxLNQSfIjdIuRFQsJ9HojSb_afA&s',
    'https://discoverpilgrim.com/cdn/shop/files/18-kv.jpg',
    'https://shop.lakmesalon.in/cdn/shop/files/540368_9_af235a07-0b03-4d41-8d6e-e39af4e0dabf.webp',
  ];

  return (
    <>
      <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5" style={{ backgroundColor: "#F5F5F5" }}>
        {/* Insights */}
        <div className='bg-white shadow-sm rounded-3 my-3 p-3 d-flex gap-3 align-items-center flex-wrap'>
          <h5 className='fw-semibold mb-0'>Insights</h5>

          <button type="button" className='btn btn-sm btn-primary ms-auto'>+ Create Campaign</button>

          <button type="button" className='btn btn-sm btn-primary'>Review And Publish</button>

          <div className='border rounded-3 p-1'>
            <img src={`	https://s3aws.emilo.in/images/icon/threeDot.svg`} style={{ height: '20px' }} alt="three dot" />
          </div>
        </div>

        {/* Page Overview */}
        <div className='d-flex align-items-center gap-2 mb-3'>
          <img src={`/images/icons/info.svg`} style={{ height: '20px' }} alt="page overview" />

          <h5 className='fw-semibold mb-0'>Page Overview</h5>

          <img src={`/images/icons/info.svg`} style={{ height: '20px' }} alt="info" />
        </div>

        <div className='insights-page-overview mb-3'>
          {
            pageOverviews.map((overview, index) => (
              <div className='bg-white p-3 rounded-3 shadow-sm' key={index}>
                <Chart
                  chartType="AreaChart"
                  height="200px"
                  width="100%"
                  data={pageOverviewData}
                  options={pageOverviewOtions}
                />

                <div className='d-flex gap-3 mb-2'>
                  <div className='p-2 rounded-3 bg-secondary-subtle d-flex align-items-center justify-content-center'>
                    <img src={overview.icons} style={{ height: '30px' }} alt="icons" />
                  </div>

                  <div>
                    <h4 className='fw-semibold mb-0'>{overview.number}</h4>
                    <small className='text-secondary'>{overview.text}</small>
                  </div>
                </div>

                <div className='fw-semibold'><span className='text-success px-0'>▲ 48%</span> vs previous 7 day</div>
              </div>
            ))
          }
        </div>

        {/* Main */}
        <div className='business-insights-main mb-3'>
          <div>
            {/* Account Reached */}
            <div className='rounded-3 p-3 shadow-sm mb-3' style={{ backgroundColor: '#fcfcfc' }}>
              <div className='d-flex justify-content-between align-items-center gap-3'>
                <h5 className='fw-semibold mb-0'>Account Reached</h5>

                <select class="form-select form-select-sm" style={{ width: '143px' }}>
                  <option selected>Last 1 Week</option>
                  <option value="1">Last 1 Month</option>
                  <option value="2">Last 1 Year</option>
                </select>
              </div>

              <hr className='hr bg-secondary-subtle' />

              <h3 className='fw-semibold mb-0 mx-0'>5,700</h3>

              <span className='mb-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, consectetur?</span>

              <div className='d-flex flex-column flex-sm-row gap-3'>
                <div className='flex-grow-1'>
                  <span className='px-3 py-1 fw-semibold' style={{ backgroundColor: '#f1f2fd', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>May 05 - May 11</span>

                  <div className='bg-white rounded-3 shadow-sm p-3 d-flex align-items-center gap-3'>
                    <div style={{ height: '100px' }}>
                      <img src="/images/business/account-reached-1.png" className='h-100' alt="account-reached-1" />
                    </div>

                    <div>
                      <h6 className='fw-semibold mb-0'><span style={{ color: 'rgb(83 249 166)' }}>●</span> 3,300</h6>
                      <small className='ms-3'>Non - Followers</small>

                      <h6 className='fw-semibold mb-0 mt-2'><span style={{ color: 'rgb(83 103 249)' }}>●</span> 1,700</h6>
                      <small className='ms-3'>Followers</small>
                    </div>
                  </div>
                </div>

                <div className='flex-grow-1'>
                  <span className='px-3 py-1 fw-semibold' style={{ backgroundColor: '#f1f2fd', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>April 28 - May 04</span>

                  <div className='bg-white rounded-3 shadow-sm p-3 d-flex align-items-center gap-3'>
                    <div style={{ height: '100px' }}>
                      <img src="/images/business/account-reached-1.png" className='h-100' alt="account-reached-1" />
                    </div>

                    <div>
                      <h6 className='fw-semibold mb-0'><span style={{ color: 'rgb(83 249 166)' }}>●</span> 2,600</h6>
                      <small className='ms-3'>Non - Followers</small>

                      <h6 className='fw-semibold mb-0 mt-2'><span style={{ color: 'rgb(83 103 249)' }}>●</span> 2,400</h6>
                      <small className='ms-3'>Followers</small>
                    </div>
                  </div>
                </div>
              </div>

              <hr className='hr bg-secondary-subtle' />

              <h6 className='fw-semibold mb-0'>Top Content</h6>

              <span>Based on business page reached</span>

              <div className='d-flex flex-wrap align-items-center gap-3 mt-3 mb-4'>
                <small className='bg-primary rounded-pill text-white px-3'>All Reach</small>
                <small className='bg-secondary-subtle rounded-pill px-3'>Followrs Reach</small>
                <small className='bg-secondary-subtle rounded-pill px-3'>Non - Followrs Reach</small>
              </div>

              <div className='d-flex justify-content-between gap-3 align-items-center'>
                <span className='fw-semibold'>Posts</span>
                <span>1,520</span>
              </div>

              <div className='d-flex justify-content-between gap-3 align-items-center my-2'>
                <span className='fw-semibold'>Stories</span>
                <span>1,200</span>
              </div>

              <div className='d-flex justify-content-between gap-3 align-items-center'>
                <span className='fw-semibold'>Flix</span>
                <span>1,800</span>
              </div>
            </div>

            {/* Posts */}
            <div className='rounded-3 p-3 shadow-sm' style={{ backgroundColor: '#fcfcfc' }}>
              <div className='d-flex align-items-center justify-content-between gap-3'>
                <h5 className='fw-semibold mb-0'>Posts</h5>

                <span className='text-primary'>See All</span>
              </div>

              <div className='d-flex flex-wrap align-items-center gap-3 mt-3 mb-4'>
                <small className='bg-primary rounded-pill text-white px-3'>All</small>
                <small className='bg-secondary-subtle rounded-pill px-3'>Top</small>
                <small className='bg-secondary-subtle rounded-pill px-3'>Most Reached</small>
                <small className='bg-secondary-subtle rounded-pill px-3'>Most Viewed</small>
              </div>

              <div className='business-insights-posts'>
                {
                  posts.map((post, index) => (
                    <div className='rounded-4 position-relative' style={{ height: '200px' }} key={index}>
                      <img src={post} className='h-100 w-100 rounded-4 object-fit-cover' alt="post" />

                      <div className='px-1 pb-1 rounded-3 position-absolute top-0 end-0 mt-2 me-2' style={{ backgroundColor: 'rgb(195 194 194 / 70%)' }}>
                        <img src="https://s3aws.emilo.in/images/icon/white-post.svg" style={{ height: '13px' }} alt="post" />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>

          <div>
            {/* User Activity */}
            <div className='rounded-3 p-3 shadow-sm mb-3' style={{ backgroundColor: '#fcfcfc' }}>
              <div className='d-flex justify-content-between align-items-center gap-3'>
                <h5 className='fw-semibold mb-0'>User Activity</h5>

                <select class="form-select form-select-sm" style={{ width: '143px' }}>
                  <option selected>Last 1 Week</option>
                  <option value="1">Last 1 Month</option>
                  <option value="2">Last 1 Year</option>
                </select>
              </div>

              <hr className='hr bg-secondary-subtle' />

              <div className='d-flex flex-wrap gap-3 mb-3'>
                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h6 className='fw-semibold mb-0'>1.14k</h6>
                  <small style={{ color: '#57cacf' }}>Daily Users</small>
                </div>

                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h6 className='fw-semibold mb-0'>9.34k</h6>
                  <small style={{ color: '#4d1bbe' }}>Weekly Users</small>
                </div>

                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h6 className='fw-semibold mb-0'>37.84k</h6>
                  <small style={{ color: '#6073f9' }}>Monthly Users</small>
                </div>
              </div>

              <div style={{ height: '350px' }}>
                <img src="/images/business/insights-user-activity.png" className='h-100 w-100' alt="insights-user-activity" />
              </div>
            </div>

            {/* Listing */}
            <div className='rounded-3 p-3 shadow-sm mb-3' style={{ backgroundColor: '#fcfcfc' }}>
              <div className='d-flex gap-3 align-items-center justify-content-between'>
                <h5 className='fw-semibold mb-0'>Listing</h5>

                <select class="form-select form-select-sm" style={{ width: '143px' }}>
                  <option selected>Last 1 Week</option>
                  <option value="1">Last 1 Month</option>
                  <option value="2">Last 1 Year</option>
                </select>
              </div>

              <hr className='hr bg-secondary-subtle' />

              <div className='d-flex flex-column gap-3 mb-3'>
                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h5 className='fw-semibold mb-0'>08</h5>
                  <small>Listing</small>
                </div>

                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <div className="progress mb-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar w-75"></div>
                  </div>

                  <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar bg-success" style={{ width: '25%' }}></div>
                  </div>
                </div>
              </div>

              <div>
                <span className='bg-primary text-white px-3 py-1' style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>Job Response</span>
                <span className='bg-secondary-subtle px-3 py-1' style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>Franchise Enquiry</span>

                <div className='bg-white p-3 rounded-3 shadow-sm'>
                  <div className='d-flex justify-content-between align-items-start gap-3 mb-3'>
                    <div className='bg-light p-2 rounded-3'>
                      <h6 className='fw-semibold mb-0'>375</h6>
                      <small className='text-primary'>Job Response</small>
                    </div>

                    <button type="button" className='btn btn-sm btn-primary rounded-3'>View List</button>
                  </div>

                  <div style={{ height: '300px' }}>
                    <img src="/images/business/insights-listing-chart.png" className='h-100 w-100'  alt="insights-listing-chart" />
                  </div>
                </div>
              </div>
            </div>

            {/* Audience */}
            <div className='rounded-3 p-3 shadow-sm mb-3' style={{ backgroundColor: '#fcfcfc' }}>
              <div className='d-flex justify-content-between gap-3 align-items-center'>
                <div>
                  <h5 className='fw-semibold mb-0'>Audience</h5>
                  <div><span className='fw-semibold'>103,023</span> <span className='text-secondary'>eMilo Followers</span></div>
                </div>

                <select class="form-select form-select-sm" style={{ width: '143px' }}>
                  <option selected>Last 1 Week</option>
                  <option value="1">Last 1 Month</option>
                  <option value="2">Last 1 Year</option>
                </select>
              </div>

              <hr className='hr bg-secondary-subtle' />

              <div className='d-flex gap-3 flex-wrap mb-3'>
                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h6 className='fw-semibold mb-0'><span style={{ color: '#5367f9', fontSize: '25px' }}>●</span> Men</h6>
                  <small className='ms-3' style={{ color: '#5367f9' }}>13.60%</small>
                </div>

                <div className='bg-white p-2 rounded-3 shadow-sm'>
                  <h6 className='fw-semibold mb-0'><span style={{ color: '#ffb4b9', fontSize: '25px' }}>●</span> Women</h6>
                  <small className='ms-3' style={{ color: '#ffb4b9' }}>86.40%</small>
                </div>
              </div>

              <div style={{ height: '300px' }}>
                <img src="/images/business/insights-audience-chart.png" className='h-100 w-100' alt="insights-audience-chart" />
              </div>
            </div>
          </div>
        </div>
      </section>  
    </>
  );
};

export default Insights;