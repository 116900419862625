import React from 'react'
import CenterBlockPosts from '../../../Home/CenterBlock/CenterBlockPosts/CenterBlockPosts'
import TrendingRightBlock from '../../../Home/RightBlock/TrendingRightBlock'

const Trending = () => {
  return (
    <div className="row ">


      <section className="center-block pt-5 p-0 mx-auto mt-5 col-lg-7 position-relative" >
        <h6 className="trending_head">Trending Feeds</h6>
        <CenterBlockPosts />
      </section>
      <div className="col-lg-5">
        <TrendingRightBlock/>
      </div>
    </div>
  )
}

export default Trending
