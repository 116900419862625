import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../../UseContext/ApiCall';
import toast from 'react-hot-toast';

const EditMemePopup = ({ showEditMemePopup, setShowEditMemePopup, setshowProfileMemeThreeDotPopuop, profileMemePopupData, setProfileMemePopupData }) => {

  const [editMemePopupData, setEditMemePopupData] = useState({
    location: '',
    description: ''
  });

  const { config } = useContext(ApiContext);

  // it will set previous data to fields
  useEffect(() => {
    profileMemePopupData
    &&
    setEditMemePopupData({
      location: profileMemePopupData.title,
      description: profileMemePopupData.description
    });
  }, [profileMemePopupData]);

  // it will change data
  const handleEditMemePopupDataChange = (e) => {
    const { name, value } = e.target;

    setEditMemePopupData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // To handle edit post
  const handleEditMeme = async (editMemePopupData, config) => {

    await axios.post('https://emilonode.sjain.io/post/editMeme', { 
      memeId: profileMemePopupData.memeId, 
      location: editMemePopupData.location, 
      description: editMemePopupData.description 
    }, config)
    .then(res => {
      console.log('res : ', res);

      setShowEditMemePopup(false);
      setshowProfileMemeThreeDotPopuop(false);

      toast.success(res.data.message);

      setProfileMemePopupData(preVal => ({
        ...preVal,
        title: editMemePopupData.location,
        description: editMemePopupData.description
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  return (
    <>
      <section className='editMemePopups'>
        <Modal show={showEditMemePopup} onHide={() => setShowEditMemePopup(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='editMemePopup'>
              <h5 className='editMemePopup-head'>Edit Meme</h5>

              <form className='editMemePopup-form'>
                <label htmlFor="location">
                  <input type="text" value={editMemePopupData.location} onChange={handleEditMemePopupDataChange} id='location' name='location' placeholder='Location...' />
                </label> <br />

                <textarea value={editMemePopupData.description} onChange={handleEditMemePopupDataChange} id="description" name="description" cols="51" rows="10" placeholder='Description...'></textarea>

                <span className='editMemePopup-btn' onClick={() => handleEditMeme(editMemePopupData, config)}>Edit Meme</span>
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default EditMemePopup;
