import React from "react";


const Ads = () => {

  const ads = [
    {
      image: 'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
      heading: 'Try new lakme kit with suhana khan and post on your social media',
      time: 'Sat 2, May',
      badgeText: 'Completed',
      badgeColor: 'primary',
      reach: '4785',
      engagement: '125',
      conversion: '1258',
      amountSpent: '789',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFVAktug7aUl_SqWLL6Ik-6DekeKkSWWUXjA&s',
      heading: 'Summer proof you hair this summer with our newly launched shampoo',
      time: 'Sat 2, May',
      badgeText: 'Completed',
      badgeColor: 'primary',
      reach: '2800',
      engagement: '7895',
      conversion: '785',
      amountSpent: '4568',
    },
    {
      image: 'https://pbs.twimg.com/media/FMQZnlqUUAMQLZk.jpg',
      heading: 'Refreshing coca cola with janhvi kapoor drink and test it',
      time: 'Wed 5, Jun',
      badgeText: 'Running',
      badgeColor: 'success',
      reach: '4590',
      engagement: '1250',
      conversion: '963',
      amountSpent: '152',
    },
    {
      image: 'https://www.bigbasket.com/media/uploads/flatpages/pd/260634-01.1.jpg',
      heading: 'Revitalize your hair this summer with our newly launched shampoo',
      time: 'Tue 7, May',
      badgeText: 'Running',
      badgeColor: 'success',
      reach: '1500',
      engagement: '2890',
      conversion: '253',
      amountSpent: '7560',
    },
    {
      image: 'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
      heading: 'Try new lakme kit with suhana khan and post on your social media',
      time: 'Sat 2, May',
      badgeText: 'Completed',
      badgeColor: 'primary',
      reach: '4785',
      engagement: '125',
      conversion: '1258',
      amountSpent: '789',
    },
    {
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFVAktug7aUl_SqWLL6Ik-6DekeKkSWWUXjA&s',
      heading: 'Summer proof you hair this summer with our newly launched shampoo',
      time: 'Sat 2, May',
      badgeText: 'Completed',
      badgeColor: 'primary',
      reach: '2800',
      engagement: '7895',
      conversion: '785',
      amountSpent: '4568',
    },
    {
      image: 'https://pbs.twimg.com/media/FMQZnlqUUAMQLZk.jpg',
      heading: 'Refreshing coca cola with janhvi kapoor drink and test it',
      time: 'Wed 5, Jun',
      badgeText: 'Running',
      badgeColor: 'success',
      reach: '4590',
      engagement: '1250',
      conversion: '963',
      amountSpent: '152',
    },
    {
      image: 'https://www.bigbasket.com/media/uploads/flatpages/pd/260634-01.1.jpg',
      heading: 'Revitalize your hair this summer with our newly launched shampoo',
      time: 'Tue 7, May',
      badgeText: 'Running',
      badgeColor: 'success',
      reach: '1500',
      engagement: '2890',
      conversion: '253',
      amountSpent: '7560',
    },
  ];

  return (
    <>
      <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5" style={{ backgroundColor: "#F5F5F5" }}>
        <div className="bg-white rounded-3 shadow-sm p-3 my-3 d-flex gap-3 align-items-center">
          <h5 className="fw-semibold mb-0">Ads</h5>

          <button type="button" className="btn btn-sm btn-primary px-3 rounded-3 ms-auto">Create Post</button>

          <img src="https://s3aws.emilo.in/images/icon/threeDot.svg" style={{ height: '20px' }} alt="three dot" />
        </div>

        <div className="bg-white rounded-3 shadow-sm p-3 my-3 d-flex gap-3 align-items-center justify-content-between">
          <div>
            <div className="d-flex gap-2 align-items-center">
              <h6 className="fw-semibold mb-0">All Ads</h6>
              <img src={`/images/business/icons/exclamation-circle-gray.svg`} style={{ height: '17px' }} alt="exclamation-circle-gray" />
            </div>

            <small className="fw-semibold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, error?</small>
          </div>

          <button type="button" className="btn btn-primary">Ad Manager</button>
        </div>

        <div className="bg-white rounded-3 shadow-sm p-3 my-3">
          <div className="d-flex gap-3 flex-wrap mb-3">
            <span className="bg-primary text-white flex-grow-1 text-center rounded-4 py-2 px-3">All Ads</span>
            <span className="bg-secondary-subtle text-secondary flex-grow-1 text-center rounded-4 py-2 px-3">Running</span>
            <span className="bg-secondary-subtle text-secondary flex-grow-1 text-center rounded-4 py-2 px-3">Completed</span>
            <span className="bg-secondary-subtle text-secondary flex-grow-1 text-center rounded-4 py-2 px-3">Drafts</span>
          </div>

          <div>
            {
              ads.map((ad, index) => (
                <div className="p-3 mb-3 rounded-3 d-flex flex-wrap gap-3" style={{ backgroundColor: '#f5f5f5' }} key={index}>
                  <div style={{ height: '127px', width: '127px' }}>
                    <img src={ad.image} className="h-100 w-100 rounded-3 object-fit-cover" alt="ad" />
                  </div>

                  <div className="flex-grow-1">
                    <div className="d-flex gap-3 flex-wrap align-items-start justify-content-between">
                      <div>
                        <h6 className="fw-semibold mb-1">{ad.heading}</h6>
                        <small className="text-secondary me-3">{ad.time}</small>
                        <small className={`bg-${ad.badgeColor}-subtle text-${ad.badgeColor} badge`}>{ad.badgeText}</small>
                      </div>

                      <button type="button" className="btn btn-primary">View Ads</button>
                    </div>

                    <hr className="hr bg-secondary-subtle" />

                    <div className="d-flex flex-wrap gap-3 justify-content-between">
                      {/* Posts Reach */}
                      <div className="text-center">
                        <div>
                          <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />
                          <small className="text-secondary">Posts Reach</small>
                        </div>

                        <h5 className="fw-semibold mb-0">{ad.reach}</h5>
                      </div>

                      {/* Engagement */}
                      <div className="text-center">
                        <div>
                          <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />
                          <small className="text-secondary">Engagement</small>
                        </div>

                        <h5 className="fw-semibold mb-0">{ad.engagement}</h5>
                      </div>

                      {/* Conversion */}
                      <div className="text-center">
                        <div>
                          <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />
                          <small className="text-secondary">Conversion</small>
                        </div>

                        <h5 className="fw-semibold mb-0">{ad.conversion}</h5>
                      </div>

                      {/* Amount Spent */}
                      <div className="text-center">
                        <div>
                          <img src={`/images/business/icons/post-reach.svg`} className="me-2" style={{ height: '20px' }} alt="post-reach" />
                          <small className="text-secondary">Amount Spent</small>
                        </div>

                        <h5 className="fw-semibold mb-0">{ad.amountSpent}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Ads;