import React from 'react'
import { BASE_URL_STATIC } from '../../../config'



const ProfileVerify = () => {
  return (
    <>
      {/* Verify Identity */}
      <section className='vh-100 text-center pt-5 pb-5 pb-lg-0 overflow-y-scroll scrollbar-hidden'>
        <img className='mt-3 mt-md-4 mt-lg-5' src={`${BASE_URL_STATIC}/SoftCorner/verify-live-image.png`} alt="verify-live" height={300} width={300} />

        <span className='w-75 my-4 my-sm-5'>For identity verification, we kindly request you to complete the necessary steps by scanning a live image. This process helps us ensure the authenticity of your identity, and it involves capturing a real-time image to confirm your verification. Your cooperation in completing this step is greatly appreciated.</span>

        <button type='button' className='btn btn-primary rounded-pill px-4 px-sm-5 mb-4 mb-lg-0'>Upload My Live Image</button>
      </section>
    </>
  )
}

export default ProfileVerify