import React, { useContext, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Accordion from 'react-bootstrap/Accordion';
import { Button } from "react-bootstrap";

import toast from 'react-hot-toast';
import { ScApiContext } from '../../useContext/softCornerContext';
import { createSpecialDates, getSpecialDays } from '../../../Emilo/UseContext/ScApiHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { BASE_URL_STATIC } from '../../../../config';

const AddSpecialDays = ({ showAddSpecialDaysPopup, setShowAddSpecialDaysPopup, setSpecialDays }) => {
  const [formData, setFormData] = useState({
    title: '',
    category: '',
    location: '',
    selectDate: '',
    alert: '',
    notes: '',
    setAlerts: [],
    customAlertTime: "",
    customDate: '',
    customTime: '',
    categoryOther: '',
  });

  const [loader, setLoader] = useState(false)

  const { scConfig } = useContext(ScApiContext)

    const { title, category, location, selectDate, notes, customDate, customTime, setAlerts,categoryOther } = formData;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {

      const newSetAlerts = checked
        ? [...formData.setAlerts, name]
        : formData.setAlerts.filter((alert) => alert !== name);

      setFormData((prevformData) => ({
        ...prevformData,
        setAlerts: newSetAlerts,
      }));

    } else {
      setFormData((prevformData) => ({
        ...prevformData,
        [name]: value,
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const toLocalISOString = (dateString, timeString) => {
      const combinedDateTimeString = `${dateString}T${timeString}`;
      const combinedDateTime = new Date(combinedDateTimeString);
      const localISOString = new Date(
        combinedDateTime.getTime() - combinedDateTime.getTimezoneOffset() * 60000
      ).toISOString();
      return localISOString;
    };

    const customIsoFormat =
      (formData.customDate || formData.customTime) &&
      toLocalISOString(formData.customDate, formData.customTime);

    try {
      if (!formData.title) {
        toast.error("please fill title")
        setLoader(false)
      } else if (!formData.category) {
        toast.error("please select category")
        setLoader(false)
      } else if (!formData.selectDate) {
        toast.error("Please select date");
      } else {
        const res = await createSpecialDates(
          {
            ...formData,
            customAlertTime:
              (formData.customDate || formData.customTime) && customIsoFormat,
          },
          scConfig
        );
        if (res) {
          setShowAddSpecialDaysPopup(false);
          setFormData({
            title: "",
            category: "",
            location: "",
            selectDate: "",
            repeat: "",
            alert: "",
            notes: "",
            customDate: "",
            customTime: "",
            setAlerts: [],
            categoryOther : ""
          });
        }
        await getSpecialDays(scConfig).then((res) => { setSpecialDays(res) })
        toast.success(res.message);
      }
    } catch (error) {
      toast.error("Failed to create plan");
    }
  };

  console.log("da" , formData);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission if needed
  };

  return (
    <>
      <Modal show={showAddSpecialDaysPopup} onHide={() => setShowAddSpecialDaysPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call">
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section className='addSpecialDays'>
            <h6 className='addSpecialDays-head'>Add Special Days</h6>

            <section className='addSpecialDays-formField'>
              <form onSubmit={handleSubmit}>
                {/* Title */}
                <label htmlFor="title" className='addSpecialDays-formField-label-all addSpecialDays-formField-title-label'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-title.png`} alt="sc-title" height={20} width={20} />
                  <span>Title</span>
                </label> <br />
                <input type="text" className='addSpecialDays-formField-input-all addSpecialDays-formField-title-input' value={title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} id='title' name='title' placeholder='Enter Title' /> <br />

                {/* Category */}
                <label htmlFor="category" className='addSpecialDays-formField-label-all addSpecialDays-formField-category-label'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-category.png`} alt="sc-category" height={17} width={17} />
                  <span>Category</span>
                </label>

                <select id='category' name='category' className='addSpecialDays-formField-input-all addSpecialDays-formField-category-input' value={category} onChange={handleChange}>
                  <option value="" disabled>select</option>
                  <option value="Category 1">Category 1</option>
                  <option value="Category 2">Category 2</option>
                  <option value="Category 3">Category 3</option>
                  <option value="Category 4">Category 4</option>
                  <option value="other">Other</option>
                </select>

                <div>
                  {formData.category === "other" && <input type="text" className='addSpecialDays-formField-input-all addSpecialDays-formField-title-input' value={categoryOther} onChange={(e) => setFormData({ ...formData, 
                    categoryOther: e.target.value })} id='title' name='title' placeholder='Enter Title' />}
                  <br />
                </div>

                {/* Location */}
                <label htmlFor="location" className='addSpecialDays-formField-label-all addSpecialDays-formField-location-label'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-location.png`} alt="sc-location" height={17} width={15} />
                  <span>Location</span>
                </label> <br />
                <input type="text" className='addSpecialDays-formField-input-all addSpecialDays-formField-location-input' value={location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} id='location' name='location' placeholder='Enter Location Name' /> <br />

                {/* Date & Time */}
                <label htmlFor="title" className='addSpecialDays-formField-label-all addSpecialDays-formField-dateAndTime-label'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-DateAndTime
                  .png`} alt="sc-DateAndTime" height={17} width={17} />
                  <span>Date</span>
                </label>

                <input type="date" min={Date.now()} className='addSpecialDays-formField-selectDate-date' value={selectDate} onChange={(e) => setFormData({ ...formData, selectDate: e.target.value })} name="start" id="start" /> <br />

                {/* Set Alert */}
                <div style={{ position: "relative" }}>
                  <label
                    htmlFor="setAlert"
                    className="newPlanPopup-formField-label-all newPlanPopup-formField-setAlert-label"
                  >
                    <img
                      src={`${BASE_URL_STATIC}/SoftCorner/sc-alert.png`}
                      alt="sc-alert"
                      height={20}
                      width={20}
                    />
                    <span>Set Alert</span>
                  </label>{" "}
                  <div style={{ position: "absolute", right: "0px", top: "0px" }}>
                    <button className="customBtn" type="button" onClick={() => setFormData({ ...formData, setToggle: !formData.setToggle })}>custom</button>
                  </div>
                </div>

                <div className="sc-alerts my-3">
                  <span>
                    <label htmlFor="1_min"></label>
                    <input
                      type="checkbox"
                      id="1_min"
                      name="1_min"
                      checked={setAlerts.includes("1_min")}
                      onChange={handleChange}
                    />
                    1 min
                  </span>
                  <span>
                    <label htmlFor="5_min"></label>
                    <input
                      type="checkbox"
                      id="5_min"
                      name="5_min"
                      checked={setAlerts.includes("5_min")}
                      onChange={handleChange}
                    />
                    5 min
                  </span>
                  <span>
                    <label htmlFor="1_week"></label>
                    <input
                      type="checkbox"
                      id="1_week"
                      name="1_week"
                      checked={setAlerts.includes("1_week")}
                      onChange={handleChange}
                    />
                    1 week
                  </span>
                  <span>
                    <label htmlFor="1_month"></label>
                    <input
                      type="checkbox"
                      id="1_month"
                      name="1_month"
                      checked={setAlerts.includes("1_month")}
                      onChange={handleChange}
                    />
                    1 month
                  </span>
                </div>

                {/* Accordian */}
                <Accordion activeKey={formData.setToggle ? "0" : null}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Body style={{ display: "flex" }}>
                      <input
                        type="date"
                        className="newPlanPopup-formField-dat-date"
                        value={customDate}
                        name="customDate"
                        id="customDate"
                        onChange={handleChange}
                      />
                      <input
                        type="time"
                        className="newPlanPopup-formField-dat-time"
                        value={customTime}
                        name="customTime"
                        id="customTime"
                        onChange={handleChange}
                      />{" "}
                    </Accordion.Body>
                  </Accordion.Item></Accordion>

                {/* Notes */}
                <label htmlFor="notes" className='addSpecialDays-formField-label-all addSpecialDays-formField-notes-label'>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} alt="sc-notes" height={20} width={17} />
                  <span>Notes</span>
                </label> <br />
                <textarea className='addSpecialDays-formField-notes-textArea' value={notes} onChange={(e) => setFormData({ ...formData, notes: e.target.value })} id='notes' name='notes' rows={5} cols={40} placeholder='Write something here...' /> <br />

                {/* Save button */}
                {/* <div className='addSpecialDays-button'>
                  <button type="submit" onClick={handleSave}>Save</button>
                </div> */}
              </form>
            </section>

            <div className="newPlan-Btn">
              <Button style={{ backgroundColor: "#64A6F0" }} onClick={handleSave} >{!loader ?
                "Next" : <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> please wait </span>}
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSpecialDays;
