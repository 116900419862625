import React, { useContext, useEffect, useRef } from 'react'
import { BASE_URL_STATIC } from '../../../../../config'
import { Link } from 'react-router-dom';
import { ApiContext } from '../../../UseContext/ApiCall';



const BlockedUsers = () => {

  const { loggedInUserId, getBlokedUsers, blockedUsers, handleBlock, handleUnblock, handleBlockFocus,
    blockSearchUsers, blockFilterSearchUsers, handleBlockChange,
    showBlockSearchList, setShowBlockSearchList, showBlockFilterSearchList, setShowBlockFilterSearchList,
  } = useContext(ApiContext);

  const searchListRef = useRef(null);

  // Function to handle click outside of the user list
  const handleClickOutside = (event) => {
    if (searchListRef.current && !searchListRef.current.contains(event.target)) {
      setShowBlockSearchList(false);
      setShowBlockFilterSearchList(false);
    };
  };

  useEffect(() => {
    // To get blocked users list
    getBlokedUsers();

    // Add click event listener on component mount
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <section className='vh-100 pt-3 pt-lg-5 overflow-scroll scrollbar-hidden px-2 px-sm-3 px-lg-4 px-xl-5'>
        {/* Breadcrumb */}
        <nav className='pt-4 pt-lg-5'>
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
            <li className="breadcrumb-item active" aria-current="page">Blocked & Restricted</li>
          </ol>
        </nav>

        {/* Toggle */}
        <div className='d-flex justify-content-evenly'>
          <Link to={`/profile/${loggedInUserId}/profile-setting/blocked-users`} className='cursor-pointer p-2 rounded-3 shadow text-dark'>
            <img className='me-2' src={`${BASE_URL_STATIC}/icon/friendBlock.svg`} alt="blocked" />
            <span>Blocked</span>
          </Link>

          <Link to={`/profile/${loggedInUserId}/profile-setting/restricted-users`} className='cursor-pointer border border-light text-dark p-1'>
            <img className='me-2' src={`${BASE_URL_STATIC}/icon/friendBlock.svg`} alt="blocked" />
            <span>Restricted</span>
          </Link>
        </div>

        <hr className='hr' />

        {/* Search */}
        <div className='d-flex rounded-pill bg-white shadow-sm position-relative'>
          <img className='px-3 rounded-pill' src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
          <input  type="search" className='form-control rounded-pill border-0 shadow-none' name='blockFilterSearchUsers' onChange={handleBlockChange} onFocus={handleBlockFocus} autoComplete='off' placeholder='Search Users...' />

          {/* All users search list */}
          <>
            {
              showBlockSearchList && blockSearchUsers.length > 0
              &&
              <div className='position-absolute bg-white w-100 shadow rounded-3 p-2 overflow-y-scroll' ref={searchListRef} style={{ top: '50px', maxHeight: '50vh' }}>
                {
                  blockSearchUsers.map((user, index) => (
                    <div className='d-flex align-items-center mb-3' key={index}>
                      <img src="https://s3aws.emilo.in/default/user.webp" className='rounded-circle' style={{ height: '50px', width: '50px' }} alt="profile" />

                      <div className='ms-3 me-auto'>
                        <h5>{user.name ? user.name : 'name'}</h5>
                        <span className='text-secondary'>{user.username ? user.username : 'username'}</span>
                      </div>

                      <button type="button" className='btn btn-primary' onClick={() => handleBlock(user._id)}>Block</button>
                    </div >
                  ))
                }
              </div>
            }
          </>

          {/* Filtered users search list */}
          <>
            {
              showBlockFilterSearchList && blockFilterSearchUsers.length > 0
              &&
              <div className='position-absolute bg-white w-100 shadow rounded-3 p-2 overflow-y-scroll' ref={searchListRef} style={{ top: '50px', maxHeight: '50vh' }}>
                {
                  blockFilterSearchUsers.map((user, index) => (
                    <div className='d-flex align-items-center mb-3' key={index}>
                      <img src="https://s3aws.emilo.in/default/user.webp" className='rounded-circle' style={{ height: '50px', width: '50px' }} alt="profile" />

                      <div className='ms-3 me-auto'>
                        <h5>{user.name ? user.name : 'name'}</h5>
                        <span className='text-secondary'>{user.username ? user.username : 'username'}</span>
                      </div>

                      <button type="button" className='btn btn-primary' onClick={() => handleBlock(user._id)}>Block</button>
                    </div >
                  ))
                }
              </div>
            }
          </>
        </div>

        {/* Blocked Users List */}
        <div className='mt-4'>
          {
            blockedUsers && blockedUsers.length > 0
            ?
            blockedUsers.map((user, index) => (
              <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>
                <img className='rounded-circle block-profile me-3' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />
      
                <div className='me-auto w-50 align-items-center'>
                  <h6 className='fw-semibold text-nowrap text-truncate'>{user.name ? user.name : 'name'}</h6>
                  <span className='text-nowrap text-truncate w-100'>{user.username ? user.username : 'username'}</span>
                </div>
      
                <button className='btn btn-sm btn-primary' onClick={() => handleUnblock(user._id)}>Unblock</button>
              </div>  
            ))
            :
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '50vh' }}>
              <img src="https://demo3.sjainventures.com/e-milo/images/icon/friendBlock.svg" className='mb-3' style={{ height: '100px', width: '100px' }} alt="block" />
              <span>You didn't blocked any users</span>
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default BlockedUsers;