import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { BASEAPIURL } from '../../../../../config';
import { profileContext } from '../../../UseContext/ProfileContext';
import { myProfile } from '../../../UseContext/ApiHelpers';
import { ApiContext } from '../../../UseContext/ApiCall';

const BioPop = ({ show, close }) => {

const { bioInput, setBioInput } = useContext(profileContext);
const [loader , setLoader] = useState(false)
const {config} = useContext(ApiContext)

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoader(true)
    const user = { bio : bioInput };

    
    fetch(`${BASEAPIURL}/user/updateuserprofile`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(user),
    }).then((result) => {
      result.json().then(() => myProfile(config).then(res => setBioInput(res.bio)));
      setLoader(false)
      close();
    });
  };

  return (
    <>
      <section>
        <Modal show={show} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={handleSubmit} className='d-flex flex-column justify-content-center '>
              <h5>Bio</h5>
              <textarea rows='8' defaultValue={bioInput && bioInput} onChange={(e) => setBioInput(e.target.value)} placeholder='write your bio here...'
                style={{ border: '2px solid #d3d3d3', width: "85%", margin: "auto", padding: "10px", borderRadius: "10px" }}
              />
              <div className='d-flex justify-content-center'>
                <button type="submit" className="btn btn-dark mt-4 col-lg-4">{loader ? "saving..." : "save"}</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default BioPop;