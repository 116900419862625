import React, { useContext, useEffect } from 'react';
import BusinessInfoPopup from '../Popup/Home/BusinessInfoPopup';
import BusinessMissionVisionPopup from '../Popup/Home/BusinessMissionVisionPopup';
import BusinessCategoryPopup from '../Popup/Home/BusinessCategoryPopup';
import BusinessAddressPopup from '../Popup/Home/BusinessAddressPopup';
import BusinessContactInfoMobilePopup from '../Popup/Home/BusinessContactInfoMobilePopup';
import BusinessContactInfoEmailPopup from '../Popup/Home/BusinessContactInfoEmailPopup';
import BusinessDealerPopup from '../Popup/Home/BusinessDealerPopup';
import BusinessWebsiteEmailPopup from '../Popup/Home/BusinessWebsiteEmailPopup';
import BusinessMilestonePopup from '../Popup/Home/BusinessMilestonePopup';
import BusinessActiveHoursPopup from '../Popup/Home/BusinessActiveHoursPopup';
import BusinessBioPopup from '../Popup/Home/BusinessBioPopup';
import { businessContext } from '../UseContext/BusinessContext';
import toast from 'react-hot-toast';
import ShowMilestonePopup from '../Popup/ShowMilestonePopup';
import { AwsFileUrl } from '../../../config';


const BusinessHomeAbout = () => {

  const {
    paramsPageId,

    setShowBioPopup,
    setShowInfoPopup,
    setShowMissionVisionPopup,
    setShowCategoryPopup,
    setShowAddressPopup,
    setShowContactInfoMobilePopup,
    setShowContactInfoEmailPopup,
    setShowDealerPopup,
    setShowWebsiteEmailPopup,
    setShowCreateMilestonePopup,
    setShowMilestonePopup,
    setShowActiveHoursPopup,

    pageAbout,
    setNewAddress, deletePageAddress,
    setContactInfo, deleteContactInfo,
    setDealer, deleteDealer,
    setMilestone,

    getPageAboutDetails,
    checkIsItMyPage, isItMyPage,
  } = useContext(businessContext);

  // console.log('isItMyPage : ', isItMyPage);


  // To handle edit page address
  const handleEditPageAddress = (address) => {
    setNewAddress(address);

    setShowAddressPopup(true);
  };

  // To handle edit contact info 
  const handleEditContactInfo = (info) => {    
    setContactInfo(info);

    if(info.type === 'mobile') {
      setShowContactInfoMobilePopup(true);
    } else {
      setShowContactInfoEmailPopup(true);
    };
  };

  // To handle edit page dealer
  const handleEditPageDealer = (dealer) => {
    setDealer(dealer);

    setShowDealerPopup(true);
  };

  // To handle milestone click
  const handleClickMilestone = (milestone) => {
    setShowMilestonePopup(true);

    setMilestone(milestone)
  };


  useEffect(() => {
    getPageAboutDetails(paramsPageId);
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]);


  return (
    <>
      {/* Popup */}
      <>
        {/* Bio Popup */}
        <BusinessBioPopup />

        {/* Info Popup */}
        <BusinessInfoPopup />

        {/* Mission & Vision Popup */}
        <BusinessMissionVisionPopup />

        {/* Category Popup */}
        <BusinessCategoryPopup />

        {/* Address Popup */}
        <BusinessAddressPopup />

        {/* Contact Info Mobile Popup */}
        <BusinessContactInfoMobilePopup />

        {/* Contact Info Email Popup */}
        <BusinessContactInfoEmailPopup />

        {/* Dealer Popup */}
        <BusinessDealerPopup />

        {/* Website & Email Popup */}
        <BusinessWebsiteEmailPopup />

        {/* Milestone Popup */}
        <BusinessMilestonePopup />

        {/* Show Milestone Popup */}
        <ShowMilestonePopup />

        {/* Active Hours Popup */}
        <BusinessActiveHoursPopup />
      </>
    
      <section>
        {/* Bio */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Bio</h6>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowBioPopup(true)}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <>
            {
              pageAbout.bio
              ?
              <span className='bg-white p-3 rounded-3 ms-3 shadow-sm'>{pageAbout.bio}</span>
              :
              <span className='bg-white p-3 rounded-3 ms-3 shadow-sm text-secondary'>Write your bio here...</span>
            }
          </>
        </div>

        {/* Info & About Page */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Info */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Info</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowInfoPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 mb-3 shadow-sm'>Year of Establishment</span>

            <input type="date" className='rounded-3 border-0 px-3 py-1 shadow-sm' value={pageAbout.yearOfEstablishment} readOnly={isItMyPage === false} />
          </>

          <hr className='hr bg-secondary' />

          {/* About Page */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>About Page</h6>

            <>
              {
                pageAbout.aboutPage
                ?
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm'>{pageAbout.aboutPage}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm text-secondary'>Write about your page...</span>
              }
            </>
          </>
        </div>

        {/* Mission & Vission */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Mission */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Mission</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowMissionVisionPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <>
              {
                pageAbout.mission
                ?
                <span className='bg-white p-3 rounded-3 ms-3 mb-3 shadow-sm'>{pageAbout.mission}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 mb-3 shadow-sm text-secondary'>Write your mission here...</span>
              }
            </>
          </>

          <hr className='hr bg-secondary' />

          {/* Vission */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>Vission</h6>

            <>
              {
                pageAbout.vission
                ?
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm'>{pageAbout.vission}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm text-secondary'>Write your vission here...</span>
              }
            </>
          </>
        </div>

        {/* Category & Sub Category */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Category */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Category</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowCategoryPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 mb-3 shadow-sm'>{pageAbout.category}</span>
          </>

          <hr className='hr bg-secondary' />

          {/* Sub Category */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>Sub Category</h6>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 shadow-sm'>{pageAbout.subCategory}</span>            
          </>
        </div>

        {/* Address */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='fw-semibold mb-0'>Address</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} onClick={() => setShowAddressPopup(true)} style={{ height: '25px' }} alt="add-circle" />
            }
          </div>

          <hr className='hr bg-secondary' />

          <div className='ms-3'>
            {
              pageAbout.address.length > 0
              &&
              pageAbout.address.map((address, index) => (
                <div className='d-flex align-items-center mb-4' key={index}>
                  <img src={`/images/business/icons/location.svg`} style={{ height: '30px' }} alt="location" />

                  <div className='mx-3 flex-grow-1 border-bottom'>
                    <p className='text-secondary'>{address.name}</p>
                    <p>{address.line1}, {address.line2}, {address.city}, {address.state} - {address.pincode}</p>
                  </div>

                  <>
                    {
                      isItMyPage
                      &&
                      <>
                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm me-2' onClick={() => handleEditPageAddress(address)}>
                          <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                        </div>

                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => deletePageAddress(address._id)}>
                          <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                        </div>
                      </>
                    }
                  </>

                </div>
              ))
            }
          </div>
        </div>

        {/* Contact Info */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <h6 className='fw-semibold mb-0'>Contact Info</h6>

          <hr className='hr bg-secondary' />

          {/* Mobile */}
          <div className='ms-3'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='bg-secondary-subtle d-inline-block px-3 py-1 rounded-3'>
                <img src={`/images/business/icons/mobile.svg`} className='me-2' style={{ height: '25px' }} alt="mobile" />
                <span>Mobile</span>
              </div>

              {
                isItMyPage
                &&
                <img src={`/images/business/icons/add-circle.svg`} style={{ height: '25px' }} onClick={() => setShowContactInfoMobilePopup(true)} alt="add-circle" />
              }
            </div>

            <div className='ms-3'>
              {
                pageAbout.contactInfo.mobile.length > 0
                &&
                pageAbout.contactInfo.mobile.map((mobile, index) => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <div className='flex-grow-1'>
                      <p className='text-secondary'>{mobile.name}</p>
                      <p className='text-primary border-bottom'>{mobile.mobile}</p>
                    </div>

                    <>
                      {
                        isItMyPage
                        &&
                        <>
                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => handleEditContactInfo(mobile)}>
                            <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                          </div>

                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteContactInfo(mobile._id)}>
                            <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                          </div>
                        </>
                      }
                    </>
                  </div>
                ))
              }
            </div>
          </div>

          <hr className='hr bg-secondary-subtle ms-3' />

          {/* Email */}
          <div className='ms-3'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='bg-secondary-subtle d-inline-block px-3 py-1 rounded-3'>
                <img src={`/images/business/icons/mail.svg`} className='me-2' style={{ height: '25px' }} alt="mobile" />
                <span>Email</span>
              </div>

              {
                isItMyPage
                &&
                <img src={`/images/business/icons/add-circle.svg`} style={{ height: '25px' }} onClick={() => setShowContactInfoEmailPopup(true)} alt="add-circle" />
              }
            </div>

            <div className='ms-3'>
              {
                pageAbout.contactInfo.email.length > 0
                &&
                pageAbout.contactInfo.email.map((email, index) => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <div className='flex-grow-1'>
                      <p className='text-secondary'>{email.name}</p>
                      <p className='text-primary border-bottom'>{email.email}</p>
                    </div>

                    <>
                      {
                        isItMyPage
                        &&
                        <>
                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => handleEditContactInfo(email)}>
                            <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                          </div>

                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteContactInfo(email._id)}>
                            <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                          </div>
                        </>
                      }
                    </>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        {/* Dealer */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='mb-0 fw-semibold'>Dealer</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} style={{ height: '25px' }} onClick={() => setShowDealerPopup(true)} alt="add-circle" />
            }
          </div>

          <hr className='hr bg-secondary-subtle' />

          <div className='ms-3'>
            {
              pageAbout.dealer.length > 0
              &&
              pageAbout.dealer.map((dealer, index) => (
                <div className='d-flex align-items-center mb-3' key={index}>
                  <img src={`/images/business/icons/dealer-man.svg`} className='me-3' style={{ height: '30px' }} alt="dealer-man" />

                  <div className='border-bottom flex-grow-1'>
                    <p>{dealer.name}</p>
                    <p className='text-primary'>{dealer.website}</p>
                  </div>

                  <>
                    {
                      isItMyPage
                      &&
                      <>
                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => handleEditPageDealer(dealer)}>
                          <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                        </div>

                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteDealer(dealer._id)}>
                          <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                        </div>
                      </>
                    }
                  </>
                </div>
              ))
            }
          </div>
        </div>

        {/* Website & Email */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Website */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Website</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowWebsiteEmailPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <hr className='hr bg-secondary' />

            <>
              {
                pageAbout.websiteLink
                &&
                <div className='ms-3 mb-5 d-flex align-items-center'>
                  <img src={`/images/business/icons/link.svg`} className='me-3' style={{ height: '20px' }} alt="link" />

                  <span className='text-primary border-bottom flex-grow-1'>{pageAbout.websiteLink}</span>
                </div>
              }
            </>
          </>

          {/* Email */}
          <>
            <h6 className='mb-0 fw-semibold'>Email</h6>

            <hr className='hr bg-secondary' />

            <div className='ms-3 d-flex align-items-center'>
              <img src={`/images/business/icons/mail.svg`} className='me-3' style={{ height: '20px' }} alt="mail" />

              <span className='text-primary border-bottom flex-grow-1'>{pageAbout.email}</span>
            </div>
          </>
        </div>

        {/* Milestone */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Milestone</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} style={{ height: '25px' }} onClick={() => setShowCreateMilestonePopup(true)} alt="add-circle" />
            }
          </div>

          <hr className='hr bg-secondary' />

          <div className='business-milestone'>
            {
              pageAbout.milestone.length > 0 
              &&
              pageAbout.milestone.map((value, index) => (
                <div className='p-3 bg-white rounded-3 shadow-sm' style={{ maxWidth: '300px' }} onClick={() => handleClickMilestone(value)} key={index}>
                  <img src={AwsFileUrl+value.url} className='w-100 rounded-3' style={{ maxHeight: '150px' }} alt="milestoen" />

                  <div className='my-2'>
                    <h6 className='fw-semibold d-inline me-2'>{value.heading}</h6>
                    <span className='badge bg-primary'>{value.date}</span>
                  </div>

                  <span className='text-secondary text-truncate text-wrap' style={{ maxHeight: '145px' }}>{value.description}</span>
                </div>
              ))
            }
          </div>
        </div>

        {/* Active Hours */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Active Hours</h6>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => setShowActiveHoursPopup(true)}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <hr className='hr bg-secondary mb-4' />

          <div className='ms-3'>
            <div className='d-flex align-items-center'>
              <img src={`/images/business/icons/active-hours.svg`} className='me-3' style={{ height: '17px' }} alt="active hours" />

              <span className='flex-grow-1 border-bottom'>
                <span className='text-capitalize'>{pageAbout.activeHours.startDay} - {pageAbout.activeHours.endDay}</span>, {pageAbout.activeHours.startTime} - {pageAbout.activeHours.endTime}
              </span>
            </div>
          </div>
        </div>

        {/* Account Info */}
        <div className='px-3 px-sm-4 px-md-5 py-3 py-sm-4 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div>
              <h6 className='mb-0 fw-semibold'>Account Info</h6>
              <small><span className='text-primary'>eMilo</span> is showing information to help you understand the purpost of this page.</small>
            </div>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => toast.success('Click')}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <hr className='hr bg-secondary mb-4' />

          <div>
            {/* Page Id */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Page Id</small>
                <span className='fw-semibold'>123456789</span>
              </div>
            </div>

            {/* Page Creation Date */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Page Creation Date</small>
                <span className='fw-semibold'>May 01, 2024</span>
              </div>
            </div>

            {/* Admin Info */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Admin Info</small>
                <span className='fw-semibold'>Nov 06, 2000</span>
              </div>
            </div>

            {/* Organizers */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>Organizers that manage this page</small>
                <span className='fw-semibold border-bottom mb-2 d-block'>Meraki International Operations Japan M.I.O.S. is responsible for this page</span>
                <small className='text-secondary d-block'>This is a person or organization that has complete our verification process and claimed responsibility for this page.</small>
              </div>
            </div>

            {/* Page Verification */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>Page Verification</small>
                <span className='fw-semibold border-bottom d-block'>Verified</span>
                <small className='text-secondary d-block my-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam dolores ad optio quidem necessitatibus quas eaque, reprehenderit repellendus quo quisquam officiis mollitia esse autem est commodi sint omnis dignissimos? Quae?</small>
                <span className='fw-semibold d-block'>Meraki International Operations Japan M.I.O.S.</span>
              </div>
            </div>

            {/* History */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>History</small>

                <span className='fw-semibold'>Changed name to Meraki</span>
                <small className='text-secondary d-block border-bottom mb-3'>Dec 11, 2010</small>

                <span className='fw-semibold'>Created - Meraki Global</span>
                <small className='text-secondary d-block border-bottom'>Nov 06, 2000</small>
              </div>
            </div>

            {/* Report Page */}
            <div className='d-flex gap-3 align-items-center'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <h6 className='fw-semibold mb-0'>Report Page</h6>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
};

export default BusinessHomeAbout;