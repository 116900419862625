import React, { useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-number-input';
import { checkNumber } from '../UseContext/ApiHelpers';
import toast from 'react-hot-toast';
import axios from 'axios';
import { ApiContext } from '../UseContext/ApiCall';
import Countdown from 'react-countdown-now';

const AfterGooglePopup = ({ showAfterGooglePopup, setShowAfterGooglePopup }) => {

  const [afterGooglePopupPin, setAfterGooglePopupPin] = useState('');
  const [msg91otp, setMsg91otp] = useState('');

  const [resendTimer, setResendTimer] = useState(59);


  const [showOtpArea, setShowOtpArea] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberMessage, setNumberMessage] = useState(false);
  const [disableOTP, setdisableOTP] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);

  console.log('nm : ', numberMessage, " otp : ", disableOTP, " btn : ", btnDisable);

  const [pendingData, setPendingData] = useState({
    gender: '',
    dob: '',
    mobile: '',
  });

  const { userInfo, updateUserProfile } = useContext(ApiContext);

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  // To handle after google popup form submit.
  const handleAfterGoogleFromSubmit = () => {
    if (msg91otp !== '' && afterGooglePopupPin === String(msg91otp)) {
      updateUserProfile(pendingData.gender, pendingData.dob, pendingData.mobile)
      setShowAfterGooglePopup(false);
    } else {
      toast.error("invalid otp");
    };
  };

  const handlePhoneChange = async (value) => {
    setPhoneNumber(value);

    setPendingData({ ...pendingData, mobile: value })

    checkNumber(value).then((res) => {
      if (res === "mobile number already register") {
        if (phoneNumber && phoneNumber.length > 9) {
          setNumberMessage("mobile number already registered");
          setdisableOTP(true)
        } else {
          setNumberMessage("");
          setdisableOTP(false)
        }
      } else {
        setNumberMessage("");
        setdisableOTP(false)
      };
    });
  };

  const sendMobileotp = async () => {
    if (phoneNumber.length < 10) {
      toast.error("enter valid mobile number")
    }
    else {
      try {
        phoneNumber &&
          await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: phoneNumber }).then((res) => {
            toast.success(res.data.message);
            setbtnDisable(false);
            setShowOtpArea(true);
            setMsg91otp(res.data.otp);
          })
      } catch (error) {
        toast.error("failed to send otp")
        setbtnDisable(false)
      }
    }
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <span style={{ cursor: "pointer" }} onClick={() => reSendOtp()}>Resend OTP</span>;
    } else {
      return <span style={{ opacity: 0.75 }}> {`Resend OTP in 00 : ${seconds < 10 ? `0${seconds}` : seconds}`}</span>;
    }
  };

  const reSendOtp = async () => {
    try {
      await axios.post("https://emilonode.sjain.io/user/otp-send", { mobile: (phoneNumber || userInfo.phoneNumber) }).then((res) => {
        toast.success(res.data.message);
        setResendTimer(59);
        setMsg91otp(res.data.otp);
      })
    } catch (error) {
      toast.error("failed to send otp")
    }
  }

  return (
    <>
      <section className='afterGooglePopups'>
        <Modal show={showAfterGooglePopup} onHide={() => setShowAfterGooglePopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='afterGooglePopup'>
              <h6 className='afterGooglePopup-head'>Please Fill Require Data</h6>

              <form className='afterGooglePopup-form'>
                {/* Gender */}
                <div className='afterGooglePopup-form-gender'>
                  <span>
                    <input type="radio" id='male' value="Male" name="gender" checked={pendingData.gender === 'Male'} onChange={(e) => setPendingData({ ...pendingData, gender: e.target.value })} />
                    <label htmlFor="male"> Male</label>
                  </span>

                  <span>
                    <input type="radio" id='female' value="Female" name="gender" checked={pendingData.gender === 'Female'} onChange={(e) => setPendingData({ ...pendingData, gender: e.target.value })} />
                    <label htmlFor="female"> Female</label>
                  </span>

                  <span>
                    <input type="radio" id='other' value="Other" name="gender" checked={pendingData.gender === 'Other'} onChange={(e) => setPendingData({ ...pendingData, gender: e.target.value })} />
                    <label htmlFor="other"> Other</label>
                  </span>
                </div>

                {/* DOB */}
                <div className='afterGooglePopup-form-dob'>
                  <lael htmlFor="dob">DOB </lael>
                  <input type="date" name="dob" id="dob" placeholder='dd/mm/yyyy' max={formattedDate} onChange={(e) => setPendingData({ ...pendingData, dob: e.target.value })} />
                </div>

                {/* Mobile */}
                <label htmlFor="mobile" className='mobile_label'>
                  {/* <input type="tel" className='afterGooglePopup-form-mobile' name="mobile" id="mobile" pattern='[0-9]{10}' placeholder='+917415789685...' /> */}

                  <PhoneInput className="afterGooglePopup-form-mobile" value={phoneNumber} onChange={handlePhoneChange} defaultCountry="IN" placeholder="Enter phone number" />

                  <div className='text-center w-100'>

                    <span className='afterGooglePopup-form-sendOtp' onClick={sendMobileotp}>Send otp</span>
                  </div>

                </label>


                {/* OTP */}
                {
                  showOtpArea
                  &&
                  <div className='afterGooglePopup-form-pin'>
                    <OtpInput
                      value={afterGooglePopupPin}
                      onChange={setAfterGooglePopupPin}
                      numInputs={4}
                      renderSeparator={<pre> </pre>}
                      renderInput={(props) => <input {...props} className='afterGooglePopup-pin' />}
                    />

                    {/* <span>Resend otp in 01:00 seconds</span> */}
                    <span className="resendOTP">Didn’t get the OTP? <b><Countdown date={Date.now() + resendTimer * 1000} renderer={renderer} /></b></span>

                  </div>
                }

                {/* Done */}
                {
                  pendingData.gender && pendingData.dob && pendingData.mobile
                  &&
                  <span className='afterGooglePopup-form-done' onClick={handleAfterGoogleFromSubmit}>Done</span>
                }
              </form>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default AfterGooglePopup
