import React, { useContext, useEffect } from 'react';
import { Outlet, useParams} from 'react-router-dom';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { businessContext } from '../UseContext/BusinessContext';


const BusinessHome1 = () => {

  const location = useLocation();
  const navigate = useNavigate(); 
  
  const { pageId } =  useParams();

  const { 
    pages,
    setParamsPageId,
    getPageDetails,
    getPages,
  } = useContext(businessContext);  

  const homePaths =  [ 
    `/business/${pageId}`,
    `/business/about/${pageId}`,
    `/business/product-services/${pageId}`,
    `/business/tagged/${pageId}`,
    `/business/reviews/${pageId}`,
    `/business/listings/${pageId}`
  ];

  useEffect(() => {
    setParamsPageId(pageId);
    getPageDetails(pageId);
    getPages(pageId);
  }, [pageId]);

  return (
    <>
      <section className='vh-100 vw-100 overflow-y-scroll scrollbar-hidden d-flex'>
        {/* Left Block */}
        <div className='business-lb h-100 overflow-y-scroll scrollbar-hidden' style={{ backgroundColor: '#f7f7f7' }}>
          {/* Logo */}
          <div className='text-center mt-4'>
            <img src={`${BASE_URL_STATIC}/logo.png`} onClick={() => navigate(`/`)} style={{ width: '125px' }} alt="logo" />
          </div>

          {/* Accordion */}
          <div className='my-5'>
            <div className='accordion accordion-flush w-75'>
              <div className='mb-3 position-relative'>
                {/* Opened */}
                <>
                  {
                    pages.businessPages.length > 0
                    &&
                    pages.businessPages.map((page, index) => (
                      <>
                        {
                          page._id === pageId
                          &&
                          <div className='accordion-level-1 shadow-sm accordion-button collapsed px-3 py-2 rounded-4' data-bs-toggle="collapse" data-bs-target="#business-lb-profile" key={index}>
                            <img src={AwsFileUrl+page.imageId} className='border rounded-circle object-fit-contain' style={{ height: '40px', width: '40px' }} alt="profile" />

                            <div className='ms-3'>
                              <h6 className='text-dark fw-semibold mb-0'>{page.fname}</h6>
                              <small className='text-secondary'>Edit Profile</small>
                            </div>
                          </div>
                        }
                      </>
                    ))
                  }
                </>

                <div id='business-lb-profile' className="accordion-collapse collapse accordion accordion-flush my-0 position-absolute bg-white rounded-3 shadow-sm mt-2 w-100 p-2" data-bs-parent="#privacyToggle1">
                  <div className='fw-semibold ps-3'>Your Accounts</div>
                  {/* Selected */}
                  <>
                  {
                    pages.businessPages.length > 0
                    &&
                    pages.businessPages.map((page, index) => (
                      <>
                        {
                          page._id === pageId
                          &&
                          <div className='d-flex align-items-center my-3 py-1 px-3 bg-primary-subtle rounded-3 cursor-pointer' key={index}>
                            <img src={AwsFileUrl+page.imageId} className='border rounded-circle me-3 object-fit-contain' style={{ height: '40px', width: '40px' }} alt="profile" />

                            <div>
                              <h6 className='fw-semibold mb-0'>{page.fname}</h6>
                              <small className='text-secondary'>3 Ad Account</small>
                            </div>
                          </div>
                        }
                        </>
                      ))
                    }
                  </>

                  {/* Others */}
                  <>
                    {
                      pages.businessPages.length > 0
                      &&
                      pages.businessPages.map((page, index) => (
                        <>
                          {
                            page._id !== pageId
                            &&
                            <div className='d-flex align-items-center py-1 px-3 cursor-pointer mb-3' onClick={() => navigate(`/business/${page._id}`)} key={index}>
                              <img src={AwsFileUrl+page.imageId} className='border rounded-circle me-3 object-fit-contain' style={{ height: '40px', width: '40px' }} alt="profile" />

                              <div>
                                <h6 className='fw-semibold mb-0'>{page.fname}</h6>
                                <small className='text-secondary'>2 Ad Account</small>
                              </div>
                            </div>
                          }
                        </>
                      ))
                    }
                  </>
                </div>
              </div>
            </div>
          </div>

          {/* Home */}
          <div onClick={() => navigate(`/business/${pageId}`)} 
            className={`${homePaths.includes(location.pathname) && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}
          >
            <img src={`/images/business/icons/lb-home.svg`} className='me-3' style={{ width: '25px' }} alt="logo" />
            <span className='d-block text-dark'>Home</span>
          </div>

          {/* Dashboard */}
          <div onClick={() => navigate(`/business/dashboard/${pageId}`)} className={`${location.pathname === `/business/dashboard/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-dashboard.svg`} className='me-3' style={{ width: '23px' }} alt="logo" />
            <span className='d-block text-dark'>Dashboard</span>
          </div>

          {/* Insights */}
          <div onClick={() => navigate(`/business/insights/${pageId}`)} className={`${location.pathname === `/business/insights/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-insights.svg`} className='me-3' style={{ width: '20px' }} alt="logo" />
            <span className='d-block text-dark'>Insights</span>
          </div>

          {/* Ads */}
          <div onClick={() => navigate(`/business/ads/${pageId}`)} className={`${location.pathname === `/business/ads/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-ads.svg`} className='me-3' style={{ width: '20px' }} alt="logo" />
            <span className='d-block text-dark'>Ads</span>
          </div>

          {/* Events */}
          <div onClick={() => navigate(`/business/events/${pageId}`)} className={`${location.pathname === `/business/events/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-events.svg`} className='me-3' style={{ width: '20px' }} alt="logo" />
            <span className='d-block text-dark'>Events</span>
          </div>

          {/* My Events */}
          <div onClick={() => navigate(`/business/my-events/${pageId}`)} className={`${location.pathname === `/business/my-events/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-my-events.svg`} className='me-3' style={{ width: '20px' }} alt="logo" />
            <span className='d-block text-dark'>My Events</span>
          </div>

          {/* Account Setting */}
          <div onClick={() => navigate(`/business/account-setting/${pageId}`)} className={`${location.pathname === `/business/account-setting/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-account-setting.svg`} className='me-3' style={{ height: '25px', width: '25px' }} alt="logo" />
            <span className='d-block text-dark'>Account Setting</span>
          </div>

          {/* Themes */}
          {/* <div onClick={() => navigate(`/business/themes/${pageId}`)} className={`${location.pathname === `/business/themes/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`${BASE_URL_STATIC}/menu-icon1.png`} className='me-3' style={{ height: '25px', width: '25px' }} alt="logo" />
            <span className='d-block text-dark'>Themes</span>
          </div> */}

          {/* Help */}
          <div onClick={() => navigate(`/business/help/${pageId}`)} className={`${location.pathname === `/business/help/${pageId}` && 'bg-primary-subtle shadow-sm'} d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-help.svg`} className='me-3' style={{ height: '25px', width: '25px' }} alt="logo" />
            <span className='d-block text-dark'>Help</span>
          </div>

          {/* Logout */}
          <div onClick={() => toast.success('Logout')} className={`d-flex w-75 mx-auto py-2 ps-5 rounded-3 mb-3 cursor-pointer`}>
            <img src={`/images/business/icons/lb-logout.svg`} className='me-3' style={{ height: '25px', width: '25px' }} alt="logo" />
            <span className='d-block text-dark'>Logout</span>
          </div>
        </div>        

        {/* Right Block */}
        <Outlet />
      </section> 
    </>
  );
};

export default BusinessHome1;