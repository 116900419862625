import React, { useContext, useEffect } from 'react';
import Slider from "react-slick";
import { ApiContext } from '../../../UseContext/ApiCall';
import CreateStoriesPopup from '../../../Popup/Home/CenterBlock/Stories/CreateStoriesPopup';
import ViewStoriesPopup from '../../../Popup/Home/CenterBlock/Stories/ViewStoriesPopup';
import { AwsFileUrl } from "../../../../../config";
import { useNavigate } from 'react-router-dom';
import StoryCircle from './StoryCircle';

const Stories = () => {
  const navigate = useNavigate();

  const settings = {
    slidesToShow: 7,
    infinite: false,
    initialSlide: 0,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 7,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        }
      }
    ]
  };

  const {
    setshowCreateStoriesPopup, setShowViewStoriesPopup,
    loggedInUserId, loggedInUserImage,
    getUserStories, getStoriesFollowingUsers,
    storiesFollowingUsers,
  } = useContext(ApiContext);

  // Function to map story type to color
  const getStoryRingColor = (mediaPosts) => {
    const colorMap = {
      'text': 'blue',
      'post': 'blue',
      'flix': 'red',
      'memes': 'orange',
      'bestclips': 'green' // Add more types if needed
    };

    // Collect unique colors based on mediaPosts type
    const colors = mediaPosts && mediaPosts.length > 0 && mediaPosts.map(post => colorMap[post.type] || 'gray'); // Default to gray for unknown types
    return Array.from(new Set(colors)); // Remove duplicates
  };

  const getImageUrl = (data) => {
    
    let b = data[data.length - 1]
    let c = b.mediaUrls[0]

    if (c.filetype === 'image') {
      return  c.url
    } else {
      return  b.postTumbnailUrl
    }
  }

  useEffect(() => {
    getStoriesFollowingUsers();
  }, []);

  return (
    <>
      <CreateStoriesPopup />
      <ViewStoriesPopup />

      <section className='stories px-2 px-sm-3 px-lg-0 my-3 mt-md-4 mt-lg-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <span className='fw-bold'>Stories</span>
          <small className='text-primary'>See all</small>
        </div>

        <div className='stories-slider'>
          <Slider {...settings}>
            {/* Logged In User Stories */}
            <div className='story me-1'>
              <div className='story-image position-relative'>
                <img
                  src={AwsFileUrl + loggedInUserImage}
                  className='story-img mx-auto mt-2 cursor-pointer object-fit-cover'
                  onClick={() => { setShowViewStoriesPopup(true); getUserStories(loggedInUserId) }}
                  alt='profile'
                />
                <img
                  src={`/images/icons/add-white.svg`}
                  className='story-add mx-auto position-relative bg-primary'
                  onClick={() => setshowCreateStoriesPopup(true)}
                  alt='profile'
                />
              </div>
              <span className='story-name'>Your Story</span>
            </div>

            {/* Other User Stories */}
            {storiesFollowingUsers && storiesFollowingUsers.length > 0 && storiesFollowingUsers.map((story, index) => (
              <div key={index} className='story' onClick={() => getUserStories(story._id)}>
                <div className='story-image'>
                  {/* Dynamic story ring with all media types */}
                  <StoryCircle colors={getStoryRingColor(story.mediaPosts)} imageUrl={story.mediaPosts && getImageUrl(story.mediaPosts)} />
                  <img
                    src={AwsFileUrl + story.imageId}
                    className='story-profile position-relative cursor-pointer object-fit-cover'
                    onClick={() => setShowViewStoriesPopup(true)}
                    alt='profile'
                    style={{left : "19px"}}
                  />
                </div>
                <span className='story-name cursor-pointer text-truncate' onClick={() => navigate(`/profile/${story._id}`)}>
                  {story.name}
                </span>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Stories;
