import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { BASEURL, BASE_URL_STATIC } from "../../../../../../config";
import toast from "react-hot-toast";
import EditMemePopup from "./EditPost/EditMemePopup";
import VisibilityMemePopup from "./Visibility/VisibilityMemePopup";
import ReportMeme from "../../../Report/ReportMeme";
import ServicePost from "../../../Service/ServicePost";
import { profileContext } from "../../../../UseContext/ProfileContext";

const ProfileMemeThreeDotPopup = ({ iframeCode, showProfileMemeThreeDotPopuop, setshowProfileMemeThreeDotPopuop, setShowProfileMemesPopup, profileMemePopupData, setProfileMemePopupData }) => {

  const [isNotificationSet, setIsNotificationSet] = useState(false);
  const [isIntrested, setIsIntrested] = useState(false);

  const [showEditMemePopup, setShowEditMemePopup] = useState(false);
  const [showVisibilityMemePopup, setShowVisibilityMemePopup] = useState(false);

  const { config, loggedInUserId,
    checkFavouriteUser, isFavouriteUser, handleCreateFavouriteUser,
    checkBothFollow, showAddCloseFriend, checkCloseFriend, handleCreateCloseFriend, isCloseFriend,
    putSnooze, checkSnoozeUser, isUserSnooze,
    handleHideMeme,
    checkBlocked, handleBlockUnblock, isBlocked,
    setShowReportMemePopup, setShowServicePopup,
    isUnfollow, checkUnfollow, handleUnfollow,
  } = useContext(ApiContext);

  // console.log(profileMemePopupData);

  const {setProfileMemeData} = useContext(profileContext)

  // Delete Meme
  const deleteProfileMeme = (memeId, config) => {
    axios.post('https://emilonode.sjain.io/post/deleteusermeme', { memeId: memeId }, config)
      .then(res => {
        // console.log(res.data.message);
        setshowProfileMemeThreeDotPopuop(false);
        setShowProfileMemesPopup(false);
      })
      .catch(err => {
        return null;
      });
  };

  useEffect(() => {
    if (profileMemePopupData) {
      checkFavouriteUser(profileMemePopupData.userId, config);
      checkBothFollow(profileMemePopupData.userId, config);
      checkCloseFriend(profileMemePopupData.userId, config);
      checkSnoozeUser(profileMemePopupData.userId, config);
      checkBlocked(profileMemePopupData.userId);
      checkUnfollow(profileMemePopupData.userId);
      setProfileMemeData(profileMemePopupData)
    };
  }, [profileMemePopupData]);

  return (
    <>
      {/* Edit Meme Popup */}
      <EditMemePopup showEditMemePopup={showEditMemePopup} setShowEditMemePopup={setShowEditMemePopup} setshowProfileMemeThreeDotPopuop={setshowProfileMemeThreeDotPopuop} profileMemePopupData={profileMemePopupData} setProfileMemePopupData={setProfileMemePopupData} />

      {/* Visibility Meme Popup */}
      <VisibilityMemePopup showVisibilityMemePopup={showVisibilityMemePopup} setShowVisibilityMemePopup={setShowVisibilityMemePopup} />

      {/* Report Meme */}
      <ReportMeme profileMemePopupData={profileMemePopupData} />

      {/* Report Service */}
      <ServicePost type='meme' />



      {
        profileMemePopupData
          &&
          profileMemePopupData.userId === loggedInUserId
          ?
          // It will show to logged in user popup
          <Modal show={showProfileMemeThreeDotPopuop} onHide={() => setshowProfileMemeThreeDotPopuop(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body className="popupModal-body">
              {/* Add to album */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon5.png`} alt="" />
                </div>
                <div className="textBox">
                  Add to album
                  <span>Add image to album.</span>
                </div>
              </div>

              {/* Edit */}
              <div className="linkBox" onClick={() => setShowEditMemePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="" />
                </div>
                <div className="textBox">
                  Edit Meme
                  <span>Update this meme.</span>
                </div>
              </div>

              {/* Visibility */}
              <div className="linkBox" onClick={() => setShowVisibilityMemePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon4.png`} alt="" />
                </div>
                <div className="textBox">
                  Visibility
                  <span>Want to change audience, who can see this meme.</span>
                </div>
              </div>

              {/* Notification */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                </div>
                <div className="textBox">
                  Don't show notification for this meme
                  <span>Do not notify when someone likes or comment to this meme.</span>
                </div>
              </div>

              {/* Copy link */}
              <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileMemePopupData.memeId}`} onCopy={() => toast.success('copied')}>
                <div className='linkBox' style={{ cursor: 'pointer' }}>
                  <div className="imgBox">
                    <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                  </div>
                  <div className="textBox">
                    Copy Link
                    <span>Copy this meme link & share it with your friend's and more.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Implant */}
              <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
                <div className='linkBox' style={{ cursor: 'pointer' }}>

                  <div className="imgBox">
                    {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                    <span className='implant-img'>{'</>'}</span>
                  </div>
                  <div className="textBox">
                    Implant
                    <span>Want to show this meme in your website.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Delete */}
              <div className="linkBox last" onClick={() => deleteProfileMeme(profileMemePopupData.memeId, config)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/chat-icon12.png`} alt="" />
                </div>

                <div className="textBox">
                  Delete Meme
                  <span>Want to delete this meme.</span>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          :
          profileMemePopupData
          &&
          // It will show to other user popup
          <Modal show={showProfileMemeThreeDotPopuop} onHide={() => setshowProfileMemeThreeDotPopuop(false)} size="md" centered backdrop="static" keyboard={false} className="main-modal" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body className="popupModal-body">
              <h5>Why am I seeing this meme? <div>Know more</div></h5>

              {profileMemePopupData.userId !== loggedInUserId && <h6>You follow <div style={{ textTransform: 'capitalize' }}>‘{profileMemePopupData.fname}’</div></h6>}

              {/* Add to favourites */}
              <>
                {
                  profileMemePopupData.userId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => handleCreateFavouriteUser(profileMemePopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                    </div>
                    {
                      isFavouriteUser
                        ?
                        <div className="textBox">
                          Add ‘{profileMemePopupData.fname}’ to favourites
                          <span>Add to your favourites if you want to see his/her meme more.</span>
                        </div>
                        :
                        <div className="textBox">
                          Remove ‘{profileMemePopupData.fname}’ from favourites
                          <span>Remove from your favourites. Want less meme from his/her.</span>
                        </div>
                    }
                  </div>
                }
              </>

              {/* Add to close friends */}
              <>
                {
                  (profileMemePopupData.userId !== loggedInUserId && !showAddCloseFriend)
                  &&
                  <div className="linkBox" onClick={() => handleCreateCloseFriend(profileMemePopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon1.png`} alt="" />
                    </div>
                    {
                      isCloseFriend
                        ?
                        <div className="textBox">
                          Add ‘{profileMemePopupData.fname}’ to close friend
                          <span>Add to your close friend if you want to see his/her meme more.</span>
                        </div>
                        :
                        <div className="textBox">
                          Remove ‘{profileMemePopupData.fname}’ from close friend
                          <span>Remove from close friend.</span>
                        </div>
                    }
                  </div>
                }
              </>

              {/* Set notification for user post */}
              <>
                {
                  profileMemePopupData.userId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => setIsNotificationSet(!isNotificationSet)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon9.png`} alt="" />
                    </div>

                    <div className="textBox">
                      {isNotificationSet ? 'Unset' : 'Set'} notification for ‘{profileMemePopupData.fname}’ meme
                      <span>Want to {isNotificationSet ? 'stop notifying' : 'get notify'}  when ‘{profileMemePopupData.fname}’ post something</span>
                    </div>
                  </div>
                }
              </>

              {/* Interested */}
              <div className="linkBox" onClick={() => setIsIntrested(!isIntrested)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" />
                </div>
                <div className="textBox">
                  {isIntrested ? 'Not Intrested' : 'Intrested'}
                  <span>Want {isIntrested ? 'less' : 'more'} meme like this to see in your feed.</span>
                </div>
              </div>

              {/* Copy Link */}
              {/* Copy Link */}
              {profileMemePopupData.memeId && (
                <div>
                  <CopyToClipboard text={`${BASEURL}/showCopyLink/${profileMemePopupData.memeId}`} onCopy={() => toast.success('copied')}>
                    <div className={profileMemePopupData.userId === loggedInUserId ? `linkBox last` : `linkBox`} style={{ cursor: 'pointer' }}>
                      <div className="imgBox">
                        <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                      </div>
                      <div className="textBox">
                        Copy Link
                        <span>Copy this meme link & share it with your friend's and more.</span>
                      </div>
                    </div>
                  </CopyToClipboard>
                </div>
              )}


              {/* Implant */}
              <CopyToClipboard text={iframeCode} onCopy={() => toast.success('implanted')}>
                <div className="linkBox">
                  <div className="imgBox">
                    {/* <img src={`${BASE_URL_STATIC}/popup-icon6.png`} alt="" /> */}
                    <span className='implant-img'>{'</>'}</span>
                  </div>
                  <div className="textBox">
                    Implant
                    <span>Want to show this meme in your website.</span>
                  </div>
                </div>
              </CopyToClipboard>

              {/* Put user snooze for 30 days */}
              <>
                {
                  (profileMemePopupData.userId !== loggedInUserId)
                  &&
                  <div className="linkBox" onClick={() => putSnooze(profileMemePopupData.userId, config)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="" />
                    </div>
                    <div className="textBox">
                      {
                        isUserSnooze
                          ?
                          `Put ${profileMemePopupData.fname} snooze for 30 Days`
                          :
                          `Remove ${profileMemePopupData.fname} from snooze`
                      }
                      <span>Stop seeing post for 30 days.</span>
                    </div>
                  </div>
                }
              </>

              {/* Hide This Meme */}
              <>
                {
                  profileMemePopupData.postId !== loggedInUserId
                  &&
                  <div className="linkBox" onClick={() => handleHideMeme(profileMemePopupData.memeId)}>
                    <div className="imgBox">
                      <img src={`${BASE_URL_STATIC}/popup-icon5.png`} alt="" />
                    </div>
                    <div className="textBox">
                      Hide This meme
                      <span>Don't want to see this meme</span>
                    </div>
                  </div>
                }
              </>

              {/* Unfollow */}
              <div className="linkBox" onClick={() => handleUnfollow(profileMemePopupData.userId)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
                </div>
                <div className="textBox">
                  {isUnfollow ? 'Follow' : 'Unfollow'} ‘{profileMemePopupData.fname}’
                  <span>Stop seeing {profileMemePopupData.fname} meme.</span>
                </div>
              </div>

              {/* Report */}
              <div className="linkBox" onClick={() => setShowReportMemePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon7.png`} alt="" />
                </div>
                <div className="textBox">
                  Report Meme
                  <span>Do you think this meme is wrong, please let us know.</span>
                </div>
              </div>

              {/* Report Service */}
              <div className="linkBox" onClick={() => setShowServicePopup(true)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/pen.jpg`} alt="pen" />
                </div>
                <div className="textBox">
                  Report Service
                  <span>Any suggestion or improvement you think to do.</span>
                </div>
              </div>

              {/* Use template */}
              <div className="linkBox">
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon8.png`} alt="" />
                </div>
                <div className="textBox">
                  Use Template
                  <span>Want to create meme using this image.</span>
                </div>
              </div>

              {/* Block */}
              <div className="linkBox last" onClick={() => handleBlockUnblock(profileMemePopupData.userId)}>
                <div className="imgBox">
                  <img src={`${BASE_URL_STATIC}/popup-icon3.png`} alt="" />
                </div>
                <div className="textBox">
                  {isBlocked ? 'Unblock' : 'Block'} ‘{profileMemePopupData.fname}’
                  <span>Stop seeing or contact each other.</span>
                </div>
              </div>
            </Modal.Body>
          </Modal>
      }
    </>
  )
}

export default ProfileMemeThreeDotPopup
