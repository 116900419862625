import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../../config';

const VoiceCallComingPopup = () => {

  const [voiceCallComingPopup, setVoiceCallComingPopup] = useState(false);
  
  return (
    <>
      <section className='voiceCallComingPopups'>
      <Modal show={voiceCallComingPopup} onHide={() => setVoiceCallComingPopup(false)} className="main-modal voiceCallComingPopup-modal" size="md" centered backdrop="static" keyboard={false}  >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body voiceCallComingPopup-modal-body">
            <section className='voiceCallComingPopup'>
            <div className='voiceCallComingPopup-logo'>
                <img src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
              </div>

              <div className='voiceCallComingPopup-profile'>
                <img className='voiceCallComingPopup-profile-img' src="https://filmfare.wwmindia.com/content/2023/apr/shahidkapoor11681969665.jpg" alt="profile" />
              </div>

              <h3 className='voiceCallComingPopup-name'>Sahid Kapoor</h3>

              <span className='voiceCallComingPopup-calling'>Incoming Call...</span>

              <div className='voiceCallComingPopup-btns'>
                <div className='voiceCallComingPopup-attend'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneAttend.svg`} alt="phoneAttend" />
                </div>
                
                <div className='voiceCallComingPopup-cut'>
                  <img src={`${BASE_URL_STATIC}/icon/phoneCut.svg`} alt="phoneCut" />
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section> 
    </>
  )
}

export default VoiceCallComingPopup
