import React, { useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';
import { settingContext } from '../../../UseContext/SettingContext';
import { getAdded, getValue } from '../../../UseContext/SettingHelper';
import SettingInputUser from '../../../Profile/ProfileUser/ProfileUserSetting/SettingInputUser';



const MemeAdvanceSettingPopup = ({
  showMemeAdvaceSettingPopup, setShowMemeAdvaceSettingPopup,
  setShowCreateMemesPopup2,
}) => {

  const { config } = useContext(ApiContext);
  const { value, setValue, handleOptionChange, setAdded, isAccountPrivate } = useContext(settingContext);

  // Messages to show according to option
  const message = {
    public: 'Everyone on EMILO can',
    closeFriends: 'Only your close friends can',
    followers: 'Only your followers can',
    fof: 'Your followers & as well as their followers can',
    none: 'No one can',
  };

  // To get privacy Added (Value, Followers Except and Hide From )
  useEffect(() => {
    // To get Value
    getValue('privacy', config)
    .then(res => {
      // console.log('res : ', res.data.message);

      setValue(prevState => ({
        ...prevState,
        // ...res.data.message,
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });

    // To get (Followers Except, Hide From, Block Comments Users and Others)
    getAdded('privacy', config)
    .then(res => {

      // Update state with the fetched data
      setAdded(prevState => ({
        ...prevState,
        // ...res.data.message
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  }, []);

  return (
    <>
      <Modal show={showMemeAdvaceSettingPopup} onHide={() => {setShowMemeAdvaceSettingPopup(false); setShowCreateMemesPopup2(true);}} className='create-post m-0' backdrop="static" centered >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section>
            <div className='d-flex'>
              <span className='cursor-pointer' onClick={() => {
                setShowMemeAdvaceSettingPopup(false); 
                setShowCreateMemesPopup2(true)
              }}>⮜</span>
              <h4 className='mx-auto text-primary'>Advance Setting</h4>
            </div>

            <div className="accordion accordion-flush overflow-y-scroll pe-1 my-4" style={{ maxHeight: '50vh' }}>
              {/* Audience Control */}
              <div>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-audienceControl">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Audience Control</h5>
                </div>

                <div id='meme-audienceControl' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* View */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#meme-audienceControl-view">Who can view your memes?</h5>

                    <div id='meme-audienceControl-view' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeView-public" name="memeView" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.memeView || value.memeView === 'public'}  
                            disabled={isAccountPrivate === 'private'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeView-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeView-followers" name="memeView" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.memeView === 'followers'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeView-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeView-follower" name="memeView" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.memeView === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeView-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeView-fof" name="memeView" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.memeView === 'fof'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeView-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.memeView ? value.memeView : 'public']} view your meme)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.memeView) || (value.memeView === 'public') || (value.memeView === 'followers') || (value.memeView === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.memeView || value.memeView === 'public') ? 'memeViewPublic' : 
                                value.memeView === 'followers' ? 'memeViewFollowers' : 
                                value.memeView === 'fof' && 'memeViewFOF'
                              }
                              settingType={value.memeView ? value.memeView : 'public'} 
                              header={
                                (!value.memeView || value.memeView === 'public') ? 'Hide meme from specific people' :
                                value.memeView === 'followers' ? 'Followers Except' :
                                value.memeView === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your meme' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Likes & Comments */}
              <div className='my-3'>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-likeAndComments">
                  <img src={`${BASE_URL_STATIC}/icon/like-comment.svg`} alt="like-comment" />
                  <h5 className='fw-semibold ms-3 mb-0'>Likes & Comments</h5>
                </div>

                <div id='meme-likeAndComments' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Like Count */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center my-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0'>Hide like count</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeLike' value={value.memeLike === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.memeLike === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <small className='text-secondary ms-3 mt-3 d-block'>(Confirm if you want to hide the like counts of your meme)</small>
                  </div>

                  <hr className='hr bg-secondary ms-3' style={{ width: '-webkit-fill-available' }} />

                  {/* Comments */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#meme-likeAndComments-comments">Who can comment on your memes?</h5>

                    <div id='meme-likeAndComments-comments' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeComment-public" name="memeComment" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.memeComment || (!value.memeComment && value.memeView === 'public') || value.memeComment === 'public'}  
                            disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeComment-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeComment-followers" name="memeComment" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeComment && value.memeView === 'followers') || value.memeComment === 'followers'} 
                            disabled={value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeComment-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeComment-follower" name="memeComment" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeComment && value.memeView === 'closeFriends') || value.memeComment === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeComment-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeComment-fof" name="memeComment" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeComment && value.memeView === 'fof') || value.memeComment === 'fof'} 
                            disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeComment-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.memeComment ? value.memeComment : 'public']} comment on your meme)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.memeComment) || (value.memeComment === 'public') || (value.memeComment === 'followers') || (value.memeComment === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.memeComment || value.memeComment === 'public') ? 'memeCommentPublic' : 
                                value.memeComment === 'followers' ? 'memeCommentFollowers' : 
                                value.memeComment === 'fof' && 'memeCommentFOF'
                              }
                              settingType={value.memeComment ? value.memeComment : 'public'} 
                              header={
                                (!value.memeComment || value.memeComment === 'public') ? 'Hide meme comment from specific people' :
                                value.memeComment === 'followers' ? 'Followers Except' :
                                value.memeComment === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your meme comment' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Share & Download */}
              <div className='my-3'>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload">
                  <img src={`${BASE_URL_STATIC}/icon/share-download.svg`} alt="share-download" />
                  <h5 className='fw-semibold ms-3 mb-0'>Share & Download</h5>
                </div>

                <div id='meme-shareAndDownload' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* Share */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload-share">Who can share your memes?</h5>

                    <div id='meme-shareAndDownload-share' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeShare-public" name="memeShare" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.memeShare || (!value.memeShare && value.memeView === 'public') || value.memeShare === 'public'}  
                            disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeShare-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeShare-followers" name="memeShare" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeShare && value.memeView === 'followers') || value.memeShare === 'followers'} 
                            disabled={value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeShare-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeShare-follower" name="memeShare" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeShare && value.memeView === 'closeFriends') || value.memeShare === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeShare-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeShare-fof" name="memeShare" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeShare && value.memeView === 'fof') || value.memeShare === 'fof'} 
                            disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeShare-fof">Followers of Followers</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.memeShare ? value.memeShare : 'public']} share your meme)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.memeShare) || (value.memeShare === 'public') || (value.memeShare === 'followers') || (value.memeShare === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.memeShare || value.memeShare === 'public') ? 'memeSharePublic' : 
                                value.memeShare === 'followers' ? 'memeShareFollowers' : 
                                value.memeShare === 'fof' && 'memeShareFOF'
                              }
                              settingType={value.memeShare ? value.memeShare : 'public'} 
                              header={
                                (!value.memeShare || value.memeShare === 'public') ? 'Hide meme share from specific people' :
                                value.memeShare === 'followers' ? 'Followers Except' :
                                value.memeShare === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your meme share' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>

                  {/* Download */}
                  <div className='ms-3 mt-3'>
                    <h5 className='accordion-level-4 shadow-sm mb-0' data-bs-toggle="collapse" data-bs-target="#meme-shareAndDownload-download">Who can download your memes?</h5>

                    <div id='meme-shareAndDownload-download' className='row accordion-collapse collapse show mt-3 ms-3 me-0'> 
                      {/* Value */}
                      <div className='row'>
                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeDownload-public" name="memeDownload" value='public' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={!value.memeDownload || (!value.memeDownload && value.memeView === 'public') || value.memeDownload === 'public'} 
                            disabled={value.memeView === 'followers' || value.memeView === 'fof' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeDownload-public">Public</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeDownload-followers" name="memeDownload" value='followers' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeDownload && value.memeView === 'followers') || value.memeDownload === 'followers'} 
                            disabled={value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeDownload-followers">Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeDownload-follower" name="memeDownload" value='closeFriends' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeDownload && value.memeView === 'closeFriends') || value.memeDownload === 'closeFriends'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeDownload-follower">Close Friends</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeDownload-fof" name="memeDownload" value='fof' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={(!value.memeDownload && value.memeView === 'fof') || value.memeDownload === 'fof'} 
                            disabled={value.memeView === 'followers' || value.memeView === 'closeFriends'}
                          />
                          <label className="form-check-label" htmlFor="ps-memeDownload-fof">Followers of Followers</label>
                        </div>

                        <div className="ms-2 mb-1 col-lg-5">
                          <input className="form-check-input" type="radio" id="ps-memeDownload-following" name="memeDownload" value='none' onChange={(e) => handleOptionChange(e, 'privacy')} 
                            checked={value.memeDownload === 'none'} 
                          />
                          <label className="form-check-label" htmlFor="ps-memeDownload-following">No one</label>
                        </div>

                        <small className='pt-1 text-secondary mb-3'>({message[value.memeDownload ? value.memeDownload : 'public']} download your meme)</small>
                      </div>

                      {/* Input */}
                      <>
                        {
                          ((!value.memeDownload) || (value.memeDownload === 'public') || (value.memeDownload === 'followers') || (value.memeDownload === 'fof'))
                          &&
                          <>
                            <hr className='hr bg-secondary mt-0 mb-3' style={{ width: '-webkit-fill-available' }} />

                            <SettingInputUser 
                              settingNav='privacy' 
                              settingKey={
                                (!value.memeDownload || value.memeDownload === 'public') ? 'memeDownloadPublic' : 
                                value.memeDownload === 'followers' ? 'memeDownloadFollowers' : 
                                value.memeDownload === 'fof' && 'memeDownloadFOF'
                              }
                              settingType={value.memeDownload ? value.memeDownload : 'public'} 
                              header={
                                (!value.memeDownload || value.memeDownload === 'public') ? 'Hide meme download from specific people' :
                                value.memeDownload === 'followers' ? 'Followers Except' :
                                value.memeDownload === 'fof' && 'Followers and their followers except'
                              }
                              message='You can select name of people whom you don’t want to show your meme download' 
                            />
                          </>
                        }
                      </>

                      <hr className='hr bg-secondary my-0' style={{ width: '-webkit-fill-available' }} />
                    </div>
                  </div>

                  {/* meme share to story */}
                  <div className='ms-3 mt-3'>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow sharing of your memes on stories</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeShareToStory' value={value.memeShareToStory === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.memeShareToStory === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='ms-4'>
                      <span>By default, your selected audience for the MEMES will be able to share your MEMES on their stories. However, if you want to disallow specific people from using your MEMES, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='memeShareToStory' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary' style={{ width: '-webkit-fill-available' }} />
                  </div>
                </div>
              </div>

              {/* Other Permission */}
              <div>
                <div className='accordion-level-3 shadow-sm accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#meme-otherPermission">
                  <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" />
                  <h5 className='fw-semibold ms-3 mb-0'>Other Permission</h5>
                </div>

                <div id='meme-otherPermission' className="accordion-collapse collapse show accordion accordion-flush my-0">
                  {/* used in templates */}
                  <div className='ms-3'>
                    <div className='d-flex justify-content-between align-items-center my-3'>
                      <h5 className='accordion-level-4 shadow-sm mb-0 me-5'>Allow your memes to be used in templates</h5>

                      <div className="form-check form-switch m-0">
                        <input type="checkbox" className="form-check-input" role="switch" aria-checked name='memeUsedInTemplate' value={value.memeUsedInTemplate === 'true' ? false : true}
                          onChange={(e) => handleOptionChange(e, 'privacy')} checked={value.memeUsedInTemplate === 'true' ? true : false} 
                        />
                      </div>
                    </div>

                    <div className='ms-4'>
                      <span>By default, your selected audience for the MEMES will be able to use your MEMES for creating memes. However, if you want to disallow specific people from using your MEMES, you can select their names below.</span>

                      <SettingInputUser settingNav='privacy' settingKey='memeUsedInTemplate' settingType='other' />
                    </div>

                    <hr className='hr bg-secondary mb-0' style={{ width: '-webkit-fill-available' }} />
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className='btn btn-primary rounded-pill w-100'>Done</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MemeAdvanceSettingPopup;