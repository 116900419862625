import React, { useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import CreateMemes2 from './CreateMemes2';
import { BASE_URL_STATIC } from '../../../../../config';
import { IoClose } from "react-icons/io5";
// import CreateMemes3 from './CreateMemes3';

const CreateMemes1 = ({ showCreateMemesPopup1,selectedEmoji, setShowCreateMemesPopup1 }) => {

  const [showCreateMemesPopup2, setShowCreateMemesPopup2] = useState(false);
  // const [showCreateMemesPopup3, setShowCreateMemesPopup3] = useState(false);
  const [selectedMeme, setSelectedMeme] = useState([])
  const [isDragging, setIsDragging] = useState(false);


  const fileInputRef = useRef(null);

  const handleButton = () => {
    fileInputRef.current.click()
  }

  const handleInputChange = (e) => {
    setShowCreateMemesPopup2(true);
    const selectedFiles = Array.from(e.target.files);
    setSelectedMeme(selectedFiles);
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    setShowCreateMemesPopup2(true);
    const selectedFiles = Array.from(e.dataTransfer.files);
    setSelectedMeme(selectedFiles);
  };



  return (
    <>
      <section>
        <section>
          <Modal className={`${isDragging ? 'dragging' : ''}`} show={showCreateMemesPopup1} onHide={() => setShowCreateMemesPopup1(false)} centered backdrop='static'>
            <Modal.Body className='modal_body_1' style={{backgroundImage : `url(${BASE_URL_STATIC}/cmbgimg.png)`}} >

              <section>
                <div className='position-relative text-center'>
                  <img src={`${BASE_URL_STATIC}/cmimg.png`} alt="cmimg" height='100' width='215' />

                  <IoClose  className='meme_close' onClick={() => setShowCreateMemesPopup1(false)} />
                </div>

                <label htmlFor="selectMeme" >
                  <input type="file" id='selectMeme' accept='image/* video/*' ref={fileInputRef} multiple style={{ display: 'none' }} onChange={handleInputChange} />
                </label>

                <div className='meme_img_vid'>
                  <div >
                    <img width='38px' src={`${BASE_URL_STATIC}/cm-image.png`} alt="cm-image1"  />
                    <span>Image</span>
                  </div>

                  <div>
                    <img src={`${BASE_URL_STATIC}/cm-video.png`} alt="video" />
                    <span>Video</span>
                  </div>
                </div>

                <div className='createMeme1' onDragEnd={handleDragEnter} onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop} >
                  <img src={`${BASE_URL_STATIC}/cm2-img1.png`} alt="cm2-img1" height='400' width='320' />
                  <div className='browse'  onClick={handleButton} >
                      <img src={`${BASE_URL_STATIC}/cm2-browse.png`} alt="cm2-browse" style={{ padding: '10px' }} />
                      <p style={{ color: 'white', padding: '5px' }}>Browse or Drag & Drop</p>
                    </div>
                </div>

              </section>
            </Modal.Body>
          </Modal>
        </section>

        {/* Create Memes Popup 2 */}
        <CreateMemes2 setSelectedMeme={setSelectedMeme} selectedEmoji={selectedEmoji} showCreateMemesPopup2={showCreateMemesPopup2} selectedMeme={selectedMeme} setShowCreateMemesPopup2={setShowCreateMemesPopup2} setShowCreateMemesPopup1={setShowCreateMemesPopup1} />

      </section>
    </>
  );
};

export default CreateMemes1
