import React from 'react'

const ProfilePrivacy = () => {
  return (
    <>
      <section className='vh-100 overflow-y-scroll scrollbar-hidden pt-5'>
        <h1 className='text-center mt-4'>Privacy soon..</h1>
      </section>
    </>
  )
}

export default ProfilePrivacy