import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createRelation, getRelation, relationList } from '../../../UseContext/ApiHelpers'
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL_STATIC } from '../../../../../config';
import axios from 'axios';
import { profileContext } from '../../../UseContext/ProfileContext';

const AddRelationPop = ({ open, close }) => {
   const [relationsData, setRelationsDatas] = useState([])
   const { config, loggedInUserGender } = useContext(ApiContext)
   const [saveLoader, setSaveLoader] = useState(false)
   const [loader, setLoader] = useState(false)
   const [inputValue, setinputValue] = useState('')
   const [err, setErr] = useState('')
   const [searchList, setSearchList] = useState([])
   const [secondUserAs, setsecondUserAs] = useState('')
   const [firstUser, setFirstUser] = useState('')
const {setRelationsData} = useContext(profileContext)

   useEffect(() => {
      relationList(config).then((res) => {
         setRelationsDatas(res)
      })
      setErr("")
      setsecondUserAs('')
      setFirstUser('')
      setSaveLoader(false)
   }, [])

   useMemo(() => {
      if (inputValue.length) {
         setErr("")
      }
   }, [inputValue])

   const handleSearch = async (e) => {
      setErr("");
      const searchValue = e.target.value.trim();

      setinputValue(searchValue);

      if (e.target.value.length === 0) {
         setSearchList([]);
         return;
      }
      setLoader(true);

      try {
         const res = await axios.post(`https://emilonode.sjain.io/user/getmutualfollowers?page=1&sort=1&search=${e.target.value}`, {}, config);

         if (res.data.data.length > 0) {

            setSearchList(res.data.data);
         } else {
            setSearchList([]);
            setinputValue('');
         }
      } catch (error) {
         setSaveLoader(false);
         console.error("Error searching for mutual followers:", error);
      } finally {
         setLoader(false);
         setSaveLoader(false);
      }
   };

   const femaleRelationships = relationsData.femaleRelationships ? Object.keys(relationsData.femaleRelationships) : []
   const maleRelationships = relationsData.maleRelationships ? Object.keys(relationsData.maleRelationships) : []

   const handleSubmit = (e) => {
      e.preventDefault()
      setSaveLoader(true)
      if (secondUserAs === '' || firstUser === '') {
         setErr('Please select relation and user')
         return
      }

      const getRelationship = (a, b) => {
         if(firstUser.gender === "Male"){
            const relation = relationsData.maleRelationships[a];
            if (relation) {
               setSaveLoader(false)
               return relation[b.toLowerCase()];
            } else {
               setSaveLoader(false)
               return "Unknown relationship";
            }
         }
         else{
            const relation = relationsData.femaleRelationships[a];
            if (relation) {
               setSaveLoader(false)
               return relation[b.toLowerCase()];
            } else {
               setSaveLoader(false)
               return "Unknown relationship";
            }
         }
      };
      
      const data = {
         secondUserAs, secondUserId: firstUser.userId, secondUserName : firstUser.fname, firstGender : loggedInUserGender, secondGender:firstUser.gender, firstUserAs : getRelationship(secondUserAs, loggedInUserGender.toLowerCase())
      }

         
      createRelation(data, config).then(async(res) => {
         getRelation(config).then(res => { setRelationsData(res) })

         await axios.post("https://emilonode.sjain.io/user/createemnotification" , {type : "relation" , receiverUserId :firstUser.userId } , config)
         close(); 

         setFirstUser("")
      })
   }

   return (
      <section>
         <Modal show={open} onHide={() => { close(); setFirstUser(""); }} centered backdrop="static" keyboard={false} className="create-call" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               <form onSubmit={handleSubmit}>
                  <h5>Relations</h5>

                  <div className="">
                     <p className='fav_movie_1'>
                        <span>
                           <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#bcbec2", }} />
                        </span>
                        <span>Relation Name</span>
                     </p>

                     {!firstUser ?
                        <div className="relation_div ">

                           <ul className='container position-absolute' style={{ top: "20%" }}>
                              {loader ?
                                 <div>
                                    <div className="d-flex justify-content-center align-items-center my-4 gap-3 pe-4">
                                       <div className="spinner-border opacity-75" style={{ color: "#0080FF" }} role="status">
                                          <span className="visually-hidden"></span>
                                       </div>
                                    </div>
                                 </div>

                                 : (
                                    <div className='relation_ul'>
                                       {searchList && searchList.map((e) => (
                                          <li key={e.userId} onClick={() => setFirstUser(e)} className="flex justify-content-start me-2">
                                             {
                                                e.imageId ?
                                                   <img className='image-fluid rounded-pill' style={{ width: "5%" }} src={`${BASE_URL_STATIC}/user/${e.imageId}`} alt="" />
                                                   :
                                                   <img className='image-fluid rounded-pill' style={{ width: "5%" }} src={`${BASE_URL_STATIC}/dummy.png`} alt="" />
                                             }
                                             <span>{e.fname}</span>
                                          </li>
                                       ))}
                                    </div>
                                 )}
                           </ul>

                           <input type="text" className="form-control" placeholder="search mutual users" aria-label="Username" aria-describedby="basic-addon1" onChange={handleSearch} />

                        </div>
                        :
                        <div className="d-flex  justify-content-center align-items-center my-4 gap-3">

                           {firstUser.imageId ? <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/user/${firstUser.imageId}`} alt="" />
                              :
                              <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/dummy.png`} alt="" />
                           }

                           <div>
                              <h6><b>{firstUser.fname}</b></h6>
                              <small>send request for comfirmation</small>
                           </div>

                           <div onClick={() => setFirstUser("")} style={{ cursor: "pointer" }}>
                              <FontAwesomeIcon icon={faClose} className='ps-3 opacity-75' />
                           </div>

                        </div>
                     }

                     <div className='w-75 m-auto'>
                        <select onChange={(e) => { setsecondUserAs(e.target.value); setErr("") }} className='w-100 text-center' name="" id="">
                           <option value="">select</option>
                           {firstUser.gender === "Female" && femaleRelationships && femaleRelationships.map((e) => (
                              <option key={e} value={e}>{e}</option>
                           ))}
                           {firstUser.gender === "Male" && maleRelationships && maleRelationships.map((e) => (
                              <option key={e} value={e}>{e}</option>
                           ))}
                        </select>
                     </div>


                     <div className='text-center'>
                        <button type='submit' className='fav_movie_btn'>{saveLoader ? "Saving..." : "Save"}</button>
                     </div>

                     <p className='mt-1 text-center' style={{ color: "#FF4640" }}>
                        <span>{err}</span>
                     </p>

                  </div>

               </form>
            </Modal.Body>
         </Modal>
      </section>
   )
}

export default AddRelationPop;
